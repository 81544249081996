import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import NavBar from "../../components/Navbar";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getContextFilterData,
  getContextSearchData,
  getTableStoreValue,
  setTableFilterData,
} from "../../utilities/pageScroll";
import { AppContext } from "../../App";
import Tab from "../../components/Tab/index";
import { Filter } from "./activityLogFilters";
import { Row } from "react-bootstrap";
import ActivityLogTable from "../../components/ActivityLogs/ActivityLogTable";
import Search from "../../components/Search/search";
import { PHYSICAL_TEAM, PHYSICALTEAM } from "../../custom/constants";
// context provider
export const ActivityLogsContext = createContext();

const ActivityLogs = () => {
  const location = useLocation();
  const loginPermission = useSelector((state) => state.login.userPermission);
  const menuId = useSelector((state) => state.login.menuId);
  const [key, setKey] = useState(
    location?.state?.customerActiveKey
      ? location?.state?.customerActiveKey
      : localStorage.getItem("activityLogsTabKey")
      ? localStorage.getItem("activityLogsTabKey")
      : loginPermission.includes(menuId.enterpriseBuyerLogs)
      ? "customerBuyer"
      : loginPermission.includes(menuId.enterpriseSellerLogs)
      ? "customerSeller"
      : loginPermission.includes(menuId.agentLogs)
      ? "customerAgent"
      : "customerBuyer"
  );
  const dispatch = useDispatch();
  const { tableStoreContext, setTableStoreContext, pageScroll } =
    useContext(AppContext);
  const currentTableValueFromConText = getTableStoreValue(
    key,
    tableStoreContext
  );
  //activity logs filter states
  let contextFilterData = getContextFilterData("activityLogs", pageScroll);
 
  const initialfilterValue = contextFilterData || {};
  const actions = {
    companyList: "companyList",
    brandList: "brandList",
    cityList: "cityList",
    regionList: "regionList",
    pocList: "pocList",
    propertyTypeList: "propertyTypeList",
    supplierList: "supplierList",
    createdByList: "createdByList",
    sortByList: "sortByList",
    search: "search",
    contactedList: "contactedList",
    startDate: "startDate",
    endDate: "endDate",
    eventNameList: "eventNameList",
  };

  const filterReducer = (state, action) => {
    let value = action?.value;
    if (
      action &&
      action?.isMulti === "no" &&
      action?.value &&
      action?.value?.value === "select"
    ) {
      value = {};
    }
    const actionType = actions[action?.type];
    if (!actionType) {
      return state;
    }
    return { ...state, [actionType]: value };
  };
  const [filterValues, filterDispatch] = useReducer(
    filterReducer,
    initialfilterValue
  );
  let scrollStoreArray = [...pageScroll];
  const storetableName = currentTableValueFromConText?.tableName;
  const storePageNo = currentTableValueFromConText?.pageNo;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [offset, setOffset] = useState(
    storetableName === key ? (storePageNo ? storePageNo : 1) : 1
  );

  useEffect(() => {
    if (!localStorage.getItem("activityLogsTabKey")) {
      localStorage.setItem("activityLogsTabKey", key);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tabDetails = [
    loginPermission?.includes(menuId.enterpriseBuyerLogs) && {
      title: "Enterprise Buyers",
      content: (
        <>
          {key === "customerBuyer" ? (
            <ActivityLogTable
              offSet={offset}
              customerType={"Brand"}
              setOffset={setOffset}
            />
          ) : null}
        </>
      ),
      eventKey: "customerBuyer",
    },
    loginPermission?.includes(menuId.enterpriseSellerLogs) && {
      title: "Enterprise Sellers",
      content: (
        <>
          {key === "customerSeller" ? (
            <ActivityLogTable
              offSet={offset}
              customerType={"Developer"}
              setOffset={setOffset}
            />
          ) : null}
        </>
      ),
      eventKey: "customerSeller",
    },
    loginPermission?.includes(menuId.agentLogs) && {
      title: "Enterprise Agents",
      content: (
        <>
          {key === "customerEAgent" ? (
            <ActivityLogTable
              offSet={offset}
              customerType={"EAgent"}
              setOffset={setOffset}
            />
          ) : null}
        </>
      ),
      eventKey: "customerEAgent",
    },
    loginPermission?.includes(menuId.agentLogs) && {
      title: PHYSICAL_TEAM,
      content: (
        <>
          {key === "customerPhysicalTeam" ? (
            <ActivityLogTable
              offSet={offset}
              customerType={PHYSICAL_TEAM}
              setOffset={setOffset}
            />
          ) : null}
        </>
      ),
      eventKey: "customerPhysicalTeam",
    },
    loginPermission?.includes(menuId.agentLogs) && {
      title: "Agents",
      content: (
        <>
          {key === "customerAgent" ? (
            <ActivityLogTable
              offSet={offset}
              customerType={"Agent"}
              setOffset={setOffset}
            />
          ) : null}
        </>
      ),
      eventKey: "customerAgent",
    },

  ];
  const setContextValue = (tableKey) => {
    let newValue = setTableFilterData(
      storetableName,
      {
        pageNo: 1,
        perPage: 10,
        id: "",
      },
      tableStoreContext
    );
    setTableStoreContext(newValue);
  };
  const setTabKey = (k) => {
    setKey(k);
    setContextValue();
    setOffset(1);
    localStorage.setItem("activityLogsTabKey", k);
  };
  const handelSearch = (e) => {
    filterDispatch({
      type: "search",
      value: e.target.value,
    });
  };
  return (
    <ActivityLogsContext.Provider
      value={{
        filterDispatch,
        filterValues,
      }}
    >
      <div>
        <div className="d-flex justify-content-between">
          <NavBar />
          <div className="d-flex " style={{ textAlign: "end" }}>
            <Search value={filterValues["search"]} onChange={handelSearch} />
          </div>
        </div>
        <Row className="mb-4" style={{ rowGap: "8px" }}>
          <Filter filterValues={filterValues} filterDispatch={filterDispatch} tabKey = {key}/>
        </Row>
        <Tab
          tabDetails={tabDetails}
          activeKey={key}
          onSelect={(k) => setTabKey(k)}
        />
      </div>
    </ActivityLogsContext.Provider>
  );
};

export default ActivityLogs;
