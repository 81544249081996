import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getData, postData, postGetData, putData } from "../../../../../api";
import Button from "../../../../../components/Form/Button";
import Loader from "../../../../../components/Loader";
import {
  labelToName,
  nameToLabel,
} from "../../../../../utilities/commonFunction";
import {
  RenderInput,
  RenderMultiSelect,
  RenderPhoneInput,
  RenderSelect,
} from "../../customComponents/inputGroups";
import { pocModalArray } from "../pocArray";
import { errorCheck } from "../pocErrorCheck";
import "../style.scss";

const AgentPocModal = ({
  customerDetails,
  companyTypeReceived,
  modKey,
  pocList,
  type,
  id,
  onClose,
  pocType,
  pocEditData,
  regions,
}) => {
  const [modalArray, setModalArray] = useState([]);
  const [modalObj, setModalObj] = useState({});
  const [errorObject, setErrorObject] = useState({});
  const [cityList, setCityList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [accessList, setAccessList] = useState([]);
  const [isView, setIsView] = useState(false);
  const [change, setChange] = useState(false);
  const brandIdUpdate = useSelector((state) => state.login.brandId);
  const [permissionHave, setPermissionHave] = useState({});

  const getPocData = () => {
    let url;
    if (modKey === "brandPOC") {
      url = `/brand/poc/${id}/${pocEditData?._id}?type=brandPoc`;
    } else if (modKey === "cityPOC") {
      url = `/brand/poc/${id}/${pocEditData?._id}?type=cityPoc`;
    }

    getData(url).then((res) => {
      if (res.status) {
        let data = res?.data;
        if (modKey === "cityPOC") {
          let cityArray = nameToLabel(res?.data?.city);
          data.city = cityArray;
          let stateArray = nameToLabel(res?.data.state);
          data.state = stateArray;
        }
        setModalObj(data);
      }
    });
  };

  const getAccess = async () => {
    let url = "";
    if (modKey === "brandPOC" && type === "physicalTeam") {
      url = `/userPermission?type=Physical Team&userType=${
        modalObj?.primary === "Yes" ? "projectPrime" : "projectPoc"
      }`;
    } else {
      if (modKey === "brandPOC") {
        url = `/userPermission?type=eagent&userType=${
          modalObj?.primary === "Yes" ? "projectPrime" : "projectPoc"
        }`;
        if (pocType === "view" || pocType === "edit") {
          url += `&id=${pocEditData?._id}&brandId=${id}`;
        }
        if (pocType === "edit" && change) {
          url += `&operation=${modalObj?.primary}`;
        }
      } else if (modKey === "cityPOC") {
        url = `/userPermission?type=${
          type ? type : "brand"
        }&userType=regionPoc`;
        if (pocType === "view" || pocType === "edit") {
          url += `&id=${pocEditData?._id}&brandId=${id}`;
        }
      }
    }

    setLoading(true);
    try {
      const res = await getData(url);
      if (res.status) {
        setChange(false);
        const permissionsArr = res?.data.userPermissions;

        setAccessList(permissionsArr);
        setLoading(false);
      } else {
        setChange(false);
        setLoading(false);
      }
    } catch (error) {
      setChange(false);
      setLoading(false);
    }
  };
  const onChange = (value, key) => {
    if (pocType === "edit" && key === "primary") {
      setChange(true);
    }
    delete errorObject[key];
    if (key === "city") {
      setModalObj({ ...modalObj, [key]: value?.label ? [value] : [] });
    } else if (key === "state") {
      setModalObj({
        ...modalObj,
        [key]: value?.label ? [value] : [],
        city: [],
      });
    } else {
      setModalObj({ ...modalObj, [key]: value });
    }
  };

  const handleCheck = (name, checked, dependent) => {
    const updatedAccessList = accessList.map((acc) => {
      if (acc.name === name) {
        return {
          ...acc,
          checked: !checked,
          isDisabled: checked,
        };
      }
      if (!checked && dependent.includes(acc.name) && !acc.checked) {
        return {
          ...acc,
          checked: true,
          isDisabled: false,
        };
      }
      return acc;
    });
    setAccessList(updatedAccessList);
  };

  const handleUnCheck = (name, checked, dependent) => {
    const updatedAccessList = accessList.map((acc) => {
      if (acc.name === name) {
        return {
          ...acc,
          checked: !checked,
          isDisabled: checked,
        };
      }
      if (checked && dependent.includes(acc.name) && acc.checked) {
        return {
          ...acc,
          checked: false,
          isDisabled: true,
        };
      }
      return acc;
    });
    setAccessList(updatedAccessList);
  };

  const handleDefaultCheck = (name, checked) => {
    const updatedAccessList = accessList.map((acc) => {
      if (acc.name === name) {
        return {
          ...acc,
          checked: !checked,
          isDisabled: checked,
        };
      }
      return acc;
    });
    setAccessList(updatedAccessList);
  };

  const handleBothCheckandUncheck = (name, checked, toUncheck, toCheck) => {
    const updatedAccessList = accessList.map((acc) => {
      if (acc.name === name) {
        return {
          ...acc,
          checked: !checked,
          isDisabled: checked,
        };
      }
      if (!checked && toUncheck.includes(acc.name) && acc.checked) {
        return {
          ...acc,
          checked: false,
          isDisabled: true,
        };
      }
      if (!checked && toCheck.includes(acc.name) && !acc.checked) {
        return {
          ...acc,
          checked: true,
          isDisabled: false,
        };
      }
      return acc;
    });
    setAccessList(updatedAccessList);
  };
  const handleCheckandUncheck = (name, checked, toUncheck) => {
    const updatedAccessList = accessList.map((acc) => {
      if (acc.name === name) {
        return {
          ...acc,
          checked: !checked,
          isDisabled: checked,
        };
      }
      if (toUncheck === acc.name) {
        return {
          ...acc,
          checked: false,
          isDisabled: true,
        };
      }
      return acc;
    });
    setAccessList(updatedAccessList);
  };

  const onChangeSelect = (acc, checkedValue) => {
    console.log({ acc });
    console.log({ checkedValue });
    console.log(
      "----------------------------------------------------------------------"
    );
    delete errorObject["permissions"];
    switch (acc?.name) {
      //----------------
      case "viewAllProperties":
        return handleUnCheck(acc?.name, checkedValue, [
          "viewResponsesReceivedProperties",
          "manageProperties",
          "revertBackToPropertyResponses",
          "viewPropertiesDashboardOfAllPocs",
        ]);

      case "viewAllDemands":
        return handleUnCheck(acc?.name, checkedValue, [
          "viewResponsesReceivedDemands",
          "manageDemands",
          "revertBackToDemandResponses",
          "viewDemandsDashboardOfAllPocs",
        ]);

      case "viewProperties":
        return handleUnCheck(acc?.name, checkedValue, [
          "viewResponsesReceivedProperties",
          "manageProperties",
          "revertBackToPropertyResponses",
          "viewPropertiesDashboardOfPocs",
        ]);

      case "viewDemands":
        return handleUnCheck(acc?.name, checkedValue, [
          "viewResponsesReceivedDemands",
          "manageDemands",
          "revertBackToDemandResponses",
          "viewDemandsDashboardOfPocs",
        ]);

      // case "viewResponsesReceivedProperties":
      //   return handleBothCheckandUncheck(
      //     acc?.name,
      //     checkedValue,
      //     ["revertBackToPropertyResponses"],
      //     ["viewAllProperties", "viewProperties"]
      //   );
      case "viewResponsesReceivedProperties":
        console.log({ checkedValue });
        return checkedValue
          ? handleUnCheck(acc?.name, checkedValue, [
              "revertBackToPropertyResponses",
            ])
          : handleCheck(acc?.name, checkedValue, [
              "viewAllProperties",
              "viewProperties",
            ]);
      // handleBothCheckandUncheck(
      //   acc?.name,
      //   checkedValue,
      //   ["revertBackToPropertyResponses"],
      //   ["viewAllProperties", "viewProperties"]
      // );
      case "viewResponsesReceivedDemands":
        return checkedValue
          ? handleUnCheck(acc?.name, checkedValue, [
              "revertBackToDemandResponses",
            ])
          : handleCheck(acc?.name, checkedValue, [
              "viewAllDemands",
              "viewDemands",
            ]);
      case "revertBackToPropertyResponses":
        return handleCheck(acc?.name, checkedValue, [
          "viewAllProperties",
          "viewProperties",
          "viewResponsesReceivedProperties",
        ]);

      case "revertBackToDemandResponses":
        return handleCheck(acc?.name, checkedValue, [
          "viewAllDemands",
          "viewDemands",
          "viewResponsesReceivedDemands",
        ]);

      case "manageProperties":
        return handleCheck(acc?.name, checkedValue, [
          "viewAllProperties",
          "viewProperties",
        ]);

      case "manageDemands":
        return handleCheck(acc?.name, checkedValue, [
          "viewAllDemands",
          "viewDemands",
        ]);

      case "viewPropertiesDashboardOfAllPocs":
        return handleCheck(acc?.name, checkedValue, [
          "viewAllProperties",
          "viewProperties",
        ]);

      case "viewDemandsDashboardOfAllPocs":
        return handleCheck(acc?.name, checkedValue, [
          "viewAllDemands",
          "viewDemands",
        ]);

      case "viewPropertiesDashboardOfPocs":
        return handleCheck(acc?.name, checkedValue, [
          "viewAllProperties",
          "viewProperties",
        ]);

      case "viewDemandsDashboardOfPocs":
        return handleCheck(acc?.name, checkedValue, [
          "viewAllDemands",
          "viewDemands",
        ]);

      // case "inboxFullAccess":
      //   return handleCheckandUncheck(acc?.name, checkedValue, "inboxReadOnly");
      // case "inboxReadOnly":
      //   return handleCheckandUncheck(
      //     acc?.name,
      //     checkedValue,
      //     "inboxFullAccess"
      //   );
      case "inboxFullAccess":
        return handleBothCheckandUncheck(
          acc?.name,
          checkedValue,
          ["inboxReadOnly"],
          [""]
        );
      case "inboxReadOnly":
        return handleBothCheckandUncheck(
          acc?.name,
          checkedValue,
          ["inboxFullAccess"],
          [""]
        );

      /////------------------------

      default:
        return handleDefaultCheck(acc?.name, checkedValue);
    }
  };

  const getCityByState = (stateId) => {
    if (stateId) {
      setLoading(true);
      let url = `/company/companyCities/${brandIdUpdate}`;
      let listedId = [];
      listedId = stateId.map((val) => val.value);
      let payload = {
        stateIds: listedId,
        type: "cityPoc",
      };
      postGetData(url, {}, payload).then((response) => {
        setLoading(false);
        if (response?.status) {
          let cityArray = nameToLabel(response?.data);
          setCityList(cityArray);
          modalArray.forEach((element) => {
            if (element.key === "city") {
              element.option = cityArray;
            }
          });
        } else {
          modalArray.forEach((element) => {
            if (element.key === "city") {
              element.option = [];
            }
          });
        }
      });
    } else {
      modalArray.forEach((element) => {
        if (element.key === "city") {
          element.option = [];
        }
      });
    }
  };
  const createPoc = () => {
    let data = { ...modalObj };
    let combinedArr = [...accessList];
    data.permissions = combinedArr;
    if (modKey === "brandPOC") {
      data.type = "brandPoc";
    } else if (modKey === "cityPOC") {
      data.type = "cityPoc";
      let cityArray = labelToName(data?.city);
      data.city = cityArray;
      let stateArray = labelToName(data?.state);
      data.state = stateArray;
    }
    if (pocEditData?._id) {
      let url;
      if (modKey === "brandPOC" || modKey === "cityPOC") {
        url = `/brand/poc/updatePoc/${id}/${pocEditData?._id}`;
      }
      setLoading(true);
      putData(url, {}, data).then((response) => {
        if (response.status) {
          pocList();
          onClose();
          setModalObj({});
          setLoading(false);
        } else if (response.statusCode === 400 || response.statusCode === 422) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
      setLoading(false);
    } else {
      let url;
      if (modKey === "brandPOC" || modKey === "cityPOC") {
        url = `/brand/poc/addPoc/${id}`;
      }
      setLoading(true);
      postData(url, {}, data).then((response) => {
        if (response.status) {
          pocList();
          onClose();
          setModalObj({});
          setLoading(false);
        } else if (response.statusCode === 400 || response.statusCode === 422) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
    }
  };
  const onClickSubmit = async (e) => {
    e.preventDefault();
    let data = { ...modalObj };

    let combinedArr = [...accessList];
    data.permissions = combinedArr;
    let errors = await errorCheck(data, modKey);
    if (Object.keys(errors).length === 0) {
      createPoc();
    } else {
      setErrorObject(errors);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && event.target.tagName !== "TEXTAREA") {
      event.preventDefault();
      onClickSubmit(event);
    }
  };
  useEffect(() => {
    getAccess();
  }, [modalObj?.primary]);

  
  useEffect(() => {
    // setModalObj()
    let currentPocArray = pocModalArray?.filter((el) => el.key === modKey);
    if (pocType === "view") {
      setIsView(true);
    } else {
      setIsView(false);
    }
    let pocFieldObj = currentPocArray[0]?.pocObj;

    if (pocEditData && id) {
      getPocData(currentPocArray[0]?.pocObj);
    } else if (
      modKey === "brandPOC" &&
      companyTypeReceived === "companyYesDeveloper"
    ) {
      delete pocFieldObj["primary"];
      setModalObj(pocFieldObj);
    } else {
      setModalObj(pocFieldObj);
    }
    if (modKey === "cityPOC") {
      // getCities();
    }

    let pocFieldArray = [];
    if (
      modKey === "brandPOC" &&
      companyTypeReceived === "companyYesDeveloper"
    ) {
      pocFieldArray = currentPocArray[0]?.pocArray?.filter(
        (item) => item.key !== "primary"
      );
    } else {
      pocFieldArray = currentPocArray[0]?.pocArray;
    }

    // setModalArray(pocFieldArray);
    if (modKey === "cityPOC" && customerDetails) {
      pocFieldArray.forEach((element) => {
        if (element.key == "state") {
          element.option = customerDetails?.state?.map((x) => ({
            label: x?.name ? x?.name : x?.label,
            value: x?._id ? x?._id : x?.value,
          }));
        }
      });
    }
    setModalArray(pocFieldArray);
  }, [regions, customerDetails]);
  useEffect(() => {
    if (modalObj?.state && modalObj.state.length > 0) {
      getCityByState(modalObj?.state);
    } else {
      modalArray.forEach((element) => {
        if (element.key == "city") {
          element.option = [];
        }
      });
    }
  }, [modalObj?.state]);

  useEffect(() => {
    if (cityList && cityList.length) {
      modalArray.forEach((element) => {
        if (element.key === "city") {
          element.option = cityList;
        }
      });
    } else {
      modalArray.forEach((element) => {
        if (element.key === "city") {
          element.option = [];
        }
      });
    }
  }, [cityList]);

  return (
    <>
      {loading && <Loader className={"fixed"} />}
      <Form onSubmit={onClickSubmit} onKeyDown={handleKeyPress}>
        <div className="row">
          {modalArray?.map((item, i) => {
            return (
              <React.Fragment key={i}>
                {item.type === "input" && (
                  <RenderInput
                    name={item.name}
                    keyValue={item.key}
                    inputType={item.inputType}
                    placeholder={item.placeholder}
                    value={modalObj[item.key]}
                    error={errorObject[item.key]}
                    onChange={onChange}
                    disabled={isView ? true : false}
                    size="w-50"
                  />
                )}
                {item.type === "phoneInput" && (
                  <RenderPhoneInput
                    name={item.name}
                    keyValue={item.key}
                    inputType={item.inputType}
                    placeholder={item.placeholder}
                    value={modalObj[item.key]}
                    error={errorObject[item.key]}
                    onChange={onChange}
                    disabled={isView ? true : false}
                    size="w-50"
                  />
                )}
                {item.type === "select" && (
                  <RenderSelect
                    option={item?.option}
                    name={item.name}
                    keyValue={item.key}
                    placeholder={item.placeholder}
                    value={modalObj[item.key]}
                    error={errorObject[item.key]}
                    onChange={onChange}
                    disabled={isView ? true : false}
                    size="w-50"
                  />
                )}
                {item.type === "multiSelect" && (
                  <RenderMultiSelect
                    option={item?.option}
                    name={item.name}
                    keyValue={item.key}
                    placeholder={item.placeholder}
                    value={modalObj[item.key]}
                    error={errorObject[item.key]}
                    onChange={onChange}
                    disabled={isView ? true : false}
                    size="w-50"
                    isMulti={item?.isMulti}
                  />
                )}
              </React.Fragment>
            );
          })}
        </div>
        <div>
          <label>Access</label>
          <div className={pocType === "view" ? "access-list-view" : ""}>
            {accessList?.length > 0 &&
              accessList.map((acc, index) => {
                // Dont delete

                return (
                  <>
                    {pocType === "view" ? (
                      acc?.checked ? (
                        <li key={acc?._id}>{acc?.displayName}</li>
                      ) : null
                    ) : (
                      <Form.Check
                        type="checkbox"
                        id={"check-api-checkbox-input"}
                        className="m-0"
                        key={index}
                        controlId={index}
                      >
                        <Form.Check.Input
                          type={"checkbox"}
                          id={`check ${acc?._id}`}
                          onChange={(e) => onChangeSelect(acc, acc?.checked)}
                          checked={acc?.checked}
                        />
                        <label className="mx-2">
                          {acc?.displayName}
                        </label>
                      </Form.Check>
                    )}
                  </>
                );
              })}
          </div>
          <Form.Control.Feedback
            type="invalid"
            style={{ display: "block", bottom: "unset", left: "unset" }}
          >
            {errorObject.hasOwnProperty("permissions") &&
              errorObject["permissions"]}
          </Form.Control.Feedback>
        </div>
        {isView ? null : (
          <div className="btn-container">
            <Button
              className="btn-outline-color-primary d-block"
              type="button"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              disabled={loading}
              loading={loading}
              className="btn-color-primary d-block"
              // onClick={onClickSubmit}
              type="submit"
            >
              {pocEditData?._id ? "Update" : "Submit"}
            </Button>
          </div>
        )}
      </Form>
    </>
  );
};
export default AgentPocModal;
