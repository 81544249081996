import Input from "../../../../components/Form/Input";
import PhoneNumberInput from "../../../../components/Form/Input/phoneNumberInput";
import Select from "../../../../components/Form/Select";
import TextArea from "../../../../components/Form/TextArea";
import MultiSelect from "../../../../components/select/dropDown";
export const RenderInput = ({
  name,
  keyValue,
  value,
  error,
  inputType,
  objName,
  placeholder,
  onChangeSubscription,
  onChange,
  edit,
  contactSuppliersShow,
  submissionInputShow,
  propertyDemandInput,
  size,
  disabled
}) => {
  return (
    
      <Input
        enableNote={
          keyValue === "contactSuppliers" && edit
            ? ""
            : ""
        }
        disabled={
          disabled?disabled:
          keyValue === "userDashboard"
            ? true
            : keyValue === "contactSuppliers" 
            ? contactSuppliersShow 
              ? false
              : true
            : keyValue === "submissionAgainstDemand"
            ? submissionInputShow
              ? false
              :true
            : keyValue === "propertyOrDemand"
            ? propertyDemandInput
              ? false
              : true
            : // : keyValue === "email" && edit ? false
              false
        }
        sufix={keyValue === "stats" ? "Projects" : ""}
        placeholder={placeholder}
        errorMessage={error}
        label={name}
        name={name}
        value={value}
        type={inputType}
        isInvalid={!!error}
        min={inputType === "number" ? 0 : ""}
        size={size ? size :"w-40"}
        onChange={(e) =>

         {
             objName === "subscription"
            ? onChangeSubscription(e.target.value, keyValue)
            : onChange(e.target.value, keyValue)}
        }
      />
    
  );
};
export const RenderPhoneInput = ({ name,
    keyValue,
    value,
    error,
    inputType,
    placeholder,
    onChange,
    size,
    disabled
}) => {
    return (
      <PhoneNumberInput
        placeholder={placeholder}
        disabled={disabled?disabled:false}
        errorMessage={error}
        label={name}
        name={name}
        value={value}
        type={inputType}
        isInvalid={!!error}
        min={inputType === "number" ? 0 : ""}
        size={size ? size :"w-40"}
        onChange={(e) => {
          onChange(e.target.value, keyValue);
        }}
      />
    );
  };


export const  RenderSelect = ({
name,
keyValue,
option,
value,
error,
objName,
placeholder,
subKey,
rmVal,
setContactSuppliersShow,
setPropertyDemandInput,
setSubmissionInputShow,
onChangeSubscription,
onChange,
subscription,
size,
companyTypeReceived,
disabled,
customerDetails,
}
) => {
let validity;
if (keyValue === "validityDays") {
    validity =
    value === 90
        ? "90 days"
        : value === 60
        ? "60 days"
        : value === 30
        ? "30 days"
        : "";
}
let featuredDemand 
let homePageSlotAndBanner
 let emailtoSuppliers
 let showBrandStatus

if(companyTypeReceived === "companyYesBrand"){
  if( keyValue === "brandNameRequired"){
    showBrandStatus =customerDetails?.disableBrandNameStatus
  }

featuredDemand = keyValue === "featuredDemand" && !subscription?.hasOwnProperty("companyFeature") 
  homePageSlotAndBanner = keyValue === "homePageSlotAndBanner" && !subscription?.hasOwnProperty("companyTopBrand")
  emailtoSuppliers = keyValue === "emailtoSuppliers" && !subscription?.hasOwnProperty("companyEmailtoSuppliers") 
}
else if(companyTypeReceived === "companyNoBrand"){
  if(keyValue === "homePageSlotAndBanner"){
    showBrandStatus =customerDetails?.brandNameRequired === "No"
    homePageSlotAndBanner = !subscription?.hasOwnProperty("companyTopBrand")
  }
}

return (
    <Select
    disabled={disabled ? disabled:showBrandStatus ? showBrandStatus : featuredDemand ? featuredDemand : homePageSlotAndBanner ? homePageSlotAndBanner : emailtoSuppliers ? emailtoSuppliers : keyValue === "rm" ? rmVal === "Yes" ? false :  true : false}
    errorMessage={error}
    placeholder={placeholder}
    label={name}
    name={name}
    value={
        objName === "subscription"
        ? validity
            ? validity
            : keyValue === "rm" ? value?._id ? value?._id: value?.name : value
        : value?._id ? value?._id : value?.name ? value?.name  : value?.companyName ? value?.companyName : value

    }
    options={option}
    size={size ? size :"w-40"}
    isInvalid={!!error}
    onChange={(e) => {
        if (objName === "subscription") {
        if (keyValue === "validityDays") {
            let validityValue =
            e.target.value === "90 days"
                ? 90
                : e.target.value === "60 days"
                ? 60
                : e.target.value === "30 days"
                ? 30
                : "";
            onChangeSubscription(validityValue ? validityValue : "", keyValue);
        } 
        else {
            if (
            keyValue === "submissionAgainstDemandSelect" || keyValue === "contactSuppliersSelect" ||
            keyValue === "propertyOrDemandSelect"
            ) {
            if (e.target.value === "Enter number") {
                subscription[subKey] = "";
                if (keyValue === "contactSuppliersSelect") {
                setContactSuppliersShow(true);
                }
                else if (keyValue === "submissionAgainstDemandSelect") {
                setSubmissionInputShow(true);
                } else {
                setPropertyDemandInput(true);
                }
                
            } else {
                if (keyValue === "contactSuppliersSelect") {
                setContactSuppliersShow(false);
                }
                else if (keyValue === "submissionAgainstDemandSelect") {
                setSubmissionInputShow(false);
                } else {
                setPropertyDemandInput(false);
                }
                subscription[subKey] = "";
            
            }  
        }
        onChangeSubscription(
            e.target.value === "Select" ? "" : e.target.value,
            keyValue
        );
        }
        } else {
        onChange(e.target.value === "Select" ? "" : e.target.value, keyValue);
        }
    }}
    />
);
};

export const RenderMultiSelect = ({ 
    name,
    keyValue,
    option,
    value,
    error,
    placeholder,
    onChange,
    size,disabled,
    isMulti=true
}) => {
    return (
      <div className={size ? size :"w-40"}>
        <MultiSelect
          onChange={(val) => onChange(val, keyValue)}
          isMulti={isMulti}
          disabled={disabled?true:false}
          name={name}
          size={size ? size :"w-40"}
          options={option}
          value={value}
          lable={name}
          placeholder={placeholder}
          errormessage={error}
        />
      </div>
    );
  };

 export const RenderTextArea = ({onChange,disabled,name, keyValue, value, error, placeholder}) => {
    return (
      <TextArea
        placeholder={placeholder}
        errorMessage={error}
        label={name}
        name={name}
        value={value}
        isInvalid={!!error}
        size={"w-80"}
        disabled={disabled ? true : false}
        onChange={(e) => {
          onChange(e.target.value, keyValue);
        }}
      />
    );
  };