import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { getData, postData, putData } from "../../../../../api";
import Button from "../../../../../components/Form/Button";
import Loader from "../../../../../components/Loader";
import {
  labelToName,
  nameToLabel,
} from "../../../../../utilities/commonFunction";
import {
  RenderInput,
  RenderMultiSelect,
  RenderPhoneInput,
  RenderSelect,
} from "../../customComponents/inputGroups";
import { pocModalArray } from "../pocArray";
import { errorCheck } from "../pocErrorCheck";
import "../style.scss";

const PhysicalTeamPocModal = ({
  customerDetails,
  companyTypeReceived,
  modKey,
  pocList,
  type,
  edit,
  view,
  id,
  onClose,
  pocType,
  pocEditData,
  regions,
}) => {
  const [modalArray, setModalArray] = useState([]);
  const [modalObj, setModalObj] = useState({});
  const [errorObject, setErrorObject] = useState({});
  const [changed, setChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [accessList, setAccessList] = useState([]);
  const [isView, setIsView] = useState(false);
  const [typeOfPoc, setTypeOfPoc] = useState('');
  //const [havePermissions, setHavePermissions] = useState({});


  const getPocData = () => {
    let url;
    if (modKey === "brandPOC") {
      url = `/brand/poc/${id}/${pocEditData?._id}?type=brandPoc`;
    } else if (modKey === "regionPOC") {
      url = `/brand/poc/${id}/${pocEditData?._id}?type=regionPoc`;
    } else {
      url = `/company/poc/${id}/${pocEditData?._id}`;
    }

    getData(url).then((res) => {
      if (res.status) {
        let data = res?.data;

        if (modKey === "regionPOC") {
          let regionArray = nameToLabel(res?.data?.region);
          data.region = regionArray;
        }
        setTypeOfPoc(data.primary)
        setModalObj(data);
      }
    });
  };

  // function createPermissionObj(permits){
  //   let obj = {}
  //   permits.forEach(function(p){
  //     obj[p.name] = p.checked;
  //   })
  //   setHavePermissions(obj)
  // }

  async function getAllPermissions(isPrimary){
    try{
      let role = isPrimary ? 'projectPrime' : 'projectPoc';
      let permitUrl = `/userPermission?type=Physical%20Team&userType=${role}`;
      let rsp = await getData(permitUrl);
      if(rsp.status){
        let data = rsp.data;
        return data.userPermissions;
        // setAccessList(data.userPermissions || [])
      }

      return [];

    }catch(err){
      console.log(':: getAllPermissions ::', err)
    }
  }

  const getAccess = async () => {
    
    let url = "";
    let baseUrl = '';
    if (modKey === "brandPOC") {
      if (modalObj?.primary === "Yes") {
        url = `/userPermission?type=${
          type ? type : "brand"
        }&userType=noFlowBrandPrime`;
        if (["view", "edit"].includes(pocType)) {
          url += `&id=${pocEditData?._id}&brandId=${id}${
            changed ? `&operation=${modalObj?.primary}` : ""
          }`;
        }
      } else {
        url = `/userPermission?type=${type ? type : "brand"}&userType=${
          companyTypeReceived === "companyYesBrand" ? "brandPoc" : "noFlowBrand"
        }`;
        if (["view", "edit"].includes(pocType)) {
          url += `&id=${pocEditData?._id}&brandId=${id}${
            changed ? `&operation=${modalObj?.primary}` : ""
          }`;
        }
      }
    } else if (modKey === "companyPOC") {
      if (modalObj?.primary === "Yes") {
        url = `/userPermission?type=${type}&userType=companyPrime`;
        if (["view", "edit"].includes(pocType)) {
          url += `&id=${pocEditData?._id}${
            changed ? `&operation=${modalObj?.primary}` : ""
          }`;
        }
      } else {
        url = `/userPermission?type=${type}&userType=companyPoc`;
        if (["view", "edit"].includes(pocType)) {
          url += `&id=${pocEditData?._id}${
            changed ? `&operation=${modalObj?.primary}` : ""
          }`;
        }
      }
    } else if (modKey === "regionPOC") {
      url = `/userPermission?type=${type ? type : "brand"}&userType=regionPoc`;
      if (["view", "edit"].includes(pocType)) {
        url += `&id=${pocEditData?._id}&brandId=${id}`;
      }
    }
   
    setLoading(true);
    if(modalObj.primary === typeOfPoc){
       getData(url).then((res) => {
        if (res.status) {
          let permissionsArr = res?.data.userPermissions;
            setAccessList(permissionsArr);       
        }
        setLoading(false);
        setChanged(false); 
      });
      
    }else{
      let allPermits = await getAllPermissions(modalObj?.primary === "Yes");  
      setAccessList(allPermits || [])
      setLoading(false);
      setChanged(false); 
    }      
  };

  const onChange = (value, key) => {
    delete errorObject[key];
    if (pocType === "edit" && key === "primary") {
      setChanged(true);
    }
    if (key === "city") {
      setModalObj({ ...modalObj, [key]: value?.label ? [value] : [] });
    } else if (key === "state") {
      setModalObj({
        ...modalObj,
        [key]: value?.label ? [value] : [],
        city: [],
      });
    } else {
      setModalObj({ ...modalObj, [key]: value });
    }
  };

  const updateInterLinkedValues = (
    key,
    checkedField = [],
    unCheckedFiled = [],
    checkedValue,
    singleFieldCheck = false
  ) => {
    let update = [...accessList];
    let updatedValue = update.map((item) => {
      if (key === item.name) {
        return { ...item, checked: checkedValue ? false : true };
      } else if (unCheckedFiled.includes(item.name) && singleFieldCheck) {
        return { ...item, checked: checkedValue ? item.checked : false };
      } else if (checkedField.includes(item.name) && !checkedValue) {
        return { ...item, checked: true };
      } else if (unCheckedFiled.includes(item.name) && checkedValue) {
        return { ...item, checked: false };
      } else {
        return item;
      }
    });

    return updatedValue;
  };

  const handleCheckandUncheck = (name, checked, toUncheck) => {
    const updatedAccessList = accessList.map((acc) => {
      if (acc.name === name) {
        return {
          ...acc,
          checked: !checked,
          isDisabled: checked,
        };
      }
      if (toUncheck === acc.name) {
        return {
          ...acc,
          checked: false,
          isDisabled: true,
        };
      }
      return acc;
    });
    return updatedAccessList;
  };

  const handleBothCheckandUncheck = (name, checked, toUncheck, toCheck) => {
    const updatedAccessList = accessList.map((acc) => {
      if (acc.name === name) {
        return {
          ...acc,
          checked: !checked,
          isDisabled: checked,
        };
      }
      if (!checked && toUncheck.includes(acc.name) && acc.checked) {
        return {
          ...acc,
          checked: false,
          isDisabled: true,
        };
      }
      if (!checked && toCheck.includes(acc.name) && !acc.checked) {
        return {
          ...acc,
          checked: true,
          isDisabled: false,
        };
      }
      return acc;
    });
    setAccessList(updatedAccessList);
  };

  const handleCheck = (name, checked, dependent) => {
    const updatedAccessList = accessList.map((acc) => {
      if (acc.name === name) {
        return {
          ...acc,
          checked: !checked,
          isDisabled: checked,
        };
      }
      if (!checked && dependent.includes(acc.name) && !acc.checked) {
        return {
          ...acc,
          checked: true,
          isDisabled: false,
        };
      }
      return acc;
    });
    setAccessList(updatedAccessList);
  };

  const handleUnCheck = (name, checked, dependent) => {
    const updatedAccessList = accessList.map((acc) => {
      if (acc.name === name) {
        return {
          ...acc,
          checked: !checked,
          isDisabled: checked,
        };
      }
      if (checked && dependent.includes(acc.name) && acc.checked) {
        return {
          ...acc,
          checked: false,
          isDisabled: true,
        };
      }
      return acc;
    });
    setAccessList(updatedAccessList);
  };

  const handleDefaultCheck = (name, checked) => {
    const updatedAccessList = accessList.map((acc) => {
      if (acc.name === name) {
        return {
          ...acc,
          checked: !checked,
          isDisabled: checked,
        };
      }
      return acc;
    });
    setAccessList(updatedAccessList);
  };

  const onChangeSelect = (acc, checkedValue) => {

    // let ko = {...havePermissions}
    // if(havePermissions[acc?.name]){   
    //   ko[acc?.name] = false;
    // }else{
    //   ko[acc?.name] = true;
    // }
    //setHavePermissions(ko)

    delete errorObject["permissions"];
    switch (acc?.name) {
      //----------------
      case "viewAllProperties":
        return handleUnCheck(acc?.name, checkedValue, [
          "viewResponsesReceivedProperties",
          "manageProperties",
          "revertBackToPropertyResponses",
          "viewPropertiesDashboardOfAllPocs",
          "viewPropertiesDashboardOfPocs",
        ]);

      case "viewAllDemands":
        return handleUnCheck(acc?.name, checkedValue, [
          "viewResponsesReceivedDemands",
          "manageDemands",
          "revertBackToDemandResponses",
          "viewDemandsDashboardOfAllPocs",
          "viewDemandsDashboardOfPocs"
        ]);

      case "viewProperties":
        return handleUnCheck(acc?.name, checkedValue, [
          "viewResponsesReceivedProperties",
          "manageProperties",
          "revertBackToPropertyResponses",
          "viewPropertiesDashboardOfPocs",
        ]);

      case "viewDemands":
        return handleUnCheck(acc?.name, checkedValue, [
          "viewResponsesReceivedDemands",
          "manageDemands",
          "revertBackToDemandResponses",
          "viewDemandsDashboardOfPocs",
        ]);

      // case "viewResponsesReceivedProperties":
      //   return handleBothCheckandUncheck(
      //     acc?.name,
      //     checkedValue,
      //     ["revertBackToPropertyResponses"],
      //     ["viewAllProperties", "viewProperties"]
      //   );
      case "viewResponsesReceivedProperties":
        return checkedValue
          ? handleUnCheck(acc?.name, checkedValue, [
              "revertBackToPropertyResponses",
            ])
          : handleCheck(acc?.name, checkedValue, [
              "viewAllProperties",
              "viewProperties",
            ]);
      // handleBothCheckandUncheck(
      //   acc?.name,
      //   checkedValue,
      //   ["revertBackToPropertyResponses"],
      //   ["viewAllProperties", "viewProperties"]
      // );
      case "viewResponsesReceivedDemands":
        return checkedValue
          ? handleUnCheck(acc?.name, checkedValue, [
              "revertBackToDemandResponses",
            ])
          : handleCheck(acc?.name, checkedValue, [
              "viewAllDemands",
              "viewDemands",
            ]);
      case "revertBackToPropertyResponses":
        return handleCheck(acc?.name, checkedValue, [
          "viewAllProperties",
          "viewProperties",
          "viewResponsesReceivedProperties",
        ]);

      case "revertBackToDemandResponses":
        return handleCheck(acc?.name, checkedValue, [
          "viewAllDemands",
          "viewDemands",
          "viewResponsesReceivedDemands",
        ]);

      case "manageProperties":
        return handleCheck(acc?.name, checkedValue, [
          "viewAllProperties",
          "viewProperties",
        ]);

      case "manageDemands":
        return handleCheck(acc?.name, checkedValue, [
          "viewAllDemands",
          "viewDemands",
        ]);

      case "viewPropertiesDashboardOfAllPocs":
        return handleCheck(acc?.name, checkedValue, [
          "viewAllProperties",
          "viewProperties",
        ]);

      case "viewDemandsDashboardOfAllPocs":
        return handleCheck(acc?.name, checkedValue, [
          "viewAllDemands",
          "viewDemands",
        ]);

      case "viewPropertiesDashboardOfPocs":
        return handleCheck(acc?.name, checkedValue, [
          "viewAllProperties",
          "viewProperties",
        ]);

      case "viewDemandsDashboardOfPocs":
        return handleCheck(acc?.name, checkedValue, [
          "viewAllDemands",
          "viewDemands",
        ]);

      // case "inboxFullAccess":
      //   return handleCheckandUncheck(acc?.name, checkedValue, "inboxReadOnly");
      // case "inboxReadOnly":
      //   return handleCheckandUncheck(
      //     acc?.name,
      //     checkedValue,
      //     "inboxFullAccess"
      //   );
      case "inboxFullAccess":
        return handleBothCheckandUncheck(
          acc?.name,
          checkedValue,
          ["inboxReadOnly"],
          [""]
        );
      case "inboxReadOnly":
        return handleBothCheckandUncheck(
          acc?.name,
          checkedValue,
          ["inboxFullAccess"],
          [""]
        );

      /////------------------------

      default:
        return handleDefaultCheck(acc?.name, checkedValue);
    }
  };

  const createPoc = () => {
    let data = { ...modalObj };
    let combinedArr = [...accessList];
    data.permissions = combinedArr;
    if (modKey === "brandPOC") {
      data.type = "brandPoc";
    } else if (modKey === "regionPOC") {
      data.type = "regionPoc";
      let regionArray = labelToName(data?.region);
      data.region = regionArray;
    }

    if (pocEditData?._id) {
      setLoading(true);
      let url;
      if (modKey === "brandPOC" || modKey === "regionPOC") {
        url = `/brand/poc/updatePoc/${id}/${pocEditData?._id}`;
      } else {
        url = `/company/updatePoc/${id}/${pocEditData?._id}`;
      }

      putData(url, {}, data).then((response) => {
        if (response.status) {
          pocList();
          onClose();
          setModalObj({});
          setLoading(false);
        } else if (response.statusCode === 400 || response.statusCode === 422) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
    } else {
      let url;
      if (modKey === "brandPOC" || modKey === "regionPOC") {
        url = `/brand/poc/addPoc/${id}`;
      } else {
        url = `/company/addPoc/${id}`;
      }
      setLoading(true);
      postData(url, {}, data).then((response) => {
        if (response.status) {
          pocList();
          onClose();
          setModalObj({});
          setLoading(false);
        } else if (response.statusCode === 400 || response.statusCode === 422) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
    }
  };

  const onClickSubmit = async (e) => {
    e.preventDefault();
    let data = { ...modalObj };

    let combinedArr = [...accessList];
    data.permissions = combinedArr;
    let errors = await errorCheck(data, modKey);
    if (Object.keys(errors).length === 0) {
      createPoc();
    } else {
      setErrorObject(errors);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && event.target.tagName !== "TEXTAREA") {
      event.preventDefault();
      onClickSubmit(event);
    }
  };
  
  useEffect(() => {
    getAccess();
  }, [modalObj?.primary]);

  useEffect(() => {
    // setModalObj()
    let currentPocArray = pocModalArray?.filter((el) => el.key === modKey);
    if (pocType === "view") {
      setIsView(true);
    } else {
      setIsView(false);
    }
    let pocFieldObj = currentPocArray[0]?.pocObj;

    if (pocEditData && id) {
      getPocData(currentPocArray[0]?.pocObj);
    } else if (
      modKey === "brandPOC" &&
      companyTypeReceived === "companyYesBrand"
    ) {
      delete pocFieldObj["primary"];
      setModalObj(pocFieldObj);
    } else {
      setModalObj(pocFieldObj);
    }

    let pocFieldArray = [];
    if (modKey === "brandPOC" && companyTypeReceived === "companyYesBrand") {
      pocFieldArray = currentPocArray[0]?.pocArray?.filter(
        (item) => item.key !== "primary"
      );
    } else {
      pocFieldArray = currentPocArray[0]?.pocArray;
    }

    // setModalArray(pocFieldArray);

    if (modKey === "regionPOC" && regions) {
      pocFieldArray.forEach((element) => {
        if (element.key === "region") {
          element.option = nameToLabel(regions);
        }
      });
    }
    setModalArray(pocFieldArray);
  }, [regions, customerDetails]);

  //  const checkForValueTrue =(checkArr=[],)=>{
  // return [...accessList].filter((item)=>checkArr.includes(item?.name)).every((item)=>item?.checked===true)
  //  }

  //  const disabledCheckBox =(acc)=>{
  // if(["companyPOC","brandPOC","regionPOC"].includes(modKey)){
  //   let checkArr=[]
  //   switch (acc?.name) {
  //     case "viewDemandsSubmittedByAssignedBrands":
  //       checkArr=["viewMappedBrands"];
  //     return checkForValueTrue(checkArr)?false:true;
  //     case "viewResponsesReceived":
  //       checkArr=["viewDemandsSubmittedByAssignedBrands"];
  //     return checkForValueTrue(checkArr)?false:true;;
  //     case "revertBackToResponses":
  //       checkArr=["viewResponsesReceived"];
  //     return checkForValueTrue(checkArr)?false:true;;
  //     default:
  //       return false
  //   }
  // }

  //  }

  return (
    <>
      {loading && <Loader className={"fixed"} />}
      <Form onSubmit={onClickSubmit} onKeyDown={handleKeyPress}>
        <div className="row">
          {modalArray?.map((item, i) => {
            return (
              <React.Fragment key={i}>
                {item.type === "input" && (
                  <RenderInput
                    name={item.name}
                    keyValue={item.key}
                    inputType={item.inputType}
                    placeholder={item.placeholder}
                    value={modalObj[item.key]}
                    error={errorObject[item.key]}
                    onChange={onChange}
                    disabled={isView ? true : false}
                    size="w-50"
                  />
                )}
                {item.type === "phoneInput" && (
                  <RenderPhoneInput
                    name={item.name}
                    keyValue={item.key}
                    inputType={item.inputType}
                    placeholder={item.placeholder}
                    value={modalObj[item.key]}
                    error={errorObject[item.key]}
                    onChange={onChange}
                    disabled={isView ? true : false}
                    size="w-50"
                  />
                )}
                {item.type === "select" && (
                  <RenderSelect
                    option={item?.option}
                    name={item.name}
                    keyValue={item.key}
                    placeholder={item.placeholder}
                    value={modalObj[item.key]}
                    error={errorObject[item.key]}
                    onChange={onChange}
                    disabled={isView ? true : false}
                    size="w-50"
                  />
                )}
                {item.type === "multiSelect" && (
                  <RenderMultiSelect
                    option={item?.option}
                    name={item.name}
                    keyValue={item.key}
                    placeholder={item.placeholder}
                    value={modalObj[item.key]}
                    error={errorObject[item.key]}
                    onChange={onChange}
                    disabled={isView ? true : false}
                    size="w-50"
                    isMulti={item?.isMulti}
                  />
                )}
              </React.Fragment>
            );
          })}
        </div>
        <div>
          <label>Access</label>
          <div className={pocType === "view" ? "access-list-view" : ""}>
            {accessList?.length > 0 &&
              accessList.map((acc, index) => {
                // Dont delete
                return (
                  <>
                    {pocType === "view" ? (
                      acc?.checked ? (
                        <li key={acc?._id}>{acc?.displayName}</li>
                      ) : null
                    ) : (
                      <Form.Check
                        type="checkbox"
                        id={"check-api-checkbox-input"}
                        className="m-0"
                        key={index}
                        controlId={index}
                      >
                        <Form.Check.Input
                          type={"checkbox"}
                          id={`check ${acc?._id}`}
                          onChange={(e) => onChangeSelect(acc, acc?.checked)}
                          // disabled={disabledCheckBox(acc)}
                          //checked={havePermissions[acc.name] || false}
                          checked={acc?.checked}
                        />
                        <label className="mx-2">{acc?.displayName}</label>
                      </Form.Check>
                    )}
                  </>
                );
              })}
          </div>
          <Form.Control.Feedback
            type="invalid"
            style={{ display: "block", bottom: "unset", left: "unset" }}
          >
            {errorObject.hasOwnProperty("permissions") &&
              errorObject["permissions"]}
          </Form.Control.Feedback>
        </div>
        {isView ? null : (
          <div className="btn-container">
            <Button
              className="btn-outline-color-primary d-block"
              type="button"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              disabled={loading}
              loading={loading}
              className="btn-color-primary d-block"
              // onClick={onClickSubmit}
              type="submit"
            >
              {pocEditData?._id ? "Update" : "Submit"}
            </Button>
          </div>
        )}
      </Form>
    </>
  );
};
export default PhysicalTeamPocModal;
