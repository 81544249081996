// import swal from "sweetalert";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
export const baseURL = process.env.REACT_APP_API_URL;
export const contentFileUploadId = "62d8de576c863a36dce35b6d";
export const isLoading = () => {
  document.getElementById("pageLoader").classList.add("d-block");
};
export const AccessDenied = () => {
  return (
    <div>
      <h3>Oops! Seems like you don't have Access to this page.</h3>
      <h5 className="mt-4 mb-3">
        How to get access to this page ? Check the instructions given below
      </h5>
      <ol className="pl-5" type="1">
        <li>Contact your administrator to revoke your access privileges.</li>
        <li>Logout and Login again using your credentials.</li>
      </ol>
    </div>
  );
};
export const isNotLoading = () => {
  if (
    document.querySelector("#pageLoader") &&
    document.querySelector("#pageLoader").classList.contains("d-block")
  ) {
    document.querySelector("#pageLoader").classList.remove("d-block");
  }
};
export const isLogedIn = () => {
  return !!localStorage.getItem("keydemand_token");
};
export const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("keydemand_user"));
};
export const getCurrentUserData = () => {
  return JSON.parse(localStorage.getItem("keydemand_data"));
};
export function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}
export const postDataForgot = async (url = "", headers = {}, data = {}) => {
  try {
    const response = await fetch(`${baseURL + url}`, {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${getCurrentUser()?.token}`,
      },
      body: JSON.stringify(data),
    });

    const responseData = await response.json();
    return responseData;
  } catch (e) {
    return e;
  }
};

//Checking For Permission
export const checkUserPermission = async () => {
  const url = "/user/getAdminData";
  try {
    const response = await fetch(`${baseURL + url}`, {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${getCurrentUser()?.token}`,
      },
    });

    const { data, statusCode, status, message } = await response.json();

    if (statusCode === 401) {
      localStorage.clear();
      window.location = "/login";
    }
    if (statusCode === 200 || status) {
      data.token = localStorage.getItem("keydemand_token");
      localStorage.setItem("keydemand_user", JSON.stringify(data));
      localStorage.setItem("chat_count", data.chatCount);
      localStorage.setItem("enquiry_count", data.enquiryCount);
    } else if (
      !status &&
      response.statusCode !== 400 &&
      response.statusCode !== 422
    ) {
      Swal.fire({
        // title: header,
        text: message,
        icon: "error",
        timer: 3000,
        buttons: false,
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: true,
      });
    }
    return data;
  } catch (e) {
    return e;
  }
};
export const postData = async (url = "", headers = {}, data = {}) => {
  // await checkUserPermission();
  // console.log({url});
  // console.log({headers});
  // console.log({data});

  try {
    const response = await fetch(`${baseURL + url}`, {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${getCurrentUser()?.token}`,
      },
      body: JSON.stringify(data),
    });

    // console.log(getCurrentUser()?.token);

    const responseData = await response.json();
    if (responseData.statusCode === 401) {
      localStorage.clear();
      window.location = "/login";
    }
    if (responseData.statusCode === 200 || responseData.status) {
      Swal.fire({
        // title: header,
        text: responseData.message,
        icon: "success",
        timer: 3000,
        buttons: false,
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: true,
      });
    } else if (
      !responseData.status &&
      response.statusCode !== 400 &&
      response.statusCode !== 422
    ) {
      Swal.fire({
        // title: header,
        text: responseData.message,
        icon: "error",
        timer: 3000,
        buttons: false,
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: true,
      });
    }
    return responseData;
  } catch (e) {
    return e;
  }
};
export const postGetData = async (url = "", headers = {}, data = {}) => {
  // await checkUserPermission();
  try {
    const response = await fetch(`${baseURL + url}`, {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${getCurrentUser()?.token}`,
      },
      body: JSON.stringify(data),
    });

    const responseData = await response.json();
    if (responseData.statusCode === 401) {
      localStorage.clear();
      window.location = "/login";
    }
    return responseData;
  } catch (e) {
    return e;
  }
};
export const postImportData = async (url = "", headers = {}, data = {}) => {
  // await checkUserPermission();
  try {
    const response = await fetch(`${baseURL + url}`, {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${getCurrentUser()?.token}`,
      },
      body: JSON.stringify(data),
    });

    const responseData = await response.json();
    if (responseData.statusCode === 401) {
      localStorage.clear();
      window.location = "/login";
    }
    if (responseData.statusCode === 200 || responseData.status) {
      Swal.fire({
        // title: header,
        text: responseData.message,
        icon: "success",
        timer: 3000,
        buttons: false,
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: true,
      });
    }

    return responseData;
  } catch (e) {
    return e;
  }
};

export const postImportFlexData = async (url = "", headers = {}, data = {}) => {
  // await checkUserPermission();
  try {
    const response = await fetch(`${baseURL + url}`, {
      method: "POST",
      headers: {
        // "content-Type": "multipart/form-data",
        Authorization: `Bearer ${getCurrentUser()?.token}`,
      },
      body: data,
    });

    const responseData = await response.json();
    if (responseData.statusCode === 401) {
      localStorage.clear();
      window.location = "/login";
    }
    if (responseData.statusCode === 200 || responseData.status) {
      Swal.fire({
        // title: header,
        text: responseData.message,
        icon: "success",
        timer: 3000,
        buttons: false,
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: true,
      });
    }

    return responseData;
  } catch (e) {
    return e;
  }
};
export const postUploadData = async (url = "", headers = {}, data) => {
  try {
    const response = await fetch(`${baseURL + url}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getCurrentUser()?.token}`,
      },
      body: data,
    });
    const responseData = await response.json();
    if (responseData.statusCode === 401) {
      // alert("Your Session Has Been Expired.Please Login Again");
      localStorage.clear();
      window.location = "/login";
    }
    return responseData;
  } catch (e) {
    return e;
  }
};

export const putUploadData = async (url = "", headers = {}, data) => {
  try {
    const response = await fetch(`${baseURL + url}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${getCurrentUser()?.token}`,
      },
      body: data,
    });
    const responseData = await response.json();
    if (responseData.statusCode === 401) {
      // alert("Your Session Has Been Expired.Please Login Again");
      localStorage.clear();
      window.location = "/login";
    }
    return responseData;
  } catch (e) {
    return e;
  }
};

export const refreshData = async (url = "", headers = {}) => {
  // if (url !== "/user/login") {
  //   await checkUserPermission();
  // }
  try {
    const response = await fetch(`${baseURL + url}`, {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${getCurrentUser()?.token}`,
      },
    });
    const responseData = await response.json();
    // const hasKey = responseData?.data?.hasOwnProperty('customerType');
    // if(hasKey){
    // const datas = {...responseData?.data}
    // delete responseData?.data['customerType']
    // }
    if (responseData.statusCode === 401) {
      // alert("Your Session Has Been Expired.Please Login Again");
      localStorage.clear();
      window.location = "/login";
    }
    return responseData;
  } catch (e) {
    return e;
  }
};

export const getData = async (url = "", headers = {}, data = {}, signal) => {
  // if (url !== "/user/login") {
  //   await checkUserPermission();
  // }
  const options = {
    method: "GET",
    headers: {
      "content-Type": "application/json",
      Authorization: `Bearer ${getCurrentUser()?.token}`,
    },
  }
  if (signal) {
    options.signal = signal;
  }
  try {
    const response = await fetch(`${baseURL + url}`, options);
    const responseData = await response.json();
    // const hasKey = responseData?.data?.hasOwnProperty('customerType');
    // if(hasKey){
    // const datas = {...responseData?.data}
    // delete responseData?.data['customerType']
    // }

    if (responseData.statusCode === 401) {
      // alert("Your Session Has Been Expired.Please Login Again");
      localStorage.clear();
      window.location = "/login";
    }
    return responseData;
  } catch (e) {
    return e;
  }
};

export const getInboxSellerData = async (url = "", headers = {}) => {
  // if (url !== "/user/login") {
  //   await checkUserPermission();
  // }
  try {
    const response = await fetch(`${baseURL + url}`, {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${getCurrentUser()?.token}`,
      },
    });
    const responseData = await response.json();
    // const hasKey = responseData?.data?.hasOwnProperty('customerType');
    // if(hasKey){
    // const datas = {...responseData?.data}
    // delete responseData?.data['customerType']
    // }
    if (responseData.statusCode === 401) {
      // alert("Your Session Has Been Expired.Please Login Again");
      localStorage.clear();
      window.location = "/login";
    }
    return responseData;
  } catch (e) {
    return e;
  }
};

export const getData2 = async (url = "", headers = {}) => {
  // if (url !== "/user/login") {
  //   await checkUserPermission();
  // }
  try {
    const response = await fetch(`http://localhost:3005${url}`, {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${getCurrentUser()?.token}`,
      },
    });
    const responseData = await response.json();
    // const hasKey = responseData?.data?.hasOwnProperty('customerType');
    // if(hasKey){
    // const datas = {...responseData?.data}
    // delete responseData?.data['customerType']
    // }
    if (responseData.statusCode === 401) {
      // alert("Your Session Has Been Expired.Please Login Again");
      localStorage.clear();
      window.location = "/login";
    }
    return responseData;
  } catch (e) {
    return e;
  }
};

export const getSampleImagesData = async (url = "", headers = {}) => {
  // if (url !== "/user/login") {
  //   await checkUserPermission();
  // }
  try {
    const response = await fetch(`${baseURL + url}`, {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${getCurrentUser()?.token}`,
      },
    });
    const responseData = await response.json();
    // const hasKey = responseData?.data?.hasOwnProperty('customerType');
    // if(hasKey){
    // const datas = {...responseData?.data}
    // delete responseData?.data['customerType']
    // }
    if (responseData.statusCode === 401) {
      // alert("Your Session Has Been Expired.Please Login Again");
      localStorage.clear();
      window.location = "/login";
    }
    return responseData;
  } catch (e) {
    return e;
  }
};

export const getClienteleData = async (url, data) => {
  try {
    const response = await fetch(`${baseURL + url}`, {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${getCurrentUser()?.token}`,
      },
      // body: JSON.stringify(data),
    });
    const responseData = await response.json();

    if (responseData.statusCode === 401) {
      localStorage.clear();
      window.location = "/login";
    }
    return responseData;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const setClienteleStatus = async (url, data) => {
  try {
    const response = await fetch(`${baseURL + url}`, {
      method: "PUT",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${getCurrentUser()?.token}`,
      },
      body: JSON.stringify(data),
    });

    const responseData = await response.json();
    if (responseData.statusCode === 401) {
      // alert("Your Session Has Been Expired.Please Login Again");
      localStorage.clear();
      window.location = "/login";
    }
    if (responseData.statusCode === 200 || responseData.status) {
      Swal.fire({
        // title: header,
        text: responseData.message,
        icon: "success",
        timer: 3000,
        buttons: false,
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: true,
      });
    } else if (
      !responseData.status &&
      response.statusCode !== 400 &&
      response.statusCode !== 422
    ) {
      Swal.fire({
        // title: header,
        text: responseData.message,
        icon: "error",
        timer: 3000,
        buttons: false,
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: true,
      });
    }
    return responseData;
  } catch (e) {
    return e;
  }
};

export const handleDashboardNewResponse = async (url, data) => {
  try {
    const response = await fetch(`${baseURL + url}`, {
      method: "PATCH",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${getCurrentUser()?.token}`,
      },
      body: JSON.stringify(data),
    });

    const responseData = await response.json();
    if (responseData.statusCode === 401) {
      // alert("Your Session Has Been Expired.Please Login Again");
      localStorage.clear();
      window.location = "/login";
    }
    if (responseData.statusCode === 200 || responseData.status) {
      Swal.fire({
        // title: header,
        text: responseData.message,
        icon: "success",
        timer: 3000,
        buttons: false,
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: true,
      });
    } else if (
      !responseData.status &&
      response.statusCode !== 400 &&
      response.statusCode !== 422
    ) {
      Swal.fire({
        // title: header,
        text: responseData.message,
        icon: "error",
        timer: 3000,
        buttons: false,
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: true,
      });
    }
    return responseData;
  } catch (e) {
    return e;
  }
};
export const handleRmDashboardResponse = async (url) => {
  try {
    const response = await fetch(`${baseURL + url}`, {
      method: "PUT",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${getCurrentUser()?.token}`,
      },
    });

    const responseData = await response.json();
    if (responseData.statusCode === 401) {
      // alert("Your Session Has Been Expired.Please Login Again");
      localStorage.clear();
      window.location = "/login";
    }
    if (responseData.statusCode === 200 || responseData.status) {
      Swal.fire({
        // title: header,
        text: responseData.message,
        icon: "success",
        timer: 3000,
        buttons: false,
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: true,
      });
    } else if (
      !responseData.status &&
      response.statusCode !== 400 &&
      response.statusCode !== 422
    ) {
      Swal.fire({
        // title: header,
        text: responseData.message,
        icon: "error",
        timer: 3000,
        buttons: false,
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: true,
      });
    }
    return responseData;
  } catch (e) {
    return e;
  }
};
export const handleRmDashboardNewResponseCrossAction = async (url, data) => {
  try {
    const response = await fetch(`${baseURL + url}`, {
      method: "PUT",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${getCurrentUser()?.token}`,
      },
      body: JSON.stringify(data),
    });
    const responseData = await response.json();
    if (responseData.statusCode === 401) {
      // alert("Your Session Has Been Expired.Please Login Again");
      localStorage.clear();
      window.location = "/login";
    }
    if (responseData.statusCode === 200 || responseData.status) {
      Swal.fire({
        // title: header,
        text: responseData.message,
        icon: "success",
        timer: 3000,
        buttons: false,
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: true,
      });
    } else if (
      !responseData.status &&
      response.statusCode !== 400 &&
      response.statusCode !== 422
    ) {
      Swal.fire({
        // title: header,
        text: responseData.message,
        icon: "error",
        timer: 3000,
        buttons: false,
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: true,
      });
    }
    return responseData;
  } catch (e) {
    return e;
  }
};
export const handleRmDashboardNewReleventCrossAction = async (url, data) => {
  try {
    const response = await fetch(`${baseURL + url}`, {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${getCurrentUser()?.token}`,
      },
      body: JSON.stringify(data),
    });
    const responseData = await response.json();
    if (responseData.statusCode === 401) {
      // alert("Your Session Has Been Expired.Please Login Again");
      localStorage.clear();
      window.location = "/login";
    }
    if (responseData.statusCode === 200 || responseData.status) {
      Swal.fire({
        // title: header,
        text: responseData.message,
        icon: "success",
        timer: 3000,
        buttons: false,
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: true,
      });
    } else if (
      !responseData.status &&
      response.statusCode !== 400 &&
      response.statusCode !== 422
    ) {
      Swal.fire({
        // title: header,
        text: responseData.message,
        icon: "error",
        timer: 3000,
        buttons: false,
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: true,
      });
    }
    return responseData;
  } catch (e) {
    return e;
  }
};

export const deleteClientele = async (url) => {
  try {
    const response = await fetch(`${baseURL + url}`, {
      method: "DELETE",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${getCurrentUser()?.token}`,
      },
    });

    const responseData = await response.json();
    if (responseData.statusCode === 401) {
      // alert("Your Session Has Been Expired.Please Login Again");
      localStorage.clear();
      window.location = "/login";
    }
    if (responseData.statusCode === 200 || responseData.status) {
      Swal.fire({
        // title: header,
        text: responseData.message,
        icon: "success",
        timer: 3000,
        buttons: false,
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: true,
      });
    } else if (
      !responseData.status &&
      response.statusCode !== 400 &&
      response.statusCode !== 422
    ) {
      Swal.fire({
        // title: header,
        text: responseData.message,
        icon: "error",
        timer: 3000,
        buttons: false,
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: true,
      });
    }
    return responseData;
  } catch (e) {
    return e;
  }
};

export const getPlanData = async (url = "", headers = {}) => {
  try {
    const response = await fetch(`${baseURL + url}`, {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${getCurrentUser()?.token}`,
      },
    });
    const responseData = await response.json();
    const hasKey = responseData?.data?.hasOwnProperty("customerType");
    if (hasKey) {
      // const datas = {...responseData?.data}
      delete responseData?.data["customerType"];
    }
    if (responseData.statusCode === 401) {
      // alert("Your Session Has Been Expired.Please Login Again");
      localStorage.clear();
      window.location = "/login";
    }
    if (
      !responseData.status &&
      response.statusCode !== 400 &&
      response.statusCode !== 422
    ) {
      Swal.fire({
        // title: header,
        text: responseData.message,
        icon: "error",
        timer: 3000,
        buttons: false,
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: true,
      });
    }
    return responseData;
  } catch (e) {
    return e;
  }
};

export const putData = async (url = "", headers = {}, data = {}) => {
  // console.log({url});
  // console.log({headers});
  // console.log({data});
  try {
    const response = await fetch(`${baseURL + url}`, {
      method: "PUT",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${getCurrentUser()?.token}`,
      },
      body: JSON.stringify(data),
    });
    const responseData = await response.json();
    if (responseData.statusCode === 401) {
      // alert("Your Session Has Been Expired.Please Login Again");
      localStorage.clear();
      window.location = "/login";
    }
    if (responseData.statusCode === 200 || responseData.status) {
      Swal.fire({
        // title: header,
        text: responseData.message,
        icon: "success",
        timer: 3000,
        buttons: false,
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: true,
      });
    } else if (
      !responseData.status &&
      response.statusCode !== 400 &&
      response.statusCode !== 422
    ) {
      Swal.fire({
        // title: header,
        text: responseData.message,
        icon: "error",
        timer: 3000,
        buttons: false,
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: true,
      });
    }
    return responseData;
  } catch (e) {
    return e;
  }
};
export const putGetData = async (url = "", headers = {}, data = {}) => {
  try {
    const response = await fetch(`${baseURL + url}`, {
      method: "PUT",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${getCurrentUser()?.token}`,
      },
      body: JSON.stringify(data),
    });
    const responseData = await response.json();
    if (responseData.statusCode === 401) {
      // alert("Your Session Has Been Expired.Please Login Again");
      // localStorage.removeItem("keydemand_user");
      localStorage.clear();
      window.location = "/";
    }
    return responseData;
  } catch (e) {
    return e;
  }
};
export const deleteData = async (url = "", headers = {}, data = {}) => {
  try {
    const response = await fetch(`${baseURL + url}`, {
      method: "DELETE",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${getCurrentUser()?.token}`,
      },
      body: JSON.stringify(data),
    });
    const responseData = await response.json();
    if (responseData.statusCode === 401) {
      // alert("Your Session Has Been Expired.Please Login Again");
      localStorage.clear();
      window.location = "/login";
    }
    if (responseData.statusCode === 200 || responseData.status) {
      Swal.fire({
        // title: header,
        text: responseData.message,
        icon: "success",
        timer: 3000,
        buttons: false,
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: true,
      });
    } else if (
      !responseData.status &&
      response.statusCode !== 400 &&
      response.statusCode !== 422
    ) {
      Swal.fire({
        // title: header,
        text: responseData.message,
        icon: "error",
        timer: 3000,
        buttons: false,
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: true,
      });
    }
    return responseData;
  } catch (e) {
    return e;
  }
};
export const getExportData = async (url = "", headers = {}) => {
  try {
    const response = await fetch(`${baseURL + url}`, {});
    const responseData = await response.blob();

    if (response.statusCode === 401) {
      localStorage.clear();
      window.location = "/";
    }
    return responseData;
  } catch (e) {
    return e;
  }
};
export const postExportData = async (url = "", headers = {}, data = {}) => {
  try {
    const response = await fetch(`${baseURL + url}`, {
      method: "POST",
      headers: {
        // "content-Type": "application/octet-stream",
        "content-Type": "application/json",
        // "content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        Authorization: `Bearer ${getCurrentUser()?.token}`,
        responseType: "arraybuffer",
      },
      body: JSON.stringify(data),
    });
    if (response.status === 200) {
      const responseData = await response.blob();
      return responseData;
    } else if (response.status === 401) {
      localStorage.clear();
      window.location = "/";
    } else {
      response.json()?.then((result) => {
        Swal.fire({
          // title: header,
          text: result?.message,
          icon: "error",
          timer: 3000,
          buttons: false,
          showCancelButton: false,
          showConfirmButton: false,
          showCloseButton: true,
        });
      });
    }
  } catch (e) {
    return e;
  }
};
export const getTemplateData = async (url = "", headers = {}) => {
  try {
    const response = await fetch(`${baseURL + url}`, {});
    // const responseData = await response;

    if (response.statusCode === 401) {
      localStorage.clear();
      window.location = "/";
    }
    return response;
  } catch (e) {
    return e;
  }
};

//Reset Password API
export const resetPassword = async (id) => {
  const url = `/user/resetAdminPassword/${id}`;
  try {
    const response = await fetch(`${baseURL + url}`, {
      method: "PUT",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${getCurrentUser()?.token}`,
      },
    });

    const responseData = await response.json();
    if (responseData.statusCode === 401) {
      // alert("Your Session Has Been Expired.Please Login Again");
      localStorage.clear();
      window.location = "/login";
    }
    if (responseData.statusCode === 200 || responseData.status) {
      Swal.fire({
        // title: header,
        text: responseData.message,
        icon: "success",
        timer: 3000,
        buttons: false,
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: true,
      });
    } else if (
      !responseData.status &&
      response.statusCode !== 400 &&
      response.statusCode !== 422
    ) {
      Swal.fire({
        // title: header,
        text: responseData.message,
        icon: "error",
        timer: 3000,
        buttons: false,
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: true,
      });
    }
    return responseData;
  } catch (e) {
    return e;
  }
};

//Update Subscription
export const updateSubscription = async (id, data, type) => {
  const url = `/company/updateCompanySubscription/${id}?type=${type}`;
  try {
    const response = await fetch(`${baseURL + url}`, {
      method: "PUT",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${getCurrentUser()?.token}`,
      },
      body: JSON.stringify(data),
    });

    const responseData = await response.json();
    if (responseData.statusCode === 401) {
      // alert("Your Session Has Been Expired.Please Login Again");
      localStorage.clear();
      window.location = "/login";
    }
    if (responseData.statusCode === 200 || responseData.status) {
      Swal.fire({
        // title: header,
        text: responseData.message,
        icon: "success",
        timer: 3000,
        buttons: false,
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: true,
      });
    } else if (
      !responseData.status &&
      response.statusCode !== 400 &&
      response.statusCode !== 422
    ) {
      Swal.fire({
        // title: header,
        text: responseData.message,
        icon: "error",
        timer: 3000,
        buttons: false,
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: true,
      });
    }
    return responseData;
  } catch (e) {
    return e;
  }
};

export const getAccountHandlersList = async () => {
  const url = "/user/getAllAccountHandler";
  try {
    const response = await fetch(`${baseURL + url}`, {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${getCurrentUser()?.token}`,
      },
    });

    const responseData = await response.json();
    if (responseData.statusCode === 401) {
      localStorage.clear();
      window.location = "/login";
    }
    if (responseData.statusCode === 200 || responseData.status) {
      // console.log(responseData.data.getAllAccountHandler);
    }

    return responseData;
  } catch (e) {
    return e;
  }
};

export const getAllSwitchUsers = async (id) => {
  const url = "/user/getAllSwitchUsers";
  try {
    const response = await fetch(`${baseURL + url}`, {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${getCurrentUser()?.token}`,
      },
      body: JSON.stringify({
        companyId: id,
      }),
    });

    const responseData = await response.json();
    if (responseData.statusCode === 401) {
      localStorage.clear();
      window.location = "/login";
    }
    if (responseData.statusCode === 200 || responseData.status) {
      // console.log(responseData.data);
    } else {
      Swal.fire({
        // title: header,
        text: responseData.message,
        icon: "error",
        timer: 3000,
        buttons: false,
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: true,
      });
    }

    return responseData;
  } catch (e) {
    return e;
  }
};

export const switchToUsers = async (data) => {
  const url = "/user/switchToUser";
  try {
    const response = await fetch(`${baseURL + url}`, {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${getCurrentUser()?.token}`,
      },
      body: JSON.stringify(data),
    });

    const responseData = await response.json();
    if (responseData.statusCode === 401) {
      localStorage.clear();
      window.location = "/login";
    }
    if (responseData.statusCode === 200 || responseData.status) {
      Swal.fire({
        // title: header,
        text: responseData.message,
        icon: "success",
        timer: 3000,
        buttons: false,
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: true,
      });
      // console.log(responseData.data);
    }

    return responseData;
  } catch (e) {
    return e;
  }
};



// categories


export const getCategoriesData = async (url, data) => {
  try {
    const response = await fetch(`${baseURL + url}`, {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${getCurrentUser()?.token}`,
      },
      // body: JSON.stringify(data),
    });
    const responseData = await response.json();

    if (responseData.statusCode === 401) {
      localStorage.clear();
      window.location = "/login";
    }
    return responseData;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const editHeaderdata = async (url = "",  data = {},headers = {}) => {
  console.log(data,url,headers,"under")
  try {
    const response = await fetch(`${baseURL + url}`, {
      method: "PATCH",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${getCurrentUser()?.token}`,
      },
      body: JSON.stringify(data),
    });
    const responseData = await response.json();
    if (responseData.statusCode === 401) {
      // alert("Your Session Has Been Expired.Please Login Again");
      // localStorage.removeItem("keydemand_user");
      localStorage.clear();
      window.location = "/";
    }
    return responseData;
  } catch (e) {
    return e;
  }
};



export const AddHeaderdata = async (url = "",  data = {},headers = {}) => {
  console.log(data,url,headers,"under")
  try {
    const response = await fetch(`${baseURL + url}`, {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${getCurrentUser()?.token}`,
      },
      body: JSON.stringify(data),
    });
    const responseData = await response.json();
    if (responseData.statusCode === 401) {
      // alert("Your Session Has Been Expired.Please Login Again");
      // localStorage.removeItem("keydemand_user");
      localStorage.clear();
      window.location = "/";
    }
    return responseData;
  } catch (e) {
    return e;
  }
};