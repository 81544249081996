import React from "react";

const PropertyDemandTab = ({ activeTab, setActiveTab }) => {
  return (
        <div className="w-100 h-100">
        <div className="d-flex align-items-end justify-content-between propertyDemandTab ">
          <div
            className={`firstDiv  w-50 ${activeTab === "property" ? "active" : ""}`}
            onClick={() => setActiveTab("property")}
          >
            Properties
          </div>
          <div
            className={`secondDiv w-50  ${activeTab === "demand" ? "active" : ""}`}
            onClick={() => setActiveTab("demand")}
          >
            Demands
          </div>
        </div>
        </div>
  );
};

export default PropertyDemandTab;
