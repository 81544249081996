import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getData } from "../../../api/index";
import { BsFillEyeFill } from "react-icons/bs";
import Button from "../../../components/Form/Button";
import Loader from "../../../components/Loader";
import MainTable from "../../../components/Table/index";
import { updateBrandId } from "../../Login/reducer";

const CustomerTable = ({
  offSet,
  search,
  activeFilterKey,
  cityFilterKey,
  customer,
  cityId,
}) => {
  let dispatch = useDispatch();
  let navigate = useNavigate();

  //loading
  const [initLoading, setInitLoading] = useState(false);
  const loginPermission = useSelector((state) => state.login.userPermission);
  const menuId = useSelector((state) => state.login.menuId);

  const access = {
    viewBuyerCustomers: loginPermission?.includes(menuId.viewBuyerCustomers),
  };

  //list
  const [listData, setListData] = useState([]);
  //pagination
  const [offset, setOffset] = useState(offSet ? offSet : 1);
  const [perPage, setPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const fromPage = useSelector((state) => state.login.parentPage);
  const [warningMessage, setWarningMessage] = useState("");

  let searchKey = search ? search : "";
  let activeFilter = activeFilterKey ? activeFilterKey : "";
  let cityFilter = cityFilterKey ? cityFilterKey : "";

  let companyID = useSelector((state) => state.login.companyId);

  const getCustomerList = () => {
    setInitLoading(true);
    let url = `/company/brand/${companyID}?limit=${perPage}&pageNo=${offset}`;
    if (searchKey) {
      url += `&search=${searchKey}`;
    }
    if (activeFilter === "Active") {
      url += `&isActive=${true}`;
    }
    if (activeFilter === "Inactive") {
      url += `&isActive=${false}`;
    }
    if (cityFilter !== "City" && cityFilter !== "") {
      url += `&cities=${cityFilter}&id=${cityId}`;
    }
    getData(url).then((response) => {
      if (response.status) {
        setInitLoading(false);
        setPageCount(Math.ceil(response.data.totalDoc / perPage));
        setTotalCount(response.data.totalDoc);
        setListData(response.data.companyBrands);
      } else {
        setInitLoading(false);
        setPageCount(0);
        setListData([]);
      }
    });
  };

  const columnsBuyer = [
    {
      dataField: "sno",
      text: "S.no",
    },
    {
      dataField: "name",
      text: "Brand name",
    },

    {
      dataField: "action",
      text: "Action",
      formatter: userButtonFormatterBuyer,
    },
  ];

  function userButtonFormatterBuyer(cell, row, rowIndex) {
    return (
      <div className="d-flex justify-content-center align-items-center ">
        <Button
          disabled={access?.viewBuyerCustomers ? false : true}
          className={"table-btn p-0"}
          onClick={(e) => {
            if (customer === "Brand") {
              let url =
                fromPage === "buyer"
                  ? `/buyerReport/companyList/brandCompanyCreation/brandPage/${row?.slug}`
                  : `/company/brandCompanyCreation/brandPage/${row?.slug}`;
              navigate(url, {
                state: { id: row._id, view: true, fromCompany: true },
              });
            } else {
              navigate("/company/developerCompanyCreation/developerPage", {
                state: { id: row._id, view: true },
              });
            }
            dispatch(updateBrandId(row?._id));
          }}
        >
          <BsFillEyeFill />
        </Button>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </div>
    );
  }

  const handelChangePerPage = (count) => {
    setPerPage(count);
    setOffset(1);
  };
  //
  const tableProps = {
    data: listData,
    columns: columnsBuyer,
    offset,
    setOffset,
    handelChangePerPage,
    pageCount,
    perPage,
    totalCount,
  };
  useEffect(() => {
    getCustomerList();
    if (searchKey) {
      setOffset(offSet);
    }
    // eslint-disable-next-line
  }, [perPage, offset, searchKey, activeFilter, cityFilter]);
  return (
    <div>
      {initLoading && <Loader className={"fixed"} />}
      <MainTable {...tableProps} />
    </div>
  );
};
export default CustomerTable;
