import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

import { useLocation } from "react-router-dom";
import { getData } from "../../../api/index";

import {
  RenderInput,
  RenderRangeInput,
  RenderSelect,
  RenderGoogleLocation,
  RenderTextArea,
  RenderMultiSelect,
} from "../inputGroups";
import Checkbox from "../../../components/Form/Checkbox";
import {
  filteredTransactionOptions,
  isFalsy,
  isNumeric,
  isTruthy,
  nameArrayOfObjects,
} from "../../../utilities/commonFunction";
import { transactionType } from "./dropDownValues";
import { brandTrancationOptions } from "../../../utilities/transactionOptions";

export default function PostPropertyDetail({
  fields,
  initialValues,
  onChangeInput,
  error,
  action,
  disableEdit,
  transactionOptions,
  brand,
  setStepError,
  responseStatus = false,
  propertyType,
  step,
  cityList,
  propertyTypeList,
  setPropertyType,
  showBrandName,
  demandDetailDivRef,
  isAgent,
  usersTransactionType,
  enterpriseAgent,
  physicalTeam,
}) {
  // const navigate = useNavigate();
  const [endUseList, setEndUseList] = useState([]);
  const [multiLocationValue, setMultiloactionValue] = useState({});
  const [pocList, setPocList] = useState([]);
  const [selectedCity, setSelectedCity] = useState({ _id: " ", name: "" });
  const location = useLocation();
  const [trasactionTypeOptions, setTrasactionTypeOptions] = useState([]);
  const brandId = location?.state?.brandId;
  const getCustomerListList = async (id) => {
    let url = `/brand/getAllPoc/${id}?type=demand`;
    // let url = `/brand/brandList?limit=0&pageNo=0`;
    const getCountryData = await getData(url, {}, {});
    const { statusCode, data } = getCountryData;
    if (statusCode === 200) {
      // setLoading(false)
      setPocList(data);
    } else {
      setPocList([]);
    }
  };
  useEffect(() => {
    if (brandId && !isAgent) {
      getCustomerListList(brandId);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (propertyType?.name === "Retail") {
      const filteredTransactionType = filteredTransactionOptions(
        [...brandTrancationOptions?.retail],
        usersTransactionType
      );

      setTrasactionTypeOptions(nameArrayOfObjects(filteredTransactionType));
    } else if (propertyType?.name === "Flex Space") {
      const filteredTransactionType = filteredTransactionOptions(
        [...brandTrancationOptions?.cowork],
        usersTransactionType
      );
      setTrasactionTypeOptions(nameArrayOfObjects(filteredTransactionType));
    } else if (propertyType?.name === "Office space") {
      const filteredTransactionType = filteredTransactionOptions(
        [...brandTrancationOptions?.office],
        usersTransactionType
      );
      setTrasactionTypeOptions(nameArrayOfObjects(filteredTransactionType));
    } else {
      setTrasactionTypeOptions([]);
    }
  }, [propertyType, propertyTypeList]);

  const getEndUseData = async () => {
    let url = `/endUse/getEndUses?type=${propertyType?.name}`;
    const res = await getData(url, {}, {});
    const { statusCode, data } = res;
    if (statusCode === 200) {
      setEndUseList(data);
      // setSelectOption("endUse",data)
    } else {
      setEndUseList([]);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (propertyType?.name) {
      // getPropertyTypeData();
      getEndUseData();
      setSelectedCity({ _id: "", name: "" });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyType?.name]);
  const checkForDemandId = () =>
    JSON.parse(localStorage.getItem("demandId")) !== undefined &&
    JSON.parse(localStorage.getItem("demandId"));
  useEffect(
    () => {
      if (initialValues["location"] && cityList?.length > 0) {
        let filterCity = cityList.filter(
          (item) => item.name === initialValues["location"]
        );
        setSelectedCity(filterCity[0] ? filterCity[0] : { id: "", name: "" });
      }
      if (initialValues["endUse"]?._id && endUseList?.length > 0) {
        let filterendUse = endUseList.filter(
          (item) => item._id === initialValues["endUse"]?._id
        );
        onChangeInput(filterendUse[0]?._id, "endUse", "checked");
      }
    },
    // eslint-disable-next-line
    [endUseList, cityList, initialValues["endUse"]?._id]
  );

  const handleSelectLocation = (suggestion) => {
    if (initialValues?.location) {
      onChangeInput(
        {
          ...suggestion,
          _id: suggestion?.placeId ? suggestion?.placeId : suggestion?._id,
          name: suggestion?.description,
        },
        "microLocation",
        1
      );
    } else {
      setStepError({ location: "Please select city" });
    }
  };
  const handelSelectMulti = (locations) => {
    if (initialValues?.location) {
      setMultiloactionValue(locations);
    } else if (isFalsy(initialValues?.location)) {
      setStepError({ location: "Please select city" });
    }
  };
  const onSelectMultiValue = (locations, btnAction, seletedValue) => {
    if (initialValues?.location) {
      if (
        btnAction === "add" &&
        locations?.length > 0 &&
        locations?.[0]?.description
      ) {
        onChangeInput(locations, "otherInterestedLocation");
        setMultiloactionValue({});
      } else if (locations?.length === 0 || btnAction === "remove") {
        onChangeInput(locations, "otherInterestedLocation");
      }
    } else if (isFalsy(initialValues?.location)) {
      setStepError({ location: "Please select city" });
      setMultiloactionValue({});
    }
  };

  const handelChangeInputs = (value, name, max) => {
    if (value.length > max) {
      return;
    }
    if (name === "pincode") {
      if (isNumeric(value) && value.trim() !== "") return;
    }
    onChangeInput(value, name);
  };
  const handelChangeSelect = (value, name) => {
    if (name === "location") {
      onChangeInput("", "microLocation");
      onChangeInput([], "otherInterestedLocation");
      setMultiloactionValue({});
      // let filterCity = cityList.filter((item) => item._id === value);
      // setSelectedCity(filterCity[0] ? filterCity[0] : { id: "", name: "" });
      onChangeInput(value, name);
    } else if (name === "userId") {
      let filterPoc = pocList.filter((item) => item._id === value);
      onChangeInput(
        filterPoc[0]?.name ? filterPoc[0] : { id: "", name: "" },
        name
      );
    } else if (name) {
      onChangeInput(value, name);
    }
  };
  const handelChangeCheckBox = (value, name) => {
    onChangeInput(value, name);
  };
  let defaultDisabledField = ["brandName", "propertyType"];
  const onChangePropertyType = (id) => {
    setStepError({});
    if (id) {
      let findpropertyType = propertyTypeList.find((item) => item._id === id);
      setPropertyType(findpropertyType);
      localStorage.setItem("propertyType", JSON.stringify(findpropertyType));
    } else {
      setPropertyType({ _id: "", name: "" });
      localStorage.removeItem("propertyType");
    }
  };
  const handelCheckLocationError = (name, value) => {
    if (
      name === "microLocation" &&
      isFalsy(value?.placeId) &&
      isFalsy(value?._id) &&
      isTruthy(initialValues?.location)
    ) {
      onChangeInput(
        {
          description: "",
        },
        "microLocation",
        1
      );
      setStepError({ microLocation: "Please enter valid location" });
    }
  };

  return (
    <div>
      <Container fluid="md" className=" invidual-form" ref={demandDetailDivRef}>
        <Row>
          <Col md="12">
            {}
            <Row>
              {!isAgent && !enterpriseAgent && !physicalTeam && (
                <>
                  <Col md="5">
                    <RenderInput
                      value={brand}
                      label={"Show brand name"}
                      requried={true}
                      name={"brandId"}
                      type="text"
                      checkBoxValue={initialValues["brandNameRequired"]}
                      onChangeCheckBox={(e) =>
                        handelChangeCheckBox(
                          e.target.checked ? true : false,
                          "brandNameRequired"
                        )
                      }
                      disabled={true}
                      hideCheckbox={
                        showBrandName && propertyType?._id ? false : true
                      }
                    />
                  </Col>
                  <Col md="1"></Col>
                </>
              )}

              <Col md="5">
                <RenderSelect
                  value={propertyType?._id}
                  label={"Property Type"}
                  requried={true}
                  name={"propertyType"}
                  options={propertyTypeList}
                  isInvalid={!!error["propertyType"]}
                  errorMessage={error["propertyType"]}
                  placeholder={"Select"}
                  checkBoxValue={true}
                  disabled={
                    action === "edit" || checkForDemandId() ? true : false
                  }
                  onChange={(e) => onChangePropertyType(e.target.value)}
                />
              </Col>
              {(isAgent || enterpriseAgent || physicalTeam) && (
                <Col md="1"></Col>
              )}
              {/* <Col md="5">
                <RenderSelect
                  value={initialValues?.transactionType || ""}
                  label={"Transaction Type"}
                  requried={true}
                  name={"transactionType"}
                  onChange={(e) =>
                    handelChangeSelect(e.target.value, "transactionType")
                  }
                  options={transactionOptions}
                  isInvalid={!!error["transactionType"]}
                  errorMessage={error["transactionType"]}
                  placeholder={"Select"}
                  checkBoxValue={true}
                  // disabled={
                  //   action === "edit" &&
                  //     responseStatus &&
                  //     disableEdit[item.requried]
                  //     ? true
                  //     : false
                  // }
                />
              </Col>
              <Col md="1"></Col> */}

              {fields?.length > 0 &&
                fields.map((item, index) => {
                  const insertCol = <Col md="1"></Col>;

                  if (
                    item.type === "input" &&
                    !(item.name == "tenure" || item.name == "lockInPeriod")
                  ) {
                    return (
                      <React.Fragment key={item.label}>
                        <Col md="5" className="my-2">
                          <RenderInput
                            value={initialValues[item.name]}
                            name={item.name}
                            label={item.label}
                            onChange={(e) =>
                              handelChangeInputs(
                                e.target.value,
                                item.name,
                                item.max
                              )
                            }
                            isInvalid={!!error[item.name]}
                            errorMessage={error[item.name]}
                            // type={item.inputType}
                            onChangeCheckBox={
                              item.defaultChecked
                                ? () => {}
                                : (e) =>
                                    handelChangeCheckBox(
                                      e.target.checked ? true : false,
                                      item.requried
                                    )
                            }
                            checkBoxValue={initialValues[item.requried]}
                            placeholder={item.placeholder}
                            disabled={
                              defaultDisabledField.includes(item.name)
                                ? true
                                : action === "edit" &&
                                  responseStatus &&
                                  disableEdit[item.requried]
                                ? true
                                : false
                            }
                            requried={item.defaultChecked}
                            checboxDisabled={
                              action === "edit" &&
                              responseStatus &&
                              disableEdit[item.requried]
                                ? true
                                : false
                            }
                            onFocus={
                              item?.onFocus
                                ? (e) =>
                                    handelChangeInputs(
                                      initialValues[item.name] === 0
                                        ? ""
                                        : initialValues[item.name],
                                      item.name
                                    )
                                : () => {}
                            }
                            onBlur={
                              item?.onBlur
                                ? (e) =>
                                    handelChangeInputs(
                                      initialValues[item.name] === ""
                                        ? 0
                                        : initialValues[item.name],
                                      item.name
                                    )
                                : () => {}
                            }
                            sufix={item.sufix}
                            prefix={item.prefix}
                          />
                        </Col>
                        {insertCol}
                      </React.Fragment>
                    );
                  } else if (
                    item.type === "input" &&
                    (item.name == "tenure" || item.name == "lockInPeriod") &&
                    initialValues.transactionType !== "Buy"
                  ) {
                    return (
                      <React.Fragment key={item.label}>
                        <Col md="5" className="my-2">
                          <RenderInput
                            value={initialValues[item.name]}
                            name={item.name}
                            label={item.label}
                            onChange={(e) =>
                              handelChangeInputs(
                                e.target.value,
                                item.name,
                                item.max
                              )
                            }
                            isInvalid={!!error[item.name]}
                            errorMessage={error[item.name]}
                            type={item.inputType}
                            onChangeCheckBox={
                              item.defaultChecked
                                ? () => {}
                                : (e) =>
                                    handelChangeCheckBox(
                                      e.target.checked ? true : false,
                                      item.requried
                                    )
                            }
                            checkBoxValue={initialValues[item.requried]}
                            placeholder={item.placeholder}
                            disabled={
                              defaultDisabledField.includes(item.name)
                                ? true
                                : action === "edit" &&
                                  responseStatus &&
                                  disableEdit[item.requried]
                                ? true
                                : false
                            }
                            requried={item.defaultChecked}
                            checboxDisabled={
                              action === "edit" &&
                              responseStatus &&
                              disableEdit[item.requried]
                                ? true
                                : false
                            }
                            onFocus={
                              item?.onFocus
                                ? (e) =>
                                    handelChangeInputs(
                                      initialValues[item.name] === 0
                                        ? ""
                                        : initialValues[item.name],
                                      item.name
                                    )
                                : () => {}
                            }
                            onBlur={
                              item?.onBlur
                                ? (e) =>
                                    handelChangeInputs(
                                      initialValues[item.name] === ""
                                        ? 0
                                        : initialValues[item.name],
                                      item.name
                                    )
                                : () => {}
                            }
                            sufix={item.sufix}
                            prefix={item.prefix}
                          />
                        </Col>
                        {insertCol}
                      </React.Fragment>
                    );
                  } else if (item.type === "rangeInput") {
                    return (
                      <React.Fragment>
                        <Col
                          md="5"
                          className="d-flex align-items-start my-2 range-input"
                          key={`${index}-${item.name}`}
                        >
                          <Checkbox
                            label={" "}
                            name={item.name}
                            value={initialValues[item.requried]}
                            onChange={
                              item.defaultChecked
                                ? () => {}
                                : (e) =>
                                    handelChangeCheckBox(
                                      e.target.checked ? true : false,
                                      item.requried
                                    )
                            }
                            size="mt-4 pt-1"
                            disabled={
                              action === "edit" &&
                              responseStatus &&
                              disableEdit[item.requried]
                                ? true
                                : false
                            }
                          />
                          <Row className="g-0">
                            <label
                              style={{
                                fontStyle: "normal",
                                fontWeight: "700",
                                fontSize: "14px",
                                color: "#000000",
                                marginBottom: "0px",
                              }}
                            >
                              {item.name !== "rentPsfMin"
                                ? item.label
                                : initialValues?.transactionType == "Buy"
                                ? "Rate budget Per square feet on carpet area"
                                : item.label}
                              {item.defaultChecked ? "*" : null}
                            </label>
                            {item.rangefields.map((range, rangefieldsIndex) => {
                              return (
                                <React.Fragment
                                  key={`${rangefieldsIndex}-${range.name}`}
                                >
                                  <Col
                                    md="6"
                                    className="d-flex  align-items-start range-input p-2"
                                  >
                                    <RenderRangeInput
                                      value={initialValues[range.name]}
                                      name={range.name}
                                      label={range.label}
                                      onChange={(e) =>
                                        handelChangeInputs(
                                          e.target.value,
                                          range.name
                                        )
                                      }
                                      isInvalid={!!error[range.name]}
                                      errorMessage={error[range.name]}
                                      type={range.inputType}
                                      placeholder={range.placeholder}
                                      disabled={
                                        action === "edit" &&
                                        responseStatus &&
                                        disableEdit[item.requried]
                                          ? true
                                          : false
                                      }
                                      sufix={range.sufix}
                                      prefix={range.prefix}
                                      onFocus={(e) =>
                                        handelChangeInputs(
                                          initialValues[range.name] === 0
                                            ? ""
                                            : initialValues[range.name],
                                          range.name
                                        )
                                      }
                                      onBlur={(e) =>
                                        handelChangeInputs(
                                          initialValues[range.name] === ""
                                            ? 0
                                            : initialValues[range.name],
                                          range.name
                                        )
                                      }
                                    />
                                  </Col>
                                </React.Fragment>
                              );
                            })}
                          </Row>
                        </Col>
                        {insertCol}
                      </React.Fragment>
                    );
                  } else if (item.type === "checkBox" && item.checkboxOnly) {
                    return (
                      <React.Fragment key={item.label}>
                        <Col md="5" className="d-flex align-items-start my-4">
                          <Checkbox
                            label={" "}
                            customLabel={item.label}
                            name={item.name}
                            value={initialValues[item.requried]}
                            onChange={
                              item.defaultChecked
                                ? () => {}
                                : (e) =>
                                    handelChangeCheckBox(
                                      e.target.checked ? true : false,
                                      item.requried
                                    )
                            }
                            size="m-0"
                            disabled={
                              action === "edit" &&
                              responseStatus &&
                              disableEdit[item.requried]
                                ? true
                                : false
                            }
                            requried={item.defaultChecked}
                          />
                        </Col>
                        {insertCol}
                      </React.Fragment>
                    );
                  } else if (
                    item.type === "select" &&
                    item.name !== "endUse"
                    //  && item.name !== "transactionType"
                  ) {
                    let optionList =
                      item.name === "location"
                        ? cityList
                        : item.name === "endUse"
                        ? endUseList
                        : item.name === "userId"
                        ? pocList
                        : item.name === "transactionType"
                        ? trasactionTypeOptions
                        : item.option;
                    let value =
                      item.name === "location"
                        ? selectedCity?._id
                        : item.name === "userId"
                        ? initialValues[item.name]?._id
                        : initialValues[item.name];

                    let checkBoxValue =
                      item.name === "userId"
                        ? true
                        : initialValues[item.requried];

                    return (
                      <React.Fragment key={item.label}>
                        <Col md="5" className="d-flex align-items-start my-2">
                          <RenderSelect
                            value={value}
                            label={item.label}
                            requried={item.defaultChecked}
                            name={item.name}
                            onChange={(e) =>
                              handelChangeSelect(e.target.value, item.name)
                            }
                            options={optionList}
                            isInvalid={!!error[item.name]}
                            errorMessage={error[item.name]}
                            placeholder={item.placeholder}
                            checkBoxValue={checkBoxValue}
                            onChangeCheckBox={
                              item.defaultChecked
                                ? () => {}
                                : (e) =>
                                    handelChangeCheckBox(
                                      e.target.checked ? true : false,
                                      item.requried
                                    )
                            }
                            disabled={
                              action === "edit" &&
                              responseStatus &&
                              disableEdit[item.requried]
                                ? true
                                : item.name === "transactionType" &&
                                  propertyType?.name === "Flex Space"
                                ? true
                                : false
                            }
                          />
                        </Col>
                        {insertCol}
                      </React.Fragment>
                    );
                  } else if (item.type === "select" && item.name == "endUse") {
                    let optionList =
                      item.name === "location"
                        ? cityList
                        : item.name === "endUse"
                        ? endUseList
                        : item.name === "userId"
                        ? pocList
                        : item.option;
                    let value =
                      item.name === "location"
                        ? selectedCity?._id
                        : item.name === "userId"
                        ? initialValues[item.name]?._id
                        : initialValues[item.name];

                    let checkBoxValue =
                      item.name === "userId"
                        ? true
                        : initialValues[item.requried];

                    return (
                      <React.Fragment key={item.label}>
                        <Col md="5" className="d-flex align-items-start my-2">
                          <RenderSelect
                            value={value}
                            label={item.label}
                            requried={item.defaultChecked}
                            name={item.name}
                            onChange={(e) =>
                              handelChangeSelect(e.target.value, item.name)
                            }
                            options={optionList}
                            isInvalid={!!error[item.name]}
                            errorMessage={error[item.name]}
                            placeholder={item.placeholder}
                            checkBoxValue={checkBoxValue}
                            onChangeCheckBox={
                              item.defaultChecked
                                ? () => {}
                                : (e) =>
                                    handelChangeCheckBox(
                                      e.target.checked ? true : false,
                                      item.requried
                                    )
                            }
                            disabled={
                              action === "edit" &&
                              responseStatus &&
                              disableEdit[item.requried]
                                ? true
                                : false
                            }
                          />
                        </Col>
                        {insertCol}
                      </React.Fragment>
                    );
                  } else if (item.type === "multiSelect") {
                    if (physicalTeam && item?.name === "userAccessibility") {
                      return (
                        <React.Fragment key={item.label}>
                          <Col
                            md="5"
                            className="d-flex align-items-start my-2 z-1000"
                          >
                            <RenderMultiSelect
                              value={
                                initialValues[item.name]?.length > 0
                                  ? initialValues[item.name]
                                  : []
                              }
                              label={item.label}
                              requried={item.defaultChecked}
                              name={item.name}
                              onChange={(val) =>
                                handelChangeSelect(val, item.name)
                              }
                              hideCheckbox={true}
                              options={item.option}
                              isInvalid={!!error[item.name]}
                              errorMessage={error[item.name]}
                              placeholder={item.placeholder}
                              checkBoxValue={initialValues[item.requried]}
                              onChangeCheckBox={
                                item.defaultChecked
                                  ? () => {}
                                  : (e) =>
                                      handelChangeCheckBox(
                                        e.target.checked ? true : false,
                                        item.requried
                                      )
                              }
                              disabled={
                                action === "edit" &&
                                responseStatus &&
                                disableEdit[item.requried]
                                  ? true
                                  : false
                              }
                            />
                          </Col>
                          {insertCol}
                        </React.Fragment>
                      );
                    } else if (item?.name !== "userAccessibility") {
                      return (
                        <React.Fragment key={item.label}>
                          <Col
                            md="5"
                            className="d-flex align-items-start my-2 z-1000"
                          >
                            <RenderMultiSelect
                              value={
                                initialValues[item.name]?.length > 0
                                  ? initialValues[item.name]
                                  : []
                              }
                              label={item.label}
                              requried={item.defaultChecked}
                              name={item.name}
                              onChange={(val) =>
                                handelChangeSelect(val, item.name)
                              }
                              options={item.option}
                              isInvalid={!!error[item.name]}
                              errorMessage={error[item.name]}
                              placeholder={item.placeholder}
                              checkBoxValue={initialValues[item.requried]}
                              onChangeCheckBox={
                                item.defaultChecked
                                  ? () => {}
                                  : (e) =>
                                      handelChangeCheckBox(
                                        e.target.checked ? true : false,
                                        item.requried
                                      )
                              }
                              disabled={
                                action === "edit" &&
                                responseStatus &&
                                disableEdit[item.requried]
                                  ? true
                                  : false
                              }
                            />
                          </Col>
                          {insertCol}
                        </React.Fragment>
                      );
                    }
                  } else if (item.type === "SearchSelect") {
                    let options = cityList.map((city) => ({
                      label: city.name,
                      value: city.name,
                    }));
                    return (
                      <React.Fragment key={item.label}>
                        <Col
                          md="5"
                          className="d-flex align-items-start my-2 multi-search-item"
                        >
                          <RenderMultiSelect
                            value={
                              initialValues[item.name]
                                ? {
                                    label: initialValues[item.name],
                                    value: initialValues[item.name],
                                  }
                                : ""
                            }
                            label={item.label}
                            requried={item.defaultChecked}
                            name={item.name}
                            onChange={(val) =>
                              handelChangeSelect(val?.value, item.name)
                            }
                            options={options}
                            isInvalid={!!error[item.name]}
                            errorMessage={error[item.name]}
                            placeholder={item.placeholder}
                            checkBoxValue={initialValues[item.requried]}
                            onChangeCheckBox={
                              item.defaultChecked
                                ? () => {}
                                : (e) =>
                                    handelChangeCheckBox(
                                      e.target.checked ? true : false,
                                      item.requried
                                    )
                            }
                            disabled={
                              action === "edit" &&
                              responseStatus &&
                              disableEdit[item.requried]
                                ? true
                                : false
                            }
                            isMulti={false}
                          />
                        </Col>
                        {insertCol}
                      </React.Fragment>
                    );
                  } else if (item.type === "GoogleSearchLocation") {
                    return (
                      <React.Fragment key={item.label}>
                        <Col
                          md="5"
                          className={`d-flex align-items-start my-2 `}
                        >
                          <RenderGoogleLocation
                            label={item.label}
                            requried={item.defaultChecked}
                            name={item.name}
                            handleSelectLocation={
                              item.isMulti
                                ? handelSelectMulti
                                : handleSelectLocation
                            }
                            cityValue={initialValues["location"]}
                            value={
                              item.isMulti
                                ? multiLocationValue
                                : initialValues[item.name]
                            }
                            isMulti={item.isMulti}
                            isInvalid={!!error[item.name]}
                            errorMessage={error[item.name]}
                            placeholder={item.placeholder}
                            checkBoxValue={initialValues[item.requried]}
                            onChangeCheckBox={
                              item.defaultChecked
                                ? () => {}
                                : (e) =>
                                    handelChangeCheckBox(
                                      e.target.checked ? true : false,
                                      item.requried
                                    )
                            }
                            disabled={
                              action === "edit" &&
                              responseStatus &&
                              disableEdit[item.requried]
                                ? true
                                : false
                            }
                            multiselectValue={
                              initialValues?.otherInterestedLocation
                                ? initialValues?.otherInterestedLocation
                                : []
                            }
                            selectMultipleValue={onSelectMultiValue}
                            handleBlur={() =>
                              handelCheckLocationError(
                                item.name,
                                item.isMulti
                                  ? multiLocationValue
                                  : initialValues[item.name]
                              )
                            }
                          />
                        </Col>
                        {insertCol}
                      </React.Fragment>
                    );
                  } else if (item.type === "textArea") {
                    return (
                      <React.Fragment key={item.label}>
                        <Col md="5" className="d-flex align-items-start my-2">
                          <RenderTextArea
                            value={initialValues[item.name]}
                            name={item.name}
                            label={item.label}
                            onChange={(e) =>
                              handelChangeInputs(e.target.value, item.name)
                            }
                            isInvalid={!!error[item.name]}
                            errorMessage={error[item.name]}
                            type={item.type}
                            onChangeCheckBox={
                              item.defaultChecked
                                ? () => {}
                                : (e) =>
                                    handelChangeCheckBox(
                                      e.target.checked ? true : false,
                                      item.requried
                                    )
                            }
                            checkBoxValue={initialValues[item.requried]}
                            placeholder={item.placeholder}
                            disabled={
                              defaultDisabledField.includes(item.name)
                                ? true
                                : action === "edit" &&
                                  responseStatus &&
                                  disableEdit[item.requried]
                                ? true
                                : false
                            }
                            requried={item.defaultChecked}
                            checboxDisabled={
                              action === "edit" &&
                              responseStatus &&
                              disableEdit[item.requried]
                                ? true
                                : false
                            }
                          />
                        </Col>
                        {insertCol}
                      </React.Fragment>
                    );
                  }

                  return null;
                })}
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
