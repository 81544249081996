import { useEffect, useRef, useState } from "react";
import "./style.scss";
import { FaChevronLeft } from "react-icons/fa";
// import Buttons from "../../components/button/buttons";
import Buttons from "../../components/Form/Button";
// import { IoChevronBackOutline } from "react-icons/io5";

import { useNavigate, useLocation } from "react-router-dom";
import PostPropertyDetailForm from "./PostDemandForms/postDemandDetail";
import PostDemandProgress from "../../components/progress/postDemand";
import PostPropertyAddtionalDetail from "./PostDemandForms/postDemandAddtional";
import PostPropertyAmenities from "./PostDemandForms/postDemandAmenities";
import PostPropertyTransction from "./PostDemandForms/postDemandTransaction";
import PostPropertyDocuments from "./PostDemandForms/postDemandDocuments";
import {
  checkValidationCoworkSpace,
  checkValidationOfficeSpace,
  checkValidationRetail,
} from "./postValidation";
import { postGetData, getData } from "../../api/index";
import Loader from "../../components/Loader/index";
import { WarningMessage, InfoMessage } from "./warning";
import { coworkInitialData, fieldInitialValue } from "./option";
import FormModal from "../../components/Modal/index";
import { useSelector } from "react-redux";
import { AdminWarningPopup } from "../Admin/adminPopup";
import { requiredDocuments } from "./PostDemandForms/dropDownValues";
import PostCoworkFormats from "./PostDemandForms/postCoworkFormats";
import {
  convertedArrayIntoObjects,
  stringTolabelValue,
} from "../../utilities/commonFunction";
import { PHYSICALTEAM, PROPVANTAGE } from "../../custom/constants";
import { userBaseOpions, UserBaseOpionsDemand } from "../../components/DropdownOptions";

export default function PostDemand() {
  const [step, setStep] = useState();
  const [stepValues, setStepValues] = useState({});
  const [stepDisableValues, setDisableValues] = useState({});
  const [stepError, setStepError] = useState({});
  const [demandId, setDemandId] = useState("");
  const [usersTransactionType, setUsersTransactionType] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [warning, setWarning] = useState(false);
  const [warningOnce, setWarningOnce] = useState(true);
  const [infoOnce, setInfoOnce] = useState(false);
  const [apiErrorData, setApiErrorData] = useState("");
  const [infoMessage, setInfoMessage] = useState(false);
  const [propertyType, setPropertyType] = useState({ _id: "", name: "" });
  const [initialformArray, setinitalFormArray] = useState([]);
  const [actionType, setActionType] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [cities, setCities] = useState([]);
  const [propertyTypeList, setPropertyTypeList] = useState([]);
  const [showBrandName, setShowBrandName] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);
  const [changed, setChanged] = useState(false);
  const [transactionOptions, setTransactionOptions] = useState([]);
  const [coworkFormatData, setCoworkFormatData] = useState([]);
  const [initCoworkFormatData, setInitCoworkFormatData] = useState([]);
  const demandFormatDetailDivRef = useRef(null);
  const demandDetailDivRef = useRef(null);
  const demandAdditionalDetailDivRef = useRef(null);
  const demandAmenitiesDivRef = useRef(null);

  const { parentPage: reportuser, activeSlug } = useSelector(
    (state) => state.login
  );

  let action = location?.state?.action;
  const page = location?.state?.page ? location.state.page : "";
  let brandName = location?.state?.brandName;
  let isAgent = location?.state?.isAgent;
  let enterpriseAgent = location?.state?.enterpriseAgent;
  let physicalTeam = location?.state?.physicalTeam;
  let userId = location?.state?.userId;
  const brandId = location?.state?.brandId;
  let companyID = useSelector((state) => state.login.companyId);

  const getPropertyType = async (id) => {
    // setIsLoading(true);
    let url = `/cityZones/checkZoneAvailability/${isAgent ? userId : id}`;
    const response = await getData(url, {});
    const { status, data } = response;
    if (status === true) {
      setPropertyTypeList(data?.propertyType);
      setShowBrandName(
        isAgent || enterpriseAgent || physicalTeam
          ? true
          : data?.brandNameRequired
      );
      setUsersTransactionType(
        convertedArrayIntoObjects(data?.transactionType) || []
      );
      if (isAgent) setCities(data?.city);
    } else {
      setPropertyTypeList([]);
    }
  };

  const checkZoneAvailablity = async (pocId) => {
    setIsLoading(true);
    let url = `/brand/poc/getcities/${brandId}/${pocId}`;
    const res = await getData(url, {});
    const { statusCode } = res;
    if (statusCode === 200) {
      setIsLoading(false);
      setCities(res?.data.city);
    } else {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (action) {
      setActionType(action);
    }
  }, [action]);

  useEffect(() => {
    if (!isAgent) {
      if (stepValues?.userId?._id && brandId) {
        checkZoneAvailablity(stepValues?.userId?._id);
      } else {
        setCities([]);
      }
    }
  }, [stepValues?.userId?._id]);

  const handelcloseWarnig = () => {
    setWarning(!warning);
  };
  const handelcloseInfo = () => {
    setInfoMessage(!infoMessage);
  };

  const cleanCoworkData = (data) => {
    const copyCoworkData = { ...data };
    //Dedicated Desk
    delete copyCoworkData["dedicatedDesk"];
    delete copyCoworkData["dedicatedDeskMax"];
    delete copyCoworkData["dedicatedDeskMin"];
    delete copyCoworkData["dedicatedDeskRequired"];

    //Flexi Desk
    delete copyCoworkData["FlexiDeskMax"];
    delete copyCoworkData["FlexiDeskMin"];
    delete copyCoworkData["flexiDesk"];
    delete copyCoworkData["flexiDeskRequired"];

    //Meeting Room
    delete copyCoworkData["meetingRoom"];
    delete copyCoworkData["meetingRoomMax"];
    delete copyCoworkData["meetingRoomMin"];
    delete copyCoworkData["meetingRoomRequired"];

    //Managed Offices
    delete copyCoworkData["managedOffices"];
    delete copyCoworkData["managedOfficesMax"];
    delete copyCoworkData["managedOfficesMin"];
    delete copyCoworkData["managedOfficesRequired"];

    //Cabins
    delete copyCoworkData["cabins"];
    delete copyCoworkData["cabinsMax"];
    delete copyCoworkData["cabinsMin"];
    delete copyCoworkData["cabinsRequired"];

    delete copyCoworkData["requiredDocuments"];

    if (copyCoworkData?.bikeParking === "Yes") {
      copyCoworkData.bikeParking = true;
    } else if (copyCoworkData?.bikeParking === "No") {
      copyCoworkData.bikeParking = false;
    } else {
      copyCoworkData.bikeParking = null;
    }
    if (copyCoworkData?.carParking === "Yes") {
      copyCoworkData.carParking = true;
    } else if (copyCoworkData?.carParking === "No") {
      copyCoworkData.carParking = false;
    } else {
      copyCoworkData.carParking = null;
    }

    if (
      copyCoworkData?.additionalDemandDetails === "" ||
      copyCoworkData?.additionalDemandDetails === undefined
    ) {
      copyCoworkData["additionalDemandDetails"] = null;
    }

    if (
      copyCoworkData?.pincode === "" ||
      copyCoworkData?.pincode === undefined
    ) {
      delete copyCoworkData["pincode"];
    }
    return copyCoworkData;
  };

  const submitCoworkDemand = async () => {
    const getCleanedData = cleanCoworkData(stepValues);
    const filtredFormatData = coworkFormatData.filter((item) => item.noOfSeat);
    if (actionType === "edit") {
      var formatedFormatData = filtredFormatData.map((data) => {
        return { ...data, _id: data?._id?._id };
      });
    } else {
      filtredFormatData.forEach((data) => {
        delete data["name"];
      });
      var formatedFormatData = filtredFormatData;
    }
    let url = "/demand/createCoWorkingDemand";
    let datas = {
      step: 5,
      ...getCleanedData,
      format: [...formatedFormatData],
    };

    if ("propertyType" in datas) {
      datas.propertyType = propertyType._id;
    }

    if ("brandName" in datas && brandName && brandName !== "") {
      datas.brandName = brandName;
    } else {
      delete datas["brandName"];
    }
    datas = {
      ...datas,
      brandId: datas?.brandId?._id || brandId,
      demandType: isAgent
        ? "agent"
        : enterpriseAgent
        ? "eagent"
        : physicalTeam
        ? PROPVANTAGE
        : "brand",
    };

    if (demandId) {
      datas = { ...datas, demandId };
    }
    if (physicalTeam) {
      if (datas["userAccessibility"]?.length) {
        datas.userAccessibility = datas.userAccessibility?.map(
          (item) => item?.value
        );
      } else {
        datas.userAccessibility = UserBaseOpionsDemand?.map((item) => item?.value);
      }
    }
    if (localStorage.getItem("demandId")) {
      datas = {
        ...datas,
        demandId: JSON.parse(localStorage.getItem("demandId")),
      };
    }
    delete datas["response"];
    if (isAgent) {
      datas = { ...datas, userId: { _id: brandId }, brandId: userId };
    }
    setSubmitLoading(true);
    const reponse = await postGetData(url, {}, datas);
    const { data, message, statusCode } = reponse;
    if (statusCode === 200) {
      setSubmitLoading(false);
      // if (
      //   (JSON.parse(localStorage.getItem("postDemandStep")) === 5 &&
      //     propertyType?.name === "Retail") ||
      //   (JSON.parse(localStorage.getItem("postDemandStep")) === 3 &&
      //     propertyType?.name === "Office space")
      // ) {
      localStorage.removeItem("postDemandStep");
      localStorage.removeItem("checkedDemand");
      localStorage.removeItem("brandData");
      localStorage.removeItem("propertyType");
      // localStorage.removeItem('demandId')
      setOpenModal(true);
      // } else {
      //   setDemandId(data?.DemandId);
      //   localStorage.setItem("postDemandStep", JSON.stringify(step + 1));
      //   setStep((pre) => pre + 1);
      //   localStorage.setItem("demandId", JSON.stringify(data?.DemandId));
      // }
    } else if (statusCode === 400 || statusCode === 422) {
      setSubmitLoading(false);
      setApiErrorData(message);
    } else {
      setSubmitLoading(false);
    }
  };

  const submitDemand = async () => {
    setApiErrorData("");
    let url = "/demand/addDemand";

    let datas = {
      step: propertyType?.name === "Retail" ? 5 : 3,
      fitOutsStatus: null,
      toiletStatus: null,
      parkingStatus: null,
      mezzainineStatus: null,
      ...stepValues,
    };
    delete datas["response"];

    if ("endUse" in datas) {
      if (typeof datas.endUse === "object") {
        datas.endUse = datas.endUse._id;
      }
    }

    if ("propertyType" in datas) {
      datas.propertyType = propertyType._id;
    }
    if ("brandName" in datas) {
      datas.brandName = brandName ? brandName : "";
    }

    // if (step === 1 || step === 5) {
    datas = {
      ...datas,
      brandId: datas?.brandId?._id || brandId,
      demandType: isAgent
        ? "agent"
        : enterpriseAgent
        ? "eagent"
        : physicalTeam
        ? PROPVANTAGE
        : "brand",
    };
    // }

    if (demandId) {
      datas = { ...datas, demandId };
    }
    if (physicalTeam) {
      if (datas["userAccessibility"]?.length) {
        datas.userAccessibility = datas.userAccessibility?.map(
          (item) => item?.value
        );
      } else {
        datas.userAccessibility = UserBaseOpionsDemand?.map((item) => item?.value);
      }
    }

    if (isAgent) {
      datas = {
        ...datas,
        userId: { _id: brandId, name: brandName },
        brandId: userId,
      };
    }
    if (localStorage.getItem("demandId")) {
      datas = {
        ...datas,
        demandId: JSON.parse(localStorage.getItem("demandId")),
      };
    }
    requiredDocuments.map((item) => {
      datas[item.value] = false;
    });
    if (datas?.requiredDocuments?.length > 0) {
      let object = {};
      datas.requiredDocuments.forEach((item) => {
        object[item.value] = true;
      });
      datas = { ...datas, ...object };
      delete datas.requiredDocuments;
    } else {
      delete datas.requiredDocuments;
    }

    // return
    Object.keys(datas).forEach((key) => {
      if (
        [
          "fitOutsStatus",
          "toiletStatus",
          "parkingStatus",
          "mezzainineStatus",
          "bikeParking",
          "washRoom",
          "carParking",
        ].includes(key)
      ) {
        let a = { Yes: true, No: false };
        // if (datas[key] === "Yes") {
        //   datas[key] = true
        //   return
        // }
        // if (datas[key] === "No") {
        //   datas[key] = false
        //   return
        // }
        // if (datas[key] === "") {
        //   // console.debug({keyyyyyyyyy:stepValues[key]})
        //   datas[key] = null
        //   return
        // }
        datas[key] = datas[key] ? a[datas[key]] : null;
      }
    });

    // if (stepValues.bikeParking) {
    //   datas = {
    //     ...datas,
    //     bikeParking: stepValues.bikeParking === "Yes" ? true : false,
    //   }
    // }
    // if (stepValues.carParking) {
    //   datas = {
    //     ...datas,
    //     carParking: stepValues.carParking === "Yes" ? true : false,
    //   }
    // }
    // if (stepValues.washRoom) {
    //   datas = {
    //     ...datas,
    //     washRoom: stepValues.washRoom === "Yes" ? true : false,
    //   }
    // }
    // console.log(datas, "datas")

    Object.keys(datas).forEach(
      (k) => String(datas[k]).length === 0 && delete datas[k]
    );
    if ("otherAmenities" in datas) {
      datas = { ...datas, otherAmenities: datas.otherAmenities };
    } else {
      datas = { ...datas, otherAmenities: [] };
    }
    setSubmitLoading(true);
    const reponse = await postGetData(url, {}, datas);
    const { data, message, statusCode } = reponse;
    if (statusCode === 200) {
      setSubmitLoading(false);
      // if (
      //   (JSON.parse(localStorage.getItem("postDemandStep")) === 5 &&
      //     propertyType?.name === "Retail") ||
      //   (JSON.parse(localStorage.getItem("postDemandStep")) === 3 &&
      //     propertyType?.name === "Office space")
      // ) {
      localStorage.removeItem("postDemandStep");
      localStorage.removeItem("checkedDemand");
      localStorage.removeItem("brandData");
      localStorage.removeItem("propertyType");
      setOpenModal(true);
      // } else {
      //   setDemandId(data?.DemandId);
      //   localStorage.setItem("postDemandStep", JSON.stringify(step + 1));
      //   setStep((pre) => pre + 1);
      //   localStorage.setItem("demandId", JSON.stringify(data?.DemandId));
      // }
    } else if (statusCode === 400 || statusCode === 422) {
      setSubmitLoading(false);
      setApiErrorData(message);
    } else {
      setSubmitLoading(false);
    }
  };

  const createDemandId = async () => {
    const { data, statusCode } = await postGetData(
      "/demand/addDemand",
      {},
      { brandId: isAgent ? userId : brandId, step: 1 }
    );
    if (statusCode == 200) {
      localStorage.setItem("demandId", JSON.stringify(data?.DemandId));
    } else {
      navigate("/");
    }
  };

  const handelSubmitForm = (e) => {
    e.preventDefault();
    let datas = {
      step: propertyType?.name === "Retail" ? 5 : 3,
      ...stepValues,
    };
    if ("propertyType" in datas) {
      datas.propertyType = propertyType._id;
    }
    // let valid = {};
    if (propertyType?.name === "Office space") {
      var { valid, primaryDetailError, additonalDetailError } =
        checkValidationOfficeSpace(datas, isAgent);
    } else if (propertyType?.name === "Retail") {
      var { valid, primaryDetailError, additonalDetailError, amenitiesError } =
        checkValidationRetail(datas, isAgent);
    } else if (propertyType?.name === "Flex Space") {
      var { valid, formatDataError, primaryDetailError } =
        checkValidationCoworkSpace(datas);
    }

    if (physicalTeam && !datas?.userAccessibility?.length) {
      valid.userAccessibility = "User Accessibility is required";
    }
    // console.log({ datas });
    // console.log({userAccessibility: datas?.userAccessibility });
    // console.log({ valid });
    // console.log({ formatDataError });
    // console.log({ primaryDetailError });
    setStepError(valid);
    let NoError = Object.values(valid).every((v) => v.length === 0);
    if (NoError) {
      propertyType?.name === "Flex Space"
        ? submitCoworkDemand()
        : submitDemand();
    } else {
      if (propertyType?.name === "Flex Space") {
        if (primaryDetailError) {
          scrollToSection(demandDetailDivRef, "start");
        } else if (formatDataError) {
          scrollToSection(demandFormatDetailDivRef, "center");
        }
      }

      if (propertyType?.name === "Retail") {
        if (primaryDetailError) {
          scrollToSection(demandDetailDivRef, "start");
        } else if (additonalDetailError) {
          scrollToSection(demandAdditionalDetailDivRef, "center");
        } else if (amenitiesError) {
          scrollToSection(demandAmenitiesDivRef, "center");
        }
      }

      if (propertyType?.name === "Office space") {
        if (primaryDetailError) {
          scrollToSection(demandDetailDivRef, "start");
        } else if (additonalDetailError) {
          scrollToSection(demandAdditionalDetailDivRef, "center");
        }
      }
    }
  };

  const scrollToSection = (sectionRef, from) => {
    if (sectionRef && sectionRef.current) {
      sectionRef.current.scrollIntoView({
        behavior: "smooth",
        block: from,
        inline: "nearest",
      });
    }
  };

  const onChangeValue = (value, name, checked) => {
    setChanged(true);
    delete stepError[name];
    setStepError(stepError);
    let checkedValue = [];
    let preData = JSON.parse(localStorage.getItem("checkedDemand"));

    if (preData) {
      checkedValue = [...checkedValue, ...preData];
    }

    if (value?.toString() === "true") {
      checkedValue.push(name);
      localStorage.setItem("checkedDemand", JSON.stringify(checkedValue));
    } else if (value?.toString() === "false") {
      const isUncheckRemove = checkedValue.filter((item) => item !== name);
      localStorage.setItem("checkedDemand", JSON.stringify(isUncheckRemove));
    }

    if (name === "userId") {
      setStepValues((pre) => {
        return {
          ...pre,
          location: "",
          otherInterestedLocation: [],
          microLocation: { name: "", _id: "" },
          [name]: value,
        };
      });
    } else {
      setStepValues((pre) => {
        return { ...pre, [name]: value };
      });
    }

    let localStorageValue = JSON.parse(localStorage.getItem("checkedDemand"));

    if (localStorageValue.length > 19 && checked !== "checked") {
      if (warningOnce) {
        setWarning(true);
        setWarningOnce(false);
      }
    } else {
      setWarningOnce(true);
    }

    if (localStorageValue.length === 8 && !infoOnce && checked !== "checked") {
      setInfoMessage(true);
      setInfoOnce(true);
    }
  };

  const stepfilteredData = (constData = {}, serverData = {}) => {
    let staticData = constData;
    // console.log(constData,serverData)
    const selectedData = Object.keys(staticData);
    const filteredData = Object.keys(serverData)
      .filter((key) => selectedData.includes(key))
      .reduce((obj, key) => {
        obj[key] = serverData[key];
        if (
          serverData[key] === true &&
          (key === "bikeParking" ||
            key === "carParking" ||
            key === "washRoom" ||
            key === "fitOutsStatus" ||
            key === "toiletStatus" ||
            key === "mezzainineStatus" ||
            key === "parkingStatus")
        ) {
          obj[key] = "Yes";
        }
        if (
          serverData[key] === false &&
          (key === "bikeParking" ||
            key === "carParking" ||
            key === "washRoom" ||
            key === "fitOutsStatus" ||
            key === "toiletStatus" ||
            key === "mezzainineStatus" ||
            key === "parkingStatus")
        ) {
          obj[key] = "No";
        }
        return obj;
      }, {});

    if (propertyType.name === "Flex Space") {
      setInitCoworkFormatData(serverData.format);
      const formatData = serverData?.format
        .map((data) => {
          const filteredData = coworkInitialData
            .map((codata) => {
              if (
                codata?.id === data?._id?.name &&
                data?._id?.name === "Dedicated Desk"
              ) {
                return {
                  dedicatedDesk: data?.noOfSeat ? data?.noOfSeat : "",
                  dedicatedDeskMax: data?.budgetMax ? data?.budgetMax : "",
                  dedicatedDeskMin: data?.budgetMin ? data?.budgetMin : "",
                  dedicatedDeskRequired: data?.isRequired
                    ? data?.isRequired
                    : false,
                };
              }
              if (
                codata?.id === data?._id?.name &&
                data?._id?.name === "Flexi Desk"
              ) {
                return {
                  flexiDesk: data?.noOfSeat ? data?.noOfSeat : "",
                  FlexiDeskMax: data?.budgetMax ? data?.budgetMax : "",
                  FlexiDeskMin: data?.budgetMin ? data?.budgetMin : "",
                  flexiDeskRequired: data?.isRequired
                    ? data?.isRequired
                    : false,
                };
              }
              if (
                codata?.id === data?._id?.name &&
                data?._id?.name === "Meeting Room"
              ) {
                return {
                  meetingRoom: data?.noOfSeat ? data?.noOfSeat : "",
                  meetingRoomMax: data?.budgetMax ? data?.budgetMax : "",
                  meetingRoomMin: data?.budgetMin ? data?.budgetMin : "",
                  meetingRoomRequired: data?.isRequired
                    ? data?.isRequired
                    : false,
                };
              }
              if (
                codata?.id === data?._id?.name &&
                data?._id?.name === "Managed Office"
              ) {
                return {
                  managedOffices: data?.noOfSeat ? data?.noOfSeat : "",
                  managedOfficesMax: data?.budgetMax ? data?.budgetMax : "",
                  managedOfficesMin: data?.budgetMin ? data?.budgetMin : "",
                  managedOfficesRequired: data?.isRequired
                    ? data?.isRequired
                    : false,
                };
              }
              if (
                codata?.id === data?._id?.name &&
                data?._id?.name === "Cabins"
              ) {
                return {
                  cabins: data?.noOfSeat ? data?.noOfSeat : "",
                  cabinsMax: data?.budgetMax ? data?.budgetMax : "",
                  cabinsMin: data?.budgetMin ? data?.budgetMin : "",
                  cabinsRequired: data?.isRequired ? data?.isRequired : false,
                };
              }
              return null;
            })
            .filter((obj) => obj !== null);
          return { ...filteredData[0] };
        })
        .filter((obj) => obj !== null);
      var singleObject = formatData.reduce(
        (acc, curr) => ({ ...acc, ...curr }),
        {}
      );
    }
    return propertyType.name === "Flex Space"
      ? { ...filteredData, ...singleObject }
      : filteredData;
  };

  const setcheckedData = (data) => {
    let keyarr = Object.keys(data);
    const finalValueArr = keyarr.filter((x) => {
      return String(data[`${x}`]) === "true";
    });
    localStorage.setItem("checkedDemand", JSON.stringify([...finalValueArr]));
  };

  useEffect(() => {
    if (brandId) {
      getPropertyType(brandId);
    }
    if (
      localStorage.getItem("propertyType") !== undefined &&
      localStorage.getItem("propertyType")
    ) {
      let type = localStorage.getItem("propertyType");
      if (type) {
        try {
          type = JSON.parse(type);
        } catch (e) {
          // error in the above string (in this case, yes)!
        }
      }
      setPropertyType(type);
    }
  }, []);
  useEffect(() => {
    let demandStep = JSON.parse(localStorage.getItem("postDemandStep"))
      ? JSON.parse(localStorage.getItem("postDemandStep"))
      : 1;
    setStep(demandStep);
  }, []);
  const getAllData = async (formValue) => {
    setIsLoading(true);
    let url = `/demand/getDemand/${JSON.parse(
      localStorage.getItem("demandId")
    )}`;
    const getList = await getData(url, {}, {});
    const { statusCode, data } = getList;
    if (statusCode === 200) {
      setIsLoading(false);
      setcheckedData(data);
      let finalValue;
      finalValue = stepfilteredData(formValue, data);
      if (physicalTeam) {
        finalValue = { ...finalValue };
        const userAccessibilityArr = stringTolabelValue(
          data?.userAccessibility
        );
        finalValue = { ...finalValue, userAccessibility: userAccessibilityArr };
      }
      setStepValues({
        ...formValue,
        ...finalValue,
        requiredDocuments:
          requiredDocuments.filter((key) => data[key.value] == true) || [],
        documentsRequired: data?.documentsRequired
          ? data?.documentsRequired
          : false,
      });
      setDisableValues({
        ...formValue,
        ...finalValue,
        requiredDocuments:
          requiredDocuments.filter((key) => data[key.value] == true) || [],
        documentsRequired: data?.documentsRequired
          ? data?.documentsRequired
          : false,
      });
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (propertyType?.name) {
      setDemandId(JSON.parse(localStorage.getItem("demandId")));
      localStorage.setItem(
        "checkedDemand",
        JSON.stringify([
          "propertyTypeRequired",
          "locationRequired",
          "microLocationRequired",
          "carpetAreaRequired",
          "otherInterestedLocationRequired",
          "endUseRequired",
          "possessionFromRequired",
        ])
      );

      let filteredArray = fieldInitialValue.filter(
        (item) => item.key === propertyType?.name
      );

      let typeOptions =
        filteredArray[0]?.value[0]?.fieldArray?.filter(
          (data) => data.name == "transactionType"
        )[0]?.option || [];
      setTransactionOptions(typeOptions);

      if (isAgent) {
        let selectPocRemoved = filteredArray[0]?.value[0]?.fieldArray?.filter(
          (data) => {
            return data.label !== "Select POC";
          }
        );
        let filtered = filteredArray[0]?.value.map((data, index) => {
          if (index === 0) {
            return { ...data, fieldArray: selectPocRemoved };
          } else {
            return data;
          }
        });
        filteredArray = filteredArray.map((filterOption, filterOptionIndex) => {
          if (filterOptionIndex === 0) {
            return { ...filterOption, value: filtered };
          }
          return filterOption;
        });
      }
      // if (stepValues.transactionType) {
      setinitalFormArray(filteredArray);
      // }

      let setpDetails = filteredArray[0]?.value?.filter(
        (item) => item.step === 1
      );
      setStepValues(setpDetails[0]?.intialValue);
      setDisableValues(setpDetails[0]?.intialValue);
      if (
        JSON.parse(localStorage.getItem("demandId")) !== undefined &&
        JSON.parse(localStorage.getItem("demandId"))
      ) {
        getAllData(setpDetails[0]?.intialValue);
      } else {
        createDemandId();
      }
    } else {
      setStepValues({});
      setDisableValues({});
      setinitalFormArray([]);
      setStepError({});
    }
  }, [propertyType, step]);

  const handlePreviousClick = () => {
    getAllData(); // get data of previous Data
    localStorage.setItem("postDemandStep", JSON.stringify(step - 1));
    setStep((pre) => pre - 1);
  };

  const detailView = () => {
    return (
      <>
        {/* {propertyType?.name === "Retail" && step === 5 ? (
          <div className="title">Submit</div>
        ) : propertyType?.name === "Office space" && step === 3 ? (
          <div className="title">Submit</div>
        ) : null} */}
        <div className="title">Primary Details</div>
        <div className="form">
          {
            <PostPropertyDetailForm
              fields={
                initialformArray.length > 0
                  ? initialformArray[0]?.value[0]?.fieldArray
                  : []
              }
              initialValues={stepValues}
              onChangeInput={onChangeValue}
              error={stepError}
              transactionOptions={transactionOptions}
              disableEdit={stepDisableValues}
              action={actionType === "edit" ? "edit" : "add"}
              setStepError={setStepError}
              responseStatus={stepValues?.response}
              propertyType={propertyType}
              propertyTypeList={propertyTypeList}
              setPropertyType={setPropertyType}
              brand={brandName}
              step={step}
              cityList={cities}
              showBrandName={showBrandName}
              demandDetailDivRef={demandDetailDivRef}
              isAgent={isAgent}
              enterpriseAgent={enterpriseAgent}
              physicalTeam={physicalTeam}
              usersTransactionType={usersTransactionType}
            />
          }
        </div>
      </>
    );
  };
  const additionalDetail = () => {
    return (
      <>
        {initialformArray?.length > 0 && (
          <>
            <div className="title">
              Additional Details
              {propertyType?.name === "Flex Space" && (
                <div className=" text-muted noteText">{`( Making fields mandatory will result in fewer property submission )`}</div>
              )}
            </div>
            <div className="form">
              <PostPropertyAddtionalDetail
                fields={
                  initialformArray.length > 0
                    ? initialformArray[0]?.value[1]?.fieldArray
                    : []
                }
                initialValues={stepValues}
                onChangeInput={onChangeValue}
                error={stepError}
                disableEdit={stepDisableValues}
                action={actionType === "edit" ? "edit" : "add"}
                responseStatus={stepValues?.response}
                propertyType={propertyType}
                demandAdditionalDetailDivRef={demandAdditionalDetailDivRef}
              />
            </div>
          </>
        )}
      </>
    );
  };

  const showSubmitBtn = () => {
    return (
      <div className="button-wrapper m-4">
        <Buttons
          type="Submit"
          className="btn-outline next-btn"
          onClick={handelSubmitForm}
          loading={submitLoading}
        >
          Submit
        </Buttons>
      </div>
    );
  };

  const showNextBtn = () => {
    return (
      <div className="button-wrapper m-4">
        <Buttons
          type="submit"
          name="Next"
          className="btn-color-primary next-btn"
          onClick={handelSubmitForm}
          loading={submitLoading}
        >
          Next
        </Buttons>
      </div>
    );
  };

  const onClickContinue = () => {
    setOpenModal(false);
    if (page === "demands") {
      navigate("/demand");
    } else if (page === "reports") {
      navigate("/buyerReport/demandList");
    } else if (page === "agentReport") {
      navigate("/agentReport/demandList");
    } else if (page === "enterpriseAgentReport") {
      navigate("/enterpriseAgentReport/demandList");
    } else if (page === "physicalTeamReport") {
      navigate("/physicalTeamReport/demandList");
    } else if (page === "reportsCompany") {
      navigate("/buyerReport/companyList/brandCompanyCreation/brandPage");
    } else if (page === "reportsCustomer") {
      navigate("/buyerReport/brandList/brandPage");
    } else if (isAgent) {
      navigate(
        companyID === ""
          ? `/customer/agentPage/${activeSlug}`
          : "/company/brandCompanyCreation/agentPage"
      );
    } else if (enterpriseAgent) {
      navigate(
        companyID === ""
          ? `/company/enterprise-agent/${activeSlug}`
          : "/company/enterpriseAgentCompanyCreation/agentPage"
      );
    } else if (physicalTeam) {
      localStorage.removeItem("demandId");
      navigate(
        companyID === ""
          ? `/company/physicalTeamPage/${activeSlug}`
          : "/company/agentCompanyCreation/agentPage"
      );
    } else {
      navigate(
        companyID === ""
          ? `/customer/brandPage/${activeSlug}`
          : "/company/brandCompanyCreation/brandPage"
      );
    }
  };

  const SubmittedContent = () => {
    return (
      <>
        <div>
          <span>Your demand has been Sucessfully posted</span>
        </div>
        <div className="btn-container">
          <Buttons className={"btn-color-primary"} onClick={onClickContinue}>
            Got It
          </Buttons>
        </div>
      </>
    );
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter" && event.target.tagName !== "TEXTAREA") {
      event.preventDefault();
      handelSubmitForm(event);
    }
  };
  useEffect(() => {
    if (
      step &&
      ((propertyType?.name === "Retail" && step === 5) ||
        (propertyType?.name === "Office space" && step === 3))
    ) {
      document.addEventListener("keydown", handleKeyPress);
      return () => {
        document.removeEventListener("keydown", handleKeyPress);
      };
    }
  }, [step, demandId, propertyType?.name, stepValues]);
  return (
    <>
      {openModal && (
        <FormModal
          show={openModal}
          onClose={onClickContinue}
          heading={"Demand  Submitted"}
          customer={false}
          children={SubmittedContent()}
          size={"md"}
        />
      )}
      <div
        className="p-2 "
        style={{
          background: "#E5E5E5",
        }}
      >
        <div className="p-2">
          <div className="py-3 d-flex align-items-center">
            <FaChevronLeft
              style={{
                cursor: "pointer",
                color: "#000000",
                marginRight: "10px",
              }}
              onClick={(e) => navigate(-1)}
            />
            <div className="page-title text-heading mb-0">
              {actionType === "edit" ? "Edit demand" : "Post a Demand "}
            </div>
          </div>
          <div className=" postDemand-container">
            <div className="p-4  postDemand-form-container">
              {/* {
                <PostDemandProgress
                  step={step}
                  type={propertyType?.name ? propertyType?.name : ""}
                />
              } */}
              {!isLoading && (
                <form onKeyDown={handleKeyPress}>
                  <div
                    className="demand-conatainer post-new-demand"
                    style={{
                      background: "white",
                    }}
                  >
                    {/* {propertyType?.name === "Retail" &&
                      (step === 1 || step === 5)
                      ? detailView()
                      : propertyType?.name === "Office space" &&
                        (step === 1 || step === 3)
                        ? detailView()
                        : step === 1
                          ? detailView()
                          : null} */}
                    {detailView()}
                    {/* {propertyType?.name === "Retail" &&
                      (step === 2 || step === 5)
                      ? additionalDetail()
                      : propertyType?.name === "Office space" &&
                        (step === 2 || step === 3)
                        ? additionalDetail()
                        : null} */}

                    {propertyType?.name === "Flex Space" && (
                      <>
                        <div className="title ">
                          <span>Flex Space Formats</span>
                          <div className=" text-muted noteText">{`( Note: Select at least one )`}</div>
                        </div>
                        <div className="form">
                          <PostCoworkFormats
                            fields={
                              initialformArray.length > 0
                                ? initialformArray[0]?.value[2]?.fieldArray
                                : []
                            }
                            initialValues={stepValues}
                            onChangeInput={onChangeValue}
                            error={stepError}
                            disableEdit={stepDisableValues}
                            action={actionType === "edit" ? "edit" : "add"}
                            responseStatus={stepValues?.response}
                            propertyType={propertyType}
                            setCoworkFormatData={setCoworkFormatData}
                            coworkFormatData={coworkFormatData}
                            initCoworkFormatData={initCoworkFormatData}
                            demandFormatDetailDivRef={demandFormatDetailDivRef}
                          />
                        </div>
                      </>
                    )}
                    {additionalDetail()}

                    {propertyType?.name === "Retail" && (
                      <>
                        <div className="title">Amenities</div>
                        <div className="form">
                          <PostPropertyAmenities
                            fields={
                              initialformArray.length > 0
                                ? initialformArray[0]?.value[2]
                                    ?.fieldArrayAmenties
                                : []
                            }
                            initialValues={stepValues}
                            onChangeInput={onChangeValue}
                            error={stepError}
                            disableEdit={stepDisableValues}
                            action={actionType === "edit" ? "edit" : "add"}
                            responseStatus={stepValues?.response}
                            propertyType={propertyType}
                            demandAmenitiesDivRef={demandAmenitiesDivRef}
                          />
                        </div>

                        <div className="title">Transaction</div>
                        <div className="form">
                          <PostPropertyTransction
                            fields={
                              initialformArray.length > 0
                                ? initialformArray[0]?.value[2]
                                    ?.fieldTransaction
                                : []
                            }
                            initialValues={stepValues}
                            onChangeInput={onChangeValue}
                            error={stepError}
                            disableEdit={stepDisableValues}
                            action={actionType === "edit" ? "edit" : "add"}
                            responseStatus={stepValues?.response}
                            propertyType={propertyType}
                          />
                        </div>
                      </>
                    )}
                    {propertyType?.name &&
                      propertyType?.name !== "Flex Space" && (
                        <>
                          <div className="title">Documents</div>
                          {/* <div>
                            (Note: Making the documents mandatory will result in
                            fewer property submissions. You may ask for them at
                            a later stage)
                          </div> */}
                          <div className="form">
                            <PostPropertyDocuments
                              fields={
                                propertyType?.name == "Retail"
                                  ? initialformArray.length > 0
                                    ? initialformArray[0]?.value[3]?.fieldArray
                                    : []
                                  : initialformArray.length > 0
                                  ? initialformArray[0]?.value[2]?.fieldArray
                                  : []
                              }
                              initialValues={stepValues}
                              onChangeInput={onChangeValue}
                              error={stepError}
                              disableEdit={stepDisableValues}
                              action={actionType === "edit" ? "edit" : "add"}
                              responseStatus={stepValues?.response}
                              propertyType={propertyType}
                            />
                          </div>
                        </>
                      )}
                    {/* {step &&
                      ((step === 5 && propertyType?.name === "Retail") ||
                        (step === 3 && propertyType?.name === "Office space")) ? (
                      <div className="hidebody"></div>
                    ) : null} */}
                  </div>
                  <span className="error-message_span mb-3">
                    {apiErrorData}
                  </span>

                  {propertyType?.name && (
                    <div className="d-flex justify-content-end flex-column flex-sm-row align-items-center pb-5 post-btn btn-flex">
                      <div className="button-wrapper m-3">
                        <Buttons
                          type="button"
                          className="btn-outline-color-primary pre-btn"
                          onClick={() => {
                            if (changed) {
                              setOpenWarning(true);
                            } else {
                              navigate(-1);
                            }
                          }}
                        >
                          Cancel
                        </Buttons>
                      </div>
                      {showSubmitBtn()}
                    </div>
                  )}
                </form>
              )}
            </div>
          </div>
          {isLoading && <Loader className={"fixed"} />}
        </div>
      </div>

      {warning && <WarningMessage show={warning} onClose={handelcloseWarnig} />}
      {infoMessage && (
        <InfoMessage show={infoMessage} onClose={handelcloseInfo} />
      )}
      {openWarning && (
        <AdminWarningPopup
          title={"Confirmation"}
          show={openWarning}
          message={"Are you sure want to discard these changes ?"}
          warningYesClick={() => {
            setOpenWarning(false);
            navigate("/");
          }}
          onClose={() => {
            setOpenWarning(false);
          }}
        />
      )}
    </>
  );
}
