import React, { useEffect, useState } from "react";
import Button from "../../../components/Form/Button";
import MainTable from "../../../components/Table";
import { AddStatistics, AdminWarningPopup } from "./addStatistics";
import { FaPen, FaTrash } from "react-icons/fa";
import DNDKITMainTable from "../../../components/dndSortableTable";
import { deleteData, getData, handleDashboardNewResponse } from "../../../api";
import Loader from "../../../components/Loader";
import { AiFillEye } from "react-icons/ai";
import { BsEyeFill } from "react-icons/bs";

const Statistics = ({ access }) => {
  const [showStatistics, setShowStatistics] = useState(false);
  const [contentId, setContentId] = useState(null);
  const [action, setAction] = useState("Add");
  const [initLoading, setInitLoading] = useState(true);
  const [statisticsData, setStatisticsdata] = useState([]);
  const [statId, setStatId] = useState("");
  const [confirmation, setConfirmation] = useState(false);
  const [editValue, setEditValue] = useState({
    value: "",
    image: "",
    title: "",
  });
  const handelShowAddStats = () => {
    setShowStatistics(true);
    setAction("Add");
  };

  

  const getStatsLIst = async () => {
    setInitLoading(true);
    let url ="/contentMangementRevamp/getStatistics";
    const getStats = await getData(url, {});
    const { data, status } = getStats;
    if (status) {
      setInitLoading(false);
      setContentId(data?._id)
      setStatisticsdata(data?.statistics);
    } else {
      setInitLoading(false);
    }
  };
  const handelCloseAddStats = (value) => {
    setShowStatistics(false);
    if (value === "updated") {
        getStatsLIst();
    }
  };

  const handelShowEditStats = (row, action) => {
    console.log({row})
    setEditValue({
      title: row.title,
      image: row.image,
      fileName: row?.fileName,
      value: row.value,
      _id: row?._id,
      signedImage: row?.signedImage,
    });

    setShowStatistics(true);
    setAction(action);
  };

  const handelShowConfirmation = (id) => {
    setConfirmation(true);
    setStatId(id);
  };

  const handelCloseConfirmation = () => {
    setConfirmation(false);
  };

  const deleteStats = async () => {
    let url = `/contentMangementRevamp/deleteStatistics/${contentId}/${statId}`;
    const newData = await deleteData(url, {}, {});
    const { status } = newData;
    if (status) {
      getStatsLIst();
      handelCloseConfirmation();
    }
  };

  function userButtonFormatterBanner(cell, row, rowIndex) {
    return (
      <>
      <Button
          className="table-btn p-0"
          disabled={access?.edit ? false : true}
        >
          <BsEyeFill
            className="icon-pointer cursor-pointer"
            onClick={() => handelShowEditStats(row, "View")}
          />
        </Button>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Button
          className="table-btn p-0"
          disabled={access?.edit ? false : true}
        >
          <FaPen
            className="icon-pointer cursor-pointer"
            onClick={() => handelShowEditStats(row, "Edit")}
          />
        </Button>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Button
          className="table-btn p-0"
          disabled={access?.delete ? false : true}
        >
          <FaTrash
            className="icon-pointer cursor-pointer"
            onClick={() => handelShowConfirmation(row._id)}
          />
        </Button>
      </>
    );
  }

 

  const statisticsHeaderDetail = [
    {
      dataField: "action",
      text: "",
      showSortable: true,
    },
    {
      dataField: "sno",
      text: "S.no",
    },
    {
      dataField: "title",
      text: "Title",
    },
    {
      dataField: "value",
      text: "Value",
    },
    {
      dataField: "action",
      text: "Action",
      formatter: userButtonFormatterBanner,
    },
  ];

  const statisticsTableProps = {
    data: statisticsData,
    columns: statisticsHeaderDetail,
  };

  useEffect(() => {
    getStatsLIst()
  }, [])

  const handleStatSequence = async (data) => {
    const newData = data.map((item) => {
      return {...item}
    })
    newData.map((item) => {
      delete item["sno"]
      item.signedImage = ""
    })
    setInitLoading(true)
    const url = `/contentMangementRevamp/updateStatisticsSequence/${contentId}`
    const {status, statusCode} = await handleDashboardNewResponse(url, newData)
    if(status && statusCode === 200) {
      getStatsLIst()
    }else {
      setInitLoading(false)
    }
  }
  return (
    <div>
      <>
      {initLoading && <Loader className={"fixed"} />}
        <div className="d-flex justify-content-between align-items-center mt-5 mb-4 mobileFlexWrapper">
          <div className="brand_text">
            <p className="contentManagementText">Statistics</p>
          </div>
          <div>
            &nbsp;&nbsp;&nbsp;
            <Button
              className="btn-color-primary"
              disabled={
                statisticsData?.length >= 6 || !access?.add ? true : false
              }
              onClick={() => handelShowAddStats()}
            >
              Add Stat
            </Button>
            &nbsp;
          </div>
        </div>
        <DNDKITMainTable {...statisticsTableProps} handleStatSequence = {handleStatSequence}/>

        {showStatistics && (
          <AddStatistics
            label={action}
            onClose={handelCloseAddStats}
            show={showStatistics}
            editValue={editValue}
            activeContentID = {contentId}
          />
        )}
        {confirmation && (
        <AdminWarningPopup
          onClose={handelCloseConfirmation}
          show={true}
          message={"Are you sure you want delete this Stat?"}
          warningYesClick={() => deleteStats()}
        />
      )}
      </>
    </div>
  );
};

export default Statistics;
