import React, { useEffect, useState, useContext } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Form } from "react-bootstrap";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ReactPaginate from "react-paginate";
import { CSS } from "@dnd-kit/utilities";
import filterFactory from "react-bootstrap-table2-filter";
import { IoChevronBackOutline, IoChevronForwardOutline } from "react-icons/io5";
import {
  HiOutlineChevronDoubleLeft,
  HiOutlineChevronDoubleRight,
} from "react-icons/hi";
import "./style.scss";
import { AppContext } from "../../App";
import {
  getTableStoreValue,
  setTableFilterData,
} from "../../utilities/pageScroll";
import { TableRows } from "../../custom/constants";
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  DragOverlay
} from "@dnd-kit/core";
import { restrictToVerticalAxis, restrictToHorizontalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { SortableItem } from "./SortableItem";
import { DraggableOverlay } from "./DraggableOverlay";

export default function DNDKITMainTable(props) {
  const [tableData, setTableData] = useState([]);
  const [count, setCount] = useState(0);
  const [activeRow, setActiveRow] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const { tableStoreContext, setTableStoreContext } = useContext(AppContext);
  const currentTableValueFromConText = getTableStoreValue(
    props?.tableName,
    tableStoreContext
  );

  const tableName = currentTableValueFromConText?.tableName;
  const formatDatas = (initArr) => {
    let sn = 0;
    let filteredarray = [];
    initArr?.forEach((element) => {
      const offVal = (props.offset - 1) * props.perPage + (sn + 1);
      const offsetValue = props.offset ? offVal : sn + 1;

      filteredarray.push({
        ...element,
        sno: offsetValue,
      });
      sn++;
    });
    return filteredarray;
  };

  const handelPerPage = (value) => {
    props?.handelChangePerPage(value);

    if (props?.tableName) {
      let valueObject = {
        pageNo: props.offset,
        perPage: value,
      };
      let newValue = setTableFilterData(
        props?.tableName,
        valueObject,
        tableStoreContext
      );
      setTableStoreContext(newValue);
    }
  };

  const handelOffSet = (value) => {
    props?.setOffset(value);
    if (props.tableName === "clientele") {
      props.handlePageChange(value);
    }
    if (props?.tableName) {
      let valueObject = {
        pageNo: value,
        perPage: props.perPage,
      };

      let newValue = setTableFilterData(
        props?.tableName,
        valueObject,
        tableStoreContext
      );
      setTableStoreContext(newValue);
    }
  };

  useEffect(() => {
    setTableData(formatDatas(props.data));
  }, [props?.data]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      let newValue = setTableFilterData(
        props?.tableName,
        { id: "" },
        tableStoreContext
      );
      setTableStoreContext(newValue);
    }, 5000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

//   const style = {
//     transform: CSS.Transform.toString(transform),
//     transition: "transform 200ms ease",
//   };

  const rowStyle = (row, rowIndex) => {
    return row?._id === activeRow
      ? {
        //   transform: CSS.Transform.toString(transform),
          transition: "transform 200ms ease",
        }
      : {};
  };
  const rowClasses = (row) => {
    // const { isDragging } = useSortable({ id: row?._id });
    let checkForTableName = tableName === props?.tableName;
    let checkForuniqueId = checkForTableName
      ? props?.uniqueId
        ? row?.uniqueId === currentTableValueFromConText?.id
        : row._id === currentTableValueFromConText?.id
      : false;
    return checkForuniqueId
      ? "active-row"
      : props?.tableName === "RmDashboard"
      ? ""
      : activeRow === row?._id
      ? "dragging-row"
      : "";
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    setActiveItem(null);

    if (active.id !== over.id) {
      setTableData((items) => {
        const oldIndex = items.findIndex(item => item._id === active.id);
        const newIndex = items.findIndex(item => item._id === over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
      setCount((prev) => {
        return prev + 1
      })
    }

  };

  useEffect(() => {
    if(count > 0) {
      props?.handleStatSequence(tableData)
    }
  }, [count])

  const handleDragStart = (event) => {
    const { active } = event;
    const draggedItem = tableData.find(item => item._id === active.id);
    setActiveItem(draggedItem);
  };

  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const columns = props.columns.map((col, index) => {
    if (index === 0) {
      return {
        ...col,
        formatter: (cell, row, rowIndex, formatExtraData) => {
          return (
            <SortableItem
              key={row._id}
              id={row._id}
              setActiveRow={setActiveRow}
            >
              {col.formatter
                ? col.formatter(cell, row, rowIndex, formatExtraData)
                : cell}
            </SortableItem>
          );
        },
      };
    }
    return col;
  });

  return (
    <div className="table-border-radius-dnd-kit">
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
        modifiers={[restrictToVerticalAxis]}
      >
        <SortableContext
          items={tableData}
          strategy={verticalListSortingStrategy}
        >
          <BootstrapTable
            keyField={props?.uniqueId ? "uniqueId" : "_id"}
            bordered={false}
            data={tableData}
            noDataIndication={"No Data Found"}
            columns={columns}
            headerWrapperClasses="thead-dark"
            bodyClasses="tableBody"
            wrapperClasses="table-responsive customScroll"
            filter={filterFactory()}
            rowClasses={rowClasses}
            rowStyle={rowStyle}
          />
        </SortableContext>
        <DragOverlay>
          {activeItem ? <DraggableOverlay item={activeItem} /> : null}
        </DragOverlay>
      </DndContext>
      {props.pageCount > 0 && (
        <div className="p-1 px-2 pagination-content ">
          <div className="d-flex  align-items-center w-80   flex-shrink-1">
            <label className="page">Rows per page: &nbsp;</label>
            <div>
              <Form.Select
                aria-label="Default select example"
                defaultValue={props.perPage}
                onChange={(e) => handelPerPage(e.target.value)}
              >
                {TableRows.map((rows, index) => (
                  <option key={index} value={rows}>
                    {rows}
                  </option>
                ))}
              </Form.Select>
            </div>
          </div>
          <div className="pagenationwrapper d-flex  justify-content-end">
            <div className="mr-2 text-end  p-2  page pl-5   d-flex justify-content-end align-items-center page-count">
              {`${tableData[0]?.sno >= 0 ? tableData[0]?.sno : "0"}-${
                tableData[tableData.length - 1]?.sno >= 0
                  ? tableData[tableData.length - 1]?.sno
                  : "0"
              } of ${props.totalCount ? props.totalCount : ""}`}
            </div>
            <div className="d-flex align-items-start justify-content-end page-between flex-shrink-1 ">
              <div className="page__arrow" onClick={() => handelOffSet(1)}>
                <HiOutlineChevronDoubleLeft
                  size={15}
                  style={{ color: "black" }}
                />
              </div>
              <ReactPaginate
                breakLabel=""
                nextLabel={<IoChevronForwardOutline size={15} />}
                marginPagesDisplayed={0}
                pageRangeDisplayed={4}
                pageCount={props.pageCount}
                previousLabel={<IoChevronBackOutline size={15} />}
                pageClassName="page"
                pageLinkClassName="page"
                previousClassName="page"
                previousLinkClassName="page"
                nextClassName="page"
                nextLinkClassName="page"
                activeClassName={"pagination-active"}
                renderOnZeroPageCount={null}
                onPageChange={(pageNumber) =>
                  handelOffSet(pageNumber.selected + 1)
                }
                forcePage={props.offset - 1}
              />
              <div
                className="page__arrow"
                onClick={() => handelOffSet(props.pageCount)}
              >
                <HiOutlineChevronDoubleRight
                  size={15}
                  style={{ color: "black" }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
