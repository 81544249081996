import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    getCurrentUser,
    getData,
    handleDashboardNewResponse,
    postExportData,
    putGetData,
} from "../../../api";
import { MdDelete, MdEdit } from "react-icons/md";
// import Search from '../../components/Search/search';
// import Select from '../../components/Form/Select';
import ReactPaginate from "react-paginate";
import {
    HiOutlineChevronDoubleLeft,
    HiOutlineChevronDoubleRight,
} from "react-icons/hi";
import {
    IoChevronBackOutline,
    IoChevronForwardOutline,
    IoEyeSharp,
} from "react-icons/io5";
import { Form } from "react-bootstrap";
// import { CustomerDeleteWarningPopUp } from '../Customer/CustomerTable/CustomerDeleteWarningPopUp';
// import Loader from '../../components/Loader';
import { useSelector } from "react-redux";
import { CustomerDeleteWarningPopUp } from "../../Customer/CustomerTable/CustomerDeleteWarningPopUp";
import Loader from "../../../components/Loader";
import Select from "../../../components/Form/Select";
import Search from "../../../components/Search/search";
// import { putGetData } from '../../../api';

const ProjectsTable = () => {
    const bearerTokenLocal = getCurrentUser()?.token;
    const loginPermission = useSelector((state) => state.login.userPermission);
    const menuId = useSelector((state) => state.login.menuId);
    let addpermission = loginPermission.includes(menuId.addProject);
    let viewpermission = loginPermission.includes(menuId.viewProject);
    let deletePermission = loginPermission.includes(menuId.deleteProject);
    let editProjectPermission = loginPermission.includes(menuId.editProject);
    let projectEnableDisablePermission = loginPermission.includes(
        menuId.projectEnableDisable
    );

    const [search, setSearch] = useState("");

    const [DeveloperbyNameFilter, setDeveloperbyNameFilter] = useState();
    const [SortBy, setSortBy] = useState();
    const [UserType, setUserType] = useState();
    console.log({ UserType });
    const [developerdata, setdeveloperData] = useState([]);
    const [projectAdminData, setProjectAdminData] = useState({
        projectList: [],
        total: 0,
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [deleteID, setDeleteID] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [loader, setLoader] = useState(false);
    const [updateDate, setUpdateDone] = useState(false);
    const sortby = [
        { value: "asc", name: "Oldest to Newest " },
        { value: "desc", name: "Newest to Oldest" },
    ];
    const userTypeData = [
        { value: "Brand", name: "Brand" },
        { value: "Developer", name: "Developer" },
        { value: "Agent", name: "Agent" },
        { value: "Occupier", name: "Occupier" },
        { value: "Operator", name: "Operator" },
        { value: "External", name: "External" },
    ];

    console.log({ search, SortBy, UserType });

    const generateUrl = (base, params) => {
        const query = Object.keys(params)
            .filter((key) => params[key] !== undefined && params[key] !== "")
            .map(
                (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
            )
            .join("&");
        return `${base}?${query}`;
    };

    // get project
    const GetProjectAdmin = async (
        page,
        limit,
        search,
        sort,
        developerId,
        userType
    ) => {
        try {
            setLoader(true);
            const url = generateUrl("/project/getProjectsAdmin", {
                pageNo: page,
                limit,
                search,
                sort,
                developerId,
                userType,
            });

            const getList = await getData(url, {}, {});
            const { statusCode, data } = getList;
            if (statusCode === 200) {
                setLoader(false);
                return data?.projectList?.length > 0
                    ? data
                    : { projectList: [], total: 0 };
            } else {
                throw new Error("Failed to fetch data");
            }
        } catch (e) {
            console.log(e, "error");
            setLoader(false);
            return { projectList: [], total: 0 };
        }
    };
    // get developer
    const GetDeveloper = async () => {
        try {
            const url = `/project/getProjectDeveloper`;
            const getList = await getData(url, {}, {});
            const { statusCode, data } = getList;
            if (statusCode === 200) {
                const newData = data?.uniqueDevelopers?.map((value) => {
                    return {
                        value: value?._id,
                        label: value?.name,
                    };
                });
                setdeveloperData(newData);
            }
        } catch (e) {
            console.log(e, "error");
        }
    };
    const UpdateStates = async (id, body) => {
        try {
            const url = `/project/changeStatus/${id}`;
            const getList = await handleDashboardNewResponse(url, body);
            const { statusCode, data } = getList;
            if (statusCode == 200) {
                GetProjectAdmin();
                setUpdateDone(true);
                setShowModal(false);
                setDeleteID(null);
            }
        } catch (e) {
            console.log(e, "error");
        }
    };

    const deleteData = async (id, body) => {
        try {
            const url = `/project/deleteProject/${id}`;
            const getList = await putGetData(url, bearerTokenLocal, body);
            const { statusCode, data } = getList;
            if (statusCode === 200) {
                setUpdateDone(true);
                setShowModal(false);
                setDeleteID(null);
            }
        } catch (e) {
            console.log(e, "error");
        }
    };

    const debounce = (func, delay) => {
        let timeoutId;
        return (...args) => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };

    const debouncedSearch = useCallback(
        debounce((searchTerm) => {
            setSearch(searchTerm);
        }, 1000),
        []
    );

    useEffect(() => {
        const fetchData = async () => {
            const data = await GetProjectAdmin(
                currentPage,
                limit,
                search,
                SortBy,
                DeveloperbyNameFilter,
                UserType
            );
            setProjectAdminData(data);
        };
        fetchData();
        GetDeveloper();
        if (updateDate) {
            fetchData();
            setUpdateDone(false);
        }
    }, [
        currentPage,
        limit,
        search,
        SortBy,
        DeveloperbyNameFilter,
        updateDate,
        UserType,
    ]);

    // useEffect(() => {

    // }, [updateDate])

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber.selected + 1);
    };

    const handleFirstPage = () => {
        setCurrentPage(1);
    };

    const handleLastPage = () => {
        setCurrentPage(
            projectAdminData.total ? Math.ceil(projectAdminData.total / limit) : 1
        );
    };

    const navigate = useNavigate();
    const handleNavigation = (path) => {
        navigate(path);
    };

    const handleSearch = (e) => {
        const searchTerm = e.target.value;
        debouncedSearch(searchTerm);
    };

    const handlePerPage = (value) => {
        setLimit(Number(value));
        setCurrentPage(1);
    };

    const OnClickExport = async (params) => {
        setLoader(true);

        postExportData(`/admin/getAllProject`, {}, params).then((blob) => {
            if (blob) {
                setLoader(false);
                let urls = window.URL.createObjectURL(blob);
                let a = document.createElement("a");
                a.href = urls;
                a.download = `project.xlsx`;
                a.click();
            }
        });
    };

    const TableRows = [5, 10, 20, 50];
    const startItem = (currentPage - 1) * limit + 1;
    const endItem = Math.min(currentPage * limit, projectAdminData.total);

    return (
        <div>
            <div className="  p-3 rounded-2 " style={{ background: "white" }}>
                <div className="d-flex py-2 justify-content-end  gap-3 align-self-center align-items-center">
                    <div className="row row-cols-4 ">
                        <Select
                            options={developerdata}
                            placeholder={"Developer "}
                            onChange={(e) => setDeveloperbyNameFilter(e.target.value)}
                        />
                        <Select
                            options={sortby}
                            onChange={(e) => setSortBy(e.target.value)}
                            placeholder={"Sort By"}
                        />
                        {/* <Select
                            options={userTypeData}
                            onChange={(e) => setUserType(e.target.value)}
                            placeholder={"User Type"}
                        /> */}
                        <Search onChange={handleSearch} value={search} />
                        <div
                            style={{
                                background: "#08536B",
                                cursor: "pointer",
                                height: "40px",
                                textAlign: "center",
                                color: "#D9E5E6",
                                width: "170px",
                                padding: "10px  auto",
                                borderRadius: "5px",
                            }}
                            className="d-flex justify-content-center align-items-center"
                            onClick={() => {
                                OnClickExport({
                                    projectId: "",
                                    userType: UserType,
                                    sort: SortBy,
                                    search: search,
                                    reportType: "export",
                                });
                            }}
                        >
                            Export CSV
                        </div>
                    </div>
                </div>
                {projectAdminData?.projectList?.length > 0 ? (
                    <div className="table-wrapper rounded-2 rounded  ">
                        {!loader ? (
                            <table className="table   border-success-subtle">
                                <thead className="thead-dark">
                                    <tr>
                                        <th scope="col">Sr No.</th>
                                        <th scope="col">Timestamp</th>
                                        <th scope="col">Project Name</th>
                                        <th scope="col">Developer</th>
                                        <th scope="col">Developer POC</th>
                                        <th scope="col">Project ID</th>
                                        <th scope="col">City</th>
                                        <th scope="col">PT POC</th>
                                        <th scope="col">RM</th>
                                        <th scope="col">Status</th>

                                        {/* <th scope="col">Action</th> */}
                                    </tr>
                                </thead>
                                {projectAdminData?.projectList?.map((value, index) => {
                                    console.log({ currentPage });
                                    const isoDateString = value?.createdAt;

                                    // Parse the ISO date string into a Date object
                                    const date = new Date(isoDateString);

                                    // Format the date into the desired format
                                    const formattedDate = date.toLocaleString("en-US", {
                                        month: "numeric",
                                        day: "numeric",
                                        year: "numeric",
                                        hour: "numeric",
                                        minute: "numeric",
                                        second: "numeric",
                                        hour12: true,
                                    });
                                    return (
                                        <tbody key={index}>
                                            <tr
                                                style={{
                                                    borderBottom: "1px solid #004A6E",
                                                    background: "white",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                <td>
                                                  
                                                    {((currentPage - 1) * limit) + index + 1}
                                                </td>

                                                <td>{formattedDate}</td>

                                                <td className="text-capitalize">
                                                    {value?.projectName}
                                                </td>
                                                <td className="text-capitalize">
                                                    {value?.developerName}
                                                </td>
                                                <td>{value?.developerPoc}</td>
                                                <th
                                                    scope="row"
                                                    style={{
                                                        borderBottom: "2px  solid #004A6E",
                                                        background: "white",
                                                        fontWeight: "bold",
                                                        cursor: "pointer",
                                                        color: "#004A6E",
                                                        textDecorationLine: "underline",
                                                    }}
                                                    onClick={
                                                        viewpermission
                                                            ? () => {
                                                                handleNavigation(
                                                                    `/viewproject/${value?._id}`
                                                                );
                                                            }
                                                            : () => { }
                                                    }
                                                >
                                                    {value?.projectId}
                                                </th>
                                                <td>{value?.city}</td>
                                                <td className="text-capitalize">
                                                    {value?.physicalTeam.map((v) => {
                                                        return v.name + " ,";
                                                    })}
                                                </td>
                                                <td>{value?.rmName}</td>
                                                <td>{value?.isActive ? 'Active' : 'Inactive'}</td>

                                                {/* <td className='d-flex gap-2 justify-content-center'>
                                        <MdEdit style={{ cursor: editProjectPermission && 'pointer', opacity: !editProjectPermission && "30%" }} onClick={editProjectPermission ? () => { handleNavigation(`/project/${value?._id}`) } : () => { }} size={23} />

                                        <IoEyeSharp style={{ cursor: viewpermission && 'pointer', opacity: !viewpermission && "30%" }} onClick={viewpermission ? () => { handleNavigation(`/viewproject/${value?._id}`) } : () => { }} size={23}/>

                                        <MdDelete style={{ cursor: deletePermission && 'pointer', opacity: !deletePermission && "30%" }} size={23} onClick={deletePermission ? () => {
                                            setDeleteID(value?._id)
                                            setShowModal(true)
                                        } : () => { }} />
                                        <Form.Check
                                            style={{ cursor: projectEnableDisablePermission && 'pointer', fontSize: "1.2rem", opacity: !projectEnableDisablePermission && "30%" }}
                                            size={25}
                                            type="switch"
                                            id={`custom-switch_${index}}`}
                                            checked={value.isActive}
                                            onChange={projectEnableDisablePermission ? (e) => {
                                                UpdateStates(value._id, {
                                                    "isActive": !value.isActive
                                                }
                                                );
                                            } : () => { }}
                                        />
                                    </td> */}
                                            </tr>
                                        </tbody>
                                    );
                                })}
                            </table>
                        ) : (
                            <>
                                <Loader />
                            </>
                        )}
                    </div>
                ) : (
                    <div className="d-flex w-100 align-items-center fs-5 justify-content-center py-4 ">
                        Data not found
                    </div>
                )}

                <div
                    className="d-flex  justify-content-end p-2"
                    style={{ backgroundColor: "#7FA2B829" }}
                >
                    <div className="d-flex align-items-center w-80 flex-shrink-1">
                        <label className="page">Rows per page: &nbsp;</label>
                        <div>
                            <Form.Select
                                aria-label="Default select example"
                                value={limit}
                                onChange={(e) => handlePerPage(e.target.value)}
                            >
                                {TableRows.map((rows, index) => (
                                    <option key={index} value={rows}>
                                        {rows}
                                    </option>
                                ))}
                            </Form.Select>
                        </div>
                    </div>
                    <div className="pagenationwrapper d-flex justify-content-end">
                        <div className="mr-2 text-end p-2 page pl-5 d-flex justify-content-end align-items-center page-count">
                            {`${startItem ? startItem : 0} - ${endItem ? endItem : 0} of ${projectAdminData.total ? projectAdminData.total : 0
                                }`}
                        </div>
                        <div className="d-flex align-items-start justify-content-end page-between flex-shrink-1">
                            <div className="page__arrow" onClick={handleFirstPage}>
                                <HiOutlineChevronDoubleLeft
                                    size={15}
                                    style={{ color: "black" }}
                                />
                            </div>
                            <ReactPaginate
                                breakLabel=""
                                nextLabel={<IoChevronForwardOutline size={15} />}
                                marginPagesDisplayed={0}
                                pageRangeDisplayed={4}
                                pageCount={Math.ceil(projectAdminData.total / limit)}
                                previousLabel={<IoChevronBackOutline size={15} />}
                                pageClassName="page"
                                pageLinkClassName="page"
                                previousClassName="page"
                                previousLinkClassName="page"
                                nextClassName="page"
                                nextLinkClassName="page"
                                activeClassName="pagination-active"
                                onPageChange={handlePageChange}
                                forcePage={currentPage - 1}
                            />
                            <div className="page__arrow" onClick={handleLastPage}>
                                <HiOutlineChevronDoubleRight
                                    size={15}
                                    style={{ color: "black" }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectsTable;
