import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { AiOutlineDelete } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdOutlineModeEdit } from "react-icons/md";
import { CustomerWarningPopup } from "../../Customer/CustomerTable/warningPopup";
import MultiSelectDropdown from "../categories/MultiSelectDropdown";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  AddHeaderdata,
  deleteData,
  editHeaderdata,
  getCategoriesData,
  postUploadData,
} from "../../../api";
import Loader from "../../../components/Loader";
import SingleImageDropzone from "../../../components/dragAndDrop/singleFileDrop";

const SplashScreen = () => {
  const [show, setShow] = useState({
    add: false,
    edit: false,
    delete: false,
  });
  const [EditID, setEditID] = useState();
  const [mobileimageData, setMobileImageData] = useState({});
  const [Loading, setLoading] = useState(false);
  const [deleteID, setDeleteID] = useState(null);
  const [titleError, settitleError] = useState(false);
  const [title, settitle] = useState({});
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(null);
  const [ImageData, setImageData] = useState(null);
  const [ImageError, setImageError] = useState(null);
  const [mainLoader, setMainLoader] = useState(false);

  const [selectedOptions, setSelectedOptions] = useState([]);

  // get splash screen
  const getSplashScreen = async () => {
    setMainLoader(true);
    let url = `/contentMangementRevamp/getSplashOrCover/splash`;
    const getCategories = await getCategoriesData(url, {});
    const { data, status } = getCategories;
    setMainLoader(false);
    if (status) {
      let Screen = data?.getData;
      setSelectedOptions(Screen);
    } else {
      setSelectedOptions([]);
    }
  };

  // s3 url
  const handleImage = async (file) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("image", file[0]);
    let url = `/contentManagement/uploadFileAndSigned
        `;
    const imageDataUpload = await postUploadData(url, {}, formData);
    const { data, status } = imageDataUpload;
    if (status) {
      setLoading(false);
      setImageData(data);

      // setMobileImageData({viewUrl : editValue?.mobile_img?.viewUrl});
      //   setFormValues((pre) => ({ ...pre, image: data?.url, fileName: data?.fileName }));
      //   setErrorMessage((pre) => ({ ...pre, image: "" }));
    } else {
      setLoading(false);
    }
  };

  // add splash screen
  const AddSplashScreen = async (body) => {
    const url = `/contentMangementRevamp/createSplashOrCover`;
    setLoading(true);
    const getClienteleStatus = await AddHeaderdata(url, body);
    const { data, status } = getClienteleStatus;
    if (status) {
      // getCategoriesList();
      setLoading(false);
      getSplashScreen();
      setShow({
        add: false,
        edit: false,
        delete: false,
      });
      setPreview(null);
      setImage(null);
      setImageData(null);
      settitleError(false);
      setImageError(false);
    } else {
      setLoading(false);
    }
  };

  // edit splash

  const EditSplash = async (body) => {
    const url = `/contentMangementRevamp/editSplashOrCover/${EditID} `;
    setLoading(true);
    const getClienteleStatus = await editHeaderdata(url, body);
    const { data, status } = getClienteleStatus;
    if (status) {
      setEditID();
      setLoading(false);
      getSplashScreen();
      setShow({
        add: false,
        edit: false,
        delete: false,
      });
      setPreview(null);
      setImage(null);
      setImageData(null);
      settitleError(false);
      setImageError(false);
    } else {
      setLoading(false);
    }
  };

  // delete api
  const DeleteAPI = async (id) => {
    const url = `/contentMangementRevamp/deleteSplashOrCover/${id} `;
    setMainLoader(true);
    const getClienteleStatus = await deleteData(url);
    const { data, status } = getClienteleStatus;
    if (status) {
      getSplashScreen();
      setMainLoader(false);
      setDeleteID(null);

      setShow({
        add: false,
        edit: false,
        delete: false,
      });
    } else {
      setMainLoader(false);
    }
  };

  // edit splash

  const updateSplashOrCoverSequence = async (body) => {
    const url = `/contentMangementRevamp/updateSplashOrCoverSequence?type=splash&platform=app `;
    setMainLoader(true);
    const getClienteleStatus = await editHeaderdata(url, body);
    const { data, status } = getClienteleStatus;
    if (status) {
      setEditID();
      setMainLoader(false);
      getSplashScreen();
    } else {
      setMainLoader(false);
    }
  };

  const handleImageChange = (file) => {
    // const file = event.target.files;

    if (file) {
      setImage(file);

      // Create a preview of the uploaded image
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (image) {
      handleImage(image);
    }
  }, [image]);

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const newOrder = Array.from(selectedOptions);
    const [removed] = newOrder.splice(result.source.index, 1);
    newOrder.splice(result.destination.index, 0, removed);

    setSelectedOptions(newOrder);
    newOrder.map((item) => {
      return delete item?.signedImageUrl;
    });

    updateSplashOrCoverSequence({
      updateData: newOrder,
    });
  };

  useEffect(() => {
    getSplashScreen();
  }, []);

  console.log({ selectedOptions });
  return (
    <div>
      {/* table */}
      <div className="table ">
        <div
          className="d-flex justify-content-end my-3  "
          onClick={() => {
            setShow({
              delete: false,
              add: true,
              edit: false,
            });
            settitle();
          }}
        >
          <div
            className="px-3 fs-6 py-2"
            style={{
              background: "#015169",
              borderRadius: "6px",
              cursor: "pointer",
              color: "#FFFFFF",
            }}
          >
            {" "}
            Add Splash Screen
          </div>
        </div>
        <div className="" style={{ background: "#015169", color: "white" }}>
          <div
            className="d-flex justify-content-between align-items-center"
            style={{
              paddingLeft: 60,
              paddingRight: 210,
            }}
          >
            <div scope="col">Title</div>
            <div scope="col">Action</div>
          </div>
        </div>
        {/* table body */}
        <div>
          {!mainLoader ? (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{ marginTop: "1rem" }}
                  >
                    {selectedOptions?.length === 0
                      ? "No data found"
                      : selectedOptions.map((value, index) => (
                          <Draggable
                            key={value?._id}
                            draggableId={String(value?._id)}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  ...provided.draggableProps.style,
                                  borderRadius: "5px",
                                  color: "#B4B8AC",
                                }}
                                className=""
                              >
                                <div
                                  key={index}
                                  className="border border-white py-2   "
                                  style={{
                                    color: "#F1F1F1",
                                    width: "100%",
                                    cursor: "pointer",
                                    fontSize: "14px",
                                    fontFamily: "outfit",
                                    background: "#f1f1f1",
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                    paddingLeft: 40,
                                    paddingRight: 10,
                                    position: "relative",
                                  }}
                                >
                                  <div
                                    style={{
                                      color: "#000000",
                                      fontSize: "14px",
                                      fontFamily: "outfit",
                                      position: "absolute",
                                      top: "50%",
                                      transform: "translateY(-50%)",
                                      left: 5,
                                    }}
                                  >
                                    <BsThreeDotsVertical
                                      style={{
                                        color: "black",
                                        fontSize: "20px",
                                      }}
                                    />
                                  </div>
                                  <div
                                    className="d-flex justify-content-bwtween align-items-center py-2"
                                    style={{
                                      background: "white",
                                      position: "relative",
                                      paddingLeft: 20,
                                    }}
                                  >
                                    <div
                                      style={{
                                        color: "#000000",
                                        fontSize: "14px",
                                        fontFamily: "outfit",
                                        display: "flex",
                                        gap: "20px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          color: "#000000",
                                          fontSize: "14px",
                                          fontFamily: "outfit",
                                        }}
                                      >
                                        {value?.title}
                                      </div>
                                    </div>

                                    <div
                                      className="d-flex flex-row gap-2 justify-content-center "
                                      style={{
                                        color: "#08536B",
                                        position: "absolute",
                                        top: "50%",
                                        transform: "translateY(-50%)",
                                        right: 200,
                                      }}
                                    >
                                      <div>
                                        <MdOutlineModeEdit
                                          onClick={() => {
                                            setShow({
                                              delete: false,
                                              add: false,
                                              edit: true,
                                            });
                                            setEditID(value?._id);
                                            settitle(value?.title);
                                            setImageData({
                                              viewUrl: value?.signedImageUrl,
                                              url: value?.imageUrl,
                                              fileName: null,
                                            });
                                          }}
                                          style={{
                                            height: "1.5rem",
                                            width: "1.5rem",
                                            cursor: "pointer",
                                          }}
                                        />
                                      </div>
                                      <div>
                                        <AiOutlineDelete
                                          onClick={() => {
                                            setShow({
                                              delete: true,
                                              add: false,
                                              edit: false,
                                            });
                                            setDeleteID(value?._id);
                                          }}
                                          style={{
                                            height: "1.5rem",
                                            width: "1.5rem",
                                            cursor: "pointer",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            <div>
              <Loader />
            </div>
          )}
        </div>
      </div>

      {/* Edit modal */}
      <Modal show={show?.edit} className="p-3" style={{ padding: "10px" }}>
        <div className="m-3">
          <Modal.Title style={{ color: "#0B262A" }}>
            Edit Splash Screen
          </Modal.Title>
        </div>
        <Modal.Body>
          <div>
            {/* input name */}
            <div
              style={{
                color: "#0B262A",
                fontFamily: "outfit",
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              Title
            </div>

            <div className="  ">
              <input
                value={title}
                placeholder="Enter Title"
                className="border my-2 p-1"
                style={{
                  outline: "none",
                  color: "#406065",
                  borderRadius: "6px",
                  width: "100%",
                  border: "#D9E5E6",
                }}
                name="title"
                onChange={(e) => settitle(e.target.value)}
              />
              {titleError && (
                <div
                  style={{
                    color: "red",
                    fontFamily: "outfit",
                    fontSize: "13px",
                    fontWeight: "500",
                  }}
                >
                  title is required.
                </div>
              )}
            </div>

            {/* Image upload */}

            <div
              className="py-2"
              style={{
                color: "#0B262A",
                fontFamily: "outfit",
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              Select Image
            </div>
            {/* {!Loading ? <div className='d-flex flex-column gap-2'>
                            {!ImageData?.
                                viewUrl && <input type="file" accept="image/*" onChange={handleImageChange} />}
                            {ImageError && <div style={{ color: 'red', fontFamily: "outfit", fontSize: "13px", fontWeight: "500" }}>Image is required.</div>}
                            {ImageData?.
                                viewUrl
                                && (
                                    <div>
                                        <div style={{ color: '#0B262A', fontFamily: "outfit", fontSize: "16px", fontWeight: "500" }}>
                                            Image Preview <span className='' onClick={() => { setImageData(null) }} style={{ color: "#015169", fontWeight: 'bold', cursor: "pointer" }}>X</span>
                                        </div>
                                        <img src={
                                            ImageData?.
                                                viewUrl

                                        } alt="Image Preview" style={{ width: '100px', height: 'auto' }} />

                                    </div>
                                )}
                        </div> : <div><Loader /></div>} */}
            <SingleImageDropzone
              size
              loading={Loading}
              onHandelImage={handleImage}
              data={ImageData}
              resolutionText={"875 X 1896"}
            />

            {ImageError && (
              <div
                style={{
                  color: "red",
                  fontFamily: "outfit",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
              >
                Image is required.
              </div>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="d-flex flex-row justify-content-end gap-4">
            <div
              className="px-3 fs-6 py-2"
              style={{
                borderColor: "#015169",
                borderStyle: "outset",
                borderWidth: "1px",
                borderRadius: "6px",
                cursor: "pointer",
                color: "#015169",
              }}
              onClick={() => {
                setShow({
                  add: false,
                  edit: false,
                  delete: false,
                });
                setPreview(null);
                setImage(null);
                setImageData(null);
                settitleError(false);
                setImageError(false);
              }}
            >
              Cancel
            </div>
            <div
              className="px-3 fs-6 py-2"
              style={{
                background: "#015169",
                borderRadius: "6px",
                cursor: "pointer",
                color: "#FFFFFF",
              }}
              onClick={() => {
                title &&
                  ImageData?.url &&
                  EditSplash({
                    title: title,
                    imageUrl: ImageData?.url,
                  });
                settitleError(title ? false : true);
                setImageError(ImageData?.url ? false : true);
              }}
            >
              Save
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      {/* delete modal */}
      <CustomerWarningPopup
        title={"Confirmation"}
        show={show?.delete}
        message={"Are you sure you want delete this Splash Screen?"}
        warningYesClick={() => {
          DeleteAPI(deleteID);
        }}
        onClose={() => {
          setShow({
            delete: false,
            add: false,
            edit: false,
          });
        }}
      />

      {/* Add Modal */}
      <Modal show={show?.add} className="p-3" style={{ padding: "10px" }}>
        <div className="m-3">
          <Modal.Title style={{ color: "#0B262A" }}>
            Add Splash Screen
          </Modal.Title>
        </div>
        <Modal.Body>
          <div>
            {/* input name */}
            <div
              style={{
                color: "#0B262A",
                fontFamily: "outfit",
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              Title
            </div>

            <div className="  ">
              <input
                value={title}
                placeholder="Enter Title"
                className="border my-2 p-1"
                style={{
                  outline: "none",
                  color: "#406065",
                  borderRadius: "6px",
                  width: "100%",
                  border: "#D9E5E6",
                }}
                name="title"
                onChange={(e) => settitle(e.target.value)}
              />
              {titleError && (
                <div
                  style={{
                    color: "red",
                    fontFamily: "outfit",
                    fontSize: "13px",
                    fontWeight: "500",
                  }}
                >
                  Title is required.
                </div>
              )}
            </div>

            {/* Image upload */}

            <div
              className="py-2"
              style={{
                color: "#0B262A",
                fontFamily: "outfit",
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              Select Image
            </div>
            {/* {!Loading ? <div className='d-flex flex-column gap-2'>
                            {!ImageData?.
                                viewUrl && <input type="file" accept="image/*" onChange={handleImageChange} />}
                            {ImageError && <div style={{ color: 'red', fontFamily: "outfit", fontSize: "13px", fontWeight: "500" }}>Image is required.</div>}
                            {ImageData?.
                                viewUrl
                                && (
                                    <div>
                                        <div style={{ color: '#0B262A', fontFamily: "outfit", fontSize: "16px", fontWeight: "500" }}>
                                            Image Preview  <span className='' onClick={() => { setImageData(null) }} style={{ color: "#015169", fontWeight: 'bold', cursor: "pointer" }}>X</span>
                                        </div>
                                        <img src={
                                            ImageData?.
                                                viewUrl

                                        } alt="Image Preview" style={{ width: '100px', height: 'auto' }} />
                                    </div>
                                )}
                        </div> : <div><Loader /></div>} */}

            <SingleImageDropzone
              size
              loading={Loading}
              onHandelImage={handleImage}
              data={ImageData}
              resolutionText={"875 X 1896"}
            />

            {ImageError && (
              <div
                style={{
                  color: "red",
                  fontFamily: "outfit",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
              >
                Image is required.
              </div>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="d-flex flex-row justify-content-end gap-4">
            <div
              className="px-3 fs-6 py-2"
              style={{
                borderColor: "#015169",
                borderStyle: "outset",
                borderWidth: "1px",
                borderRadius: "6px",
                cursor: "pointer",
                color: "#015169",
              }}
              onClick={() => {
                setShow({
                  add: false,
                  edit: false,
                  delete: false,
                });
                setPreview(null);
                setImage(null);
                setImageData(null);
                settitleError(false);
                setImageError(false);
              }}
            >
              Cancel
            </div>
            <div
              className="px-3 fs-6 py-2"
              style={{
                background: "#015169",
                borderRadius: "6px",
                cursor: "pointer",
                color: "#FFFFFF",
              }}
              onClick={() => {
                title &&
                  ImageData?.url &&
                  AddSplashScreen({
                    title: title,
                    imageUrl: ImageData?.url,
                    platform: "app",
                    type: "splash",
                  });
                settitleError(title ? false : true);
                setImageError(ImageData?.url ? false : true);
              }}
            >
              Save
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SplashScreen;
