import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateChildPageData } from "../../../pages/Login/reducer";
import "./style.scss";
export default function ReportCards({ keyData, value }) {
  // console.log({keyData});
  // console.log({value});
  let navigate = useNavigate();
  const dispath = useDispatch();

  const onNavigate = (path, pageName) => {
    if (path && pageName) {
      dispath(updateChildPageData(pageName));
      navigate(path);
    }
  };
  return (
    <div className="report-card-container">
      {keyData?.map((item) => {
        if (!value.hasOwnProperty(item?.name)) {
          return null;
        }
        return (
          <div
            className="card report-card-items d-flex flex-column"
            key={item?.id}
          >
            <div className="report-card-value" title={value[item?.name]}>
              {/* {value[item?.name]} */}
              {String(value[item?.name])?.length > 6
                ? `${String(value[item?.name])?.slice(0, 6)}..`
                : String(value[item?.name])}
            </div>
            <p className="report-card-heading mt-2 mb-0" title={item?.label}>
              {item?.label}
            </p>
            <button
              className={`btn-view mt-3"`}
              onClick={() => onNavigate(item?.path, item?.pageName)}
            >
              View all
            </button>
          </div>
        );
      })}
    </div>
  );
}
