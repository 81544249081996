import "../propertyViewList/propertyDetail.css";
import Loading from "../../assets/images/loading.jpg";
import "./propertyDetail.css";

export default function PropertyAmenties(props) {
  const { apiData } = props;
  console.log({ apiData });

  // const camalCase = (string) => {
  //   return string.charAt(0).toUpperCase() + string.slice(1);
  // }
  return (
    <div className="w-100">
      {apiData?.otherAmenities?.length > 0 ? (
        <div className="amenties-row">
          {apiData?.otherAmenities?.map((item, index) => (
            <div key={index} sm={props.sm} md={props.md} className="mt-2">
              <div className="bg-light-card Property-amenties-lable ">
                {item?.viewUrl ? (
                  <div className="Property-amenties-lable-container">
                    <img
                      src={item?.viewUrl}
                      className=""
                      alt={item.label ?? "images"}
                      style={{objectFit: "contain",width:"75%"}}
                    />
                  </div>
                ) : (
                  <div>
                    <img
                      src={Loading}
                      className="w-100  mt-4 aminitiesSize"
                      alt="images"
                    />
                  </div>
                )}
                {/* <div className=" Property-amenties mt-2 ">{item.value}</div>
              <div className="Property-amenties mb-2">{item.name}</div> */}
                {/* <div className="Property-amenties mb-2 f-14 fw-500">{camalCase(item.name)}</div> */}
                <div className="Property-amenties mb-2 capitalize">
                  {item.name}
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center">
          No data found
        </div>
      )}
    </div>
  );
}
