import { useEffect, useState } from "react";

// components
import MainTable from "../../../components/Table";
import Select from "../../../components/Form/Select/index";
import Search from "../../../components/Search/search";
import {
  AccessDenied,
  getAllSwitchUsers,
  getCurrentUser,
  getData,
  getData2,
  getInboxSellerData,
  switchToUsers,
} from "../../../api";

import { Row, Col } from "react-bootstrap";

// icons and images
import switchuser from "../../../assets/images/switchuser.png";
import CrossSign from "../../../assets/images/CrossSign.svg";
import Loader from "../../../components/Loader";
import SwitchAccountPopup from "../../../components/switchModal/SwitchCompanyBuyerPopup";
import { useSearchParams } from "react-router-dom";
import { getAgentColumns, getBrandColumns } from "./inboxTableColumns";

const ListSellersInbox = ({ filterData, trigger, type, isPermission }) => {
  const [offSet, setOffset] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [tableData, setTableData] = useState([]);
  console.log({ type });
  const [propertyTypeList, setPropertyTypeList] = useState([]);
  const [propertyType, setPropertyType] = useState();
  const [transactionType, setTransactionType] = useState();
  const [sort, setSort] = useState();
  const [isNew, seIsNew] = useState(filterData ? filterData : true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [customerType, setCustomerType] = useState();
  const newMessages = searchParams.get("filter")
    ? searchParams.get("filter")
    : true;

  let customerCreatedSortValues = [
    {
      name: "Newest",
      _id: "desc",
    },
    {
      name: "Oldest",
      _id: "asc",
    },
  ];
  let customerTypeSortValues = [
    {
      name: "Agent",
      _id: "Agent",
    },
    {
      name: "Developer",
      _id: "Enterprise Seller",
    },
    {
      name: "Brand",
      _id: "Enterprise Buyer",
    },
    {
      name: "Enterprise Agent",
      // _id: "EAgent",
      _id: "Enterprise Agent",
    },
  ];

  const [search, setSearch] = useState("");

  // account switch modal
  const [switchModal, setSwitchModal] = useState(false);
  const [pocs, setPocs] = useState({});
  const [companyId, setCompanyId] = useState(null);
  const [loading, setLoading] = useState(false);

  const getTableData = async (loadStatus) => {
    if (loadStatus) {
      setLoading(true);
    }

    // let url = `/dashboard/getCustomers?type=Brand&page=${offSet}&limit=${perPage}`;
    let url = `/chat/getChats?pageNo=${offSet}&limit=${perPage}&isNew=${isNew}&type=${
      type === "physicalTeam" ? "Physical Team" : type
    }`;
    if (search.length > 2) {
      url += `&search=${search}`;
    }

    if (transactionType) {
      url += `&transactionType=${transactionType}`;
    }

    if (propertyType) {
      url += `&propertyType=${propertyType}`;
    }
    if (customerType) {
      url += `&search=${customerType}`;
    }
    if (sort) {
      url += `&sort=${sort}`;
    }

    let cusomerData = await getInboxSellerData(url);

    const { statusCode, data } = cusomerData;
    if (statusCode === 200) {
      setPageCount(Math.ceil(data.messageListTotal / perPage));
      setTotalCount(data.messageListTotal);
      setTableData(data.messageListInfo);
      setLoading(false);
    } else {
      setPageCount(0);
      setTableData([]);
      setLoading(false);
    }
  };

  let columns =
    type === "agent"
      ? getAgentColumns(newMessages, type, getTableData)
      : getBrandColumns(newMessages, type, getTableData);

  const handelChangePerPage = (count) => {
    setPerPage(count);
    setOffset(1);
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
    setOffset(1);
  };

  let tableProps = {
    data: tableData,
    columns: columns,
    offset: offSet,
    setOffset: setOffset,
    handelChangePerPage,
    pageCount,
    perPage,
    totalCount,
    tableName: "RmDashboard",
  };

  useEffect(() => {
    if (search === "" || search.length > 2) getTableData(true);
  }, [
    perPage,
    offSet,
    search,
    propertyType,
    transactionType,
    sort,
    isNew,
    customerType,
  ]);

  // useEffect(() => {
  //   getTableData(false);
  // }, [trigger]);

  const onClickResponse = (value) => {
    if (value === "new") {
      setSearchParams({ filter: "new" });
      seIsNew(true);
    }
    if (value === "all") {
      setSearchParams({});
      seIsNew(false);
    }
  };
  if (isPermission) {
    return (
      <>
        {loading && <Loader className={"fixed"} />}
        <div className="db-filter-container mt-3">
          <Row className=" filter-select">
            <>
              <Col lg={4}>
                <Select
                  placeholder="Posted On"
                  options={customerCreatedSortValues}
                  name={"Posted"}
                  value={sort}
                  onChange={(e) => {
                    setSort(e.target.value);
                    setOffset(1);
                  }}
                  // isHideSelect
                />
              </Col>
              <Col md={4} sm={5}>
                <Select
                  placeholder="Customer Type"
                  options={customerTypeSortValues}
                  name={"customerType"}
                  value={customerType}
                  onChange={(e) => {
                    setCustomerType(e.target.value);
                    setOffset(1);
                  }}
                  // isHideSelect
                />
              </Col>
              {/* <Col md={4} sm={5}>
                <Select
                  placeholder="Property Type"
                  options={propertyTypeList}
                  name={"PropertyType"}
                  value={propertyType}
                  onChange={(e) => setPropertyType(e.target.value)}
                  // isHideSelect
                />
              </Col>
              <Col md={4} sm={5}>
                <Select
                  placeholder="Transaction Type"
                  options={customerTransactionTypeSortValues}
                  name={"Transaction"}
                  value={transactionType}
                  onChange={(e) => setTransactionType(e.target.value)}
                  // isHideSelect
                />
              </Col> */}
            </>
          </Row>
          <Row className=" filter-search-inbox">
            <Col lg={8} md={5} sm={6} className="d-flex align-items-start">
              <Search onChange={handleSearch} value={search} />
            </Col>
          </Row>
        </div>
        <MainTable {...tableProps} trigger={trigger} />
        <SwitchAccountPopup
          show={switchModal}
          onClose={() => setSwitchModal(false)}
          data={pocs}
          companyId={companyId}
        />
      </>
    );
  } else {
    return <AccessDenied />;
  }
};
export default ListSellersInbox;
