import { useEffect, useState } from "react";

//library
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2/dist/sweetalert2.js";

//fetch
import {
  getAllSwitchUsers,
  getCurrentUser,
  getData,
  postData,
  putData,
  switchToUsers,
} from "../../../api/index";

//icons
import { BsFillEyeFill } from "react-icons/bs";
import { FaPen } from "react-icons/fa";
import switchuser from "../../../assets/images/switchuser.png";

//components
import { useContext } from "react";
import { AppContext } from "../../../App";
import Button from "../../../components/Form/Button";
import Loader from "../../../components/Loader";
import { ListPopup } from "../../../components/Modal/listModal";
import MainTable from "../../../components/Table/index";
import { isObjectHasValue } from "../../../utilities/commonFunction";
import {
  getTableStoreValue,
  setScrollBackId,
  setTableFilterData,
} from "../../../utilities/pageScroll";
import { updateBrandId } from "../../Login/reducer";
import { CustomerWarningPopup } from "./warningPopup";
import SwitchCustomerEnterprisePopup from "../../../components/switchModal/SwitchCustomeBuyerPopup";
import {
  MULTIPLE_SWITCH,
  SWITCH_LABEL,
} from "../../../../src/custom/constants";

const BuyerTable = (props) => {
  console.log({ propsACCesss: props?.access });
  let navigate = useNavigate();
  const dispatch = useDispatch();
  let tableName = "customerBuyer";
  const { tableStoreContext, setTableStoreContext, pageScroll, setPageScroll } =
    useContext(AppContext);
  let scrollStoreArray = [...pageScroll];

  const currentTableValueFromConText = getTableStoreValue(
    tableName,
    tableStoreContext
  );
  const storetableName = currentTableValueFromConText?.tableName;
  const storePerPage = currentTableValueFromConText?.perPage;
  let checkForTableName = tableName === storetableName;

  //loading
  const [initLoading, setInitLoading] = useState(false);

  //list
  const [listData, setListData] = useState([]);
  //pagination
  const [perPage, setPerPage] = useState(
    checkForTableName ? (storePerPage ? storePerPage : 10) : 10
  );
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  // account switch modal
  const [switchModal, setSwitchModal] = useState(false);
  const [pocs, setPocs] = useState({});
  const [companyId, setCompanyId] = useState(null);
  const [switchEnv, setSwitchEnv] = useState(process.env.REACT_APP_SWITCH_URL);

  //active
  const [activeStatus, setActiveStatus] = useState(false);
  const [customerId, setCustomerId] = useState("");
  const [warningMessage, setWarningMessage] = useState("");
  const [warningPopup, setWarningPopup] = useState(false);
  const [showListPopup, setShowListPopup] = useState(false);
  const [cityList, setcityList] = useState([]);

  let search = props.search ? props.search : "";

  const setContextValue = (id) => {
    let scrollArray = setScrollBackId(
      tableName,
      id,
      props?.filter,
      search,
      scrollStoreArray
    );
    setPageScroll(scrollArray);
  };
  const updateTableStoreContext = (id) => {
    let newValue = setTableFilterData(
      tableName,
      { pageNo: props?.offSet, perPage: perPage, id: id },
      tableStoreContext
    );
    setTableStoreContext(newValue);
  };

  const handleAccountSwitch = async (
    id,
    brandId,
    SWITCH_URL = process.env.REACT_APP_SWITCH_URL
  ) => {
    console.log({SWITCH_URL});
    
    setCompanyId(id);
    setSwitchEnv(SWITCH_URL)
    getAllSwitchUsers(id).then((res) => {
      if (res.status && res.statusCode === 200) {
        // const companySpecificPOCs = res?.data?.companySpecificPOCs || [];
        const brandPOCs =
          res?.data?.brandPOCs.filter((poc) => poc.brandId === brandId) || [];
        const regionPOCs =
          res?.data?.regionPOCs.filter((poc) => poc.brandId === brandId) || [];
        const combinedArrays = [...brandPOCs, ...regionPOCs];
        if (combinedArrays.length === 1) {
          const user = combinedArrays[0];
          const data = {
            companyId: id,
            customerId: user._id,
            type: "user",
          };
          switchToUsers(data).then((res) => {
            console.log({SWITCH_URLfsfs:SWITCH_URL});
            
            let openUrl = SWITCH_URL;
            if (res.status) {
              window.open(`${openUrl}/${res?.data?.token}`);
            }
          });
        } else if (combinedArrays.length < 1) {
          Swal.fire({
            // title: header,
            text: "No Poc Found",
            icon: "error",
            timer: 3000,
            buttons: false,
            showCancelButton: false,
            showConfirmButton: false,
            showCloseButton: true,
          });
        } else {
          setSwitchModal(true);
          setPocs({
            brandPocs: brandPOCs,
            regionPocs: regionPOCs,
          });
        }
      }
    });
  };

  const getCustomerList = () => {
    setInitLoading(true);
    let url = `/brand/?pageNo=${
      props.offSet ? props.offSet : 1
    }&limit=${perPage}&type=${"Brand"}&userId=${getCurrentUser()._id}`;
    if (search) {
      url += `&search=${search}`;
    }
    if (props?.filter?.active === "Active") {
      url += `&isActive=${true}`;
    }
    if (props?.filter?.active === "Inactive") {
      url += `&isActive=${false}`;
    }
    if (
      props?.filter?.city?.label !== "City" &&
      props?.filter?.city?.label !== ""
    ) {
      url += `&cities=${props?.filter?.city?.label}&id=${props?.filter?.city?.value}`;
    }
    getData(url).then((response) => {
      if (response.status) {
        setInitLoading(false);
        setPageCount(Math.ceil(response.data.totalCount / perPage));
        setTotalCount(response.data.totalCount);
        setListData(response.data.customerList);
      } else {
        setInitLoading(false);
        setPageCount(0);
        setListData([]);
      }
    });
  };

  const handelOpenListPopup = (cityData) => {
    setcityList(cityData);
    setShowListPopup(true);
  };

  const warningYesClick = () => {
    let status = {
      isActive: activeStatus ? false : true,
    };
    let url = `/brand/${customerId}`;
    putData(url, {}, status).then((response) => {
      if (response.status) {
        getCustomerList();
        setWarningPopup(false);
      } else {
        setWarningPopup(false);
      }
    });
  };

  const handleWarningClose = () => {
    setWarningPopup(false);
  };
  const onActiveStatusChange = (id, active) => {
    setActiveStatus(active);
    setCustomerId(id);
    setWarningPopup(true);
    if (active === true) {
      setWarningMessage("Are you sure, you want to deactivate this customer");
    } else if (active === false) {
      setWarningMessage("Are you sure, you want to activate this customer");
    }
  };

  function userButtonFormatterBuyer(cell, row, rowIndex) {
    return (
      <div className="d-flex justify-content-center align-items-center ">
        {props?.access?.switchingCapabilityBuyerCustomers ? (
          <>
            <div class="bg-white shadow-md rounded-lg overflow-hidden max-w-xs mx-auto mr-20">
              <img
                style={{ cursor: "pointer", width: "17px", height: "17px" }}
                onClick={(e) => {
                  e.preventDefault();
                  handleAccountSwitch(row?.companyId?._id, row?._id);
                }}
                alt="switch"
                src={switchuser}
              />
              {MULTIPLE_SWITCH && (
                <div style={{ padding: 2 }}>
                  <p class="text-gray-700" style={{ fontSize: "10px" }}>
                    {SWITCH_LABEL.SWITCH1}
                  </p>
                </div>
              )}
            </div>

            {MULTIPLE_SWITCH && (
              <div class="bg-white shadow-md rounded-lg overflow-hidden max-w-xs mx-auto">
                <img
                  style={{ cursor: "pointer", width: "17px", height: "17px" }}
                  src={switchuser}
                  onClick={() => {
                    handleAccountSwitch(
                      row?.companyId?._id,
                      row?._id,
                      process.env.REACT_APP_REVAMP_SWITCH_URL
                    );
                  }}
                  alt="Description of image"
                  class="w-full"
                />
                <div style={{ padding: 2 }}>
                  <p class="text-gray-700" style={{ fontSize: "10px" }}>
                    {SWITCH_LABEL.SWITCH2}
                  </p>
                </div>
              </div>
            )}
          </>
        ) : (
          ""
        )}
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Button
          className="table-btn p-0"
          disabled={props?.access?.viewBuyerCustomers ? false : true}
          onClick={() => {
            dispatch(updateBrandId(row?._id));
            localStorage.setItem("customer-id", JSON.stringify(row._id));
            navigate(`/customer/brandPage/${row.slug}`, {
              state: {
                id: row._id,
                view: true,
                customerType: row?.subBrands
                  ? "companyYesBrand"
                  : "companyNoBrand",
                fromCustomer: true,
              },
            });
          }}
        >
          <BsFillEyeFill />
        </Button>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Button
          onClick={() => {
            dispatch(updateBrandId(row?._id));
            navigate("/customer/brandPageCreation", {
              state: {
                id: row._id,
                edit: true,
                customerType: row?.subBrands
                  ? "companyYesBrand"
                  : "companyNoBrand",
              },
            });
          }}
          className="table-btn p-0"
          disabled={
            row.isActive && props?.access?.editBuyerCustomers ? false : true
          }
        >
          <FaPen />
        </Button>{" "}
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Form.Check
          type="switch"
          id={`custom-switch_${rowIndex}}`}
          checked={row.isActive ? true : false}
          onChange={(e) => onActiveStatusChange(row._id, row.isActive)}
          disabled={props?.access?.buyerEnableDisable ? false : true}
        />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </div>
    );
  }

  const handelChangePerPage = (count) => {
    setPerPage(count);
    props?.setOffset(1);
  };
  const columnsBuyer = [
    {
      dataField: "sno",
      text: "S.no",
    },
    {
      dataField: "companyId",
      text: "Company Name",
      formatter: (cellContent, row, rowIndex) => {
        if (row.companyId === null || !("companyId" in row)) {
          return "-";
        } else if (row.companyId && row.companyId.companyName) {
          return row.companyId.companyName;
        }
      },
    },
    {
      dataField: "name",
      text: "Brand Name",
    },
    {
      dataField: "city",
      text: "City",
      formatter: (cellContent, row, rowIndex) => {
        if (row.cities === null || row?.cities?.length === 0) {
          return "-";
        } else if (row?.cities?.length > 0) {
          let cityArray = [];

          row?.cities?.map((val) => {
            cityArray.push(val?.name);
            return val;
          });
          return (
            <>
              {cityArray?.length > 1 ? (
                <span>
                  {`${cityArray[0]},..`}
                  <span
                    className="view-all-btn cursor-pointer"
                    onClick={() => handelOpenListPopup(cityArray)}
                  >
                    (View all)
                  </span>
                </span>
              ) : (
                <span>{cityArray.toString()}</span>
              )}
            </>
          );
        }
      },
    },
    {
      dataField: "count",
      text: "No. of demands",
    },

    {
      dataField: "rm",
      text: "Relationship Manager",
      formatter: (cellContent, row, rowIndex) => {
        if (
          ((row?.rm === null || row?.rm === "" || !("rm" in row)) &&
            (row?.subscriptionPlanId?.rm === null ||
              row?.subscriptionPlanId?.rm === "" ||
              !("rm" in row?.subscriptionPlanId))) ||
          row?.subscriptionPlanId?.relationshipManager === "No"
        ) {
          return "-";
        } else {
          if ("rm" in row?.subscriptionPlanId) {
            return row?.subscriptionPlanId?.rm?.name;
          } else {
            return row?.rm?.name;
          }
        }
      },
    },
    {
      dataField: "companyId",
      text: "Subscription Valid Till",
      formatter: (cellContent, row, rowIndex) => {
        if (
          row?.companyId?.subscriptionPlanId?.validDate === null ||
          row?.companyId?.subscriptionPlanId?.validDate === undefined ||
          row?.companyId?.subscriptionPlanId?.validDate === "" ||
          !("companyId" in row)
        ) {
          return "-";
        } else if (row?.companyId) {
          return new Date(
            row?.companyId?.subscriptionPlanId?.validDate
          ).toLocaleDateString();
        }
      },
    },
    {
      dataField: "createdAt",
      text: "Created on",
      formatter: (cellContent, row, rowIndex) => {
        if (
          row.createdAt === null ||
          row.createdAt === "" ||
          !("createdAt" in row)
        ) {
          return "-";
        } else if (row?.createdAt) {
          return new Date(row?.createdAt).toLocaleString();
        }
      },
    },
    {
      dataField: "action",
      text: "Action",
      formatter: userButtonFormatterBuyer,
    },
  ];
  //
  const tableProps = {
    data: listData,
    columns: columnsBuyer,
    offset: props?.offSet,
    setOffset: props?.setOffset,
    handelChangePerPage,
    pageCount,
    perPage,
    totalCount,
    tableName: "customerBuyer",
    setPerPage,
  };
  const onCloseCityListPopup = () => {
    setcityList([]);
    setShowListPopup(false);
  };
  useEffect(() => {
    getCustomerList();
    if (search || isObjectHasValue(props?.filter)) {
      setContextValue();
    }
  }, [perPage, props?.offSet, search, props?.filter]);
  return (
    <div>
      {warningPopup && (
        <CustomerWarningPopup
          title={"Confirmation"}
          show={warningPopup}
          message={warningMessage}
          warningYesClick={warningYesClick}
          onClose={handleWarningClose}
        />
      )}
      {initLoading && <Loader className={"fixed"} />}

      <MainTable {...tableProps} />
      <SwitchCustomerEnterprisePopup
        show={switchModal}
        onClose={() => setSwitchModal(false)}
        data={pocs}
        companyId={companyId}
        SWITCH_URL={switchEnv}
      />
      {showListPopup && (
        <ListPopup
          onClose={onCloseCityListPopup}
          lists={cityList}
          show={showListPopup}
          title="Cities"
        />
      )}
    </div>
  );
};
export default BuyerTable;
