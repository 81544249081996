export const errorCheck = (subscription) => {
    let errors = {};
    let requiredField = "This Field is Required";

    const subscriptionArray = [
        "propertyOrDemandSelect",
        "propertyOrDemand",
        "validityDays",
        "homePageSlotAndBanner",
        "userDashboard",
        "demandMails",
        "planName",
        "discount",
        "subscriptionAmount",
        "assistedUploading",
        "featuredProperty",
        "submissionAgainstDemand",
        "submissionAgainstDemandSelect",
        "relationshipManager",
        "rm",
        "emailtoSuppliers",
        "contactSuppliersSelect",
        "contactSuppliers"
      ];
  
    subscriptionArray.map((item) => {
        if (subscription.hasOwnProperty(item)) {
          if (item === "propertyOrDemandSelect") {
            if (subscription[item] === "") {
              errors.propertyOrDemandSelect = requiredField;
            } else if (subscription[item] === "Enter number") {
              if (
                subscription.propertyOrDemand === null ||
                subscription.propertyOrDemand === "" ||
                subscription.propertyOrDemand.match(/^\s*$/)
              ) {
                errors.propertyOrDemand = requiredField;
              }
            }
          }
          if (item === "validityDays") {
            if (
              subscription[item] === null ||
              subscription[item] === "" ||
              isNaN(subscription[item])
            ) {
              errors[item] = requiredField;
            }
          } else {
            
            if (
              item === "demandMails" ||
              item === "featuredProperty" ||
              item === "assistedUploading" ||
              item === "homePageSlotAndBanner" ||
              item === "relationshipManager" ||
              item === "discount" ||
              item === "subscriptionAmount" ||
              item === "planName" ||
              item === "emailtoSuppliers" ||
              item === "contactSuppliersSelect"   ||
              (item === "contactSuppliers" && subscription["contactSuppliersSelect"] === "Enter number" )
            ) {
              if (subscription[item] === "Select" || subscription[item] === "" ) {
                errors[item] = requiredField;
              }
            }
          }
    
          if (item === "submissionAgainstDemandSelect") {
            if (subscription[item] === "") {
              errors[item] = requiredField;
            } else if (subscription[item] === "Enter number") {
              if (
                subscription.submissionAgainstDemand === null ||
                subscription.submissionAgainstDemand === "" ||
                subscription.submissionAgainstDemand.match(/^\s*$/)
              ) {
                errors.submissionAgainstDemand = requiredField;
              }
            }
          }
          if (item === "userDashboard") {
            if (
              subscription[item] === null ||
              subscription[item] === "Select" ||
              subscription[item] === ""
            ) {
              errors[item] = requiredField;
            }
          }
          if (item === "rm") {
            if (
              subscription?.relationshipManager === "Yes" &&
              (subscription?.rm?._id === "" ||
                subscription?.rm?._id === undefined ||
                Object.keys(subscription?.rm)?.length === 0)
            ) {
              errors.rm = requiredField;
            }
          }
        }
        return item;
      });
  
    // -----------------------------------------------
  
    return errors;
  };
  