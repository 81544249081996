import { PHYSICAL_TEAM } from "../../../../../custom/constants";

export const sellerColumns = [
  {
    pageName: "physicalTeamList",
    data: [
      {
        dataField: "sno",
        text: "S.no",
        formatter: false,
      },

      {
        dataField: "companyName",
        text: "Company Name",
        formatter: false,
      },

      {
        dataField: "cityAction",
        text: "City",
        formatter: true,
      },
      {
        dataField: "propertyCount",
        text: "Property uploads",
        formatter: false,
      },

      {
        dataField: "agentAction",
        text: "Action",
        formatter: true,
      },
    ],
  },
  {
    pageName: "physicalTeamPocList",
    data: [
      {
        dataField: "sno",
        text: "S.no",
        formatter: false,
      },
      {
        dataField: "companyName",
        text: PHYSICAL_TEAM,
        formatter: false,
      },
      {
        dataField: "primaryPoc",
        text: "Primary POC",
        formatter: false,
      },
      {
        dataField: "name",
        text: "POC name",
        formatter: false,
      },
      // {
      //   dataField: "designation",
      //   text: "Designation",
      //   formatter: false,
      // },
      {
        dataField: "phoneNumber",
        text: "Phone number",
        formatter: false,
      },
      {
        dataField: "email",
        text: "Email",
        formatter: false,
      },
      {
        dataField: "propertyCount",
        text: "Property uploads",
        formatter: false,
      },
      {
        dataField: "agentPocActionButtonFormatter",
        text: "Action",
        formatter: true,
      },
    ],
  },
  {
    pageName: "propertyAcceptedList",
    data: [
      {
        dataField: "sno",
        text: "S.no",
        formatter: false,
      },

      {
        dataField: "brand",
        text: "Brand",
        formatter: false,
      },
      {
        dataField: "acceptBy",
        text: "Accepted by",
        formatter: false,
      },
      {
        dataField: "demandIdFormatter",
        text: "Demand ID",
        formatter: true,
      },
      {
        dataField: "propertyIdFormatter",
        text: "Property ID",
        formatter: true,
      },
      {
        dataField: "postedBy",
        text: "Posted by",
        formatter: false,
      },
      {
        dataField: "companyNameFormatter",
        text: PHYSICAL_TEAM,
        formatter: true,
      },
      {
        dataField: "projectNameFormatter",
        text: "Project name",
        formatter: true,
      },
      {
        dataField: "propertyType.name",
        text: "Property type",
        formatter: false,
      },
      {
        dataField: "city",
        text: "City",
        formatter: false,
      },
      {
        dataField: "acceptedAction",
        text: "Accepted on",
        formatter: true,
      },
    ],
  },
  {
    pageName: "propertyRejectedList",
    data: [
      {
        dataField: "sno",
        text: "S.no",
        formatter: false,
      },

      {
        dataField: "brand",
        text: "Brand",
        formatter: false,
      },
      {
        dataField: "rejectedBy",
        text: "Rejected by",
        formatter: false,
      },
      {
        dataField: "demandIdFormatter",
        text: "Demand ID",
        formatter: true,
      },
      {
        dataField: "propertyIdFormatter",
        text: "Property ID",
        formatter: true,
      },
      {
        dataField: "postedBy",
        text: "Posted by",
        formatter: false,
      },
      {
        dataField: "companyNameFormatter",
        text: PHYSICAL_TEAM,
        formatter: true,
      },
      {
        dataField: "projectNameFormatter",
        text: "Project name",
        formatter: true,
      },
      {
        dataField: "propertyType.name",
        text: "Property type",
        formatter: false,
      },
      {
        dataField: "city",
        text: "City",
        formatter: false,
      },
      {
        dataField: "rejectedAction",
        text: "Rejected on",
        formatter: true,
      },
    ],
  },
  {
    pageName: "needMoreInfoList",
    data: [
      {
        dataField: "sno",
        text: "S.no",
        formatter: false,
      },
      {
        dataField: "brand",
        text: "Brand",
        formatter: false,
      },
      {
        dataField: "acceptBy",
        text: "Contacted by",
        formatter: false,
      },

      {
        dataField: "demandIdFormatter",
        text: "Demand ID",
        formatter: true,
      },
      {
        dataField: "propertyIdFormatter",
        text: "Property ID",
        formatter: true,
      },
      // {
      //   dataField: "postedBy",
      //   text: "Posted By",
      //   formatter: false,
      // },
      // {
      //   dataField: "companyNameFormatter",
      //   text: "Developer",
      //   formatter: true,
      // },
      {
        dataField: "projectNameFormatter",
        text: "Project name",
        formatter: true,
      },
      {
        dataField: "propertyTypeFormatter",
        text: "Property type",
        formatter: true,
      },
      {
        dataField: "city",
        text: "City",
        formatter: true,
      },
      {
        dataField: "contactedAction",
        text: "Contacted on",
        formatter: true,
      },
    ],
  },
  {
    pageName: "propertyContactedList",
    data: [
      {
        dataField: "sno",
        text: "S.no",
        formatter: false,
      },

      {
        dataField: "brand",
        text: "Brand",
        formatter: false,
      },
      {
        dataField: "company.companyName",
        text: "Company",
        formatter: false,
      },
      {
        dataField: "pocName",
        text: "POC name",
        formatter: false,
      },
      {
        dataField: "supplierName.name",
        text: "Supplier name",
        formatter: false,
      },
      {
        dataField: "propertyIdFormatter",
        text: "Property ID",
        formatter: true,
      },
      {
        dataField: "projectNameFormatter",
        text: "Project name",
        formatter: true,
        // formatter: (cellContent, row, rowIndex) => row?.projectName?row?.projectName:"-",
      },
      {
        dataField: "propertyType.name",
        text: "Property type",
        formatter: false,
        // formatter: (cellContent, row, rowIndex) => row?.propertyType?.name,
      },
      {
        dataField: "city",
        text: "City",
        formatter: false,
      },
      {
        dataField: "createdAtAction",
        text: "Contacted on",
        formatter: true,
      },
    ],
  },
  {
    pageName: "propertyPostedList",
    data: [
      {
        dataField: "sno",
        text: "S.no",
        formatter: false,
      },

      {
        dataField: "submittedBy.name",
        text: PHYSICAL_TEAM,
        formatter: false,
      },
      {
        dataField: "listPropertyIdFormatter",
        text: "Property ID",
        formatter: true,
      },
      {
        dataField: "propertyTypeId.name",
        text: "Property type",
        formatter: false,
        // formatter: (cellContent, row, rowIndex) => row?.propertyType?.name,
      },
      {
        dataField: "cities",
        text: "City",
        formatter: false,
      },
      // {
      //   dataField: "submittedBy.name",
      //   text: "Submitted By",
      //   formatter: false,
      // },
      {
        dataField: "createdAtAction",
        text: "Created on",
        formatter: true,
      },

      // {
      //   dataField:"reponseCoutFormatter",
      //   text: "Properties response",
      //   formatter: true,
      // },
      {
        dataField: "propertyResponse",
        text: "Properties response",
        formatter: false,
      },
      {
        dataField: "isActive",
        text: "Active/In-Active",
        formatter: (cellContent, row, rowIndex) =>
          row?.isActive ? "Active" : "In-Active",
      },
      // {
      //   dataField: "matchingCountFormatter",
      //   text: "Matching demands",
      //   formatter: true,
      // },
      //  {
      //   dataField:"matchingContactedFormatter",
      //   text: "Matching demands Contacted",
      //   formatter: true,
      // },
      {
        dataField: "matchingDemand",
        text: "Matching demands",
        formatter: false,
      },
      {
        dataField: "matchingDemandContacted",
        text: "Matching demands contacted",
        formatter: false,
      },
      {
        dataField: "propertyListAction",
        text: "Action",
        formatter: true,
      },
    ],
  },
  {
    pageName: "propertyActiveList",
    data: [
      {
        dataField: "sno",
        text: "S.no",
        formatter: false,
      },
      {
        dataField: "developerId.name",
        text:PHYSICAL_TEAM,
        formatter: false,
      },
      {
        dataField: "submittedBy.name",
        text: "Poc Name",
        formatter: false,
      },
      {
        dataField: "listPropertyIdFormatter",
        text: "Property ID",
        formatter: true,
      },
      {
        dataField: "propertyTypeId.name",
        text: "Property type",
        formatter: false,
        // formatter: (cellContent, row, rowIndex) => row?.propertyType?.name,
      },
      {
        dataField: "cities",
        text: "City",
        formatter: false,
      },
      {
        dataField: "submittedBy.name",
        text: "Submitted by",
        formatter: false,
      },
      {
        dataField: "createdAtAction",
        text: "Created on",
        formatter: true,
      },

      // {
      //   dataField:"reponseCoutFormatter",
      //   text: "Properties response",
      //   formatter: true,
      // },
      {
        dataField: "propertyResponse",
        text: "Properties response",
        formatter: false,
      },
      {
        dataField: "isActive",
        text: "Active/In-Active",
        formatter: (cellContent, row, rowIndex) =>
          row?.isActive ? "Active" : "In-Active",
      },
      // {
      //   dataField: "matchingCountFormatter",
      //   text: "Matching demands",
      //   formatter: true,
      // },
      //  {
      //   dataField:"matchingContactedFormatter",
      //   text: "Matching demands Contacted",
      //   formatter: true,
      // },
      {
        dataField: "matchingDemand",
        text: "Matching demands",
        formatter: false,
      },
      {
        dataField: "matchingDemandContacted",
        text: "Matching demands contacted",
        formatter: false,
      },
      {
        dataField: "propertyListAction",
        text: "Action",
        formatter: true,
      },
    ],
  },
  {
    pageName: "propertyInActiveList",
    data: [
      {
        dataField: "sno",
        text: "S.no",
        formatter: false,
      },

      {
        dataField: "developerId.name",
        text:PHYSICAL_TEAM,
        formatter: false,
      },
      {
        dataField: "submittedBy.name",
        text: "Poc Name",
        formatter: false,
      },
      {
        dataField: "listPropertyIdFormatter",
        text: "Property ID",
        formatter: true,
      },
      {
        dataField: "propertyTypeId.name",
        text: "Property type",
        formatter: false,
        // formatter: (cellContent, row, rowIndex) => row?.propertyType?.name,
      },
      {
        dataField: "cities",
        text: "City",
        formatter: false,
      },
      {
        dataField: "submittedBy.name",
        text: "Submitted by",
        formatter: false,
      },
      {
        dataField: "createdAtAction",
        text: "Created on",
        formatter: true,
      },

      // {
      //   dataField:"reponseCoutFormatter",
      //   text: "Properties response",
      //   formatter: true,
      // },
      {
        dataField: "propertyResponse",
        text: "Properties response",
        formatter: false,
      },
      {
        dataField: "isActive",
        text: "Active/In-Active",
        formatter: (cellContent, row, rowIndex) =>
          row?.isActive ? "Active" : "In-Active",
      },
      // {
      //   dataField: "matchingCountFormatter",
      //   text: "Matching demands",
      //   formatter: true,
      // },
      //  {
      //   dataField:"matchingContactedFormatter",
      //   text: "Matching demands Contacted",
      //   formatter: true,
      // },
      {
        dataField: "matchingDemand",
        text: "Matching demands",
        formatter: false,
      },
      {
        dataField: "matchingDemandContacted",
        text: "Matching demands contacted",
        formatter: false,
      },
      {
        dataField: "propertyListAction",
        text: "Action",
        formatter: true,
      },
    ],
  },
  {
    pageName: "propertyResponseList",
    data: [
      {
        dataField: "sno",
        text: "S.no",
        formatter: false,
      },

      {
        dataField: "developerId.name",
        text: PHYSICAL_TEAM,
        formatter: false,
      },

      {
        dataField: "submittedBy.name",
        text: "Poc Name",
        formatter: false,
      },
      {
        dataField: "listPropertyIdFormatter",
        text: "Property ID",
        formatter: true,
      },
      {
        dataField: "propertyTypeId.name",
        text: "Property type",
        formatter: false,
        // formatter: (cellContent, row, rowIndex) => row?.propertyType?.name,
      },
      {
        dataField: "cities",
        text: "City",
        formatter: false,
      },
      {
        dataField: "submittedBy.name",
        text: "Submitted by",
        formatter: false,
      },
      {
        dataField: "createdAtAction",
        text: "Created on",
        formatter: true,
      },

      // {
      //   dataField:"reponseCoutFormatter",
      //   text: "Properties response",
      //   formatter: true,
      // },
      {
        dataField: "propertyResponse",
        text: "Properties response",
        formatter: false,
      },
      {
        dataField: "isActive",
        text: "Active/In-Active",
        formatter: (cellContent, row, rowIndex) =>
          row?.isActive ? "Active" : "In-Active",
      },
      // {
      //   dataField: "matchingCountFormatter",
      //   text: "Matching demands",
      //   formatter: true,
      // },
      //  {
      //   dataField:"matchingContactedFormatter",
      //   text: "Matching demands Contacted",
      //   formatter: true,
      // },
      {
        dataField: "matchingDemand",
        text: "Matching demands",
        formatter: false,
      },
      {
        dataField: "matchingDemandContacted",
        text: "Matching demands contacted",
        formatter: false,
      },
      {
        dataField: "propertyListAction",
        text: "Action",
        formatter: true,
      },
    ],
  },
  {
    pageName: "propertyPostedAgainstList",
    data: [
      {
        dataField: "sno",
        text: "S.no",
        formatter: false,
      },
      {
        dataField: "developerId.name",
        text: PHYSICAL_TEAM,
        formatter: false,
      },

      {
        dataField: "submittedBy.name",
        text: "Poc Name",
        formatter: false,
      },
      {
        dataField: "listPropertyIdFormatter",
        text: "Property ID",
        formatter: true,
      },
      {
        dataField: "propertyTypeId.name",
        text: "Property type",
        formatter: false,
        // formatter: (cellContent, row, rowIndex) => row?.propertyType?.name,
      },
      {
        dataField: "cities",
        text: "City",
        formatter: false,
      },
      {
        dataField: "submittedBy.name",
        text: "Submitted by",
        formatter: false,
      },
      {
        dataField: "createdAtAction",
        text: "Created on",
        formatter: true,
      },

      // {
      //   dataField:"reponseCoutFormatter",
      //   text: "Properties response",
      //   formatter: true,
      // },
      {
        dataField: "propertyResponse",
        text: "Properties response",
        formatter: false,
      },
      {
        dataField: "isActive",
        text: "Active/In-Active",
        formatter: (cellContent, row, rowIndex) =>
          row?.isActive ? "Active" : "In-Active",
      },
      // {
      //   dataField: "matchingCountFormatter",
      //   text: "Matching demands",
      //   formatter: true,
      // },
      //  {
      //   dataField:"matchingContactedFormatter",
      //   text: "Matching demands Contacted",
      //   formatter: true,
      // },
      {
        dataField: "matchingDemand",
        text: "Matching demands",
        formatter: false,
      },
      {
        dataField: "matchingDemandContacted",
        text: "Matching demands contacted",
        formatter: false,
      },
      {
        dataField: "demandIdFormatter",
        text: "Demand ID",
        formatter: true,
      },
      {
        dataField: "brand",
        text: "Physical Team Name",
        formatter: false,
      },
      {
        dataField: "company",
        text: "Company name",
        formatter: false,
      },
      {
        dataField: "pocName.name",
        text: "POC name",
        formatter: false,
      },
      {
        dataField: "propertyListAction",
        text: "Action",
        formatter: true,
      },
    ],
  },

  {
    pageName: "demandPostedList",
    data: [
      {
        dataField: "sno",
        text: "S.no",
      },
      // {
      //   dataField: "companyId.companyName",
      //   text: "Company Name",
      //   formatter: false,
      // },
      {
        dataField: "brandId.name",
        text: "Physical Team Name",
        formatter: false,
      },
      {
        dataField: "demandIdFormatterAction",
        text: "Demand ID",
        formatter: true,
      },
      {
        dataField: "userId.name",
        text: "Submitted by",
        formatter: false,
      },
      {
        dataField: "propertyType.name",
        text: "Property type",
        formatter: false,
      },
      {
        dataField: "location",
        text: "City",
        formatter: false,
      },
      {
        dataField: "properSizeFormatter",
        text: "Requirement size",
        formatter: true,
      },
      {
        dataField: "createdAtAction",
        text: "Created on",
        formatter: true,
      },
      {
        dataField: "responseCount",
        // dataField: "demandReponseCoutFormatter",
        text: "Demand responses",
        formatter: true,
      },
      {
        dataField: "isActive",
        text: "Active/In-Active",
        formatter: (cellContent, row, rowIndex) =>
          row?.isActive ? "Active" : "In-Active",
      },
      {
        dataField: "matchedProperty",
        // dataField: "matchingPropertyCountFormatter",
        text: "Matching properties",
        formatter: true,
      },
      {
        dataField: "demandButtonFormatter",
        text: "Action",
        formatter: true,
      },
    ],
  },

  // {
  //   pageName: "propertyContactedDemandList",
  //   data: [
  //     {
  //       dataField: "sno",
  //       text: "S.no",
  //       formatter: false,
  //     },
  //     {
  //       dataField: "supplierName.name",
  //       text: "Supplier name",
  //       formatter: false,
  //     },
  //     {
  //       dataField: "propertyIdFormatter",
  //       text: "Property ID",
  //       formatter: true,
  //     },
  //     {
  //       dataField: "projectNameFormatter",
  //       text: "Project name",
  //       formatter: true,
  //     },
  //     {
  //       dataField: "propertyType.name",
  //       text: "Property type",
  //       formatter: false,
  //     },

  //     {
  //       dataField: "city",
  //       text: "City",
  //       formatter: false,
  //     },
  //     {
  //       dataField: "supplierType",
  //       text: "Supplier type",
  //       formatter: false,
  //     },

  //     {
  //       dataField: "createdFormatter",
  //       text: "Contacted on",
  //       formatter: true,
  //     },
  //     {
  //       dataField: "brand",
  //       text: "Brand",
  //       formatter: false,
  //     },
  //     // {
  //     //   dataField: "company.companyName",
  //     //   text: "Company",
  //     //   formatter: false,
  //     // },
  //     {
  //       dataField: "pocName",
  //       text: "POC name",
  //       formatter: false,
  //     },
  //   ],
  // },

  {
    pageName: "propertyContactedDemandList",
    data: [
      {
        dataField: "sno",
        text: "S.no",
        formatter: false,
      },
      {
        // dataField: "supplierName.name",
        dataField: "supplierName",
        text: "Supplier name",
        formatter: false,
      },
      {
        dataField: "propertyIdFormatterForContacted",
        text: "Property ID",
        formatter: true,
      },
      {
        dataField: "projectNameFormatter",
        text: "Project name",
        formatter: true,
      },
      {
        dataField: "propertyType",
        text: "Property type",
        formatter: false,
      },

      {
        dataField: "city",
        text: "City",
        formatter: false,
      },
      {
        dataField: "supplierType",
        text: "Supplier type",
        formatter: false,
      },

      {
        dataField: "respondedONAction",
        text: "Contacted on",
        formatter: true,
      },
      // {
      //   dataField: "brand",
      //   text: "Brand",
      //   formatter: false,
      // },
      // {
      //   dataField: "company.companyName",
      //   text: "Company",
      //   formatter: false,
      // },
      {
        dataField: "pocName",
        text: "POC name",
        formatter: false,
      },
    ],
  },

  {
    pageName: "mailingLogs",
    data: [
      {
        dataField: "sno",
        text: "S.no",
        formatter: false,
      },
      {
        dataField: "brandNameFormatterForMallingLog",
        text: `${PHYSICAL_TEAM} name`,
        formatter: true,
      },
      {
        dataField: "doneByUser.name",
        text: "POC name",
        formatter: false,
      },
      {
        dataField: "displayName",
        text: "Events",
        formatter: false,
      },
      {
        dataField: "propertyIdAndDemandIdFormatterForMallingLogs",
        text: "Property ID / Demand ID",
        formatter: true,
      },
      // {
      //   dataField: "cityFormatterForActivityLog",
      //   text: "City",
      //   formatter: true,
      // },
      {
        dataField: "createdAtActionForActivityLog",
        text: "Timestamp",
        formatter: true,
      },
    ],
  },

  {
    pageName: "demandResponseList",
    data: [
      {
        dataField: "sno",
        text: "S.no",
      },
      // {
      //   dataField: "companyId.companyName",
      //   text: "Company Name",
      //   formatter: false,
      // },

      // {
      //   dataField: "brandId.name",
      //   text: "Brand name",
      //   formatter: false,
      // },
      {
        dataField: "demandIdFormatterAction",
        text: "Demand ID",
        formatter: true,
      },
      {
        dataField: "userId.name",
        text: "Submitted by",
        formatter: false,
      },
      {
        dataField: "propertyType.name",
        text: "Property type",
        formatter: false,
      },
      {
        dataField: "location",
        text: "City",
        formatter: false,
      },
      {
        dataField: "properSizeFormatter",
        text: "Requirement size",
        formatter: true,
      },
      {
        dataField: "contactCreatedAt",
        text: "Created on",
        formatter: true,
      },
      {
        dataField: "updatedAtAction",
        text: "Updated on",
        formatter: true,
      },
      {
        dataField: "responseCount",
        // dataField: "demandReponseCoutFormatter",
        text: "Demand responses",
        formatter: true,
      },
      {
        dataField: "isActive",
        text: "Active/In-Active",
        formatter: (cellContent, row, rowIndex) =>
          row?.isActive ? "Active" : "In-Active",
      },
      {
        dataField: "matchedProperty",
        // dataField: "matchingPropertyCountFormatter",
        text: "Matching properties",
        formatter: true,
      },
      // {
      //   dataField: "matchingContactedFormatter",
      //   text: "Matching properties contacted",
      //   formatter: true,
      // },
      {
        dataField: "demandButtonFormatter",
        text: "Action",
        formatter: true,
      },
    ],
  },
  {
    pageName: "activeDemandList",
    data: [
      {
        dataField: "sno",
        text: "S.no",
      },
      // {
      //   dataField: "companyId.companyName",
      //   text: "Company Name",
      //   formatter: false,
      // },
      // {
      //   dataField: "brandId.name",
      //   text: "Brand Name",
      //   formatter: false,
      // },
      {
        dataField: "demandIdFormatterAction",
        text: "Demand ID",
        formatter: true,
      },
      {
        dataField: "userId.name",
        text: "Submitted by",
        formatter: false,
      },
      {
        dataField: "propertyType.name",
        text: "Property type",
        formatter: false,
      },
      {
        dataField: "location",
        text: "City",
        formatter: false,
      },
      {
        dataField: "properSizeFormatter",
        text: "Requirement size",
        formatter: true,
      },
      {
        dataField: "createdAtAction",
        text: "Created on",
        formatter: true,
      },
      {
        dataField: "responseCount",
        // dataField: "demandReponseCoutFormatter",
        text: "Demand responses",
        formatter: true,
      },
      {
        dataField: "isActive",
        text: "Active/In-Active",
        formatter: (cellContent, row, rowIndex) =>
          row?.isActive ? "Active" : "In-Active",
      },
      {
        dataField: "matchedProperty",
        // dataField: "matchingPropertyCountFormatter",
        text: "Matching properties",
        formatter: true,
      },
      // {
      //   dataField: "matchingContactedFormatter",
      //   text: "Matching properties contacted",
      //   formatter: true,
      // },
      {
        dataField: "demandButtonFormatter",
        text: "Action",
        formatter: true,
      },
    ],
  },
  {
    pageName: "inActiveDemandList",
    data: [
      {
        dataField: "sno",
        text: "S.no",
      },
      // {
      //   dataField: "companyId.companyName",
      //   text: "Company Name",
      //   formatter: false,
      // },
      // {
      //   dataField: "brandId.name",
      //   text: "Brand name",
      //   formatter: false,
      // },
      {
        dataField: "demandIdFormatterAction",
        text: "Demand ID",
        formatter: true,
      },
      {
        dataField: "userId.name",
        text: "Submitted by",
        formatter: false,
      },
      {
        dataField: "propertyType.name",
        text: "Property type",
        formatter: false,
      },
      {
        dataField: "location",
        text: "City",
        formatter: false,
      },
      {
        dataField: "properSizeFormatter",
        text: "Requirement size",
        formatter: true,
      },
      {
        dataField: "createdAtAction",
        text: "Created on",
        formatter: true,
      },
      {
        dataField: "responseCount",
        // dataField: "demandReponseCoutFormatter",
        text: "Demand responses",
        formatter: true,
      },
      {
        dataField: "isActive",
        text: "Active/In-Active",
        formatter: (cellContent, row, rowIndex) =>
          row?.isActive ? "Active" : "In-Active",
      },
      {
        dataField: "matchedProperty",
        // dataField: "matchingPropertyCountFormatter",
        text: "Matching properties",
        formatter: true,
      },
      // {
      //   dataField: "matchingContactedFormatter",
      //   text: "Matching properties contacted",
      //   formatter: true,
      // },
      {
        dataField: "demandButtonFormatter",
        text: "Action",
        formatter: true,
      },
    ],
  },
  {
    pageName: "demandContactVisibility",
    data: [
      {
        dataField: "sno",
        text: "S.no",
      },
      {
        dataField: "name",
        text: "Name",
        formatter: false,
      },
      {
        dataField: "contactCreatedAt",
        text: "Timestamp",
        formatter: true,
      },
    ],
  },
  {
    pageName: "demandViewedContact",
    data: [
      {
        dataField: "sno",
        text: "S.no",
      },
      // {
      //   dataField: "companyId.companyName",
      //   text: "Company Name",
      //   formatter: false,
      // },
      {
        dataField: "name",
        text: "Name",
        formatter: false,
      },

      {
        dataField: "contactCreatedAt",
        text: "Timestamp",
        formatter: true,
      },
    ],
  },
];
