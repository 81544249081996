import {
  fitOutData,
  possessionData,
  ownershipData,
  depositMonthData,
  brokerageeData,
  propTypeData,
  floorVal,
  buildingStructureData,
  propertyAgeData,
  washroomData,
  parkingData,
  totalFloors,
  meetingRoomData,
  floorNumber,
  leaseData,
  fitouts,
  fitOutDataOffice,
  cabinData,
  amenetiesAvailable,
  floorOption,
  propTypeDataOffice,
  userBaseOpions,
  UserBaseOpionsProperty
} from "../../components/DropdownOptions";
export const getPocDetail = {
  label: "Select POC",
  type: "select",
  disabled: false,
  errorMessage: "POC is required",
  required: true,
  placeholder: "Select POC",
  name: "userId",
  options: [],
  autoFocus: true,
};
export const getPropertyDetailRetail = [
  {
    disabled: false,
    label: "Property Type",
    type: "select",
    errorMessage: "Property Type is required",
    required: true,
    name: "propertyTypeId",
    placeholder: "Select Property Type",
    options: [],
    isHideSelect: true,
  },
  {
    disabled: false,
    label: "Transaction Type",
    type: "select",
    errorMessage: "Transaction Type is required",

    required: true,
    name: "transactionType",
    placeholder: "Select Transaction Type",
    options: propTypeData,
  },
  {
    disabled: false,
    label: "Who can see your property *",
    type: "multiselect",
    errorMessage: "Userbase is required",
    required: true,
    selected: [],
    name: "userAccessibility",
    placeholder: "Select User Accessibility",
    options: UserBaseOpionsProperty,
  },
  {
    disabled: true,
    label: "City",
    type: "SearchSelect",
    required: true,
    placeholder: "Select city name",
    errorMessage: "City is required",
    name: "location",
  },
  {
    label: "Location",
    type: "microLocation",
    name: "microLocation",
    errorMessage: "Location is required",
    hidden: true,
    required: true,
    size: "hidden-group",
  },
  {
    disabled: false,
    label: "Pin Code",
    // type: "number",
    type: "text",
    placeholder: "Enter Pin Code",
    required: true,
    errorMessage: "Pin Code is required",
    name: "pincode",
    max: 6
  },
  {
    disabled: false,
    label: "Project Name",
    type: "text",
    errorMessage: "Project Name is required",
    required: false,
    placeholder: "Enter Project Name",
    name: "propertyName",
  },
  // {
  //   disabled: false,
  //   label: "Developer Name",
  //   type: "text",
  //   errorMessage: "Developer name is required",
  //   required: false,
  //   placeholder: "Enter Developer name",
  //   name: "projectDeveloper",
  // },
  {
    disabled: false,
    label: " About Property/Project",
    type: "textarea",
    errorMessage: "About is required",
    required: true,
    placeholder: "Enter about property",
    name: "about",
  },
  {
    disabled: false,
    type: "radio",
    errorMessage: "Units is required",
    required: true,
    placeholder: "Enter Units",
    name: "propertyUnit",
    options: [{
      value: 'individual',
      label: 'Individual'
    }, {
      value: 'multiple',
      label: 'Multiple'
    }],
    fields: [{
      disabled: false,
      label: "Builtup Area In Square Feet",
      type: "number",
      errorMessage: "Builtup Area In Square Feet is required",
      required: true,
      placeholder: "Enter Builtup Area",
      name: "builtupArea",
      requiredFor: 'individual',
      sufix: "sq.ft",
      positive: true,
    }, {
      disabled: false,
      label: "Number of Units",
      type: "number",
      errorMessage: "Number of Units is required",
      required: true,
      placeholder: "Enter  Number of Units",
      name: "noOfUnits",
      requiredFor: 'multiple',
      positive: true,
    },
    {
      disabled: false,
      label: "Carpet Area range in square feet",
      name: "carpetAreaMin",
      errorMessage: "Carpet Area is required",
      placeholder: "Enter number",
      required: "carpetAreaRequired",
      defaultChecked: true,
      type: "rangeInput",
      requiredFor: 'multiple',
      rangefields: [
        {
          type: "input",
          inputType: "number",
          fieldValue: "carpetAreaMin",
          placeholder: "Min-Range",
          name: "carpetAreaMin",
          sufix: "sqft"

        },
        {
          type: "input",
          inputType: "number",
          fieldValue: "carpetAreaMax",
          placeholder: "Max-Range",
          name: "carpetAreaMax",
          sufix: "sqft"
        }

      ],
    },
    {
      disabled: false,
      label: "Carpet Area in square feet",
      type: "number",
      errorMessage: "Carpet Area is required",
      required: true,
      placeholder: "Enter number",
      name: "carpetAreaMax",
      requiredFor: 'individual',
      sufix: "sq.ft",
      min: "1",
    },
    ]
  },
  // {
  //   disabled: false,
  //   label: "Carpet Area in square feet",
  //   type: "number",
  //   errorMessage: "Carpet Area is required",
  //   required: true,
  //   placeholder: "Enter number",
  //   name: "carpetArea",

  //   sufix: "sq.ft",
  //   min: "0",
  // },

  {
    disabled: false,
    label: "Builtup Area in square feet",
    type: "number",
    errorMessage: "Builtup Area is required",
    required: true,
    placeholder: "Enter number",
    name: "builtupArea",

    sufix: "sq.ft",
    positive: true,
  },
  {
    disabled: false,
    label: "Carpet Area in square feet",
    type: "number",
    errorMessage: "Carpet Area is required",
    required: true,
    placeholder: "Enter number",
    name: "carpetAreaMax",
    sufix: "sq.ft",
    positive: true,
    min: "1",
  },
  {
    disabled: false,
    label: "Rate per square feet on carpet area",
    type: "number",
    errorMessage: "Rate psf is required",
    required: true,
    placeholder: "Enter number",
    name: "rentPsf",
    note: "Note: Please confirm that the rate entered is on Carpet Area",
    prefix: "₹",
    sufix: "sq.ft",
    min: "1",
  },
  {
    disabled: false,
    label: "Minimum clear ceiling height in feet",
    type: "number",
    errorMessage: "Ceiling height is required",
    required: true,
    placeholder: "",
    name: "ceilingHeight",
    sufix: "ft",
    min: "1",
  },
  {
    disabled: false,
    label: "Negotiable",
    type: "checkbox",
    errorMessage: "Negotiable is required",
    required: true,
    name: "negotiable",

    note: "Note: Tick the box if negotiable",
    checked: false,
  },
  {
    label: " Google Location",
    type: "google-location",
    name: "gMapLatLong",
    hidden: true,
    required: false,
  },

  // {
  //   label: "Power Backup available",
  //   type: "select",
  //   disabled: false,
  //   errorMessage: "Power Backup is required",
  //   required: true,
  //   placeholder: "Select Power Backup",
  //   name: "powerBackup",
  //   options: fitOutData,
  // },
  {
    label: "Fitouts available",
    type: "select",
    disabled: false,
    errorMessage: "FitOuts is required",
    required: false,
    placeholder: "Select Fitouts Avaliable",
    name: "fitOuts",
    options: fitOutData,
  },
  {
    label: "Toilet avaliable",
    type: "select",
    disabled: false,
    errorMessage: "Toilet is required",
    required: true,

    placeholder: "Select Toilet Avaliable",
    name: "toilet",
    options: fitOutData,
  },
  {
    disabled: false,
    label: "Number of parking spaces",
    type: "number",
    errorMessage: "Please enter valid parking space",
    required: false,
    placeholder: "Enter number",
    name: "parking",
    sufix: "units",
    min: "1",
  },
  {
    disabled: false,
    label: "Property available from",
    type: "select",
    errorMessage: "Property available from is required",

    required: true,
    name: "possession",
    placeholder: "Select Property Avaliable",
    options: possessionData,
  },
  {
    disabled: false,
    label: "Lease tenure In years",
    type: "number",
    errorMessage: "Lease tenure is required",
    required: false,
    placeholder: "Enter number",
    name: "tenure",

    sufix: "years",
    positive: "true",
  },
  {
    disabled: false,
    label: "OC available",
    type: "select",
    errorMessage: "OC available is required",

    required: true,
    name: "ocStatus",
    placeholder: "Select OC Avaliable",
    options: fitOutData,
  },
  {
    disabled: false,
    label: "Ownership",
    type: "select",
    errorMessage: "Ownership is required",

    required: false,
    name: "ownerShip",
    placeholder: "Select Ownership",
    options: ownershipData,
  },
  {
    disabled: false,
    label: "Deposit in Months",
    type: "select",
    errorMessage: "Deposit in Months is required",

    required: false,
    name: "deposit",
    placeholder: "Select Deposit in Months",
    options: depositMonthData,
  },

  {
    disabled: false,
    label: "Brokerage in days",
    type: "select",
    errorMessage: "Brokerage in days is required",

    required: false,
    name: "brokerage",
    placeholder: "Select Brokerage in days",
    options: brokerageeData,
  },
  {
    disabled: false,
    label: "Mezzanine Available",
    type: "select",
    errorMessage: "Mezzanine Available is required",
    required: false,
    name: "mezzainineAvailable",
    placeholder: "Select Mezzanine Available",
    options: fitOutData
  },
];
export const getPropertyDetailOffice = [
  {
    disabled: false,
    label: "Property Type",
    type: "select",
    errorMessage: "Property Type is required",
    required: true,
    name: "propertyTypeId",
    placeholder: "Select Property Type",
    options: [],
    isHideSelect: true,
  },
  {
    disabled: false,
    label: "Transaction Type",
    type: "select",
    errorMessage: "Transaction Type is required",

    required: true,
    name: "transactionType",
    placeholder: "Select Transaction Type",
    options: propTypeDataOffice,
  },
  {
    disabled: false,
    label: "Who can see your property *",
    type: "multiselect",
    errorMessage: "User Accessibility is required",
    required: true,
    selected: [],
    name: "userAccessibility",
    placeholder: "Select User Accessibility",
    options: UserBaseOpionsProperty,
  },
  {
    disabled: true,
    label: "City",
    type: "SearchSelect",
    required: true,
    placeholder: "Select city name",
    errorMessage: "City is required",
    name: "location",
  },

  {
    label: "Location",
    type: "microLocation",
    name: "microLocation",
    errorMessage: "Location is required",
    hidden: true,
    size: "hidden-group",
    required: true
  },
  {
    disabled: false,
    label: "Pin Code",
    // type: "number",
    type: "text",
    required: true,
    placeholder: "Enter Pin Code",
    errorMessage: "Pin Code is required",
    name: "pincode",
    max: 6
  },
  {
    disabled: false,
    label: "Project Name",
    type: "text",
    errorMessage: "Project name is required",
    required: false,
    placeholder: "Enter Project name",
    name: "propertyName",
  },
  // {
  //   disabled: false,
  //   label: "Developer Name",
  //   type: "text",
  //   errorMessage: "Developer name is required",
  //   required: false,
  //   placeholder: "Enter Developer name",
  //   name: "projectDeveloper",
  // },
  {
    disabled: false,
    label: " About Property/Project",
    type: "textarea",
    errorMessage: "About is required",
    required: true,
    placeholder: "Enter about property",
    name: "about",
  },
  {
    disabled: false,
    type: "radio",
    errorMessage: "Units is required",
    required: true,
    placeholder: "Enter Units",
    name: "propertyUnit",
    options: [{
      value: 'individual',
      label: 'Individual'
    }, {
      value: 'multiple',
      label: 'Multiple'
    }],
    fields: [{
      disabled: false,
      label: "Builtup Area In Square Feet",
      type: "number",
      errorMessage: "Builtup Area In Square Feet is required",
      required: true,
      placeholder: "Enter Builtup Area",
      name: "builtupArea",
      requiredFor: 'individual',
      sufix: "sq.ft",
      positive: true,
    }, {
      disabled: false,
      label: "Number of Units",
      type: "number",
      errorMessage: "Number of Units is required",
      required: true,
      placeholder: "Enter  Number of Units",
      name: "noOfUnits",
      requiredFor: 'multiple',
      positive: true,
    },
    {
      disabled: false,
      label: "Carpet Area range in square feet",
      name: "carpetAreaMin",
      errorMessage: "Carpet Area is required",
      placeholder: "Enter number",
      required: "carpetAreaRequired",
      defaultChecked: true,
      type: "rangeInput",
      requiredFor: 'multiple',
      rangefields: [
        {
          type: "input",
          inputType: "number",
          fieldValue: "carpetAreaMin",
          placeholder: "Min-Range",
          name: "carpetAreaMin",
          sufix: "sqft"

        },
        {
          type: "input",
          inputType: "number",
          fieldValue: "carpetAreaMax",
          placeholder: "Max-Range",
          name: "carpetAreaMax",
          sufix: "sqft"
        }

      ],
    },
    {
      disabled: false,
      label: "Carpet Area in square feet",
      type: "number",
      errorMessage: "Carpet Area is required",
      required: true,
      placeholder: "Enter number",
      name: "carpetAreaMax",
      requiredFor: 'individual',
      sufix: "sq.ft",
      min: "1",
    },
    ]
  },
  {
    disabled: false,
    label: "Builtup Area in square feet",
    type: "number",
    errorMessage: "Builtup Area is required",
    required: true,
    placeholder: "Enter number",
    name: "builtupArea",

    sufix: "sq.ft",
    positive: true,
  },
  {
    disabled: false,
    label: "Carpet Area in square feet",
    type: "number",
    errorMessage: "Carpet Area is required",
    required: true,
    placeholder: "Enter number",
    name: "carpetAreaMax",
    sufix: "sq.ft",
    min: "1",
  },

  {
    disabled: false,
    label: "Rate per square feet on carpet area",
    type: "number",
    errorMessage: "Rate psf is required",
    required: true,
    placeholder: "Enter number",
    name: "rentPsf",
    note: "Note: Please confirm that the rate entered is on Carpet Area",
    prefix: "₹",
    sufix: "sq.ft",
    min: "1",
  },

  {
    disabled: false,
    label: "Negotiable",
    type: "checkbox",
    errorMessage: "Negotiable is required",
    required: true,
    name: "negotiable",

    note: "Note: Tick the box if negotiable",
    checked: false,
  },
  {
    label: " Google Location",
    type: "google-location",
    name: "gMapLatLong",
    hidden: true,
    required: false,
  },
  {
    disabled: false,
    label: "Property available from",
    type: "select",
    errorMessage: "Property available from is required",

    required: true,
    name: "possession",
    placeholder: "Select Property Avaliable",
    options: possessionData,
  },
  {
    disabled: false,
    label: "Deposit in Months",
    type: "select",
    errorMessage: "Deposit in Months is required",

    required: false,
    name: "deposit",
    placeholder: "Select Deposit in Months",
    options: depositMonthData,
  },


  {
    disabled: false,
    label: "Brokerage in days",
    type: "select",
    errorMessage: "Brokerage in days is required",

    required: false,
    name: "brokerage",
    placeholder: "Select Brokerage in days",
    options: brokerageeData,
  },
  {
    disabled: false,
    label: "Lease tenure In years",
    type: "number",
    errorMessage: "Lease tenure is required",
    required: false,
    placeholder: "Enter number",
    name: "tenure",

    sufix: "years",
    positive: "true",
  },

  {
    disabled: false,
    label: "Lock in period",
    type: "number",
    errorMessage: "Lock in period is required",
    required: false,
    name: "lockInPeriod",
    placeholder: "Enter Lock in period",
    sufix: "years"

  },
  // {
  //   disabled: false,
  //   label: "Fit-out/Rent free period",
  //   type: "select",
  //   errorMessage: "Fit-out/Rent free period is required",
  //   // required: true,
  //   name: "rentFreePeriod",
  //   placeholder: "Select Fit-out/Rent free period",
  //   options: fitouts,
  // },
  {
    disabled: false,
    label: "OC available",
    type: "select",
    errorMessage: "OC available is required",
    required: true,
    name: "ocStatus",
    placeholder: "Select OC Avaliable",
    options: fitOutData,
  },
  // {
  //   disabled: false,
  //   label: "Lease escalation",
  //   type: "select",
  //   errorMessage: "Lease escalation is required",
  //   name: "leaseEscalationValue",
  //   placeholder: "Select Lease escalation",
  //   options: leaseData,
  // },
  // {
  //   disabled: false,
  //   label: "Lease escalation %",
  //   type: "number",
  //   errorMessage: "leaseEscalation is required",
  //   name: "leaseEscalationpercentage",
  //   placeholder: "Enter lease escalation",
  // },
];
export const getUploadPropertyData = [
  {
    title: "Front Side",
    name: "frontSide",
  },
  {
    title: "Interior",
    name: "interior",
  },
  {
    title: "Exterior",
    name: "exterior",
  },
];
export const getInputData = [
  {
    disabled: false,
    label: "Floor",
    type: "select",
    errorMessage: "Floor is required",
    value: "",
    required: false,
    name: "floor",
    placeholder: "Select Floor",
    options: floorOption,
  },
  {
    disabled: false,
    label: "Frontage in feet",
    type: "number",
    errorMessage: "Frontage is required",
    required: false,
    placeholder: "Enter number",
    name: "frontAge",
    sufix: "ft",
  },
  // {
  //   disabled: false,
  //   label: "Access road width in feet",
  //   type: "number",
  //   errorMessage: "Access road width is required",
  //   required: false,
  //   placeholder: "Enter number",
  //   name: "accessRoadWidth",
  //   sufix: "ft",
  // },
  {
    disabled: false,
    label: "Store type",
    type: "select",
    errorMessage: "Store type is required",
    value: "",
    required: true,
    name: "buildingStructure",
    placeholder: "Select store type",
    options: buildingStructureData,
  },
  {
    disabled: false,
    label: "Age of property",
    type: "select",
    errorMessage: "Age of property is required",
    value: "",
    required: false,
    name: "propertyAge",
    placeholder: "Select Age of property",
    options: propertyAgeData,
  },
  // {
  //   disabled: false,
  //   label: "Previous Occupancy",
  //   type: "text",
  //   errorMessage: "Previous Occupancy is required",
  //   required: false,
  //   placeholder: "Write text",
  //   name: "previousOccupancy",
  //   // sufix: "ft",
  // },
  // {
  //   label: "Ideal For",
  //   type: "multiselect",
  //   name: "idealFor",
  //   selected: [],
  //   options: [],
  // },
  {
    label: "",
    type: "multiselect",
    name: "otherAmenities",
    selected: [],
    options: [],
  },
  {
    name: "lockInPeriod",
  },
  {
    name: "rentFreePeriod",
  },
];
export const getDocumentData = [
  {
    label: "Floorplans available",
    type: "file",
    name: "floorPlans",
    floorPlans: [],
    uploadFiles: []
  },
  {
    label: "Autocad available",
    type: "file",
    name: "autocad",
    autocad: [],
    uploadFiles: []
  },
  {
    label: "Fire NOC available",
    type: "file",
    name: "fireNDC",
    fireNDC: [],
    uploadFiles: []
  },
  {
    label: "Other licenses available",
    type: "file",
    name: "licenses",
    licenses: [],
    uploadFiles: []
  },
];

export const getDocumentProjectData = [
  {
    label: "Floorplans available",
    type: "file",
    name: "floorPlans",
    floorPlans: [],
    uploadFiles: []
  },
  {
    label: "Brochure available",
    type: "file",
    name: "brochure",
    Brochure: [],
    uploadFiles: []
  }

];
export const OfficeSpaceAdditonalnput = [
  // {
  //   label: "Ideal For",
  //   type: "select",
  //   name: "idealFor",
  //   options: idealFor,
  // },
  {
    label: "Fitouts Status",
    type: "select",
    disabled: false,
    errorMessage: "Fitouts is required",
    required: true,
    placeholder: "Select Fitouts Status",
    name: "fitOuts",
    options: fitOutDataOffice,

  },
  // {
  //   label: "Power Backup available",
  //   type: "select",
  //   disabled: false,
  //   errorMessage: "Power Backup is required",
  //   required: true,

  //   placeholder: "Select Power Backup",
  //   name: "powerBackup",
  //   options: fitOutData,
  // },
  // {
  //   disabled: false,
  //   label: "Mezzanine Available",
  //   type: "select",
  //   errorMessage: "Mezzanine Available is required",
  //   required: false,
  //   name: "mezzainineAvailable",
  //   placeholder: "Select Mezzanine Available",
  //   options: [{
  //     value: true,
  //     name: 'Yes'
  //   }, {
  //     value: false,
  //     name: 'No'
  //   }]
  // },
  {
    disabled: false,
    label: "Toilet Available",
    type: "select",
    errorMessage: "Toilet available is required",
    required: false,
    name: "toilet",
    placeholder: "Select Toilet Available",
    options: washroomData
  },
  // {
  //   label: "Washrooms",
  //   type: "select",
  //   disabled: false,
  //   errorMessage: "Washrooms is required",
  //   required: false,

  //   placeholder: "Select Washrooms Avaliable",
  //   name: "washRooms",
  //   options: washroomData,
  // },
  {
    label: "Bike parking allotted",
    type: "select",
    disabled: false,
    errorMessage: "Bike parking allotted is required",
    required: true,

    placeholder: "Select Bike parking allotted Avaliable",
    name: "bikeParking",
    options: parkingData,
  },
  {
    label: "Car parking allotted",
    type: "select",
    disabled: false,
    errorMessage: "Car parking allotted is required",
    required: true,

    placeholder: "Select Car parking allotted Avaliable",
    name: "carParking",
    options: parkingData,
  },
  {
    label: "Total floors",
    type: "number",
    disabled: false,
    errorMessage: "Total floors is required",
    required: false,

    placeholder: "Enter Total floors Avaliable",
    name: "totalFloors",
    options: totalFloors,
  },
  {
    label: "Available Floor number ",
    type: "number",
    disabled: false,
    errorMessage: "Available Floor number is required",
    required: false,
    placeholder: "Enter Available Floor number Avaliable",
    name: "availablefloors",
    options: floorNumber,
  },
  // {
  //   label: "Floor number",
  //   type: "select",
  //   disabled: false,
  //   errorMessage: "Floor number is required",
  //   required: false,

  //   placeholder: "Select Floor number Avaliable",
  //   name: "floor",
  //   options: floorNumber,
  // },
  {
    label: "Meeting rooms",
    type: "select",
    disabled: false,
    errorMessage: "Meeting rooms is required",
    required: false,

    placeholder: "Select Meeting rooms Avaliable",
    name: "meetingRooms",
    options: meetingRoomData,
  },
  {
    label: "Cabins",
    type: "select",
    disabled: false,
    errorMessage: "Cabins is required",
    required: false,

    placeholder: "Select Cabins Avaliable",
    name: "cabins",
    options: cabinData,
  },
  {
    disabled: false,
    label: "Age of property",
    type: "select",
    errorMessage: "Age of property is required",
    value: "",
    required: false,
    name: "propertyAge",
    placeholder: "Select Age of property",
    options: propertyAgeData,
  },
  // {
  //   disabled: false,
  //   label: "Age of property",
  //   type: "select",
  //   errorMessage: "Age of property is required",
  //   value: "",
  //   required: false,
  //   name: "propertyAge",
  //   placeholder: "Select Age of property",
  //   options: propertyAgeData,
  // },
  // {
  //   disabled: false,
  //   label: "Ameneties available",
  //   type: "select",
  //   errorMessage: "Ameneties available is required",
  //   value: "",
  //   required: false,
  //   name: "ameneties",
  //   placeholder: "Select Ameneties available",
  //   options: amenetiesAvailable,
  // },
];
