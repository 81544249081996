import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { AccessDenied, getData } from "../../../api";

//image
import BuyerImage from "../../../assets/images/Buyer_Rectangle.png";

//components
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/Loader";
import NavBar from "../../../components/Navbar";
import PocTable from "../CustomerForm/poc/pocTable";
import "../style.scss";
import Property from "./Properties/properties";
import Cities from "./cities";
import Detail from "./detail";
import Reports from "./reports";
import Button from "../../../components/Form/Button";
import { FaPen } from "react-icons/fa";
import { updateCompanyFormStatus } from "../../Login/reducer";
import { ENTERPRISEAGENT } from "../../../custom/constants";

const EnterpriseAgentPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const brandIdUpdate = useSelector((state) => state.login.enterpriseAgentId);
  const { slug } = useParams();
  //state
  const [customerData, setCustomerData] = useState({});
  const [loading, setLoading] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState([]);

  const view = location.state
    ? location.state.view
      ? location.state.view
      : false
    : false;
  const getIdentity = location.state ? location.state.id : "";
  localStorage.setItem("customerId", JSON.stringify(getIdentity));
  const loginPermission = useSelector((state) => state.login.userPermission);
  const menuId = useSelector((state) => state.login.menuId);

  const access = {
    addSellerProperty: loginPermission?.includes(menuId.addSellerProperty),
    addEAgentProperty: loginPermission?.includes(menuId.addEAgentProperty),
    addEAgentDemand: loginPermission?.includes(menuId.addEAgentDemand),
    sellerImportsAndExports: loginPermission?.includes(
      menuId?.sellerImportsAndExports
    ),
    view: loginPermission?.includes(menuId?.viewPropertyAgent) ? true : false,
    edit: loginPermission?.includes(menuId?.editPropertyAgent) ? true : false,
  };

  const getCustomerData = () => {
    // let url = `/brand/${brandIdUpdate}`;
    let url = `/brand/getBrandByName/${slug}`;
    setLoading(true);
    getData(url, {}, {}).then((response) => {
      if (response.status) {
        let data = response.data;
        let subscription = [
          {
            key: "propertyOrDemand",
            title: "Listings",
          },
          {
            key: "validityDays",
            title: "Validity days",
          },
          {
            key: "userDashboard",
            title: "User dashboard",
          },
          {
            key: "demandMails",
            title: "Demand alert",
          },
          {
            key: "contactSuppliers",
            title: "Contact suppliers",
          },
          {
            key: "featuredProperty",
            title: "Featured Listing",
          },
          {
            key: "assistedUploading",
            title: "Assisted uploading",
          },
          {
            key: "homePageSlotAndBanner",
            title: "Top developer slot",
          },
          {
            key: "expiredate",
            title: "Expire at",
          },
        ];

        let subsArray = [];
        subscription.map((item) => {
          if (response?.data?.subscriptionPlanId?.hasOwnProperty(item.key)) {
            subsArray.push({
              value: response?.data?.subscriptionPlanId[item.key],
              title: item.title,
              key: item.key,
            });
          }
          return item;
        });
        setSubscriptionData(subsArray);

        setCustomerData(data);
        localStorage.setItem("customerData", JSON.stringify(data));
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    getCustomerData();
  }, [view]);

  return (
    <>
      {loginPermission.includes(menuId?.viewCompanyAgent) ? (
        <>
          <NavBar />
          {loading ? (
            <Loader />
          ) : (
            <>
              {Object.keys(customerData).length > 0 ? (
                <div className="brandPage-container">
                  {customerData?.image && customerData?.image?.viewUrl ? (
                    <img
                      className="w-100 brand-image"
                      alt={"bgImage"}
                      src={customerData?.image?.viewUrl}
                    />
                  ) : (
                    <div className="w-100 brand-image"></div>
                  )}
                  <div className="brandPage-content">
                    <div className="d-flex profile-container justify-content-between">
                      <img
                        alt={"logo"}
                        src={
                          customerData.logo
                            ? customerData.logo.viewUrl
                              ? customerData.logo.viewUrl
                              : BuyerImage
                            : BuyerImage
                        }
                        className="brand-profile"
                      />
                      <div className="profile-content justify-content-between">
                        <label className="body-text-heading">
                          {customerData?.name}
                        </label>
                      </div>
                      {/* <label className="body-text-heading">
                        {customerData?.stats + "+ Projects"}
                      </label> */}
                    </div>
                    <Detail customerData={customerData} />
                    <div className="mt-3">
                      <label className="body-text-heading mb-0 ">
                        About all
                      </label>
                      <p className="word-wrap">{customerData.about}</p>
                    </div>
                    <div className="mt-3">
                      <label className="body-text-heading mb-0 ">POC </label>
                    </div>
                    <PocTable
                      companyTypeReceived={location?.state?.customerType}
                      type={ENTERPRISEAGENT}
                      view={true}
                      viewPage={true}
                    />

                    <Reports
                      customerUserId={customerData?._id}
                      type={"eagent"}
                    />
                    {subscriptionData.length > 0 ? (
                      <div className="mt-3 subscription-card">
                        <div className="row">
                          <div className="col">
                            <label
                              title="Subscription"
                              className="text-heading"
                            >
                              Subscription
                            </label>
                          </div>
                          <div className="col ">
                            <div className="d-flex gap-3 float-end align-items-center">
                              <Button
                                type="button"
                                className="btn-outline-color-primary d-block border-0"
                                disabled={customerData?.isActive ? false : true}
                                onClick={(e) => {
                                  dispatch(updateCompanyFormStatus("edit"));
                                  navigate(
                                    "/company/enterpriseAgentCompanyCreation",
                                    {
                                      state: {
                                        fromCustomerSeller: true,
                                        edit: true,
                                        subscriptionEdit: true,
                                      },
                                    }
                                  );
                                }}
                              >
                                <span className="me-2 text-blue ">
                                  Edit Plan
                                </span>
                                <FaPen className="text-blue" />
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-4 mb-4">
                          {subscriptionData?.map((item, i) => {
                            return (
                              <div
                                className="subscription-detail-columns"
                                key={i}
                              >
                                <p className="subscription-key">{item.title}</p>
                                <p className="subscription-value word-wrap">
                                  {item.key === "propertyOrDemand"
                                    ? item.value
                                    : item.key === "expiredate"
                                    ? moment(item.value).format("DD-MM-YYYY")
                                    : item.value}
                                </p>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {customerData.cities.length > 0 ? (
                      <Cities data={customerData.cities} />
                    ) : (
                      ""
                    )}

                    <Property
                      access={access}
                      loading={loading}
                      featuredPositioningStatus={
                        customerData?.subscriptionPlanId?.featuredProperty ||
                        customerData?.subscriptionPlanId?.featuredPositioning
                      }
                      customerUserId={brandIdUpdate}
                      customerCityData={customerData?.cities}
                      image={
                        customerData.logo
                          ? customerData.logo.viewUrl
                            ? customerData.logo.viewUrl
                            : BuyerImage
                          : BuyerImage
                      }
                      developerId={brandIdUpdate}
                      type={ENTERPRISEAGENT}
                    />
                  </div>
                </div>
              ) : (
                <div>"No data found"</div>
              )}
            </>
          )}
        </>
      ) : (
        <AccessDenied />
      )}
    </>
  );
};
export default EnterpriseAgentPage;
