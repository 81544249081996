import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {
  deleteData,
  getAllSwitchUsers,
  getCurrentUser,
  getData,
  postData,
  putData,
  switchToUsers,
} from "../../../api/index";
import { BsFillEyeFill } from "react-icons/bs";
import { FaPen, FaTrash } from "react-icons/fa";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { AppContext } from "../../../App";
import switchuser from "../../../assets/images/switchuser.png";
import Button from "../../../components/Form/Button";
import MainTable from "../../../components/Table/index";
import {
  getTableStoreValue,
  setTableFilterData,
} from "../../../utilities/pageScroll";
import { CustomerWarningPopup } from "../../Customer/CustomerTable/warningPopup";
import {
  updateBrandId,
  updateComapanyId,
  updateCompanyFormStatus,
  updatePhysicalTeamId,
  updateHasSubBrand,
} from "../../Login/reducer";
import SwitchSellerAccountPopup from "../../../components/switchModal/SwitchCompanySellerPopup";
import { CustomerDeleteWarningPopUp } from "../../Customer/CustomerTable/CustomerDeleteWarningPopUp";
import SwitchEnterpriseAgentAccountPopup from "../../../components/switchModal/SwitchCompanyEnterpriseAgent";
import { ListPopup } from "../../../components/Modal/listModal";
import Loader from "../../../components/Loader";
import {
  PHYSICALTEAM,
  MULTIPLE_SWITCH,
  SWITCH_LABEL,
} from "../../../custom/constants";

const PhysicalTeamTable = (props) => {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let tableName = "physicalTeam";
  const { tableStoreContext, setTableStoreContext } = useContext(AppContext);
  const currentTableValueFromConText = getTableStoreValue(
    tableName,
    tableStoreContext
  );
  const storetableName = currentTableValueFromConText?.tableName;
  const storePerPage = currentTableValueFromConText?.perPage;
  let checkForTableName = tableName === storetableName;

  //loading
  const [initLoading, setInitLoading] = useState(false);

  //list
  const [listData, setListData] = useState([]);
  //pagination
  const [perPage, setPerPage] = useState(
    checkForTableName ? (storePerPage ? storePerPage : 10) : 10
  );
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  //active
  const [activeStatus, setActiveStatus] = useState(false);
  const [customerId, setCustomerId] = useState("");
  const [warningMessage, setWarningMessage] = useState("");
  const [warningPopup, setWarningPopup] = useState(false);

  //Delete
  const [deleteWarningPopup, setDeleteWarningPopup] = useState(false);
  const [deleteWarningMessage, setDeleteWarningMessage] = useState("");
  const [activeCompanyId, setActiveCompanyId] = useState("");

  // account switch modal
  const [switchModal, setSwitchModal] = useState(false);
  const [pocs, setPocs] = useState({});
  const [companyId, setCompanyId] = useState(null);

  const [showListPopup, setShowListPopup] = useState(false);
  const [cityList, setcityList] = useState([]);
  const [switchEnv, setSwitchEnv] = useState(process.env.REACT_APP_SWITCH_URL);

  let search = props.search ? props.search : "";
  let activeFilterKey = props.activeFilterKey ? props.activeFilterKey : "";
  let cityFilterKey = props.cityFilterKey ? props.cityFilterKey : "";
  const setContextValue = (id) => {
    let newValue = setTableFilterData(
      tableName,
      { search: props?.search, pageNo: props.offSet, perPage: perPage, id: id },
      tableStoreContext
    );
    setTableStoreContext(newValue);
  };

  const handleAccountSwitch = async (
    id,
    SWITCH_USER = process.env.REACT_APP_SWITCH_URL
  ) => {
    setCompanyId(id);
    setSwitchEnv(SWITCH_USER);
    getAllSwitchUsers(id).then((res) => {
      if (res.status && res.statusCode === 200) {
        const eagentPOCs = res?.data?.physicalTeamPOCs;
        const combinedArrays = [...eagentPOCs];
        if (combinedArrays.length === 1) {
          const user = combinedArrays[0];
          let data = {
            companyId: id,
            customerId: user._id,
            type: "user",
          };
          switchToUsers(data).then((res) => {
            let openUrl = SWITCH_USER;
            if (res.status) {
              window.open(`${openUrl}/${res?.data?.token}`);
            }
          });
        } else if (combinedArrays.length < 1) {
          Swal.fire({
            // title: header,
            text: "No Poc Found",
            icon: "error",
            timer: 3000,
            buttons: false,
            showCancelButton: false,
            showConfirmButton: false,
            showCloseButton: true,
          });
        } else {
          setSwitchModal(true);
          setPocs({
            eagentPOCs,
          });
        }
      }
    });
  };

  const getCustomerList = () => {
    setInitLoading(true);
    let url = `/company/?pageNo=${
      props.offSet ? props.offSet : 1
    }&limit=${perPage}&type=${"Physical Team"}`;

    if (search) {
      url += `&search=${search}`;
    }
    if (activeFilterKey === "Active") {
      url += `&isActive=${true}`;
    }
    if (activeFilterKey === "Inactive") {
      url += `&isActive=${false}`;
    }
    if (cityFilterKey !== "City" && cityFilterKey !== "") {
      url += `&cities=${cityFilterKey}&id=${props.cityId}`;
    }
    getData(url).then((response) => {
      if (response.status) {
        setInitLoading(false);
        setPageCount(Math.ceil(response.data.totalDoc / perPage));
        setTotalCount(response.data.totalDoc);
        setListData(response.data.companies);
        localStorage.removeItem("customerData");
      } else {
        setInitLoading(false);
        setPageCount(0);
        setListData([]);
      }
    });
  };

  const warningYesClick = () => {
    let status = {
      isActive: activeStatus ? false : true,
      isCompany: true,
    };
    let url = `/brand/${customerId}`;
    putData(url, {}, status).then((response) => {
      if (response.status) {
        getCustomerList();
        setWarningPopup(false);
      } else {
        setWarningPopup(false);
      }
    });
  };

  const deleteWarningYesClick = () => {
    let url = `/company/deleteCompany/${activeCompanyId}`;
    deleteData(url, {}, {}).then((response) => {
      if (response.status) {
        getCustomerList();
        setDeleteWarningPopup(false);
      } else {
        setDeleteWarningPopup(false);
      }
    });
  };

  const handleWarningClose = (type) => {
    if (type === "delete") {
      setDeleteWarningPopup(false);
    } else {
      setWarningPopup(false);
    }
  };
  const onActiveStatusChange = (id, active) => {
    setActiveStatus(active);
    setCustomerId(id);
    setWarningPopup(true);
    if (active === true) {
      setWarningMessage("Are you sure, you want to deactivate this company");
    } else if (active === false) {
      setWarningMessage("Are you sure, you want to activate this company");
    }
  };

  const handelOpenListPopup = (cityData) => {
    setcityList(cityData);
    setShowListPopup(true);
  };
  const onCloseCityListPopup = () => {
    setcityList([]);
    setShowListPopup(false);
  };

  function userButtonFormatterSeller(cell, row, rowIndex) {
    return (
      <>
        <div className="d-flex justify-content-center align-items-center ">
          {props?.access?.switchingCapabilityCompanyPhysicalTeam ? (
            <>
              <div class="overflow-hidden">
                  <img
                    style={{ cursor: "pointer", width: "17px", height: "17px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      handleAccountSwitch(
                        row?.companyId,
                        process.env.REACT_APP_REVAMP_SWITCH_URL
                      );
                    }}
                    alt="switch"
                    src={switchuser}
                  />

                  {/* <div style={{ padding: 2 }}>
                    <p class="text-gray-700 mb-0" style={{ fontSize: "10px" }}>
                      {SWITCH_LABEL.SWITCH2}
                    </p>
                  </div> */}
                </div>
              {/* {MULTIPLE_SWITCH && (
                <div class="overflow-hidden">
                  <img
                    style={{ cursor: "pointer", width: "17px", height: "17px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      handleAccountSwitch(
                        row?.companyId,
                        process.env.REACT_APP_REVAMP_SWITCH_URL
                      );
                    }}
                    alt="switch"
                    src={switchuser}
                  />

                  <div style={{ padding: 2 }}>
                    <p class="text-gray-700 mb-0" style={{ fontSize: "10px" }}>
                      {SWITCH_LABEL.SWITCH2}
                    </p>
                  </div>
                </div>
              )} */}
            </>
          ) : (
            ""
          )}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Button
            onClick={(e) => {
              localStorage.removeItem("propTabKey");
              dispatch(updatePhysicalTeamId(row?._id));
              localStorage.setItem("customerId", JSON.stringify(row._id));
              navigate(`/company/physicalTeamPage/${row.slug}`, {
                state: {
                  id: row._id,
                  view: true,
                  customerType: row?.subBrands
                    ? "companyYesDeveloper"
                    : "companyNoDeveloper",
                },
              });
            }}
            disabled={props?.access?.viewCompanyPhysicalTeam ? false : true}
            className={"table-btn p-0"}
          >
            <BsFillEyeFill />
          </Button>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Button
            className={"table-btn p-0"}
            onClick={(e) => {
              if (row.isActive) {
                setContextValue(row?._id);
                localStorage.setItem(
                  "enterpriseAgentEdit",
                  JSON.stringify(row?.subBrands)
                );
                dispatch(updateHasSubBrand(row?.subBrands));
                dispatch(updatePhysicalTeamId(row?._id));
                dispatch(updateComapanyId(row?._id));
                dispatch(updateCompanyFormStatus("edit"));
                navigate("/company/physicalTeamCompanyCreation", {
                  state: { edit: true },
                });
              }
            }}
            disabled={props?.access?.editCompanyPhysicalTeam ? false : true}
          >
            <FaPen />
          </Button>{" "}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Form.Check
            type="switch"
            id={`custom-switch_${rowIndex}}`}
            checked={row.isActive ? true : false}
            onChange={(e) => onActiveStatusChange(row._id, row.isActive)}
            disabled={
              props.access.companyPhysicalTeamEnableDisable ? false : true
            }
          />
          <Button
            className="table-btn p-0"
            onClick={(e) => {
              setActiveCompanyId(row?.companyId);
              setDeleteWarningPopup(true);
            }}
            disabled={props?.access?.deleteCompanyPhysicalTeam ? false : true}
          >
            <FaTrash />
          </Button>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
      </>
    );
  }
  const columnsBuyer = [
    {
      dataField: "sno",
      text: "S.no",
    },
    {
      dataField: "companyName",
      text: "Agent Name",
      formatter: (cellContent, row, rowIndex) => {
        if (row.companyName === null || !("companyName" in row)) {
          return "-";
        } else if (row.companyName) {
          return row.companyName;
        }
      },
    },
    // {
    //   dataField: "name",
    //   text: "Developer Name",
    // },

    {
      dataField: "city",
      text: "City",
      formatter: (cellContent, row, rowIndex) => {
        if (row.city === null || row?.city?.length === 0) {
          return "-";
        } else if (row?.city?.length > 0) {
          let cityArray = [];

          row?.city?.map((val, index) => {
            cityArray.push(val?.name);
            return val;
          });
          return (
            <>
              {cityArray?.length > 1 ? (
                <span>
                  {`${cityArray[0]},..`}
                  <span
                    className="view-all-btn cursor-pointer"
                    onClick={() => handelOpenListPopup(cityArray)}
                  >
                    (View all)
                  </span>
                </span>
              ) : (
                <span>{cityArray.toString()}</span>
              )}
            </>
          );
        }
      },
    },
    {
      dataField: "subscriptionPlanId",
      text: "Property Uploads",
      formatter: (cellContent, row, rowIndex) => {
        if (
          row?.subscriptionPlanId?.propertyUploads === null ||
          row?.subscriptionPlanId?.propertyUploads === undefined ||
          row?.subscriptionPlanId?.propertyUploads === "" ||
          !("companyId" in row)
        ) {
          return "-";
        } else if (row?.subscriptionPlanId) {
          return row?.subscriptionPlanId?.propertyUploads;
        }
      },
    },
    {
      dataField: "subscriptionPlanId",
      text: "Demand Uploads",
      formatter: (cellContent, row, rowIndex) => {
        if (
          row?.subscriptionPlanId?.demandUploads === null ||
          row?.subscriptionPlanId?.demandUploads === undefined ||
          row?.subscriptionPlanId?.demandUploads === "" ||
          !("companyId" in row)
        ) {
          return "-";
        } else if (row?.subscriptionPlanId) {
          return row?.subscriptionPlanId?.demandUploads;
        }
      },
    },
    // {
    //     dataField: "projectsAssociated",
    //     text: "Projects Associated",
    //     formatter: (cellContent, row, rowIndex) => {
    //         if (
    //             row?.projectsAssociated === null ||
    //             row?.projectsAssociated === undefined ||
    //             row?.projectsAssociated === "" ||
    //             !("companyId" in row)
    //         ) {
    //             return "-";
    //         } else if (row?.projectsAssociated) {
    //             return row?.projectsAssociated
    //         }
    //     },
    // },
    {
      dataField: "subscriptionPlanId",
      text: "Relationship Manager",
      formatter: (cellContent, row, rowIndex) => {
        if (
          ((row?.rm === null || row?.rm === "" || !("rm" in row)) &&
            (row?.subscriptionPlanId?.rm === null ||
              row?.subscriptionPlanId?.rm === "")) ||
          row?.subscriptionPlanId?.relationshipManager === "No"
        ) {
          return "-";
        } else {
          if ("rm" in row?.subscriptionPlanId) {
            return row?.subscriptionPlanId?.rm?.name;
          } else {
            return row?.rm?.name;
          }
        }
      },
    },
    {
      dataField: "subscriptionPlanId",
      text: "Subscription Valid Till",
      formatter: (cellContent, row, rowIndex) => {
        if (
          row?.subscriptionPlanId?.validDate === null ||
          row?.subscriptionPlanId?.validDate === undefined ||
          row?.subscriptionPlanId?.validDate === "" ||
          !("companyId" in row)
        ) {
          return "-";
        } else if (row?.subscriptionPlanId) {
          return new Date(
            row?.subscriptionPlanId?.validDate
          ).toLocaleDateString();
        }
      },
    },
    {
      dataField: "createdAt",
      text: "Created On",
      formatter: (cellContent, row, rowIndex) => {
        if (
          row.createdAt === null ||
          row.createdAt === "" ||
          !("createdAt" in row)
        ) {
          return "-";
        } else if (row?.createdAt) {
          return new Date(row?.createdAt).toLocaleString();
        }
      },
    },
    {
      dataField: "action",
      text: "Action",
      formatter: userButtonFormatterSeller,
    },
  ];

  const handelChangePerPage = (count) => {
    setPerPage(count);
    props?.setOffset(1);
  };
  //
  const tableProps = {
    data: listData,
    columns: columnsBuyer,
    offset: props?.offSet,
    setOffset: props?.setOffset,
    handelChangePerPage,
    pageCount,
    perPage,
    totalCount,
    tableName: tableName,
    setPerPage,
  };

  useEffect(() => {
    getCustomerList();
  }, [perPage, props?.offSet, search, activeFilterKey, cityFilterKey]);

  return (
    <div>
      {warningPopup && (
        <CustomerWarningPopup
          show={warningPopup}
          title={"Confirmation"}
          message={warningMessage}
          warningYesClick={warningYesClick}
          onClose={() => handleWarningClose("status")}
        />
      )}
      {deleteWarningPopup && (
        <CustomerDeleteWarningPopUp
          show={deleteWarningPopup}
          title={"Confirmation"}
          message={"Are you sure, you want to delete this company"}
          warningYesClick={deleteWarningYesClick}
          onClose={() => handleWarningClose("delete")}
        />
      )}
      {initLoading && <Loader className={"fixed"} />}
      <SwitchEnterpriseAgentAccountPopup
        show={switchModal}
        onClose={() => setSwitchModal(false)}
        data={pocs}
        companyId={companyId}
        REDIRECT_URL={switchEnv}
      />
      <MainTable {...tableProps} />
      {showListPopup && (
        <ListPopup
          onClose={onCloseCityListPopup}
          lists={cityList}
          show={showListPopup}
          title="Cities"
        />
      )}
    </div>
  );
};
export default PhysicalTeamTable;
