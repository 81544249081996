import "../propertyViewList/propertyDetail.css";
// import Loading from '../../assets/images/loading.jpg';

export default function BusinessUse(props) {
  const { apiData } = props;

  const camelCase = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <div className="container-fluid">
    {apiData?.endUse?.length > 0 ? (
      <div className="d-flex flex-wrap justify-content-start pt-2">
        {apiData?.endUse?.map((item, index) => (
          <div key={index} className="d-flex align-items-center justify-content-start mb-3 px-2" >
            <div
              style={{
                background: "#F4F6F5",
                color: "#0B262A",
                padding: "8px 16px",
                borderRadius: "8px",
                textAlign: "center",
              }}
              className="d-inline-flex"
            >
              <span className="text-content">{camelCase(item.name)}</span>
            </div>
          </div>
        ))}
      </div>
    ) : (
      <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
        No data found
      </div>
    )}
  </div>
  );
}
