import { useEffect, useState } from "react";
import Swal from "sweetalert2/dist/sweetalert2.js";

// components
import MainTable from "../../../../components/Table";
import Select from "../../../../components/Form/Select/index";
import Search from "../../../../components/Search/search";
import {
  getAllSwitchUsers,
  getCurrentUser,
  getData,
  getData2,
  switchToUsers,
} from "../../../../api";

import { Row, Col } from "react-bootstrap";

// icons and images
import switchuser from "../../../../assets/images/switchuser.png";
import CrossSign from "../../../../assets/images/CrossSign.svg";
import Loader from "../../../../components/Loader";
import SwitchAccountPopup from "../../../../components/switchModal/SwitchCompanyBuyerPopup";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { PHYSICALTEAM, MULTIPLE_SWITCH, SWITCH_LABEL } from "../../../../custom/constants";

const ListDemandPhysicalTeamTable = ({ filterData, trigger }) => {
  const [offSet, setOffset] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [tableData, setTableData] = useState([]);

  const [propertyTypeList, setPropertyTypeList] = useState([]);
  const [propertyType, setPropertyType] = useState();
  const [transactionType, setTransactionType] = useState();
  const [sort, setSort] = useState();

  let customerCreatedSortValues = [
    {
      name: "Newest",
      _id: "desc",
    },
    {
      name: "Oldest",
      _id: "asc",
    },
  ];

  const [search, setSearch] = useState("");

  const navigate = useNavigate();
  // account switch modal
  const [switchModal, setSwitchModal] = useState(false);
  const [pocs, setPocs] = useState({});
  const [companyId, setCompanyId] = useState(null);

  const [loading, setLoading] = useState(false);

  const loginPermission = useSelector((state) => state.login.userPermission);
  const menuId = useSelector((state) => state.login.menuId);
  let agentSwitch = loginPermission?.includes(menuId?.agentSwitch);
  //   setCompanyId(id);
  //   getAllSwitchUsers(id).then((res) => {
  //     if (res.status && res.statusCode === 200) {
  //       const companySpecificPOCs = res?.data?.companySpecificPOCs || [];
  //       // const brandPOCs = res?.data?.brandPOCs || [];
  //       // const regionPOCs = res?.data?.regionPOCs || [];
  //       const combinedArrays = [...companySpecificPOCs];
  //       if (combinedArrays.length === 1) {
  //         const user = combinedArrays[0];
  //         const data = {
  //           companyId: id,
  //           customerId: user._id,
  //           type: "user",
  //         };
  //         switchToUsers(data).then((res) => {
  //           let openUrl = process.env.REACT_APP_SWITCH_URL;
  //           if (res.status) {
  //             window.open(`${openUrl}/${res?.data?.token}`);
  //           }
  //         });
  //       } else if (combinedArrays.length < 1) {
  //         Swal.fire({
  //           // title: header,
  //           text: "No Poc Found",
  //           icon: "error",
  //           timer: 3000,
  //           buttons: false,
  //           showCancelButton: false,
  //           showConfirmButton: false,
  //           showCloseButton: true,
  //         });
  //       } else {
  //         setSwitchModal(true);
  //         setPocs({
  //           companyPocs: companySpecificPOCs,
  //         });
  //       }
  //     }
  //   });
  // };
  // const handleAccountSwitch = async (id, demandId) => {
  //   const data = {
  //     customerId: id,
  //     type: "user",
  //   };
  //   switchToUsers(data).then((res) => {
  //     let openUrl = process.env.REACT_APP_SWITCH_URL;
  //     if (res.status) {
  //       window.open(
  //         `${openUrl}/${res?.data?.token}?trd=/profile/manage-property/${demandId}`
  //       );
  //     } else {
  //       Swal.fire({
  //         // title: header,
  //         text: "No Poc Found",
  //         icon: "error",
  //         timer: 3000,
  //         buttons: false,
  //         showCancelButton: false,
  //         showConfirmButton: false,
  //         showCloseButton: true,
  //       });
  //     }
  //   });
  // };

  // let columnsBrand = [
  //   {
  //     dataField: "publishedDate",
  //     text: "Posted On",
  //     formatter: (cellContent, row, rowIndex) => {
  //       return (
  //         <div>
  //           {new Date(row.publishedDate).toLocaleString(undefined, {
  //             timeZone: "Asia/Kolkata",
  //           })}
  //         </div>
  //       );
  //     },
  //   },
  //   {
  //     text: "Agent Name",
  //     dataField: "agentName",
  //   },
  //   {
  //     text: "Demand Id",
  //     dataField: "demandId",
  //     formatter: (cellContent, row, rowIndex) => {
  //       return (
  //         <div
  //           className="hyperlinkText"
  //           onClick={(e) => {
  //             console.log("demandId ID: ", row.demandId);
  //           }}
  //         >
  //           {row.demandId}
  //         </div>
  //       );
  //     },
  //   },
  //   {
  //     text: "Property Type",
  //     dataField: "propertyType",
  //   },
  //   {
  //     text: "Transaction Type",
  //     dataField: "transactionType",
  //   },
  //   {
  //     text: "City",
  //     dataField: "city",
  //   },
  //   {
  //     dataField: "action",
  //     text: "Action",
  //     formatter: (cellContent, row, rowIndex) => {
  //       return (
  //         <div className="db-number-card-container">
  //           {getCurrentUser()?.role[0]?.name === "admin" ||
  //           getCurrentUser()?.role[0]?.name === "Relationship Manager" ? (
  //             <img
  //               style={{ cursor: "pointer", width: "20px", height: "20px" }}
  //               onClick={(e) => {
  //                 e.preventDefault();
  //                 handleAccountSwitch(row?._id);
  //               }}
  //               alt="switch"
  //               src={switchuser}
  //             />
  //           ) : (
  //             ""
  //           )}
  //         </div>
  //       );
  //     },
  //   },
  // ];
  const handleAccountSwitch = async (id, demandId, REDIRECT_URL=process.env.REACT_APP_SWITCH_URL) => {
    const data = {
      customerId: id,
      type: "user",
    };
    switchToUsers(data).then((res) => {
      let openUrl = REDIRECT_URL;
      if (res.status) {
        window.open(
          `${openUrl}/${res?.data?.token}?trd=/profile/manage-demand/${demandId}`
        );
      } else {
        Swal.fire({
          // title: header,
          text: "No Poc Found",
          icon: "error",
          timer: 3000,
          buttons: false,
          showCancelButton: false,
          showConfirmButton: false,
          showCloseButton: true,
        });
      }
    });
  };
  const columnsBrand = [
    {
      dataField: "publishedDate",
      text: "Timestamp",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div>
            {new Date(row.updatedAt).toLocaleString(undefined, {
              timeZone: "Asia/Kolkata",
            })}
          </div>
        );
      },
    },
    {
      text: "Physical Team Name",
      dataField: "brandName",
    },
    {
      text: "POC Name",
      dataField: "pocName",
    },
    {
      text: "Demand ID",
      dataField: "productId",
      formatter: (cellContent) => {
        return (
          <div
            className={`${cellContent && "hyperlinkText"}`}
            onClick={(e) => {
              if (cellContent) {
                navigate("DemandDetailView/" + cellContent);
              }
            }}
          >
            {cellContent ? cellContent : "-"}
          </div>
        );
      },
    },
    {
      text: "Transaction Type",
      dataField: "transactionType",
    },
    {
      text: "Property Type",
      dataField: "propertyType",
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div className="db-number-card-container">
            <>    
              <div class="overflow-hidden">
                <img
                  style={{
                    cursor: `${agentSwitch ? "pointer" : ""}`,
                    opacity: `${agentSwitch ? 1 : 0.5}`,
                    width: "20px",
                    height: "20px",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    if (agentSwitch) {
                      handleAccountSwitch(row?.userId, row?.productId);
                    }
                  }}
                  alt="switch"
                  src={switchuser}
                />
                {MULTIPLE_SWITCH &&
                  <div style={{padding : 2}}>
                      <p class="text-gray-700 mb-0" style={{fontSize: '10px'}}>{SWITCH_LABEL.SWITCH1}</p>
                  </div>}
              </div>
              {MULTIPLE_SWITCH &&
                <div class="overflow-hidden">
                  <img
                    style={{
                      cursor: `${agentSwitch ? "pointer" : ""}`,
                      opacity: `${agentSwitch ? 1 : 0.5}`,
                      width: "20px",
                      height: "20px",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      if (agentSwitch) {
                        handleAccountSwitch(row?.userId, row?.productId, process.env.REACT_APP_REVAMP_SWITCH_URL);
                      }
                    }}
                    alt="switch"
                    src={switchuser}
                  />      
                    <div style={{padding : 2}}>
                        <p class="text-gray-700 mb-0" style={{fontSize: '10px'}}>{SWITCH_LABEL.SWITCH2}</p>
                    </div>
                </div>} 
            </>      
          </div>
        );
      },
    },
    // {
    //   dataField: "action",
    //   text: "Action",
    //   formatter: (cellContent, row, rowIndex) => {
    //     return (
    //       <div className="db-number-card-container">
    //         <img
    //           style={{
    //             cursor: `${buyerSwitch ? "pointer" : ""}`,
    //             opacity: `${buyerSwitch ? 1 : 0.5}`,
    //             width: "20px",
    //             height: "20px",
    //           }}
    //           onClick={(e) => {
    //             e.preventDefault();
    //             if (buyerSwitch) {
    //               handleAccountSwitch(row?.userId, row?.productId);
    //             }
    //           }}
    //           alt="switch"
    //           src={switchuser}
    //         />
    //       </div>
    //     );
    //   },
    // },
  ];

  if (getCurrentUser()?.role[0]?.name === "admin") {
    const rmData = [
      {
        text: "Relationship Manager",
        dataField: "rmName",
        formatter: (cellContent, row, rowIndex) => {
          return <div>{cellContent ? cellContent : "-"}</div>;
        },
      },
    ];
    columnsBrand.splice(5, 0, ...rmData);
  }

  const getTableData = async (loadStatus) => {
    if (loadStatus) {
      setLoading(true);
    }
    // let url = `/dashboard/getCustomers?type=Brand&page=${offSet}&limit=${perPage}`;
    // let url = `/api/v1/property/viewProperties?type=Agent&pageNo=${offSet}&limit=${perPage}`;
    let url = `/demand/viewDemands?type=${PHYSICALTEAM}&pageNo=${offSet}&limit=${perPage}`;
    if (search.length > 2) {
      url += `&search=${search}`;
    }
    if (transactionType) {
      if (transactionType === "RevenueShare") {
        url += `&transactionType=Revenue Share`;
      } else if (transactionType === "MGRevenueShare") {
        url += `&transactionType=MG Revenue Share`;
      } else {
        url += `&transactionType=${transactionType}`;
      }
    }

    if (propertyType) {
      url += `&propertyType=${propertyType}`;
    }
    if (sort) {
      url += `&sort=${sort}`;
    }

    let cusomerData = await getData(url);

    const { statusCode, data } = cusomerData;
    if (statusCode === 200) {
      setPageCount(Math.ceil(data.total / perPage));
      setTotalCount(data.total);
      setTableData(data.demandList);
      setLoading(false);
    } else {
      setPageCount(0);
      setTableData([]);
      setLoading(false);
    }
  };

  const handelChangePerPage = (count) => {
    setPerPage(count);
    setOffset(1);
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
    setOffset(1);
  };
  let tempArray = [
    {
      publishedDate: "11/02/2023",
      agentName: "Kartik",
      demandId: "DIR 5771",
      propertyId: "46846563453",
      propertyType: "Retail",
      transactionType: "Lease",
      city: "Mumbai",
    },
    {
      publishedDate: "11/02/2023",
      agentName: "Ashish",
      demandId: "DIR 1678",
      propertyId: "46846563453",
      propertyType: "Retail",
      transactionType: "Lease",
      city: "Mumbai",
    },
    {
      publishedDate: "11/02/2023",
      agentName: "Nitesh",
      demandId: "DIR 16456",
      propertyId: "46846563453",
      propertyType: "Retail",
      transactionType: "Lease",
      city: "Mumbai",
    },
  ];

  let tableProps = {
    // data: tempArray,
    data: tableData,
    columns: columnsBrand,
    offset: offSet,
    setOffset: setOffset,
    handelChangePerPage,
    pageCount,
    perPage,
    totalCount,
  };

  useEffect(() => {
    if (search === "" || search.length > 2) getTableData(true);
  }, [perPage, offSet, search, propertyType, transactionType, sort]);

  useEffect(() => {
    getTableData(false);
  }, [trigger]);

  useEffect(() => {
    // getBrandList();
    getPropertyType();
  }, []);

  let customerTransactionTypeSortValues = [
    {
      _id: "Lease",
      name: "Lease",
    },
    {
      _id: "RevenueShare",
      name: "Revenue Share",
    },
    {
      _id: "MGRevenueShare",
      name: "MG Revenue Share",
    },
    {
      _id: "Buy",
      name: "Buy",
    },
  ];

  const getPropertyType = () => {
    let url = "/propertyType";
    getData(url, {}, {}).then((response) => {
      if (response?.status) {
        let propertyArray = [];
        response?.data?.map((data) => {
          propertyArray.push({
            name: data?.name,
            _id: data?._id,
          });
          return data;
        });
        setPropertyTypeList(propertyArray);
      }
    });
  };

  return (
    <>
      {loading && <Loader className={"fixed"} />}
      <div className="db-filter-container">
        <Row className=" filter-select">
          <>
            <Col>
              <Select
                placeholder="Posted on"
                options={customerCreatedSortValues}
                name={"Posted"}
                value={sort}
                onChange={(e) => {
                  setSort(e.target.value);
                  setOffset(1);
                }}
                // isHideSelect
              />
            </Col>
            <Col>
              <Select
                placeholder="Property Type"
                options={propertyTypeList}
                name={"PropertyType"}
                value={propertyType}
                onChange={(e) => {
                  setPropertyType(e.target.value);
                  setOffset(1);
                }}
                // isHideSelect
              />
            </Col>
            <Col>
              <Select
                placeholder="Transaction Type"
                options={customerTransactionTypeSortValues}
                name={"Transaction"}
                value={transactionType}
                onChange={(e) => {
                  setTransactionType(e.target.value);
                  setOffset(1);
                }}
                // isHideSelect
              />
            </Col>
          </>
        </Row>
        <Row className=" filter-search">
          <Col md={5} sm={6} className="d-flex align-items-start">
            <Search onChange={handleSearch} value={search} />
          </Col>
        </Row>
      </div>
      <MainTable {...tableProps} trigger={trigger} />
      <SwitchAccountPopup
        show={switchModal}
        onClose={() => setSwitchModal(false)}
        data={pocs}
        companyId={companyId}
      />
    </>
  );
};
export default ListDemandPhysicalTeamTable;
