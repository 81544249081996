import { Form } from "react-bootstrap";
import { BsFillEyeFill } from "react-icons/bs";
import { FaPen } from "react-icons/fa";
import Button from "../../../../../components/Form/Button";
import { agentFilterUrls, agentUrls } from "./urls";
import {
  PHYSICALTEAM,
  PHYSICAL_TEAM,
  PROPVANTAGE,
} from "../../../../../custom/constants";

// table data URL
export const getURL = (key, offsetValue, perPageValue) => {
  if (!agentUrls.hasOwnProperty(key)) return "";
  return agentUrls[key](offsetValue, perPageValue);
};

// get payload
export const getPayLoad = (data, offset, perPage) => {
  const defaultPayload = {
    pageNo: offset,
    limit: perPage,
  };
  const payloadMap = {
    physicalTeamList: {
      pageNo: offset,
      limit: perPage,
      type: PHYSICAL_TEAM,
    },
    physicalTeamPocList: {
      pageNo: offset,
      limit: perPage,
      roleType: PHYSICAL_TEAM,
      poc: "brandPoc",
    },
    inActiveDemandList: {
      pageNo: offset,
      limit: perPage,
      isActive: false,
      demandType: PROPVANTAGE,
    },
    demandPostedList: {
      pageNo: offset,
      limit: perPage,
      demandType: PROPVANTAGE,
    },
    demandContactVisibility: {
      pageNo: offset,
      limit: perPage,
      customerType: PROPVANTAGE,
    },
    propertyContactedDemandList: {
      pageNo: offset,
      limit: perPage,
      isNew: false,
      // type:"Agent",
      responseType: "contectSeller",
      respondedType: PHYSICAL_TEAM,
    },
    demandViewedContact: {
      pageNo: offset,
      limit: perPage,
      contactView: true,
    },
    activeDemandList: {
      pageNo: offset,
      limit: perPage,
      isActive: true,
      demandType: PROPVANTAGE,
    },
    demandResponseList: {
      pageNo: offset,
      limit: perPage,
      response: true,
      demandType: PROPVANTAGE,
    },
    propertyAcceptedList: {
      pageNo: offset,
      limit: perPage,
      demandStatus: "completed",
      propertyType: "accept",
      customerType: PROPVANTAGE,
    },
    propertyRejectedList: {
      pageNo: offset,
      limit: perPage,
      demandStatus: "rejected",
      customerType: PROPVANTAGE,
    },
    needMoreInfoList: {
      pageNo: offset,
      limit: perPage,
      propertyType: "needInfo",
      customerType: PROPVANTAGE,
    },
    propertyPostedAgainstList: {
      pageNo: offset,
      limit: perPage,
      demand: true,
      customerType: PROPVANTAGE,
    },
    propertyPostedList: {
      pageNo: offset,
      limit: perPage,
      customerType: PROPVANTAGE,
    },
    propertyResponseList: {
      pageNo: offset,
      limit: perPage,
      customerType: PROPVANTAGE,
      response: true,
    },
    propertyInActiveList: {
      pageNo: offset,
      limit: perPage,
      customerType: PROPVANTAGE,
      isActive: false,
    },
    propertyActiveList: {
      pageNo: offset,
      limit: perPage,
      customerType: PROPVANTAGE,
      isActive: true,
    },
    propertyContactedList: {
      pageNo: offset,
      limit: perPage,
      customerType: PROPVANTAGE,
    },
    mailingLogs: {
      pageNo: offset,
      limit: perPage,
      customerType: PHYSICAL_TEAM,
    },
  };
  return payloadMap[data] || defaultPayload;
};

// table data key
export const tableDataKey = (key) => {
  if (!key) return "";
  const lookup = {
    physicalTeamList: "dataList",
    physicalTeamPocList: "dataList",
    propertiesPostedList: "properties",
    propertyAcceptedList: "responseData",
    propertyRejectedList: "responseData",
    needMoreInfoList: "responseData",
    propertyContactedList: "responseData",
    propertyPostedList: "propertyData",
    propertyPostedAgainstList: "propertyData",
    propertyResponseList: "propertyData",
    propertyInActiveList: "propertyData",
    propertyActiveList: "propertyData",
    demandPostedList: "demands",
    inActiveDemandList: "demands",
    activeDemandList: "demands",
    demandResponseList: "demands",
    propertyContactedDemandList: "list",
    demandContactVisibility: "data",
    demandViewedContact: "data",
    mailingLogs: "data",
  };

  const dataKey = lookup[key];
  return dataKey;
};

// filter
// filter URL
export const getFilterURL = (key) => {
  if (!agentFilterUrls.hasOwnProperty(key)) return "";
  return agentFilterUrls[key]();
};

// brand name formatter for mailling log
export function brandNameFormatterForMallingLog() {
  return (cell, row, rowIndex) => {
    // if (row?.loginType === "Switch") {
    //   if (row?.behalfOf?.brandName) {
    //     return row.behalfOf?.brandName;
    //   } else {
    //     return "-";
    //   }
    // } else {
    //   if (row?.doneByUser?.brandName) {
    //     return row?.doneByUser?.brandName;
    //   } else if (row?.behalfOf?.brandName) {
    //     return row.behalfOf?.brandName;
    //   } else {
    //     return "-";
    //   }
    // }
    if (row?.otherInfo?.brandName) {
      return row?.otherInfo?.brandName;
    } else if (row?.behalfOf?.brandName) {
      return row.behalfOf?.brandName;
    } else {
      return "-";
    }
  };
}

// property id formatter for multiple properites
export function propertyIdAndDemandIdFormatterForMallingLogs(
  handelOpenListPopup,
  key
) {
  return (cell, row, rowIndex) => {
    const properties = row[key];
    let propertyOrDemandIds;
    if (properties.length === 0) {
      const demands = row?.demands;
      // demands.push({
      //   _id: "65a906dae44f1200390a0df8",
      //   productId: {
      //     prefix: "R",
      //     number: 276,
      //     display: "RD0276"
      //   }
      // })
      propertyOrDemandIds = demands.map((demand) => {
        return demand?.productId?.display ? demand?.productId?.display : "-";
      });
    } else {
      propertyOrDemandIds = properties.map((property) => {
        return property?.productId?.display
          ? property?.productId?.display
          : "-";
      });
    }

    return (
      <>
        {propertyOrDemandIds.length > 1 ? (
          <span>
            {`${propertyOrDemandIds[0]},..`}
            <span
              className="view-all-btn cursor-pointer"
              onClick={() => handelOpenListPopup(propertyOrDemandIds, key)}
            >
              (View all)
            </span>
          </span>
        ) : propertyOrDemandIds.length === 1 ? (
          <span>{propertyOrDemandIds[0]}</span>
        ) : (
          <span>-</span>
        )}
      </>
    );
  };
}

// property Id and Demand Id formatter for Malling logs
export function propertyIdAndDemandIdFormatterForMallingLog() {
  return (cell, row, rowIndex) => {
    if (row?.productId?.display) {
      return row?.productId?.display;
    } else {
      return "-";
    }
  };
}

// filter payload'
export const getFilterPayLoad = (data) => {
  const payloadMap = {
    physicalTeamList: {
      type: PHYSICAL_TEAM,
    },
    physicalTeamPocList: {
      pocType: "brandPoc",
      type: PHYSICAL_TEAM,
    },
    propertyPostedList: {
      customerType: PROPVANTAGE,
    },
    propertyResponseList: {
      customerType: PROPVANTAGE,
      response: true,
    },
    propertyPostedAgainstList: {
      customerType: PROPVANTAGE,
      response: true,
    },
    propertyActiveList: {
      customerType: PROPVANTAGE,
      isActive: true,
    },
    propertyInActiveList: {
      customerType: PROPVANTAGE,
      isActive: false,
    },
    propertyContactedList: {
      propertyType: "contectSeller",
      customerType: PROPVANTAGE,
    },
    propertyAcceptedList: {
      customerType: PROPVANTAGE,
      demandStatus: "completed",
      propertyType: "accept",
    },
    propertyRejectedList: {
      demandStatus: "rejected",
      customerType: PROPVANTAGE,
    },
    needMoreInfoList: {
      customerType: PHYSICAL_TEAM,
      propertyType: "needInfo",
    },
    inActiveDemandList: {
      isActive: false,
      customerType: PROPVANTAGE,
    },
    activeDemandList: {
      isActive: true,
      customerType: PROPVANTAGE,
    },
    demandResponseList: {
      response: true,
      customerType: PROPVANTAGE,
    },
    demandPostedList: {
      customerType: PROPVANTAGE,
    },
    propertyContactedDemandList: {
      //TODO
      // pageNo: offset,
      // limit: perPage,
      // isNew: false,
      // type: PHYSICAL_TEAM,
      customerType: PROPVANTAGE,
    },
    demandContactVisibility: {
      customerType: PROPVANTAGE,
    },
    mailingLogs: {
      isEmail: true,
      customerType: PHYSICAL_TEAM,
    },
  };

  return payloadMap[data];
};
// formatter

export function viewbuttonFormatter(onClick, access) {
  return (cell, row, rowIndex) => {
    return (
      <Button
        className="d-flex table-btn p-0 m-auto"
        disabled={access?.viewCompanyPhysicalTeam ? false : true}
        onClick={() => onClick(row)}
      >
        <BsFillEyeFill />{" "}
      </Button>
    );
  };
}
export function userButtonFormatterDemandPoc(
  page,
  openModal,
  openWaring,
  table,
  viewOnly = false,
  access
) {
  return (cell, row, rowIndex) => {
    let brandId = page === "companyPocList" ? row?.companyId : row?.brandId;
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        id={row?._id}
      >
        <Button
          className="table-btn p-0"
          disabled={
            row?.demandType === PROPVANTAGE
              ? access?.agentView
                ? false
                : true
              : access?.view
              ? false
              : true
          }
          onClick={() => openModal("view", brandId, row)}
        >
          <BsFillEyeFill />
        </Button>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        {viewOnly ? null : (
          <>
            <Button
              className="table-btn p-0"
              disabled={
                row?.demandType === PROPVANTAGE
                  ? row?.isActive && access?.agentEdit
                    ? false
                    : true
                  : row?.isActive && access?.edit
                  ? false
                  : true
              }
              onClick={() => openModal("edit", brandId, row)}
            >
              <FaPen />
            </Button>{" "}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Form.Check
              type="switch"
              id={`custom-switch_${rowIndex}}`}
              checked={row.isActive ? true : false}
              disabled={
                row?.demandType === PROPVANTAGE
                  ? access?.agentEnable
                    ? false
                    : true
                  : access.enable
                  ? false
                  : true
              }
              onChange={() => openWaring(row, row.isActive, table)}
              // disabled={props.access.enable?false:true}
            />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </>
        )}
      </div>
    );
  };
}
// export function properSizeFormatter() {
//   return (cell, row, rowIndex) => {
//     if(row.carpetAreaMin && row.carpetAreaMin) {
//       return `${row.carpetAreaMin ? row.carpetAreaMin : 0}-${
//         row.carpetAreaMax ? row.carpetAreaMax : 0
//       } sqft`;
//     } else {
//       return "-"
//     }

//   };
// }
export function userButtonFormatterPoc(
  page,
  openModal,
  openWaring,
  table,
  access
) {
  return (cell, row, rowIndex) => {
    let brandId = page === "companyPocList" ? row?.companyId : row?.brandId;
    return (
      <div className="d-flex justify-content-center align-items-center ">
        <Button
          className="table-btn p-0"
          disabled={access.viewCompanyPhysicalTeam ? false : true}
          onClick={() => openModal("view", brandId, row)}
        >
          <BsFillEyeFill />
        </Button>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Button
          className="table-btn p-0"
          disabled={access.editCompanyPhysicalTeam ? false : true}
          onClick={() => openModal("edit", brandId, row)}
        >
          <FaPen />
        </Button>{" "}
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Form.Check
          type="switch"
          id={`custom-switch_${rowIndex}}`}
          checked={row.isActive ? true : false}
          disabled={access.companyPhysicalTeamEnableDisable ? false : true}
          onChange={() => openWaring(row, row.isActive, table)}
          // disabled={props.access.enable?false:true}
        />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </div>
    );
  };
}
export function userButtonFormatterPropertyPoc(
  page,
  openModal,
  openWaring,
  table,
  access
) {
  console.log({ access });
  return (cell, row, rowIndex) => {
    let brandId = page === "companyPocList" ? row?.companyId : row?.brandId;
    return (
      <div className="d-flex justify-content-center align-items-center ">
        <Button
          className="table-btn p-0"
          disabled={
            row?.customerType === PROPVANTAGE
              ? access.agentView
                ? false
                : true
              : access.view
              ? false
              : true
          }
          onClick={() => openModal("view", brandId, row)}
        >
          <BsFillEyeFill />
        </Button>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Button
          className="table-btn p-0"
          disabled={
            row?.customerType === PROPVANTAGE
              ? row?.isActive && access.agentEdit
                ? false
                : true
              : row?.isActive && access.edit
              ? false
              : true
          }
          onClick={() => openModal("edit", brandId, row)}
        >
          <FaPen />
        </Button>{" "}
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Form.Check
          type="switch"
          id={`custom-switch_${rowIndex}}`}
          checked={row.isActive ? true : false}
          disabled={
            row?.customerType === PROPVANTAGE
              ? access.agentEnable
                ? false
                : true
              : access.enable
              ? false
              : true
          }
          onChange={() => openWaring(row, row.isActive, table)}
          // disabled={props.access.enable?false:true}
        />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </div>
    );
  };
}

export function postedByFormatter() {
  return (cell, row, rowIndex) => {
    if (row?.brandNameRequired) {
      return `${row.brandId ? row.brandId?.name : "Anonymous"}`;
    }
    return "Anonymous";
  };
}

export function properSizeFormatter() {
  return (cell, row, rowIndex) => {
    return `${row.carpetAreaMin ? row.carpetAreaMin : 0}-${
      row.carpetAreaMax ? row.carpetAreaMax : 0
    } sqft`;
  };
}

export function createdOnFormatter(key = "updatedAt") {
  return (cell, row, rowIndex) => {
    if (!row[key] || row[key] === null || row[key] === "" || !(key in row)) {
      return "-";
    }
    return new Date(row[key]).toLocaleString();
  };
}

export function dateFormatter(key) {
  return (cell, row, rowIndex) => {
    if (!row[key] || row[key] === null || row[key] === "" || !(key in row)) {
      return "-";
    }
    return new Date(row[key]).toLocaleString();
  };
}
export function cityFormatter(handelOpenListPopup) {
  return (cell, row, rowIndex) => {
    const cities = row?.cities
      ?.filter((city) => city?.name)
      .map((city) => city.name);
    const cityString = cities?.join(", ");

    if (!cityString) {
      return "-";
    }
    return (
      <>
        {cities.length > 1 ? (
          <span>
            {`${cities[0]},..`}
            <span
              className="view-all-btn cursor-pointer"
              onClick={() => handelOpenListPopup(cities)}
            >
              (View all)
            </span>
          </span>
        ) : (
          <span>{cityString}</span>
        )}
      </>
    );
  };
}
export function onClickIdFormater(key, onClickView, toPage = "") {
  return (cell, row, rowIndex) => {
    return (
      <div className="btn f-14 fw-700 btn btn-link link-btn table-link-btn">
        {" "}
        <span
          className="cursor-pointer"
          onClick={() => onClickView(row, toPage)}
        >
          {row[key]?.display}
        </span>
      </div>
    );
  };
}
export function onClickIdFormaterForContacted(key, onClickView, toPage = "") {
  return (cell, row, rowIndex) => {
    return (
      <div className="btn f-14 fw-700 btn btn-link link-btn table-link-btn">
        {" "}
        <span
          className="cursor-pointer"
          onClick={() => onClickView(row, toPage)}
        >
          {row[key]}
        </span>
      </div>
    );
  };
}
export function companyNameFormatter() {
  return (cell, row, rowIndex) => {
    return row?.company?.companyName;
  };
}

export function propertyTypeFormatter() {
  return (cell, row, rowIndex) => {
    return row?.propertyType?.name;
  };
}

// optional field
export function projectNameFormatter() {
  return (cell, row, rowIndex) => {
    return row?.projectName !== "" ? row?.projectName : "-";
  };
}

export function onClickCountFormater(key, onClickView) {
  return (cell, row, rowIndex) => {
    return (
      <div className="btn f-14 fw-700 btn btn-link link-btn table-link-btn">
        {" "}
        <span className="cursor-pointer" onClick={() => onClickView(row)}>
          {row[key]}
        </span>
      </div>
    );
  };
}
