import React from "react";

import SwitchModal from ".";
import switchuser from "../../assets/images/switchuser.png";
import { switchToUsers } from "../../api";

const SwitchCustomerEnterprisePopup = ({ show, onClose, data, companyId, SWITCH_URL=process.env.REACT_APP_REVAMP_SWITCH_URL  }) => {
  const switchAccount = (userId) => {
    const data = {
      companyId: companyId,
      customerId: userId,
      type: "user",
    };
    switchToUsers(data).then((res) => {
      let openUrl = SWITCH_URL;
      if (res.status) {
        onClose();
        window.open(`${openUrl}/${res?.data?.token}`);
      }
    });
  };
  return (
    <SwitchModal
      onClose={onClose}
      show={show}
      heading={"Switch Account"}
      size={"md"}
      loading={false}
      loader="fixed"
    >
      <div className="conatiner">
        {data?.brandPocs && data?.brandPocs.length > 0 && (
          <div className="row my-1">
            <div className="col col-12 fs-5 text-blue">Brand POC</div>
          </div>
        )}
        {data?.brandPocs &&
          data?.brandPocs.map((poc) => {
            return (
              <div className="row py-1   border-bottom" key={poc._id}>
                <div className="col col-8 ">{poc.name}</div>
                <div className="col col-4 d-flex align-items-center justify-content-end">
                  <img
                    style={{ cursor: "pointer", width: "17px", height: "17px" }}
                    className="float-end"
                    onClick={() => switchAccount(poc?._id)}
                    alt="switch"
                    src={switchuser}
                  />
                </div>
              </div>
            );
          })}
        <hr />
        {data?.regionPocs && data?.regionPocs.length > 0 && (
          <div className="row mb-1">
            <div className="col col-12 fs-5 text-blue">Region POC</div>
          </div>
        )}

        {data?.regionPocs &&
          data?.regionPocs.map((poc) => {
            return (
              <div className="row  py-1 border-bottom" key={poc._id}>
                <div className="col col-8 ">{poc.name}</div>
                <div className="col col-4 d-flex align-items-center justify-content-end">
                  <img
                    style={{ cursor: "pointer", width: "17px", height: "17px" }}
                    className="float-end"
                    onClick={() => switchAccount(poc?._id)}
                    alt="switch"
                    src={switchuser}
                  />
                </div>
              </div>
            );
          })}

        {data?.regionPocs &&
          data?.regionPocs.length === 0 &&
          data?.brandPocs &&
          data?.brandPocs.length === 0 && (
            <div className="d-flex align-items-center justify-content-center">
              No Pocs found
            </div>
          )}
      </div>
    </SwitchModal>
  );
};

export default SwitchCustomerEnterprisePopup;
