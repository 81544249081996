export const agentUrls = {
    agentList: () =>"/admin/eagentNew",
    agentPocList: () =>"/admin/eAgentPocNew",
    propertyAcceptedList:() =>"/admin/propertyAccepted",
    propertyRejectedList:() =>"/admin/propertyAccepted",
    needMoreInfoList:() => "/admin/propertyAccepted",
    propertyContactedList:()=>"/admin/propertyContacted",
    // propertyContactedDemandList:()=>'/admin/propertyContacted',
    propertyContactedDemandList:()=>'/dashboard/getPropertyResponses',
    propertyPostedList:()=>"/admin/popertyPosted",
    propertyPostedAgainstList:()=>"/admin/popertyPosted",
    propertyResponseList:()=>"/admin/popertyPosted",
    propertyInActiveList:()=>"/admin/popertyPosted",
    propertyActiveList:()=>"/admin/popertyPosted",
    demandPostedList: () =>'/admin/demandPosted',
    // demandResponseList: () => '/demand/getAllAgentDemandResponse',
    demandResponseList: () => '/admin/demandPosted',
    inActiveDemandList: () =>'/admin/demandPosted',
    activeDemandList: () => '/admin/demandPosted',
    demandContactVisibility: () => '/eventLog/getAllContactVisibilityForAdmin',
    demandViewedContact: () => '/eventLog/getAllContactVisibilityForAdmin',
    mailingLogs: () => "/eventLog/getMailingLogForAdmin",
  };
  
  export const agentFilterUrls = {
    agentList: () =>"/admin/companyFilterNew",
    agentPocList: () =>"/admin/pocFilterNew",
    propertyAcceptedList:() =>"/admin/propertyReportFilter",
    propertyRejectedList: () => "/admin/propertyReportFilter",
    needMoreInfoList:() =>"/admin/propertyReportFilter",
    propertyContactedList:()=>"/admin/propertyReportFilter",
    propertyContactedDemandList:()=>'/admin/propertyReportFilter',
    propertyPostedList:()=>"/admin/popertyPostedFilter",
    propertyPostedAgainstList:()=>"/admin/popertyPostedFilter",
    propertyResponseList:()=>"/admin/popertyPostedFilter",
    propertyInActiveList:()=>"/admin/popertyPostedFilter",
    propertyActiveList:()=>"/admin/popertyPostedFilter",
    demandPostedList: ()=>"/admin/demandPostedFilter",
    inActiveDemandList:()=> "/admin/demandPostedFilter",
    activeDemandList:()=> "/admin/demandPostedFilter",
    demandResponseList:()=> "/admin/demandPostedFilter",
    demandContactVisibility: () => '/admin/demandPostedFilter',
    demandViewedContact: () => '/admin/demandPostedFilter',
    mailingLogs: () => "/eventLog/getAllEventName",
  }