import React, { useEffect, useRef, useState } from "react";
import { getData, postGetData } from "../../../api";
import { Row, Col, Form } from "react-bootstrap";
import Input from "../../../components/Form/Input";
import Select from "../../../components/Form/Select";
import Button from "../../../components/Form/Button";
import { OfficeSpaceAdditonalnput } from "../options";
import MultiSelect from "../../../components/select/dropDown";
import { useLocation } from "react-router-dom";
import { coworkFormatData } from "../coworkingSpaceOptions";
const CoworkingFormatData = ({
  gotoStep,
  propertyDetail,
  step,
  properytyObject,
  setProperyObject,
  coworkingFormatDataStepButtonRef,
  coworkingFormatDataStepFormRef,
  setChanged,
  isExitData,
  setFormatError,
  formatError,
  formatAmountError,
  setFormatAmountError,
  coworkFormatDivRef
}) => {
  const [properytyData, setPropertyData] = useState([]);
  // const [properytyObject, setProperyObject] = useState({});
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [datasAmenitey, setDatasAmenitey] = useState([]);
  const [idealData, setIdealData] = useState([]);
  const [coworkFields, setcoworkFields] = useState([]);
  const [validAmountError, setValidAmountError] = useState({});
  const inputRef = useRef(null)

  useEffect(() => {
    if(!isExitData) {
      // setProperyObject({})
      setFormatError(false)
    }
  }, [])
  const handleChange = (e, item) => {
    const { name } = item;
    const { id } = item;
    delete validAmountError[id]
    const value = e.target?.value;
    setFormatError(false)
    setFormatAmountError(false)
    if(isExitData) {
      const filtredData = properytyObject?.format.filter((data) => data?.name ===  item?.id)
      if(filtredData.length > 0) {
        var newData = properytyObject?.format.map((data) => {
          if(data?.name === item?.id) {
            return {...data, amount: value}
          }else {
            return data
          }
        })
        setProperyObject((prev) => {
          return {format: [...newData]}
        });
      } else {
       const  newfield = coworkFields.filter((data) => data?.name === item?.id)
        setProperyObject((prev) => {
          return {format: [...prev?.format, {...newfield[0], amount: value}]}
        });
      }
    }else {
      var newData = properytyObject.map((data) => {
        if(data?.name === item?.id) {
          return {...data, amount: value}
        }else {
          return data
        }
      })
      setProperyObject([...newData]);
    }
    
    
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (!form.checkValidity()) {
      event.preventDefault();
      event.stopPropagation();
    }
    let errorMessage = 'Please enter correct format value'
    if(isExitData) {
      properytyObject.format.map((data) => {
        let {name} = data
        if(data.amount !== '' && Number(data.amount) <= 0 ) {
          setValidAmountError((prev) => {
            return {...prev, [name]: errorMessage}
          })
        } else if(isNaN(data.amount)) {
          setValidAmountError((prev) => {
            return {...prev, [name]: errorMessage}
          })
        }

      })
    } else {
      properytyObject.map((data) => {
        let {name } = data
        if((data.amount !== '' && Number(data.amount) <= 0)) {
          setValidAmountError((prev) => {
            return {...prev, [name]: errorMessage}
          })
        }else if(isNaN(data.amount)) {
          setValidAmountError((prev) => {
            return {...prev, [name]: errorMessage}
          })
        }
      })
    }
    setValidated(true);
  };

  const handleCoworkData = async() => {
    let url = `/coWorking/getCoWorking`
    const apiDatas = await getData(url, {}, {});
    const { statusCode, data } = apiDatas;
    if (statusCode === 200) {
      if(!isExitData) {
        if(properytyObject.length < 5) {
          data?.map((item) => {
            setProperyObject((prev) => {
              return [...prev, {_id: item?._id, amount:"", name: item?.name}]
            })
          })
        }
      }
      setcoworkFields([...data])
    }
  }

  const handleMultiChange = (selectedData, selectedname) => {
    const optionData = { ...properytyObject };
    optionData[selectedname] = selectedData;
    setProperyObject(optionData);
    setChanged(true)
  };
  const handleAmenitey = async () => {
    let url = "/amenity?type=Co-working%20space";
    const apiDatas = await getData(url, {}, {});
    const { statusCode, data } = apiDatas;
    if (statusCode === 200) {
      data?.forEach((val) => {
        val.label = val.name;
        val.value = val._id;
      });
      setDatasAmenitey(data);
    }
  };
  // const getIdealForApiData = async () => {
  //   let url = "/endUse/getEndUses?type=Co-working%20space";
  //   // let objData = {
  //   //   type: "Office space",
  //   // };
  //   const newData = await getData(url, {}, {});
  //   const { statusCode, data, status } = newData;
  //   if (status && statusCode === 200) {
  //     data.forEach((val) => {
  //       val.label = val.name;
  //       val.value = val._id;
  //     });
  //     setIdealData(data);
  //   }
  // };
  useEffect(() => {
    setPropertyData(coworkFormatData);
    // handleAmenitey();
    // getIdealForApiData();
    
      handleCoworkData()
    
    
    // coworkFormatData.forEach((item) => {
    //   setProperyObject((prev) => ({
    //     ...prev,
    //     [item.name]: "",
    //   }));
    // });
    if (propertyDetail?.format) {
      const formatData = propertyDetail?.format.map((data) => {
        const filteredData = coworkFormatData.map((codata) => {
          if(codata?.id === data?.name && data?.name === 'Dedicated Desk') {
            return {
              dedicatedDesk: data?.amount ? data?.amount: "",
            }
          }
          if(codata?.id === data?.name && data?.name === 'Flexi Desk') {
            return {
              flexiDesk: data?.amount  ? data?.amount: "",
            }
          }
          if(codata?.id === data?.name && data?.name === 'Meeting Room') {
            return {
              meetingRoom: data?.amount  ? data?.amount: "",
            }
          }
          if(codata?.id === data?.name && data?.name === 'Managed Office') {
            return {
              managedOffices: data?.amount  ? data?.amount: "",
            }
          }
          if(codata?.id === data?.name && data?.name === 'Cabins') {
            return {
              cabins: data?.amount  ? data?.amount: "",
            }
          }
          return null;
        }).filter(obj => obj !== null)
        return {...filteredData[0]}
      }).filter(obj => obj !== null)
      var singleObject = formatData.reduce((acc, curr) => ({ ...acc, ...curr }), {});
      let exitingObj = {
        format: [...propertyDetail?.format],
        ...singleObject
      };
      setProperyObject(exitingObj);
    }
  }, []);
  const properyDetailUpdate = async (data) => {
    const res = await postGetData("/property", {}, data);
    const { statusCode } = res;
    if (statusCode === 200) {
      setLoading(false);
      gotoStep(3, true);
    }
  };
  const gotoBack = () => {
    gotoStep(step - 1);
  };
  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && event.target.tagName !== 'TEXTAREA') {
      event.preventDefault();
      handleSubmit(event);
    }
  };



  return (
    <div className="property-details-container pt-4" ref={coworkFormatDivRef}>
      <Row>
        <Col md="1" />
        <Col md="10">
          <h4>Flex Space Format</h4>
          <h6 className="text-muted">{`( Note: Please provide information for at least one format )`}</h6>
          <Form noValidate validated={validated} onSubmit={handleSubmit} ref={coworkingFormatDataStepFormRef}>
            <Row>
              {/* <Col md="6">
                <MultiSelect
                  onChange={(e) => handleMultiChange(e, "idealFor")}
                  isMulti={true}
                  options={idealData}
                  value={properytyObject["idealFor"]}
                  lable="Ideal For"
                  placeholder="Select Ideal For"
                />
              </Col> */}
              {/* <Col md="6">
                <MultiSelect
                  onChange={(e) => handleMultiChange(e, "otherAmenities")}
                  isMulti={true}
                  options={datasAmenitey}
                  value={properytyObject["otherAmenities"]}
                  lable="Amenities available"
                  placeholder="Select Amenities"
                />
              </Col> */}
              {properytyData &&
                properytyData.map((item, index) => (
                  <React.Fragment key={index}>
                    {item.type == "text" || item.type == "number" ? (
                      <Col md="6">
                        <Input
                          {...item}
                          value={properytyObject[item.name]}
                          onChange={(e) => handleChange(e, item)}
                          isInvalid = {validAmountError[item?.id]}
                          errorMessage = {validAmountError[item?.id]}
                        />
                      </Col>
                    ) : null}
                    {item.type == "select" && (item.name !== "powerBackup" && item.name !== "availablefloors"  && item.name !== "mezzainineAvailable") ? (
                      <Col md="6">
                        <Select
                          {...item}
                          value={properytyObject[item.name]}
                          onChange={(e) => handleChange(e, item)}
                        />
                      </Col>
                    ) : null}
                    {item.type == "select" && (item.name === "powerBackup" || item.name === "availablefloors"  || item.name === "mezzainineAvailable") && JSON.parse(localStorage.getItem('keydemand_data'))?.type === "Developer" ? (
                      <Col md="6">
                        <Select
                          {...item}
                          value={properytyObject[item.name]}
                          onChange={(e) => handleChange(e, item)}
                        />
                      </Col>
                    ) : null}
                  </React.Fragment>
                ))}
              
              <Col md="12" hidden={true}>
                <div className="text-end">
                  <Button
                    type="button"
                    className="pre-btn  me-3"
                    onClick={gotoBack}
                  >
                    Previous
                  </Button>
                  <Button
                    type="submit"
                    className="next-btn p-0"
                    loading={loading}
                    _ref={coworkingFormatDataStepButtonRef}
                  >
                    Next
                  </Button>
                </div>
              </Col>
              {formatError && <div className="text-danger text-center">{`Enter amount for atleast one format`}</div>}
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
};
export default CoworkingFormatData;
