import React, { useState, useEffect } from "react";
// import {
//   postGetData,
//   postUploadData,
// } from "../../../api";
import { Row, Col, Form, Button } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { HiUpload } from "react-icons/hi";
// import { getDocumentProjectData } from "../options";
// import Button from "../../../components/Form/Button";
import swal from "sweetalert";
// import Loader from "../../../components/Loader";
import "../../pages/AddNewProperty/style.scss";
import { getDocumentProjectData } from "../../pages/AddNewProperty/options";
import Loader from "../Loader";
import { postUploadData } from "../../api";
import { toast } from "react-toastify";
// import { swal } from "sweetalert2/dist/sweetalert2";
const UploadPropertyDocuments = ({
  gotoStep,
  propertyDetail,
  step,
  initData,
  properytyInput,
  setPropertyInput,
  uploadDocumentStepButtonRef,
  setChanged,
  projectID,
  type,
}) => {
  console.log({ aman: properytyInput });
  // const [properytyInput, setPropertyInput] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  useEffect(() => {
    if (type === "add") {
      getDocumentProjectData.forEach((data, i) => {
        getDocumentProjectData[i].uploadFiles = [];
      });
    }
    setPropertyInput(getDocumentProjectData);
    if (propertyDetail?.otherDocuments) {
      getDocumentProjectData.forEach((item) => {
        let docData = Object.keys(propertyDetail.otherDocuments);

        docData.forEach((document) => {
          if (item.name === document) {
            item.uploadFiles = propertyDetail.otherDocuments[item.name];
          }
        });
      });
    } else {
      setPropertyInput([
        {
          label: "Floorplans available",
          type: "file",
          name: "floorPlans",
          floorPlans: [],
          uploadFiles: [],
        },
        {
          label: "Brochure available",
          type: "file",
          name: "brochure",
          Brochure: [],
          uploadFiles: [],
        },
      ]);
    }
  }, []);

  const UPloadImage = async (datas, selectedField, file) => {
    setFileLoading(true);
    let url = `/user/file-upload/${projectID ? projectID : localStorage.getItem("ProjectId")
      }?type=image&module=project`;
    const resData = await postUploadData(url, {}, datas);
    const { statusCode, data } = resData;
    if (statusCode === 200) {
      data.forEach((element) => {
        const optionData = [...properytyInput];
        optionData.forEach((data) => {
          if (data.name === selectedField.name) {
            data.uploadFiles.push(element);
          }
        });

        setPropertyInput(optionData);
      });
      setFileLoading(false);
    }
    setFileLoading(false);
  };

  const [totalSize, settotalSize] = useState(0);

  function uploadFiles(file, maxTotalSize, selectedField, datas, totalSize) {
    if (totalSize + file.size > maxTotalSize) {
      swal({
        text: "Total file size should be less than 20 MB",
        icon: "warning",
        timer: 3000,
        buttons: false,
        showCloseButton: true,
      });
      return; // Exit if total size exceeds 20 MB
    } else {
      settotalSize((prev) => {
        return prev + file.size;
      });
    }

    // Append valid files to FormData
    datas.append("uploadfiles", file);
    if (datas.has("uploadfiles")) {
      UPloadImage(datas, selectedField);
    }
  }

  const UploadImageValidation = (imagedata, selectedField) => {
    const { files } = imagedata.target;
    let datas = new FormData();
    const maxTotalSize = 20 * 1024 * 1024; // 20 MB
    let selectedFileTypes = {
      "brochure": ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
      "floorPlans": ['image/png', 'image/jpeg', 'image/webp']
    }

    let validationsMessage = {
      "brochure": "Accept Only PDF/DOC files",
      "floorPlans": "Accept Only Images files"
    }

    // Iterate through files and check individual and total file size
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileType = file.type;

      if (!selectedFileTypes[selectedField?.name]?.includes(fileType)) {
        swal({
          text: validationsMessage[selectedField?.name],
          icon: "warning",
          timer: 3000,
          buttons: false,
          showCloseButton: true,
        });
      } else {
        uploadFiles(file, maxTotalSize, selectedField, datas, totalSize)
      }

    }

  };

  const handleChange = (e, item) => {
    setChanged(true);
    UploadImageValidation(e, item);
  };

  // const handleDatas = async (documentDetails) => {
  //   setLoading(true);
  //   let finaldata = {
  //     step: 4,
  //     propertyId: initData.propertyId,
  //     documents: documentDetails,
  //   };
  //   const res = await postGetData("/property", {}, finaldata);

  //   const { statusCode } = res;
  //   if (statusCode === 200) {
  //     setLoading(false);
  //     gotoStep(5, true);
  //   }
  //   setLoading(false);
  // };

  const handleSubmit = (event) => {
    event.preventDefault();
    let documentDetails = {};
    properytyInput.forEach((element) => {
      documentDetails[element.name] = element.uploadFiles;
    });
    // handleDatas(documentDetails);
  };

  const removeFile = (data, mainIndex, removeIndex) => {
    const optionData = [...properytyInput];
    optionData[mainIndex].uploadFiles.splice(removeIndex, 1);
    setPropertyInput(optionData);
  };
  const gotoBack = () => {
    gotoStep(step - 1);
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter" && event.target.tagName !== "TEXTAREA") {
      event.preventDefault();
      handleSubmit(event);
    }
  };
  useEffect(() => {
    if (step && step !== 5) {
      document.addEventListener("keydown", handleKeyPress);
      return () => {
        document.removeEventListener("keydown", handleKeyPress);
      };
    }
  }, [properytyInput]);
  return (
    <div className="">
      <Row>
        {/* <Col md="12" /> */}
        <Col md="12">
          {/* <h4>Documents</h4> */}
          <Form onSubmit={handleSubmit}>
            <Row>
              {properytyInput &&
                properytyInput.map((item, index) => (
                  <React.Fragment key={index}>
                    {item.type === "file" ? (
                      <Col md="12">
                        <div>
                          <Form.Label>
                            <span className="f-14 fw-700">{item.label}</span>
                          </Form.Label>
                          <div className="upload-lable">
                            <div className="p-1 upload-container">
                              <div className="d-flex  flex-wrap justify-content-start align-items-center upload-container-wrapper">
                                {item?.uploadFiles?.map(
                                  (innerdata, documentIndex) => {
                                    return (
                                      <div
                                        className="m-1 uploadUIWidth"
                                        key={documentIndex}
                                      >
                                        <div className="upload-list ">
                                          <label
                                            className="NameOfFile"
                                            title={
                                              innerdata.name
                                                ? innerdata.name
                                                : innerdata.fileName
                                            }
                                          >
                                            {innerdata.name
                                              ? innerdata.name
                                              : innerdata.fileName}
                                          </label>
                                          &nbsp;
                                          <AiOutlineClose
                                            className="closeButtonUpload"
                                            style={{
                                              color: "white",
                                              fontSize: "em",
                                              paddingLeft: "3px",
                                              cursor: "pointer",
                                            }}
                                            size={20}
                                            onClick={() =>
                                              removeFile(
                                                item,
                                                index,
                                                documentIndex
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                              <div className="upload-icon">
                                <label className="cursor-point">
                                  <div>
                                    <HiUpload
                                      size={20}
                                      style={{
                                        color: "#3E4958",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </div>
                                  <input
                                    name="file"
                                    id="id"
                                    type="file"
                                    onChange={(e) => handleChange(e, item)}
                                    className={"d-none"}
                                    // multiple={props.multiple?true:false}
                                    multiple={true}
                                    accept=".pdf,image/jpeg,image/jpg,image/gif,image/png,application/pdf,image/x-eps"
                                  />
                                </label>
                              </div>
                            </div>
                            <span
                              style={{
                                fontSize: "11px",
                                color: "gray",
                                marginLeft: "10px",
                              }}
                            >
                              File Size not more than 20 MB
                            </span>
                          </div>
                        </div>
                      </Col>
                    ) : null}
                  </React.Fragment>
                ))}
              <Col md="12" hidden={true}>
                <div className="text-end">
                  <Button
                    type="submit"
                    className="next-btn p-0"
                    loading={loading}
                    _ref={uploadDocumentStepButtonRef}
                  >
                    Next
                  </Button>
                </div>
              </Col>
            </Row>
            {fileLoading && <Loader />}
          </Form>
        </Col>
      </Row>
    </div>
  );
};
export default UploadPropertyDocuments;
