import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Form } from "react-bootstrap";
import MainTable from "../../../../../components/Table";

import { BsFillEyeFill } from "react-icons/bs";
import lockIcon from "../../../../../assets/images/lock1_1.svg";
import { FaPen, FaTrash } from "react-icons/fa";
import {
  deleteData,
  getCurrentUser,
  getData,
  putData,
  resetPassword,
} from "../../../../../api";
import FormModal from "../../../../../components/Modal";
import { CustomerWarningPopup } from "../../../CustomerTable/warningPopup";
import BuyerPocModal from "../pocModal/buyerPocModal";
import SellerPocModal from "../pocModal/sellerPocModal";
import Button from "../../../../../components/Form/Button";
import { CustomerDeleteWarningPopUp } from "../../../CustomerTable/CustomerDeleteWarningPopUp";
import { ENTERPRISEAGENT, PHYSICALTEAM } from "../../../../../custom/constants";
import AgentPocModal from "../pocModal/agentPocModal";
import PhysicalTeamPocModal from "../pocModal/physicalTeamPocModal";

const BrandPOCTable = forwardRef(
  (
    { companyTypeReceived, modkey, type, brandID, view, viewPage, access },
    ref
  ) => {
    const [listData, setListData] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [openWarningModal, setOpenWarningModal] = useState();

    // pagination
    const [perPage, setPerPage] = useState(10);
    const [pageCount, setPageCount] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [offset, setOffset] = useState(1);
    //Delete
    const [deleteWarningPopup, setDeleteWarningPopup] = useState(false);
    const [deleteWarningMessage, setDeleteWarningMessage] = useState("");
    const [activePocId, setActivePocId] = useState("");

    // data
    const [rowObj, setRowObj] = useState({});
    const [pocType, setPocType] = useState("");
    const [pocId, setPocId] = useState("");
    const [status, setStatus] = useState(false);
    const [warningMessage, setWarningMessage] = useState("");

    //password reset
    const [passwordResetWarningPopup, setPasswordResetWarningPopup] =
      useState(false);
    const [currentUserId, setCurrentUserId] = useState("");

    useImperativeHandle(ref, () => ({
      getPocList() {
        let url = `/brand/poc/pocList?id=${brandID}&pageNo=${offset}&limit=${perPage}`;
        getData(url).then((response) => {
          if (response?.status) {
            setPageCount(Math.ceil(response.data.totalCount / perPage));
            setTotalCount(response.data.totalCount);
            setListData(response?.data?.pocList);
          }
        });
      },
    }));

    // PASSWORD RESET
    const handleResetPassword = (row) => {
      setCurrentUserId(row._id);
      setPasswordResetWarningPopup(true);
      setWarningMessage(
        "Are you sure, do you want to reset the login credentials?"
      );
    };

    const deleteWarningYesClick = () => {
      let url;
      if (type === "enterpriseAgent") {
        url = `/user/deleteAgent/${activePocId}`;
      } else {
        url = `/brand/deletePoc/${activePocId}`;
      }
      deleteData(url, {}, {}).then((response) => {
        if (response.status) {
          setDeleteWarningPopup(false);
          getPocLists();
        } else {
          setDeleteWarningPopup(false);
        }
      });
    };

    const passwordResetWarningYesClick = () => {
      resetPassword(currentUserId).then(() => {
        setPasswordResetWarningPopup(false);
      });
    };

    const handlePasswordResetWarningClose = () => {
      setPasswordResetWarningPopup(false);
    };

    const getPocLists = () => {
      let url = `/brand/poc/pocList?id=${brandID}&pageNo=${offset}&limit=${perPage}`;
      getData(url).then((response) => {
        if (response?.status || response?.statusCode === 200) {
          setPageCount(Math.ceil(response.data.totalDoc / perPage));
          setTotalCount(response.data.totalDoc);
          setListData(response?.data?.pocList);
        }
      });
    };

    const onClickEdit = (row, actionType) => {
      setOpenModal(true);
      setPocType(actionType);
      setRowObj(row);
    };
    const onActiveStatusChange = (id, active) => {
      setOpenWarningModal(true);
      setWarningMessage(
        `Are you sure, you want to ${active ? "deactivate" : "active"} this POC`
      );
      setPocId(id);
      setStatus(active);
    };
    function userButtonFormatterBuyer(cell, row, rowIndex) {
      return (
        <div className="d-flex justify-content-center align-items-center ">
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              onClickEdit(row, "view");
            }}
          >
            <BsFillEyeFill />
          </div>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <div
            style={{
              cursor: row?.designation !== "Account Handler" && "pointer",
            }}
            onClick={
              view || !row.isActive || row?.designation === "Account Handler"
                ? null
                : () => onClickEdit(row, "edit")
            }
          >
            <FaPen />
          </div>{" "}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Form.Check
            type="switch"
            id={`custom-switch_${rowIndex}}`}
            checked={row.isActive ? true : false}
            onChange={
              view || row?.designation === "Account Handler"
                ? null
                : (e) => onActiveStatusChange(row._id, row.isActive)
            }
            disabled={
              view || row?.designation === "Account Handler" ? true : false
            }
          />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {(getCurrentUser()?.role[0]?.name === "admin" ||
            getCurrentUser()?.role[0]?.name === "Master Admin" ||
            getCurrentUser()?.role[0]?.name === "Relationship Manager") && (
            <>
              <div
                style={{
                  cursor: row?.designation !== "Account Handler" && "pointer",
                  opacity: row?.designation !== "Account Handler" ? 1 : 0.5,
                }}
                onClick={
                  view || row?.designation === "Account Handler"
                    ? null
                    : () => {
                        handleResetPassword(row);
                      }
                }
              >
                <img
                  src={lockIcon}
                  alt="lock icon"
                  style={{
                    cursor: row?.designation !== "Account Handler" && "pointer",
                    width: "20px",
                    height: "20px",
                    marginLeft: "-7px",
                  }}
                />
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </>
          )}
          {(type === ENTERPRISEAGENT || 
            type === PHYSICALTEAM ||
            type === "developer" ||
            type === "brand") && (
            <Button
              className="table-btn p-0"
              onClick={(e) => {
                setActivePocId(row?._id);
                setDeleteWarningPopup(true);
              }}
              // disabled={props?.access?.viewCompanySeller ? false : true}
              disabled={
                type === "brand"
                  ? access?.deleteBrandPoc
                    ? false
                    : true
                  : false
              }
            >
              <FaTrash />
            </Button>
          )}
        </div>
      );
    }
    const columnsBuyer = [
      {
        dataField: "sno",
        text: "S.no",
      },
      ...(companyTypeReceived === "companyNoBrand" ||
      companyTypeReceived === "companyNoDeveloper"
        ? [{ dataField: "primary", text: "Primary POC" }]
        : []),
      {
        dataField: "name",
        text: "POC Name",
      },
      {
        dataField: "designation",
        text: "Designation",
      },
      {
        dataField: "email",
        text: "Email ID",
      },
      {
        dataField: "phoneNumber",
        text: "Phone Number",
      },
      ...(!viewPage
        ? [
            {
              dataField: "action",
              text: "Action",
              formatter: userButtonFormatterBuyer,
            },
          ]
        : []),
    ];
    const handelChangePerPage = (count) => {
      setPerPage(count);
      setOffset(1);
    };
    const tableProps = {
      data: listData,
      columns: columnsBuyer,
      offset,
      setOffset,
      handelChangePerPage,
      pageCount,
      perPage,
      totalCount,
    };
    const handleClose = () => {
      setOpenModal(false);
    };
    const warningYesClick = () => {
      let data = {
        isActive: !status,
        type: "brandPoc",
      };
      let url = `/brand/poc/updatePoc/${brandID}/${pocId}`;
      putData(url, {}, data).then((response) => {
        if (response.status) {
          getPocLists();
          setOpenWarningModal(false);
        } else {
          setOpenWarningModal(false);
        }
      });
    };
    const handleWarningClose = (type) => {
      if (type === "delete") {
        setDeleteWarningPopup(false);
      } else {
        setOpenWarningModal(false);
      }
    };
    useEffect(() => {
      getPocLists();
    }, [perPage, offset]);

    return (
      <>
        <MainTable {...tableProps} />
        <FormModal
          show={openModal}
          onClose={handleClose}
          heading={
            pocType === "view"
              ? "View POC"
              : pocType === "edit"
              ? "Edit POC"
              : "Add POC"
          }
          children={
            type === "developer" ? (
              <SellerPocModal
                companyTypeReceived={companyTypeReceived}
                modKey={modkey}
                type={type}
                pocType={pocType}
                pocList={getPocLists}
                id={brandID}
                onClose={handleClose}
                pocEditData={rowObj}
              />
            ) : type === ENTERPRISEAGENT ? (
              <AgentPocModal
                companyTypeReceived={companyTypeReceived}
                modKey={modkey}
                type={type}
                pocType={pocType}
                pocList={getPocLists}
                id={brandID}
                onClose={handleClose}
                pocEditData={rowObj}
              />
            ) : type === "physicalTeam" ? (
              <PhysicalTeamPocModal
                companyTypeReceived={companyTypeReceived}
                modKey={modkey}
                type={type}
                pocType={pocType}
                pocList={getPocLists}
                id={brandID}
                onClose={handleClose}
                pocEditData={rowObj}
              />
            ) : (
              <BuyerPocModal
                companyTypeReceived={companyTypeReceived}
                modKey={modkey}
                type={type}
                pocType={pocType}
                pocList={getPocLists}
                id={brandID}
                onClose={handleClose}
                pocEditData={rowObj}
              />
            )
          }
          size={"lg"}
        />
        {openWarningModal && (
          <CustomerWarningPopup
            title={"Confirmation"}
            show={openWarningModal}
            message={warningMessage}
            warningYesClick={warningYesClick}
            onClose={handleWarningClose}
          />
        )}

        {deleteWarningPopup && (
          <CustomerDeleteWarningPopUp
            show={deleteWarningPopup}
            title={"Confirmation"}
            message={"Are you sure, you want to delete this POC"}
            warningYesClick={deleteWarningYesClick}
            onClose={() => handleWarningClose("delete")}
          />
        )}
        {passwordResetWarningPopup && (
          <CustomerWarningPopup
            title={"Confirmation"}
            show={passwordResetWarningPopup}
            message={warningMessage}
            warningYesClick={passwordResetWarningYesClick}
            onClose={handlePasswordResetWarningClose}
          />
        )}
      </>
    );
  }
);

export default BrandPOCTable;
