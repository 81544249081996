export const brandTrancationOptions = {
  retail: [
    {
      _id: "LEASE",
      name: "Lease",
    },
    {
      _id: "REVENUE_SHARE",
      name: "Revenue Share",
    },
    {
      _id: "MG_REVENUE_SHARE",
      name: "MG Revenue Share",
    },
    {
      _id: "BUY",
      name: "Buy",
    },
  ],
  office: [
    {
      _id: "LEASE",
      name: "Lease",
    },
    {
      _id: "BUY",
      name: "Buy",
    },
  ],
  cowork: [
    {
      _id: "LEASE",
      name: "Lease",
    },
  ],
  retailAndOffice: [
    {
      _id: "LEASE",
      name: "Lease",
    },
    {
      _id: "REVENUE_SHARE",
      name: "Revenue Share",
    },
    {
      _id: "MG_REVENUE_SHARE",
      name: "MG Revenue Share",
    },
    {
      _id: "BUY",
      name: "Buy",
    },
  ],
};

export const PropertyTrancationOptions = {
  retail: [
    {
      _id: "LEASE",
      name: "Lease",
    },
    {
      _id: "REVENUE_SHARE",
      name: "Revenue Share",
    },
    {
      _id: "MG_REVENUE_SHARE",
      name: "MG Revenue Share",
    },
    {
      _id: "SALES",
      name: "Sales",
    },
  ],
  office: [
    {
      _id: "LEASE",
      name: "Lease",
    },
    {
      _id: "SALES",
      name: "Sales",
    },
  ],

  cowork: [
    {
      _id: "LEASE",
      name: "Lease",
    },
  ],
  retailAndOffice: [
    {
      _id: "LEASE",
      name: "Lease",
    },
    {
      _id: "REVENUE_SHARE",
      name: "Revenue Share",
    },
    {
      _id: "MG_REVENUE_SHARE",
      name: "MG Revenue Share",
    },
    {
      _id: "SALES",
      name: "Sales",
    },
  ],
};

export const demandAndPropertyTransactionTypes = {
  retail: [
    {
      _id: "LEASE",
      name: "Lease",
    },
    {
      _id: "REVENUE_SHARE",
      name: "Revenue Share",
    },
    {
      _id: "MG_REVENUE_SHARE",
      name: "MG Revenue Share",
    },
    {
      _id: "SALES",
      name: "Sales",
    },
    {
      _id: "BUY",
      name: "Buy",
    },
  ],
  office: [
    {
      _id: "LEASE",
      name: "Lease",
    },
    {
      _id: "SALES",
      name: "Sales",
    },
    {
      _id: "BUY",
      name: "Buy",
    },
  ],

  cowork: [
    {
      _id: "LEASE",
      name: "Lease",
    },
  ],
  retailAndOffice: [
    {
      _id: "LEASE",
      name: "Lease",
    },
    {
      _id: "REVENUE_SHARE",
      name: "Revenue Share",
    },
    {
      _id: "MG_REVENUE_SHARE",
      name: "MG Revenue Share",
    },
    {
      _id: "SALES",
      name: "Sales",
    },
    {
      _id: "BUY",
      name: "Buy",
    },
  ],
};


export const getTransactionOptions = (data, options) => {
  let transactionOptions = []
  if (
    data?.propertyTypes &&
    data?.propertyTypes.length === 1
  ) {
    data.propertyTypes.map((item) => {
      if (item?.label === "Retail") {
        transactionOptions = [...options?.retail];
      } else if (item?.label === "Office space") {
        transactionOptions = [...options?.office];
      } else if (item?.label === "Flex Space") {
        transactionOptions = [...options?.cowork];
      }
    });
  } else if (
    data?.propertyTypes &&
    data.propertyTypes.length === 2
  ) {
    const namesArray = data?.propertyTypes.map(
      (property) => property.label
    );
    if (
      namesArray.includes("Retail") &&
      namesArray.includes("Office space")
    ) {
      transactionOptions = [...options?.retailAndOffice];
    } else if (
      namesArray.includes("Retail") &&
      namesArray.includes("Flex Space")
    ) {
      transactionOptions = [...options?.retailAndOffice];
    } else if (
      namesArray.includes("Office space") &&
      namesArray.includes("Flex Space")
    ) {
      transactionOptions = [...options?.office];
    }
  } else if(
    data?.propertyTypes &&
    data.propertyTypes.length === 3
  ) {
    transactionOptions = [...options?.retailAndOffice];
  }

  return transactionOptions
}