import { useMemo, useState, useCallback } from "react";
import { FaCloudUploadAlt } from "react-icons/fa";
import { useDropzone } from "react-dropzone";
import swal from "sweetalert";

import "react-toastify/dist/ReactToastify.css";

import "./style.scss";
const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "rgba(56, 78, 183, 0.3)",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};
export default function SingleImageDropzone(props) {

  const [fileList, setfileList] = useState([]);
  const [fileURL, setfileURL] = useState([]);

  const handleApiFile = (acceptedFiles) => {
    let acceptData = acceptedFiles;
    const formData = new FormData();
    formData.append("image", acceptData[0]);
    if (acceptData[0].size > 5000 * 1024 || acceptData[0].size === undefined) {
      swal({
        text: "file size should be less than 5 MB",
        icon: "warning",
        timer: 3000,
        buttons: false,
      });
      return;
    }

    props.onHandelImage(acceptData);

    // })
  };
  const onDrop = useCallback(
    (acceptedFiles, name, deletePosition) => {
      if (name !== "delete") {
        if (fileList.length !== 0) {
          acceptedFiles.forEach((file) => {
            const reader = new FileReader();
            reader.onload = () => {
              setfileURL((prev) => [...prev, reader.result]);
            };
            reader.readAsDataURL(file);
            setfileList([...fileList, file]);
          });
        } else {
          acceptedFiles.forEach((file) => {
            const reader = new FileReader();

            reader.onload = () => {
              setfileURL((prev) => [...prev, reader.result]);
            };
            reader.readAsDataURL(file);
            fileList.push(file);
          });
        }

        handleApiFile(acceptedFiles);
      } else {
        setfileList([]);
        acceptedFiles.forEach((file) => {
          const reader = new FileReader();
          reader.onload = () => {
            setfileURL((prev) => [...prev, reader.result]);
          };
          reader.readAsDataURL(file);
          setfileList((prev) => [...prev, file]);
        });
      }
    },
    [fileList, fileURL]
  );
  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop,
    maxFiles: 1,
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
    noDrag: true,
    minSize: 0,
    multiple: false,
  });
  const style = useMemo(
    () => ({
      ...baseStyle,
    }),
    []
  );

  return (
    <div>
      <div className="drop-container">
        <div className="container text-center">
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} accept="image/png, image/jpeg" />
            <div className="image-dropzoneWrapper image-container-grids">
              <div className={"dropZone-ImageSize grid-image"}>
                {props?.data?.viewUrl && (
                  <img
                    src={props?.data?.viewUrl}
                    alt="images"
                    className={"images-dropzone"}
                  />
                )}
              </div>
            </div>

            <FaCloudUploadAlt
              size="50px"
              color="#034168"
              onClick={open}
              className="cursor-pointer"
            />

            <span className="drag-container-span">
              Upload Banner Image{" "}
              <a className="drag-container-link" onClick={open} href="#">
                Browse
              </a>
              <br />
              Image should be under 5MB <br />
              Image resolution must be{" "}
              {props?.resolutionText ? props?.resolutionText : "1459 X 392"}
              <br />
            </span>
          </div>
        </div>
      </div>
      <span className="customInvalid">{props.error}</span>
    </div>
  );
}
