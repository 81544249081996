import { Form } from "react-bootstrap";
import { BsFillEyeFill } from "react-icons/bs";
import { FaPen } from "react-icons/fa";
import Button from "../../../../../components/Form/Button";
import { agentFilterUrls, agentUrls } from "./urls";

// table data URL
export const getURL = (key, offsetValue, perPageValue) => {
  if (!agentUrls.hasOwnProperty(key)) return "";
  return agentUrls[key](offsetValue, perPageValue);
};

// get payload
export const getPayLoad = (data, offset, perPage) => {
  const defaultPayload = {
    pageNo: offset,
    limit: perPage,
  };
  const payloadMap = {
    inActiveDemandList: {
      pageNo: offset,
      limit: perPage,
      isActive: false,
      demandType: "agent",
    },
    demandPostedList: {
      pageNo: offset,
      limit: perPage,
      demandType: "agent",
    },
    demandContactVisibility: {
      pageNo: offset,
      limit: perPage,
    },
    propertyContactedDemandList:{
      pageNo: offset,
      limit: perPage,
      isNew:false,
      // type:"Agent",
      responseType: "contectSeller",
      respondedType: "Agent"
    },
    demandViewedContact: {
      pageNo: offset,
      limit: perPage,
      contactView: true
    },
    activeDemandList: {
      pageNo: offset,
      limit: perPage,
      isActive: true,
      demandType: "agent",
    },
    demandResponseList: {
      pageNo: offset,
      limit: perPage,
      response: true,
      demandType: "agent",
    },
    propertyAcceptedList:{
      pageNo: offset,
      limit: perPage,
      demandStatus: "completed",
      propertyType: "accept",
      customerType: "Agent"
    },
    propertyRejectedList: {
      pageNo: offset,
      limit: perPage,
      demandStatus: "rejected",
      customerType: "Agent"
    },
    needMoreInfoList:{
      pageNo: offset,
      limit: perPage,
      propertyType: "needInfo",
      customerType: "Agent"
    },
    propertyPostedAgainstList:{
      pageNo: offset,
      limit: perPage,
    demand: true,
    customerType: "Agent"
    },
    propertyPostedList:{
      pageNo: offset,
      limit: perPage,
      customerType: "Agent"
    },
    propertyResponseList:{
      pageNo: offset,
      limit: perPage,
      customerType: "Agent",
      response: true,
    },
    propertyInActiveList:{
      pageNo: offset,
      limit: perPage,
      customerType: "Agent",
      isActive:false
    },
    propertyActiveList:{
      pageNo: offset,
      limit: perPage,
      customerType: "Agent",
      isActive:true
    },
    propertyContactedList:{
      pageNo: offset,
      limit: perPage,
      customerType: "Agent",
    }, 
    mailingLogs: {
      pageNo: offset,
      limit: perPage,
      customerType: "agent",
    },  
  };

  return (payloadMap[data] || defaultPayload);
}

// table data key
export const tableDataKey = (key) => {
  if (!key) return "";
  const lookup = {
    agentList: "dataList",
    propertiesPostedList: "properties",
    propertyAcceptedList: "responseData",
    propertyRejectedList: "responseData",
    needMoreInfoList: "responseData",
    propertyContactedList: "responseData",
    propertyPostedList: "propertyData",
    propertyPostedAgainstList: "propertyData",
    propertyResponseList: "propertyData",
    propertyInActiveList: "propertyData",
    propertyActiveList: "propertyData",
    demandPostedList: "demands",
    inActiveDemandList: "demands",
    activeDemandList: "demands",
    demandResponseList: "demands",
    propertyContactedDemandList: "list",
    demandContactVisibility: "data",
    demandViewedContact: "data",
    mailingLogs: "data",
  };

  const dataKey = lookup[key];
  return dataKey;
};

export const tableTotalDataKey = (key) => {
  if (!key) return "";
  const lookup = {
    agentList: "totalDataCount",
  };

  const dataKey = lookup[key];
  return dataKey;
};

// filter
// filter URL
export const getFilterURL = (key) => {
  if (!agentFilterUrls.hasOwnProperty(key)) return "";
  return agentFilterUrls[key]();
};

// filter payload'
export const getFilterPayLoad = (data) => {
  const payloadMap = {
    agentList: {
      type: "Agent",
    },
    propertyPostedList: {
      customerType: "Agent"
    },
    propertyResponseList: {
      customerType: "Agent",
      response: true,
    },
    propertyActiveList: {
      customerType: "Agent",
      isActive: true,
    },
    propertyInActiveList: {
      customerType: "Agent",
      isActive: false,
    },
    propertyContactedList: {
      propertyType: "contectSeller",
      customerType: "Agent"
    },
    propertyAcceptedList: {
      customerType: "Agent",
      demandStatus: "completed",
      propertyType: "accept",
    },
    propertyRejectedList: {
      demandStatus: "rejected",
      customerType: "Agent"
    },
    needMoreInfoList: {
      customerType: "Agent",
      propertyType: "needInfo",
    },
    inActiveDemandList: {
      isActive: false,
      customerType:"Agent"
    },
    activeDemandList: {
      isActive: true,
      customerType:"Agent"
    },
    demandResponseList: {
      response: true,
    },
    demandPostedList: {
      customerType:"Agent"
    },
    propertyContactedDemandList: {//TODO
      // pageNo: offset,
      // limit: perPage,
      isNew:false,
      type:"Agent",
    },
    demandContactVisibility:{
      customerType:"Agent"
    },
    mailingLogs: {
      isEmail: true,
      customerType: "agent",
    },
  };

  return payloadMap[data];
};
// formatter


// Agent name formatter for mailling log
export function agentNameFormatterForMallingLog() {
  return (cell, row, rowIndex) => {
    // if (row?.loginType === "Switch") {
    //   if (row?.behalfOf?.brandName) {
    //     return row.behalfOf?.brandName;
    //   } else {
    //     return "-";
    //   }
    // } else {
    //   if (row?.doneByUser?.brandName) {
    //     return row?.doneByUser?.brandName;
    //   } else if (row?.behalfOf?.brandName) {
    //     return row.behalfOf?.brandName;
    //   } else {
    //     return "-";
    //   }
    // }
    if (row?.otherInfo?.brandName) {
      return row?.otherInfo?.brandName;
    } else if (row?.behalfOf?.brandName) {
      return row.behalfOf?.brandName;
    } else {
      return "-";
    }
  };
}


// property id formatter for multiple properites
export function propertyIdAndDemandIdFormatterForMallingLogs(handelOpenListPopup, key) {
  return (cell, row, rowIndex) => {
    
    const properties = row[key];
    let propertyOrDemandIds ;
    if (properties.length === 0) {
      const demands = row?.demands;
      // demands.push({
      //   _id: "65a906dae44f1200390a0df8",
      //   productId: {
      //     prefix: "R",
      //     number: 276,
      //     display: "RD0276"
      //   }
      // })
      propertyOrDemandIds = demands.map((demand) => {
        return demand?.productId?.display ? demand?.productId?.display : "-";
      });
    }
    else{
      propertyOrDemandIds = properties.map((property) => {
        return property?.productId?.display ? property?.productId?.display : "-";
      });
    }
   
    return (
      <>
        {propertyOrDemandIds.length > 1 ? (
          <span>
            {`${propertyOrDemandIds[0]},..`}
            <span
              className="view-all-btn cursor-pointer"
              onClick={() => handelOpenListPopup(propertyOrDemandIds, key)}
            >
              (View all)
            </span>
          </span>
        ) : propertyOrDemandIds.length === 1 ? (
          <span>{propertyOrDemandIds[0]}</span>
        ) : (
          <span>-</span>
        )}
      </>
    );
  };
}
// property Id and Demand Id formatter for Malling logs
export function propertyIdAndDemandIdFormatterForMallingLog() {
  return (cell, row, rowIndex) => {
    if (row?.productId?.display) {
      return row?.productId?.display;
    } else {
      return "-";
    }
  };
}


export function viewbuttonFormatter(onClick,access) {
  return (cell, row, rowIndex) => {
    return (
      <Button
        className="d-flex table-btn p-0 m-auto"
        disabled={
          access?.viewAgentCustomers
            ? false
            : true
        }
        onClick={() => onClick(row)}
      >
        <BsFillEyeFill />{" "}
      </Button>
    );
  };
}
export function userButtonFormatterDemandPoc(page, openModal, openWaring, table,viewOnly=false,access) {
  return (cell, row, rowIndex) => {
    let brandId = page === "companyPocList" ? row?.companyId : row?.brandId;
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        id={row?._id}
      >
        <Button
         className="table-btn p-0"
          disabled={
            row?.demandType === "agent"
              ? access?.agentView
                ? false
                : true
              : access?.view
              ? false
              : true
          }
          onClick={() => openModal("view", brandId, row)}
        >
          <BsFillEyeFill />
        </Button>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        {viewOnly?null:<>
          <Button
            className="table-btn p-0"
            disabled={
              row?.demandType === "agent"
                ? row?.isActive && access?.agentEdit
                  ? false
                  : true
                : row?.isActive && access?.edit
                ? false
                : true
            }
            onClick={() => openModal("edit", brandId, row)}
          >
            <FaPen />
          </Button>{" "}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Form.Check
            type="switch"
            id={`custom-switch_${rowIndex}}`}
            checked={row.isActive ? true : false}
            disabled={
              row?.demandType === "agent"
                ? access?.agentEnable
                  ? false
                  : true
                : access.enable
                ? false
                : true
            }
            onChange={() => openWaring(row, row.isActive, table)}
            // disabled={props.access.enable?false:true}
          />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </>}
      </div>
    );
  };
}
// export function properSizeFormatter() {
//   return (cell, row, rowIndex) => {
//     if(row.carpetAreaMin && row.carpetAreaMin) {
//       return `${row.carpetAreaMin ? row.carpetAreaMin : 0}-${
//         row.carpetAreaMax ? row.carpetAreaMax : 0
//       } sqft`;
//     } else {
//       return "-"
//     }
    
//   };
// }
export function userButtonFormatterPoc(page, openModal, openWaring, table,access) {
  return (cell, row, rowIndex) => {
    let brandId = page === "companyPocList" ? row?.companyId : row?.brandId;
    

    return (
        <div className="d-flex justify-content-center align-items-center ">
          <Button
            className="table-btn p-0"
            disabled={
              row?.customerType === "Agent"
                ? access.agentView
                  ? false 
                  : true
                : access.view
                ? false
                : true
            }
            onClick={() =>{ openModal("view", brandId, row)}}
            
          >
            <BsFillEyeFill />
          </Button>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Button
            className="table-btn p-0"
            disabled={
              row?.customerType === "Agent"
                ? row?.isActive && access.agentEdit
                  ? false
                  : true
                : row?.isActive && access.edit
                ? false
                : true
            
            }
            onClick={ () => openModal("edit", brandId, row)}
          >
            <FaPen />
          </Button>{" "}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Form.Check
            type="switch"
            id={`custom-switch_${rowIndex}}`}
            checked={row.isActive ? true : false}
            onChange={() => openWaring(row, row.isActive, table)}
            // disabled={props.access.enable?false:true}
            disabled={
              row?.customerType === "Agent"
                ? access.agentEnable
                  ? false
                  : true
                : access.enable
                ? false
                : true
            }
          />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
    );
  };
}

export function postedByFormatter() {
  return (cell, row, rowIndex) => {
    if (row?.brandNameRequired) {
      return `${row.brandId ? row.brandId?.name : "Anonymous"}`;
    }
    return "Anonymous";
  };
}

export function properSizeFormatter() {
  return (cell, row, rowIndex) => {
    return `${row.carpetAreaMin ? row.carpetAreaMin : 0}-${
      row.carpetAreaMax ? row.carpetAreaMax : 0
    } sqft`;
  };
}

export function createdOnFormatter(key = "createdAt") {
  return (cell, row, rowIndex) => {
    if (!row[key] || row[key] === null || row[key] === "" || !(key in row)) {
      return "-";
    }
    return new Date(row[key]).toLocaleString();
  };
}

export function dateFormatter(key) {
  return (cell, row, rowIndex) => {
    if (!row[key] || row[key] === null || row[key] === "" || !(key in row)) {
      return "-";
    }
    return new Date(row[key]).toLocaleString();
  };
}
export function cityFormatter(handelOpenListPopup) {
  return (cell, row, rowIndex) => {
    const cities = row?.city
      ?.filter((city) => city?.name)
      .map((city) => city.name);
    const cityString = cities?.join(", ");

    if (!cityString) {
      return "-";
    }
    return (
      <>
        {cities.length > 1 ? (
          <span>
            {`${cities[0]},..`}
            <span
              className="view-all-btn cursor-pointer"
              onClick={() => handelOpenListPopup(cities)}
            >
              (View all)
            </span>
          </span>
        ) : (
          <span>{cityString}</span>
        )}
      </>
    );
  };
}
export function onClickIdFormater(key, onClickView, toPage = "") {
  return (cell, row, rowIndex) => {
    return (
      <div className="btn f-14 fw-700 btn btn-link link-btn table-link-btn">
        {" "}
        <span
          className="cursor-pointer"
          onClick={() => onClickView(row, toPage)}
        >
          {row[key]?.display}
        </span>
      </div>
    );
  };
}
export function onClickIdFormaterForContacted(key, onClickView, toPage = "") {
  return (cell, row, rowIndex) => {
    return (
      <div className="btn f-14 fw-700 btn btn-link link-btn table-link-btn">
        {" "}
        <span
          className="cursor-pointer"
          onClick={() => onClickView(row, toPage)}
        >
          {row[key]}
        </span>
      </div>
    );
  };
}
export function companyNameFormatter() {
  return (cell, row, rowIndex) => {
    return row?.company?.companyName;
  };
}

export function doneByUserFormatter() {
  return (cell, row, rowIndex) => {
    return row?.doneByUser?.name ? row?.doneByUser?.name : "-";
  };
}

export function fullNameFormatter() {
  return (cell, row, rowIndex) => {
    return `${row?.name?row?.name:"" }  ${row?.lastName?row?.lastName:"" }`;
  };
}
export function fullContatedByNameFormatter() {
  return (cell, row, rowIndex) => {
    return `${row?.contactUserName?row?.contactUserName:"" }  ${row?.contactUserLastName?row?.contactUserLastName:"" }`;
  };
}

export function propertyTypeFormatter() {
  return (cell, row, rowIndex) => {
    return row?.propertyType?.name;
  };
}

// User Type formatter
export function userTypeFormatterForViewedContact() {
  return (cell, row, rowIndex) => {
    if (row?.roles) {
      return row?.roles[0]
    } else {
      return "-";
      
    }
  };
}

// optional field
export function projectNameFormatter() {
  return (cell, row, rowIndex) => {
    return row?.projectName !== "" ? row?.projectName : "-";
  };
}

export function onClickCountFormater(key, onClickView) {
  return (cell, row, rowIndex) => {
    return (
      <div className="btn f-14 fw-700 btn btn-link link-btn table-link-btn">
        {" "}
        <span className="cursor-pointer" onClick={() => onClickView(row)}>
          {row[key]}
        </span>
      </div>
    );
  };
}
