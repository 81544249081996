import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.scss";
//pages
import Layout from "./layout";
import PostDemand from "./pages/AddNewDemand/index";
import Admin from "./pages/Admin/index";
import Customer from "./pages/Customer";
import AgentPageform from "./pages/Customer/CustomerForm/agentFormPage";
import BrandPageForm from "./pages/Customer/CustomerForm/brandPageForm";
import DeveloperPageForm from "./pages/Customer/CustomerForm/developerPageForm";
import BrandPage from "./pages/Customer/CustomerPages/brandPage";
import DeveloperPage from "./pages/Customer/CustomerPages/developerPage";
import DashBoard from "./pages/Dashboard";
import Demand from "./pages/Demand/index";
import Login from "./pages/Login";
import Properties from "./pages/PropertiesList/properties";
import PropertyDetailView from "./pages/PropertyDetailView";
import Subscription from "./pages/Subscription/index";
import DemandDetailView from "./pages/demandDetailView/demandDetailView";
import LocationManagement from "./pages/locationManagement/index";
import PropertyView from "./pages/propertyView/propertyView";
import Settings from "./pages/settings/index";
// add property rework
import NewAddProperty from "./pages/AddNewProperty";

//property against demand
// login
import { createContext, useState } from "react";
import LayoutNoSidebar from "./layout/layoutWithoutSidebar";
import Company from "./pages/Company";
import BrandCompany from "./pages/Company/CompanyForm/brandCompanyForm";
import AgentPage from "./pages/Customer/CustomerPages/agentpage";
import DashboardTable from "./pages/Dashboard/table";
import Enquires from "./pages/Enquiries";
import Forget from "./pages/Login/forgot";
import Reset from "./pages/Login/reset";
import ManageRoles from "./pages/ManageRoles";
import NotfoundPage from "./pages/PageNotFound";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Termsandconditions from "./pages/TermsAndCondition";
import Chat from "./pages/chat";
import ContentManagement from "./pages/contentManagement/contentManagement";
import Coupon from "./pages/coupon";
import DemandResponse from "./pages/demandResponse";
import Notification from "./pages/notification";
import PropertyRelevantPage from "./pages/propertyRelevantdemand";
import PropertyResponsePage from "./pages/propertyResponse";
import Reports from "./pages/reports";
import ReportData from "./pages/reports/userReports";
import AgentReportTable from "./pages/reports/userReports/reportTabels/agent/agentReport";
import BuyerReportTable from "./pages/reports/userReports/reportTabels/buyer/buyerReport";
import SellerReportTable from "./pages/reports/userReports/reportTabels/seller/sellerReport";
import ListDashboardTable from "./pages/Dashboard/listTables";
import ListResponseTable from "./pages/Dashboard/listTables/listResponseTable";
import RMDashBoard from "./pages/Dashboard/rmDashboard";
import ActivityLogs from "./pages/ActivityLogs";
import EnterpriseAgentPageForm from "./pages/Customer/CustomerForm/enterpriseAgentPageForm";
import EnterpriseAgentPage from "./pages/Customer/CustomerPages/enterpriseAgentPage";
import EnterpriseAgentReportData from "./pages/reports/userReports/reportTabels/enterpriseAgent/enterpriseAgentReportData";
import EnterpriseAgentReport from "./pages/reports/userReports/reportTabels/enterpriseAgent/enterpriseAgentReport";
import PhysicalTeamFromPage from "./pages/Customer/CustomerForm/physicalTeamFormPage";
import PhysicalTeamReport from "./pages/reports/userReports/reportTabels/physicalTeam/physicalTeamReport";
import PhysicalTeamPage from "./pages/Customer/CustomerPages/physicalTeamPage";
import Projects from "./pages/Project/Projects";
import { CreateProject } from "./pages/Project/CreateProject/CreateProject";
import EditProjectPage from "./pages/Project/editPorject";
import ViewProject from "./pages/Project/viewproject";

export const AppContext = createContext();

function App() {
  //  After B2B
  const [pageScroll, setPageScroll] = useState([]);
  const [tableStoreContext, setTableStoreContext] = useState([]);
  const rmDashBoardName = "dashboard";
  return (
    <AppContext.Provider
      value={{
        setPageScroll,
        pageScroll,
        tableStoreContext,
        setTableStoreContext,
      }}
    >
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/login" exact={true} element={<Login />} />
            <Route path="/forgot" exact={true} element={<Forget />} />
            <Route
              path="/reset-password/:id"
              exact={true}
              element={<Reset />}
            />
            <Route path="/project/CreateProject" exact={true} element={<CreateProject />} />
              <Route
                path="/project/:slug"
                exact={true}
                element={<EditProjectPage />}
              />
                  <Route
                path="/viewproject/:slug"
                exact={true}
                element={<ViewProject />}
              />
            {/* pages with sidebar */}
            <Route path="/" element={<Layout />}>
              <Route path="/" exact={true} element={<RMDashBoard />} />
              {/* <Route path="dashboard" exact={true} element={<DashBoard />} /> */}
              <Route
                path={`${rmDashBoardName}`}
                exact={true}
                element={<RMDashBoard />}
              />
              <Route path="reports" exact={true} element={<Reports />} />
              {/* customer */}
              <Route path="customer" exact={true} element={<Customer />} />
              <Route path="company" exact={true} element={<Company />} />
              {[
                "/company/brandCompanyCreation",
                "/buyerReport/companyList/brandCompanyCreation",
              ].map((path, index) => (
                <Route
                  path={path}
                  key={index}
                  exact={true}
                  element={<BrandCompany />}
                />
              ))}
              {/* <Route path="/company/developerCompanyCreation" exact={true} element={<DeveloperCompany />} /> */}
              <Route
                path="/privacyPolicy"
                exact={true}
                element={<PrivacyPolicy />}
              />
              <Route
                path="/termsConditions"
                exact={true}
                element={<Termsandconditions />}
              />
              <Route path="/enquiry" exact={true} element={<Enquires />} />
              { <Route path="/project" exact={true} element={<Projects />} />}
              
              <Route path="/settings" exact element={<Settings />} />
              <Route path="/properties" exact element={<Properties />} />

              <Route
                path="/customer/brandPageCreation"
                exact={true}
                element={<BrandPageForm />}
              />
              <Route path="/admin" exact={true} element={<Admin />} />
              <Route
                path="/activityLogs"
                exact={true}
                element={<ActivityLogs />}
              />
              <Route path="/demand" exact={true} element={<Demand />} />
              <Route
                path="/brandPage/:slug"
                exact={true}
                element={<BrandPage />}
              />
              {[
                "/customer/developerPageCreation",
                "/company/developerCompanyCreation",
              ].map((path, index) => (
                <Route
                  path={path}
                  key={index}
                  exact={true}
                  element={<DeveloperPageForm />}
                />
              ))}
              {["/company/enterpriseAgentCompanyCreation"].map((path, index) => (
                <Route
                  path={path}
                  key={index}
                  exact={true}
                  element={<EnterpriseAgentPageForm />}
                />
              ))}
              {["/company/physicalTeamCompanyCreation"].map((path, index) => (
                <Route
                  path={path}
                  key={index}
                  exact={true}
                  element={<PhysicalTeamFromPage />}
                />
              ))}
              {/* <Route path='/customer/developerPageCreation' exact={true}  element={<DeveloperPageForm />} /> */}
              <Route
                path="/customer/agentPageCreation"
                exact={true}
                element={<AgentPageform />}
              />
              <Route
                path="/subscription"
                exact={true}
                element={<Subscription />}
              />
              <Route
                path="/managerole"
                exact={true}
                element={<ManageRoles />}
              />
              <Route path="/chat" exact={true} element={<Chat />} />
              <Route
                path="/notification"
                exact={true}
                element={<Notification />}
              />
              <Route path="/coupon" exact={true} element={<Coupon />} />
              {/* property against demand */}
              <Route path={"/propertyView"} exact element={<PropertyView />} />
              <Route
                path="/contentManagement"
                exact={true}
                element={<ContentManagement />}
              />
              <Route
                path="/locationManagement"
                exact={true}
                element={<LocationManagement />}
              />
              {[
                "/buyerReport/companyList",
                "/buyerReport/pocList",
                "/buyerReport/pocList",
                "/buyerReport/brandList",
                "/buyerReport/demandList",
                "/buyerReport/pocList",
                "/buyerReport/demandPosted",
                "/buyerReport/demandResponse",
                "/buyerReport/activeDemand",
                "/buyerReport/inActiveDemand",
                "/buyerReport/demandList",
                "/buyerReport/demandList",
              ].map((path, index) => (
                <Route
                  path={path}
                  key={index}
                  exact={true}
                  element={<BuyerReportTable />}
                />
              ))}
              {[
                "/sellerReport/propertyList",
                "/sellerReport/developerList",
                "/sellerReport/pocList",
                "/sellerReport/pocList",
                "/sellerReport/propertyList",
              ].map((path, index) => (
                <Route
                  path={path}
                  key={index}
                  exact={true}
                  element={<SellerReportTable />}
                />
              ))}
              {[
                "/agentReport/propertyList",
                "/agentReport/agentList",
                "/agentReport/propertyList",
                "/agentReport/demandList",
                "/agentReport/contacts",
              ].map((path, index) => (
                <Route
                  path={path}
                  key={index}
                  exact={true}
                  element={<AgentReportTable />}
                />
              ))}

              {[
                "/enterpriseAgentReport/propertyList",
                "/enterpriseAgentReport/agentList",
                "/enterpriseAgentReport/agentpocList",
                "/enterpriseAgentReport/propertyList",
                "/enterpriseAgentReport/demandList",
                "/enterpriseAgentReport/contacts",
              ].map((path, index) => (
                <Route
                  path={path}
                  key={index}
                  exact={true}
                  element={<EnterpriseAgentReport />}
                />
              ))}

              {[
                "/physicalTeamReport/propertyList",
                "/physicalTeamReport/physicalTeamList",
                "/physicalTeamReport/physicalTeamPocList",
                "/physicalTeamReport/propertyList",
                "/physicalTeamReport/demandList",
                "/physicalTeamReport/contacts",
              ].map((path, index) => (
                <Route
                  path={path}
                  key={index}
                  exact={true}
                  element={<PhysicalTeamReport />}
                />
              ))}

              {[
                "/buyerReport",
                "/sellerReport",
                "/agentReport",
                "/enterpriseAgentReport",
                "/physicalTeamReport",
              ].map((path, index) => (
                <Route
                  path={path}
                  key={index}
                  exact={true}
                  element={<ReportData />}
                />
              ))}
              {["/demand/response"].map((path, index) => (
                <Route
                  path={path}
                  exact
                  key={index}
                  element={<DemandResponse />}
                />
              ))}
              {[
                "/sellerReport/propertyList/response",
                "/agentReport/propertyList/response",
                "/physicalTeamReport/propertyList/response",
              ].map((path, index) => (
                <Route
                  path={path}
                  exact
                  key={index}
                  element={<PropertyResponsePage />}
                />
              ))}
              {[
                "/sellerReport/propertyList/relevantDemand",
                "/agentReport/propertyList/relevantDemand",
                "/physicalTeamReport/propertyList/relevantDemand",
              ].map((path, index) => (
                <Route
                  path={path}
                  exact
                  key={index}
                  element={<PropertyRelevantPage />}
                />
              ))}
            </Route>
            {/*  */}

            <Route path="/" element={<LayoutNoSidebar />}>
              {[
                "/company/brandCompanyCreation/brandPage",
                "/company/brandCompanyCreation/brandPage/:slug",
                "/customer/brandPage",
                "/customer/brandPage/:slug",
                "/buyerReport/brandList/brandPage",
                "/buyerReport/brandList/brandPage/:slug",
                "/buyerReport/companyList/brandCompanyCreation/brandPage",
                "/buyerReport/companyList/brandCompanyCreation/brandPage/:slug",
              ].map((path, index) => (
                <Route
                  path={path}
                  key={index}
                  exact={true}
                  element={<BrandPage />}
                />
              ))}
              {[
                "/company/developerCompanyCreation/developerPage",
                "/company/developerCompanyCreation/developerPage/:slug",
                "/customer/developerPage",
                "/customer/developerPage/:slug",
                "/sellerReport/developerList/developerPage",
                "/sellerReport/developerList/developerPage/:slug",
              ].map((path, index) => (
                <Route
                  path={path}
                  key={index}
                  exact={true}
                  element={<DeveloperPage />}
                />
              ))}

              {[
                "/company/enterprise-agent",
                "/company/enterprise-agent/:slug",
                "/enterpriseAgentReport/agentList/enterprise-agent/:slug",
              ].map((path, index) => (
                <Route
                  path={path}
                  key={index}
                  exact={true}
                  element={<EnterpriseAgentPage />}
                />
              ))}
              {[
                "/company/physicalTeamPage",
                "/company/physicalTeamPage/:slug",
                "/physicalTeamReport/agentList/physicalTeam/:slug"
              ].map((path, index) => (
                <Route
                  path={path}
                  key={index}
                  exact={true}
                  element={<PhysicalTeamPage />}
                />
              ))}
              {[
                "/customer/agentPage/:slug",
                "/agentReport/agentList/agentPage/:slug",
              ].map((path, index) => (
                <Route
                  path={path}
                  key={index}
                  exact={true}
                  element={<AgentPage />}
                />
              ))}
              {[
                "/dashboard/:type",
                "/dashboard/enterpriseSellers/:type",
                "/dashboard/enterpriseBuyers/:type",
                "/dashboard/agents/:type",
              ].map((path, index) => (
                <Route
                  path={path}
                  key={index}
                  exact={true}
                  element={<DashboardTable />}
                />
              ))}
              {[
                `/${rmDashBoardName}/:type`,
                `/${rmDashBoardName}/enterpriseSellers/:type`,
                `/${rmDashBoardName}/viewEnterpriseBuyers/:type`,
                `${rmDashBoardName}/agents/:type`,
              ].map((path, index) => (
                <Route
                  path={path}
                  key={index}
                  exact={true}
                  element={<ListDashboardTable />}
                />
              ))}
              {[`/${rmDashBoardName}/:customerType/:type`].map(
                (path, index) => (
                  <Route
                    path={path}
                    key={index}
                    element={<ListDashboardTable />}
                  />
                )
              )}

              {[
                "/company/brandCompanyCreation/brandPage/DemandDetailView/:pathDemandId",
                "/customer/brandPage/DemandDetailView/:pathDemandId",
                "/customer/agentPage/DemandDetailView/:pathDemandId",
                "/company/enterprise-agent/DemandDetailView/:pathDemandId",
                "/company/physicalTeam/DemandDetailView/:pathDemandId",
                "/demand/DemandDetailView/:pathDemandId",
                `/${rmDashBoardName}/:customerType/:type/DemandDetailView/:pathDemandId`,
                "/company/brandCompanyCreation/brandPage/DemandDetailView",
                "/customer/brandPage/DemandDetailView/",
                "/customer/agentPage/DemandDetailView/",
                "/demand/DemandDetailView",
                "/activityLogs/DemandDetailView/:pathDemandId",
                "/buyerReport/demandList/DemandDetailView/:pathDemandId",
                "/agentReport/contacts/DemandDetailView/:pathDemandId",
                "/agentReport/demandList/DemandDetailView/:pathDemandId",
                "/agentReport/Contacts/DemandDetailView/:pathDemandId",
                "sellerReport/responseList/DemandDetailView/:pathDemandId",
                "sellerReport/responseList/DemandDetailView",
                "/buyerReport/demandList/DemandDetailView",
                "/sellerReport/propertyList/relevantDemand/DemandDetailView/:pathDemandId",
                "/sellerReport/propertyList/relevantDemand/DemandDetailView",
                "/sellerReport/propertyList/DemandDetailView",
                "/sellerReport/propertyList/DemandDetailView/:pathDemandId",
                "/enterpriseAgentReport/propertyList/DemandDetailView",
                "/enterpriseAgentReport/propertyList/DemandDetailView/:pathDemandId",
                "/physicalTeamReport/propertyList/DemandDetailView",
                "/physicalTeamReport/propertyList/DemandDetailView/:pathDemandId",
                "/enterpriseAgentReport/demandList/DemandDetailView",
                "/enterpriseAgentReport/demandList/DemandDetailView/:pathDemandId",
                "/physicalTeamReport/demandList/DemandDetailView",
                "/physicalTeamReport/demandList/DemandDetailView/:pathDemandId",
                "/agentReport/propertyList/DemandDetailView",
                "/agentReport/propertyList/DemandDetailView/:pathDemandId",
                "agentReport/responseList/DemandDetailView/:pathDemandId",
                "agentReport/responseList/DemandDetailView",
                "/agentReport/propertyList/relevantDemand/DemandDetailView/:pathDemandId",
                "/agentReport/propertyList/relevantDemand/DemandDetailView",
                "/buyerReport/brandList/brandPage/DemandDetailView/:pathDemandId",
                "/buyerReport/brandList/brandPage/DemandDetailView",
                "/buyerReport/companyList/brandCompanyCreation/brandPage/DemandDetailView/",
                "/buyerReport/companyList/brandCompanyCreation/brandPage/DemandDetailView/:pathDemandId",
              ].map((path, index) => (
                <Route
                  path={path}
                  exact
                  key={index}
                  element={<DemandDetailView />}
                />
              ))}
              {[
                "/customer/developerPage/addProperty",
                "/properties/editProperty",
                "/customer/agentPage/addProperty",
              ].map((path, index) => (
                <Route
                  path={path}
                  exact
                  key={index}
                  element={<NewAddProperty />}
                />
              ))}
              <Route
                path="/property/:type"
                exact={true}
                element={<NewAddProperty />}
              />
              {[
                "/company/developerCompanyCreation/developerPage/addProperty",
                "/company/developerCompanyCreation/developerPage/propertyDetailView/:pathPropertyId",
                "/enterpriseAgentReport/propertyList/propertyDetailView/:pathPropertyId",
                "/physicalTeamReport/propertyList/propertyDetailView/:pathPropertyId",
                "/customer/developerPage/propertyDetailView/:pathPropertyId",
                "/properties/propertyDetailView/:pathPropertyId",
                `/${rmDashBoardName}/:customerType/:type/propertyDetailView/:pathPropertyId`,
                "/customer/agentPage/propertyDetailView/:pathPropertyId",
                "/company/enterprise-agent/propertyDetailView/:pathPropertyId",
                "/company/physicalTeam/propertyDetailView/:pathPropertyId",
                "/customer/developerPage/propertyDetailView",
                "/customer/agentPage/propertyDetailView",
                "/properties/propertyDetailView",
                "/sellerReport/propertyList/propertyDetailView",
                "/sellerReport/propertyList/propertyDetailView/:pathPropertyId",
                "/buyerReport/demandList/propertyDetailView",
                "/sellerReport/propertyList/propertyDetailView/",
                "/sellerReport/propertyList/propertyDetailView/:pathPropertyId",
                "/agentReport/propertyList/propertyDetailView/",
                "/agentReport/propertyList/propertyDetailView/:pathPropertyId",
                "/agentReport/Contacts/propertyDetailView/:pathPropertyId",
                "/buyerReport/demandList/propertyDetailView/:pathPropertyId",
                "/activityLogs/propertyDetailView/:pathPropertyId",
                "/agentReport/propertyList/propertyDetailView",
                "/agentReport/propertyList/propertyDetailView/:pathPropertyId",
                "/agentReport/Contacts/propertyDetailView/:pathPropertyId",
                "/sellerReport/developerList/developerPage/propertyDetailView/:pathPropertyId",
                "/sellerReport/developerList/developerPage/propertyDetailView",
                "/agentReport/agentList/agentPage/propertyDetailView/:pathPropertyId",
                "/agentReport/agentList/agentPage/propertyDetailView",
              ].map((path, index) => (
                <Route
                  path={path}
                  exact
                  key={index}
                  element={<PropertyView />}
                />
              ))}
              {[
                "/customer/developerPage/propertyDetailView/propertyResponse",
                "/properties/propertyView",
                "/customer/agentPage/propertyDetailView/propertyResponse",
              ].map((path, index) => (
                <Route
                  path={path}
                  exact
                  key={index}
                  element={<PropertyDetailView />}
                />
              ))}
              {[
                "/company/brandCompanyCreation/brandPage/PostDemand",
                "/company/brandCompanyCreation/agentPage/PostDemand",
                "/customer/brandPage/PostDemand",
                "/customer/agentPage/PostDemand",
                "/company/enterpriseAgent/PostDemand",
                "/company/physicalTeam/PostDemand",
                "/demand/PostDemand",
              ].map((path, index) => (
                <Route path={path} exact key={index} element={<PostDemand />} />
              ))}

              <Route path="*" exact element={<NotfoundPage />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </div>
    </AppContext.Provider>
  );
}

export default App;
