import React, { useEffect } from "react";
import { useSortable } from "@dnd-kit/sortable";
import "./style.scss";
import { DragHandle } from "./DragHandle";

export function SortableItem({ id, setActiveRow, children }) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });
  useEffect(() => {
    if (isDragging) {
      setActiveRow(id);
    } else {
      setActiveRow(null);
    }
  }, [isDragging]);

  return (
    <div ref={setNodeRef} className="flex justify-between">
      <DragHandle {...attributes} {...listeners} />
      {children}
    </div>
  );
}
