
export const buyerUrls = {
  companyList: () => "/admin/getAllCompanyListNew",
  companyPocList: () => "/admin/getAllCompanyPocListNew",
  regionPocList: () => "/admin/getAllRegionPocListNew",
  brandList: () => "/admin/getAllBrandListNew",
  demandPostedList: () => "/admin/demandPosted",
  brandPocList: () => "/admin/getAllBrandPocListNew",
  inActiveDemandList: () => "/admin/demandPosted",
  activeDemandList: () => "/admin/demandPosted",
  demandResponseList: () => "/admin/demandPosted",
  propertyContactedList: () => "/admin/getAllPropertyContacted",
  needMoreInfoList: () => "/admin/getAllResponseAccept",
  propertyRejectedList: () => "/admin/getAllResponseAccept",
  propertyAcceptedList: () => "/admin/getAllResponseAccept",
  acitivityLogs: () => "/eventLog/getAllEventLog",
  mailingLogs: () => "/eventLog/getMailingLogForAdmin",
};
export const buyerFilterUrls = {
  companyList: () => "/admin/companyFilterNew",
  companyPocList: () => "/admin/pocFilterNew",
  brandList: () => "/admin/brandFilterNew",
  brandPocList: () => "/admin/pocFilterNew",
  regionPocList: () => "/admin/pocFilterNew",
  needMoreInfoList: () => "/admin/propertyReportFilter",
  propertyContactedList: () => "/admin/propertyReportFilter",
  propertyAcceptedList: () => "/admin/propertyReportFilter",
  propertyRejectedList: () => "/admin/propertyReportFilter",
  acitivityLogs: () => "/eventLog/getAllEventName",
  mailingLogs: () => "/eventLog/getAllEventName",
  demandPostedList: () => "/admin/demandPostedFilter",
  inActiveDemandList: () => "/admin/demandPostedFilter",
  activeDemandList: () => "/admin/demandPostedFilter",
  demandResponseList: () => "/admin/demandPostedFilter",
};
