 export const TableRows = [
    10, 15, 20, 50 
 ]
 export const ENTERPRISEAGENT = "enterpriseAgent"
 export const EAGENT = "EAgent"
 export const PHYSICALTEAM = "physicalTeam"
 export const PHYSICAL_TEAM = "Physical Team"
 export const PROPVANTAGE = "Propvantage"
 export const MULTIPLE_SWITCH = false;
 export const SWITCH_LABEL = {
      'SWITCH1' : 'OLD',
      'SWITCH2' : 'NEW'
 };
