export const fitOutData = [
  {
    name: "Yes",
    value: "yes",
  },
  {
    name: "No",
    value: "no",
  },
  // {
  //   value: "Any",
  //   name: "Any",
  // }
];
export const buisnessHoursData = [
  {
    value: "1",
    name: "1",
  },
  {
    value: "2",
    name: "2",
  },
  {
    value: "3",
    name: "3",
  },
  {
    value: "4",
    name: "4",
  },
  {
    value: "5",
    name: "5",
  },
  {
    value: "6",
    name: "6",
  },
  {
    value: "7",
    name: "7",
  },
  {
    value: "8",
    name: "8",
  },
  {
    value: "9",
    name: "9",
  },
  {
    value: "10",
    name: "10",
  },
  {
    value: "11",
    name: "11",
  },
  {
    value: "12",
    name: "12",
  },
  {
    value: "13",
    name: "13",
  },
  {
    value: "14",
    name: "14",
  },
  {
    value: "15",
    name: "15",
  },
  {
    value: "16",
    name: "16",
  },
  {
    value: "17",
    name: "17",
  },
  {
    value: "18",
    name: "18",
  },
  {
    value: "19",
    name: "19",
  },
  {
    value: "20",
    name: "20",
  },
  {
    value: "21",
    name: "21",
  },
  {
    value: "22",
    name: "22",
  },
  {
    value: "23",
    name: "23",
  },
  {
    value: "24",
    name: "24",
  },
];
export const nearestConnectivityList = [
  {
    _id: "METRO",
    name: "Metro Station",
    value: "METRO",
    label: "Metro Station",
  },
  {
    _id: "RAILWAY",
    name: "Railway Station",
    value: "RAILWAY",
    label: "Railway Station",
  },
  {
    _id: "BUS",
    name: "Bus Station",
    value: "BUS",
    label: "Bus Station",
  },
];
export const floorOption = [
  {
    _id: "0",
    name: "Basement",
  },
  {
    _id: "1",
    name: "Lower Ground",
  },
  {
    _id: "2",
    name: "Upper Ground",
  },
  {
    _id: "9",
    name: "Ground",
  },
  {
    _id: "3",
    name: "1st Floor",
  },
  {
    _id: "4",
    name: "2nd Floor",
  },
  {
    _id: "5",
    name: "3rd Floor",
  },
  {
    _id: "6",
    name: "4th Floor",
  },
  {
    _id: "7",
    name: "5th Floor",
  },
  {
    _id: "8",
    name: "Above 5",
  },
  // {
  //   _id: "9",
  //   name: "Ground",
  // },
  // {
  //   _id: "0",
  //   name: "Ground",
  // },
  // {
  //   _id: "1",
  //   name: "1",
  // },
  // {
  //   _id: "2",
  //   name: "2",
  // },
  // {
  //   _id: "3",
  //   name: "3",
  // },
  // {
  //   _id: "4",
  //   name: "4",
  // },
  // {
  //   _id: "5",
  //   name: "5",
  // },
];
export const amenetiesAvailable = [
  {
    name: "Fire Fighting System",
    value: "Fire Fighting System",
  },
  {
    name: "Fire Exit",
    value: "Fire Exit",
  },
  {
    name: "AC",
    value: "AC",
  },
  {
    name: "Reserved Parking",
    value: "Reserved Parking",
  },
  {
    name: "Lift",
    value: "Lift",
  },
  {
    name: "Security",
    value: "Security",
  },
  {
    name: "CCTV Survillance",
    value: "CCTV Survillance",
  },
  {
    name: "Vastu Compilance",
    value: "Vastu Compilance",
  },
  {
    name: "Central Air Conditioning",
    value: "Central Air Conditioning",
  },
  {
    name: "Service Air Lift",
    value: "Service Air Lift",
  },
  {
    name: "Facilities For Disabled",
    value: "Facilities For Disabled",
  },
  {
    name: "Cafetaira / Food Court",
    value: "Cafetaira / Food Court",
  },
  {
    name: "Fire Sensor",
    value: "Fire Sensor",
  },
  {
    name: "Pantry",
    value: "Pantry",
  },
  {
    name: "Recieption Area",
    value: "Recieption Area",
  },
  {
    name: "Security Personal",
    value: "Security Personal",
  },
];
export const buildingStructureData = [
  {
    value: "Independent building",
    name: "Independent building",
  },
  {
    value: "Mall",
    name: "Mall",
  },
  {
    value: "Highstreet",
    name: "Highstreet",
  },
  {
    value: "Mixed use",
    name: "Mixed use",
  },
  {
    value: "Kiosks",
    name: "Kiosks",
  },
  {
    value: "Store-in-store",
    name: "Store-in-store",
  },
];

export const idealFor = [
  {
    label: "Retail",
    value: "Retail",
  },
  {
    label: "Café",
    value: "Café",
  },
  {
    label: "Restaurant",
    value: "Restaurant",
  },
  {
    label: "Cloud kitchen",
    value: "Cloud kitchen",
  },
  {
    label: "Clinic/Diagnostic center",
    value: "Clinic/Diagnostic center",
  },
  {
    label: "Gym",
    value: "Gym",
  },
  {
    label: "Electronic store",
    value: "Electronic store",
  },
  {
    label: "Jewellery store",
    value: "Jewellery store",
  },
  {
    label: "Clothing store",
    value: "Clothing store",
  },
  {
    label: "Footwear store",
    value: "Footwear store",
  },
  {
    label: "Automative showroom",
    value: "Automative showroom",
  },
  {
    label: "Cosmetics store",
    value: "Cosmetics store",
  },
  {
    label: "Others",
    value: "Others",
  },
];

export const fitouts = [
  {
    value: 2,
    name: "1-2 months",
  },
  {
    value: 4,
    name: "2-4 months",
  },
  {
    value: 6,
    name: "4-6 months",
  },
  {
    value: 8,
    name: "Over 6 months",
  },
];

export const ownershipData = [
  {
    value: "Individual or Investor",
    name: "Individual or Investor",
  },
  {
    value: "Developer",
    name: "Developer",
  },
];

export const possessionDataCowork = [
  {
    value: 1,
    name: "Immediate",
  },
  {
    value: 3,
    name: "Within 3 months",
  },
];
export const possessionData = [
  {
    value: 1,
    name: "Immediate",
  },
  {
    value: 3,
    name: "Within 3 months",
  },
  {
    value: 6,
    name: "Within 6 months",
  },
  {
    value: 9,
    name: "Within 9 months",
  },
  {
    value: 12,
    name: "After 9 months",
  },
];
export const propertyAgeData = [
  {
    value: 5,
    name: "Less than 5 years",
  },
  {
    value: 10,
    name: "5-10 years",
  },
  {
    value: 20,
    name: "10-20 years",
  },
  {
    value: 30,
    name: "Above 20 years",
  },
  //    {
  //     value:0,
  //     name:"Any"
  // },
];

export const brokerageeData = [
  {
    value: 7,
    name: "7 days",
  },
  {
    value: 14,
    name: "14 days",
  },
  {
    value: 21,
    name: "21 days",
  },
  {
    value: 30,
    name: "30 days",
  },
  {
    value: 35,
    name: "Negotiable",
  },
];

export const depositMonthData = [
  {
    value: 1,
    name: "1 month",
  },
  {
    value: 2,
    name: "2 months",
  },
  {
    value: 3,
    name: "3 months",
  },
  {
    value: 4,
    name: "4 months",
  },
  {
    value: 5,
    name: "5 months",
  },
  {
    value: 6,
    name: "6 months",
  },
  {
    value: 7,
    name: "7 months",
  },
  {
    value: 8,
    name: "8 months",
  },
  {
    value: 9,
    name: "9 months",
  },
  {
    value: 10,
    name: "10 months",
  },
  {
    value: 11,
    name: "11 months",
  },
  {
    value: 12,
    name: "12 months",
  },
];
export const floorVal = [
  {
    value: "Ground",
    name: "Ground",
  },
  {
    value: "1",
    name: "1",
  },
  {
    value: "2",
    name: "2",
  },
  {
    value: "3",
    name: "3",
  },
  {
    value: "4",
    name: "4",
  },
  {
    value: "5",
    name: "5",
  },
];
export const propTypeData = [
  {
    value: "Lease",
    name: "Lease",
  },
  { value: "Revenue Share", name: "Revenue Share" },
  { value: "MG Revenue Share", name: "MG Revenue Share" },
  { value: "Sales", name: "Sales" },
];
export const propTypeDataOffice = [
  {
    value: "Lease",
    name: "Lease",
  },
  { value: "Sales", name: "Sales" },
];
export const leaseData = [
  {
    value: 1,
    name: "Every year",
  },
  { value: 3, name: "Every 3 years" },
  { value: 5, name: "Every 5 years" },
];
export const washroomData = [
  {
    value: "100",
    name: "0",
  },
  {
    value: "1",
    name: "1",
  },
  {
    value: "2",
    name: "2",
  },
  {
    value: "3",
    name: "3",
  },
  {
    value: "4",
    name: "4",
  },
  {
    value: "5",
    name: "5",
  },
  {
    value: "6",
    name: "6",
  },
  {
    value: "7",
    name: "7",
  },
  {
    value: "8",
    name: "8",
  },
  {
    value: "9",
    name: "9",
  },
  {
    value: "10",
    name: "10",
  },
  {
    value: "11",
    name: "11",
  },
  {
    value: "12",
    name: "12",
  },
  {
    value: "13",
    name: "13",
  },
  {
    value: "14",
    name: "14",
  },
  {
    value: "15",
    name: "15",
  },
];
export const parkingData = [
  {
    value: "No information available",
    name: "No information available",
  },
  {
    value: "100",
    name: "0",
  },
  {
    value: "1",
    name: "1",
  },
  {
    value: "2",
    name: "2",
  },
  {
    value: "3",
    name: "3",
  },
  {
    value: "4",
    name: "4",
  },
  {
    value: "5",
    name: "5",
  },
  {
    value: "6",
    name: "6",
  },
  {
    value: "7",
    name: "7",
  },
  {
    value: "8",
    name: "8",
  },
  {
    value: "9",
    name: "9",
  },
  {
    value: "10",
    name: "10",
  },
  {
    value: "11",
    name: "11",
  },
  {
    value: "12",
    name: "12",
  },
  {
    value: "13",
    name: "13",
  },
  {
    value: "14",
    name: "14",
  },
  {
    value: "15",
    name: "15",
  },
  {
    value: "16",
    name: "16",
  },
  {
    value: "17",
    name: "17",
  },
  {
    value: "18",
    name: "18",
  },
  {
    value: "19",
    name: "19",
  },
  {
    value: "20",
    name: "20",
  },
  {
    value: "21",
    name: "21",
  },
  {
    value: "22",
    name: "22",
  },
  {
    value: "23",
    name: "23",
  },
  {
    value: "24",
    name: "24",
  },
  {
    value: "25",
    name: "25",
  },
];
export const cabinData = [
  {
    value: "100",
    name: "0",
  },
  {
    value: "1",
    name: "1",
  },
  {
    value: "2",
    name: "2",
  },
  {
    value: "3",
    name: "3",
  },
  {
    value: "4",
    name: "4",
  },
  {
    value: "5",
    name: "5",
  },
  {
    value: "6",
    name: "6",
  },
  {
    value: "7",
    name: "7",
  },
  {
    value: "8",
    name: "8",
  },
  {
    value: "9",
    name: "9",
  },
  {
    value: "10",
    name: "10",
  },
  {
    value: "11",
    name: "11",
  },
  {
    value: "12",
    name: "12",
  },
  {
    value: "13",
    name: "13",
  },
  {
    value: "14",
    name: "14",
  },
  {
    value: "15",
    name: "15",
  },
  {
    value: "16",
    name: "16",
  },
  {
    value: "17",
    name: "17",
  },
  {
    value: "18",
    name: "18",
  },
  {
    value: "19",
    name: "19",
  },
  {
    value: "20",
    name: "20",
  },
  {
    value: "21",
    name: "21",
  },
  {
    value: "22",
    name: "22",
  },
  {
    value: "23",
    name: "23",
  },
  {
    value: "24",
    name: "24",
  },
  {
    value: "25",
    name: "25",
  },
];
export const totalFloors = [
  {
    value: "5",
    name: "Up to 5",
  },
  {
    value: "10",
    name: "Between 6 - 10",
  },
  {
    value: "15",
    name: "Between 11 - 15",
  },
  {
    value: "20",
    name: "Between 16 - 20",
  },
  {
    value: "25",
    name: "Between 21 - 25",
  },
  {
    value: "30",
    name: "Between 26 - 30",
  },
  {
    value: "31",
    name: "Beyond 31",
  },
];
export const floorNumber = [
  {
    value: "5",
    name: "Between ground - 5",
  },
  {
    value: "10",
    name: "Between 6 - 10",
  },
  {
    value: "15",
    name: "Between 11 - 15",
  },
  {
    value: "20",
    name: "Between 16 - 20",
  },
  {
    value: "25",
    name: "Between 21 - 25",
  },
  {
    value: "30",
    name: "Between 26 - 30",
  },
  {
    value: "31",
    name: "Beyond 31",
  },
];
export const meetingRoomData = [
  {
    value: "110",
    name: "0",
  },
  {
    value: "1",
    name: "1",
  },
  {
    value: "2",
    name: "2",
  },
  {
    value: "3",
    name: "3",
  },
  {
    value: "4",
    name: "4",
  },
  {
    value: "5",
    name: "5",
  },
  {
    value: "6",
    name: "6",
  },
  {
    value: "7",
    name: "7",
  },
  {
    value: "8",
    name: "8",
  },
  {
    value: "9",
    name: "9",
  },
  {
    value: "10",
    name: "10",
  },
  {
    value: "11",
    name: "11",
  },
  {
    value: "12",
    name: "12",
  },
  {
    value: "13",
    name: "13",
  },
  {
    value: "14",
    name: "14",
  },
  {
    value: "15",
    name: "15",
  },
];

export const fitOutDataOffice = [
  {
    name: "Bare shell",
    value: "Bare shell",
  },
  {
    name: "Warm shell",
    value: "Warm shell",
  },
  {
    name: "Fully furnished",
    value: "Fully furnished",
  },
  {
    name: "Semi furnished",
    value: "Semi furnished",
  },
  {
    name: "Owner to do fit out",
    value: "Owner to do fit out",
  },
];

export const userBaseOpions = [
  { value: "Enterprise Buyer", label: "Enterprise Buyer" },
  { value: "Enterprise Seller", label: "Enterprise Seller" },
  { value: "Enterprise Agent", label: "Enterprise Agent" },
  { value: "Physical Team", label: "Physical Team" },
  { value: "agent", label: "agent" },
];


export const UserBaseOpionsDemand = [
  { value: "Enterprise Seller", label: "Enterprise Seller" },
  // { value: "Enterprise Agent", label: "Enterprise Agent" },
  { value: "Physical Team", label: "Physical Team" },
  { value: "agent", label: "agent" },
];

export const UserBaseOpionsProperty = [
  { value: "Enterprise Buyer", label: "Enterprise Buyer" },
  // { value: "Enterprise Agent", label: "Enterprise Agent" },
  { value: "Physical Team", label: "Physical Team" },
  { value: "agent", label: "agent" },
];
