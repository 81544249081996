import { useEffect, useState } from "react";
// import ReportCards from "../../../components/Cards/report";
// import { cardArray, cardDemandArray } from "./reportsArray";
// import Loader from "../../../components/Loader";
import { useSelector, useDispatch } from "react-redux";
// import {
//   updateParentPageData,
//   updateSelectedAgentReportTab,
// } from "../../../pages/Login/reducer";
import { useLocation } from "react-router-dom";
// import { getData } from "../../../api";
// import CTab from "../../../components/Tab";
import { cardArray } from "../../reportsArray";
import { getData } from "../../../../../api";
import CTab from "../../../../../components/Tab";
import { updateParentPageData, updateSelectedAgentReportTab } from "../../../../Login/reducer";
import Loader from "../../../../../components/Loader";
import ReportCards from "../../../../../components/Cards/report";

export default function AgentReportData() {
  const [filterArray, setFilterArray] = useState([]);
  const [demandsFilterArray, setDemmandFilterArray] = useState([]);
  const location = useLocation();
  const [isloding, setIsLoading] = useState(false);
  const [reportValue, setReportValue] = useState({});
  const [key, setKey] = useState("property");
  const [agentTabKey, setAgentTabKey] = useState("enterpriseAgent");
  const user = useSelector((state) => state.login.parentPage);
  const selectedAgentReportTab = useSelector(
    (state) => state.login.selectedAgentReportTab
  );
    // const storeuser = location?.state?.user ? location?.state?.user : user;
    const storeuser = "agent";
  const getReportData = async () => {
    setIsLoading(true);
    let url = `/admin/reportNew?type=${storeuser}&sectionType=${selectedAgentReportTab}`;
    const res = await getData(url, {}, {});
    const { statusCode, data } = res;
    if (statusCode === 200) {
      setIsLoading(false);
      setReportValue(data);
    } else {
      setIsLoading(false);
    }
  };

  const setTabKey = (k) => {
    // setOffset(1);
    setKey(k);
    // setContextValue(k);
    // localStorage.setItem("companyTabKey", k);
  };

  const dispath = useDispatch();
  useEffect(() => {
    getReportData();
    // if (storeuser) {
      dispath(updateParentPageData(storeuser));
      let filterValue = cardArray.filter((item) => item.name === "agent");
      // if (storeuser === "agent") {
      //   if (selectedAgentReportTab === "demands") {
      //     setFilterArray(filterValue[0]?.value2);
      //   } else if (selectedAgentReportTab === "properties") {
      //     setFilterArray(filterValue[0]?.value1);
      //   } else if (selectedAgentReportTab === "contacts") {
      //     setFilterArray(filterValue[0]?.value3);
      //   }
      // } else {
      //   setFilterArray(filterValue[0]?.value);
      // }
      if (selectedAgentReportTab === "demand") {
        setFilterArray(filterValue[0]?.value2);
      } else if (selectedAgentReportTab === "property") {
        setFilterArray(filterValue[0]?.value1);
      } else if (selectedAgentReportTab === "contact") {
        setFilterArray(filterValue[0]?.value3);
      }

      return () => {
        setFilterArray([]);
        setReportValue({});
      };
    // }
  }, [storeuser, selectedAgentReportTab]);
  
  const tabDetails = [
    {
      title: "Properties",
      content: (
        <>
          {key === "property" ? (
            <ReportCards keyData={filterArray} value={reportValue} />
          ) : null}
        </>
      ),
      eventKey: "property",
    },
    {
      title: "Demands",
      content: (
        <>
          <ReportCards keyData={filterArray} value={reportValue} />
        </>
      ),
      eventKey: "demand",
    },

    {
      title: "Contacts",
      content: <ReportCards keyData={filterArray} value={reportValue} />,
      eventKey: "contact",
    },
    // {
    //   title: "Responses",
    //   content: <>{key === "responses" ? <div>Responses Report</div> : null}</>,
    //   eventKey: "responses",
    // },
  ];
  return (
    <>

      {isloding ? (
        <Loader />
      ) : storeuser === "agent" ? (
        <div className="w-100">
          <CTab
            tabDetails={tabDetails}
            activeKey={selectedAgentReportTab}
            onSelect={(k) => {
              dispath(updateSelectedAgentReportTab(k));
            }}
          />
        </div>
      ) : (
        <ReportCards keyData={filterArray} value={reportValue} />
      )}
    </>
  );
}
