export const buyerConstantHeaderValue = {
  companyList: "Company list",
  companyPocList: "Company Poc list",
  regionPocList: "Region Poc list",
  brandList: "Brand list",
  brandPocList: "Brand Poc list",
  demandPostedList: "Demands posted list",
  inActiveDemandList: "In-Active demand list",
  activeDemandList: "Active demand list",
  demandResponseList: "Demand response list",
  propertyAcceptedList: "Property accepted list",
  propertyContactedList: "Property contacted list",
  propertyRejectedList: "Property rejected list",
  needMoreInfoList: "Need further information list",
  mailingLogs:"Mailing Logs list",
};
export const sellerConstantHeaderValue = {
  propertiesPostedList: "Property posted list",
  developerList: "Developer list",
  developerPocList: "Developer Poc list",
  cityPocList: "City Poc list",
  propertyAcceptedList: "Property accepted list",
  propertyContactedList: "Property contacted list",
  propertyRejectedList: "Property rejected list",
  propertyPostedList: "Properties posted list",
  propertyPostedAgainstList: "Properties posted against list",
  propertyResponseList: "Properties response list",
  propertyInActiveList: "In-Active properties list",
  propertyActiveList: "Active properties list",
  needMoreInfoList: "Need further information list",
  mailingLogs:"Mailing Logs list",
};
export const agentConstantHeaderValue = {
  propertiesPostedList: "Property posted list",
  agentList: "Agent list",
  agentPocList: "Agent Poc list",
  propertyAcceptedList: "Property accepted list",
  propertyContactedList: "Property contacted list",
  propertyRejectedList: "Property rejected list",
  propertyPostedList: "Properties posted list",
  propertyPostedAgainstList: "Properties posted against list",
  propertyResponseList: "Properties response list",
  propertyInActiveList: "In-Active properties list",
  propertyActiveList: "Active properties list",
  needMoreInfoList: "Need further information list",
  demandPostedList: "Demand Posted List",
  inActiveDemandList: "Inactive Demands List",
  activeDemandList: "Active Demands List",
  demandResponseList: "Demand Response List",
  propertyContactedDemandList: "Property contacted list",
  demandContactVisibility: "Total Contact Seen",
  demandViewedContact: "Viewed Conatact  List",
  mailingLogs:"Mailing Logs list",
};
