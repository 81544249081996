import React, { useState, useEffect, useRef } from "react";
import Progress from "../../components/progress/addProperty";
import OfficeProgress from "../../components/progress/officeProgress";
import { getData, postGetData, getCurrentUserData } from "../../api";
import PropertyDetail from "./ProperyDetail";
import UploadProperty from "./UploadProperty";
import Loader from "../../components/Loader";
import UploadPropertyDocuments from "./UploadPropertyDocument";
import AdditionalAndTransaction from "./AdditionalDetailsAndTransaction";
import OfficeSpaceAdditionalDetails from "./OfficeSpaceAdditionalDetails";
import Button from "../../components/Form/Button";
import { Row, Col } from "react-bootstrap";
import FormModal from "../../components/Modal";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./style.scss";
import {
  convertedArrayIntoObjects,
  isFalsy,
  nameArrayOfObjects,
  removeEmptyStringValues,
  stringTolabelValue,
} from "../../utilities/commonFunction";
import { AdminWarningPopup } from "../Admin/adminPopup";
import { useSelector } from "react-redux";
import CoworkingPropertyDetail from "./CoworkingPropertyDetail";
import CoworkingSpaceAdditionalDetails from "./CoworkingSpaceAdditionalDetails";
import CoworkingFormatData from "./CoworkingFormatData";
import CoworkUploadProperty from "./CoworkingUploadProperty";
import UploadVideoUrl from "./VideoUrl";
import CoworkUploadDocuments from "./CoworkUploadDocuments";
import { ENTERPRISEAGENT, PHYSICALTEAM } from "../../custom/constants";
import {
  userBaseOpions,
  UserBaseOpionsProperty,
} from "../../components/DropdownOptions";
const NewAddProperty = () => {
  const [step, setStep] = useState(5);
  const [isExitData, setIsExitData] = useState("no");
  const [propertyDetail, setPropertyDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [propertyList, setPropertyList] = useState([]);
  const [cityList, setCityList] = useState([]);
  console.log({ cityList });
  const [propertyType, setPropertyType] = useState(null);
  const [propertyTypeId, setPropertyTypeId] = useState(null);
  const [pocUserId, setPocUserId] = useState(null);
  const [cityLocation, setCityLocation] = useState("");
  const [initData, setInitData] = useState({});
  const [properytyObject, setProperyObject] = useState({});
  const [coworkProperytyObject, setCoworkProperytyObject] = useState({});
  const [amenietiesError, setAmenietiesError] = useState(false);
  const [formatError, setFormatError] = useState(false);
  const [coworkAdditionalProperytyObject, setCoworkAdditionalProperytyObject] =
    useState({});
  const [coworkFormatDataObject, setCoworkFormatDataObject] = useState([]);
  const [coworkUploadPropertyObject, setCoworkUploadPropertyObject] = useState(
    {}
  );
  const [documentCoworkInput, setDocumentCoworkInput] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [formatAmountError, setFormatAmountError] = useState(false);
  const [googleMapError, setGoogleMapError] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [videoUrlError, setVideoUrlError] = useState("");
  const [nearestLocationError, setNearestLocationError] = useState({});
  const [usersTransactionType, setUsersTransactionType] = useState([]);

  const [uploadPropertyObject, setUploadPropertyObject] = useState({
    frontSide: [],
    interior: [],
    exterior: [],
  });
  const [rateInfo, setRateInfo] = useState(false);
  const [officeproperytyObject, setOfficeProperyObject] = useState({});
  const [retailproperytyObject, setRetailProperyObject] = useState({});
  const [documentproperytyInput, setDocumentPropertyInput] = useState([]);
  const [openWarning, setWarning] = useState(false);
  const [changed, setChanged] = useState(false);
  const inputRef = useRef(null);
  const propertyStepButtonRef = useRef(null);
  const propertyStepFormRef = useRef(null);
  const officeSpaceAdditionalStepButtonRef = useRef(null);
  const officeSpaceAdditionalStepFormRef = useRef(null);
  const retailAdditionalStepButtonRef = useRef(null);
  const retailAdditionalStepFormRef = useRef(null);
  const uploadPropertyStepButtonRef = useRef(null);
  const uploadDocumentStepButtonRef = useRef(null);
  const coworkUploadPropertyStepButtonRef = useRef(null);
  const coworkPropertyStepFormRef = useRef(null);
  const coworkpropertyStepButtonRef = useRef(null);
  const coworkingSpaceAdditionalStepButtonRef = useRef(null);
  const coworkingFormatDataStepButtonRef = useRef(null);
  const coworkingSpaceAdditionalStepFormRef = useRef(null);
  const coworkingFormatDataStepFormRef = useRef(null);
  const coworkPropertyDetailDivRef = useRef(null);
  const coworkGoogleMapDivRef = useRef(null);
  const coworkFormatDivRef = useRef(null);
  const coworkImagesDivRef = useRef(null);
  const coworkAdditionalDetailDivRef = useRef(null);
  const PropertyDetailDivRef = useRef(null);
  const AdditionalDetailDivRef = useRef(null);
  const ImagesDivRef = useRef(null);
  const propertyVideoURLDivRef = useRef(null);
  const retailAdditionalDetailDivRef = useRef(null);
  const { activeSlug } = useSelector((state) => state.login);
  let navigate = useNavigate();
  const location = useLocation();
  const { type } = useParams();
  const searchParams = new URLSearchParams(location.search);
  const checkImage = () => {
    let errorsList = {};
    Object.entries(uploadPropertyObject).forEach((item) => {
      if (item[1].length === 0) errorsList[item[0]] = "This Field is required";
    });
    return errorsList;
  };

  const checkAgent = () => {
    return initData?.developerType === "Agent" ? true : false;
  };

  const checkCoworkImage = () => {
    let errorsList = {};
    Object.entries(coworkUploadPropertyObject).forEach((item) => {
      if (item[1].length === 0) errorsList[item[0]] = "This Field is required";
    });
    return errorsList;
  };
  const getProperyDetail = async (status, developerId) => {
    setLoading(true);
    const propertyId = searchParams.get("propertyId");
    if (propertyId !== "0") {
      let url = `/property/${propertyId}`;
      const res = await getData(url, {}, {});
      const { statusCode, data } = res;
      if (statusCode && statusCode === 200) {
        setLoading(false);
        setIsExitData(true);
        let modified = { ...data };
        if (searchParams.get("developerType") === PHYSICALTEAM) {
          const userAccessibilityArr = stringTolabelValue(
            data?.userAccessibility
          );
          modified = { ...modified, userAccessibility: userAccessibilityArr };
        }
        setPropertyDetail(modified);
        setInitData((prev) => ({
          ...prev,
          propertyId: searchParams.get("propertyId"),
        }));
        setPropertyTypeId(data.propertyTypeId?._id);
        setPocUserId(data.userId?._id);
        setCityLocation(data?.location);
        setPropertyType(data.propertyTypeId?.name);
        if (
          searchParams.get("developerType") === "Developer" ||
          searchParams.get("developerType") === ENTERPRISEAGENT ||
          searchParams.get("developerType") === PHYSICALTEAM
        ) {
          let devId = searchParams.get("developerId");
          let urlCity = `/brand/poc/getcities/${devId}/${res.data?.userId?._id}`;
          const response = await getData(urlCity, {});
          if (response.statusCode === 200) {
            setCityList(response?.data.city);
          }
        }
        if (status) setStep(5);
      } else {
        setLoading(false);
        setIsExitData(false);
      }
    } else {
      // console.log(initData)
      const { data, statusCode } = await postGetData(
        "/property",
        {},
        { developerId: developerId, step: 1 }
      );
      if (statusCode == 200) {
        localStorage.setItem("addPropertyId", data?.propertyId);
      } else {
        navigate("/");
      }
      setIsExitData(false);
      setInitData((prev) => ({
        ...prev,
        propertyId: localStorage.getItem("addPropertyId"),
      }));
      setLoading(false);
      // setStep(1);
    }
  };

  const getcityZone = async (developerId) => {
    if (developerId) {
      setLoading(true);
      let url = `/cityZones/checkZoneAvailability/${developerId}`;
      const res = await getData(url, {});
      const { status, data } = res;
      if (status) {
        console.log({ data });
        if (searchParams.get("developerType") === "Agent") {
          setCityList(data.city);
        }
        setPropertyList(data.propertyType);
        setUsersTransactionType(
          convertedArrayIntoObjects(data?.transactionType) || []
        );
        setPropertyType(data.propertyType[0].name);
        setPropertyTypeId(data.propertyType[0]._id);
        setLoading(false);
        getProperyDetail(true, developerId);
      } else {
        setLoading(false);
        getProperyDetail(true, developerId);
      }
    }
  };
  useEffect(() => {}, [cityList]);

  const getInitData = () => {
    if (searchParams) {
      setInitData((prev) => ({
        ...prev,
        propertyId: searchParams.get("propertyId"),
        customerId: searchParams.get("customerId"),
        developerId: searchParams.get("developerId"),
        developerType: searchParams.get("developerType"),
        developerName: searchParams.get("developerName"),
        type: type,
        offset: searchParams.get("offset"),
        fromPage: searchParams.get("fromPage"),
      }));
      getcityZone(searchParams.get("developerId"));
    }
  };

  useEffect(() => {
    getInitData();
  }, []);
  useEffect(() => {
    return () => {
      sessionStorage.removeItem("fetchPropertyId");
    };
  });
  const gotoStep = (Nextstep, status) => {
    if (status) {
      getProperyDetail(!status);
    }
    setStep(Nextstep);
  };

  const handleCoworkDataUpdate = () => {
    const coworkFormatData = isExitData
      ? [...coworkFormatDataObject.format]
      : [...coworkFormatDataObject];
    const filtredFormatData = coworkFormatData.filter(
      (data) => !["", null].includes(data?.amount)
    );
    const finalCoworkFormatData = filtredFormatData.map((data) => {
      return { _id: data?._id, amount: Number(data?.amount) };
    });

    var imagesAndVideos = {};

    if (videoUrl) {
      const youtubeUrl =
        videoUrl.includes("youtube.com") &&
        (videoUrl.includes("watch?v=") || videoUrl.includes("embed/"));
      imagesAndVideos = {
        ...coworkUploadPropertyObject,
        videoUrl: [{ url: videoUrl, type: youtubeUrl ? "youtube" : "vimeo" }],
      };
    } else {
      imagesAndVideos = {
        ...coworkUploadPropertyObject,
      };
    }

    let documentDetails = {};
    documentCoworkInput.forEach((element) => {
      documentDetails[element.name] = element.uploadFiles;
    });

    const finalobj = {
      ...coworkProperytyObject,
      developerId: initData.developerId,
      format: [...finalCoworkFormatData],
      coworkingImages: {
        ...imagesAndVideos,
      },
      otherAmenities: coworkAdditionalProperytyObject.otherAmenities || [],
      otherDocuments: documentDetails,
    };

    if (checkAgent() && !isExitData) {
      delete finalobj["userId"];
    }

    const coworkAdditionalDetailCopy = { ...coworkAdditionalProperytyObject };
    if (!coworkAdditionalDetailCopy?.availableFloor) {
      delete coworkAdditionalDetailCopy["availableFloor"];
    }

    if (!coworkAdditionalDetailCopy?.bikeParking) {
      delete coworkAdditionalDetailCopy["bikeParking"];
    }
    if (!coworkAdditionalDetailCopy?.businessHours) {
      delete coworkAdditionalDetailCopy["businessHours"];
    }
    if (!coworkAdditionalDetailCopy?.carParking) {
      delete coworkAdditionalDetailCopy["carParking"];
    }
    if (!coworkAdditionalDetailCopy?.propertyAge) {
      delete coworkAdditionalDetailCopy["propertyAge"];
    }

    if (!finalobj?.propertyName) {
      delete finalobj["propertyName"];
    }

    delete coworkAdditionalDetailCopy["otherAmenities"];

    finalobj.additionalDetails = { ...coworkAdditionalDetailCopy };
    delete finalobj["projectDeveloper"];
    delete finalobj.locationmap;
    delete finalobj.mapLoacation;
    delete finalobj.propertyUnit;
    return finalobj;
  };

  const properyDetailUpdate = () => {
    let body = {};
    const copyData = {};
    Object.entries(properytyObject).forEach((item) => {
      if (item[1]) copyData[item[0]] = item[1];
      if (item[1] == "yes") copyData[item[0]] = true;
      if (item[1] == "no") copyData[item[0]] = false;
      if (item[0] === "fitOuts") {
        copyData[item[0]] = item[1];
      }
      if (
        item[0] === "lockInPeriod" &&
        (item[1] === "" || item[1] === 0 || item[1] === null || !item[1])
      ) {
        copyData[item[0]] = null;
      }
      if (
        item[0] === "propertyName" &&
        (item[1] === "" || item[1] === 0 || item[1] === null || !item[1])
      ) {
        copyData[item[0]] = null;
      }
      if (
        item[0] === "tenure" &&
        (item[1] === "" || item[1] === null || !item[1])
      ) {
        copyData[item[0]] = null;
      }
      if (
        item[0] === "negotiable" &&
        (item[1] !== undefined || item[1] !== null)
      ) {
        copyData[item[0]] = item[1];
      }
    });
    delete copyData.locationmap;
    delete copyData.mapLoacation;
    copyData.developerId = initData.developerId;
    // const addPropertyId = localStorage.getItem("addPropertyId");
    // if (addPropertyId) copyData.propertyId = addPropertyId;
    body = { ...copyData, ...body };
    var retailAndOfficeimagesAndVideos = {};

    if (videoUrl) {
      const youtubeUrl =
        videoUrl.includes("youtube.com") &&
        (videoUrl.includes("watch?v=") || videoUrl.includes("embed/"));
      retailAndOfficeimagesAndVideos = {
        ...uploadPropertyObject,
        videoUrl: [{ url: videoUrl, type: youtubeUrl ? "youtube" : "vimeo" }],
      };
    } else {
      retailAndOfficeimagesAndVideos = {
        ...uploadPropertyObject,
      };
    }
    let obj = {
      imagesAndVideos: {
        ...retailAndOfficeimagesAndVideos,
      },
      // propertyId: localStorage.getItem("addPropertyId"),
    };
    if (propertyType == "Office space") {
      obj.propertyTypeId = propertyTypeId;
    }
    body = { ...body, ...obj };
    if (propertyType === "Office space") {
      let objData = {
        additionalDetails: {
          floor: officeproperytyObject.floor,
          propertyAge: Number(officeproperytyObject.propertyAge),
          totalFloors: Number(officeproperytyObject.totalFloors),
          cabins: Number(officeproperytyObject.cabins),
          meetingRooms: Number(officeproperytyObject.meetingRooms),
          bikeParking: officeproperytyObject.bikeParking,
          carParking: officeproperytyObject.carParking,
          washRooms: Number(officeproperytyObject.washRooms),
          idealFor: officeproperytyObject.idealFor,
          availablefloors: officeproperytyObject.availablefloors,
        },
        toilet: officeproperytyObject.toilet === "yes" ? true : false,
        mezzainineAvailable: officeproperytyObject.mezzainineAvailable,
        fitOuts: officeproperytyObject.fitOuts,
        powerBackup: officeproperytyObject.powerBackup === "yes" ? true : false,
        // propertyId: localStorage.getItem("addPropertyId"),
      };
      for (var propName in objData) {
        for (var innerobj in objData?.additionalDetails) {
          if (
            objData?.additionalDetails[innerobj] === "" ||
            objData.additionalDetails[innerobj]?.length === 0 ||
            !objData.additionalDetails[innerobj]
          ) {
            delete objData.additionalDetails[innerobj];
          }
        }
        if (
          !objData[propName] ||
          objData[propName] === "" ||
          objData[propName].length === 0
        ) {
          delete objData[propName];
        }
      }
      body = {
        ...body,
        ...objData,
        otherAmenities: officeproperytyObject.otherAmenities || [],
      };
    }

    if (propertyType === "Retail") {
      let data = handleDatas();
      body = { ...body, ...data };
    }
    let documentDetails = {};
    documentproperytyInput.forEach((element) => {
      documentDetails[element.name] = element.uploadFiles;
    });
    let finaldata = {
      propertyId: localStorage.getItem("addPropertyId"),
      documents: documentDetails,
    };
    body = { ...body, ...finaldata };
    return body;
    // const res = await postGetData("/property", {}, { ...body });

    // const { statusCode, data } = res;
    // if (statusCode === 200) {
    //   localStorage.setItem("addPropertyId", data.propertyId);
    //   setLoading(false);
    //   // gotoStep(2, true);
    // }
    // setLoading(false);
  };

  const handleDatas = () => {
    const objData = { ...retailproperytyObject };
    const finalData = {};

    finalData["additionalDetails"] = {
      floor: objData.floor,
      frontAge: objData.frontAge ? Number(objData.frontAge) : "",
      accessRoadWidth: objData.accessRoadWidth
        ? Number(objData.accessRoadWidth)
        : "",
      buildingStructure: objData.buildingStructure,
      propertyAge: objData.propertyAge ? Number(objData.propertyAge) : "",
      previousOccupancy: objData.previousOccupancy,
      idealFor: objData.idealFor,
    };
    finalData["lockInPeriod"] = objData.lockInPeriod
      ? Number(objData.lockInPeriod)
      : null;
    finalData["propertyName"] = objData.propertyName
      ? objData.propertyName
      : null;
    finalData["tenure"] = objData.tenure ? Number(objData.tenure) : null;

    finalData["rentFreePeriod"] = objData.rentFreePeriod
      ? Number(objData.rentFreePeriod)
      : "";

    for (var propName in finalData) {
      for (var innerobj in finalData?.additionalDetails) {
        if (
          !finalData?.additionalDetails[innerobj] ||
          finalData?.additionalDetails[innerobj] === "" ||
          finalData.additionalDetails[innerobj]?.length === 0
        ) {
          delete finalData.additionalDetails[innerobj];
        }
      }
      // if (properytyObject.transactionType !== "Sales") {
      if (
        (!finalData[propName] ||
          finalData[propName] === "" ||
          finalData[propName].length === 0) &&
        propName !== "tenure" &&
        propName !== "lockInPeriod" &&
        propName !== "propertyName"
      ) {
        delete finalData[propName];
      }

      // }
      // if (properytyObject.transactionType == "Sales" && (propName !== "tenure" || propName !== "lockInPeriod")) {
      //   if (
      //     !finalData[propName] ||
      //     finalData[propName] === "" ||
      //     finalData[propName].length === 0
      //   ) {
      //     delete finalData[propName];
      //   }
      // }
    }
    finalData["otherAmenities"] = objData.otherAmenities || [];
    // finalData["propertyId"] = localStorage.getItem("addPropertyId")
    return finalData;
  };

  const submitCoworkingProperty = async (event) => {
    event.preventDefault();
    let valid = true;
    let coworkDetailError = false;
    let coworkAdditionalError = false;
    let coworkFormatError = false;
    let coworkGoogleMapError = false;
    let coworkImagesError = false;
    coworkpropertyStepButtonRef?.current?.click();
    coworkUploadPropertyStepButtonRef?.current?.click();
    coworkingSpaceAdditionalStepButtonRef?.current?.click();
    coworkingFormatDataStepButtonRef?.current?.click();

    if (
      propertyType === "Flex Space" &&
      !coworkingSpaceAdditionalStepFormRef?.current?.checkValidity()
    ) {
      valid = false;
      coworkAdditionalError = true;
    }

    if (
      propertyType === "Flex Space" &&
      !coworkPropertyStepFormRef?.current?.checkValidity()
    ) {
      valid = false;
      coworkDetailError = true;
    }

    if (
      !coworkAdditionalProperytyObject?.otherAmenities ||
      coworkAdditionalProperytyObject?.otherAmenities.length <= 0
    ) {
      valid = false;
      setAmenietiesError(true);
      coworkAdditionalError = true;
    }

    if (
      coworkAdditionalProperytyObject?.nearestConnectivity &&
      coworkAdditionalProperytyObject?.nearestConnectivity.length > 0
    ) {
      let stationNameErrors = {};
      coworkAdditionalProperytyObject?.nearestConnectivity.forEach(
        (station) => {
          if (!station?.stationName) {
            stationNameErrors = {
              ...stationNameErrors,
              [station?.value]: "Please enter station name",
            };
          } else {
            return;
          }
        }
      );

      if (Object.keys(stationNameErrors).length > 0) {
        valid = false;
        setNearestLocationError({ ...stationNameErrors });
        coworkAdditionalError = true;
      }
    }

    if (Object.keys(checkCoworkImage()).length > 0) {
      valid = false;
      coworkImagesError = true;
    }

    if (
      isFalsy(coworkProperytyObject.location) ||
      coworkProperytyObject.pincode?.length !== 6 ||
      Object.keys(coworkProperytyObject?.microLocation).length === 0
    ) {
      valid = false;
      coworkDetailError = true;
    }

    if (!coworkProperytyObject?.gMapLatLong?.length > 0) {
      valid = false;
      setGoogleMapError(true);
      coworkGoogleMapError = true;
    }

    if (isExitData) {
      var count = 0;
      var validAmount = 0;
      coworkFormatDataObject.format.map((data) => {
        if (
          data.amount === "" ||
          data.amount === null ||
          data.amount === undefined
        ) {
          count++;
        }
        if (data.amount !== "" && Number(data.amount) <= 0) {
          validAmount++;
        } else if (isNaN(data.amount)) {
          validAmount++;
        }
      });

      if (count >= coworkFormatDataObject.format.length) {
        valid = false;
        setFormatError(true);
      }

      if (validAmount > 0) {
        valid = false;
        setFormatAmountError(true);
      }
    } else {
      var count = 0;
      var validAmount = 0;
      coworkFormatDataObject.map((data) => {
        if (
          data.amount === "" ||
          data.amount === null ||
          data.amount === undefined
        ) {
          count++;
        }

        if (data.amount !== "" && Number(data.amount) <= 0) {
          validAmount++;
        } else if (isNaN(data.amount)) {
          validAmount++;
        }
      });

      if (count >= 5) {
        valid = false;
        setFormatError(true);
        coworkFormatError = true;
      }

      if (validAmount > 0) {
        valid = false;
        setFormatAmountError(true);
        coworkFormatError = true;
      }
    }

    const isvalidUrl = validateVideoUrl();
    const isValidYoutubeVimeoUrl = isYouTubeOrVimeoUrl();
    if (videoUrl) {
      if (!isvalidUrl) {
        setVideoUrlError("Please enter correct URL");
        valid = false;
      } else if (!isValidYoutubeVimeoUrl) {
        valid = false;
        setVideoUrlError("Please enter Youtube or Vimeo URL only");
      }
    }

    if (coworkDetailError) {
      scrollToSection(coworkPropertyDetailDivRef, "start");
    } else if (coworkGoogleMapError) {
      scrollToSection(coworkGoogleMapDivRef, "center");
    } else if (coworkFormatError) {
      scrollToSection(coworkFormatDivRef, "center");
    } else if (coworkAdditionalError) {
      scrollToSection(coworkAdditionalDetailDivRef, "center");
    } else if (coworkImagesError) {
      scrollToSection(coworkImagesDivRef, "center");
    }

    if (valid) {
      let reqData = handleCoworkDataUpdate();
      setSubmitBtnLoading(true);
      let finalObj = {
        ...reqData,
        step: 5,
        propertyId: !isExitData
          ? localStorage.getItem("addPropertyId")
          : searchParams.get("propertyId"),
      };
      // console.log({finalObj})
      // return
      if (
        JSON.parse(localStorage.getItem("keydemand_data"))?.type !== "Developer"
      ) {
        delete finalObj.propertyUnit;
      }
      if (finalObj?.rentFreePeriod) {
        delete finalObj["rentFreePeriod"];
      }
      if (finalObj?.zone) {
        delete finalObj["zone"];
      }
      if (finalObj?.floor) {
        delete finalObj["floor"];
      }
      if (finalObj?.buildingStructure) {
        delete finalObj["buildingStructure"];
      }

      if (finalObj?.frontAge) {
        delete finalObj["frontAge"];
      }

      if (finalObj?.accessRoadWidth) {
        delete finalObj["accessRoadWidth"];
      }

      if (finalObj?.propertyAgainstDemand) {
        delete finalObj["propertyAgainstDemand"];
      }

      if (finalObj?.productId) {
        delete finalObj["productId"];
      }
      if (searchParams.get("developerType") === PHYSICALTEAM) {
        if (finalObj["userAccessibility"]?.length) {
          finalObj.userAccessibility = finalObj.userAccessibility?.map(
            (item) => item?.value
          );
        } else {
          finalObj.userAccessibility = UserBaseOpionsProperty?.map(
            (item) => item?.value
          );
        }
      }

      finalObj = removeEmptyStringValues(finalObj);
      // return
      const res = await postGetData("/property/coworkProperty", {}, finalObj);
      const { data, message, statusCode } = res;
      if (statusCode === 200) {
        //gotoStep(5, true);
        setSubmitBtnLoading(false);
        setInitData((prev) => ({
          ...prev,
          propertyDisplayId: data.productId?.display,
        }));
        localStorage.removeItem("addPropertyId");
        setOpenModal(true);
        // localStorage.removeItem("addPropertyId")
        sessionStorage.removeItem("fetchPropertyId");
      } else {
        setSubmitBtnLoading(false);
      }
    }
  };

  const submitProperty = async (event) => {
    let valid = true;
    let propertyDetailError = false;
    let propertyAdditionalDetailError = false;
    let retailPropertyAdditionalDetailError = false;
    let propertyImagesError = false;
    let propertyOcStatusError = false;
    let propertyVideoUrl = false;
    event.preventDefault();
    propertyStepButtonRef?.current?.click();
    uploadPropertyStepButtonRef?.current?.click();
    if (propertyType === "Office space") {
      officeSpaceAdditionalStepButtonRef?.current?.click();
    }
    if (propertyType === "Retail") {
      retailAdditionalStepButtonRef?.current?.click();
    }

    if (propertyType === "Retail" && !properytyObject?.toilet?.length > 0) {
      valid = false;
      propertyDetailError = true;
    }

    uploadDocumentStepButtonRef?.current?.click();
    if (Object.keys(checkImage()).length > 0) {
      valid = false;
      propertyImagesError = true;
    }

    if (isFalsy(properytyObject.location)) {
      valid = false;
      propertyDetailError = true;
    }
    if (
      propertyType === "Office space" &&
      !officeSpaceAdditionalStepFormRef?.current?.checkValidity()
    ) {
      valid = false;
      propertyAdditionalDetailError = true;
    }
    // if (
    //   !propertyStepFormRef?.current?.checkValidity() && getCurrentUserData().type !== "Developer"
    // ) {
    //   propertyDetailError = true;
    // }
    if (
      propertyType === "Retail" &&
      !retailAdditionalStepFormRef?.current?.checkValidity()
    ) {
      valid = false;
      retailPropertyAdditionalDetailError = true;
    }

    if (properytyObject.pincode?.length !== 6) {
      valid = false;
      propertyDetailError = true;
    }
    if (!properytyObject.microLocation?.name) {
      valid = false;
      propertyDetailError = true;
    }

    if (!properytyObject?.ocStatus?.length > 0) {
      valid = false;
      propertyDetailError = true;
    }

    if (
      properytyObject?.possession === "" ||
      isNaN(properytyObject?.possession)
    ) {
      valid = false;
      propertyDetailError = true;
    }

    // // if (properytyObject.transactionType !== "Sales") {
    // if (propertyType === 'Retail') {
    //   if (Number(retailproperytyObject.lockInPeriod) > Number(retailproperytyObject.tenure)) {
    //     valid = false
    //   }
    // } else {
    //   if (Number(properytyObject.lockInPeriod) > Number(properytyObject.tenure)) {
    //     valid = false
    //   }
    // }

    const isvalidUrl = validateVideoUrl();
    const isValidYoutubeVimeoUrl = isYouTubeOrVimeoUrl();
    if (videoUrl) {
      if (!isvalidUrl) {
        setVideoUrlError("Please enter correct URL");
        propertyVideoUrl = true;
        valid = false;
      } else if (!isValidYoutubeVimeoUrl) {
        valid = false;
        propertyVideoUrl = true;
        setVideoUrlError("Please enter Youtube or Vimeo URL only");
      }
    }
    // }

    // if (!propertyStepFormRef?.current?.checkValidity()) {
    //   // properyDetailUpdate()
    // if (properytyObject?.possession === "" || isNaN(properytyObject?.possession)) {
    //   valid = false
    // }
    // if (properytyObject.transactionType !== "Sales") {
    if (propertyType === "Retail") {
      if (
        Number(retailproperytyObject.lockInPeriod) >
        Number(retailproperytyObject.tenure)
      ) {
        valid = false;
        propertyDetailError = true;
      }
    } else {
      if (
        Number(properytyObject.lockInPeriod) > Number(properytyObject.tenure)
      ) {
        valid = false;
        propertyDetailError = true;
      }
    }
    if (parseInt(properytyObject.carpetAreaMax) < 1) {
      valid = false;
      propertyDetailError = true;
    }

    if (properytyObject?.propertyUnit === "multiple") {
      if (properytyObject.carpetAreaMin === "") {
        valid = false;
        propertyDetailError = true;
      }

      if (
        valid &&
        Number(properytyObject.carpetAreaMax) <
          Number(properytyObject.carpetAreaMin)
      ) {
        valid = false;
        propertyDetailError = true;
      }
    } else {
      if (parseInt(properytyObject.builtupArea) < 1) {
        valid = false;
        propertyDetailError = true;
      }
      if (
        Number(properytyObject.builtupArea) <
        Number(properytyObject.carpetAreaMax)
      ) {
        valid = false;
        propertyDetailError = true;
      }
    }

    let checkFor = [];
    const checkForLength = () => {
      let individualData = [
        "builtupArea",
        "carpetAreaMax",
        "ceilingHeight",
        "rentPsf",
      ];
      let multipleData = [
        "noOfUnits",
        "carpetAreaMax",
        "carpetAreaMin",
        "ceilingHeight",
        "rentPsf",
      ];
      checkFor =
        properytyObject?.propertyUnit == "multiple"
          ? multipleData
          : individualData;
      let datas = [false];
      datas = checkFor?.map((value) => {
        if (propertyType == "Office space" && value == "ceilingHeight") return;
        return Number(properytyObject[value]) > 0;
      });
      return datas?.includes(false) ? false : true;
    };
    if (!checkForLength()) {
      valid = false;
      propertyDetailError = true;
    }

    if (JSON.parse(localStorage.getItem("propertyFrom")) === "seller") {
      if (!properytyObject?.propertyName.trim()) {
        valid = false;
      }
    }

    // console.log(properytyObject.possession)
    if (
      !properytyObject?.about.trim() ||
      !properytyObject.possession ||
      !properytyObject.transactionType
    ) {
      valid = false;
    }

    //     let mandatoryData = ["propertyName", "possession", "about"]
    //     const mandatoryValuesCheck = () => {
    //       let checkData = mandatoryData?.map((value) => {
    //         return properytyObject[value]?.length > 0
    //       })
    //       return checkData?.includes(false) ? false : true
    //     }

    //     if (!mandatoryValuesCheck()) {
    //       valid = false
    //     }

    //remain fields

    if (propertyDetailError) {
      scrollToSection(PropertyDetailDivRef, "start");
    } else if (propertyAdditionalDetailError) {
      scrollToSection(AdditionalDetailDivRef, "center");
    } else if (propertyImagesError) {
      scrollToSection(ImagesDivRef, "center");
    } else if (propertyVideoUrl) {
      scrollToSection(propertyVideoURLDivRef, "center");
    } else if (retailPropertyAdditionalDetailError) {
      scrollToSection(retailAdditionalDetailDivRef, "center");
    }
    if (valid) {
      setSubmitBtnLoading(true);
      let reqData = properyDetailUpdate();
      let finalObj = {
        ...reqData,
        step: 5,
        propertyId: !isExitData
          ? localStorage.getItem("addPropertyId")
          : searchParams.get("propertyId"),
        location: properytyObject.location,
        microLocation: properytyObject.microLocation,
      };

      //Remove unwanted old keys start
      let addtional = finalObj.additionalDetails;

      delete finalObj["rentFreePeriod"];
      delete finalObj["zone"];
      delete finalObj["floor"];
      delete finalObj["frontAge"];
      delete finalObj["propertyAgainstDemand"];
      delete finalObj["productId"];
      delete finalObj["leaseEscalationValue"];

      if (addtional?.accessRoadWidth) {
        delete finalObj.additionalDetails["accessRoadWidth"];
      }
      if (addtional?.washRooms) {
        delete finalObj.additionalDetails["washRooms"];
      }
      if (finalObj?.zone) {
        delete finalObj["zone"];
      }
      if (finalObj?.floor) {
        delete finalObj["floor"];
      }
      // if (finalObj?.buildingStructure) {
      //   delete finalObj['buildingStructure'];
      // }

      if (addtional?.previousOccupancy) {
        delete finalObj.additionalDetails["previousOccupancy"];
      }
      if (searchParams.get("developerType") === PHYSICALTEAM) {
        if (finalObj["userAccessibility"]?.length) {
          finalObj.userAccessibility = finalObj.userAccessibility?.map(
            (item) => item?.value
          );
        } else {
          finalObj.userAccessibility = UserBaseOpionsProperty?.map(
            (item) => item?.value
          );
        }
      }

      finalObj = removeEmptyStringValues(finalObj);

      // if (addtional?.buildingStructure) {
      //   delete finalObj.additionalDetails['buildingStructure'];
      // }

      //Remove unwanted old keys end
      const res = await postGetData("/property", {}, finalObj);

      const { data, statusCode } = res;
      if (statusCode === 200) {
        //gotoStep(5, true);
        setInitData((prev) => ({
          ...prev,
          propertyDisplayId: data.productId?.display,
        }));
        localStorage.removeItem("addPropertyId");
        setOpenModal(true);
      }
      setSubmitBtnLoading(false);
    }
  };

  const scrollToSection = (sectionRef, from) => {
    if (sectionRef && sectionRef.current) {
      sectionRef.current.scrollIntoView({
        behavior: "smooth",
        block: from,
        inline: "nearest",
      });
    }
  };

  function validateVideoUrl(url) {
    var regex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\/?.*$/;
    return regex.test(videoUrl);
  }

  function isYouTubeOrVimeoUrl(url) {
    return /youtube\.com|vimeo\.com/.test(videoUrl);
  }

  const onClickContinue = () => {
    setOpenModal(false);
    console.log({ developerType: initData.developerType });
    if (type === "edit" && initData?.fromPage === "sellerReports") {
      navigate("/sellerReport/propertyList");
    } else if (initData?.fromPage === "agentReportsCustomer") {
      navigate("/agentReport/agentList/agentPage");
    } else if (type === "edit" && initData?.fromPage === "agentReports") {
      navigate("/agentReport/propertyList");
    } else if (
      type === "edit" &&
      initData?.fromPage === "enterpriseAgentReports"
    ) {
      navigate("/enterpriseAgentReport/propertyList");
    } else if (type === "edit" && initData?.fromPage === "physicalTeamReport") {
      navigate("/physicalTeamReport/propertyList");
    } else if (initData?.fromPage === "sellerReportsCustomer") {
      navigate("/sellerReport/developerList/developerPage");
    } else if (
      (type === "add" || type === "editProp") &&
      initData.developerType === "Agent"
    ) {
      navigate(`/customer/agentPage/${activeSlug}`);
    } else if (
      (type === "add" || type === "editProp") &&
      initData.developerType === "Developer"
    ) {
      navigate(`/customer/developerPage/${activeSlug}`);
    } else if (
      (type === "add" || type === "editProp") &&
      initData.developerType === ENTERPRISEAGENT
    ) {
      navigate(`/company/enterprise-agent/${activeSlug}`);
    } else if (
      (type === "add" || type === "editProp") &&
      initData.developerType === PHYSICALTEAM
    ) {
      navigate(`/company/physicalTeamPage/${activeSlug}`);
    } else {
      navigate("/properties/propertyDetailView/" + initData?.propertyDisplayId);
    }
  };
  const SubmittedContent = () => {
    return (
      <>
        <div>
          <span>Your Property has been Successfully posted</span>
        </div>
        <div className="btn-container">
          <Button className={"submit-btn px-3"} onClick={onClickContinue}>
            Got It
          </Button>
        </div>
      </>
    );
  };
  const propertyTypeChange = (type) => {
    setLoading(true);
    setPropertyType(type?.name);
    setPropertyTypeId(type?._id);
    setLoading(false);
  };
  const cityUpdate = (list) => {
    setCityList(list);
  };

  const finishStep = () => {
    setOpenModal(true);
  };
  const gotoBack = () => {
    if (propertyType === "Office space") {
      gotoStep(3);
    } else {
      gotoStep(4);
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter" && event.target.tagName !== "TEXTAREA") {
      event.preventDefault();
      submitProperty(event);
    }
  };
  useEffect(() => {
    if (step && step === 5) {
      document.addEventListener("keydown", handleKeyPress);
      return () => {
        document.removeEventListener("keydown", handleKeyPress);
      };
    }
  }, [step, propertyDetail]);

  return (
    <>
      <div className="property-container py-4 post-new-property">
        {loading && <Loader />}
        {!loading && (
          <React.Fragment>
            {propertyType && propertyType !== "Flex Space" ? (
              <React.Fragment>
                {/* {propertyType === "Retail" ? (
                  <Progress step={step} />
                ) : propertyType === "Office space" ? (
                  <OfficeProgress step={step} />
                ) : null} */}
                {propertyList.length ? (
                  <PropertyDetail
                    gotoStep={gotoStep}
                    propertyDetail={propertyDetail}
                    isExitData={isExitData}
                    step={step}
                    propertyTypeId={propertyTypeId}
                    pocUserId={pocUserId}
                    setPocUserId={setPocUserId}
                    setCityLocation={setCityLocation}
                    cityLocation={cityLocation}
                    propertyList={propertyList}
                    cityList={cityList}
                    cityUpdate={cityUpdate}
                    propertyType={propertyType}
                    initData={initData}
                    propertyTypeChange={propertyTypeChange}
                    properytyObject={properytyObject}
                    setProperyObject={setProperyObject}
                    propertyStepButtonRef={propertyStepButtonRef}
                    propertyStepFormRef={propertyStepFormRef}
                    properyDetailUpdate={properyDetailUpdate}
                    setChanged={setChanged}
                    setRateInfo={setRateInfo}
                    PropertyDetailDivRef={PropertyDetailDivRef}
                    usersTransactionType={usersTransactionType}
                  />
                ) : null}
                {propertyType === "Retail" ? (
                  <>
                    <UploadProperty
                      gotoStep={gotoStep}
                      propertyDetail={propertyDetail}
                      isExitData={isExitData}
                      step={step}
                      propertyType={propertyType}
                      initData={initData}
                      uploadPropertyObject={uploadPropertyObject}
                      setUploadPropertyObject={setUploadPropertyObject}
                      checkImage={checkImage}
                      uploadPropertyStepButtonRef={uploadPropertyStepButtonRef}
                      setChanged={setChanged}
                      ImagesDivRef={ImagesDivRef}
                    />

                    <UploadVideoUrl
                      propertyDetail={propertyDetail}
                      isExitData={isExitData}
                      setVideoUrl={setVideoUrl}
                      videoUrl={videoUrl}
                      videoUrlError={videoUrlError}
                      setVideoUrlError={setVideoUrlError}
                      propertyType={propertyType}
                      propertyVideoURLDivRef={propertyVideoURLDivRef}
                    />
                  </>
                ) : null}
                {propertyType === "Office space" ? (
                  <OfficeSpaceAdditionalDetails
                    gotoStep={gotoStep}
                    propertyDetail={propertyDetail}
                    isExitData={isExitData}
                    step={step}
                    initData={initData}
                    propertyDetailsInputValues={properytyObject}
                    properytyObject={officeproperytyObject}
                    setProperyObject={setOfficeProperyObject}
                    officeSpaceAdditionalStepButtonRef={
                      officeSpaceAdditionalStepButtonRef
                    }
                    officeSpaceAdditionalStepFormRef={
                      officeSpaceAdditionalStepFormRef
                    }
                    setChanged={setChanged}
                    AdditionalDetailDivRef={AdditionalDetailDivRef}
                  />
                ) : null}

                {propertyType === "Office space" ? (
                  <>
                    <UploadProperty
                      gotoStep={gotoStep}
                      propertyDetail={propertyDetail}
                      isExitData={isExitData}
                      step={step}
                      propertyType={propertyType}
                      propertyTypeId={propertyTypeId}
                      finishStep={finishStep}
                      initData={initData}
                      uploadPropertyObject={uploadPropertyObject}
                      setUploadPropertyObject={setUploadPropertyObject}
                      checkImage={checkImage}
                      uploadPropertyStepButtonRef={uploadPropertyStepButtonRef}
                      setChanged={setChanged}
                      ImagesDivRef={ImagesDivRef}
                    />

                    <UploadVideoUrl
                      propertyDetail={propertyDetail}
                      isExitData={isExitData}
                      setVideoUrl={setVideoUrl}
                      videoUrl={videoUrl}
                      videoUrlError={videoUrlError}
                      setVideoUrlError={setVideoUrlError}
                      propertyType={propertyType}
                      propertyVideoURLDivRef={propertyVideoURLDivRef}
                    />
                  </>
                ) : null}

                {propertyType === "Retail" ? (
                  <AdditionalAndTransaction
                    gotoStep={gotoStep}
                    isExitData={isExitData}
                    propertyDetail={propertyDetail}
                    step={step}
                    propertyDetailsInputValues={properytyObject}
                    initData={initData}
                    properytyObject={retailproperytyObject}
                    setProperyObject={setRetailProperyObject}
                    retailAdditionalStepButtonRef={
                      retailAdditionalStepButtonRef
                    }
                    retailAdditionalStepFormRef={retailAdditionalStepFormRef}
                    setChanged={setChanged}
                    retailAdditionalDetailDivRef={retailAdditionalDetailDivRef}
                  />
                ) : null}
                {/* {propertyType === "Retail" ? ( */}
                <UploadPropertyDocuments
                  gotoStep={gotoStep}
                  isExitData={isExitData}
                  propertyDetail={propertyDetail}
                  step={step}
                  initData={initData}
                  properytyInput={documentproperytyInput}
                  setPropertyInput={setDocumentPropertyInput}
                  uploadDocumentStepButtonRef={uploadDocumentStepButtonRef}
                  setChanged={setChanged}
                  type={type}
                />
                {/* ) : null} */}
              </React.Fragment>
            ) : null}

            {propertyType === "Flex Space" && (
              //Coworking space Code
              <React.Fragment>
                {propertyList.length > 0 ? (
                  <CoworkingPropertyDetail
                    gotoStep={gotoStep}
                    propertyDetail={propertyDetail}
                    isExitData={isExitData}
                    step={step}
                    propertyTypeId={propertyTypeId}
                    propertyList={propertyList}
                    pocUserId={pocUserId}
                    setPocUserId={setPocUserId}
                    setCityLocation={setCityLocation}
                    cityLocation={cityLocation}
                    cityList={cityList}
                    cityUpdate={cityUpdate}
                    propertyType={propertyType}
                    initData={initData}
                    propertyTypeChange={propertyTypeChange}
                    properytyObject={coworkProperytyObject}
                    setProperyObject={setCoworkProperytyObject}
                    propertyStepButtonRef={coworkpropertyStepButtonRef}
                    propertyStepFormRef={coworkPropertyStepFormRef}
                    properyDetailUpdate={handleCoworkDataUpdate}
                    setChanged={setChanged}
                    setRateInfo={setRateInfo}
                    googleMapError={googleMapError}
                    setGoogleMapError={setGoogleMapError}
                    coworkPropertyDetailDivRef={coworkPropertyDetailDivRef}
                    coworkGoogleMapDivRef={coworkGoogleMapDivRef}
                  />
                ) : null}

                {propertyType == "Flex Space" ? (
                  // <CoworkingFormatData
                  //   gotoStep={gotoStep}
                  //   propertyDetail={propertyDetail}
                  //   isExitData={isExitData}
                  //   step={step}
                  //   properytyObject={coworkFormatDataObject}
                  //   setProperyObject={setCoworkFormatDataObject}
                  //   coworkingFormatDataStepButtonRef={coworkingFormatDataStepButtonRef}
                  //   coworkingFormatDataStepFormRef={coworkingFormatDataStepFormRef}
                  //   setChanged={setChanged}
                  // />
                  <CoworkingFormatData
                    gotoStep={gotoStep}
                    propertyDetail={propertyDetail}
                    isExitData={isExitData}
                    step={step}
                    initData={initData}
                    properytyObject={coworkFormatDataObject}
                    setProperyObject={setCoworkFormatDataObject}
                    coworkingFormatDataStepButtonRef={
                      coworkingFormatDataStepButtonRef
                    }
                    coworkingFormatDataStepFormRef={
                      coworkingFormatDataStepFormRef
                    }
                    setChanged={setChanged}
                    setFormatError={setFormatError}
                    formatError={formatError}
                    setFormatAmountError={setFormatAmountError}
                    formatAmountError={formatAmountError}
                    coworkFormatDivRef={coworkFormatDivRef}
                  />
                ) : null}

                {propertyType == "Flex Space" ? (
                  <CoworkingSpaceAdditionalDetails
                    gotoStep={gotoStep}
                    propertyDetail={propertyDetail}
                    isExitData={isExitData}
                    step={step}
                    initData={initData}
                    properytyObject={coworkAdditionalProperytyObject}
                    setProperyObject={setCoworkAdditionalProperytyObject}
                    coworkingSpaceAdditionalStepButtonRef={
                      coworkingSpaceAdditionalStepButtonRef
                    }
                    coworkingSpaceAdditionalStepFormRef={
                      coworkingSpaceAdditionalStepFormRef
                    }
                    setChanged={setChanged}
                    amenietiesError={amenietiesError}
                    setAmenietiesError={setAmenietiesError}
                    setNearestLocationError={setNearestLocationError}
                    nearestLocationError={nearestLocationError}
                    coworkAdditionalDetailDivRef={coworkAdditionalDetailDivRef}
                  />
                ) : null}

                {propertyType == "Flex Space" ? (
                  <>
                    <CoworkUploadProperty
                      gotoStep={gotoStep}
                      propertyDetail={propertyDetail}
                      isExitData={isExitData}
                      step={step}
                      propertyType={propertyType}
                      propertyTypeId={propertyTypeId}
                      finishStep={finishStep}
                      initData={initData}
                      uploadPropertyObject={coworkUploadPropertyObject}
                      setUploadPropertyObject={setCoworkUploadPropertyObject}
                      checkImage={checkCoworkImage}
                      uploadPropertyStepButtonRef={
                        coworkUploadPropertyStepButtonRef
                      }
                      setChanged={setChanged}
                      coworkImagesDivRef={coworkImagesDivRef}
                    />

                    <CoworkUploadDocuments
                      gotoStep={gotoStep}
                      isExitData={isExitData}
                      propertyDetail={propertyDetail}
                      step={step}
                      properytyInput={documentCoworkInput}
                      setPropertyInput={setDocumentCoworkInput}
                      uploadDocumentStepButtonRef={uploadDocumentStepButtonRef}
                      setChanged={setChanged}
                    />

                    <UploadVideoUrl
                      propertyDetail={propertyDetail}
                      isExitData={isExitData}
                      setVideoUrl={setVideoUrl}
                      videoUrl={videoUrl}
                      videoUrlError={videoUrlError}
                      setVideoUrlError={setVideoUrlError}
                      propertyType={propertyType}
                    />
                  </>
                ) : null}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        {/* {step && step === 5 ? <div className="hidebody"></div> : null} */}
        <Row>
          <Col md="1"></Col>
          <Col md="10">
            <div className="text-end">
              <Button
                type="button"
                className="pre-btn me-3"
                onClick={() => {
                  if (changed) {
                    setWarning(true);
                  } else {
                    navigate("/");
                  }
                }}
              >
                Cancel
              </Button>
              <Button
                type="button"
                className="next-btn p-0"
                loading={loading || submitBtnLoading}
                onClick={
                  propertyType === "Flex Space"
                    ? submitCoworkingProperty
                    : submitProperty
                }
              >
                Submit
              </Button>
            </div>
          </Col>
        </Row>
      </div>
      {openModal && (
        <FormModal
          show={openModal}
          // onClose={setOpenModal}
          heading={"Property Submitted"}
          customer={false}
          children={SubmittedContent()}
          size={"md"}
          hideCloseIcon={true}
        />
      )}

      {openWarning && (
        <AdminWarningPopup
          title={"Confirmation"}
          show={openWarning}
          message={"Are you sure want to discard these changes ?"}
          warningYesClick={() => {
            setWarning(false);
            navigate("/");
          }}
          onClose={() => {
            setWarning(false);
          }}
        />
      )}
    </>
  );
};
export default NewAddProperty;
