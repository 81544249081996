import React from "react";
import { Form } from "react-bootstrap";
import "./style.scss";

const CustomSelect = ({
  label,
  name,
  value,
  onChange,
  placeholder,
  size,
  errorMessage,
  options,
  isInvalid,
  addText,
  onBlur,
  disabled,
  required,
  isHideSelect,
  autoFocus,
}) => {
  
  const handleChange = (e) => {
    const selectedOption = options.find(
      (option) =>
        option._id === e.target.value ||
        option.value === e.target.value ||
        option.cityId === e.target.value ||
        option.name === e.target.value
    );
    onChange(e.target.value, selectedOption?.name || selectedOption?.label || "");
  };

  return (
    <Form.Group controlId={name} className={`form-group select-group ${size}`}>
      <div className="d-grid">
        {label && (
          <Form.Label className="fw-700 f-14 text-black">
            {label} {required && "*"}
          </Form.Label>
        )}
        {addText && (
          <label className="fw-400 f-14 text-black mb-1">
            {addText}
          </label>
        )}
      </div>
      <Form.Select
        required={required}
        name={name}
        value={value}
        placeholder={placeholder}
        isInvalid={isInvalid}
        onChange={handleChange}
        autoComplete="off"
        onBlur={onBlur}
        disabled={disabled}
        autoFocus={autoFocus}
      >
        {!isHideSelect && (
          <option value="">{placeholder || "Select"}</option>
        )}
        {options?.map((item, index) => (
          <option
            key={index}
            value={
              item._id
                ? item._id
                : item.value
                ? item.value
                : item.cityId
                ? item.cityId
                : item.name
            }
          >
            {item?.name || item?.label || item}
          </option>
        ))}
      </Form.Select>
      {isInvalid && <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback>}
    </Form.Group>
  );
};

export default CustomSelect;
