import { useState, useRef, useEffect } from "react";
import Button from "../../../../../components/Form/Button";
import FormModal from "../../../../../components/Modal";
import Tab from "../../../../../components/Tab/index";
import BuyerPocModal from "../pocModal/buyerPocModal";
import SellerPocModal from "../pocModal/sellerPocModal";
import BrandPOCTable from "./brandPocTable";
import RegionPOCTable from "./regionPocTable";
import { useSelector } from "react-redux";
import { getData } from "../../../../../api";
import AgentPocModal from "../pocModal/agentPocModal";
import { ENTERPRISEAGENT, PHYSICALTEAM, PHYSICAL_TEAM } from "../../../../../custom/constants";

const PocTable = ({
  customerDetails,
  companyTypeReceived,
  type,
  view,
  viewPage,
  regions,
  access
}) => {

  console.log(customerDetails)
  const brandIdUpdate = useSelector((state) => state.login.brandId);
  const enterpriseAgentId = useSelector(
    (state) => state.login.enterpriseAgentId
  );
  const physicalTeamId = useSelector((state) => state.login.physicalTeamId);
  const [key, setKey] = useState("brandPOC");
  const [openModal, setOpenModal] = useState(false);
  const [cityList, setCityList] = useState([]);
  const [pocType, setPocType] = useState("");
  const refRegion = useRef();
  const refBrand = useRef();
  const tabDetails = [
    {
      title: type === "developer" ? "Developer POC" : "Brand POC",
      content: (
        <BrandPOCTable
          view={view}
          ref={refBrand}
          modkey={"brandPOC"}
          type={type}
          companyTypeReceived={companyTypeReceived}
          brandID={brandIdUpdate}
          setPocType={setPocType}
          viewPage={viewPage}
          access={access}
        />
      ),
      eventKey: "brandPOC",
    },
    {
      title: type === "developer" ? "City POC" : "Region POC",
      content: (
        <RegionPOCTable
          customerDetails={customerDetails}
          cityList={cityList}
          view={view}
          ref={refRegion}
          modkey={type === "developer" ? "cityPOC" : "regionPOC"}
          type={type}
          companyTypeReceived={companyTypeReceived}
          brandID={brandIdUpdate}
          setPocType={setPocType}
          viewPage={viewPage}
          regions={regions}
          access={access}
        />
      ),
      eventKey: type === "developer" ? "cityPOC" : "regionPOC",
    },
  ];

  const enterpriseAgentTabDetails = [
    {
      title:type === PHYSICALTEAM
      ? "Physical Team POC" : "Agent POC",
      content: (
        <BrandPOCTable
          view={view}
          ref={refBrand}
          modkey={"brandPOC"}
          type={type}
          companyTypeReceived={companyTypeReceived}
          brandID={
            type === ENTERPRISEAGENT
              ? enterpriseAgentId
              : type === PHYSICALTEAM
              ? physicalTeamId
              : ""
          }
          setPocType={setPocType}
          viewPage={viewPage}
          access={access}
        />
      ),
      eventKey: "brandPOC",
    },
  ];

  const getCities = () => {
    let url = `/cityZones/checkZoneAvailability/${brandIdUpdate}`;

    getData(url).then((res) => {
      if (res.status) {
        let data = res?.data;
        setCityList(data.city);
      }
    });
  };

  const setTabKey = (k) => {
    setKey(k);
  };
  const onOpenPocModal = () => {
    setOpenModal(true);
    setPocType("");
  };
  const handleClose = () => {
    setOpenModal(false);
  };
  useEffect(() => {
    if (type === "developer") {
      getCities();
    }
  }, []);

  function getTblHeading(){
    let dynlbl = 'Agent'
    if(type === ENTERPRISEAGENT){
      dynlbl = 'E Agent'
    }
    if(type === PHYSICALTEAM){
      dynlbl = 'Physical Team'
    }

    return `Add ${dynlbl} POC`
  }
  return (
    <div>
      {view ? null : type === ENTERPRISEAGENT || type === PHYSICALTEAM ? (
        <div className="btn-container">
          <Button
            type="button"
            className="btn-color-primary d-block"
            onClick={onOpenPocModal}
          >
            {getTblHeading()}
          </Button>
        </div>
      ) : (
        <div className="btn-container">
          <Button
            type="button"
            className="btn-color-primary d-block"
            onClick={onOpenPocModal}
          >
            {type === "developer" && key === "brandPOC"
              ? "Add Developer POC"
              : key === "cityPOC" && type === "developer"
              ? "Add city POC"
              : key === "regionPOC" && type === "brand"
              ? "Add region POC"
              : "Add brand POC"}
          </Button>
        </div>
      )}
      <Tab
        tabDetails={
          type === ENTERPRISEAGENT || type === PHYSICALTEAM
            ? enterpriseAgentTabDetails
            : tabDetails
        }
        activeKey={key}
        onSelect={(k) => setTabKey(k)}
      />

      <FormModal
        show={openModal}
        onClose={handleClose}
        heading={"Add POC"}
        children={
          type === "developer" ? (
            <SellerPocModal
              customerDetails={customerDetails}
              companyTypeReceived={companyTypeReceived}
              modKey={key}
              type={type}
              regions={regions}
              pocType={pocType}
              id={brandIdUpdate}
              cityList={cityList}
              pocList={() => {
                if (key === "brandPOC") {
                  refBrand.current.getPocList();
                } else {
                  refRegion.current.getPocList();
                }
              }}
              onClose={handleClose}
            />
          ) : type === ENTERPRISEAGENT || type === PHYSICALTEAM ? (
            <AgentPocModal
              customerDetails={customerDetails}
              companyTypeReceived={companyTypeReceived}
              modKey={key}
              type={type}
              regions={regions}
              pocType={pocType}
              id={type === ENTERPRISEAGENT ? enterpriseAgentId : physicalTeamId}
              cityList={cityList}
              pocList={() => {
                if (key === "brandPOC") {
                  refBrand.current.getPocList();
                } else {
                  refRegion.current.getPocList();
                }
              }}
              onClose={handleClose}
            />
          ) : (
            <BuyerPocModal
              customerDetails={customerDetails}
              companyTypeReceived={companyTypeReceived}
              modKey={key}
              type={type}
              regions={regions}
              pocType={pocType}
              // id={brandID}
              id={brandIdUpdate}
              cityList={cityList}
              pocList={() => {
                if (key === "brandPOC") {
                  refBrand.current.getPocList();
                } else {
                  refRegion.current.getPocList();
                }
              }}
              onClose={handleClose}
            />
          )
        }
        size={"lg"}
      />
    </div>
  );
};
export default PocTable;
