import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//library
import { useLocation, useNavigate } from "react-router-dom";

//components
import Button from "../../components/Form/Button";
import Search from "../../components/Search/search";
import Tab from "../../components/Tab/index";

//fetch
import { AccessDenied } from "../../api/index";

//table
import { useContext } from "react";
import { AppContext } from "../../App";
import NavBar from "../../components/Navbar";
import {
  getTableStoreValue,
  setTableFilterData,
} from "../../utilities/pageScroll";
import {
  updateBrandId,
  updateComapanyId,
  updateCompanyFormStatus,
  updateParentPageData,
} from "../Login/reducer";
import BuyerTable from "./CompanyTable/enterpriseBuyerTable";
import SellerTable from "./CompanyTable/enterpriseSellerTable";
import "./style.scss";
import EnterpriseAgentTable from "./CompanyTable/enterpriseAgentTable";
import PhysicalTeamTable from "./CompanyTable/physicalTeamTable";

const Company = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const loginPermission = useSelector((state) => state.login.userPermission);
  const menuId = useSelector((state) => state.login.menuId);
  const navigate = useNavigate();
  const [key, setKey] = useState(
    location?.state?.companyActiveKey
      ? location?.state?.companyActiveKey
      : localStorage.getItem("companyTabKey")
      ? localStorage.getItem("companyTabKey")
      : loginPermission.includes(menuId.companyBuyer)
      ? "enterpriseBuyers"
      : loginPermission.includes(menuId.company)
      ? "enterpriseSellers"
      : loginPermission.includes(menuId.companyAgent)
      ? "enterpriseAgents"
      : "enterpriseBuyers"
  );
  const { tableStoreContext, setTableStoreContext } = useContext(AppContext);
  const currentTableValueFromConText = getTableStoreValue(
    key,
    tableStoreContext
  );
  const storetableName = currentTableValueFromConText?.tableName;
  const storePageNo = currentTableValueFromConText?.pageNo;

  const [offset, setOffset] = useState(
    storetableName === key ? (storePageNo ? storePageNo : 1) : 1
  );

  const access = {
    viewCompanySeller: loginPermission?.includes(menuId?.viewCompanySeller)
      ? true
      : false,
    addCompanySeller: loginPermission?.includes(menuId?.addCompanySeller)
      ? true
      : false,
    editCompany: loginPermission?.includes(menuId?.editCompany) ? true : false,
    deleteCompany: loginPermission?.includes(menuId?.deleteCompany)
      ? true
      : false,
    companySellerEnableDisable: loginPermission?.includes(
      menuId?.companySellerEnableDisable
    )
      ? true
      : false,
    viewCompanyBuyer: loginPermission?.includes(menuId?.viewCompanyBuyer)
      ? true
      : false,
    addCompanyBuyer: loginPermission?.includes(menuId?.addCompanyBuyer)
      ? true
      : false,
    editCompanyBuyer: loginPermission?.includes(menuId?.editCompanyBuyer)
      ? true
      : false,
    companyBuyerEnableDisable: loginPermission?.includes(
      menuId?.companyBuyerEnableDisable
    )
      ? true
      : false,

    viewCompanyAgent: loginPermission?.includes(menuId?.viewCompanyAgent)
      ? true
      : false,
    addCompanyAgent: loginPermission?.includes(menuId?.addCompanyAgent)
      ? true
      : false,
    editCompanyAgent: loginPermission?.includes(menuId?.editCompanyAgent)
      ? true
      : false,
    companyAgentEnableDisable: loginPermission?.includes(
      menuId?.companyAgentEnableDisable
    )
      ? true
      : false,
    switchingCapabilityCompanyAgent: loginPermission?.includes(
      menuId?.switchingCapabilityCompanyAgent
    )
      ? true
      : false,
    switchingCapabilityCompanyBuyer: loginPermission?.includes(
      menuId?.switchingCapabilityCompanyBuyer
    )
      ? true
      : false,
    switchingCapabilityCompanySeller: loginPermission?.includes(
      menuId?.switchingCapabilityCompanySeller
    )
      ? true
      : false,
    switchingCapabilityCompanyAgent: loginPermission?.includes(
      menuId?.switchingCapabilityCompanyAgent
    )
      ? true
      : false,
    deleteCompanyAgent: loginPermission?.includes(menuId?.deleteCompanyAgent)
      ? true
      : false,
    //physical team
    viewCompanyPhysicalTeam: loginPermission?.includes(
      menuId?.viewCompanyPhysicalTeam
    )
      ? true
      : false,
    addCompanyPhysicalTeam: loginPermission?.includes(
      menuId?.addCompanyPhysicalTeam
    )
      ? true
      : false,
    editCompanyPhysicalTeam: loginPermission?.includes(
      menuId?.editCompanyPhysicalTeam
    )
      ? true
      : false,
    companyPhysicalTeamEnableDisable: loginPermission?.includes(
      menuId?.companyPhysicalTeamEnableDisable
    )
      ? true
      : false,
    switchingCapabilityCompanyPhysicalTeam: loginPermission?.includes(
      menuId?.switchingCapabilityCompanyPhysicalTeam
    )
      ? true
      : false,
    deleteCompanyPhysicalTeam: loginPermission?.includes(
      menuId?.deleteCompanyPhysicalTeam
    )
      ? true
      : false,
  };
  const [search, setSearch] = useState(
    storetableName === key ? currentTableValueFromConText?.search : ""
  );

  useEffect(() => {
    dispatch(updateBrandId(""));
    dispatch(updateComapanyId(""));
    dispatch(updateCompanyFormStatus(""));
    dispatch(updateParentPageData(""));
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("companyTabKey")) {
      localStorage.setItem("companyTabKey", key);
    }
  }, []);

  const setContextValue = () => {
    let newValue = setTableFilterData(
      storetableName,
      { search: search, pageNo: 1, perPage: 10, id: "" },
      tableStoreContext
    );
    setTableStoreContext(newValue);
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
    setOffset(1);
  };

  const tabDetails = [
    loginPermission.includes(menuId?.companyBuyer) && {
      title: "Enterprise Buyers",
      content: (
        <>
          {key === "enterpriseBuyers" ? (
            <BuyerTable
              access={access}
              offSet={offset}
              search={search}
              setOffset={setOffset}
            />
          ) : null}
        </>
      ),
      eventKey: "enterpriseBuyers",
    },
    loginPermission.includes(menuId?.company) && {
      title: "Enterprise Sellers",
      content: (
        <>
          {key === "enterpriseSellers" ? (
            <SellerTable
              offSet={offset}
              access={access}
              search={search}
              setOffset={setOffset}
            />
          ) : null}
        </>
      ),
      eventKey: "enterpriseSellers",
    },
    loginPermission.includes(menuId.companyAgent) && {
      title: "Enterprise Agents",
      content: (
        <>
          {key === "enterpriseAgents" ? (
            <EnterpriseAgentTable
              offSet={offset}
              access={access}
              search={search}
              setOffset={setOffset}
            />
          ) : null}
        </>
      ),
      eventKey: "enterpriseAgents",
    },
    loginPermission.includes(menuId.companyPhysicalTeam) && {
      title: "Physical Team",
      content: (
        <>
          {key === "physicalTeam" ? (
            <PhysicalTeamTable
              offSet={offset}
              access={access}
              search={search}
              setOffset={setOffset}
            />
          ) : null}
        </>
      ),
      eventKey: "physicalTeam",
    },
  ];

  const onClick = (e, value) => {
    if (value === "add") {
      localStorage.setItem("brandEdit", JSON.stringify(false));
      localStorage.setItem("physicalTeamEdit", JSON.stringify(false));
      dispatch(updateBrandId(""));
      setLoading(true);
      if (key === "enterpriseBuyers") {
        setLoading(false);
        navigate("/company/brandCompanyCreation", {
          state: { roleObj: "Brand" },
        });
      } else if (key === "enterpriseSellers") {
        setLoading(false);
        navigate("/company/developerCompanyCreation", {
          state: { roleObj: "Developer" },
        });
      } else if (key === "enterpriseAgents") {
        setLoading(false);
        navigate("/company/enterpriseAgentCompanyCreation", {
          state: { roleObj: "EnterpriseAgent" },
        });
      } else {
        setLoading(false);
        navigate("/company/physicalTeamCompanyCreation", {
          state: { roleObj: "physicalTeam" },
        });
      }
    }
  };

  const setTabKey = (k) => {
    setOffset(1);
    setKey(k);
    setContextValue(k);
    localStorage.setItem("companyTabKey", k);
  };
  let customerView =
    loginPermission?.includes(menuId.companyBuyer) ||
    loginPermission?.includes(menuId.company);

  let buyerAdd =
    key === "enterpriseBuyers" &&
    loginPermission?.includes(menuId?.addCompanyBuyer);
  let sellerAdd =
    key === "enterpriseSellers" &&
    loginPermission?.includes(menuId?.addCompanySeller);
  let enterpriseAgentAdd =
    key === "enterpriseAgents" &&
    loginPermission?.includes(menuId?.addCompanyAgent);
  let physicalTeamAdd =
    key === "physicalTeam" &&
    loginPermission?.includes(menuId?.addCompanyPhysicalTeam);

  return (
    <>
      {customerView ? (
        <div>
          <div className="d-flex justify-content-between">
            <NavBar />
            <div className="d-flex " style={{ textAlign: "end" }}>
              <Search onChange={handleSearch} value={search} />
              <div className="col-md-5 mr-4">
                <Button
                  onClick={(e) => onClick(e, "add")}
                  // disabled={loading}
                  loading={loading}
                  className="btn-color-primary mobilePosition"
                  disabled={
                    buyerAdd ||
                    sellerAdd ||
                    enterpriseAgentAdd ||
                    physicalTeamAdd
                      ? false
                      : true
                  }
                >
                  Add Company
                </Button>
              </div>
            </div>
          </div>

          <Tab
            tabDetails={tabDetails}
            activeKey={key}
            onSelect={(k) => setTabKey(k)}
          />
        </div>
      ) : (
        <AccessDenied />
      )}
    </>
  );
};

export default Company;
