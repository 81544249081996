import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { AccessDenied, getData } from "../../api/index";
import { DemandDetailCard } from "../../components/Cards/Demands/demandDetailCard";
import Loader from "../../components/Loader";
import NavBar from "../../components/Navbar";
import DemandDetailListCard, { DemandFormatListCard } from "../../components/demandDetailList/demandDetailCard";
import {
  ageProperty, closeTimeList, floorOffice,
  floorOption,
  rentFree
} from "../AddNewDemand/PostDemandForms/dropDownValues";
import { demandValues } from "./demandValues";
import "./style.scss";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import Button from "../../components/Form/Button";

export default function DemandDetailView() {
  const [demand, setdemand] = useState({});
  const location = useLocation();
  const [requiredArr, setRequiredArr] = useState([]);
  const [nonRequiredArr, setNonRequiredArr] = useState([]);
  const [formatArr, setFormatArr] = useState([])
  const [loading, setLoading] = useState(false)
  const [initLoading, setInitLoading] = useState(false);
  const { pathDemandId } = useParams();
  const stateData = location.state;
  const demandId = stateData?.demandId ? stateData?.demandId : pathDemandId;
  const loginPermission = useSelector(
    (state) => state.login.userPermission
  );
  const menuId = useSelector((state) => state.login.menuId);
  const checkPushValue = (data) => {
    let requiredfield = [];
    let nonRequiredfield = [];

    data?.format?.length > 0 && setFormatArr(data?.format)

    demandValues.filter((item) => item.showLabel.includes(data?.propertyType?.name)).map((items) => {
      Object.entries(data)?.map(([key, value]) => {
        if (items.required === key && value) {
          if (items.required === "otherAmenitiesRequired") {
            requiredfield.push({ name: key, fieldValue: data[items.value]?.map((item) => item.label), lable: items.lable, subText: items.subText })
          }
          else if (items.value === "propertyType") {
            requiredfield.push({
              name: key,
              fieldValue: data[items.value]?.name
                ? data[items.value]?.name
                : "-",
              lable: items.lable,
              subText: items.subText,
            });
          }
          else if (items.required === "microLocationRequired") {
            requiredfield.push({ name: key, fieldValue: data[items.value]?.description ? data[items.value]?.description : "", lable: items.lable, subText: items.subText })
          }
          else if (items.required === "rentFreePeriodRequired") {
            let val = rentFree.filter((item) => item._id === data[items.value]).map((item) => item.name);
            requiredfield.push({ name: key, fieldValue: val, lable: items.lable, subText: items.subText })
          }
          else if (items.required === "propertyAgeRequired") {
            let val = ageProperty.filter((item) => item._id === String(data[items.value])).map((item) => item.name);
            requiredfield.push({ name: key, fieldValue: val, lable: items.lable, subText: items.subText })
          }
          else if (items.required === "endUseRequired") {
            requiredfield.push({ name: key, fieldValue: data[items.value]?.name, lable: items.lable, subText: items.subText })
          }
          else if (items.required === "transactionTimelineRequired") {
            let val = closeTimeList.filter((item) => item._id === String(data[items.value])).map((item) => item.name);
            requiredfield.push({ name: key, fieldValue: val, lable: items.lable, subText: items.subText })
          }
          else if (items.required === "otherInterestedLocationRequired") {

            requiredfield.push({
              name: key,
              fieldValue: data[items.value]?.map((item) => item?.description),
              lable: items.lable,
              subText: items.subText,
            });


          }
          else if (items.required === "lockInPeriodRequired") {
            requiredfield.push({
              name: key,
              fieldValue: (data[items.value] === 0 || data[items.value]) ? data[items.value] : "-",
              lable: items.lable,
              subText: (data[items.value] === 0 || data[items.value]) ? items.subText : "",
            });
          }
          else if (items.required === "floorRequired") {
            let listValue = data?.propertyType?.name === "Retail" ? floorOption : floorOffice;
            requiredfield.push({
              name: key,
              fieldValue: listValue
                .filter((item) => item._id === data[items.value])
                .map((item) => item.name),
              lable: items.lable,
              subText: data[items.value] ? items.subText : "",
            });
          }

          else {
            requiredfield.push({ name: key, fieldValue: data[items.value], lable: items.lable, subText: items.subText })
          }

        }
        else if (items.required === key && !value) {

          if (items.required === "otherAmenitiesRequired") {
            nonRequiredfield.push({ name: key, fieldValue: data[items.value]?.map((item) => item.label), lable: items.lable, subText: items.subText })
          }
          else if (items.required === "rentFreePeriodRequired") {
            let val = rentFree.filter((item) => item._id === data[items.value]).map((item) => item.name);

            nonRequiredfield.push({ name: key, fieldValue: val, lable: items.lable, subText: items.subText })
          }
          else if (items.required === "propertyAgeRequired") {
            let val = ageProperty.filter((item) => item._id === String(data[items.value])).map((item) => item.name);
            nonRequiredfield.push({ name: key, fieldValue: val, lable: items.lable, subText: items.subText })
          }
          else if (items.required === "otherInterestedLocationRequired") {

            nonRequiredfield.push({
              name: key,
              fieldValue: data[items.value]?.map((item) => item?.description),
              lable: items.lable,
              subText: items.subText,
            });


          }
          else if (items.required === "endUseRequired") {
            nonRequiredfield.push({
              name: key,
              fieldValue: data[items.value]?.name,
              lable: items.lable,
              subText: items.subText,
            });
          }
          else if (items.required === "lockInPeriodRequired") {
            nonRequiredfield.push({
              name: key,
              fieldValue: (data[items.value] === 0 || data[items.value]) ? data[items.value] : "-",
              lable: items.lable,
              subText: (data[items.value] === 0 || data[items.value]) ? items.subText : "",
            });
          }
          else if (items.required === "transactionTimelineRequired") {
            let val = closeTimeList.filter((item) => item._id === String(data[items.value])).map((item) => item.name);
            nonRequiredfield.push({ name: key, fieldValue: val, lable: items.lable, subText: items.subText })
          }
          else if (items.required === "floorRequired") {
            let listValue = data?.propertyType?.name === "Retail" ? floorOption : floorOffice;
            nonRequiredfield.push({
              name: key,
              fieldValue: listValue
                .filter((item) => item._id === data[items.value])
                .map((item) => item.name),
              lable: items.lable,
              subText: data[items.value] ? items.subText : "",
            });
          }
          else {
            nonRequiredfield.push({ name: key, fieldValue: data[items.value], lable: items.lable, subText: items.subText })
          }


        }
        setRequiredArr(requiredfield);
        setNonRequiredArr(nonRequiredfield);


      })

    })


  }
  const getAllData = async () => {
    setLoading(true)
    let url = `/demand/getDemand/${demandId ? demandId : JSON.parse(localStorage.getItem('demandid'))}`;
    const response = await getData(url, {}, {});
    const { statusCode, data } = response;
    if (statusCode === 200) {
      setdemand(data);
      checkPushValue(data)
      setLoading(false)
    } else {
      setLoading(false)
    }
  };

  const onClickDownloadPdf = () => {
    setInitLoading(true);
    let element = document.getElementById("pdf-conversion-el");
    html2canvas(element, {
      logging: true,
      letterRendering: 1,
      scale: 2,
      windowWidth: 1440,
      useCORS: true,
      // onclone: (document: Document) => {
      //   const div = document.getElementById('pdf-snapshot-render');
      //   div.style.overflow = 'visible';
      // },
    }).then((canvas) => {
      var imgData = canvas.toDataURL("image/png");
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;
      var doc = new jsPDF("p", "mm");
      var position = 0;
      doc.addImage(
        imgData,
        "jpeg",
        0,
        position,
        imgWidth,
        imgHeight,
        "",
        "FAST"
      );
      heightLeft -= pageHeight;
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(
          imgData,
          "jpeg",
          0,
          position,
          imgWidth,
          imgHeight,
          "",
          "FAST"
        );
        heightLeft -= pageHeight;
      }
      doc.save("Demand view.pdf");
      setInitLoading(false);
    });
  };


  useEffect(() => {
    window.scrollTo(0, 0)
    getAllData();

  }, []);


  const listingItems = (items) => {

    return (
      <>
        {items?.length > 0 ?
          <ul className="m-0">
            {items.map((item) => {
              return <li key={item} className="w-100 f-16 fw-500 demand-detail-lable-left word-wrap pl-0">{item}</li>
            })}
          </ul>
          : "No"}
      </>
    )
  }

  return (
    <>
      {loginPermission?.includes(menuId?.viewDemand) || loginPermission?.includes(menuId?.viewDemandAgent)  ?
        <>
          <NavBar />
          {loading && <Loader className={"fixed"} />}
          {!loading && <>
            <div id="pdf-conversion-el">
              <div className="demand-detail-view">

                <div className="card-container p-2">

                  <DemandDetailCard
                    demand={demand}
                  />
                </div>
                <div className="card-container p-2 py-3 mt-3">
                  <label className="brand-text my-2">Demand Details</label>
                  <div>
                    <Row>

                      {requiredArr?.map((item, i) => {
                        if ((item.name == 'lockInPeriodRequired' || item.name == 'tenureRequired') && demand.transactionType == "Buy") return
                        if ((item.name == "fitOutsRequired" || item.name == "ownerShipRequired") && demand?.propertyType?.name !== "Office space" && demand.transactionType == "Buy") return
                        return (
                          <Col sm={12} md={6} className="column-data" key={i}>
                            <DemandDetailListCard
                              name={item.lable}
                              value={

                                (item.name === "otherInterestedLocationRequired" || item.name === "otherAmenitiesRequired") ? listingItems(item.fieldValue) :

                                  String(item.fieldValue) === "true" ? "Yes" : String(item.fieldValue) === "false" ? "No" : `${item.fieldValue?.toString() === undefined || item.fieldValue?.toString() === "" ? "-" : `${item.fieldValue?.toString()} ${item.subText}`} `}
                              sm={12}
                              md={6}
                            />
                          </Col>
                        )
                      })}
                    </Row>
                  </div>
                </div>

                {(demand?.propertyType?.name == "Flex Space" && formatArr.length > 0) ? (
                  <div className="card-container p-2 py-3 mt-3">
                    <label className="brand-text my-2">Formats</label>
                    <div>
                      <Row>
                        {formatArr?.map((item, i) => {
                          if (!item?.noOfSeat) return
                          return (
                            <>
                              <DemandFormatListCard
                                name={item?._id?.name}
                                value={item}
                                sm={12}
                                md={6}
                              />
                            </>
                          )
                        })}
                      </Row>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {nonRequiredArr.length > 0 ? <div className="card-container p-2 py-3 mt-3">
                  <label className="brand-text my-2">More Details</label>
                  <div>
                    <Row>
                      {nonRequiredArr?.map((item, i) => {
                        if ((item.name == 'lockInPeriodRequired' || item.name == 'tenureRequired' || item.name == 'fitOutsRequired' || item.name == 'ownerShipRequired' || item.name == "endUseRequired") && demand.transactionType == "Buy") return
                        if (item.name == "otherInterestedLocationRequired" && !demand?.otherInterestedLocation?.length > 0) return

                        if (!item.fieldValue) return
                        return (
                          <Col sm={12} md={6} className="column-data" key={i}>
                            <DemandDetailListCard
                              name={item.lable}
                              value={
                                (item.name === "otherInterestedLocationRequired" || item.name === "otherAmenitiesRequired") ? listingItems(item.fieldValue) :
                                  String(item.fieldValue) === "false" ? "No" : String(item.fieldValue) === "true" ? "Yes" : `${item.fieldValue?.toString() === undefined || item.fieldValue?.toString() === "" ? "-" : `${item.fieldValue?.toString()} ${item.subText}`} `}
                              sm={12}
                              md={6}
                            />
                          </Col>
                        )
                      })}
                    </Row>
                  </div>
                </div> : ""}
                <div className="demand-col ">
                  <Row>


                    {demand?.propertyType?.name !== "Flex Space" &&
                      <Col sm={12} md={6}>
                        <div className="card-container p-2 py-3 mt-3 ">
                          <label className="brand-text">Demand documents</label>
                          <Row className="p-2">

                            <Col sm={12} md={12} className="mt-2 p-1">
                              <DemandDetailListCard
                                name="FloorPlans required"
                                value={demand?.floorPlansRequired ? "Yes" : "No"}
                              />
                            </Col>
                            <Col sm={12} md={12} className="mt-2 p-1">
                              <DemandDetailListCard
                                name="AutoCad required"
                                value={demand?.autoCadRequired ? "Yes" : "No"}
                              />
                            </Col>
                            <Col sm={12} md={12} className="mt-2 p-1">
                              <DemandDetailListCard
                                name="FireNoc required"
                                value={demand?.fireNocRequired ? "Yes" : "No"}
                              />
                            </Col>
                            {/* {demand?.otherLicensesRequired ? <Col sm={12} md={12} className="mt-2 p-1">
                          <DemandDetailListCard
                            name="Other licenses"
                            value={demand?.otherLicenses}
                          />
                        </Col> : ""} */}
                            <Col sm={12} md={12} className="mt-2 p-1">
                              <DemandDetailListCard
                                name="Other licenses required"
                                value={
                                  demand?.otherLicensesRequired ? "Yes" : "No"
                                }
                              />
                            </Col>
                          </Row>{" "}
                        </div>
                      </Col>
                    }

                    {demand?.additionalDemandDetails?.length > 0 &&
                      <Col sm={12} md={demand?.propertyType?.name !== "Flex Space" ? 6 : 12} className="card-container p-2 py-3 mt-3 ">
                        <label className="brand-text">Additional Demand Details</label>
                        {demand?.additionalDemandDetails?.length > 0 ? <p className="p-2">{demand?.additionalDemandDetails}</p> : <div className="text-center">No data</div>}
                      </Col>
                    }
                  </Row>
                </div>


              </div>
            </div>
            <div className="mt-4 detailView-footer-container">
              <div className="footer-left">
                <p className="mb-0 brand-text" title={`Demand for ${demand?.propertyType?.name ? demand?.propertyType?.name == "Flex Space" ? demand?.propertyType?.name : demand?.propertyType?.name?.toLowerCase() : ""} ${demand?.propertyType?.name == "Flex Space" ? "" : " store"} in ${demand?.microLocation?.description}`}>
                  {`Demand for ${demand?.propertyType?.name ? demand?.propertyType?.name == "Flex Space" ? demand?.propertyType?.name : demand?.propertyType?.name?.toLowerCase() : ""} ${demand?.propertyType?.name == "Flex Space" ? "" : " store"} in ${demand?.microLocation?.description?.length > 20 ? `${demand?.microLocation?.description?.slice(0, 20)}...` : demand?.microLocation?.description}`}
                </p>
                <div className="companyName">
                  {" "}
                  {demand?.brandNameRequired
                    ? demand?.brandId
                      ? demand?.brandId?.companyName
                      : ""
                    : ""}
                </div>
              </div>
              <div className="footer-right">
                <Button
                  className="d-block btn-view"
                  loading={initLoading}
                  // disabled={
                  //   Object.keys(pdfImages)?.length > 0 ||
                  //     (viewDatas?.imagesAndVideos?.exterior?.length ===
                  //       0 &&
                  //       viewDatas?.imagesAndVideos?.frontSide?.length ===
                  //       0 &&
                  //       viewDatas?.imagesAndVideos?.interior?.length ===
                  //       0)
                  //     ? false
                  //     : true
                  // }
                  onClick={onClickDownloadPdf}
                >
                  Download as PDF
                </Button>
              </div>
            </div>
          </>}
        </>
        : <AccessDenied />}

    </>
  );
}