import { useEffect, useState } from "react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import MainTable from "../../../components/Table";
import Select from "../../../components/Form/Select/index";
import Search from "../../../components/Search/search";
import {
  AccessDenied,
  getAllSwitchUsers,
  getCurrentUser,
  getData,
  switchToUsers,
} from "../../../api";

import { Row, Col } from "react-bootstrap";
import switchuser from "../../../assets/images/switchuser.png";
import Loader from "../../../components/Loader";
import SwitchAccountPopup from "../../../components/switchModal/SwitchCompanyBuyerPopup";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { MULTIPLE_SWITCH, SWITCH_LABEL } from "../../../../src/custom/constants";

const ListSellerDBTable = ({ filterData, trigger }) => {
  const [offSet, setOffset] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [tableData, setTableData] = useState([]);

  const [propertyTypeList, setPropertyTypeList] = useState([]);
  const [propertyType, setPropertyType] = useState();
  const [transactionType, setTransactionType] = useState();
  const [sort, setSort] = useState();

  const loginPermission = useSelector((state) => state.login.userPermission);
  const menuId = useSelector((state) => state.login.menuId);

  let customerCreatedSortValues = [
    {
      name: "Newest",
      _id: "desc",
    },
    {
      name: "Oldest",
      _id: "asc",
    },
  ];

  const [search, setSearch] = useState("");

  // account switch modal
  const [switchModal, setSwitchModal] = useState(false);
  const [pocs, setPocs] = useState({});
  const [companyId, setCompanyId] = useState(null);

  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  let sellerSwitch = loginPermission?.includes(menuId?.enterpriseSellerSwitch)

  const handleAccountSwitch = async (id, propertyId, REDIRECT_URL=process.env.REACT_APP_SWITCH_URL) => {
    const data = {
      customerId: id,
      type: "user",
    };
    switchToUsers(data).then((res) => {
      let openUrl = REDIRECT_URL;
      if (res.status) {
        window.open(
          `${openUrl}/${res?.data?.token}?trd=/profile/manage-property/${propertyId}`
        );
      } else {
        Swal.fire({
          // title: header,
          text: "No Poc Found",
          icon: "error",
          timer: 3000,
          buttons: false,
          showCancelButton: false,
          showConfirmButton: false,
          showCloseButton: true,
        });
      }
    });
  };

  const columnsBrand = [
    {
      dataField: "publishedDate",
      text: "Timestamp",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div>
            {new Date(row.updatedAt).toLocaleString(undefined, {
              timeZone: "Asia/Kolkata",
            })}
          </div>
        );
      },
    },
    {
      text: "Developer Name",
      dataField: "developerName",
    },
    {
      text: "POC Name",
      dataField: "pocName",
      formatter: (cellContent, row, rowIndex) => {
        return <div>{cellContent ? cellContent : "-"}</div>;
      },
    },
    {
      text: "Property ID",
      dataField: "productId",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div
            className="hyperlinkText"
            onClick={(e) => {
              navigate("propertyDetailView/" + row?.productId);
            }}
          >
            {row?.productId}
          </div>
        );
      },
    },
    {
      text: "Transaction Type",
      dataField: "transactionType",
    },
    {
      text: "Property Type",
      dataField: "propertyType",
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div className="db-number-card-container">
            <>
            <div class="overflow-hidden">
              <img
                style={{ cursor: `${sellerSwitch ? "pointer" : ""}`, opacity: `${sellerSwitch ? 1 : 0.5}`, width: "20px", height: "20px" }}
                onClick={(e) => {
                  e.preventDefault();
                  if(sellerSwitch) {
                    handleAccountSwitch(row?.userId, row?.productId);
                  }
                }}
                alt="switch"
                src={switchuser}
              />  
              {MULTIPLE_SWITCH &&
                <div style={{padding : 2}}>
                    <p class="text-gray-700 mb-0" style={{fontSize: '10px'}}>{SWITCH_LABEL.SWITCH1}</p>
                </div>}
            </div>

            {MULTIPLE_SWITCH &&
              <div class="overflow-hidden">
                <img
                  style={{ cursor: `${sellerSwitch ? "pointer" : ""}`, opacity: `${sellerSwitch ? 1 : 0.5}`, width: "20px", height: "20px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    if(sellerSwitch) {
                      handleAccountSwitch(row?.userId, row?.productId, process.env.REACT_APP_REVAMP_SWITCH_URL);
                    }
                  }}
                  alt="switch"
                  src={switchuser}
                />  
                <div style={{padding : 2}}>
                  <p class="text-gray-700 mb-0" style={{fontSize: '10px'}}>{SWITCH_LABEL.SWITCH2}</p>
                </div>
              </div>} 
            </>           
          </div>
        );
      },
    }
  ];

  if (getCurrentUser()?.role[0]?.name === "admin") {
    const rmData = [
      {
        text: "Relationship Manager",
        dataField: "rmName",
        formatter: (cellContent, row, rowIndex) => {
          return <div>{cellContent ? cellContent : "-"}</div>;
        },
      },
    ];
    columnsBrand.splice(6, 0, ...rmData);
  }

  // if (loginPermission?.includes(menuId?.enterpriseSellerSwitch)) {
  //   const actionColumns = {
  //     dataField: "action",
  //     text: "Action",
  //     formatter: (cellContent, row, rowIndex) => {
  //       return (
  //         <div className="db-number-card-container">
  //           <img
  //             style={{ cursor: "pointer", width: "20px", height: "20px" }}
  //             onClick={(e) => {
  //               e.preventDefault();
  //               handleAccountSwitch(row?.userId, row?.productId);
  //             }}
  //             alt="switch"
  //             src={switchuser}
  //           />
  //         </div>
  //       );
  //     },
  //   };
  //   columnsBrand.push(actionColumns);
  // }
  const getTableData = async (loadStatus) => {
    if (loadStatus) {
      setLoading(true);
    }

    // let url = `/dashboard/getCustomers?type=Brand&page=${offSet}&limit=${perPage}`;
    let url = `/property/viewProperties?type=Developer&pageNo=${offSet}&limit=${perPage}`;
    if (search.length > 2) {
      url += `&search=${search}`;
    }

    if (transactionType) {
      url += `&transactionType=${transactionType}`;
    }

    if (propertyType) {
      url += `&propertyType=${propertyType}`;
    }
    if (sort) {
      url += `&sort=${sort}`;
    }

    let cusomerData = await getData(url);

    const { statusCode, data } = cusomerData;
    if (statusCode === 200) {
      setPageCount(Math.ceil(data.total / perPage));
      setTotalCount(data.total);
      setTableData(data.propertyList);
      setLoading(false);
    } else {
      setPageCount(0);
      setTableData([]);
      setLoading(false);
    }
  };

  const handelChangePerPage = (count) => {
    setPerPage(count);
    setOffset(1);
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
    setOffset(1);
  };

  const onChangeFilter = (e) => {
    let filterField = e.target.name;
    if (filterField === "Posted") setSort(e.target.value);
    if (filterField === "PropertyType") setPropertyType(e.target.value);
    if (filterField === "Transaction") setTransactionType(e.target.value);
    setOffset(1);
  };

  const tableProps = {
    data: tableData,
    columns: columnsBrand,
    offset: offSet,
    setOffset: setOffset,
    handelChangePerPage,
    pageCount,
    perPage,
    totalCount,
    tableName: "RmDashboard",
  };

  useEffect(() => {
    if (search === "" || search.length > 2) getTableData(true);
  }, [perPage, offSet, search, propertyType, transactionType, sort]);

  // useEffect(() => {
  //   getTableData(false);
  // }, [trigger]);

  useEffect(() => {
    // getBrandList();
    getPropertyType();
  }, []);

  let customerTransactionTypeSortValues = [
    {
      _id: "Lease",
      name: "Lease",
    },
    {
      _id: "Revenue Share",
      name: "Revenue Share",
    },
    {
      _id: "MG Revenue Share",
      name: "MG Revenue Share",
    },
    {
      _id: "Sales",
      name: "Sales",
    },
  ];

  const getPropertyType = () => {
    let url = "/propertyType";
    getData(url, {}, {}).then((response) => {
      if (response?.status) {
        let propertyArray = [];
        response?.data?.map((data) => {
          propertyArray.push({
            name: data?.name,
            _id: data?._id,
          });
          return data;
        });
        setPropertyTypeList(propertyArray);
      }
    });
  };

  if (loginPermission?.includes(menuId?.enterpriseSellerDashboard)) {
    return (
      <>
        {loading && <Loader className={"fixed"} />}
        <div className="db-filter-container">
          <Row className=" filter-select">
            <>
              <Col md={4} sm={5}>
                <Select
                  placeholder="Posted On"
                  options={customerCreatedSortValues}
                  name={"Posted"}
                  value={sort}
                  onChange={onChangeFilter}
                  // isHideSelect
                />
              </Col>
              <Col md={4} sm={5}>
                <Select
                  placeholder="Property Type"
                  options={propertyTypeList}
                  name={"PropertyType"}
                  value={propertyType}
                  onChange={onChangeFilter}
                  // isHideSelect
                />
              </Col>
              <Col md={4} sm={5}>
                <Select
                  placeholder="Transaction Type"
                  options={customerTransactionTypeSortValues}
                  name={"Transaction"}
                  value={transactionType}
                  onChange={onChangeFilter}
                  // isHideSelect
                />
              </Col>
            </>
          </Row>
          <Row className=" filter-search-inbox">
            <Col md={6} sm={7} className="d-flex align-items-start">
              <Search onChange={handleSearch} value={search} />
            </Col>
          </Row>
        </div>
        <MainTable {...tableProps} trigger={trigger} />
        <SwitchAccountPopup
          show={switchModal}
          onClose={() => setSwitchModal(false)}
          data={pocs}
          companyId={companyId}
        />
      </>
    );
  } else{
    return (
      <AccessDenied />
    )
  }

  
};
export default ListSellerDBTable;
