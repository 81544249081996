import React from "react";

export function DraggableOverlay({ item }) {
  return (
    <div className="table-border-radius">
      <div className="w-full">
        <table className="table w-full" style={{ width: "100% !important" }}>
          <tbody className="tableBody ">
            <tr style={{ backgroundColor: "white", width: "100%" }}>
              {Object.keys(item).map((key, index) => (
                <td
                  key={index}
                  style={{ padding: "1.1rem 1rem !important" }}
                >
                  {item[key]}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
