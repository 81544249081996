import { useEffect, useState } from "react";
import ListBuyerDBTable from "./listBuyerTable";
import ListResponseTable from "./listResponseTable";
// import SellerDBTable from "./sellerTable";
// import AgentDBTable from "./agentTable";
import NavBar from "../../../components/Navbar";
import { useLocation, useParams } from "react-router-dom";
import { camelCaseConversion } from "../../../custom/camelCaseConversion";
import { socket } from "../../chat/socket";

// style
import "../style.scss";
import ListSellerDBTable from "./listSellerTable";
import ListAgentDBTable from "./listAgentTable";
import ListSellersRelevancy from "./listBrandRelevancyTable";
import ListSellersInbox from "./listSellersInboxTable";
import ListResponsePropertyAgent from "./listResponsePropertyAgentTable";
import ListBuyersDemandTable from "./listBuyersDemandAgentTable";
import ListInboxAgentTable from "./listInboxAgent";
import ListResponseDemandAgentTable from "./listResponseDemandAgentTable";
import ListRelevancyDemandAgentTable from "./listAgentRelevancyDemandTable";
import ListDeveloperResponseTable from "./listDeveloperResponseTable";
import ListDeveloperRelevancyTable from "./listDeveloperRelevancyTable";
import ListAgentRelevancySupplyView from "./listAgentRelevancyViewTable";
import { useSelector } from "react-redux";
import ListBuyersDemandAgentTable from "./listBuyersDemandAgentTable";
import ListEAgentDBTable from "./EAgentTables/listEagentTable";
import ListDemandEAgentTable from "./EAgentTables/listEAgentDemandTable";
import ListResponsePropertyEAgent from "./EAgentTables/listResponsePropertyEAgentTable";
import ListEAgentRelevancySupplyView from "./EAgentTables/listEAgentRelevancyViewTable";
import ListResponseDemandEAgentTable from "./EAgentTables/listResponseDemandEAgentTable";
import ListRelevancyDemandEAgentTable from "./EAgentTables/listEAgentRelevancyDemandTable";
import { PHYSICALTEAM, PHYSICAL_TEAM } from "../../../custom/constants";
import ListPhysicalTeamDBTable from "./PhysicalTeamTables/listPhysicalTeamTable";
import ListDemandPhysicalTeamTable from "./PhysicalTeamTables/listPhysicalTeamDemandTable";
import ListResponsePropertyPhysicalTeam from "./PhysicalTeamTables/listResponsePropertyPhysicalTeamTable";
import ListPhysicalTeamRelevancySupplyView from "./PhysicalTeamTables/listPhysicalTeamRelevancyViewTable";
import ListRelevancyDemandPhysicalTeamTable from "./PhysicalTeamTables/listPhysicalTeamRelevancyDemandTable";
import ListResponseDemandPhysicalTeamTable from "./PhysicalTeamTables/listResponseDemandPhysicalTeamTable";
import ProjectsTable from "./ProjectsTable";
import ProjectsLeads from "./ProjectsLeads";

// context
// export const DashboardTableContext = createContext();

const ListDashboardTable = ({ }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { type, customerType } = useParams();
  console.log({ type });
  console.log({ customerType });
  const [connected, setConnected] = useState(false);
  const [trigger, setTrigger] = useState(false);

  const loginPermission = useSelector((state) => state.login.userPermission);
  const menuId = useSelector((state) => state.login.menuId);
  const setTriggerData = () => {
    setTrigger((prev) => !prev);
  };

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("keydemand_user"));
    const eventHandler = () => setConnected(true);
    const eventId = user?._id;
    socket.on(eventId, eventHandler);
    socket.on(eventId, (newMessage) => {
      if (type === "enterpriseBuyers") {
        if (
          (newMessage?.propertyCount && String(newMessage?.propertyCount)) ||
          (newMessage?.newProperty && String(newMessage?.newProperty))
        ) {
          setTriggerData();
        }
      } else {
        if (
          (newMessage?.responseCount && String(newMessage?.responseCount)) ||
          (newMessage?.newDemand && String(newMessage?.newDemand))
        ) {
          setTriggerData();
        }
      }
    });
    // unsubscribe from event for preventing memory leaks
    return () => {
      socket.off(eventId, eventHandler);
    };
  }, []);

  const renderTable = (tableType, customerType) => {
    console.log({ customerType });
    console.log({ tableType });
    if (customerType === "enterpriseBuyer") {
      switch (tableType) {
        case "demands":
          return (
            <ListBuyerDBTable
              filterData={searchParams.get("filter")}
              trigger={trigger}
            />
          );
        case "propertiesReceivedAll":
        case "propertiesReceivedNew":
          return (
            <ListResponseTable
              filterData={searchParams.get("filter")}
              trigger={trigger}
              tableType={tableType}
            />
          );
        case "relevantPropertiesAll":
        case "relevantPropertiesNew":
          return (
            <ListSellersRelevancy
              filterData={searchParams.get("filter")}
              trigger={trigger}
              tableType={tableType}
            />
          );
        case "unreadInboxMessages":
          return (
            <ListSellersInbox
              filterData={searchParams.get("filter")}
              trigger={trigger}
              type={"Enterprise Buyer"}
              isPermission={loginPermission?.includes(
                menuId?.enterpriseBuyerDashboard
              )}
            />
          );
      }
    } else if (customerType === "enterpriseSeller") {
      switch (tableType) {
        case "properties":
          return (
            <ListSellerDBTable
              filterData={searchParams.get("filter")}
              trigger={trigger}
            />
          );
        case "interestReceivedAll":
        case "interestReceivedNew":
          return (
            <ListDeveloperResponseTable
              filterData={searchParams.get("filter")}
              trigger={trigger}
              tableType={tableType}
            />
          );
        case "relevantDemandsAll":
        case "relevantDemandsNew":
          return (
            <ListDeveloperRelevancyTable
              filterData={searchParams.get("filter")}
              trigger={trigger}
              tableType={tableType}
            />
          );
        case "unreadInboxMessages":
          return (
            <ListSellersInbox
              filterData={searchParams.get("filter")}
              trigger={trigger}
              type={"Enterprise Seller"}
              isPermission={loginPermission?.includes(
                menuId?.enterpriseSellerDashboard
              )}
            />
          );
        case "Projects":
          return (
            <ProjectsTable />
          );
        case "projectLeads":
          return (
            <ProjectsLeads />
          );
      }
    } else if (customerType === "agent") {
      switch (tableType) {
        case "properties":
          return (
            <ListAgentDBTable
              filterData={searchParams.get("filter")}
              trigger={trigger}
            />
          );
        case "interestReceivedAll":
        case "interestReceivedNew":
          return (
            <ListResponsePropertyAgent
              filterData={searchParams.get("filter")}
              trigger={trigger}
              tableType={tableType}
            />
          );
        case "relevantDemandsAll":
        case "relevantDemandsNew":
          return (
            <ListAgentRelevancySupplyView
              filterData={searchParams.get("filter")}
              trigger={trigger}
              tableType={tableType}
            />
          );
        case "unreadInboxMessages":
          return (
            <ListSellersInbox
              filterData={searchParams.get("filter")}
              trigger={trigger}
              type={"agent"}
              isPermission={loginPermission?.includes(menuId?.agentDashboard)}
            />
          );
        case "relevantPropertiesAll":
        case "relevantPropertiesNew":
          return (
            <ListRelevancyDemandAgentTable
              filterData={searchParams.get("filter")}
              trigger={trigger}
              tableType={tableType}
            />
          );
        case "propertiesReceivedAll":
        case "propertiesReceivedNew":
          return (
            <ListResponseDemandAgentTable
              filterData={searchParams.get("filter")}
              trigger={trigger}
              tableType={tableType}
            />
          );
        // case "enterpriseRelevancyDemandAgentAll":
        // case "enterpriseRelevancyDemandAgentNew":
        //   return (
        //     <ListRelevancyDemandAgentTable
        //       filterData={searchParams.get("filter")}
        //       trigger={trigger}
        //       tableType={tableType}
        //     />
        //   );
        case "demands":
          return (
            <ListBuyersDemandAgentTable
              filterData={searchParams.get("filter")}
              trigger={trigger}
            />
          );
        //   case "enterpriseBuyersDemandAgent": → already verify and remove
        //     return <ListBuyersDemandTable filterData={searchParams.get("filter")} trigger={trigger}/>;
      }
    } else if (customerType === "enterpriseAgent") {
      switch (tableType) {
        case "properties":
          return (
            <ListEAgentDBTable
              filterData={searchParams.get("filter")}
              trigger={trigger}
            />
          );
        case "interestReceivedAll":
        case "interestReceivedNew":
          return (
            <ListResponsePropertyEAgent
              filterData={searchParams.get("filter")}
              trigger={trigger}
              tableType={tableType}
            />
          );
        case "relevantDemandsAll":
        case "relevantDemandsNew":
          return (
            <ListEAgentRelevancySupplyView
              filterData={searchParams.get("filter")}
              trigger={trigger}
              tableType={tableType}
            />
          );
        case "unreadInboxMessages":
          return (
            <ListSellersInbox
              filterData={searchParams.get("filter")}
              trigger={trigger}
              type={"Enterprise Agent"}
              isPermission={loginPermission?.includes(menuId?.agentDashboard)}
            />
          );
        case "propertiesReceivedAll":
        case "propertiesReceivedNew":
          return (
            <ListResponseDemandEAgentTable
              filterData={searchParams.get("filter")}
              trigger={trigger}
              tableType={tableType}
            />
          );
        case "relevantPropertiesAll":
        case "relevantPropertiesNew":
          return (
            <ListRelevancyDemandEAgentTable
              filterData={searchParams.get("filter")}
              trigger={trigger}
              tableType={tableType}
            />
          );
        // case "enterpriseRelevancyDemandAgentAll":
        // case "enterpriseRelevancyDemandAgentNew":
        //   return (
        //     <ListRelevancyDemandAgentTable
        //       filterData={searchParams.get("filter")}
        //       trigger={trigger}
        //       tableType={tableType}
        //     />
        //   );
        case "demands":
          return (
            <ListDemandEAgentTable
              filterData={searchParams.get("filter")}
              trigger={trigger}
            />
          );
        //   case "enterpriseBuyersDemandAgent": → already verify and remove
        //     return <ListBuyersDemandTable filterData={searchParams.get("filter")} trigger={trigger}/>;
      }
    } else if (customerType === PHYSICALTEAM) {
      switch (tableType) {
        case "properties":
          return (
            <ListPhysicalTeamDBTable
              filterData={searchParams.get("filter")}
              trigger={trigger}
            />
          );
        case "interestReceivedAll":
        case "interestReceivedNew":
          return (
            <ListResponsePropertyPhysicalTeam
              filterData={searchParams.get("filter")}
              trigger={trigger}
              tableType={tableType}
            />
          );
        case "relevantDemandsAll":
        case "relevantDemandsNew":
          return (
            <ListPhysicalTeamRelevancySupplyView
              filterData={searchParams.get("filter")}
              trigger={trigger}
              tableType={tableType}
            />
          );
        case "unreadInboxMessages":
          return (
            <ListSellersInbox
              filterData={searchParams.get("filter")}
              trigger={trigger}
              type={PHYSICALTEAM}
              isPermission={loginPermission?.includes(menuId?.agentDashboard)}
            />
          );
        case "propertiesReceivedAll":
        case "propertiesReceivedNew":
          return (
            <ListResponseDemandPhysicalTeamTable
              filterData={searchParams.get("filter")}
              trigger={trigger}
              tableType={tableType}
            />
          );
        case "relevantPropertiesAll":
        case "relevantPropertiesNew":
          return (
            <ListRelevancyDemandPhysicalTeamTable
              filterData={searchParams.get("filter")}
              trigger={trigger}
              tableType={tableType}
            />
          );
        // case "enterpriseRelevancyDemandAgentAll":
        // case "enterpriseRelevancyDemandAgentNew":
        //   return (
        //     <ListRelevancyDemandAgentTable
        //       filterData={searchParams.get("filter")}
        //       trigger={trigger}
        //       tableType={tableType}
        //     />
        //   );
        case "demands":
          return (
            <ListDemandPhysicalTeamTable
              filterData={searchParams.get("filter")}
              trigger={trigger}
            />
          );
          case "Projects":
          return (
            <ProjectsTable />
          );
        case "projectLeads":
          return (
            <ProjectsLeads />
          );
        //   case "enterpriseBuyersDemandAgent": → already verify and remove
        //     return <ListBuyersDemandTable filterData={searchParams.get("filter")} trigger={trigger}/>;
      }
    }
  };
  return (
    <div className="db-table-container">
      <NavBar />
      <div>{renderTable(type, customerType)}</div>
    </div>
  );
};
export default ListDashboardTable;
