import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import { FaArrowCircleRight } from "react-icons/fa";
import { FaArrowCircleLeft } from "react-icons/fa";
import { IoMdDownload } from 'react-icons/io';
function FeatureDetailCard({ data }) {
    return (
        <div>
            <div className="w-full d-flex justify-content-between align-items-center ">
                <div className="d-flex align-items-center  py-3">
                    {/* <img
                        src={`${process.env.NEXT_PUBLIC_IMAGE_URL}/icons/pdf-icon.svg`}
                        alt="Pdf Icon"
                        className="w-4 h-4 md:w-5 md:h-5"
                    /> */}
                    <h2 className=" fs-4 " style={{ color: '#0B262A' }}>
                        Project Listing
                    </h2>
                </div>
                { <div className="d-flex align-items-center gap-3">
                    <div className="d-flex  gap-2 ">
                        {/* <img
                            src={`${process?.env?.NEXT_PUBLIC_IMAGE_URL}/icons/arrows/leftarrow.png`}
                            alt="Left Arrow"
                            className="w-[25px] h-[25px] md:w-[35px] md:h-[35px]"
                        /> */}
                        <FaArrowCircleLeft className="arrow-left-2" style={{ color: '#08536B', fontSize: "2rem", cursor: "pointer" }} />
                        <FaArrowCircleRight className="arrow-right-1" style={{ color: '#08536B', fontSize: "2rem", cursor: "pointer" }} />
                        {/* <img
                            src={`${process?.env?.NEXT_PUBLIC_IMAGE_URL}/icons/arrows/rightarrow.png`}
                            alt="Right Arrow"
                            className="w-[25px] h-[25px] md:w-[35px] md:h-[35px]"
                        /> */}
                    </div>
                    {/* <div className="px-4 py-2 fs-6" style={{ background: "#D9E5E6", color: '#08536B', cursor: "pointer" }} onClick={() => { }}>
                        View All
                    </div> */}
                </div>}
            </div>
            <div className="relative flex justify-center  items-center w-full h-full">
                <Swiper
                    slidesPerView={3}
                    spaceBetween={10}
                    loop={true}
                    modules={[Navigation]}
                    navigation={{ nextEl: ".arrow-right-1", prevEl: ".arrow-left-2" }}
                    breakpoints={{
                        499: { slidesPerView: 1, spaceBetween: 6 },
                        650: { slidesPerView: 1, spaceBetween: 6 },
                        999: { slidesPerView: 2, spaceBetween: 6 },
                    }}
                    className="w-100 z-0 h-[200px]"
                // style={{width:"400px",height:'200px'}}
                >
                    {data?.map((value, index) => (
                        <SwiperSlide key={index} style={{ height: '230px' }}>
                            <div className='flex flex-col  '>
                                <div className='bg-[#F4F6F5] border w-full p-3 mb-2' style={{ borderColor: '#D9DCD2' }}>
                                    <div className="d-flex gap-2 align-items-center">
                                        <div className=" font-Poppins fs-5 ">
                                            Property ID :
                                        </div>
                                        <span className="fw-bold fs-6" style={{ color: '#0B262A', }}>
                                            {value?.displayId}
                                        </span>
                                    </div>
                                    <div className="row mt-3 ">
                                        <div className="col-md-6 border-end border-gray-300 pe-3">
                                            {value?.propertyUnit === "individual" && (
                                                <>
                                                    <div className="d-flex justify-content-between mb-2">
                                                        <h4 className=" fs-6 font-Poppins" style={{ color: '#406065' }}>
                                                            Built-Up Area
                                                        </h4>
                                                        <h4 className=" fs-6 font-Poppins" style={{ color: '#406065' }}>
                                                            {value?.builtupArea} ft
                                                        </h4>
                                                    </div>
                                                    <div className="d-flex justify-content-between mb-2">
                                                        <h4 className=" fs-6 font-Poppins" style={{ color: '#406065' }}>
                                                            Carpet Area
                                                        </h4>
                                                        <h4 className=" fs-6 font-Poppins" style={{ color: '#406065' }}>
                                                            {value?.carpetAreaMax} ft
                                                        </h4>
                                                    </div>
                                                </>
                                            )}
                                            {value?.propertyUnit === "multiple" && (
                                                <>
                                                    <div className="d-flex justify-content-between mb-2">
                                                        <h4 className=" fs-6 font-Poppins" style={{ color: '#406065' }}>
                                                            No Of Unit
                                                        </h4>
                                                        <h4 className=" fs-6 font-Poppins" style={{ color: '#406065' }}>
                                                            {value?.noOfUnits} ft
                                                        </h4>
                                                    </div>
                                                    <div className="d-flex justify-content-between mb-2">
                                                        <h4 className=" fs-6 font-Poppins" style={{ color: '#406065' }}>
                                                            Carpet Area
                                                        </h4>
                                                        <h4 className=" fs-6 font-Poppins" style={{ color: '#406065' }}>
                                                            {value?.carpetAreaMin} - {value?.carpetAreaMax} ft
                                                        </h4>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                        <div className="col-md-6 ps-3">
                                            <div className="d-flex justify-content-between mb-2">
                                                <h4 className=" fs-6 font-Poppins" style={{ color: '#406065' }}>
                                                    Floor
                                                </h4>
                                                <h4 className=" fs-6 font-Poppins" style={{ color: '#406065' }}>
                                                    {value?.floors} ft
                                                </h4>
                                            </div>
                                            <div className="d-flex justify-content-between mb-2">
                                                <h4 className=" fs-6 font-Poppins" style={{ color: '#406065' }}>
                                                    Rent on Carpet Area
                                                </h4>
                                                <h4 className=" fs-6 font-Poppins" style={{ color: '#406065' }}>
                                                    {value?.rentPsf} ft
                                                </h4>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                {/* <div className="text-center">
                                    <div className="fs-5  cursor-pointer p-2" style={{ background: '#D9E5E6', color: '#08536B' }}>
                                        View Property
                                    </div>
                                </div> */}

                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className="absolute inset-0 bg-white/20"></div>
            </div>
            {/* <div className='d-flex justify-content-end '>
                <div className='d-flex gap-2 p-2 rounded ' style={{ background: '#D9E5E6', cursor: 'pointer' }}>
                    <IoMdDownload className='' style={{ fontSize: '1.8rem', color: '#08536B' }} />
                    <div className='fs-5  ' >Download</div>
                </div>
            </div> */}
        </div>
    );
}

export default FeatureDetailCard;
