import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../../App";
import { getData, postData } from "../../../../api";
import { PropertyCard } from "../../../../components/Cards/PropertyCard/propertyCard";
import Button from "../../../../components/Form/Button";
import MultiSelect from "../../../../components/select/dropDown";
import {
  getContextFilterData,
  performScrollEvent,
} from "../../../../utilities/pageScroll";
import PropertyDemandTab from "../../../../components/PropsertyDemandTabs";
import ReactDatePicker from "react-datepicker";
import { FaCalendar } from "react-icons/fa";
import {
  checkForUserSubPlan,
  filteredTransactionOptions,
  labelValueOfObjects,
  nameToLabel,
} from "../../../../utilities/commonFunction";
import Loader from "../../../../components/Loader";
import { DemandCard } from "../../../../components/Cards/Demands";
import {
  PropertyTrancationOptions,
  brandTrancationOptions,
} from "../../../../utilities/transactionOptions";
import {
  ENTERPRISEAGENT,
  PHYSICALTEAM,
  PROPVANTAGE,
} from "../../../../custom/constants";
const FeaturedProperty = (props) => {
  const navigate = useNavigate();
  const { pageScroll, setPageScroll } = useContext(AppContext);
  const scrollStoreArray = [...pageScroll];
  let contextFilterData = getContextFilterData(
    "myPageProperty",
    scrollStoreArray
  );
  let companyID = useSelector((state) => state.login.companyId);
  const [apiCardDetail, setApiCardDetail] = useState([]);
  const cardDetail = [
    "propertyTypeId",
    "carpetArea",
    "endUse",
    "possession",
    "rentPsf",
    "tenure",
  ];
  const [startDate, setStartDate] = useState("");
  const [cityFilterKeyValue, setCityFilterKeyValue] = useState(
    contextFilterData ?? { label: "City", value: "City" }
  );
  const [sortFilterKeyValue, setSortFilterKeyValue] = useState({
    label: "Sort",
    value: "sort",
  });
  const [transactionFilterKeyValue, setTransactionFilterKeyValue] = useState({
    label: "Transaction Type",
    value: "Transaction Type",
  });

  const [propertyTypeFilterKeyValue, setPropertyTypeFilterKeyValue] = useState({
    label: "Property Type",
    value: "Property Type",
  });
  const [showCheckBox, setShowCheckBox] = useState(false);
  const [propertyId, setPropertyId] = useState([]);
  const [filterMessage, setFilterMessage] = useState("");
  const [activeTab, setActiveTab] = useState("property");
  const fromPage = useSelector((state) => state.login.parentPage);
  const [loading, setLoading] = useState(false);
  const [demandCardDetails, setDemandCardDetails] = useState([]);
  const [showCheckBoxForDemands, setShowCheckBoxForDemands] = useState(false);
  const [demandId, setDemandId] = useState([]);
  const [transactionTypeOptions, setTransactionTypeOptions] = useState([]);

  const getFilteredTransactionTypeProerty = () => {
    var filteredTransactionType = [];
    if (propertyTypeFilterKeyValue?.label === "Retail") {
      filteredTransactionType = filteredTransactionOptions(
        [...PropertyTrancationOptions?.retail],
        props?.usersTransactionType
      );
    } else if (propertyTypeFilterKeyValue?.label === "Office space") {
      filteredTransactionType = filteredTransactionOptions(
        [...PropertyTrancationOptions?.office],
        props?.usersTransactionType
      );
    } else if (propertyTypeFilterKeyValue?.label === "Flex Space") {
      filteredTransactionType = filteredTransactionOptions(
        [...PropertyTrancationOptions?.cowork],
        props?.usersTransactionType
      );
    } else {
      filteredTransactionType = filteredTransactionOptions(
        [...PropertyTrancationOptions?.retail],
        props?.usersTransactionType
      );
    }

    return filteredTransactionType;
  };

  const getFilteredTransactionTypeDemand = () => {
    var filteredTransactionType = [];
    if (propertyTypeFilterKeyValue?.label === "Retail") {
      filteredTransactionType = filteredTransactionOptions(
        [...brandTrancationOptions?.retail],
        props?.usersTransactionType
      );
    } else if (propertyTypeFilterKeyValue?.label === "Office space") {
      filteredTransactionType = filteredTransactionOptions(
        [...brandTrancationOptions?.office],
        props?.usersTransactionType
      );
    } else if (propertyTypeFilterKeyValue?.label === "Flex Space") {
      filteredTransactionType = filteredTransactionOptions(
        [...brandTrancationOptions?.cowork],
        props?.usersTransactionType
      );
    } else {
      filteredTransactionType = filteredTransactionOptions(
        [...brandTrancationOptions?.retail],
        props?.usersTransactionType
      );
    }

    return filteredTransactionType;
  };

  useEffect(() => {
    if (activeTab === "property") {
      setTransactionTypeOptions(getFilteredTransactionTypeProerty());
    } else {
      setTransactionTypeOptions(getFilteredTransactionTypeDemand());
    }
  }, [propertyTypeFilterKeyValue, activeTab, props?.usersTransactionType]);

  useEffect(() => {
    if (transactionTypeOptions.length > 0) {
      const thisisLabel = labelValueOfObjects(transactionTypeOptions);
      const isObjectInArray = thisisLabel.some(
        (item) =>
          item.label === transactionFilterKeyValue.label &&
          item.value === transactionFilterKeyValue.value
      );

      if (!isObjectInArray) {
        setTransactionFilterKeyValue({
          label: "Transaction Type",
          value: "Transaction Type",
        });
      }
    }
  }, [transactionTypeOptions]);

  const getPropertyData = async () => {
    setLoading(true);
    setFilterMessage("");
    let url = `/property?pageNo=1&customerId=${props.id}&limit=0&featuredProperty=true`;
    if (
      cityFilterKeyValue?.label !== "City" &&
      cityFilterKeyValue?.label !== ""
    ) {
      url += `&location=${cityFilterKeyValue?.label}`;
    }
    if (propertyTypeFilterKeyValue.value !== "Property Type") {
      url += `&propertyType=${propertyTypeFilterKeyValue?.value}`;
    }
    if (transactionFilterKeyValue.value !== "Transaction Type") {
      url += `&transactionType=${transactionFilterKeyValue?.value}`;
    }
    if (sortFilterKeyValue.value !== "sort") {
      url += `&sort=${sortFilterKeyValue?.value}`;
    }
    await getData(url, {}, {}).then((response) => {
      if (response.status) {
        setLoading(false);
        setApiCardDetail(response.data.properties);
        if (
          cityFilterKeyValue?.label !== "City" &&
          cityFilterKeyValue?.label !== ""
        ) {
          if (response?.data?.properties?.length === 0) {
            setFilterMessage("No results found");
          }
        }
      } else {
        setLoading(false);
      }
    });
  };
  const getDemandData = async () => {
    setFilterMessage("");
    let url = `/profile/mydemandsForAgent?pageNo=0&customerId=${props.demandAgentId}&limit=0&featuredDemand=true`;
    if (
      cityFilterKeyValue?.label !== "City" &&
      cityFilterKeyValue?.label !== ""
    ) {
      url += `&city=${cityFilterKeyValue?.label}`;
    }
    if (propertyTypeFilterKeyValue.value !== "Property Type") {
      url += `&propertyType=${propertyTypeFilterKeyValue?.value}`;
    }
    if (transactionFilterKeyValue.value !== "Transaction Type") {
      url += `&transactionType=${transactionFilterKeyValue?.value}`;
    }
    if (sortFilterKeyValue.value !== "sort") {
      url += `&sort=${sortFilterKeyValue?.value}`;
    }
    await getData(url, {}, {}).then((response) => {
      if (response.statusCode === 200 || response.status) {
        setDemandCardDetails(response.data.myDemands);
        if (
          cityFilterKeyValue?.label !== "City" &&
          cityFilterKeyValue?.label !== ""
        ) {
          if (response?.data?.properties?.length === 0) {
            setFilterMessage("No results found");
          }
        }
      }
    });
  };

  const getEAgentDemandData = async () => {
    setFilterMessage("");
    let url = `/demand/getDemands?pageNo=0&customerId=${
      props?.fromTab === ENTERPRISEAGENT ? props?.id : props.demandAgentId
    }&limit=0&featuredDemand=true`;
    if (
      cityFilterKeyValue?.label !== "City" &&
      cityFilterKeyValue?.label !== ""
    ) {
      url += `&city=${cityFilterKeyValue?.label}`;
    }
    if (propertyTypeFilterKeyValue.value !== "Property Type") {
      url += `&propertyType=${propertyTypeFilterKeyValue?.value}`;
    }
    if (transactionFilterKeyValue.value !== "Transaction Type") {
      url += `&transactionType=${transactionFilterKeyValue?.value}`;
    }
    if (sortFilterKeyValue.value !== "sort") {
      url += `&sort=${sortFilterKeyValue?.value}`;
    }
    await getData(url, {}, {}).then((response) => {
      if (response.statusCode === 200 || response.status) {
        setDemandCardDetails(response.data.demands);
        if (
          cityFilterKeyValue?.label !== "City" &&
          cityFilterKeyValue?.label !== ""
        ) {
          if (response?.data?.properties?.length === 0) {
            setFilterMessage("No results found");
          }
        }
      }
    });
  };

  const onchangeCityFilterKey = (val) => {
    setCityFilterKeyValue(val);
  };
  const onchangeSortFilterKey = (val) => {
    setSortFilterKeyValue(val);
  };

  const onchangeTransactionFilterKey = (val) => {
    setTransactionFilterKeyValue(val);
  };

  const onchangePropertyTypeFilterKey = (val) => {
    setPropertyTypeFilterKeyValue(val);
  };
  const onclickViewProperty = (a) => {
    props.setScrollEvent(a?._id, cityFilterKeyValue);
    if (props?.fromTab === "agent") {
      localStorage.setItem("propertyValues", JSON.stringify(a));
      let url =
        fromPage === "agent"
          ? "/agentReport/agentList/agentPage/propertyDetailView/"
          : "/customer/agentPage/propertyDetailView/";
      navigate(`${url}${a.productId?.display}`, {
        state: { values: a, page: "customer" },
      });
    } else if (props?.fromTab === PHYSICALTEAM) {
      localStorage.setItem("propertyValues", JSON.stringify(a));
      let url =
        fromPage === "agent"
          ? "/agentReport/agentList/agentPage/propertyDetailView/"
          : "/company/physicalTeam/propertyDetailView/";
      navigate(`${url}${a.productId?.display}`, {
        state: { values: a, page: "customer" },
      });
    } else {
      localStorage.setItem("propertyValues", JSON.stringify(a));
      let url =
        fromPage === "seller"
          ? "/sellerReport/developerList/developerPage/propertyDetailView/"
          : "/customer/developerPage/propertyDetailView/";
      navigate(
        companyID === ""
          ? `${url}${a.productId?.display}`
          : `/company/developerCompanyCreation/developerPage/propertyDetailView/${a.productId?.display}`,
        { state: { values: a, page: "customer" } }
      );
    }
    localStorage.setItem("propTabKey", JSON.stringify("featuredProperties"));
  };
  const onClickFeaturedProperty = () => {
    setShowCheckBox(true);
  };

  const onSelectProperties = (id) => {
    let propertyCheckedArray = [...propertyId];
    let includes = propertyCheckedArray.includes(id);
    if (includes) {
      let removedValue = propertyCheckedArray.filter((item) => item !== id);
      setPropertyId(removedValue);
    } else {
      propertyCheckedArray.push(id);
      setPropertyId([...propertyCheckedArray]);
    }
  };

  const onClickMove = async () => {
    let data = {
      propertyIds: propertyId,
      userId: props?.id,
      status: false,
    };
    await postData("/property/addFeaturedProperty", {}, data).then(
      (response) => {
        if (response.status) {
          getPropertyData();
          setShowCheckBox(false);
          setPropertyId([]);
        }
      }
    );
  };
  const resetClick = () => {
    setShowCheckBox(false);
    setPropertyId([]);
  };
  const btnList = [
    {
      btnName: "Edit",
      onClick: (a) => onclickEditProperty(a),
      className: "btn-color-primary",
    },
    {
      btnName: "View",
      onClick: (a) => onclickViewProperty(a),
      className: "btn-color-primary",
    },
  ];
  const onclickEditProperty = async (a) => {
    props.setScrollEvent(a?._id, cityFilterKeyValue);
    let page =
      fromPage === "agent"
        ? "agentReportsCustomer"
        : fromPage === "seller"
        ? "sellerReportsCustomer"
        : "";

    let url;
    localStorage.setItem("propTabKey", JSON.stringify("featuredProperties"));
    localStorage.setItem("propertyValues", JSON.stringify(a));
    url = `/property/editProp?propertyId=${a?._id}&developerId=${
      a?.developerId?._id
    }&developerType=${
      a?.customerType === "EAgent"
        ? ENTERPRISEAGENT
        : a?.customerType === PROPVANTAGE
        ? PHYSICALTEAM
        : a?.customerType
    }&developerName=${a?.developerId?.name}&fromPage=${page}`;
    localStorage.setItem("ownerShip", JSON.stringify(a?.propertyTypeId._id));
    navigate(url);
  };

  const onClickFeaturedDemand = () => {
    setShowCheckBoxForDemands(true);
  };
  const onSelectDemands = (id) => {
    let propertyCheckedArray = [...demandId];
    let includes = propertyCheckedArray.includes(id);
    if (includes) {
      let removedValue = propertyCheckedArray.filter((item) => item !== id);
      setDemandId(removedValue);
    } else {
      propertyCheckedArray.push(id);
      setDemandId([...propertyCheckedArray]);
    }
  };

  const resetClickForDemand = () => {
    setShowCheckBoxForDemands(false);
    setDemandId([]);
  };
  const onClickMoveForDemand = async () => {
    let data = {
      demands: demandId,
      userId: props?.id,
      status: false,
    };
    await postData(`/demand/addFeaturedDemands`, {}, data).then((response) => {
      if (response.status || response.statusCode === 200) {
        props?.fromTab === ENTERPRISEAGENT || props?.fromTab === PHYSICALTEAM
          ? getEAgentDemandData()
          : getDemandData();
        setShowCheckBoxForDemands(false);
        setDemandId([]);
      }
    });
  };

  const onClickEditDemand = async (a) => {
    props.setScrollEvent(a?._id, cityFilterKeyValue);
    const response = await checkForUserSubPlan(
      props?.subscriptionCheckId,
      "demand"
    );
    if (response?.status) {
      localStorage.setItem("demandId", JSON.stringify(a?._id));
      localStorage.setItem("propertyType", JSON.stringify(a?.propertyType));
      if (a?.propertyType?.name === "Office space") {
        localStorage.setItem("postDemandStep", JSON.stringify(3));
      } else {
        localStorage.setItem("postDemandStep", JSON.stringify(5));
      }
      localStorage.setItem("demandTabKey", JSON.stringify("ongoingDemands"));
      let pageFrom =
        companyID && fromPage === "buyer"
          ? "reportsCompany"
          : fromPage === "buyer"
          ? "reportsCustomer"
          : "brandPage";

      navigate("/demand/PostDemand", {
        state: {
          brandId: a?.brandId?._id,
          action: "edit",
          brandName: props?.brandName,
          page: pageFrom,
          enterpriseAgent: props?.fromTab === ENTERPRISEAGENT,
          physicalTeam: props?.fromTab === PHYSICALTEAM,
        },
      });
    }
  };
  const onClickViewDemand = (a) => {
    props.setScrollEvent(a?._id, cityFilterKeyValue);
    localStorage.setItem("demandid", JSON.stringify(a?._id));
    localStorage.setItem("brandid", JSON.stringify(props?.brandId));
    localStorage.setItem("demandTabKey", JSON.stringify("ongoingDemands"));
    let url =
      fromPage === "buyer"
        ? "/buyerReport/brandList/brandPage/DemandDetailView/"
        : "/customer/brandPage/DemandDetailView/";
    let url2 =
      fromPage === "buyer"
        ? "/buyerReport/companyList/brandCompanyCreation/brandPage/DemandDetailView/"
        : "/company/brandCompanyCreation/brandPage/DemandDetailView/";

    let agentUrl = "/customer/agentPage/DemandDetailView/";
    let eagentUrl = "/company/enterprise-agent/DemandDetailView/";
    let physicalTeamUrl = "/company/physicalTeam/DemandDetailView/";

    if (props?.fromTab === "agent") {
      navigate(`${agentUrl}${a?.productId?.display}`, {
        state: { demandId: a?._id, brandId: props?.brandId },
      });
    } else if (props?.fromTab === ENTERPRISEAGENT) {
      navigate(`${eagentUrl}${a?.productId?.display}`, {
        state: { demandId: a?._id, brandId: props?.brandId },
      });
    } else if (props?.fromTab === PHYSICALTEAM) {
      navigate(`${physicalTeamUrl}${a?.productId?.display}`, {
        state: { demandId: a?._id, brandId: props?.brandId },
      });
    } else {
      navigate(
        companyID === ""
          ? `${url}${a?.productId?.display}`
          : `${url2}${a?.productId?.display}`,
        { state: { demandId: a?._id, brandId: props?.brandId } }
      );
    }

    // navigate(
    //   companyID === ""
    //     ? `${url}${a?.productId?.display}`
    //     : `${url2}${a?.productId?.display}`,
    //   { state: { demandId: a?._id, brandId: props?.brandId } }
    // );
  };
  const demandBtnList = [
    {
      btnName: "Edit",
      onClick: (a) => onClickEditDemand(a),
      className: "btn-color-primary",
    },
    {
      btnName: "View",
      onClick: (a) => onClickViewDemand(a),
      className: "btn-color-primary",
    },
  ];

  useEffect(() => {
    if (apiCardDetail?.length > 0 && props.tabKey === "featuredProperties") {
      let updatedArray = performScrollEvent("myPageProperty", scrollStoreArray);
      setPageScroll(updatedArray);
    }
  }, [apiCardDetail]);

  useEffect(() => {
    getPropertyData();
    if (props?.fromTab === "agent") {
      getDemandData();
    } else if (
      props?.fromTab === ENTERPRISEAGENT ||
      props?.fromTab === PHYSICALTEAM
    ) {
      getEAgentDemandData();
    }
    setShowCheckBox(false);
    setPropertyId([]);
  }, [
    cityFilterKeyValue,
    propertyTypeFilterKeyValue,
    transactionFilterKeyValue,
    sortFilterKeyValue,
  ]);
  let sortValues = [
    {
      label: "Sort",
      value: "sort",
    },
    {
      label: "Newest",
      value: "desc",
    },
    {
      label: "Oldest",
      value: "asc",
    },
  ];
  return (
    <>
      <div className="row mb-3 ">
        <div className="col col-xs-12 col-sm-12 col-md-4 col-lg-3 ">
          {(props?.fromTab === "agent" ||
            props?.fromTab === ENTERPRISEAGENT ||
            props.fromTab === PHYSICALTEAM) && (
            <PropertyDemandTab
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          )}
        </div>
        <div className="col col-xs-12 col-sm-12 col-md-8 col-lg-9 mt-3 mt-md-0 mt-lg-0">
          <div className="d-flex flex-column flex-lg-row flex-md-row  align-items-center justify-content-end gap-3 propertyfilter">
            {/* <ReactDatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              placeholderText="Sort-Date"
              icon={<FaCalendar />}
              showIcon={true}
            /> */}
            <MultiSelect
              width="20%"
              onChange={(val) => {
                onchangeSortFilterKey(val);
              }}
              isMulti={false}
              options={[...sortValues]}
              value={sortFilterKeyValue}
            />
            <MultiSelect
              width="20%"
              onChange={(val) => {
                onchangeTransactionFilterKey(val);
              }}
              isMulti={false}
              options={[
                { label: "Transaction Type", value: "Transaction Type" },
                ...labelValueOfObjects(transactionTypeOptions),
              ]}
              value={transactionFilterKeyValue}
            />
            <MultiSelect
              width="20%"
              onChange={(val) => {
                onchangePropertyTypeFilterKey(val);
              }}
              isMulti={false}
              options={[
                { label: "Property Type", value: "Property Type" },
                ...nameToLabel(props?.PropertyTypeList),
              ]}
              value={propertyTypeFilterKeyValue}
            />
            <MultiSelect
              width="20%"
              onChange={(val) => {
                onchangeCityFilterKey(val);
              }}
              isMulti={false}
              options={[
                { label: "City", value: "City" },
                ...props?.searchCityList,
              ]}
              value={cityFilterKeyValue}
            />
          </div>
        </div>
      </div>
      {loading ? (
        <Loader />
      ) : activeTab === "property" ? (
        apiCardDetail.length > 0 ? (
          <>
            {props.featuredPositioningStatus &&
            props.featuredPositioningStatus === "Yes" ? (
              <div className="d-flex btn-container">
                {showCheckBox && propertyId?.length > 0 ? (
                  <div
                    className="d-flex mb-4 btn-container"
                    style={{ marginRight: "auto" }}
                  >
                    <Button
                      onClick={resetClick}
                      className="btn-outline-color-primary"
                    >
                      Reset
                    </Button>
                    <Button onClick={onClickMove} className="btn-color-primary">
                      Remove
                    </Button>
                  </div>
                ) : (
                  ""
                )}
                <div className="d-flex justify-content-end mb-4 btn-container">
                  <Button
                    onClick={onClickFeaturedProperty}
                    className="btn-color-primary"
                  >
                    Remove from featured properties
                  </Button>
                </div>
              </div>
            ) : (
              ""
            )}

            <PropertyCard
              access={props?.access}
              apiCardDetail={apiCardDetail}
              cardDetail={cardDetail}
              image={props?.image?.viewUrl ? props.image.viewUrl : props.image}
              // onclick={onclickViewProperty}
              showCheckBox={showCheckBox}
              onSelectProperties={onSelectProperties}
              button={btnList}
            />
          </>
        ) : (
          <center>
            {filterMessage ? filterMessage : "No featured properties yet"}
          </center>
        )
      ) : demandCardDetails?.length > 0 ? (
        <>
          {props.featuredPositioningStatus &&
          props.featuredPositioningStatus === "Yes" ? (
            <div className="d-flex btn-container">
              {showCheckBoxForDemands && demandId?.length > 0 ? (
                <div
                  className="d-flex mb-4 btn-container"
                  style={{ marginRight: "auto" }}
                >
                  <Button
                    onClick={resetClickForDemand}
                    className="btn-outline-color-primary"
                  >
                    Reset
                  </Button>
                  <Button
                    onClick={onClickMoveForDemand}
                    className="btn-color-primary"
                  >
                    Remove
                  </Button>
                </div>
              ) : (
                ""
              )}
              <div className="d-flex justify-content-end mb-4 btn-container">
                <Button
                  onClick={onClickFeaturedDemand}
                  className="btn-color-primary"
                >
                  Remove from featured demands
                </Button>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="demand-card-container" id="demand-card">
            <DemandCard
              access={props?.access}
              demandDetail={demandCardDetails}
              response={true}
              button={demandBtnList}
              relevant={false}
              showCheckBox={showCheckBoxForDemands}
              onSelectDemands={onSelectDemands}
            />
          </div>
        </>
      ) : (
        <center>
          {filterMessage ? filterMessage : "No featured demands yet"}
        </center>
      )}
    </>
  );
};
export default FeaturedProperty;
