import FormModal from "../../../components/Modal";
import Button from "../../../components/Form/Button";

export const CustomerDeleteWarningPopUp = ({onClose, show, message,warningYesClick,title,btnLoading}) =>{
    return(
        <FormModal
            onClose={onClose}
            show={show}
            heading={title?title :"Warning"}
            size={"md"}
            loading={false}
            loader="fixed"
            headerClassName={"warning-header-class"}
            className={"pt-0"}
        >
            <p>{message}</p>
            <div className='btn-container'>
                <Button
                    className={"btn-outline-color-primary"}
                    onClick={onClose}>
                    No
                </Button>
                <Button
                loading={btnLoading}
                    onClick={warningYesClick}
                    className="btn-color-primary"
                >
                    Yes
                </Button>
            </div>
        </FormModal>
    )
}

