import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { AiOutlineCheckCircle, AiOutlineDelete } from "react-icons/ai";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { IoEyeOutline } from "react-icons/io5";
import { MdCancel, MdOutlineEdit, MdOutlineModeEdit } from "react-icons/md";
import { CustomerWarningPopup } from "../../Customer/CustomerTable/warningPopup";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  AddHeaderdata,
  deleteData,
  editHeaderdata,
  getCategoriesData,
} from "../../../api";
import Loader from "../../../components/Loader";
import { BsThreeDotsVertical } from "react-icons/bs";
import { IoMdClose, IoMdEye } from "react-icons/io";

const CategoriesPage = () => {
  let getitem = localStorage.getItem('checkbox')
  const TabDataNew = [{
    name: "Brand",
    _id: 1,
    heading: "demo",
    displayName: "Brand"
  },
  {
    name: "Flex Occupier",
    _id: 2,
    heading: "Occupier",
    displayName: "Occupier"
  },
  {
    name: "Flex Operator",
    _id: 3,
    heading: "Operator",
    displayName: "Operator"
  },
  {
    name: "Developer",
    _id: 4,
    heading: "Developer",
    displayName: "Developer"
  }
  ]
  const [CategoryName, setCategoryName] = useState({});
  const [selectDropDownData, setDropDownData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  console.log({ selectedOptions });
  const [ProjectCheckBox, setProjectCheckBox] = useState(false)
  const [projectData, setProjectData] = useState([])
  const [CategoriesData, setGetCategories] = useState([]);

  const [brandCategoriesTab, setbrandCategoriesTab] = useState(null);
  console.log({ brandCategoriesTab });
  const [tempState, setTempState] = useState(true);
  const [headingEdit, SetheadingEdit] = useState(true);
  const [heading, setHeading] = useState({});
  console.log(heading, "heading");
  const [show, setShow] = useState({
    add: false,
    edit: false,
    delete: false,
  });
  const [EditID, setEditID] = useState();
  const [tableData, setTableData] = useState();
  const [Loading, setLoading] = useState(false);
  const [deleteID, setDeleteID] = useState(null);
  const [categoryNameError, setCategoryNameError] = useState(false);
  const [selectionError, setSelectionError] = useState(false);
  const [selectedOptionsError, setDropDownError] = useState(false);
  // get category
  const getCategoriesList = async () => {
    let url = `/contentMangementRevamp/getCategory`;
    const getCategories = await getCategoriesData(url, {});
    const { data, status } = getCategories;
    if (status) {
      console.log("lg8");
      setLoading(false);
      let CategoriesList = data?.getCategory?.map((item) => {
        return {
          name: item?.name,
          _id: item?._id,
          heading: item?.heading,
        };
      });
      if (brandCategoriesTab?.name) {
        getCategoriesTableData(brandCategoriesTab?.name);
      }
      setGetCategories(CategoriesList);



      if (tempState) {
        setbrandCategoriesTab(CategoriesList[0]);
        setTempState(false);
      } else {

      }


    }
  };


  const getCategoriesTableData = async (name) => {
    setLoading(true);
    let url = `/contentMangementRevamp/getSubCategoryByName/${name}`;
    const getCategories = await getCategoriesData(url, {});
    const { data, status } = getCategories;
    console.log({ data })
    if (status) {
      console.log("lg7");
      setLoading(false);
      SetheadingEdit(true);
      setTableData(data?.getSubCategory);
      setHeading({ heading: data?.getCategory?.heading })


    } else {
      setLoading(false);
      setTableData([]);
      setHeading({ heading: "" });
    }
  };

  const getProjetData = async () => {
    let url;
    if (brandCategoriesTab?.name === "Brand L1" || brandCategoriesTab?.name === "Occupier L1") {
      url = `/project/getProjectsAdmin?userType=Brand`;
    } else if (brandCategoriesTab?.name === "Developer L1" || brandCategoriesTab?.name === "Operator L1") {
      url = `/project/getProjectsAdmin?userType=Developer`;
    } else if (brandCategoriesTab?.name === "Agent L1") {
      url = "/project/getProjectsAdmin?userType=Agent";
    } else {
      url = "/project/getProjectsAdmin"
    }
    const getCategories = await getCategoriesData(url, {});
    const { data, status } = getCategories;
    if (status) {
      console.log("lg6");
      const newData = data?.projectList?.map((value) => {
        return {
          "_id": value?._id,
          "name": value?.projectName,
          "displayName": value?.projectId || "-"
        }
      })
      if (ProjectCheckBox) {
        setDropDownData(newData)
        setProjectData(newData);

      } else {
        setDropDownData([])
        setSelectedOptions([])
        setProjectData([]);
      }

    } else {

    }
  };

  // by id
  const getCategoryByID = async (id) => {
    setLoading(true);
    let url = `/contentMangementRevamp/getSubCategoryDetail/${id}`;
    const getCategories = await getCategoriesData(url, {});
    const { data, status } = getCategories;
    if (status) {
      setLoading(false);
      console.log("lg5");
      if (data?.getSubCategory
      [0]?.selectedType[0] == 'Projects') {
        // setSelectedItems([])
      } else {
        const array = data?.getSubCategory[0]?.selectedData?.map((value) => value?.displayName);
        const filteredArray = array.filter(item => item !== null);
        setSelectedItems(
          filteredArray
        );
      }
      const checkbox = data?.getSubCategory
      [0]?.selectedType[0] == 'Projects' ? true : false

      setProjectCheckBox(checkbox)
      setCategoryName(data?.getSubCategory[0]?.name);
      setSelectedOptions(
        data?.getSubCategory[0]?.selectedData?.map((value) => {
          return {
            _id: value?._id,
            name: value?.projectName || value?.name,
            displayName: value?.displayName || value?.projectId,
          };
        })
      );
    } else {
      setLoading(false);
    }
  };

  // edit heading
  const SaveHEadingAPI = async (ID, body) => {
    const url = `/contentMangementRevamp/editCategory/${ID} `;
    setLoading(true);
    const getClienteleStatus = await editHeaderdata(url, body);
    const { data, status } = getClienteleStatus;
    if (status) {
      getCategoriesList();
      setLoading(false);
      SetheadingEdit(true);
    } else {
      setLoading(false);
    }
  };

  // edit sub categories
  const EditHEadingAPI = async (body) => {
    const url = `/contentMangementRevamp/editSubCategory/${EditID} `;
    setLoading(true);
    const getClienteleStatus = await editHeaderdata(url, body);
    const { data, status } = getClienteleStatus;
    if (status) {
      if (brandCategoriesTab) {
        getCategoriesTableData(brandCategoriesTab?.name);
      }
      console.log("lg3");
      setLoading(false);
      SetheadingEdit(true);
      setProjectCheckBox(false)
      setShow({
        add: false,
        edit: false,
        delete: false,
      });
      setSelectedOptions([]);
      setSelectedItems([]);
      SetheadingEdit(true);
      setCategoryName({});
      setDropDownData([]);
      setEditID();
      setDropDownError(false);
      setSelectionError(false);
      setCategoryNameError(false);
      setIsOpen(false)
    } else {
      setLoading(false);
      setIsOpen(false)
    }
  };

  // add categories
  const CreateSubCategoriesAPI = async (body) => {
    const url = `/contentMangementRevamp/createSubCategory `;
    setLoading(true);
    const getClienteleStatus = await AddHeaderdata(url, body);
    const { data, status } = getClienteleStatus;
    if (status) {
      console.log("lg1");
      getCategoriesList();
      setLoading(false);
      if (brandCategoriesTab?.name) {
        getCategoriesTableData(brandCategoriesTab?.name);
      }
      setShow({
        add: false,
        edit: false,
        delete: false,
      });
      setProjectCheckBox(false)
      setSelectedOptions([]);
      setSelectedItems([]);
      SetheadingEdit(true);
      setCategoryName({});
      setDropDownData([]);
      setDropDownError(false);
      setSelectionError(false);
      setCategoryNameError(false);
      setIsOpen(false)
    } else {
      setLoading(false);
      setIsOpen(false)
    }
  };

  // selected data list dropdown
  const getBrandBasedOnUserType = async (body) => {
    const url = `/contentMangementRevamp/getBrandBasedOnUserType`;
    // setLoading(true);
    const getClienteleStatus = await AddHeaderdata(url, body);
    const { data, status } = getClienteleStatus;
    if (status) {
      // getCategoriesList();
      console.log("lg2");
      const filteredData = data?.getData.filter(item => item.name);
      // setLoading(false);
      setDropDownData(filteredData);
      // console.log(filteredData,"aman");
      SetheadingEdit(true);
    } else {
      // setLoading(false);
    }
  };

  // delete sub categories
  const DeleteAPI = async (id) => {
    const url = `/contentMangementRevamp/deleteSubCategory/${id} `;
    setLoading(true);
    const getClienteleStatus = await deleteData(url);
    const { data, status } = getClienteleStatus;
    if (status) {
      getCategoriesList();
      setLoading(false);
      setDeleteID(null);
      if (brandCategoriesTab?.name) {
        getCategoriesTableData(brandCategoriesTab?.name);
      }
      setShow({
        add: false,
        edit: false,
        delete: false,
      });
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCategoriesList();

    if (brandCategoriesTab?.name) {
      getCategoriesTableData(brandCategoriesTab?.name);
    }
  }, [brandCategoriesTab?.name]);

  const handleCheckboxChange = (itemId, item) => {
    console.log(itemId, "amana");
    if (selectedItems.includes(itemId)) {
      setSelectedItems(selectedItems.filter((item) => item !== itemId));
      setDropDownData([])
    } else {
      setSelectedItems([...selectedItems, itemId]);
      setDropDownData([])
    }
  };

  const handleOptionClick = (option) => {
    if (selectedOptions.some((selected) => selected._id === option._id)) {
      setSelectedOptions(
        selectedOptions.filter((item) => item._id !== option._id)
      );
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  const handleCancelClick = (name) => {
    setSelectedOptions(
      selectedOptions.filter((option) => option.name !== name)
    );
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const newOrder = Array.from(selectedOptions);
    const [removed] = newOrder.splice(result.source.index, 1);
    newOrder.splice(result.destination.index, 0, removed);

    setSelectedOptions(newOrder);
  };

  const handlesetvalues = (value) => {
    setbrandCategoriesTab(value);
    setHeading({ heading: value?.heading });
  };

  useEffect(() => {
    const array = selectedItems;
    const filteredArray = array.filter(item => item !== null);
    if (selectedItems) {
      getBrandBasedOnUserType({
        userType: ProjectCheckBox ? [] : filteredArray,
      });
    }
  }, [selectedItems]);


  useEffect(() => {
    if (ProjectCheckBox) {
      getProjetData()
    }
  }, [ProjectCheckBox])


  useEffect(() => {
    if (ProjectCheckBox) {
      setSelectedItems([])
    }
  }, [ProjectCheckBox])



  let filteredData = TabDataNew;
  if (brandCategoriesTab?.name == "Brand L1" && "Brand") {
    filteredData = TabDataNew.filter(
      (item) => item.name !== "Brand" && item.name !== "Flex Occupier"
    )
  } else if (brandCategoriesTab?.name == "Developer L1" && "Developer") {
    filteredData = TabDataNew.filter(
      (item) => item.name !== "Developer" && item.name !== "Flex Operator"
    )
  } else if (brandCategoriesTab?.name == "Operator L1" && "Operator") {
    filteredData = TabDataNew.filter(
      (item) => item.name !== "Developer" && item.name !== "Flex Operator"
    )
  } else if (brandCategoriesTab?.name == "Occupier L1" && "Occupier") {
    filteredData = TabDataNew.filter(
      (item) => item.name !== "Brand" && item.name !== "Flex Occupier"
    )

  }

  console.log([filteredData]);

  return !Loading ? (
    <div className="p-4">
      <div className="d-flex flex-row flex-wrap gap-3">
        {CategoriesData.map((value, index) => {

          const elementStyle = {
            cursor: "pointer",
            borderBottom: `${brandCategoriesTab?.name === value?.
              name
              ? "3px solid #6C8C91" : "none"}`,
            borderColor: ` ${brandCategoriesTab?.name === value?.
              name
              ? "#0B262A" : "#6C8C91"}`,
            color: `${brandCategoriesTab?.name === value?.
              name
              ? "#0B262A" : "#6C8C91"}`,
            fontWeight: `${brandCategoriesTab?.name === value?.
              name
              ? "bold" : "normal"}`,
          };

          return (
            <div
              key={index}
              onClick={() => handlesetvalues(value)}
              className={`px-3 py-2 ${brandCategoriesTab === value ? "border-bottom" : ""}  fs-6"`}
              style={elementStyle}
            >
              {" "}
              {value?.name}
            </div>
          );
        })}
      </div>

      <div
        className="p-4 my-3 w-100 h-100"
        style={{ background: "#D1E7EE4D", width: "100%" }}
      >
        <div className="d-flex justify-content-between flex-row gap-2">
          <div className="d-flex flex-row gap-2">
            <div>Heading Text</div>
            <div
              className="fs-6 font-[500] font-outfit"
              style={{ cursor: "pointer" }}
              onClick={() => {
                SetheadingEdit(!headingEdit);
              }}
            >
              <MdOutlineEdit style={{ height: "1.2rem", width: "1.2rem" }} />
            </div>
          </div>
          {!headingEdit && heading?.length > 0 && <div className="d-flex flex-row justify-content-end gap-4">

            <div
              className="px-3 fs-6 py-2"
              style={{
                background: "#015169",
                borderRadius: "6px",
                cursor: "pointer",
                color: "#FFFFFF",
              }}
              onClick={
                !headingEdit
                  ? () => {
                    SaveHEadingAPI(brandCategoriesTab?._id, {
                      heading: heading?.heading || heading,
                    });
                  }
                  : () => { }
              }
            >
              Save
            </div>
            <div
              className="px-3 fs-6 py-2"
              style={{
                borderColor: "#015169",
                borderStyle: "outset",
                borderWidth: "1px",
                borderRadius: "6px",
                cursor: "pointer",
                color: "#015169",
              }}
              onClick={() => { SetheadingEdit(!headingEdit) }}
            >
              Cancel
            </div>
          </div>}

        </div>
        <div className={`${headingEdit ? "opacity-25" : ""}`}>
          <div className="w-100 h-100 outline-none  py-3">
            <input
              value={heading.heading}
              disabled={headingEdit}
              className="h-100 p-2"
              style={{
                outline: "none",
                color: "#406065",
                width: "100%",
                border: "none",
              }}
              name="heading"
              onChange={(e) => setHeading(e.target.value)}
            />
          </div>
        </div>

        <div className="border my-4"></div>

        <div>
          <div className="container mt-5">
            <h2 className="mb-4 fs-5" style={{ color: "#0B262A" }}>
              Categories
            </h2>
            <div>
              <div className="d-flex flex-row justify-content-end gap-4 py-3" >
                <div
                  className="px-3 fs-6 py-2"
                  onClick={() => {
                    setShow({
                      delete: false,
                      add: true,
                      edit: false,
                    });
                    setCategoryName();
                  }}
                  style={{
                    background: "#015169",
                    borderRadius: "6px",
                    cursor: "pointer",
                    color: "#FFFFFF",
                  }}
                >
                  Add Category
                </div>
              </div>
            </div>
            <table className="table ">
              <thead
                className=""
                style={{ background: "#D9E5E6", color: "#406065" }}
              >
                <tr>
                  <th scope="col">Sr. No.</th>
                  <th scope="col">Category Name</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {tableData?.map((value, index) => {
                  return (
                    <tr
                      key={index}
                      className="border-bottom"
                      style={{
                        color: "#406065",
                        fontSize: "14px",
                        fontFamily: "outfit",
                      }}
                    >
                      <th>{index + 1}</th>
                      <td>{value?.name}</td>
                      <td
                        className="d-flex flex-row gap-2 justify-content-center "
                        style={{ color: "#406065" }}
                      >
                        <div>
                          <IoMdEye
                            onClick={() => {
                              setShow({
                                delete: false,
                                add: false,
                                edit: false,
                                view: true
                              });

                              getCategoryByID(value?._id);
                              setEditID(value?._id);
                            }}
                            style={{
                              height: "1.5rem",
                              width: "1.5rem",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                        <div>
                          <MdOutlineModeEdit
                            onClick={() => {
                              setShow({
                                delete: false,
                                add: false,
                                edit: true,
                              });

                              getCategoryByID(value?._id);
                              setEditID(value?._id);
                            }}
                            style={{
                              height: "1.5rem",
                              width: "1.5rem",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                        <div>
                          <AiOutlineDelete
                            onClick={() => {
                              setShow({
                                delete: true,
                                add: false,
                                edit: false,
                              });
                              setDeleteID(value?._id);
                            }}
                            style={{
                              height: "1.5rem",
                              width: "1.5rem",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* add  modal*/}

      <Modal show={show?.add} className="p-3 " style={{ padding: "10px" }}>

        <div className="mx-3 mt-3 ">
          <div className='d-flex justify-content-end'>
            <IoMdClose onClick={() => {
              setShow({
                add: false,
                edit: false,
                delete: false,
              });
              setSelectedOptions([]);
              setProjectCheckBox(false)
              setSelectedItems([]);
              SetheadingEdit(true);
              setCategoryName({});
              setDropDownData([]);
              setDropDownError(false);
              setSelectionError(false);
              setCategoryNameError(false);
              setIsOpen(false)

            }} style={{ height: "25px", width: "25px", cursor: "pointer" }} />
          </div>
          <Modal.Title style={{ color: "#0B262A" }}>Add Category</Modal.Title>
          {/* <div className="" style={{ fontSize: "18px", color: "#406065" }}>
            Fill out the form{" "}
          </div> */}
        </div>
        <Modal.Body>
          <div className="d-flex p-2 ">
            <div className="p-3">
              <div
                style={{
                  color: "#0B262A",
                  fontFamily: "outfit",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                Category Name
              </div>
              <div className="  ">
                <input
                  value={CategoryName}
                  className="border my-2 p-1"
                  style={{
                    outline: "none",
                    color: "#406065",
                    borderRadius: "6px",
                    width: "100%",
                    border: "#D9E5E6",
                  }}
                  placeholder="Enter Category Name"
                  name="CategoryName"
                  onChange={(e) => setCategoryName(e.target.value)}
                />
                {categoryNameError && (
                  <div
                    style={{
                      color: "red",
                      fontFamily: "outfit",
                      fontSize: "13px",
                      fontWeight: "500",
                    }}
                  >
                    Category name is required.
                  </div>
                )}
              </div>
              <div
                className="mt-3"
                style={{
                  color: "#0B262A",
                  fontFamily: "outfit",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                User Type/ Projects
              </div>

              {<div className="py-3 d-flex flex-column  " >
                {!ProjectCheckBox && filteredData?.map((item, index) => {
                  return (
                    <div className="col-md-3 ">
                      <div key={index} className="d-flex flex-row gap-2 ">
                        <div>
                          <input
                            type="checkbox"
                            value={item.name}
                            className="accent:primary-color"
                            checked={selectedItems.includes(item.name)}
                            onChange={() => handleCheckboxChange(item.name, item)}
                          />
                        </div>
                        <p
                          style={{
                            color: "#406065",
                            fontFamily: "outfit",
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          {item.displayName}
                        </p>
                      </div>

                    </div>
                  );
                })}
                {brandCategoriesTab?.name == "Developer L1" || brandCategoriesTab?.name == "Operator L1" ? <div></div> : <div className="d-flex self-items-center gap-2" style={{
                  color: "#0B262A",
                  fontFamily: "outfit",
                  fontSize: "16px",
                  fontWeight: "500",
                }}>
                  <input type="checkbox" name="project" checked={ProjectCheckBox} onChange={(e) => {
                    setProjectCheckBox(!ProjectCheckBox)
                    setSelectedOptions([])
                    setDropDownData([])

                  }} id="" />
                  <span style={{
                    color: "#406065",
                    fontFamily: "outfit",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}>  Projects </span>
                </div>}
              </div>}
              {selectionError && (
                <div
                  style={{
                    color: "red",
                    fontFamily: "outfit",
                    fontSize: "13px",
                    fontWeight: "500",
                  }}
                >
                  Error: choose both user type and projects.
                </div>
              )}
              <div
                style={{
                  color: "#B4B8AC",
                  fontFamily: "outfit",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
              >
                <span
                  style={{
                    color: "#406065",
                    fontFamily: "outfit",
                    fontSize: "13px",
                    fontWeight: "500",
                  }}
                >
                  Note:{" "}
                </span>{" "}
                You cannot choose a user type if you choose Projects, and vice
                versa.
              </div>
            </div>

            <div
              className="w-100 p-2"
              style={{ background: "#F8F8F8", width: "100%" }}
            >
              <div
                style={{
                  borderColor: "#D9E5E6",
                  borderWidth: "2px",
                  borderRadius: "5px",
                  zIndex: 1,
                  position: "relative",
                }}
                className="border p-2"
                // onMouseEnter={handleMouseEnter}
                // onMouseLeave={handleMouseLeave}
                onClick={() => { setIsOpen(!isOpen) }}
              >
                <div
                  className="d-flex flex-row w-100 justify-content-between"
                  style={{ cursor: "pointer" }}
                >
                  <div style={{ color: "#6C8C91", fontSize: "16px" }}>
                    Select
                  </div>
                  {isOpen ? (
                    <FaChevronUp
                      style={{
                        color: "#6C8C91",
                        height: "1.2rem",
                        width: "1.2rem",
                        cursor: "pointer",
                      }}
                    />
                  ) : (
                    <FaChevronDown
                      style={{
                        color: "#6C8C91",
                        height: "1.2rem",
                        width: "1.2rem",
                        cursor: "pointer",
                      }}
                    />
                  )}
                </div>
                {isOpen && (
                  <div
                    className="dropdown-content"
                    style={{
                      position: "absolute",
                      left: 0,
                      background: "#fff",
                      border: "1px solid #D9E5E6",
                      borderRadius: "5px",
                      width: "100%",
                      zIndex: 2,
                    }}
                  >
                    {selectDropDownData?.map((option) => (
                      <div
                        key={option.id}
                        className="d-flex flex-row p-2 w-100 justify-content-between align-item-center"
                        onClick={() => handleOptionClick(option)}
                        style={{
                          backgroundColor: selectedOptions.some(
                            (selected) => selected._id === option._id
                          )
                            ? ""
                            : "transparent",
                          cursor: "pointer",
                        }}
                      >
                        <div style={{ color: "#6C8C91", fontSize: "16px" }}>
                          {option.name}
                        </div>
                        {selectedOptions.some(
                          (selected) => selected._id === option._id
                        ) && (
                            <span>
                              <AiOutlineCheckCircle
                                style={{ color: "#6C8C91" }}
                              />
                            </span>
                          )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {selectedOptionsError && (
                <div
                  style={{
                    color: "red",
                    fontFamily: "outfit",
                    fontSize: "13px",
                    fontWeight: "500",
                  }}
                >
                  Error: select options
                </div>
              )}
              <div style={{ marginTop: "1rem", overflow: "auto", height: "20rem" }}>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{ marginTop: "1rem" }}
                      >
                        {selectedOptions.map((item, index) => (
                          <Draggable
                            key={item._id}
                            draggableId={String(item._id)}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  ...provided.draggableProps.style,
                                  borderRadius: "5px",
                                  color: "#B4B8AC",
                                }}
                                className="d-flex align-self-center align-items-center py-2 my-2  flex-row border p-1 justify-content-between"
                              >
                                <div className="d-flex gap-1">
                                  <BsThreeDotsVertical
                                    style={{ color: "black" }}
                                  />
                                  <div
                                    className=""
                                    style={{ fontSize: "13px", color: "#406065" }}
                                    data-toggle="tooltip" data-placement="top" title={item.name.length > 6 ? item.name : ""}

                                  >
                                    {item.name.length > 6 ? item.name.slice(0, 6) + "..." : item.name}
                                  </div>
                                </div>

                                <div className="d-flex gap-2  align-self-center align-items-center">
                                  <div style={{
                                    backgroundColor: "#D9E5E6",
                                    fontSize: "11px",
                                    padding: "3px 14px 3px 14px",
                                    borderRadius: "10px",
                                    color: "#08536B",
                                    cursor: "pointer",
                                  }}>
                                    {item.displayName}
                                  </div>
                                  <MdCancel
                                    style={{
                                      height: "1.3rem",
                                      width: "1.3rem",
                                      color: "#B4B8AC",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleCancelClick(item.name)}
                                  />
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="d-flex flex-row justify-content-end my-4 gap-4">
            <div
              className="px-3 fs-6 py-2"
              style={{
                borderColor: "#015169",
                borderStyle: "outset",
                borderWidth: "1px",
                borderRadius: "6px",
                cursor: "pointer",
                color: "#015169",
              }}
              onClick={() => {
                setShow({
                  add: false,
                  edit: false,
                  delete: false,
                });
                setSelectedOptions([]);
                setProjectCheckBox(false)
                setSelectedItems([]);
                SetheadingEdit(true);
                setCategoryName({});
                setDropDownData([]);
                setDropDownError(false);
                setSelectionError(false);
                setCategoryNameError(false);
                setIsOpen(false)
              }}
            >
              Cancel
            </div>
            <div
              className="px-3 fs-6 py-2"
              style={{
                background: "#015169",
                borderRadius: "6px",
                cursor: "pointer",
                color: "#FFFFFF",
              }}
              onClick={() => {
                CategoryName &&
                  // selectedItems?.length > 0 || ProjectCheckBox &&
                  selectedOptions?.length > 0 &&
                  CreateSubCategoriesAPI({
                    name: CategoryName,
                    categoryId: brandCategoriesTab?._id,
                    selectedId: selectedOptions.map((v) => v._id),
                    selectedType: ProjectCheckBox ? ['Projects'] : selectedItems
                  });

                setCategoryNameError(CategoryName ? false : true);
                setSelectionError(selectedItems?.length > 0 || ProjectCheckBox ? false : true);
                setDropDownError(selectedOptions?.length > 0 ? false : true);
              }}
            >
              Save
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      {/* delete modal */}
      <CustomerWarningPopup
        title={"Confirmation"}
        show={show?.delete}
        message={"Do you want to delete category?"}
        warningYesClick={() => {
          DeleteAPI(deleteID);
        }}
        onClose={() => {
          setShow({
            add: false,
            edit: false,
            delete: false,
          });
          setSelectedOptions([]);
          setProjectCheckBox(false)
          setSelectedItems([]);
          SetheadingEdit(true);
          setCategoryName({});
          setDropDownData([]);
          setDropDownError(false);
          setSelectionError(false);
          setCategoryNameError(false);
          setIsOpen(false)
        }}
      />

      {/* edit modal */}

      <div className="relative-box  position-relative "  >

        <Modal show={show?.edit} className=" absolute-box modal-cls  position-absolute  d-flex align-items-center justify-content-center" >

          <div className="mx-3 mt-3   "  >
            <div className='d-flex justify-content-end ' >
              <IoMdClose onClick={() => {
                setShow({
                  add: false,
                  edit: false,
                  delete: false,
                });
                setSelectedOptions([]);
                setProjectCheckBox(false)
                setSelectedItems([]);
                SetheadingEdit(true);
                setCategoryName({});
                setDropDownData([]);
                setDropDownError(false);
                setSelectionError(false);
                setCategoryNameError(false);
                setIsOpen(false)
              }} style={{ height: "25px", width: "25px", cursor: "pointer" }} />
            </div>
            <Modal.Title style={{ color: "#0B262A" }}>Edit Category</Modal.Title>
            {/* <div className="" style={{ fontSize: "18px", color: "#406065" }}>
          Fill out the form{" "}
        </div> */}
          </div>
          <Modal.Body className="w-100"  >
            <div className="d-flex flex-row">
              <div className="p-3">
                <div
                  style={{
                    color: "#0B262A",
                    fontFamily: "outfit",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  Category Name
                </div>
                <div className="  ">
                  <input
                    value={CategoryName}
                    className="border my-2 p-1"
                    style={{
                      outline: "none",
                      color: "#406065",
                      borderRadius: "6px",
                      width: "100%",
                      border: "#D9E5E6",
                    }}
                    name="CategoryName"
                    onChange={(e) => setCategoryName(e.target.value)}
                  />
                  {categoryNameError && (
                    <div
                      style={{
                        color: "red",
                        fontFamily: "outfit",
                        fontSize: "13px",
                        fontWeight: "500",
                      }}
                    >
                      Category name is required.
                    </div>
                  )}
                </div>
                <div
                  className="mt-3"
                  style={{
                    color: "#0B262A",
                    fontFamily: "outfit",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  User Type/ Projects
                </div>


                {/* <div className=" row container">
              {!ProjectCheckBox && CategoriesData.map((item, index) => {
                return (
                  <div className="col-md-3 ">
                    <div key={index} className="d-flex align-self-center gap-2">
                      <div>
                        <input
                          type="checkbox"
                          value={item.name}
                          className="accent:primary-color"
                          checked={selectedItems.includes(item.name)}
                          onChange={() => handleCheckboxChange(item.name, item)}
                        />
                      </div>
                      <p
                        style={{
                          color: "#406065",
                          fontFamily: "outfit",
                          fontSize: "16px",
                          fontWeight: "500",
                        }}
                      >
                        {item.name}
                      </p>
                    </div>
                  </div>
                );
              })}
              <div className="d-flex self-items-center  gap-3" style={{
                color: "#0B262A",
                fontFamily: "outfit",
                fontSize: "16px",
                fontWeight: "500",
              }}>
                <input type="checkbox" name="project" checked={ProjectCheckBox} onChange={(e) => {
                  setProjectCheckBox(!ProjectCheckBox)
                  setSelectedOptions([]);


                }} id="" />
                <p style={{
                  color: "#406065",
                  fontFamily: "outfit",
                  fontSize: "16px",
                  fontWeight: "500",
                }}>  Projects </p>
              </div>
            </div> */}

                {<div className="py-3 d-flex flex-column">

                  {!ProjectCheckBox &&

                    filteredData?.map((item, index) => {
                      return (
                        <div className="col-md-3 ">
                          <div key={index} className="d-flex align-self-center gap-2">
                            <div>
                              <input
                                type="checkbox"
                                value={item.name}
                                className="accent:primary-color"
                                checked={selectedItems.includes(item.name)}
                                onChange={() => handleCheckboxChange(item.name, item)}
                              />
                            </div>
                            <p
                              style={{
                                color: "#406065",
                                fontFamily: "outfit",
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              {item.displayName}
                            </p>
                          </div>

                        </div>
                      );
                    })}
                  {brandCategoriesTab?.name == "Developer L1" || brandCategoriesTab?.name == "Operator L1" ? <div></div> : <div className="d-flex self-items-center gap-2" style={{
                    color: "#0B262A",
                    fontFamily: "outfit",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}>
                    <input type="checkbox" name="project" checked={ProjectCheckBox} onChange={(e) => {
                      setProjectCheckBox(!ProjectCheckBox)
                      setSelectedOptions([])
                      setDropDownData([])

                    }} id="" />
                    <span style={{
                      color: "#406065",
                      fontFamily: "outfit",
                      fontSize: "16px",
                      fontWeight: "500",
                    }}>  Projects </span>
                  </div>}
                </div>}
                {selectionError && (
                  <div
                    style={{
                      color: "red",
                      fontFamily: "outfit",
                      fontSize: "13px",
                      fontWeight: "500",
                    }}
                  >
                    Error: choose both user type and projects.
                  </div>
                )}
                <div
                  style={{
                    color: "#B4B8AC",
                    fontFamily: "outfit",
                    fontSize: "13px",
                    fontWeight: "500",
                  }}
                >
                  <span
                    style={{
                      color: "#406065",
                      fontFamily: "outfit",
                      fontSize: "13px",
                      fontWeight: "500",
                    }}
                  >
                    Note:{" "}
                  </span>{" "}
                  You cannot choose a user type if you choose Projects, and vice
                  versa.
                </div>
              </div>

              <div
                className=" p-2"
                style={{ background: "#F8F8F8", width: "100%" }}
              >
                <div
                  style={{
                    borderColor: "#D9E5E6",
                    borderWidth: "2px",
                    borderRadius: "5px",
                    zIndex: 1,
                    position: "relative",
                  }}
                  className="border p-2"
                  onClick={() => { setIsOpen(!isOpen) }}
                // onMouseEnter={handleMouseEnter}
                // onMouseLeave={handleMouseLeave}
                >
                  <div
                    className="d-flex flex-row w-100 justify-content-between"
                    style={{ cursor: "pointer" }}
                  >
                    <div style={{ color: "#6C8C91", fontSize: "16px" }}>
                      Select
                    </div>
                    {isOpen ? (
                      <FaChevronUp
                        style={{
                          color: "#6C8C91",
                          height: "1.2rem",
                          width: "1.2rem",
                          cursor: "pointer",
                        }}
                      />
                    ) : (
                      <FaChevronDown
                        style={{
                          color: "#6C8C91",
                          height: "1.2rem",
                          width: "1.2rem",
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </div>
                  {isOpen && (
                    <div
                      className="dropdown-content"
                      style={{
                        position: "absolute",
                        left: 0,
                        background: "#fff",
                        border: "1px solid #D9E5E6",
                        borderRadius: "5px",
                        width: "100%",
                        zIndex: 2,
                      }}
                    >
                      {selectDropDownData.map((option) => (
                        <div
                          key={option.id}
                          className="d-flex flex-row p-2 w-100 justify-content-between align-items-center"
                          onClick={() => handleOptionClick(option)}
                          style={{
                            backgroundColor: selectedOptions.some(
                              (selected) => selected._id === option._id
                            )
                              ? ""
                              : "transparent",
                            cursor: "pointer",
                          }}
                        >
                          <div style={{ color: "#6C8C91", fontSize: "16px" }}>
                            {option.name}
                          </div>
                          {selectedOptions.some(
                            (selected) => selected._id === option._id
                          ) && (
                              <span>
                                <AiOutlineCheckCircle
                                  style={{ color: "#6C8C91" }}
                                />
                              </span>
                            )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                {selectedOptionsError && (
                  <div
                    style={{
                      color: "red",
                      fontFamily: "outfit",
                      fontSize: "13px",
                      fontWeight: "500",
                    }}
                  >
                    Error: select options
                  </div>
                )}
                <div style={{ marginTop: "1rem", overflow: "auto", height: "20rem" }}>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{ marginTop: "1rem" }}
                        >
                          {selectedOptions.map((item, index) => (
                            <Draggable
                              key={item._id}
                              draggableId={String(item._id)}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={{
                                    ...provided.draggableProps.style,
                                    borderRadius: "5px",
                                    color: "#B4B8AC",
                                  }}
                                  className="d-flex align-self-center align-items-center py-2 my-2  flex-row border p-1 justify-content-between"
                                >
                                  <div className="d-flex gap-1">
                                    <BsThreeDotsVertical
                                      style={{ color: "black" }}
                                    />
                                    <div
                                      className=""
                                      style={{ fontSize: "13px", color: "#406065" }}
                                      data-toggle="tooltip" data-placement="top" title={item.name.length > 6 ? item.name : ""}

                                    >
                                      {item.name.length > 6 ? item.name.slice(0, 6) + "..." : item.name}
                                    </div>
                                  </div>

                                  <div className="d-flex gap-2  align-self-center align-items-center">
                                    <div style={{
                                      backgroundColor: "#D9E5E6",
                                      fontSize: "11px",
                                      padding: "3px 14px 3px 14px",
                                      borderRadius: "10px",
                                      color: "#08536B",
                                      cursor: "pointer",
                                    }}>
                                      {item.displayName}
                                    </div>
                                    <MdCancel
                                      style={{
                                        height: "1.3rem",
                                        width: "1.3rem",
                                        color: "#B4B8AC",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => handleCancelClick(item.name)}
                                    />
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <div className="d-flex flex-row justify-content-end gap-4">
              <div
                className="px-3 fs-6 py-2"
                style={{
                  borderColor: "#015169",
                  borderStyle: "outset",
                  borderWidth: "1px",
                  borderRadius: "6px",
                  cursor: "pointer",
                  color: "#015169",
                }}
                onClick={() => {
                  setShow({
                    add: false,
                    edit: false,
                    delete: false,
                  });
                  setSelectedOptions([]);
                  setSelectedItems([]);
                  SetheadingEdit(true);
                  setCategoryName({});
                  setDropDownData([]);
                  setEditID();
                  setProjectCheckBox(false)
                  setDropDownError(false);
                  setSelectionError(false);
                  setCategoryNameError(false);
                  setIsOpen(false);

                }}
              >
                Cancel
              </div>
              <div
                className="px-3 fs-6 py-2"
                style={{
                  background: "#015169",
                  borderRadius: "6px",
                  cursor: "pointer",
                  color: "#FFFFFF",
                }}
                onClick={() => {
                  CategoryName &&
                    // selectedItems?.length > 0 || ProjectCheckBox &&
                    selectedOptions?.length > 0 &&
                    EditHEadingAPI({
                      name: CategoryName,
                      categoryId: brandCategoriesTab?._id,
                      selectedId: selectedOptions.map((v) => v._id),
                      selectedType: ProjectCheckBox ? ['Projects'] : selectedItems
                    });
                  setCategoryNameError(CategoryName ? false : true);
                  setSelectionError(selectedItems?.length > 0 || ProjectCheckBox ? false : true);
                  setDropDownError(selectedOptions?.length > 0 ? false : true);
                }}
              >
                Save
              </div>
            </div>
          </Modal.Footer>
        </Modal>


      </div>



      {/* view */}
      <div className="relative-box  position-relative "  >

        <Modal show={show?.view} className=" absolute-box modal-cls  position-absolute  d-flex align-items-center justify-content-center" >

          <div className="mx-3 mt-3   "  >
            <div className='d-flex justify-content-end ' >
              <IoMdClose onClick={() => {
                setShow({
                  add: false,
                  edit: false,
                  delete: false,
                  view: false
                });
                setSelectedOptions([]);
                setProjectCheckBox(false)
                setSelectedItems([]);
                SetheadingEdit(true);
                setCategoryName({});
                setDropDownData([]);
                setDropDownError(false);
                setSelectionError(false);
                setCategoryNameError(false);
                setIsOpen(false);

              }} style={{ height: "25px", width: "25px", cursor: "pointer" }} />
            </div>
            <Modal.Title style={{ color: "#0B262A" }}>View Category</Modal.Title>
            {/* <div className="" style={{ fontSize: "18px", color: "#406065" }}>
          Fill out the form{" "}
        </div> */}
          </div>
          <Modal.Body className="w-100"  >
            <div className="d-flex flex-row">
              <div className="p-3">
                <div
                  style={{
                    color: "#0B262A",
                    fontFamily: "outfit",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  Category Name
                </div>
                <div className="  ">
                  <input
                    value={CategoryName}
                    className="border my-2 p-1"
                    style={{
                      outline: "none",
                      color: "#406065",
                      borderRadius: "6px",
                      width: "100%",
                      border: "#D9E5E6",
                    }}
                    disabled={true}
                    name="CategoryName"
                    onChange={(e) => setCategoryName(e.target.value)}
                  />
                  {categoryNameError && (
                    <div
                      style={{
                        color: "red",
                        fontFamily: "outfit",
                        fontSize: "13px",
                        fontWeight: "500",
                      }}
                    >
                      Category name is required.
                    </div>
                  )}
                </div>
                <div
                  className="mt-3"
                  style={{
                    color: "#0B262A",
                    fontFamily: "outfit",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  User Type/ Projects
                </div>


                {/* <div className=" row container">
              {!ProjectCheckBox && CategoriesData.map((item, index) => {
                return (
                  <div className="col-md-3 ">
                    <div key={index} className="d-flex align-self-center gap-2">
                      <div>
                        <input
                          type="checkbox"
                          value={item.name}
                          className="accent:primary-color"
                          checked={selectedItems.includes(item.name)}
                          onChange={() => handleCheckboxChange(item.name, item)}
                        />
                      </div>
                      <p
                        style={{
                          color: "#406065",
                          fontFamily: "outfit",
                          fontSize: "16px",
                          fontWeight: "500",
                        }}
                      >
                        {item.name}
                      </p>
                    </div>
                  </div>
                );
              })}
              <div className="d-flex self-items-center  gap-3" style={{
                color: "#0B262A",
                fontFamily: "outfit",
                fontSize: "16px",
                fontWeight: "500",
              }}>
                <input type="checkbox" name="project" checked={ProjectCheckBox} onChange={(e) => {
                  setProjectCheckBox(!ProjectCheckBox)
                  setSelectedOptions([]);


                }} id="" />
                <p style={{
                  color: "#406065",
                  fontFamily: "outfit",
                  fontSize: "16px",
                  fontWeight: "500",
                }}>  Projects </p>
              </div>
            </div> */}

                {<div className="py-3 d-flex flex-column">
                  {!ProjectCheckBox && filteredData?.map((item, index) => {
                    return (
                      <div className="col-md-3 ">
                        <div key={index} className="d-flex align-self-center gap-2">
                          <div>
                            <input
                              disabled={true}
                              type="checkbox"
                              value={item.name}
                              className="accent:primary-color"
                              checked={selectedItems.includes(item.name)}
                              onChange={() => handleCheckboxChange(item.name, item)}
                            />
                          </div>
                          <p
                            style={{
                              color: "#406065",
                              fontFamily: "outfit",
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            {item.displayName}
                          </p>
                        </div>

                      </div>
                    );
                  })}
                  {brandCategoriesTab?.name == "Developer L1" || brandCategoriesTab?.name == "Operator L1" ? <div></div> : <div className="d-flex self-items-center gap-2" style={{
                    color: "#0B262A",
                    fontFamily: "outfit",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}>
                    <input disabled={true} type="checkbox" name="project" checked={ProjectCheckBox} onChange={(e) => {
                      setProjectCheckBox(!ProjectCheckBox)
                      setSelectedOptions([])

                    }} id="" />
                    <span style={{
                      color: "#406065",
                      fontFamily: "outfit",
                      fontSize: "16px",
                      fontWeight: "500",
                    }}>  Projects </span>
                  </div>}
                </div>}
                {selectionError && (
                  <div
                    style={{
                      color: "red",
                      fontFamily: "outfit",
                      fontSize: "13px",
                      fontWeight: "500",
                    }}
                  >
                    Error: choose both user type and projects.
                  </div>
                )}
                <div
                  style={{
                    color: "#B4B8AC",
                    fontFamily: "outfit",
                    fontSize: "13px",
                    fontWeight: "500",
                  }}
                >
                  <span
                    style={{
                      color: "#406065",
                      fontFamily: "outfit",
                      fontSize: "13px",
                      fontWeight: "500",
                    }}
                  >
                    Note:{" "}
                  </span>{" "}
                  You cannot choose a user type if you choose Projects, and vice
                  versa.
                </div>
              </div>

              <div
                className=" p-2"
                style={{ background: "#F8F8F8", width: "100%" }}
              >
                <div
                  style={{
                    borderColor: "#D9E5E6",
                    borderWidth: "2px",
                    borderRadius: "5px",
                    zIndex: 1,
                    position: "relative",
                  }}
                  className="border p-2"
                // onClick={() => { setIsOpen(!isOpen)}}
                // onMouseEnter={handleMouseEnter}
                // onMouseLeave={handleMouseLeave}
                >
                  <div
                    className="d-flex flex-row w-100 justify-content-between"
                    style={{ cursor: "pointer" }}
                  >
                    <div style={{ color: "#6C8C91", fontSize: "16px" }}>
                      Select
                    </div>
                    {isOpen ? (
                      <FaChevronUp
                        style={{
                          color: "#6C8C91",
                          height: "1.2rem",
                          width: "1.2rem",
                          cursor: "pointer",
                        }}
                      />
                    ) : (
                      <FaChevronDown
                        style={{
                          color: "#6C8C91",
                          height: "1.2rem",
                          width: "1.2rem",
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </div>
                  {isOpen && (
                    <div
                      className="dropdown-content"
                      style={{
                        position: "absolute",
                        left: 0,
                        background: "#fff",
                        border: "1px solid #D9E5E6",
                        borderRadius: "5px",
                        width: "100%",
                        zIndex: 2,
                      }}
                    >
                      {selectDropDownData.map((option) => (
                        <div
                          key={option.id}
                          className="d-flex flex-row p-2 w-100 justify-content-between align-items-center"
                          onClick={() => handleOptionClick(option)}
                          style={{
                            backgroundColor: selectedOptions.some(
                              (selected) => selected._id === option._id
                            )
                              ? ""
                              : "transparent",
                            cursor: "pointer",
                          }}
                        >
                          <div style={{ color: "#6C8C91", fontSize: "16px" }}>
                            {option.name}
                          </div>
                          {selectedOptions.some(
                            (selected) => selected._id === option._id
                          ) && (
                              <span>
                                <AiOutlineCheckCircle
                                  style={{ color: "#6C8C91" }}
                                />
                              </span>
                            )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                {selectedOptionsError && (
                  <div
                    style={{
                      color: "red",
                      fontFamily: "outfit",
                      fontSize: "13px",
                      fontWeight: "500",
                    }}
                  >
                    Error: select options
                  </div>
                )}
                <div style={{ marginTop: "1rem", overflow: "auto", height: "20rem" }}>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{ marginTop: "1rem" }}
                        >
                          {selectedOptions.map((item, index) => (

                            <Draggable
                              key={item._id}
                              draggableId={String(item._id)}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={{
                                    ...provided.draggableProps.style,
                                    borderRadius: "5px",
                                    color: "#B4B8AC",
                                  }}
                                  className="d-flex align-self-center align-items-center py-2 my-2  flex-row border p-1 justify-content-between"
                                >
                                  <div className="d-flex gap-1">
                                    <BsThreeDotsVertical
                                      style={{ color: "black" }}
                                    />
                                    <div
                                      className=""
                                      style={{ fontSize: "13px", color: "#406065" }}
                                      data-toggle="tooltip" data-placement="top" title={item.name.length > 6 ? item.name : ""}

                                    >
                                      {item.name.length > 6 ? item.name.slice(0, 6) + "..." : item.name}
                                    </div>
                                  </div>

                                  <div className="d-flex gap-2  align-self-center align-items-center">
                                    <div style={{
                                      backgroundColor: "#D9E5E6",
                                      fontSize: "11px",
                                      padding: "3px 14px 3px 14px",
                                      borderRadius: "10px",
                                      color: "#08536B",
                                      cursor: "pointer",
                                    }}>
                                      {item.displayName}
                                    </div>
                                    {/* <MdCancel
                                    style={{
                                      height: "1.3rem",
                                      width: "1.3rem",
                                      color: "#B4B8AC",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleCancelClick(item.name)}
                                  /> */}
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <div className="d-flex flex-row justify-content-end gap-4">
              <div
                className="px-3 fs-6 py-2"
                style={{
                  borderColor: "#015169",
                  borderStyle: "outset",
                  borderWidth: "1px",
                  borderRadius: "6px",
                  cursor: "pointer",
                  color: "#015169",
                }}
                onClick={() => {
                  setShow({
                    add: false,
                    edit: false,
                    delete: false,
                    view: false
                  });
                  setIsOpen(false)
                  setSelectedOptions([]);
                  setSelectedItems([]);
                  SetheadingEdit(true);
                  setCategoryName({});
                  setDropDownData([]);
                  setEditID();
                  setProjectCheckBox(false)
                  setDropDownError(false);
                  setSelectionError(false);
                  setCategoryNameError(false);
                }}
              >
                Cancel
              </div>
              {/* <div
                className="px-3 fs-6 py-2"
                style={{
                  background: "#015169",
                  borderRadius: "6px",
                  cursor: "pointer",
                  color: "#FFFFFF",
                }}
                onClick={() => {
                  CategoryName &&
                    // selectedItems?.length > 0 || ProjectCheckBox &&
                    selectedOptions?.length > 0 &&
                    EditHEadingAPI({
                      name: CategoryName,
                      categoryId: brandCategoriesTab?._id,
                      selectedId: selectedOptions.map((v) => v._id),
                      selectedType: ProjectCheckBox ? ['Projects'] : selectedItems
                    });
                  setCategoryNameError(CategoryName ? false : true);
                  setSelectionError(selectedItems?.length > 0 || ProjectCheckBox ? false : true);
                  setDropDownError(selectedOptions?.length > 0 ? false : true);
                }}
              >
                Save
              </div> */}
            </div>
          </Modal.Footer>
        </Modal>


      </div>
    </div>
  ) : (
    <div className="d-flex justify-content-center">
      <Loader />
    </div>
  );
};

export default CategoriesPage;
