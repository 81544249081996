import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getCurrentUser,
  getData,
  handleDashboardNewResponse,
  putGetData,
} from "../../api";
import { MdDelete, MdEdit } from "react-icons/md";
import Search from "../../components/Search/search";
import Select from "../../components/Form/Select";
import ReactPaginate from "react-paginate";
import {
  HiOutlineChevronDoubleLeft,
  HiOutlineChevronDoubleRight,
} from "react-icons/hi";
import {
  IoChevronBackOutline,
  IoChevronForwardOutline,
  IoEyeSharp,
} from "react-icons/io5";
import { Form, Modal } from "react-bootstrap";
import { CustomerDeleteWarningPopUp } from "../Customer/CustomerTable/CustomerDeleteWarningPopUp";
import Loader from "../../components/Loader";
import { useSelector } from "react-redux";
import { IoMdClose } from "react-icons/io";
import FormModal from "../../components/Modal";
import { BsFillEyeFill } from "react-icons/bs";
import { FaPen, FaTrash } from "react-icons/fa";

const Projects = () => {
  const bearerTokenLocal = getCurrentUser()?.token;
  const loginPermission = useSelector((state) => state.login.userPermission);
  const menuId = useSelector((state) => state.login.menuId);
  let addpermission = loginPermission.includes(menuId.addProject);
  let viewpermission = loginPermission.includes(menuId.viewProject);
  let deletePermission = loginPermission.includes(menuId.deleteProject);
  let editProjectPermission = loginPermission.includes(menuId.editProject);
  let projectEnableDisablePermission = loginPermission.includes(
    menuId.projectEnableDisable
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");

  const [DeveloperbyNameFilter, setDeveloperbyNameFilter] = useState();
  const [SortBy, setSortBy] = useState();
  const [developerdata, setdeveloperData] = useState([]);
  const [projectAdminData, setProjectAdminData] = useState({
    projectList: [],
    total: 0,
  });
  const [limit, setLimit] = useState(10);
  const [deleteID, setDeleteID] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [updateDate, setUpdateDone] = useState(false);
  const sortby = [
    { value: "asc", name: "Oldest to Newest " },
    { value: "desc", name: "Newest to Oldest" },
  ];

  const generateUrl = (base, params) => {
    const query = Object.keys(params)
      .filter((key) => params[key] !== undefined && params[key] !== "")
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
      )
      .join("&");
    return `${base}?${query}`;
  };

  // get project
  const GetProjectAdmin = async (page, limit, search, sort, developerId) => {
    try {
      setLoader(true);
      const url = generateUrl("/project/getProjectsAdmin", {
        pageNo: page,
        limit,
        search,
        sort,
        developerId,
      });

      const getList = await getData(url, {}, {});
      const { statusCode, data } = getList;
      if (statusCode === 200) {
        setLoader(false);
        return data?.projectList?.length > 0
          ? data
          : { projectList: [], total: 0 };
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (e) {
      console.log(e, "error");
      setLoader(false);
      return { projectList: [], total: 0 };
    }
  };
  // get developer
  const GetDeveloper = async () => {
    try {
      const url = `/project/getProjectDeveloper`;
      const getList = await getData(url, {}, {});
      const { statusCode, data } = getList;
      if (statusCode === 200) {
        const newData = data?.uniqueDevelopers?.map((value) => {
          return {
            value: value?._id,
            label: value?.name,
          };
        });
        setdeveloperData(newData);
      }
    } catch (e) {
      console.log(e, "error");
    }
  };
  const UpdateStates = async (id, body) => {
    try {
      const url = `/project/changeStatus/${id}`;
      const getList = await handleDashboardNewResponse(url, body);
      const { statusCode, data } = getList;
      if (statusCode == 200) {
        GetProjectAdmin();
        setUpdateDone(true);
        setShowModal(false);
        setDeleteID(null);
        setValueActiveModal({});
        setActiveModal(false);
      } else {
        // setUpdateDone(true)
        setShowModal(false);
        setDeleteID(null);
        setValueActiveModal({});
        setActiveModal(false);
      }
    } catch (e) {
      console.log(e, "error");
    }
  };

  const deleteData = async (id, body) => {
    try {
      const url = `/project/deleteProject/${id}`;
      const getList = await putGetData(url, bearerTokenLocal, body);
      const { statusCode, data } = getList;
      if (statusCode === 200) {
        setUpdateDone(true);
        setShowModal(false);
        setDeleteID(null);
      }
    } catch (e) {
      console.log(e, "error");
    }
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const debouncedSearch = useCallback(
    debounce((searchTerm) => {
      setSearch(searchTerm);
    }, 1000),
    []
  );

  useEffect(() => {
    const fetchData = async () => {
      const data = await GetProjectAdmin(
        currentPage,
        limit,
        search,
        SortBy,
        DeveloperbyNameFilter
      );
      setProjectAdminData(data);
    };
    fetchData();
    GetDeveloper();
    if (updateDate) {
      fetchData();
      setUpdateDone(false);
    }
  }, [currentPage, limit, search, SortBy, DeveloperbyNameFilter, updateDate]);

  // useEffect(() => {

  // }, [updateDate])

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber.selected + 1);
  };

  const handleFirstPage = () => {
    setCurrentPage(1);
  };

  const handleLastPage = () => {
    setCurrentPage(
      projectAdminData.total ? Math.ceil(projectAdminData.total / limit) : 1
    );
  };

  const navigate = useNavigate();
  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    console.log(searchTerm, "aman");
    debouncedSearch(searchTerm);
  };

  const [viewPT, setViewPt] = useState(false);
  const [ViewPTData, setPtData] = useState([]);
  console.log(viewPT, ViewPTData, "viewPt");
  const handleModalData = (value, bool) => {
    setViewPt(bool);
    setPtData(value);
  };

  const handlePerPage = (value) => {
    setLimit(Number(value)); // Update the rows per page limit
    setCurrentPage(1); // Reset to first page when limit changes
  };

  const TableRows = [5, 10, 20, 50]; // Define rows per page options

  const startItem = (currentPage - 1) * limit + 1;
  const endItem = Math.min(currentPage * limit, projectAdminData.total);

  const [ActiveModal, setActiveModal] = useState(false);
  const [ValueActiveModal, setValueActiveModal] = useState({});
  console.log({ ValueActiveModal });
  const handleOpenActiveModal = (value) => {
    console.log({ value });
    setActiveModal(true);
    setValueActiveModal({
      isActive: value?.isActive,
      _id: value?._id,
    });
  };
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <h3 style={{ color: "#004A6E" }}>Project</h3>
        <div
          className="py-2 px-3 rounded-2  m-3"
          style={{
            background: "#004A6E",
            color: "white",
            fontWeight: "bold",
            cursor: "pointer",
            opacity: !addpermission && "10%",
          }}
          onClick={
            addpermission
              ? () => {
                  handleNavigation("/project/CreateProject");
                }
              : () => {}
          }
        >
          Add New Project
        </div>
      </div>
      <div className="">
        <FormModal
          show={viewPT}
          onClose={setViewPt}
          heading={"Physical Team"}
          // customer={false}
          children={
            <div className="d-flex flex-column align-items-start">
              {ViewPTData?.physicalTeam?.map((v, i) => (
                <div
                  key={i}
                  className="w-100"
                  style={{ fontWeight: "400", marginBottom: "10px" }}
                >
                  <div className="d-flex flex-column">
                    <div>
                       {v.name}
                    </div>
                    <div
                      className="border-bottom"
                      style={{ margin: "10px 0" }}
                    ></div>
                  </div>
                </div>
              ))}
            </div>
          }
          size={""}
        />
      </div>

      <div class="container  p-3 rounded-2 " style={{ background: "white" }}>
        <div className="d-flex py-2 justify-content-end  gap-3 align-self-center align-items-center">
          <div className="row row-cols-3 ">
            <Select
              options={developerdata}
              placeholder={"Developer "}
              onChange={(e) => setDeveloperbyNameFilter(e.target.value)}
            />
            <Select
              options={sortby}
              onChange={(e) => setSortBy(e.target.value)}
              placeholder={"Sort By"}
            />
            <Search onChange={handleSearch} value={search} />
          </div>
        </div>
        <div class="table-wrapper rounded-2 rounded   ">
          {!loader ? (
            <table class="table   border-success-subtle">
              <thead class="thead-dark">
                <tr>
                  <th scope="col">Project ID</th>
                  <th scope="col">Project Name</th>
                  <th scope="col">Developer</th>
                  <th scope="col">POC</th>
                  <th scope="col">City</th>
                  <th scope="col">Physical Team</th>
                  <th scope="col">RM</th>
                  <th scope="col">Created On</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              {projectAdminData?.projectList?.map((value, index) => {
                console.log(value.isActive, "value.isActive");
                const isoDateString = value?.createdAt;

                // Parse the ISO date string into a Date object
                const date = new Date(isoDateString);

                // Format the date into the desired format
                const formattedDate = date.toLocaleString("en-US", {
                  month: "numeric",
                  day: "numeric",
                  year: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  second: "numeric",
                  hour12: true,
                });
                function getTextBeforeFirstComma(input) {
                  const commaIndex = input.indexOf(",");
                  if (commaIndex !== -1) {
                    return input.substring(0, commaIndex).trim();
                  }

                  return input.trim();
                }
                return (
                  <tbody key={index}>
                    <tr
                      style={{
                        borderBottom: "1px solid #004A6E",
                        background: "white",
                        fontWeight: "bold",
                      }}
                    >
                      <th scope="row">{value?.projectId}</th>
                      <td>{value?.projectName}</td>
                      <td>{value?.developerName}</td>
                      <td>{value?.developerPoc}</td>
                      <td
                        data-toggle="tooltip"
                        data-placement="top"
                        style={{ cursor: "pointer" }}
                        title={value?.city}
                      >
                        {/* <button type="button"   > */}

                        {getTextBeforeFirstComma(value?.city)}
                        {/* </button> */}
                      </td>
                      <td
                        className=""
                        onClick={() => {
                          if (value?.physicalTeam?.length > 1) {
                            handleModalData(value, true);
                          }
                        }}
                        style={{
                          cursor:
                            value?.physicalTeam?.length > 1
                              ? "pointer"
                              : "default",
                          fontWeight:
                            value?.physicalTeam?.length > 1 ? "" : "normal",
                        }}
                      >
                        {value?.physicalTeam?.length > 1 ? (
                          <>
                            {getTextBeforeFirstComma(
                              value.physicalTeam[0].name
                            )}
                            ...
                            <span style={{ color: "black", fontWeight: "bold" }}>
                              (View all)
                            </span>
                          </>
                        ) : (
                          value?.physicalTeam?.map((v, i) => (
                            <span key={i}>{v.name}</span>
                          ))
                        )}
                      </td>
                      <td>{value?.rmName}</td>

                      <td>{formattedDate}</td>
                      <td className="d-flex gap-3  justify-content-center align-self-center align-items-center">
                        <FaPen
                          style={{
                            cursor: editProjectPermission && "pointer",
                            opacity: !editProjectPermission && "30%",
                            color:"black"
                          }}
                          onClick={
                            editProjectPermission
                              ? () => {
                                  handleNavigation(`/project/${value?._id}`);
                                }
                              : () => {}
                          }
                          size={14}
                        />

                        <BsFillEyeFill
                          style={{
                            cursor: viewpermission && "pointer",
                            opacity: !viewpermission && "30%",
                            color:"black"
                          }}
                          onClick={
                            viewpermission
                              ? () => {
                                  handleNavigation(
                                    `/viewproject/${value?._id}`
                                  );
                                }
                              : () => {}
                          }
                          size={18}
                        />
                          <FaTrash
                          style={{
                            cursor: deletePermission && "pointer",
                            opacity: !deletePermission && "30%",
                            color:'black'
                          }}
                          size={16}
                          onClick={
                            deletePermission
                              ? () => {
                                  setDeleteID(value?._id);
                                  setShowModal(true);
                                }
                              : () => {}
                          }
                        />

                        <Form.Check
                          style={{
                            cursor: projectEnableDisablePermission && "pointer",
                            // fontSize: "1.2rem",
                            opacity: !projectEnableDisablePermission && "30%",
                          }}
                          size={18}
                          type="switch"
                          id={`custom-switch_${index}}`}
                          checked={value.isActive}
                          onChange={
                            projectEnableDisablePermission
                              ? (e) => {
                                  handleOpenActiveModal(value);
                                }
                              : () => {}
                          }
                        />
                       
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            </table>
          ) : (
            <>
              <Loader />
            </>
          )}
        </div>

        <CustomerDeleteWarningPopUp
          show={showModal}
          title={"Confirmation"}
          message={"Are you sure, you want to delete this project"}
          warningYesClick={deleteID ? () => deleteData(deleteID) : () => {}}
          onClose={() => {
            setShowModal(false);
            setDeleteID(null);
          }}
        />

        <CustomerDeleteWarningPopUp
          show={ActiveModal}
          title={"Confirmation"}
          message={` ${
            ValueActiveModal.isActive
              ? "Are you sure you want to deactivate this project"
              : "Are you sure you want activate this project"
          } `}
          warningYesClick={
            ValueActiveModal
              ? () =>
                  UpdateStates(ValueActiveModal._id, {
                    isActive: !ValueActiveModal.isActive,
                  })
              : () => {}
          }
          onClose={() => {
            setActiveModal(false);
            setValueActiveModal(null);
          }}
        />

        <div
          className="d-flex  justify-content-end p-2"
          style={{ backgroundColor: "#7FA2B829" }}
        >
          <div className="d-flex align-items-center w-80 flex-shrink-1">
            <label className="page">Rows per page: &nbsp;</label>
            <div>
              <Form.Select
                aria-label="Default select example"
                value={limit}
                onChange={(e) => handlePerPage(e.target.value)}
              >
                {TableRows.map((rows, index) => (
                  <option key={index} value={rows}>
                    {rows}
                  </option>
                ))}
              </Form.Select>
            </div>
          </div>
          <div className="pagenationwrapper d-flex justify-content-end">
            <div className="mr-2 text-end p-2 page pl-5 d-flex justify-content-end align-items-center page-count">
              {`${startItem} - ${endItem} of ${projectAdminData.total}`}
            </div>
            <div className="d-flex align-items-start justify-content-end page-between flex-shrink-1">
              <div className="page__arrow" onClick={handleFirstPage}>
                <HiOutlineChevronDoubleLeft
                  size={15}
                  style={{ color: "black" }}
                />
              </div>
              <ReactPaginate
                breakLabel=""
                nextLabel={<IoChevronForwardOutline size={15} />}
                marginPagesDisplayed={0}
                pageRangeDisplayed={4}
                pageCount={Math.ceil(projectAdminData.total / limit)}
                previousLabel={<IoChevronBackOutline size={15} />}
                pageClassName="page"
                pageLinkClassName="page"
                previousClassName="page"
                previousLinkClassName="page"
                nextClassName="page"
                nextLinkClassName="page"
                activeClassName="pagination-active"
                onPageChange={handlePageChange}
                forcePage={currentPage - 1}
              />
              <div className="page__arrow" onClick={handleLastPage}>
                <HiOutlineChevronDoubleRight
                  size={15}
                  style={{ color: "black" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
