import { useContext, useEffect, useReducer, useState } from "react";

//  components and function
import Search from "../../../../../components/Search/search";
import MultiSelect from "../../../../../components/select/dropDown";
import { convertIntoNameLabelObject, nameToLabel } from "../../../../../utilities/commonFunction";
import { filterArraySeller } from "../../filteroptions";
import { getFilterPayLoad, getFilterURL } from "./sellerFormatter";
import { SellerContext } from "./sellerReport";
import DatePicker from "react-datepicker";
// library
import { Col } from "react-bootstrap";

// api
import { postGetData } from "../../../../../api";
import Button from "../../../../../components/Form/Button";

export const Filter = ({ page, setOffset,onClickExportData }) => {
  const { filterDispatch, filterValues, setViewCities, } =
    useContext(SellerContext);
 
    
  const initialState = {
    companyList: [],
    developerList: [],
    brandList: [],
    cityList: [],
    regionList: [],
    pocList: [],
    propertyTypeList: [],
    supplierList: [],
    sortByList: [
      { label: "Sort by", value: "select" },
    ],
    createdByList: [
      { label: "Created date", value: "select" },
    ],
    contactedList: [{ label: "Contacted date", value: "select" }],
    eventNameList: [{ label: "Events ", value: "select" }],
  };
  const reducer = (state, action) => {
    const stateKeys = {
      COMPANYLIST: "companyList",
      DEVELOPERLIST: "developerList",
      BRANDLIST: "brandList",
      CITYLIST: "cityList",
      REGIONLIST: "regionList",
      POCLIST: "pocList",
      PROPERTYTYPELIST: "propertyTypeList",
      SUPPLIERTYPELIST: "supplierList",
      SORTBYLIST: "sortByList",
      CREATEDLIST: "createdByList",
      CONTACTEDLIST:"contactedList",
      EventNameList: "eventNameList",
    };

    const stateKey = stateKeys[action.type];
    if (!stateKey) {
      return state;
    }
  

    return {
      ...state,
      [stateKey]: [...state[stateKey], ...action.value],
    };
  };

  const [filter, reducerDispatch] = useReducer(reducer, initialState);
  const [filterInputs, setFilterInputs] = useState([]);

  const onClickViewCities = (value) => {
    setViewCities({ show: true, value: value });
  };


  const setOptions = (key, data) => {
    let finalValue;
    if (key === "eventNameList") {
      finalValue = nameToLabel(data);
    } else {
      finalValue = nameToLabel(data);
    }
    const filterKey = {
      developer: () => reducerDispatch({ type: "DEVELOPERLIST", value: finalValue }),
      cities: () => reducerDispatch({ type: "CITYLIST", value: finalValue }),
      property:()=>reducerDispatch({ type: "PROPERTYTYPELIST", value: finalValue }),
      poc:()=>reducerDispatch({ type: "POCLIST", value: finalValue }),
      sort:()=>reducerDispatch({ type: `${["cityPocList","developerPocList","developerList",].includes(page)?"SORTBYLIST":
      ["propertyContactedList",
      "propertyAcceptedList",
      "propertyRejectedList",
      "needMoreInfoList"].includes(page)?"CONTACTEDLIST":"CREATEDLIST"}`, value: finalValue }),
      supplier:()=>reducerDispatch({ type: "SUPPLIERTYPELIST", value: finalValue }),
      companies:()=>reducerDispatch({ type: "COMPANYLIST", value: finalValue }),
      brand:()=>reducerDispatch({ type: "BRANDLIST", value: finalValue }),
      eventNameList: () =>
        reducerDispatch({ type: "EventNameList", value: finalValue }),
    };
    return filterKey[key];
  };

  const getFilterData = async (array) => {
    let url = getFilterURL(page);
    let payLoad = getFilterPayLoad(page);
    const apiDatas = await postGetData(url, {}, payLoad);
    const { data, statusCode } = apiDatas;
    if (statusCode === 200) {
      array?.map((item) => {
        if (data?.hasOwnProperty(item?.key)) {
          let returnfn = setOptions(item?.key, data[item?.key]);
          returnfn();
        }
        return item
      });
    } 
   
    
  };

  const handelSearch = (e) => {
    setOffset(1);
    filterDispatch({
      type: "search",
      value: e.target.value,
    });
  };
  const onChangeSelect = (val, item) => {
    setOffset(1);
    filterDispatch({
      value: val,
      type: item?.name,
      isMulti: item.isMulti ? "yes" : "no",
    });
  };
  const onClickExport = () => {
    onClickExportData()
  }
  useEffect(() => {
    if (page) {
      let filterValue = [...filterArraySeller].filter(
        (item) => item.tableName === page
      )?.[0]?.filters;
      getFilterData(filterValue);
      setFilterInputs(filterValue);
    }
  }, [page]);
  return (
    <>
      {filterInputs?.length > 0 &&
        filterInputs.map((item,i) => {
          if (item?.fieldType === "search") {
            return (
              <>
                <Col md={3} sm={6} className="d-flex align-items-start pt-3" key={i}  style={{marginTop:"-8px"}}>
                  <Search
                    size="search-input-width mb-3 "
                    onChange={handelSearch}
                    placeholder="Search"
                    value={filterValues[item?.name]}
                  />
                </Col>
              </>
            );
          } else if (item?.fieldType === "select") {
            let value = item.isMulti
              ? Object.keys(filterValues)?.length > 0 &&
                filterValues[item?.name]?.length > 0
                ? filterValues[item?.name]
                : []
              : Object.keys(filterValues)?.length > 0 &&
                filterValues[item?.name]?.value
              ? filterValues[item?.name]
              : item?.defaultValue[0];

            return (
              
                <Col md={3} sm={6} className="d-flex align-items-start " key={i}>
                  <MultiSelect
                    disabled={false}
                    onChange={(val) => onChangeSelect(val, item)}
                    isMulti={item.isMulti}
                    name={""}
                    size={"select-multi"}
                    options={filter[item?.name]}
                    value={value}
                    lable={""}
                    placeholder={item?.defaultValue[0]?.label}
                    view={item?.name === "regionList" ? true : false}
                    onClickViewList={(viewValue) => onClickViewCities(viewValue)}
                    viewAllString={true}
                  />
                </Col>
             
            );
          } 
          else if (item?.fieldType === "datePicker") {
            return (
              <Col
                md={3}
                sm={6}
                className="d-flex flex-column justify-content-center align-items-center"
                style={{marginTop: "-12px"}}
                key={i}
              >
                <div>
                  {item?.name === "startDate" ? "Start Date" : "End Date"}
                </div>
                <div className="">
                  {item.name === "startDate" ? (
                    <DatePicker
                      showIcon
                      selected={filterValues[item.name] ?? new Date()}
                      onChange={(val) => onChangeSelect(val, item)}
                      selectsStart
                      startDate={filterValues["startDate"] ?? new Date()}
                      endDate={filterValues["endDate"] ?? new Date()}
                      maxDate={new Date()}
                    />
                  ) : (
                    <DatePicker
                      showIcon
                      selected={filterValues[item.name] ?? new Date()}
                      onChange={(val) => onChangeSelect(val, item)}
                      selectsEnd
                      startDate={filterValues["startDate"] ?? new Date()}
                      endDate={filterValues["endDate"] ?? new Date()}
                      minDate={filterValues["startDate"] ?? new Date()}
                      maxDate={new Date()}
                    />
                  )}
                </div>
              </Col>
            );
          }
          else if(item?.fieldType === "export"){
            return(
            
             <Col md={3} sm={6} className="d-flex align-items-start mt-2 " key={i} >
             <Button children={item?.name} className={"btn-primary w-75"} onClick={onClickExport}/>
             </Col>
            
            )
           }
          return null;
        })}
    </>
  );
};
