import { useEffect, useState } from "react";

import { FaPen } from "react-icons/fa";
import { getData } from "../../api";
import Button from "../../components/Form/Button";
import FormModal from "../../components/Modal";
import Search from "../../components/Search/search";
import MainTable from "../../components/Table";
import RoleContentModal from "./roleContentModal";

function ManageRoles() {
  const [search, setSearch] = useState("");

  const [roleList, setRoleList] = useState([]);
  const [roleModal, setRoleModal] = useState(false);
  const [rowId, setRowId] = useState();
  const [name, setName] = useState("");
  const [menuList, setMenuList] = useState([]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleRoles = (action, row) => {
    setRoleModal(true);
    setRowId(row._id);
    setName(action);
  };
  const getMenuList = async () => {
    let url = "/menu";

    const getList = await getData(url, {}, {});
    const { statusCode, data } = getList;
    if (statusCode === 200) {
      // setIsLoading(false);
      setMenuList(data);
    } else {
      setMenuList([]);
      // setIsLoading(false);
    }
  };
  function userButtonFormatter(cell, row, rowIndex) {
    return (
      <div className="d-flex justify-content-center">
        <Button
          className="button-none"
          onClick={() => {
            handleRoles("Edit Role", row);
          }}
          disabled={row?.isHandler}
        >
          <FaPen className="icon-pointer cursor-pointer " />
        </Button>
      </div>
    );
  }

  const getRoleList = async () => {
    let url = `/role/roletype?type=${"admin"}`;

    if (search) {
      url += `&search=${search}`;
    }
    // let url=`/user/getAllSubAdmin?pageNo=1&limit=2&role=All`;
    const getList = await getData(url, {}, {});
    const { statusCode, data } = getList;
    if (statusCode === 200) {
      setRoleList(data);
    } else {
      setRoleList([]);
    }
  };
  const columns = [
    {
      dataField: "sno",
      text: "S.no",
    },
    {
      dataField: "name",
      text: "Name",
    },
    {
      dataField: "isActive",
      text: "Action",
      formatter: userButtonFormatter,
    },
  ];

  const tableProps = {
    data: roleList,
    columns,
  };

  const roleContent = () => {
    return (
      <>
        {menuList?.length > 0 ? (
          <RoleContentModal
            roleId={rowId}
            apiCall={getRoleList}
            onClose={setRoleModal}
            menuList={menuList}
          />
        ) : (
          <center>No Data found</center>
        )}
      </>
    );
  };

  useEffect(() => {
    getMenuList();
  }, []);
  useEffect(() => {
    getRoleList();
  }, [search]);

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex justify-content-between align-items-center text-heading">
          <label title="Dashboard">Manage Roles</label>
        </div>

        <div className="d-flex mb-4 flexEnd_div">
          <div className="mx-3 manageWidthSearch">
            <Search onChange={handleSearch} value={search} />
          </div>
          <Button
            className="btn-color-primary"
            onClick={() => handleRoles("Add Role", {})}
          >
            Add Roles
          </Button>
        </div>
      </div>
      <div className="mt-4">
        <MainTable {...tableProps} />
      </div>
      <FormModal
        show={roleModal}
        onClose={setRoleModal}
        heading={name}
        children={roleContent()}
        size={"lg"}
      />
    </div>
  );
}

export default ManageRoles;
