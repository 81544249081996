export const buyerColumns = [
  {
    pageName: "companyList",
    data: [
      {
        dataField: "sno",
        text: "S.no",
        formatter: false,
      },
      {
        dataField: "companyName",
        text: "Company name",
        formatter: false,
      },
      {
        dataField: "cityAction",
        text: "City",
        formatter: true,
      },
      {
        dataField: "regionAction",
        text: "Region",
        formatter: true,
      },
      {
        dataField: "demandCount",
        text: "No.of demands",
        formatter: false,
      },
      {
        dataField: "companyButtonFormatter",
        text: "Action",
        formatter: true,
      },
    ],
  },
  {
    pageName: "companyPocList",
    data: [
      {
        dataField: "sno",
        text: "S.no",
        formatter: false,
      },

      { dataField: "companyName", text: "Company name", formatter: false },
      { dataField: "primary", text: "Primary POC", formatter: false },

      {
        dataField: "pocName",
        text: "POC Name",
        formatter: false,
      },
      {
        dataField: "designation",
        text: "Designation",
        formatter: false,
      },
      {
        dataField: "phoneNumber",
        text: "Phone Number",
        formatter: false,
      },
      {
        dataField: "email",
        text: "Email",
        formatter: false,
      },

      {
        dataField: "demandCount",
        text: "Demands posted",
        formatter: false,
      },

      {
        dataField: "pocActionForCompany",
        text: "Action",
        formatter: true,
      },
    ],
  },
  {
    pageName: "brandList",
    data: [
      {
        dataField: "sno",
        text: "S.no",
      },
      {
        dataField: "companyName",
        text: "Company name",
        formatter: true,
      },
      {
        dataField: "name",
        text: "Brand name",
        formatter: false,
      },

      {
        dataField: "cityAction",
        text: "City",
        formatter: true,
      },
      {
        dataField: "regionAction",
        text: "Region",
        formatter: true,
      },
      {
        dataField: "demandCount",
        text: "No of demands",
      },

      {
        dataField: "buyerButtonFormatter",
        text: "Action",
        formatter: true,
      },
    ],
  },

  {
    pageName: "demandPostedList",
    data: [
      {
        dataField: "sno",
        text: "S.no",
      },
      {
        dataField: "companyId.companyName",
        text: "Company name",
        formatter: false,
      },
      {
        dataField: "brandId.name",
        text: "Brand name",
        formatter: false,
      },
      {
        dataField: "demandIdFormatter",
        text: "Demand ID",
        formatter: true,
      },
      {
        dataField: "userId.name",
        text: "Submitted by",
        formatter: false,
      },
      {
        dataField: "propertyType.name",
        text: "Property type",
        formatter: false,
      },
      {
        dataField: "location",
        text: "City",
        formatter: false,
      },
      {
        dataField: "properSizeFormatter",
        text: "Requirement size",
        formatter: true,
      },
      {
        dataField: "createdAtAction",
        text: "Created on",
        formatter: true,
      },
      {
        dataField: "responseCount",
        text: "Demand responses",
        formatter: true,
      },
      {
        dataField: "isActive",
        text: "Active/In-Active",
        formatter: (cellContent, row, rowIndex) =>
          row?.isActive ? "Active" : "In-Active",
      },
      {
        dataField: "matchedProperty",
        text: "Matching properties",
        formatter: false,
      },
      {
        dataField: "matchedPropertyContacted",
        text: "Matching properties contacted",
        formatter: false,
      },
      {
        dataField: "demandButtonFormatter",
        text: "Action",
        formatter: true,
      },
    ],
  },
  {
    pageName: "activeDemandList",
    data: [
      {
        dataField: "sno",
        text: "S.no",
      },
      {
        dataField: "companyId.companyName",
        text: "Company name",
        formatter: false,
      },
      {
        dataField: "brandId.name",
        text: "Brand name",
        formatter: false,
      },
      {
        dataField: "demandIdFormatter",
        text: "Demand ID",
        formatter: true,
      },
      {
        dataField: "userId.name",
        text: "Submitted by",
        formatter: false,
      },
      {
        dataField: "propertyType.name",
        text: "Property type",
        formatter: false,
      },
      {
        dataField: "location",
        text: "City",
        formatter: false,
      },
      {
        dataField: "properSizeFormatter",
        text: "Requirement size",
        formatter: true,
      },
      {
        dataField: "createdAtAction",
        text: "Created on",
        formatter: true,
      },
      {
        dataField: "responseCount",
        text: "Demand responses",
        formatter: true,
      },
      {
        dataField: "isActive",
        text: "Active/In-Active",
        formatter: (cellContent, row, rowIndex) =>
          row?.isActive ? "Active" : "In-Active",
      },
      {
        dataField: "matchedProperty",
        text: "Matching properties",
        formatter: false,
      },
      {
        dataField: "matchedPropertyContacted",
        text: "Matching properties contacted",
        formatter: false,
      },
      {
        dataField: "demandButtonFormatter",
        text: "Action",
        formatter: true,
      },
    ],
  },
  {
    pageName: "inActiveDemandList",
    data: [
      {
        dataField: "sno",
        text: "S.no",
      },
      {
        dataField: "companyId.companyName",
        text: "Company name",
        formatter: false,
      },
      {
        dataField: "brandId.name",
        text: "Brand name",
        formatter: false,
      },
      {
        dataField: "demandIdFormatter",
        text: "Demand ID",
        formatter: true,
      },
      {
        dataField: "userId.name",
        text: "Submitted by",
        formatter: false,
      },
      {
        dataField: "propertyType.name",
        text: "Property type",
        formatter: false,
      },
      {
        dataField: "location",
        text: "City",
        formatter: false,
      },
      {
        dataField: "properSizeFormatter",
        text: "Requirement size",
        formatter: true,
      },
      {
        dataField: "createdAtAction",
        text: "Created on",
        formatter: true,
      },
      {
        dataField: "responseCount",
        text: "Demand responses",
        formatter: true,
      },
      {
        dataField: "isActive",
        text: "Active/In-Active",
        formatter: (cellContent, row, rowIndex) =>
          row?.isActive ? "Active" : "In-Active",
      },
      {
        dataField: "matchedProperty",
        text: "Matching properties",
        formatter: false,
      },
      {
        dataField: "matchedPropertyContacted",
        text: "Matching properties contacted",
        formatter: false,
      },
      {
        dataField: "demandButtonFormatter",
        text: "Action",
        formatter: true,
      },
    ],
  },
  {
    pageName: "demandResponseList",
    data: [
      {
        dataField: "sno",
        text: "S.no",
      },
      {
        dataField: "companyId.companyName",
        text: "Company name",
        formatter: false,
      },
      {
        dataField: "brandId.name",
        text: "Brand name",
        formatter: false,
      },
      {
        dataField: "demandIdFormatter",
        text: "Demand ID",
        formatter: true,
      },
      {
        dataField: "userId.name",
        text: "Submitted by",
        formatter: false,
      },
      {
        dataField: "propertyType.name",
        text: "Property type",
        formatter: false,
      },
      {
        dataField: "location",
        text: "City",
        formatter: false,
      },
      {
        dataField: "properSizeFormatter",
        text: "Requirement size",
        formatter: true,
      },
      {
        dataField: "createdAtAction",
        text: "Created on",
        formatter: true,
      },
      {
        dataField: "responseCount",
        text: "Demand responses",
        formatter: true,
      },
      {
        dataField: "isActive",
        text: "Active/In-Active",
        formatter: (cellContent, row, rowIndex) =>
          row?.isActive ? "Active" : "In-Active",
      },
      {
        dataField: "matchedProperty",
        text: "Matching properties",
        formatter: false,
      },
      {
        dataField: "matchedPropertyContacted",
        text: "Matching properties contacted",
        formatter: false,
      },
      {
        dataField: "demandButtonFormatter",
        text: "Action",
        formatter: true,
      },
    ],
  },
  {
    pageName: "brandPocList",
    data: [
      {
        dataField: "sno",
        text: "S.no",
        formatter: false,
      },
      {
        dataField: "name",
        text: "POC name",
        formatter: false,
      },
      {
        dataField: "companyName",
        text: "Company name",
        formatter: false,
      },
      {
        dataField: "brandName",
        text: "Brand name",
        formatter: false,
      },
      {
        dataField: "designation",
        text: "Designation",
        formatter: false,
      },
      {
        dataField: "email",
        text: "Email ID",
        formatter: false,
      },
      {
        dataField: "phoneNumber",
        text: "Phone number",
        formatter: false,
      },
      {
        dataField: "demandCount",
        text: "Demands posted",
        formatter: false,
      },

      {
        dataField: "pocAction",
        text: "Action",
        formatter: true,
      },
    ],
  },
  {
    pageName: "regionPocList",
    data: [
      {
        dataField: "sno",
        text: "S.no",
        formatter: false,
      },
      {
        dataField: "name",
        text: "POC name",
        formatter: false,
      },
      {
        dataField: "companyName",
        text: "Company name",
        formatter: true,
      },
      {
        dataField: "brandName",
        text: "Brand name",
        formatter: false,
      },
      {
        dataField: "regionAction",
        text: "Region",
        formatter: true,
      },

      {
        dataField: "designation",
        text: "Designation",
        formatter: false,
      },
      {
        dataField: "email",
        text: "Email ID",
        formatter: false,
      },
      {
        dataField: "phoneNumber",
        text: "Phone number",
        formatter: false,
      },
      {
        dataField: "demandCount",
        text: "Demands posted",
        formatter: false,
      },

      {
        dataField: "pocAction",
        text: "Action",
        formatter: true,
      },
    ],
  },
  {
    pageName: "propertyContactedList",
    data: [
      {
        dataField: "sno",
        text: "S.no",
        formatter: false,
      },
      {
        dataField: "supplierName.name",
        text: "Supplier name",
        formatter: false,
      },
      {
        dataField: "propertyIdFormatter",
        text: "Property ID",
        formatter: true,
      },
      {
        dataField: "projectNameFormatter",
        text: "Project name",
        formatter: true,
      },
      {
        dataField: "propertyType.name",
        text: "Property type",
        formatter: false,
      },

      {
        dataField: "city",
        text: "City",
        formatter: false,
      },
      {
        dataField: "supplierType",
        text: "Supplier type",
        formatter: false,
      },

      {
        dataField: "createdFormatter",
        text: "Contacted on",
        formatter: true,
      },
      {
        dataField: "brand",
        text: "Brand",
        formatter: false,
      },
      {
        dataField: "company.companyName",
        text: "Company",
        formatter: false,
      },
      {
        dataField: "pocName",
        text: "POC name",
        formatter: false,
      },
    ],
  },
  {
    pageName: "propertyAcceptedList",
    data: [
      {
        dataField: "sno",
        text: "S.no",
        formatter: false,
      },
      {
        dataField: "demandIdFormatter",
        text: "Demand ID",
        formatter: true,
      },
      {
        dataField: "supplierName.name",
        text: "Supplier name",
        formatter: false,
      },
      {
        dataField: "propertyIdFormatter",
        text: "Property ID",
        formatter: true,
      },
      {
        dataField: "propertyType.name",
        text: "Property type",
        formatter: false,
      },

      {
        dataField: "city",
        text: "City",
      },
      {
        dataField: "supplierType",
        text: "Supplier type",
      },

      {
        dataField: "acceptedAction",
        text: "Accepted on",
        formatter: true,
      },

      {
        dataField: "company.companyName",
        text: "Company",
        formatter: false,
      },
      {
        dataField: "brand",
        text: "Brand",
        formatter: false,
      },
      {
        dataField: "pocName",
        text: "POC name",
        formatter: false,
      },
    ],
  },

  {
    pageName: "propertyRejectedList",
    data: [
      {
        dataField: "sno",
        text: "S.no",
        formatter: false,
      },
      {
        dataField: "demandIdFormatter",
        text: "Demand ID",
        formatter: true,
      },
      {
        dataField: "supplierName.name",
        text: "Supplier name",
        formatter: false,
      },
      {
        dataField: "propertyIdFormatter",
        text: "Property ID",
        formatter: true,
      },
      {
        dataField: "propertyType.name",
        text: "Property type",
        formatter: false,
      },

      {
        dataField: "city",
        text: "City",
        formatter: false,
      },
      {
        dataField: "supplierType",
        text: "Supplier type",
        formatter: false,
      },

      {
        dataField: "rejectedFormatter",
        text: "Rejected on",
        formatter: true,
      },

      {
        dataField: "company.companyName",
        text: "Company",
        formatter: false,
      },
      {
        dataField: "brand",
        text: "Brand",
        formatter: false,
      },
      {
        dataField: "pocName",
        text: "POC name",
        formatter: false,
      },
    ],
  },

  {
    pageName: "needMoreInfoList",
    data: [
      {
        dataField: "sno",
        text: "S.no",
        formatter: false,
      },
      {
        dataField: "demandIdFormatter",
        text: "Demand ID",
        formatter: true,
      },
      {
        dataField: "supplierName.name",
        text: "Supplier name",
        formatter: false,
      },
      {
        dataField: "propertyIdFormatter",
        text: "Property ID",
        formatter: true,
      },
      {
        dataField: "propertyType.name",
        text: "Property type",
        formatter: false,
      },

      {
        dataField: "city",
        text: "City",
        formatter: false,
      },
      {
        dataField: "supplierType",
        text: "Supplier type",
        formatter: false,
      },

      {
        dataField: "contactedAction",
        text: "Contacted on",
        formatter: true,
      },

      {
        dataField: "company.companyName",
        text: "Company",
        formatter: false,
      },
      {
        dataField: "brand",
        text: "Brand",
        formatter: false,
      },
      {
        dataField: "pocName",
        text: "POC name",
        formatter: false,
      },
    ],
  },

  {
    pageName: "mailingLogs",
    data: [
      {
        dataField: "sno",
        text: "S.no",
        formatter: false,
      },
      // {
      //   dataField: "demandIdFormatter",
      //   text: "Demand ID",
      //   formatter: true,
      // },
      {
        dataField: "brandNameFormatterForMallingLog",
        text: "Brand name",
        formatter: true,
      },
      {
        dataField: "doneByUser.name",
        text: "POC name",
        formatter: false,
      },
      {
        dataField: "displayName",
        text: "Events",
        formatter: false,
      },
      {
        dataField: "propertyIdFormatterForMallingLogs",
        text: "Property ID",
        formatter: true,
      },

      // {
      //   dataField: "cityFormatterForActivityLog",
      //   text: "City",
      //   formatter: true,
      // },
      {
        dataField: "createdAtActionForActivityLog",
        text: "Timestamp",
        formatter: true,
      },
    ],
  },
];
