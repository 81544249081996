export const sellerColumns = [
  {
    pageName: "developerList",
    data: [
      {
        dataField: "sno",
        text: "S.no",
        formatter: false,
      },
      {
        dataField: "companyName",
        text: "Developer",
        formatter: false,
      },
      {
        dataField: "cityAction",
        text: "City",
        formatter: true,
      },
      {
        dataField: "propertyCount",
        text: "Property uploads",
        formatter: false,
      },

      {
        dataField: "developerAction",
        text: "Action",
        formatter: true,
      },
    ],
  },
  {
    pageName: "developerPocList",
    data: [
      {
        dataField: "sno",
        text: "S.no",
        formatter: false,
      },
      {
        dataField: "companyName",
        text: "Developer",
        formatter: false,
      },
      {
        dataField: "primaryPoc",
        text: "Primary POC",
        formatter: false,
      },
      {
        dataField: "name",
        text: "POC name",
        formatter: false,
      },
      {
        dataField: "designation",
        text: "Designation",
        formatter: false,
      },
      {
        dataField: "phoneNumber",
        text: "Phone number",
        formatter: false,
      },
      {
        dataField: "email",
        text: "Email",
        formatter: false,
      },
      {
        dataField: "propertyCount",
        text: "Property uploads",
        formatter: false,
      },
      {
        dataField: "developerPOCAction",
        text: "Action",
        formatter: true,
      },
    ],
  },
  {
    pageName: "cityPocList",
    data: [
      {
        dataField: "sno",
        text: "S.no",
        formatter: false,
      },

      {
        dataField: "companyName",
        text: "Developer",
        formatter: false,
      },
      {
        dataField: "name",
        text: "POC name",
        formatter: false,
      },
      {
        dataField: "cityAction",
        text: "City",
        formatter: true,
      },
      {
        dataField: "designation",
        text: "Designation",
        formatter: false,
      },
      {
        dataField: "phoneNumber",
        text: "Mobile number",
        formatter: false,
      },
      {
        dataField: "email",
        text: "Email",
        formatter: false,
      },
      {
        dataField: "propertyCount",
        text: "Property uploads",
        formatter: false,
      },
      {
        dataField: "developerPOCAction",
        text: "Action",
        formatter: true,
      },
    ],
  },
  {
    pageName: "propertyAcceptedList",
    data: [
      {
        dataField: "sno",
        text: "S.no",
        formatter: false,
      },

      {
        dataField: "brand",
        text: "Brand",
        formatter: false,
      },
      {
        dataField: "acceptBy",
        text: "Accepted by",
        formatter: false,
      },
      {
        dataField: "demandIdFormatter",
        text: "Demand ID",
        formatter: true,
      },
      {
        dataField: "propertyIdFormatter",
        text: "Property ID",
        formatter: true,
      },
      {
        dataField: "postedBy",
        text: "Posted by",
        formatter: false,
      },
      {
        dataField: "developerId.name",
        text: "Developer",
        formatter: false,
      },
      {
        dataField: "projectNameFormatter",
        text: "Project name",
        formatter: true,
      },
      {
        dataField: "propertyTypeFormatter",
        text: "Property type",
        formatter: true,
      },
      {
        dataField: "city",
        text: "City",
        formatter: false,
      },
      {
        dataField: "acceptedAction",
        text: "Accepted on",
        formatter: true,
      },
    ],
  },
  {
    pageName: "propertyRejectedList",
    data: [
      {
        dataField: "sno",
        text: "S.no",
        formatter: false,
      },

      {
        dataField: "brand",
        text: "Brand",
        formatter: false,
      },
      {
        dataField: "rejectedBy",
        text: "Rejected by",
        formatter: false,
      },
      {
        dataField: "demandIdFormatter",
        text: "Demand ID",
        formatter: true,
      },
      {
        dataField: "propertyIdFormatter",
        text: "Property ID",
        formatter: true,
      },
      {
        dataField: "postedBy",
        text: "Posted by",
        formatter: false,
      },
      {
        dataField: "developerId.name",
        text: "Developer",
        formatter: true,
      },
      {
        dataField: "projectNameFormatter",
        text: "Project name",
        formatter: true,
      },
      {
        dataField: "propertyTypeFormatter",
        text: "Property type",
        formatter: true,
      },
      {
        dataField: "city",
        text: "City",
        formatter: false,
      },
      {
        dataField: "rejectedAction",
        text: "Rejected on",
        formatter: true,
      },
    ],
  },
  {
    pageName: "needMoreInfoList",
    data: [
      {
        dataField: "sno",
        text: "S.no",
        formatter: false,
      },

      {
        dataField: "acceptBy",
        text: "Contacted by",
        formatter: false,
      },
      {
        dataField: "demandIdFormatter",
        text: "Demand ID",
        formatter: true,
      },
      {
        dataField: "propertyIdFormatter",
        text: "Property ID",
        formatter: true,
      },
      {
        dataField: "postedBy",
        text: "Posted by",
        formatter: false,
      },
      {
        dataField: "developerId.name",
        text: "Developer",
        formatter: true,
      },
      {
        dataField: "projectNameFormatter",
        text: "Project name",
        formatter: true,
      },
      {
        dataField: "propertyType.name",
        text: "Property type",
        formatter: true,
      },
      {
        dataField: "city",
        text: "City",
        formatter: true,
      },
      {
        dataField: "contactedAction",
        text: "Contacted on",
        formatter: true,
      },
    ],
  },
  {
    pageName: "propertyContactedList",
    data: [
      {
        dataField: "sno",
        text: "S.no",
        formatter: false,
      },

      {
        dataField: "brand",
        text: "Brand",
        formatter: false,
      },
      {
        dataField: "company.companyName",
        text: "Company",
        formatter: false,
      },
      {
        dataField: "pocName",
        text: "POC name",
        formatter: false,
      },
      {
        dataField: "supplierName.name",
        text: "Supplier name",
        formatter: false,
      },
      {
        dataField: "propertyIdFormatter",
        text: "Property ID",
        formatter: true,
      },
      {
        dataField: "projectNameFormatter",
        text: "Project name",
        formatter: true,
        // formatter: (cellContent, row, rowIndex) => row?.projectName?row?.projectName:"-",
      },
      {
        dataField: "propertyType.name",
        text: "Property type",
        formatter: false,
        // formatter: (cellContent, row, rowIndex) => row?.propertyType?.name,
      },
      {
        dataField: "city",
        text: "City",
        formatter: false,
      },
      {
        dataField: "createdAtAction",
        text: "Contacted on",
        formatter: true,
      },
    ],
  },
  {
    pageName: "propertyPostedList",
    data: [
      {
        dataField: "sno",
        text: "S.no",
        formatter: false,
      },

      {
        dataField: "developerId.name",
        text: "Developer",
        formatter: false,
      },
      {
        dataField: "listPropertyIdFormatter",
        text: "Property ID",
        formatter: true,
      },
      {
        dataField: "propertyTypeId.name",
        text: "Property type",
        formatter: false,
        // formatter: (cellContent, row, rowIndex) => row?.propertyType?.name,
      },
      {
        dataField: "cities",
        text: "City",
        formatter: false,
      },
      {
        dataField: "submittedBy.name",
        text: "Submitted by",
        formatter: false,
      },
      {
        dataField: "createdAtAction",
        text: "Created on",
        formatter: true,
      },

      // {
      //   dataField: "reponseCoutFormatter",
      //   text: "Properties Response",
      //   formatter: true,
      // },
      {
        dataField: "propertyResponse",
        text: "Properties Response",
        formatter: false,
      },
      {
        dataField: "isActive",
        text: "Active/In-Active",
        formatter: (cellContent, row, rowIndex) =>
          row?.isActive ? "Active" : "In-Active",
      },
      // {
      //   dataField: "matchingCountFormatter",
      //   text: "Matching Demands",
      //   formatter: true,
      // },
      // {
      //   dataField: "matchingContactedFormatter",
      //   text: "Matching Demands Contacted",
      //   formatter: true,
      // },
      {
        dataField: "matchingDemand",
        text: "Matching Demands",
        formatter: false,
      },
      {
        dataField: "matchingDemandContacted",
        text: "Matching Demands Contacted",
        formatter: false,
      },
      {
        dataField: "propertyListAction",
        text: "Action",
        formatter: true,
      },
    ],
  },
  {
    pageName: "propertyActiveList",
    data: [
      {
        dataField: "sno",
        text: "S.no",
        formatter: false,
      },

      {
        dataField: "developerId.name",
        text: "Developer",
        formatter: false,
      },
      {
        dataField: "listPropertyIdFormatter",
        text: "Property ID",
        formatter: true,
      },
      {
        dataField: "propertyTypeId.name",
        text: "Property type",
        formatter: false,
        // formatter: (cellContent, row, rowIndex) => row?.propertyType?.name,
      },
      {
        dataField: "cities",
        text: "City",
        formatter: false,
      },
      {
        dataField: "submittedBy.name",
        text: "Submitted By",
        formatter: false,
      },
      {
        dataField: "createdAtAction",
        text: "Created On",
        formatter: true,
      },

      // {
      //   dataField: "reponseCoutFormatter",
      //   text: "Properties Response",
      //   formatter: true,
      // },
      {
        dataField: "propertyResponse",
        text: "Properties Response",
        formatter: false,
      },
      {
        dataField: "isActive",
        text: "Active/In-Active",
        formatter: (cellContent, row, rowIndex) =>
          row?.isActive ? "Active" : "In-Active",
      },
      // {
      //   dataField: "matchingCountFormatter",
      //   text: "Matching Demands",
      //   formatter: true,
      // },
      {
        dataField: "matchingDemand",
        text: "Matching Demands",
        formatter: false,
      },
      // {
      //   dataField: "matchingContactedFormatter",
      //   text: "Matching Demands Contacted",
      //   formatter: false,
      // },
      {
        dataField: "matchingDemandContacted",
        text: "Matching Demands Contacted",
        formatter: false,
      },
      {
        dataField: "propertyListAction",
        text: "Action",
        formatter: true,
      },
    ],
  },
  {
    pageName: "propertyInActiveList",
    data: [
      {
        dataField: "sno",
        text: "S.no",
        formatter: false,
      },

      {
        dataField: "developerId.name",
        text: "Developer",
        formatter: false,
      },
      {
        dataField: "listPropertyIdFormatter",
        text: "Property ID",
        formatter: true,
      },
      {
        dataField: "propertyTypeId.name",
        text: "Property type",
        formatter: false,
        // formatter: (cellContent, row, rowIndex) => row?.propertyType?.name,
      },
      {
        dataField: "cities",
        text: "City",
        formatter: false,
      },
      {
        dataField: "submittedBy.name",
        text: "Submitted By",
        formatter: false,
      },
      {
        dataField: "createdAtAction",
        text: "Created On",
        formatter: true,
      },

      // {
      //   dataField: "reponseCoutFormatter",
      //   text: "Properties Response",
      //   formatter: true,
      // },
      {
        dataField: "propertyResponse",
        text: "Properties Response",
        formatter: false,
      },
      {
        dataField: "isActive",
        text: "Active/In-Active",
        formatter: (cellContent, row, rowIndex) =>
          row?.isActive ? "Active" : "In-Active",
      },
      // {
      //   dataField: "matchingCountFormatter",
      //   text: "Matching Demands",
      //   formatter: true,
      // },
      // {
      //   dataField: "matchingContactedFormatter",
      //   text: "Matching Demands Contacted",
      //   formatter: true,
      // },
      {
        dataField: "matchingDemand",
        text: "Matching Demands",
        formatter: false,
      },
      {
        dataField: "matchingDemandContacted",
        text: "Matching Demands Contacted",
        formatter: false,
      },

      {
        dataField: "propertyListAction",
        text: "Action",
        formatter: true,
      },
    ],
  },
  {
    pageName: "propertyResponseList",
    data: [
      {
        dataField: "sno",
        text: "S.no",
        formatter: false,
      },

      {
        dataField: "developerId.name",
        text: "Developer",
        formatter: false,
      },
      {
        dataField: "listPropertyIdFormatter",
        text: "Property ID",
        formatter: true,
      },
      {
        dataField: "propertyTypeId.name",
        text: "Property type",
        formatter: false,
        // formatter: (cellContent, row, rowIndex) => row?.propertyType?.name,
      },
      {
        dataField: "cities",
        text: "City",
        formatter: false,
      },
      {
        dataField: "submittedBy.name",
        text: "Submitted By",
        formatter: false,
      },
      {
        dataField: "createdAtAction",
        text: "Created On",
        formatter: true,
      },

      // {
      //   dataField: "reponseCoutFormatter",
      //   text: "Properties Response",
      //   formatter: true,
      // },
      {
        dataField: "propertyResponse",
        text: "Properties Response",
        formatter: false,
      },
      {
        dataField: "isActive",
        text: "Active/In-Active",
        formatter: (cellContent, row, rowIndex) =>
          row?.isActive ? "Active" : "In-Active",
      },
      // {
      //   dataField: "matchingCountFormatter",
      //   text: "Matching Demands",
      //   formatter: true,
      // },
      // {
      //   dataField: "matchingContactedFormatter",
      //   text: "Matching Demands Contacted",
      //   formatter: true,
      // },
      {
        dataField: "matchingDemand",
        text: "Matching Demands",
        formatter: false,
      },
      {
        dataField: "matchingDemandContacted",
        text: "Matching Demands Contacted",
        formatter: false,
      },
      {
        dataField: "propertyListAction",
        text: "Action",
        formatter: true,
      },
    ],
  },
  {
    pageName: "propertyPostedAgainstList",
    data: [
      {
        dataField: "sno",
        text: "S.no",
        formatter: false,
      },

      {
        dataField: "developerId.name",
        text: "Developer",
        formatter: false,
      },
      {
        dataField: "listPropertyIdFormatter",
        text: "Property ID",
        formatter: true,
      },
      {
        dataField: "propertyTypeId.name",
        text: "Property type",
        formatter: false,
        // formatter: (cellContent, row, rowIndex) => row?.propertyType?.name,
      },
      {
        dataField: "cities",
        text: "City",
        formatter: false,
      },
      {
        dataField: "submittedBy.name",
        text: "Submitted By",
        formatter: false,
      },
      {
        dataField: "createdAtAction",
        text: "Created On",
        formatter: true,
      },

      // {
      //   dataField: "reponseCoutFormatter",
      //   text: "Properties Response",
      //   formatter: true,
      // },
      {
        dataField: "propertyResponse",
        text: "Properties Response",
        formatter: false,
      },
      {
        dataField: "isActive",
        text: "Active/In-Active",
        formatter: (cellContent, row, rowIndex) =>
          row?.isActive ? "Active" : "In-Active",
      },
      {
        dataField: "matchingDemand",
        text: "Matching Demands",
        formatter: false,
      },
      {
        dataField: "matchingDemandContacted",
        text: "Matching Demands Contacted",
        formatter: false,
      },
      // {
      //   dataField: "matchingCountFormatter",
      //   text: "Matching Demands",
      //   formatter: true,
      // },
      // {
      //   dataField: "matchingContactedFormatter",
      //   text: "Matching Demands Contacted",
      //   formatter: true,
      // },
      {
        dataField: "demandIdFormatter",
        text: "Demand ID",
        formatter: true,
      },
      {
        dataField: "brand",
        text: "Brand Name",
        formatter: false,
      },
      {
        dataField: "company",
        text: "Company Name",
        formatter: false,
      },
      {
        dataField: "pocName.name",
        text: "POC Name",
        formatter: false,
      },
      {
        dataField: "propertyListAction",
        text: "Action",
        formatter: true,
      },
    ],
  },
  {
    pageName: "mailingLogs",
    data: [
      {
        dataField: "sno",
        text: "S.no",
        formatter: false,
      },
      // {
      //   dataField: "demandIdFormatter",
      //   text: "Demand ID",
      //   formatter: true,
      // },
      {
        dataField: "developerNameFormatterForMallingLog",
        text: "Developer name",
        formatter: true,
      },
      {
        dataField: "doneByUser.name",
        text: "POC name",
        formatter: false,
      },
      {
        dataField: "displayName",
        text: "Events",
        formatter: false,
      },
      {
        dataField: "demandIdFormatterForMallingLogs",
        text: "Demand ID",
        formatter: true,
      },

      // {
      //   dataField: "cityFormatterForActivityLog",
      //   text: "City",
      //   formatter: true,
      // },
      {
        dataField: "createdAtActionForActivityLog",
        text: "Timestamp",
        formatter: true,
      },
    ],
  },
];
