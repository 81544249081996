import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../../App";
import { getData } from "../../../../api";
import { PropertyCard } from "../../../../components/Cards/PropertyCard/propertyCard";
import MultiSelect from "../../../../components/select/dropDown";
import {
  getContextFilterData,
  performScrollEvent,
} from "../../../../utilities/pageScroll";
import PropertyDemandTab from "../../../../components/PropsertyDemandTabs";
import ReactDatePicker from "react-datepicker";
import { FaCalendar } from "react-icons/fa";
import {
  filteredTransactionOptions,
  labelValueOfObjects,
  nameToLabel,
} from "../../../../utilities/commonFunction";
import Loader from "../../../../components/Loader";
import { DemandCard } from "../../../../components/Cards/Demands";
import {
  PropertyTrancationOptions,
  brandTrancationOptions,
} from "../../../../utilities/transactionOptions";
import { ENTERPRISEAGENT, PHYSICALTEAM } from "../../../../custom/constants";
const CompletedProperty = (props) => {
  const navigate = useNavigate();
  const { pageScroll, setPageScroll } = useContext(AppContext);
  const scrollStoreArray = [...pageScroll];
  let contextFilterData = getContextFilterData(
    "myPageProperty",
    scrollStoreArray
  );
  let companyID = useSelector((state) => state.login.companyId);
  const fromPage = useSelector((state) => state.login.parentPage);
  const [apiCardDetail, setApiCardDetail] = useState([]);
  const [activeTab, setActiveTab] = useState("property");
  const [startDate, setStartDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [demandCardDetails, setDemandCardDetails] = useState([]);

  const cardDetail = [
    "propertyTypeId",
    "carpetArea",
    "endUse",
    "possession",
    "rentPsf",
    "tenure",
  ];
  const [cityFilterKeyValue, setCityFilterKeyValue] = useState(
    contextFilterData ?? { label: "City", value: "City" }
  );
  const [sortFilterKeyValue, setSortFilterKeyValue] = useState({
    label: "Sort",
    value: "sort",
  });
  const [transactionFilterKeyValue, setTransactionFilterKeyValue] = useState({
    label: "Transaction Type",
    value: "Transaction Type",
  });

  const [propertyTypeFilterKeyValue, setPropertyTypeFilterKeyValue] = useState({
    label: "Property Type",
    value: "Property Type",
  });
  const [filterMessage, setFilterMessage] = useState("");
  const [transactionTypeOptions, setTransactionTypeOptions] = useState([]);

  const getFilteredTransactionTypeProerty = () => {
    var filteredTransactionType = [];
    if (propertyTypeFilterKeyValue?.label === "Retail") {
      filteredTransactionType = filteredTransactionOptions(
        [...PropertyTrancationOptions?.retail],
        props?.usersTransactionType
      );
    } else if (propertyTypeFilterKeyValue?.label === "Office space") {
      filteredTransactionType = filteredTransactionOptions(
        [...PropertyTrancationOptions?.office],
        props?.usersTransactionType
      );
    } else if (propertyTypeFilterKeyValue?.label === "Flex Space") {
      filteredTransactionType = filteredTransactionOptions(
        [...PropertyTrancationOptions?.cowork],
        props?.usersTransactionType
      );
    } else {
      filteredTransactionType = filteredTransactionOptions(
        [...PropertyTrancationOptions?.retail],
        props?.usersTransactionType
      );
    }

    return filteredTransactionType;
  };

  const getFilteredTransactionTypeDemand = () => {
    var filteredTransactionType = [];
    if (propertyTypeFilterKeyValue?.label === "Retail") {
      filteredTransactionType = filteredTransactionOptions(
        [...brandTrancationOptions?.retail],
        props?.usersTransactionType
      );
    } else if (propertyTypeFilterKeyValue?.label === "Office space") {
      filteredTransactionType = filteredTransactionOptions(
        [...brandTrancationOptions?.office],
        props?.usersTransactionType
      );
    } else if (propertyTypeFilterKeyValue?.label === "Flex Space") {
      filteredTransactionType = filteredTransactionOptions(
        [...brandTrancationOptions?.cowork],
        props?.usersTransactionType
      );
    } else {
      filteredTransactionType = filteredTransactionOptions(
        [...brandTrancationOptions?.cowork],
        props?.usersTransactionType
      );
    }

    return filteredTransactionType;
  };

  useEffect(() => {
    if (activeTab === "property") {
      setTransactionTypeOptions(getFilteredTransactionTypeProerty());
    } else {
      setTransactionTypeOptions(getFilteredTransactionTypeDemand());
    }
  }, [propertyTypeFilterKeyValue, activeTab, props?.usersTransactionType]);

  useEffect(() => {
    if (transactionTypeOptions.length > 0) {
      const thisisLabel = labelValueOfObjects(transactionTypeOptions);
      const isObjectInArray = thisisLabel.some(
        (item) =>
          item.label === transactionFilterKeyValue.label &&
          item.value === transactionFilterKeyValue.value
      );

      if (!isObjectInArray) {
        setTransactionFilterKeyValue({
          label: "Transaction Type",
          value: "Transaction Type",
        });
      }
    }
  }, [transactionTypeOptions]);
  const getPropertyData = async () => {
    setLoading(true);
    setFilterMessage("");
    let url = `/property?pageNo=1&customerId=${props.id}&limit=0&isActive=false`;
    if (
      cityFilterKeyValue?.label !== "City" &&
      cityFilterKeyValue?.label !== ""
    ) {
      url += `&location=${cityFilterKeyValue?.label}`;
    }
    if (propertyTypeFilterKeyValue.value !== "Property Type") {
      url += `&propertyType=${propertyTypeFilterKeyValue?.value}`;
    }
    if (transactionFilterKeyValue.value !== "Transaction Type") {
      url += `&transactionType=${transactionFilterKeyValue?.value}`;
    }
    if (sortFilterKeyValue.value !== "sort") {
      url += `&sort=${sortFilterKeyValue?.value}`;
    }
    await getData(url, {}, {}).then((response) => {
      if (response.status) {
        setLoading(false);
        // apiDetail.push()
        setApiCardDetail(response.data.properties);
        if (
          cityFilterKeyValue?.label !== "City" &&
          cityFilterKeyValue?.label !== ""
        ) {
          if (response?.data?.properties?.length === 0) {
            setFilterMessage("No results found");
          }
        }
      } else {
        setLoading(false);
      }
    });
  };

  const getDemandData = async () => {
    setFilterMessage("");
    let url = `/profile/mydemandsForAgent?pageNo=0&customerId=${props.demandAgentId}&limit=0&isActive=false`;
    if (
      cityFilterKeyValue?.label !== "City" &&
      cityFilterKeyValue?.label !== ""
    ) {
      url += `&city=${cityFilterKeyValue?.label}`;
    }
    if (propertyTypeFilterKeyValue.value !== "Property Type") {
      url += `&propertyType=${propertyTypeFilterKeyValue?.value}`;
    }
    if (transactionFilterKeyValue.value !== "Transaction Type") {
      url += `&transactionType=${transactionFilterKeyValue?.value}`;
    }
    if (sortFilterKeyValue.value !== "sort") {
      url += `&sort=${sortFilterKeyValue?.value}`;
    }
    await getData(url, {}, {}).then((response) => {
      if (response.statusCode === 200 || response.status) {
        setDemandCardDetails(response.data.myDemands);
        if (
          cityFilterKeyValue?.label !== "City" &&
          cityFilterKeyValue?.label !== ""
        ) {
          if (response?.data?.properties?.length === 0) {
            setFilterMessage("No results found");
          }
        }
      }
    });
  };

  const getEAgentDemandData = async () => {
    setFilterMessage("");
    let url = `/demand/getDemands?pageNo=0&customerId=${props?.fromTab === ENTERPRISEAGENT ? props?.id : props.demandAgentId
      }&limit=0&isActive=false&featuredDemand=false`;
    if (
      cityFilterKeyValue?.label !== "City" &&
      cityFilterKeyValue?.label !== ""
    ) {
      url += `&city=${cityFilterKeyValue?.label}`;
    }
    if (propertyTypeFilterKeyValue.value !== "Property Type") {
      url += `&propertyType=${propertyTypeFilterKeyValue?.value}`;
    }
    if (transactionFilterKeyValue.value !== "Transaction Type") {
      url += `&transactionType=${transactionFilterKeyValue?.value}`;
    }
    if (sortFilterKeyValue.value !== "sort") {
      url += `&sort=${sortFilterKeyValue?.value}`;
    }
    await getData(url, {}, {}).then((response) => {
      if (response.statusCode === 200 || response.status) {
        setDemandCardDetails(response.data.demands);
        if (
          cityFilterKeyValue?.label !== "City" &&
          cityFilterKeyValue?.label !== ""
        ) {
          if (response?.data?.properties?.length === 0) {
            setFilterMessage("No results found");
          }
        }
      }
    });
  };

  const onchangeCityFilterKey = (val) => {
    setCityFilterKeyValue(val);
  };
  const onchangeSortFilterKey = (val) => {
    setSortFilterKeyValue(val);
  };

  const onchangeTransactionFilterKey = (val) => {
    setTransactionFilterKeyValue(val);
  };

  const onchangePropertyTypeFilterKey = (val) => {
    setPropertyTypeFilterKeyValue(val);
  };

  const onclickViewProperty = (a) => {
    props.setScrollEvent(a?._id);
    if (props?.fromTab === "agent") {
      localStorage.setItem("propertyValues", JSON.stringify(a));
      let url =
        fromPage === "agent"
          ? "/agentReport/agentList/agentPage/propertyDetailView/"
          : "/customer/agentPage/propertyDetailView/";
      navigate(`${url}${a.productId?.display}`, {
        state: { values: a, page: "customer" },
      });
    }
     else if (props?.fromTab === ENTERPRISEAGENT) {
      localStorage.setItem("propertyValues", JSON.stringify(a));
      let url =
        fromPage === "eagent"
          ? "/agentReport/agentList/agentPage/propertyDetailView/"
          : "/company/enterprise-agent/propertyDetailView/";
      navigate(`${url}${a.productId?.display}`, {
        state: { values: a, page: "eagent" },
      });
    } 
    
     else if (props?.fromTab === PHYSICALTEAM) {
      localStorage.setItem("propertyValues", JSON.stringify(a));
      let url =
        fromPage === PHYSICALTEAM
          ? "/physicalTeam/physicalTeamList/agentPage/propertyDetailView/"
          : "/company/physicalTeam/propertyDetailView/";
      navigate(`${url}${a.productId?.display}`, {
        state: { values: a, page: "eagent" },
      });
    } 
    
    else {
      localStorage.setItem("propertyValues", JSON.stringify(a));
      let url =
        fromPage === "seller"
          ? "/sellerReport/developerList/developerPage/propertyDetailView/"
          : "/customer/developerPage/propertyDetailView/";
      navigate(
        companyID === ""
          ? `${url}${a.productId?.display}`
          : `/company/developerCompanyCreation/developerPage/propertyDetailView/${a.productId?.display}`,
        { state: { values: a, page: "customer" } }
      );
    }
    localStorage.setItem("propTabKey", JSON.stringify("completedProperties"));
  };
  const onClickViewDemand = (a) => {
    props.setScrollEvent(a?._id, cityFilterKeyValue);
    localStorage.setItem("demandid", JSON.stringify(a?._id));
    localStorage.setItem("brandid", JSON.stringify(props?.brandId));
    localStorage.setItem("demandTabKey", JSON.stringify("ongoingDemands"));
    let url =
      fromPage === "buyer"
        ? "/buyerReport/brandList/brandPage/DemandDetailView/"
        : "/customer/brandPage/DemandDetailView/";
    let url2 =
      fromPage === "buyer"
        ? "/buyerReport/companyList/brandCompanyCreation/brandPage/DemandDetailView/"
        : "/company/brandCompanyCreation/brandPage/DemandDetailView/";
    let eagentUrl = "/company/enterprise-agent/DemandDetailView/";
    let agentUrl = "/customer/agentPage/DemandDetailView/";
    let physicalTeamUrl = "/company/physicalTeam/DemandDetailView/";
    if (props?.fromTab === "agent") {
      navigate(`${agentUrl}${a?.productId?.display}`, {
        state: { demandId: a?._id, brandId: props?.brandId },
      });
    } else if (props?.fromTab === ENTERPRISEAGENT) {
      navigate(`${eagentUrl}${a?.productId?.display}`, {
        state: { demandId: a?._id, brandId: props?.brandId },
      });
    }
    else if (props?.fromTab === PHYSICALTEAM) {
      navigate(`${physicalTeamUrl}${a?.productId?.display}`, {
        state: { demandId: a?._id, brandId: props?.brandId },
      });
    } 
    else {
      navigate(
        companyID === ""
          ? `${url}${a?.productId?.display}`
          : `${url2}${a?.productId?.display}`,
        { state: { demandId: a?._id, brandId: props?.brandId } }
      );
    }
    // navigate(
    //   companyID === ""
    //     ? `${url}${a?.productId?.display}`
    //     : `${url2}${a?.productId?.display}`,
    //   { state: { demandId: a?._id, brandId: props?.brandId } }
    // );
  };
  const demandBtnList = [
    {
      btnName: "View",
      onClick: (a) => onClickViewDemand(a),
      className: "btn-color-primary",
    },
  ];
  const btnList = [
    // {
    //   btnName: "Edit",
    //   onClick: (a) => onclickEditProperty(a),
    //   className: "btn-color-primary",
    // },
    {
      btnName: "View",
      onClick: (a) => onclickViewProperty(a),
      className: "btn-color-primary",
    },
  ];
  const btnListForDemand = [
    {
      btnName: "View",
      onClick: (a) => onclickViewProperty(a),
      className: "btn-color-primary",
    },
  ];
  useEffect(() => {
    if (apiCardDetail?.length > 0 && props.tabKey === "completedProperties") {
      let updatedArray = performScrollEvent("myPageProperty", scrollStoreArray);
      setPageScroll(updatedArray);
    }
  }, [apiCardDetail]);

  useEffect(() => {
    getPropertyData();
    if (props?.fromTab === "agent") {
      getDemandData();
    } else if (props?.fromTab === ENTERPRISEAGENT || props?.fromTab === PHYSICALTEAM  ) {
      getEAgentDemandData();
    }
  }, [
    cityFilterKeyValue,
    propertyTypeFilterKeyValue,
    transactionFilterKeyValue,
    sortFilterKeyValue,
  ]);
  let sortValues = [
    {
      label: "Sort",
      value: "sort",
    },
    {
      label: "Newest",
      value: "desc",
    },
    {
      label: "Oldest",
      value: "asc",
    },
  ];
  return (
    <>
      <div className="row mb-3 ">
        <div className="col col-xs-12 col-sm-12 col-md-4 col-lg-3 ">
          {(props?.fromTab === "agent" ||
            props?.fromTab === ENTERPRISEAGENT || props.fromTab === PHYSICALTEAM) && (
              <PropertyDemandTab
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            )}
        </div>
        <div className="col col-xs-12 col-sm-12 col-md-8 col-lg-9 mt-3 mt-md-0 mt-lg-0">
          <div className="d-flex flex-column flex-lg-row flex-md-row  align-items-center justify-content-end gap-3 propertyfilter">
            {/* <ReactDatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              placeholderText="Sort-Date"
              icon={<FaCalendar />}
              showIcon={true}
            /> */}
            <MultiSelect
              width="20%"
              onChange={(val) => {
                onchangeSortFilterKey(val);
              }}
              isMulti={false}
              options={[...sortValues]}
              value={sortFilterKeyValue}
            />
            <MultiSelect
              width="20%"
              onChange={(val) => {
                onchangeTransactionFilterKey(val);
              }}
              isMulti={false}
              options={[
                { label: "Transaction Type", value: "Transaction Type" },
                ...labelValueOfObjects(transactionTypeOptions),
              ]}
              value={transactionFilterKeyValue}
            />
            <MultiSelect
              width="20%"
              onChange={(val) => {
                onchangePropertyTypeFilterKey(val);
              }}
              isMulti={false}
              options={[
                { label: "Property Type", value: "Property Type" },
                ...nameToLabel(props?.PropertyTypeList),
              ]}
              value={propertyTypeFilterKeyValue}
            />
            <MultiSelect
              width="20%"
              onChange={(val) => {
                onchangeCityFilterKey(val);
              }}
              isMulti={false}
              options={[
                { label: "City", value: "City" },
                ...props?.searchCityList,
              ]}
              value={cityFilterKeyValue}
            />
          </div>
        </div>
      </div>
      {loading ? (
        <Loader />
      ) : activeTab === "property" ? (
        apiCardDetail.length > 0 ? (
          <div>
            <PropertyCard
              access={props?.access}
              apiCardDetail={apiCardDetail}
              cardDetail={cardDetail}
              image={props?.image?.viewUrl ? props.image.viewUrl : props.image}
              // onclick={onclickViewProperty}
              button={btnList}
            />
          </div>
        ) : (
          <center>
            {filterMessage ? filterMessage : "No inactive properties yet"}
          </center>
        )
      ) : demandCardDetails?.length > 0 ? (
        <>
          <div className="demand-card-container" id="demand-card">
            <DemandCard
              access={props?.access}
              demandDetail={demandCardDetails}
              response={true}
              button={demandBtnList}
              relevant={false}
            />
          </div>
        </>
      ) : (
        <center>
          {filterMessage ? filterMessage : "No inactive demands yet"}
        </center>
      )}
    </>
  );
};
export default CompletedProperty;
