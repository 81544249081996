import { useEffect, useReducer, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { BsFillEyeFill } from "react-icons/bs";
import { FaPen, FaTrash } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "../../components/Form/Select";
import Loader from "../../components/Loader/index";
import MainTable from "../../components/Table/index";

import { useContext } from "react";
import { AppContext } from "../../App";
import {
  AccessDenied,
  deleteData,
  getCurrentUser,
  getData,
  putData,
} from "../../api/index";
import Button from "../../components/Form/Button";
import NavBar from "../../components/Navbar";
import Search from "../../components/Search/search";
import {
  checkForUserSubPlan,
  isObjectHasValue,
} from "../../utilities/commonFunction";
import {
  getContextFilterData,
  getContextSearchData,
  getTableStoreValue,
  setScrollBackId,
  setTableFilterData,
} from "../../utilities/pageScroll";
import { CustomerWarningPopup } from "../Customer/CustomerTable/warningPopup";
import "./style.scss";
import { CustomerDeleteWarningPopUp } from "../Customer/CustomerTable/CustomerDeleteWarningPopUp";
import { PROPVANTAGE } from "../../custom/constants";
export default function Demand() {
  const navigate = useNavigate();
  const { tableStoreContext, setTableStoreContext, pageScroll, setPageScroll } =
    useContext(AppContext);

  let tableName = "totalDemands";
  const currentTableValueFromConText = getTableStoreValue(
    tableName,
    tableStoreContext
  );
  const storePageNo = currentTableValueFromConText?.pageNo;
  const storePerPage = currentTableValueFromConText?.perPage;
  let contextFilterData = getContextFilterData(tableName, pageScroll);
  let contextSearchData = getContextSearchData(tableName, pageScroll);

  const [offset, setOffset] = useState(storePageNo ? storePageNo : 1);
  const [perPage, setPerPage] = useState(storePerPage ? storePerPage : 10);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState(
    contextSearchData ? contextSearchData : ""
  );
  const [demandList, setdemandList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  let initialState = {
    requirementSort: "",
    createdSort: "",
    propertyTypeSort: "",
    transactionTypeSort: "",
  };
  const reducer = (state, action) => {
    if (!action.type) {
      return state;
    }

    return {
      ...state,
      [action.type]: action.value,
    };
  };
  let filterinitialState = contextFilterData || initialState;
  const [filter, filterReducerDispatch] = useReducer(
    reducer,
    filterinitialState
  );
  //active states
  const [activeStatus, setActiveStatus] = useState(false);
  const [demandId, setDemandId] = useState("");
  const [warningMessage, setWarningMessage] = useState("");
  const [warningPopup, setWarningPopup] = useState(false);
  const [propertyTypeList, setPropertyTypeList] = useState([]);

  //Delete
  const [deleteWarningPopup, setDeleteWarningPopup] = useState(false);
  const [activeDemandId, setActiveDemandId] = useState("");

  const loginPermission = useSelector((state) => state.login.userPermission);
  const menuId = useSelector((state) => state.login.menuId);
  const access = {
    view: loginPermission?.includes(menuId?.viewDemand) ? true : false,
    agentView: loginPermission?.includes(menuId?.viewDemandAgent)
      ? true
      : false,
    edit: loginPermission?.includes(menuId?.editDemand) ? true : false,
    agentEdit: loginPermission?.includes(menuId?.editDemandAgent)
      ? true
      : false,
    delete: loginPermission?.includes(menuId?.deleteDemand) ? true : false,
    agentDelete: loginPermission?.includes(menuId?.deleteDemandAgent)
      ? true
      : false,
    enable: loginPermission?.includes(menuId?.demandEnableOrDisable)
      ? true
      : false,
    agentEnable: loginPermission?.includes(menuId?.demandEnableOrDisableAgent)
      ? true
      : false,
  };

  let customerCreatedSortValues = [
    {
      name: "Newest",
      _id: "desc",
    },
    {
      name: "Oldest",
      _id: "asc",
    },
  ];
  let customerRequirementValues = [
    {
      name: "Largest",
      _id: "desc",
    },
    {
      name: "Smallest",
      _id: "asc",
    },
  ];

  let customerTransactionTypeSortValues = [
    {
      _id: "Lease",
      name: "Lease",
    },
    {
      _id: "Revenue Share",
      name: "Revenue Share",
    },
    {
      _id: "MG Revenue Share",
      name: "MG Revenue Share",
    },
    {
      _id: "Buy",
      name: "Buy",
    },
  ];
  let customerTypeFilterValues = [
    {
      name: "Agent",
      _id: "agent",
    },
    {
      name: "Brand",
      _id: "brand",
    },
    {
      name: "Enterprise Agent",
      _id: "eagent",
    },
    {
      name: "Physical Team",
      _id: PROPVANTAGE,
    },
  ];

  let customerTypeFilterValuesForAgents = [
    {
      name: "Agent",
      _id: "agent",
    },
    {
      name: "Enterprise Agent",
      _id: "eagent",
    },
    {
      name: "Physical Team",
      _id: PROPVANTAGE,
    },
  ];

  const setContextValue = (id) => {
    let scrollArray = setScrollBackId(
      tableName,
      id,
      filter,
      search,
      pageScroll
    );
    setPageScroll(scrollArray);
  };
  const updateTableStoreContext = (id) => {
    let newValue = setTableFilterData(
      tableName,
      { pageNo: offset, perPage: perPage, id: id },
      tableStoreContext
    );
    setTableStoreContext(newValue);
  };
  const handelDemandEdit = async (
    id,
    brandId,
    brandName,
    userId,
    propertyType,
    demandType
  ) => {
    const response = await checkForUserSubPlan(userId, "demand");
    if (response?.status) {
      localStorage.setItem("demandId", JSON.stringify(id));
      localStorage.setItem("propertyType", JSON.stringify(propertyType));
      if (propertyType?.name === "Office space") {
        localStorage.setItem("postDemandStep", JSON.stringify(3));
      } else {
        localStorage.setItem("postDemandStep", JSON.stringify(5));
      }

      navigate("/demand/PostDemand", {
        state: {
          brandId: brandId,
          userId: userId,
          action: "edit",
          brandName: brandName,
          page: "demands",
          isAgent: demandType === "agent",
          enterpriseAgent: demandType === "eagent",
          physicalTeam: demandType === PROPVANTAGE,
        },
      });
    }
  };

  const getPropertyType = () => {
    let url = "/propertyType";
    getData(url, {}, {}).then((response) => {
      if (response?.status) {
        let propertyArray = [];
        response?.data?.map((data) => {
          propertyArray.push({
            name: data?.name,
            _id: data?._id,
          });
          return data;
        });
        setPropertyTypeList(propertyArray);
      }
    });
  };

  const getBrandData = async (brandId, id, propertyType, demandType) => {
    let url = `/brand/${brandId}`;
    const response = await getData(url, {});
    const { status, data } = response;
    if (status === true) {
      updateTableStoreContext(id);
      if (demandType === "agent") {
        handelDemandEdit(
          id,
          data?.userId?._id,
          data?.userId?.name,
          brandId,
          propertyType,
          demandType
        );
      } else {
        handelDemandEdit(
          id,
          brandId,
          data?.name,
          brandId,
          propertyType,
          demandType
        );
      }
    }
  };
  // useEffect(() => {
  //   if(loginPermission?.includes(menuId.agentDemands)) {
  //     filterReducerDispatch({ type: "demandTypeSort", value: "agent,eagent" });
  //     setOffset(1);
  //   }
  // }, [loginPermission])
  const getDemandList = async () => {
    setIsLoading(true);
    let url = `/demand/getDemands?pageNo=${offset}&limit=${perPage}&userId=${
      getCurrentUser()._id
    }&type=table`;
    if (search) {
      url += `&search=${search}`;
    }
    if (filter?.createdSort && filter?.createdSort !== "select")
      url += `&sort=${filter?.createdSort}`;
    if (filter?.requirementSort && filter?.requirementSort !== "select")
      url += `&carpetArea=${filter?.requirementSort}`;
    if (filter?.transactionTypeSort && filter?.transactionTypeSort !== "select")
      url += `&transactionType=${filter?.transactionTypeSort}`;
    if (filter?.propertyTypeSort && filter?.propertyTypeSort !== "select")
      url += `&propertyType=${filter?.propertyTypeSort}`;

    if (
      loginPermission?.includes(menuId.demand) &&
      loginPermission?.includes(menuId.agentDemands)
    ) {
      if (filter?.demandTypeSort && filter?.demandTypeSort !== "select") {
        url += `&demandType=${filter?.demandTypeSort}`;
      }
    } else if (loginPermission?.includes(menuId.demand)) {
      url += `&demandType=brand`;
      // filterReducerDispatch({ type: "demandTypeSort", value: "brand" });
    } else if (loginPermission?.includes(menuId.agentDemands)) {
      if (filter?.demandTypeSort && filter?.demandTypeSort !== "select") {
        url += `&demandType=${filter?.demandTypeSort}`;
      } else {
        url += `&demandType=agent,eagent,Propvantage`;
      }
      // filterReducerDispatch({ type: "demandTypeSort", value: "agent" });
    }

    const response = await getData(url, {});
    const { statusCode, data } = response;
    if (statusCode === 200) {
      setIsLoading(false);
      setdemandList(data.demands);
      setPageCount(Math.ceil(data.totalCount / perPage));
      setTotalCount(data.totalCount);
    } else {
      setPageCount(0);
      setIsLoading(false);
      setdemandList([]);
    }
  };

  const warningYesClick = async () => {
    let url = `/demand/updateDemand/${demandId}`;
    let datas = {
      isActive: activeStatus ? false : true,
    };
    setIsLoading(true);
    const apiDatas = await putData(url, {}, datas);
    const { statusCode } = apiDatas;
    if (statusCode === 200) {
      setIsLoading(false);
      getDemandList();
      setWarningPopup(false);
    } else {
      setIsLoading(false);
      setWarningPopup(false);
    }
  };

  const deleteWarningYesClick = () => {
    let url = `/demand/deleteDemand/${activeDemandId}?adminToUser=true`;

    deleteData(url, {}, {}).then((response) => {
      if (response.status) {
        getDemandList();
        setDeleteWarningPopup(false);
      } else {
        setDeleteWarningPopup(false);
      }
    });
  };

  const handleWarningClose = (type) => {
    if (type === "delete") {
      setDeleteWarningPopup(false);
    } else {
      setWarningPopup(false);
    }
  };

  const updateStatus = (id, active) => {
    setActiveStatus(active);
    setDemandId(id);
    setWarningPopup(true);
    if (active === true) {
      setWarningMessage("Are you sure, you want to inactivate this Demand");
    } else if (active === false) {
      setWarningMessage("Are you sure, you want to activate this Demand");
    }
  };

  const properSizeFormatter = (cell, row) => {
    return `${row.carpetAreaMin ? row.carpetAreaMin : 0}-${
      row.carpetAreaMax ? row.carpetAreaMax : 0
    } sqft`;
  };

  const customertTypeFormatter = (cell, row) => {
    if (row?.demandType === "agent") {
      return "Agent";
    } else if (row?.demandType === "eagent") {
      return "Enterprise Agent";
    } else if (row?.demandType === PROPVANTAGE) {
      return "Physical Team";
    } else {
      return "Brand";
    }
  };
  const postedByFormatter = (cell, row) => {
    if (row?.demandType === "agent" || row?.demandType === "eagent" ||row?.demandType === PROPVANTAGE) {
      return `${row.userId?.name ? row.userId?.name : "-"}`;
    } else {
      if (row?.brandNameRequired) {
        return `${
          row.brandId
            ? row.brandId?.name
              ? row.brandId?.name
              : row.brandId?.contactPerson
            : "Anonymous"
        }`;
      }
    }

    return "Anonymous";
  };

  const onClickViewDemand = (row) => {
    updateTableStoreContext(row?._id);
    navigate(`/demand/DemandDetailView/${row?.productId?.display}`, {
      state: { demandId: row._id, brandId: row?.brandId?._id, page: "demands" },
    });
  };

  function userButtonFormatter(cell, row, rowIndex) {
    return (
      <div className="d-flex justify-content-center align-items-center ">
        <Button
          className="table-btn p-0"
          disabled={
            row?.demandType === "agent" ||
            row?.demandType === "eagent" ||
            row?.demandType === PROPVANTAGE
              ? access?.agentView
                ? false
                : true
              : access?.view
              ? false
              : true
          }
        >
          <BsFillEyeFill onClick={() => onClickViewDemand(row)} />
        </Button>{" "}
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Button
          className="table-btn p-0"
          disabled={
            row?.demandType === "agent" ||
            row?.demandType === "eagent" ||
            row?.demandType === PROPVANTAGE
              ? row?.isActive && access?.agentEdit
                ? false
                : true
              : row?.isActive && access?.edit
              ? false
              : true
          }
        >
          <span className="cursor-pointer">
            <FaPen
              onClick={
                row?.demandType === "agent" ||
                row?.demandType === "eagent" ||
                row?.demandType === PROPVANTAGE
                  ? access?.agentEdit
                    ? () =>
                        getBrandData(
                          row?.brandId?._id,
                          row._id,
                          row?.propertyType,
                          row?.demandType
                        )
                    : null
                  : access?.edit
                  ? () =>
                      getBrandData(
                        row?.brandId?._id,
                        row._id,
                        row?.propertyType,
                        row?.demandType
                      )
                  : null
              }
            />{" "}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </Button>
        <Form.Check
          type="switch"
          id={`custom-switch_${rowIndex}}`}
          checked={row.isActive ? true : false}
          disabled={
            row?.demandType === "agent" ||
            row?.demandType === "eagent" ||
            row?.demandType === PROPVANTAGE
              ? access?.agentEnable
                ? false
                : true
              : access.enable
              ? false
              : true
          }
          onChange={(e) => {
            updateStatus(row._id, row.isActive);
          }}
        />
        <Button
          className="table-btn p-0"
          onClick={(e) => {
            setActiveDemandId(row?._id);
            setDeleteWarningPopup(true);
          }}
          disabled={
            row?.demandType === "agent" ||
            row?.demandType === "eagent" ||
            row?.demandType === PROPVANTAGE
              ? access.agentDelete
                ? false
                : true
              : access.delete
              ? false
              : true
          }
        >
          <FaTrash />
        </Button>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </div>
    );
  }
  const handelChangePerPage = (count) => {
    setPerPage(count);
    setOffset(1);
  };
  const columns = [
    {
      dataField: "productId.display",
      text: "Demand id",
    },
    {
      dataField: "brand",
      text: "Brand name",
      formatter: (cellContent, row, rowIndex) => (row.brand ? row?.brand : "-"),
    },
    {
      dataField: "brandId",
      text: "Submitted by",
      formatter: postedByFormatter,
    },
    {
      dataField: "propertyType",
      text: "Property type",
      formatter: (cellContent, row, rowIndex) => row?.propertyType?.name,
    },
    {
      dataField: "transactionType",
      text: "Transaction Type",
    },
    {
      dataField: "location",
      text: "City",
    },
    {
      dataField: "microLocation",
      text: "Locality",
      formatter: (cellContent, row, rowIndex) => {
        return <span>{row?.microLocation?.description}</span>;
      },
    },
    {
      dataField: "demandType",
      text: "Customer Type",
      formatter: customertTypeFormatter,
    },
    {
      dataField: "carpetAreaMax",
      text: "Requirement size",
      formatter: properSizeFormatter,
    },
    {
      dataField: "createdAt",
      text: "Created on",
      formatter: (cellContent, row, rowIndex) => {
        if (
          row.createdAt === null ||
          row.createdAt === "" ||
          !("createdAt" in row)
        ) {
          return "-";
        } else if (row?.createdAt) {
          return new Date(row?.createdAt).toLocaleString();
        }
      },
    },
    {
      dataField: "action",
      text: "Action",
      formatter: userButtonFormatter,
    },
  ];

  const tableProps = {
    data: demandList,
    columns,
    offset,
    setOffset,
    handelChangePerPage,
    pageCount,
    perPage,
    totalCount,
    tableName: "totalDemands",
    setPerPage,
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setOffset(1);
  };
  const handleCreatedSort = (e) => {
    filterReducerDispatch({ type: "createdSort", value: e.target.value });
    setOffset(1);
  };
  const handleRequirementSort = (e) => {
    filterReducerDispatch({ type: "requirementSort", value: e.target.value });
    setOffset(1);
  };
  const handleTransactionTypeSort = (e) => {
    filterReducerDispatch({
      type: "transactionTypeSort",
      value: e.target.value,
    });
    setOffset(1);
  };
  const handleDemandTypeSort = (e) => {
    filterReducerDispatch({ type: "demandTypeSort", value: e.target.value });
    setOffset(1);
  };
  const handlePropertyTypeSort = (e) => {
    filterReducerDispatch({ type: "propertyTypeSort", value: e.target.value });
    setOffset(1);
  };

  useEffect(() => {
    getDemandList();
    if (search || isObjectHasValue(filter)) {
      setContextValue();
    }
  }, [search, perPage, offset, filter]);

  useEffect(() => {
    getPropertyType();
  }, []);

  return (
    <>
      {loginPermission?.includes(menuId.demand) ||
      loginPermission?.includes(menuId.agentDemands) ? (
        <div className="dashboard-container">
          {warningPopup && (
            <CustomerWarningPopup
              show={warningPopup}
              title={"Confirmation"}
              message={warningMessage}
              warningYesClick={warningYesClick}
              onClose={handleWarningClose}
            />
          )}
          {deleteWarningPopup && (
            <CustomerDeleteWarningPopUp
              show={deleteWarningPopup}
              title={"Confirmation"}
              message={"Are you sure, you want to delete this demand"}
              warningYesClick={deleteWarningYesClick}
              onClose={() => handleWarningClose("delete")}
            />
          )}
          <div className="d-flex justify-content-between">
            <NavBar />

            <Row className="filter_container" style={{ width: "75%" }}>
              <Col>
                <Search onChange={handleSearch} value={search} />
              </Col>
              <Col>
                <Select
                  placeholder="Property Type"
                  options={propertyTypeList}
                  name={"PropertyType"}
                  value={filter?.propertyTypeSort}
                  onChange={handlePropertyTypeSort}
                  // isHideSelect
                />
              </Col>
              <Col>
                <Select
                  placeholder="Transaction Type"
                  options={customerTransactionTypeSortValues}
                  name={"Transaction"}
                  value={filter?.transactionTypeSort}
                  onChange={handleTransactionTypeSort}
                  // isHideSelect
                />
              </Col>
              <Col>
                <Select
                  // placeholder="Customer Type"
                  placeholder={
                    loginPermission?.includes(menuId?.demand) &&
                    loginPermission?.includes(menuId?.agentDemands)
                      ? "Customer Type"
                      : loginPermission?.includes(menuId?.demand)
                      ? "brand"
                      : "Customer Type"
                  }
                  options={
                    loginPermission?.includes(menuId?.demand) &&
                    loginPermission?.includes(menuId?.agentDemands)
                      ? customerTypeFilterValues
                      : loginPermission?.includes(menuId?.agentDemands)
                      ? customerTypeFilterValuesForAgents
                      : []
                  }
                  name={"selectDemandType"}
                  value={filter?.demandType}
                  disabled={
                    loginPermission?.includes(menuId?.demand) &&
                    loginPermission?.includes(menuId?.agentDemands)
                      ? false
                      : loginPermission?.includes(menuId?.agentDemands)
                      ? false
                      : true
                  }
                  onChange={handleDemandTypeSort}
                  // isHideSelect
                />
              </Col>
              <Col>
                {/* <Select
                  options={customerRequirementValues}
                  name="customerRequirementType"
                  id="customerRequirementType"
                  onChange={handleRequirementSort}
                  value={createdSort}
                  isHideSelect
                /> */}
                <Select
                  placeholder="Requirement size"
                  options={customerRequirementValues}
                  name={"devloper"}
                  value={filter?.requirementSort}
                  onChange={handleRequirementSort}
                  // isHideSelect
                />
              </Col>
              <Col>
                {/* <Select
                  data={customerCreatedSortValues}
                  filter={true}
                  options={"Created on"}
                  name="customerCreatedon"
                  id="customerCreatedon"
                  onChange={handleCreatedSort}
                  value={requirementSort}
                /> */}
                <Select
                  placeholder="Created on"
                  options={customerCreatedSortValues}
                  name={"Created"}
                  value={filter?.createdSort}
                  onChange={handleCreatedSort}
                  // isHideSelect
                />
              </Col>
            </Row>
          </div>

          <div className="mt-4">
            <MainTable {...tableProps} />
          </div>
          {isLoading && <Loader className={"fixed"} />}
        </div>
      ) : (
        <AccessDenied />
      )}
    </>
  );
}
