import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postData } from "../../api";

const initialState = {
  loginData: {},
  menuId: {},
  brandId: "",
  enterpriseAgentId: "",
  physicalTeamId: "",
  subBrand: true,
  agentId: "",
  companyId: "",
  companyFormStatus: "",
  tableName: "",
  pageNo: "",
  perPage: "",
  parentPage: "",
  childPage: "",
  relevantPropertyId: "",
  reportTableName: "",
  scrollId: [],
  userPermission: [],
  activeSlug: "",
  activeUrl: "",
  agentTabKey:"enterpriseAgent",
  selectedAgentReportTab:"property",
  selectedEnterpriseAgentReportTab:"properties",
  selectedPhysicalTeamReportTab:"properties",
};

export const loginDataReducer = createSlice({
  name: "login",
  initialState,
  reducers: {
    updateLoginData: (state, action) => {
      state.loginData = action.payload;
    },
    updateMenuId: (state, action) => {
      state.menuId = action.payload;
    },
    updatePermissions: (state, action) => {
      state.userPermission = action.payload;
    },
    updateComapanyId: (state, action) => {
      state.companyId = action.payload;
    },
    updateBrandId: (state, action) => {
      state.brandId = action.payload;
    },
    updateEnterpriseAgentId: (state, action) => {
      state.enterpriseAgentId = action.payload;
    },
    updatePhysicalTeamId: (state, action) => {
      state.physicalTeamId = action.payload;
    },
    updateHasSubBrand: (state, action) => {
      state.subBrand = action.payload;
    },
    updateAgentId: (state, action) => {
      state.agentId = action.payload;
    },
    updateCompanyFormStatus: (state, action) => {
      state.companyFormStatus = action.payload;
    },
    updateTableName: (state, action) => {
      state.tableName = action.payload;
    },
    updatePageNo: (state, action) => {
      state.pageNo = action.payload;
    },
    updatePerPageNo: (state, action) => {
      state.perPage = action.payload;
    },
    updateDashboardType: (state, action) => {
      state.dashboardType = action.payload;
    },
    updateParentPageData: (state, action) => {
      state.parentPage = action.payload;
    },
    updateChildPageData: (state, action) => {
      state.childPage = action.payload;
    },
    updateRelevantPropertyId: (state, action) => {
      state.relevantPropertyId = action.payload;
    },
    updateScrollId: (state, action) => {
      state.scrollId = action.payload;
    },
    updateActiveSlug: (state, action) => {
      state.activeSlug = action.payload;
    },
    updateActiveUrl: (state, action) => {
      state.activeUrl = action.payload;
    },
    updateSelectedAgentTabKey: (state, action) => {
      state.agentTabKey = action.payload;
    },
    updateSelectedAgentReportTab: (state, action) => {
      state.selectedAgentReportTab = action.payload;
    },
    updateSelectedEnterpriseAgentReportTab: (state, action) => {
      state.selectedEnterpriseAgentReportTab = action.payload;
    },
    updateSelectedPhysicalTeamReportTab: (state, action) => {
      state.selectedPhysicalTeamReportTab = action.payload;
    },
    reset: () => initialState,
  },
});
export const loginUsers = createAsyncThunk(
  "login/loginHandler",
  async (thunkAPI, { dispatch }) => {
    const res = await postData("/user/login", {}, thunkAPI);
    if (res.statusCode === 200) {
      dispatch(loginDataReducer.actions.updateLoginData(res.data));
    }
    return res;
  }
);

// Action creators are generated for each case reducer function

export const {
  updateLoginData,
  updateMenuId,
  updateBrandId,
  updateEnterpriseAgentId,
  updatePhysicalTeamId,
  updateHasSubBrand,
  updateAgentId,
  updateComapanyId,
  updateCompanyFormStatus,
  updateTableName,
  updatePageNo,
  updatePerPageNo,
  updateDashboardType,
  updateChildPageData,
  updateParentPageData,
  updateRelevantPropertyId,
  updateScrollId,
  updatePermissions,
  updateActiveSlug,
  updateActiveUrl,
  reset,
  updateSelectedAgentTabKey,
  updateSelectedAgentReportTab,
  updateSelectedEnterpriseAgentReportTab,
  updateSelectedPhysicalTeamReportTab
} = loginDataReducer.actions;

export default loginDataReducer.reducer;
