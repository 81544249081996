import React, { useEffect, useReducer, useState } from "react";

// library
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// components
import MainTable from "../Table";

// api and user data
import {
  getData,
  postGetData,
  postExportData,
  getCurrentUser,
  AccessDenied,
} from "../../api";
//redux fuction
import {
  updateComapanyId,
  updateBrandId,
  updateCompanyFormStatus,
} from "../../pages/Login/reducer";

// style scss
import "./style.scss";
import { Row } from "react-bootstrap";
import Loader from "../Loader";
import { useContext } from "react";
import { AppContext } from "../../App";
import {
  getContextFilterData,
  getTableStoreValue,
  setScrollBackId,
  setTableFilterData,
} from "../../utilities/pageScroll";
import { activityLogsColumns } from "./columns";
import { ActivityLogsContext } from "../../pages/ActivityLogs";

const ActivityLogTable = ({ customerType }) => {
  const { filterValues } = useContext(ActivityLogsContext);
  let tableName = `activityLogs_${customerType}`;
  const { tableStoreContext, setTableStoreContext, pageScroll, setPageScroll } =
    useContext(AppContext);

  // table Data states
  const [tableData, setTableData] = useState([]);
  const [offset, setOffset] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);

  //router
  const navigate = useNavigate();

  //redux
  let dispatch = useDispatch();
  const setContextValue = (id) => {
    let scrollArray = setScrollBackId(
      "activityLogs",
      id,
      filterValues,
      "",
      pageScroll
    );
    setPageScroll(scrollArray);
  };
  const updateTableStoreContext = (id) => {
    let newValue = setTableFilterData(
      "activityLogs",
      { pageNo: offset, perPage: perPage, id: id },
      tableStoreContext
    );
    setTableStoreContext(newValue);
  };

  //   fetch table data
  const getTableData = async () => {
    setLoading(true);
    let url = "/eventLog/getAllEventLog";
    let payLoad = {
      ...(filterValues?.search && { search: filterValues?.search }),
      ...(filterValues?.eventNameList && {
        eventNameList: filterValues?.eventNameList?.value,
      }),
      ...(filterValues?.startDate && { startDate: filterValues?.startDate }),
      ...(filterValues?.endDate && { endDate: filterValues?.endDate }),
    };
    payLoad.pageNo = offset;
    payLoad.limit = perPage;
    payLoad.customerType = customerType;
    let response = await postGetData(url, {}, payLoad);
    const { data, statusCode } = response;
    let dataKey = "eventList";
    if (statusCode === 200) {
      setLoading(false);
      setTableData(data?.[dataKey]);
      const totalDoc = data?.totalDoc ? data?.totalDoc : data?.totalEventCount;
      setPageCount(Math.ceil(totalDoc / perPage));
      setTotalCount(totalDoc);
    } else {
      setLoading(false);
      setPageCount(0);
      setTableData([]);
    }
  };

  //  handling action item(MODALS) starts----------------------
  //get brandList

  //   open poc modal
  //getRegionList
  const onClickPropertyId = (row) => {
    updateTableStoreContext(row?.uniqueId);
    localStorage.setItem("propertyValues", JSON.stringify(row));
    const propertyId = row?.productId?.display
      ? row?.productId?.display
      : row?.propertyData?.productId;
    navigate(`/activityLogs/propertyDetailView/${propertyId}`, {
      state: { values: {}, customerType: "properties" },
    });
  };

  // demand id click
  const onClickDemandId = (row) => {
    updateTableStoreContext(row?.uniqueId);
    const demandId = row?.demandId?.display
      ? row?.demandId?.display
      : row?.demandData?.productId;
    navigate(`/activityLogs/DemandDetailView/${demandId}`);
  };

  // table action and data starts ------------------------------------
  //   get table columns
  // created formatter
  function createdOnFormatter(key = "updatedAt") {
    return (cell, row, rowIndex) => {
      if (!row[key] || row[key] === null || row[key] === "" || !(key in row)) {
        return "-";
      }
      return new Date(row[key]).toLocaleString();
    };
  }

  // property Id/Demand Id formatter for activity logs
  function propertyIdOrDemandIdFormatter(onClickDemandId, onClickPropertyId) {
    return (cell, row, rowIndex) => {
      console.log({ row });
      if (
        row?.event === "RESPONSE_ACTION" ||
        row?.event === "PROPERTY_EDITED" ||
        row?.event === "PROPERTY_POSTED"
      ) {
        if (row?.propertyData?.productId) {
          return (
            <div className="f-14 fw-700 table-link">
              {" "}
              <span
                className="cursor-pointer"
                onClick={() => onClickPropertyId(row)}
              >
                {row?.propertyData?.productId}
              </span>
            </div>
          );
        } else {
          return "-";
        }
      } else {
        if (row?.demandData?.productId) {
          return (
            <div className="f-14 fw-700 table-link">
              {" "}
              <span
                className="cursor-pointer"
                onClick={() => onClickDemandId(row)}
              >
                {row?.demandData?.productId}
              </span>
            </div>
          );
        } else {
          return "-";
        }
      }
    };
  }

  function demandIdFormatterLog(onClickDemandId) {
    return (cell, row, rowIndex) => {
      if (row?.demandData?.productId && !row?.demandData?.isDelete) {
        return (
          <div className="f-14 fw-700 table-link">
            {" "}
            <span
              className="cursor-pointer"
              onClick={() => onClickDemandId(row)}
            >
              {row?.demandData?.productId}
            </span>
          </div>
        );
      } else if (row?.demandData?.productId && row?.demandData?.isDelete) {
        return (
          <div className="f-14 fw-700">
            {" "}
            <span>{row?.demandData?.productId}</span>
          </div>
        );
      } else {
        return "-";
      }
    };
  }

  function propertyIdFormatterLog(onClickPropertyId) {
    return (cell, row, rowIndex) => {
      if (row?.propertyData?.productId && !row?.propertyData?.isDelete) {
        return (
          <div className="f-14 fw-700 table-link">
            {" "}
            <span
              className="cursor-pointer"
              onClick={() => onClickPropertyId(row)}
            >
              {row?.propertyData?.productId}
            </span>
          </div>
        );
      } else if (row?.propertyData?.productId && row?.propertyData?.isDelete) {
        return (
          <div className="f-14 fw-700">
            {" "}
            <span>{row?.propertyData?.productId}</span>
          </div>
        );
      } else {
        return "-";
      }
    };
  }

  // Event Type formatter for activity log
  function eventTypeFormatterForActivityLog() {
    return (cell, row, rowIndex) => {
      if (row?.event === "RESPONSE_ACTION" && row?.responseType) {
        return (
          <span
            className="capitalize"
          >
            {row?.displayName + " - " +row?.responseType}
          </span>
        );
      } else if (row?.displayName) {
        return row?.displayName;
      } else {
        return "-";
      }
    };
  }

  // city formatter for activity log
  function cityFormatterForActivityLog() {
    return (cell, row, rowIndex) => {
      if (row?.propertyData?.location) {
        return row.propertyData.location;
      } else if (row?.demandData?.location) {
        return row?.demandData?.location;
      } else {
        return "-";
      }
    };
  }
  // brand name formatter for activity log
  function brandNameFormatterForActivityLog() {
    return (cell, row, rowIndex) => {
      if (row?.loginType === "Switch") {
        if (row?.behalfOf?.brandName) {
          return row.behalfOf?.brandName;
        } else {
          return "-";
        }
      } else if(row?.event === "POC_ADDED" || row?.event === "POC_UPDATED" || row?.event === "POC_DELETED") {
        if (row?.pocData?.brandName) {
          return row?.pocData?.brandName;
        } else {
          return "-";
        }
      } else {
        if (row?.doneByUser?.brandName) {
          return row?.doneByUser?.brandName;
        } else if (row?.behalfOf?.brandName) {
          return row.behalfOf?.brandName;
        } else {
          return "-";
        }
      }
    };
  }
  // donebyuserFormatterfor activity log
  function donebyuserFormatterForActivityLog() {
    return (cell, row, rowIndex) => {
      if (row?.doneByUser.name) {
        return row?.doneByUser.name;
      } else {
        return "-";
      }
    };
  }

  const tableFormatter = () => {
    const columnArray = activityLogsColumns[customerType];

    const getFormatter = {
      createdAtActionForActivityLog: createdOnFormatter("createdAt"),
      propertyIdOrDemandIdFormatter: propertyIdOrDemandIdFormatter(
        onClickDemandId,
        onClickPropertyId
      ),
      propertyIdFormatterLog: propertyIdFormatterLog(onClickPropertyId),
      demandIdFormatterLog: demandIdFormatterLog(onClickDemandId),
      cityFormatterForActivityLog: cityFormatterForActivityLog(),
      brandNameFormatterForActivityLog: brandNameFormatterForActivityLog(),
      donebyuserFormatterForActivityLog: donebyuserFormatterForActivityLog(),
      eventTypeFormatterForActivityLog:eventTypeFormatterForActivityLog()
    };

    return columnArray.map((element) => ({
      ...element,
      ...(element?.formatter === true && {
        formatter: getFormatter[element.dataField],
      }),
    }));
  };
  // table columns call
  const columnsDeveloper = tableFormatter();

  //   handle page change
  const handelChangePerPage = (count) => {
    setPerPage(count);
    setOffset(1);
  };

  const tableProps = {
    data: tableData,
    columns: columnsDeveloper,
    offset,
    setOffset,
    handelChangePerPage,
    pageCount,
    perPage,
    totalCount,
    uniqueId: true,
    tableName: tableName,
    setPerPage,
  };

  // table action and data Ends ------------------------------------
  //   table data call
  useEffect(() => {
    if (customerType) {
      getTableData();
    }
    setContextValue();
  }, [customerType, perPage, offset, filterValues]);

  return (
    <>
      <div className="seller-report-table">
        {columnsDeveloper?.length > 0 && <MainTable {...tableProps} />}
      </div>
      {loading && <Loader className="fixed" />}
    </>
  );
};

export default ActivityLogTable;
