import { AiOutlineClose } from "react-icons/ai";
import Select from "react-select";
import "./style.scss";
import { BsFillEyeFill } from "react-icons/bs";
import { truncateString } from "../../utilities/commonFunction";
import { useState } from "react";
import FilterTableList from "../tableFilterList";

const MultiSelect = (props) => {
  const { isMulti, value, onChange } = props;
  const [showTableList, setShowTableList] = useState(false);
  let errorField = !isMulti && props.errormessage ? true : false;
  const customStyles = {
    control: (base) => ({
      ...base,
      padding: "1px 5px",
      transition: "none",
      width: "100%",
      background: props.disabled ? "#e9ecef" : "#ffff",
      outline: "none",
      borderRadius: "0.375rem",
      borderColor:
        props.errormessage && value?.length === 0
          ? "#dc3545"
          : errorField
          ? "#dc3545"
          : "#ced4da",
    }),
    indicatorSeparator: () => ({ display: "none" }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#555555",
        fontSize: "16px",
        fontWeight: "500",
      };
    },
  };
  const handleClose = () => {
    // view table
    setShowTableList(false);
  };
  const handleRemoveValue = (e) => {
    e.preventDefault();
    if (e.target.classList.contains("close-icon")) return;
    if (!onChange) return;
    const { name: buttonName } = e.currentTarget;
    const removedValue = value.find((val) => {
      return String(val.value) === String(buttonName);
    });
    if (!removedValue) return;

    onChange(
      value.filter((val) => String(val.value) !== String(buttonName)),
      { action: "remove-value", removedValue }
    );
    if (props?.viewAllString) {
      handleClose();
    }
  };

  const handleViewAll = () => {
    // view table
    setShowTableList(true);
  };
 
  const keyDownHandler = e => {
    if (e.key === "Enter") e.preventDefault();
  }

  return (
    <div
      className="multi-select-content pt-2"
      style={{ width: props?.width ? props?.width : "100%" }}
    >
      {props.lable && <div className="d-grid w-100">
        <label className="form-lable mb-2 input-lable text-black">
          {props.lable}
        </label>
        {props?.addText ? (
          <label id={props?.addText} className="fw-400 f-14 text-black mb-2">
            {props?.addText}
          </label>
        ) : (
          ""
        )}
      </div>}
      <Select
        {...props}
        controlShouldRenderValue={!isMulti}
        isMulti={isMulti}
        clearable
        placeholder={props.placeholder}
        styles={customStyles}
        isDisabled={props.disabled ? true : false}
        onKeyDown={keyDownHandler}
      />

      {(props.errormessage || value?.length === 0) && (
        <div className="error-msg">{props.errormessage}</div>
      )}

      <div className="d-flex flex-wrap justify-content-start align-items-center mt-1">
        {isMulti && value?.length > 0 && (
          <>
            {props?.viewAllString ? (
              <>
                {value.slice(0, 3).map((val) => (
                  <div
                    key={val.value}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <div
                      key={val.value}
                      className="multi-select-list ml-2 my-1"
                    >
                      <span title={val.label}>
                        {truncateString(val.label, 5)}
                      </span>
                      {props?.view && (
                        <span
                          className="cursor-pointer m-1"
                          onClick={() => props?.onClickViewList(val)}
                        >
                          <BsFillEyeFill />
                        </span>
                      )}
                      <button
                        className="close-icon"
                        name={val.value}
                        disabled={props?.disabled ? true : false}
                        onClick={handleRemoveValue}
                      >
                        <AiOutlineClose
                          style={{
                            color: "white",
                            fontSize: "1em",
                            paddingLeft: "3px",
                            cursor: "pointer",
                          }}
                        />
                      </button>
                    </div>
                  </div>
                ))}
                {value?.length > 3 && (
                  <span
                    className="view-all-btn cursor-pointer"
                    onClick={handleViewAll}
                  >,..(View all)</span>
                )}
              </>
            ) : (
              <>
                {value?.length > 0 &&
                  value.map((val) => (
                    <div key={val.value}>
                      <div
                        className="multi-select-list ml-2 my-1"
                        key={val.value}
                      >
                        <span title={val.label}>
                          {truncateString(val.label, 10)}
                        </span>
                        {props?.view && (
                          <span
                            className="cursor-pointer m-1"
                            onClick={() => props?.onClickViewList(val)}
                          >
                            <BsFillEyeFill />
                          </span>
                        )}
                        <button
                          className="close-icon"
                          name={val.value}
                          disabled={props?.disabled ? true : false}
                          onClick={handleRemoveValue}
                        >
                          <AiOutlineClose
                            style={{
                              color: "white",
                              fontSize: "1em",
                              paddingLeft: "3px",
                              cursor: "pointer",
                            }}
                          />
                        </button>
                      </div>
                    </div>
                  ))}
              </>
            )}
          </>
        )}
      </div>
      <FilterTableList
        data={value}
        onClose={handleClose}
        show={showTableList}
        handleRemoveValue={handleRemoveValue}
        onClickViewList={props?.onClickViewList}
        view={props?.view}
      />
    </div>
  );
};

export default MultiSelect;
