import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import { FaArrowCircleLeft, FaArrowCircleRight, FaRegFilePdf } from 'react-icons/fa';

function BrochureProject({ DemoSwiperimages }) {
    const defaultImage = "/images/galleryImage.png"; // Ensure this path is correct and the image exists

    const handleImageError = (event) => {
        // Set the default image on error
        event.target.src = defaultImage;
    };

    const isImageType = (type) => {
        // Check if the type starts with 'image/'
        return type && type.startsWith('image');
    };

    return (
        <div>
            <div className="d-flex justify-content-between align-items-center p-4 py-3 my-3">
                <div className="d-flex align-items-center gap-2">
                    <FaRegFilePdf style={{ color: "#6C8C91", fontSize: "1.3rem" }} />
                    <h1 className={"fs-5 pt-1"}>Floor Plans</h1>
                </div>

                {/* Navigation buttons */}
                {DemoSwiperimages?.length > 1 && (
                    <div className="d-flex gap-3">
                        <FaArrowCircleLeft className="arrow-right-left2" style={{ color: '#08536B', fontSize: "2rem", cursor: "pointer" }} />
                        <FaArrowCircleRight className="arrow-left-right1" style={{ color: '#08536B', fontSize: "2rem", cursor: "pointer" }} />
                    </div>
                )}
            </div>

            <div className="position-relative d-flex justify-content-center align-items-center w-100" style={{ height: '500px' }}>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={5}
                    loop={true}
                    modules={[Navigation, Pagination]}
                    navigation={{ nextEl: ".arrow-left-right1", prevEl: ".arrow-right-left2" }}
                    breakpoints={{
                        499: { slidesPerView: 1, spaceBetween: 10 },
                        650: { slidesPerView: 1, spaceBetween: 10 },
                        999: { slidesPerView: 1, spaceBetween: 10 },
                    }}
                    className="z-0 w-100 h-100"
                >
                    {DemoSwiperimages?.map((image, index) => {
                        // Check if the item is an image
                        const imageUrl = isImageType(image.type) ? image.viewUrl : defaultImage;
                        return (
                            <SwiperSlide key={index} className="h-100 w-100">
                                <div className="d-flex justify-content-center w-100 h-100">
                                    <img
                                        src={imageUrl}
                                        alt={`Image ${index + 1}`}
                                        width="100%"
                                        height="100%"
                                        className="w-100 h-100"
                                        onError={handleImageError}
                                        style={{ objectFit: 'contain' }}
                                    />
                                </div>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </div>
        </div>
    );
}

export default BrochureProject;
