import React, { useEffect, useReducer, useState } from "react";

// library
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// components
import MainTable from "../../../../../components/Table";
import { buyerColumns } from "./buyerColumns";
import { buyerUrls } from "./url";
import BuyerModal from "./buyerModals";
import NavBar from "../../../../../components/Navbar";

// buyer custom events and formatter
import {
  cityFormatter,
  createdOnFormatter,
  getPayLoad,
  onClickCountFormater,
  onClickIdFormater,
  tableDataKey,
  userButtonFormatterPoc,
  viewbuttonFormatter,
  companyNameFormatter,
  projectNameFormatter,
  properSizeFormatter,
  brandNameWithLogoFormatter,
  userButtonFormatterForDemand,
  userButtonFormatterPocForCompany,
  cityFormatterForActivityLog,
  brandNameFormatterForMallingLog,
  propertyIdFormatterForMallingLog,
  propertyIdFormatterForMallingLogs,
  tableTotalDataKey,
} from "./buyerFormatter";
import { buyerConstantHeaderValue } from "../reportConstants";

// api and user data
import {
  getData,
  postGetData,
  postExportData,
  getCurrentUser,
  AccessDenied,
} from "../../../../../api";
//redux fuction
import {
  updateComapanyId,
  updateBrandId,
  updateCompanyFormStatus,
} from "../../../../Login/reducer";

// style scss
import "./style.scss";
import { Filter } from "./buyerFilter";
import { Row } from "react-bootstrap";
import Loader from "../../../../../components/Loader";
import { useContext } from "react";
import { AppContext } from "../../../../../App";
import {
  getContextFilterData,
  getTableStoreValue,
  setScrollBackId,
  setTableFilterData,
} from "../../../../../utilities/pageScroll";
import { ListPopup } from "../../../../../components/Modal/listModal";
import { checkForUserSubPlan } from "../../../../../utilities/commonFunction";

// context provider
export const BuyerContext = React.createContext();

const BuyerReportTable = () => {
  const page = useSelector((state) => state.login.childPage);
  const parentPage = useSelector((state) => state.login.parentPage);

  let tableName = `${page}${parentPage}`;
  const { tableStoreContext, setTableStoreContext, pageScroll, setPageScroll } =
    useContext(AppContext);
  const currentTableValueFromConText = getTableStoreValue(
    tableName,
    tableStoreContext
  );

  let contextFilterData = getContextFilterData(tableName, pageScroll);
  const storePageNo = currentTableValueFromConText?.pageNo;
  const storePerPage = currentTableValueFromConText?.perPage;

  //permissions
  const loginPermission = useSelector((state) => state.login.userPermission);
  const menuId = useSelector((state) => state.login.menuId);
  const accessForproperty = {
    view: loginPermission?.includes(menuId?.viewProperty) ? true : false,
    agentView: loginPermission?.includes(menuId?.viewPropertyAgent)
      ? true
      : false,
    edit: loginPermission?.includes(menuId?.editProperty) ? true : false,
    agentEdit: loginPermission?.includes(menuId?.editPropertyAgent)
      ? true
      : false,
    enable: loginPermission?.includes(menuId?.propertyEnableOrDisable)
      ? true
      : false,
    agentEnable: loginPermission?.includes(menuId?.propertyEnableOrDisableAgent)
      ? true
      : false,
  };
  const accessForDemand = {
    view: loginPermission?.includes(menuId?.viewDemand) ? true : false,
    agentView: loginPermission?.includes(menuId?.viewDemandAgent)
      ? true
      : false,
    edit: loginPermission?.includes(menuId?.editDemand) ? true : false,
    agentEdit: loginPermission?.includes(menuId?.editDemandAgent)
      ? true
      : false,
    enable: loginPermission?.includes(menuId?.demandEnableOrDisable)
      ? true
      : false,
    agentEnable: loginPermission?.includes(menuId?.demandEnableOrDisableAgent)
      ? true
      : false,
  };

  const access = {
    viewCompanyBuyer: loginPermission?.includes(menuId?.viewCompanyBuyer)
      ? true
      : false,
    editCompanyBuyer: loginPermission?.includes(menuId?.editCompanyBuyer)
      ? true
      : false,
    companyBuyerEnableDisable: loginPermission?.includes(
      menuId?.companyBuyerEnableDisable
    )
      ? true
      : false,
    viewBuyerCustomers: loginPermission?.includes(menuId?.viewBuyerCustomers)
      ? true
      : false,
    editBuyerCustomers: loginPermission?.includes(menuId?.editBuyerCustomers)
      ? true
      : false,
    buyerEnableDisable: loginPermission?.includes(menuId?.buyerEnableDisable)
      ? true
      : false,
    buyerImportsAndExports: loginPermission?.includes(
      menuId?.buyerImportsAndExports
    )
      ? true
      : false,
  };

  const [showPropertyListPopup, setPropertyShowListPopup] = useState(false);
  const [propertyList, setPropertyList] = useState([]);

  // table Data states
  const [tableData, setTableData] = useState([]);
  const [offset, setOffset] = useState(storePageNo ? storePageNo : 1);
  const [perPage, setPerPage] = useState(storePerPage ? storePerPage : 10);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const initialfilterValue = contextFilterData || {};
  const actions = {
    companyList: "companyList",
    brandList: "brandList",
    cityList: "cityList",
    regionList: "regionList",
    pocList: "pocList",
    propertyTypeList: "propertyTypeList",
    supplierList: "supplierList",
    createdByList: "createdByList",
    sortByList: "sortByList",
    search: "search",
    contactedList: "contactedList",
    startDate: "startDate",
    endDate: "endDate",
    eventNameList: "eventNameList",
  };

  const filterReducer = (state, action) => {
    let value = action?.value;
    if (
      action &&
      action?.isMulti === "no" &&
      action?.value &&
      action?.value?.value === "select"
    ) {
      value = {};
    }
    const actionType = actions[action?.type];
    if (!actionType) {
      return state;
    }
    return { ...state, [actionType]: value };
  };

  //   formatter  modal states
  const [openPocModal, setOpenPocModal] = useState({
    show: false,
    pocType: "",
    brandID: "",
    rowObj: {},
    companyTypeReceived: "",
    brandList: [],
  });
  const [warningPopup, setWarningPopup] = useState({
    show: false,
    message: "",
    rowData: {},
    key: "",
  });
  const [showListCities, setShowListcities] = useState({
    show: false,
    value: [],
  });

  //filter
  const [filterValues, filterDispatch] = useReducer(
    filterReducer,
    initialfilterValue
  );
  //router
  const navigate = useNavigate();

  //redux
  let dispatch = useDispatch();
  const setContextValue = (id) => {
    let scrollArray = setScrollBackId(
      tableName,
      id,
      filterValues,
      "",
      pageScroll
    );
    setPageScroll(scrollArray);
  };
  //   table data call

  // get table  data url

  const getURL = (key, offsetValue, perPageValue) => {
    if (!buyerUrls.hasOwnProperty(key)) return "";
    return buyerUrls[key](offsetValue, perPageValue);
  };
  const updateTableStoreContext = (id) => {
    let newValue = setTableFilterData(
      tableName,
      { pageNo: offset, perPage: perPage, id: id },
      tableStoreContext
    );
    setTableStoreContext(newValue);
  };

  const getIds = (array) => array.map((item) => item.value);

  //   fetch table data
  const getTableData = async (exportData) => {
    setLoading(true);
    let url = getURL(page, offset, perPage);
    let payLoadAddon = getPayLoad(page, offset, perPage);
    let payLoad = {
      ...(filterValues?.search && { search: filterValues?.search }),
      ...(filterValues?.eventNameList && {
        eventNameList: filterValues?.eventNameList?.value,
      }),
      ...(filterValues?.startDate && { startDate: filterValues?.startDate }),
      ...(filterValues?.endDate && { endDate: filterValues?.endDate }),

      ...(filterValues?.cityList?.length > 0 && {
        city: getIds(filterValues?.cityList),
      }),
      ...(filterValues?.companyList?.length > 0 && {
        company: getIds(filterValues?.companyList),
      }),
      ...(filterValues?.regionList?.length > 0 && {
        region: getIds(filterValues?.regionList),
      }),
      ...(filterValues?.brandList?.length > 0 && {
        brand: getIds(filterValues?.brandList),
      }),
      ...(filterValues?.sortByList?.value !== "select" &&
        filterValues?.sortByList?.value && {
          sort: filterValues?.sortByList?.value,
        }),
      ...(filterValues?.createdByList?.value !== "select" &&
        filterValues?.createdByList?.value && {
          sort: filterValues?.createdByList?.value,
        }),
      ...(filterValues?.contactedList?.value !== "select" &&
        filterValues?.contactedList?.value && {
          sort: filterValues?.contactedList?.value,
        }),
      ...(filterValues?.pocList?.length > 0 && {
        poc: getIds(filterValues?.pocList),
      }),
      ...(filterValues?.propertyTypeList?.length > 0 && {
        property: getIds(filterValues?.propertyTypeList),
      }),
      ...(filterValues?.supplierList?.length > 0 && {
        supplier: getIds(filterValues?.supplierList),
      }),
      ...(payLoadAddon !== "" && payLoadAddon),
    };
    if (exportData) {
      let filterPayload;
      if (page === "mailingLogs") {
        filterPayload = { ...payLoad, isExport: true };
      } else {
        filterPayload = { ...payLoad, reportType: "export" };
        delete filterPayload["pageNo"];
        delete filterPayload["limit"];
      }
      postExportData(url, {}, filterPayload).then((blob) => {
        if (blob) {
          let urls = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = urls;
          a.download = `${parentPage}-${page}.xlsx`;
          a.click();
        }
      });
      setLoading(false);
    } else {
      let response = await postGetData(url, {}, payLoad);
      const { data, statusCode } = response;

      let dataKey = tableDataKey(page);
      let dataTotalKey = tableTotalDataKey(page);
      if (statusCode === 200) {
        setLoading(false);
        setTableData(data?.[dataKey]);
        const totalDoc = data[dataTotalKey]
          ? data[dataTotalKey]
          : data?.totalDoc
          ? data?.totalDoc
          : data?.totalEventCount
          ? data?.totalEventCount
          : data?.total;
        setPageCount(Math.ceil(totalDoc / perPage));
        setTotalCount(totalDoc);
      } else {
        setLoading(false);

        setPageCount(0);
        setTableData([]);
      }
    }
  };

  //  handling action item(MODALS) starts----------------------
  //get brandList

  //   open poc modal
  //getRegionList
  const getRegionList = async (brandId = "", action, row) => {
    let url = `/cityZones/checkZoneAvailability/${brandId}`;
    const res = await getData(url, {});
    const { status, data } = res;
    if (status) {
      //  setRegionList(data?.region)
      setOpenPocModal({
        show: true,
        pocType: action,
        brandID: brandId,
        companyTypeReceived: row?.subBrands ? "Yes" : "no",
        rowObj: row,
        regionList: data?.region,
      });
    }
  };
  const handelOpenPocModal = (action, brandId, row) => {
    if (page === "regionPocList") {
      getRegionList(row?.brandId, action, row);
    } else {
      setOpenPocModal({
        show: true,
        pocType: action,
        brandID: brandId,
        companyTypeReceived: row?.subBrands ? "Yes" : "no",
        rowObj: row,
      });
    }
  };
  //view company
  const onClickViewCompany = (row) => {
    updateTableStoreContext(row?.uniqueId);
    localStorage.setItem("brandEdit", JSON.stringify(!row?.subBrands));
    dispatch(updateBrandId(row?.brandId));
    dispatch(updateComapanyId(row?.brandId));
    dispatch(updateCompanyFormStatus("view"));
    navigate("/buyerReport/companyList/brandCompanyCreation", {
      state: {
        view: true,
        companyType: row?.subBrands ? "" : "companyNoBrands",
      },
    });
  };
  // open warning popup
  const handelOpenWarning = (row, active, key) => {
    setWarningPopup({
      show: true,
      message: `Are you sure, you want to ${active ? "deactivate" : "active"} ${
        key === "poc" ? "this customer" : "this demand"
      }?`,
      rowData: row,
      key,
    });
  };

  // city list popup open
  const handelOpenListPopup = (data, key) => {
    setShowListcities({ show: true, value: data, title: key });
  };

  // property Id click

  const onClickPropertyId = (row) => {
    updateTableStoreContext(row?.uniqueId);
    localStorage.setItem("propertyValues", JSON.stringify(row));
    const propertyId = row?.productId?.display
      ? row?.productId?.display
      : row?.propertyData?.productId;
    navigate(`/buyerReport/demandList/propertyDetailView/${propertyId}`, {
      state: { values: {}, page: "properties" },
    });
  };

  // demand id click
  const onClickDemandId = (row) => {
    updateTableStoreContext(row?.uniqueId);
    const demandId = row?.demandId?.display
      ? row?.demandId?.display
      : row?.demandData?.productId;
    navigate(`/buyerReport/demandList/DemandDetailView/${demandId}`);
  };

  // matching demand count click
  const onNavigateMatchingDemand = (row) => {};

  // repsonse count click
  const onNavigatePropertyresponse = (row) => {};

  // onClick property view
  const onClickProperty = (action, brandId, row) => {};
  const onClickViewBrand = (row) => {
    updateTableStoreContext(row?.uniqueId);
    dispatch(updateBrandId(row?._id));
    dispatch(updateComapanyId(""));
    localStorage.setItem("customer-id", JSON.stringify(row._id));
    navigate(`/buyerReport/brandList/brandPage/${row?.slug}`, {
      state: {
        id: row._id,
        view: true,
        customerType: row?.subBrands ? "companyYesBrand" : "companyNoBrand",
      },
    });
  };
  //demand action
  const handelDemandEdit = async(
    demandId,
    brandId,
    brandName,
    userId,
    propertyType
  ) => {
    const response = await checkForUserSubPlan(brandId, "demand")
    if(response?.status) {
      localStorage.setItem("demandId", JSON.stringify(demandId));
      localStorage.setItem("propertyType", JSON.stringify(propertyType));
      if (propertyType?.name === "Office space") {
        localStorage.setItem("postDemandStep", JSON.stringify(3));
      } else {
        localStorage.setItem("postDemandStep", JSON.stringify(5));
      }
  
      navigate("/demand/PostDemand", {
        state: {
          brandId: brandId,
          userId: userId,
          action: "edit",
          brandName: brandName,
          page: "reports",
        },
      });
    }
  };
  const getBrandData = async (row) => {
    let url = `/brand/${row?.brandId?._id}`;
    const response = await getData(url, {});
    const { status, data } = response;
    if (status === true) {
      handelDemandEdit(
        row._id,
        row?.brandId?._id,
        data?.name,
        row?.brandId?._id,
        row?.propertyType
      );
    }
  };
  const onClickDemand = (action, brandId, row) => {
    updateTableStoreContext(row?.uniqueId);
    if (action === "view") {
      navigate(
        `/buyerReport/demandList/DemandDetailView/${row?.demandId?.display}`,
        {
          state: {
            demandId: row._id,
            brandId: row?.brandId?._id,
            page: "reports",
          },
        }
      );
    } else {
      getBrandData(row);
    }
  };
  //export
  const onClickExportData = () => {
    getTableData(true);
  };

  //property ids popup
  const handelOpenPropertyListPopup = (propertyData) => {
    setPropertyList(propertyData);
    setPropertyShowListPopup(true);
  };
  const onClosePropertyListPopup = () => {
    setPropertyList([]);
    setPropertyShowListPopup(false);
  };

  // table action and data starts ------------------------------------
  //   get table columns
  const sellerFormatter = () => {
    const columnArray = buyerColumns?.find(
      (item) => item.pageName === page
    )?.data;
    if (!columnArray) return [];

    const getFormatter = {
      companyButtonFormatter: viewbuttonFormatter(
        onClickViewCompany,
        access,
        "company"
      ),
      cityAction: cityFormatter(handelOpenListPopup, "cities"),
      regionAction: cityFormatter(handelOpenListPopup, "region"),
      buyerButtonFormatter: viewbuttonFormatter(
        onClickViewBrand,
        access,
        "customer"
      ),
      pocActionForCompany: userButtonFormatterPocForCompany(
        page,
        handelOpenPocModal,
        handelOpenWarning,
        "poc",
        access
      ),
      pocAction: userButtonFormatterPoc(
        page,
        handelOpenPocModal,
        handelOpenWarning,
        "poc",
        access
      ),
      demandButtonFormatter: userButtonFormatterForDemand(
        page,
        onClickDemand,
        handelOpenWarning,
        "demand",
        accessForDemand
      ),
      propertyIdFormatter: onClickIdFormater("productId", onClickPropertyId),
      createdAtAction: createdOnFormatter("createdAt"),
      matchingContactedFormatter: onClickCountFormater(
        "matchingDemandContacted",
        () => {}
      ),
      matchingCountFormatter: onClickCountFormater(
        "matchingDemand",
        onNavigateMatchingDemand
      ),
      reponseCoutFormatter: onClickCountFormater(
        "propertyResponse",
        onNavigatePropertyresponse
      ),
      demandIdFormatter: onClickIdFormater("demandId", onClickDemandId),
      propertyListAction: userButtonFormatterPoc(
        page,
        onClickProperty,
        handelOpenWarning,
        "property",
        accessForproperty
      ),
      createdFormatter: createdOnFormatter(),
      rejectedFormatter: createdOnFormatter("rejectedOn"),

      properSizeFormatter: properSizeFormatter(),
      acceptedAction: createdOnFormatter("acceptedOn"),
      //   demandIdFormatter: onClickIdFormater("demandId", onClickDemandId),
      // propertyIdFormatter: onClickIdFormater("productId", onClickPropertyId),
      companyNameFormatter: companyNameFormatter(),
      //   propertyTypeFormatter: propertyTypeFormatter(),
      projectNameFormatter: projectNameFormatter(),
      contactedAction: createdOnFormatter("contactedOn"),
      brandNameWithLogo: brandNameWithLogoFormatter(),
      createdAtActionForActivityLog: createdOnFormatter("createdAt"),
      propertyIdFormatterForMallingLog:
        propertyIdFormatterForMallingLog(onClickPropertyId),
      cityFormatterForActivityLog: cityFormatterForActivityLog(
        "productId",
        onClickPropertyId
      ),
      brandNameFormatterForMallingLog: brandNameFormatterForMallingLog(),
      propertyIdFormatterForMallingLogs: propertyIdFormatterForMallingLogs(
        handelOpenPropertyListPopup,
        "properties",
        propertyIdFormatterForMallingLog
      ),
    };

    return columnArray.map((element) => ({
      ...element,
      ...(element?.formatter === true && {
        formatter: getFormatter[element.dataField],
      }),
    }));
  };
  // table columns call
  const columnsDeveloper = sellerFormatter();

  //   handle page change
  const handelChangePerPage = (count) => {
    setPerPage(count);
    setOffset(1);
  };
  const filterProps = {
    page,
    setOffset,
    onClickExportData,
  };

  const tableProps = {
    data: tableData,
    columns: columnsDeveloper,
    offset,
    setOffset,
    handelChangePerPage,
    pageCount,
    perPage,
    totalCount,
    uniqueId: true,
    tableName: tableName,
    setPerPage,
  };

  // table action and data Ends ------------------------------------

  //   table data call
  useEffect(() => {
    if (page) {
      getTableData();
    }

    setContextValue();
  }, [page, perPage, offset, filterValues]);

  return (
    <BuyerContext.Provider
      value={{
        openPocModal,
        setOpenPocModal,
        warningPopup,
        setWarningPopup,
        showListCities,
        setShowListcities,
        filterDispatch,
        filterValues,
      }}
    >
      {/* {getCurrentUser()?.role[0]?.name === "admin" ||
      getCurrentUser()?.role[0]?.name === "Relationship Manager" ? ( */}
        <>
          <NavBar />
          <div className="d-flex justify-content-between align-items-center text-heading">
            <label title="Dashboard">{buyerConstantHeaderValue?.[page]}</label>
          </div>
          <Row className="mb-4" style={{ rowGap: "8px" }}>
            <Filter {...filterProps} />
          </Row>
          <div className="seller-report-table">
            {columnsDeveloper?.length > 0 && <MainTable {...tableProps} />}

            {/* Modals */}
            {showPropertyListPopup && (
              <ListPopup
                onClose={onClosePropertyListPopup}
                show={showPropertyListPopup}
                title="Property IDs"
                lists={propertyList}
              />
            )}
            <BuyerModal page={page} getTableData={getTableData} />
          </div>
          {loading && <Loader className="fixed" />}
        </>
      {/* ) : (
        <AccessDenied />
      )} */}
    </BuyerContext.Provider>
  );
};
export default BuyerReportTable;
