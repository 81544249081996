import React, { useEffect, useState } from "react";

//library
import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";
//fetch
import {
    AccessDenied,
    getAccountHandlersList,
    getData,
    postData,
    postGetData,
    postUploadData,
    putData,
    updateSubscription,
} from "../../../api/index";

//components
import Button from "../../../components/Form/Button";
import SingleImageUpload from "../../../components/imageUpload/singleImageUpload";
import Loader from "../../../components/Loader";
import NavBar from "../../../components/Navbar";

// custom components
import {
    EnterpriseAgentPlanFieldArray,
    EnterpriseAgentFieldArray,
    PhysicalTeamFieldArray,
} from "./customComponents/fieldArray";
import {
    RenderInput,
    RenderMultiSelect,
    RenderPhoneInput,
    RenderSelect,
    RenderTextArea,
} from "./customComponents/inputGroups";

import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getPropertyType, getRm, getRoles, postStates } from "../../../api/api";
import {
    convertIntoNameLabelObject,
    labelToName,
    nameToLabel,
} from "../../../utilities/commonFunction";
import { updateBrandId, updatePhysicalTeamId } from "../../Login/reducer";
import PocTable from "./poc/pocTable";
import { FaPen } from "react-icons/fa";
import SubscriptionRenew from "../../../components/subscriptionRenew/developerSubscriptionRenew";
import Select from "../../../components/Form/Select";
import {
    brandTrancationOptions,
    demandAndPropertyTransactionTypes,
    getTransactionOptions,
    PropertyTrancationOptions,
} from "../../../utilities/transactionOptions";
import { ENTERPRISEAGENT, PHYSICALTEAM } from "../../../custom/constants";
import { errorCheck } from "./enterprsieAgentErrorCheck";
import EnterpriseAgentSubscriptionRenew from "../../../components/subscriptionRenew/enterpriseAgentSubsciptionRenew";

const PhysicalTeamFromPage = ({ view }) => {
    const dispatch = useDispatch();

    let location = useLocation();
    const fromCustomerSeller = location?.state?.fromCustomerSeller;

    const defaultObj = {
        propertyOrDemandSelect: "",
        propertyOrDemand: "",
        validityDays: "",
        userDashboard: "Yes",
        demandMails: "",
        homePageSlotAndBanner: "",
        assistedUploading: "",
        featuredProperty: "",
        submissionAgainstDemand: "",
        submissionAgainstDemandSelect: "",
        relationshipManager: "",
        rm: "",
        planName: "",
        discount: "",
        subscriptionAmount: "",
        contactSuppliersSelect: "",
        contactSuppliers: "",
        emailtoSuppliers: "",
    }

    const navigate = useNavigate();
    const [validated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [initLoading, setInitLoading] = useState(true);
    const [dataLoad, setDataLoad] = useState(false);
    const [stateList, setStateList] = useState([]);
    //success Model
    const [successModel, setSuccessModel] = useState(false);
    const [recieveDemands, setRecieveDemands] = useState(false);
    const [accountHandler, setAccountHandler] = useState("");
    const [accountHandlerList, setAccountHandlerList] = useState([]);
    const [subscriptionPlanOptions, setSubscriptionPlanOptions] = useState([]);
    const [subPlanChosen, setSubPlanChosen] = useState(false);

    // Subscription Edit
    const [subscrptionEdit, setSubscrptionEdit] = useState(false);
    const [initialSubscriptionData, setInitialSubscriptionData] = useState({});
    const [subscriptionExpired, setSubscriptionExpired] = useState(false);
    const [showRenewModal, setShowRenewModal] = useState(false);
    const [subscriptionExpiryDate, setSubscriptionExpiryDate] = useState("");
    const [companyId, setCompanyId] = useState("");
    const [defaultsSubscriptionPlans, setDefaultsSubscriptionPlans] = useState(
        []
    );

    const [submissionInputShow, setSubmissionInputShow] = useState(false);
    const [propertyDemandInput, setPropertyDemandInput] = useState(false);
    const [contactSuppliersShow, setContactSuppliersShow] = useState(false);

    const [subscriptionPlan, setSubscriptionPlan] = useState(defaultObj);

    const brandIdUpdate = useSelector((state) => state.login.physicalTeamId);
    let company;
    let companyTypeReceived = "companyNoDeveloper";
    let editStorage = JSON.parse(localStorage.getItem("physicalTeamEdit"));

    const [edit, setEdit] = useState(
        editStorage
            ? editStorage
            : location?.state?.edit
                ? location?.state?.edit
                : location?.state?.view
                    ? location?.state?.view
                    : false
    );
    const loginPermission = useSelector((state) => state.login.userPermission);
    const menuId = useSelector((state) => state.login.menuId);
    const access = {
        editCompany: loginPermission?.includes(menuId.editCompany),
        addCompanyAgent: loginPermission?.includes(menuId?.addCompanyAgent),
        viewCompanyAgent: loginPermission?.includes(menuId?.viewCompanyAgent),
    };
    let customerView =
        access?.addCompanyAgent || access?.editCompany || access?.viewCompanyAgent;
    //props
    const [customerArray, setCustomerArray] = useState([]);
    const [customerDetails, setCustomerDetails] = useState({});
    const [subscription, setSubscription] = useState({});
    const [cityArray, setCityArray] = useState([]);

    //select options list data
    const [rmList, setRmList] = useState([]);

    const [propertyTypeList, setPropertyTypeList] = useState([]);
    const [transactionTypeList, setTransactionTypeList] = useState([]);

    //id state

    //imageupload state
    const [imageName, setImageName] = useState([]);
    const [logoName, setLogoName] = useState([]);
    const [apiErrorData, setApiErrorData] = useState("");

    const [role, setRole] = useState({});

    //error object
    const [errorObject, setErrorObject] = useState({});

    // Getting Account Handler List
    const getAcHanList = async () => {
        const response = await getAccountHandlersList();
        if (response?.data?.getAllAccountHandler) {
            setAccountHandlerList(response.data.getAllAccountHandler);
        }
    };

    // useEffect(() => {
    //     if (!edit) {
    //         setSubscrptionEdit(true);
    //     } else {
    //         setSubPlanChosen(true);
    //     }
    // }, [edit]);

    const getSubscription = () => {
        let url = "/subscription";
        getData(url, {}, {}).then((response) => {
            if (response.status) {
                const agentSub = response?.data.filter((sub) => sub._id === "Agent");
                const agentSubPlan = agentSub[0]?.plans;

                setDefaultsSubscriptionPlans(agentSubPlan);

                const subPlanOptions = agentSubPlan.map((plan) => plan.planName);
                setSubscriptionPlanOptions(subPlanOptions);
            }
        });
    };

    useEffect(() => {
        // let transactionOptions = getTransactionOptions(
        //   customerDetails,
        //   PropertyTrancationOptions
        // );
        let transactionOptions = getTransactionOptions(
            customerDetails,
            demandAndPropertyTransactionTypes
        );
        if (customerDetails?.transactionType) {
            const filteredTransactionArray = customerDetails?.transactionType.filter(
                (item1) => {
                    return nameToLabel(transactionOptions).some(
                        (item2) => item2.value === item1.value
                    );
                }
            );
            setCustomerDetails({
                ...customerDetails,
                transactionType: filteredTransactionArray,
            });
        }
        const customerArrayUpdated = customerArray.map((element, index) => {
            return element.key === "transactionType"
                ? { ...element, option: nameToLabel(transactionOptions) }
                : element;
        });
        setCustomerArray(customerArrayUpdated);
        setTransactionTypeList(nameToLabel(transactionOptions));
    }, [customerDetails?.propertyTypes]);


    useEffect(() => {
        if (successModel) {
            getAcHanList();
        } else {
            setAccountHandlerList([]);
            setAccountHandler("");
        }
    }, [successModel]);

    useEffect(() => {
        if(subscriptionPlan.planName){
            let errors = errorCheck(customerDetails, subscriptionPlan);
            setErrorObject(errors);
        }    
    }, [subscriptionPlan]);

    
    //Handle Success Model
    const handleSuccessModel = (val) => {
        if (!val) {
            setAccountHandler("");
        } else {
            setRecieveDemands(false);
        }
        setSuccessModel(val);
    };

    const handleRecieveDemands = (val) => {
        setRecieveDemands(val);
    };

    const getRole = () => {
        getData(getRoles(), {}, {}).then((response) => {
            if (response.status) {
                let roleID;
                response?.data.find((el) =>
                    el.name === "Physical Team" ? (roleID = el._id) : null
                );
                let roleObject = {
                    _id: roleID,
                    name: "Physical Team",
                    type: "user",
                };
                setRole(roleObject);
            }
        });
    };

    //Subscrition Edit
    const handleSubscriptionEdit = async () => {
        if (subscriptionPlan.submissionAgainstDemandSelect === "Unlimited") {
            subscriptionPlan.submissionAgainstDemand = "Unlimited";
        }
        if (subscriptionPlan.propertyOrDemandSelect === "Unlimited") {
            subscriptionPlan.propertyOrDemand = "Unlimited";
        }

        if (subscriptionPlan?.relationshipManager === "No") {
            delete subscriptionPlan?.rm;
        }

        if (subscriptionPlan.contactSuppliersSelect === "Unlimited") {
            subscriptionPlan.contactSuppliers = "Unlimited";
        }

        let errors = await errorCheck(customerDetails, subscriptionPlan);
        if (
            !errors["validityDays"] &&
            parseInt(subscriptionPlan?.validityDays) <
            parseInt(initialSubscriptionData?.validityDays)
        ) {
            errors["validityDays"] =
                "Subscription validity must be greater that current validity";
        }
        if (Object.keys(errors).length === 0) {
            delete subscriptionPlan.submissionAgainstDemandSelect;
            delete subscriptionPlan.propertyOrDemandSelect;
            delete subscriptionPlan.contactSuppliersSelect;
            setLoading(true);
            updateSubscription(companyId, { ...subscriptionPlan }, "EAgent").then(
                (res) => {
                    setSubscrptionEdit(false);
                    setLoading(false);
                }
            );
        } else {
            setErrorObject(errors);
        }
    };

    const getStates = () => {
        postGetData(postStates(), {}, {}).then((response) => {
            if (response?.status) {
                let stateArray = nameToLabel(response?.data);
                setStateList(stateArray);
            }
        });
    };
    const getRmList = () => {
        getData(getRm(), {}, {}).then((response) => {
            setRmList(response?.data?.subAdminList);
        });
    };
    const getPropertyTypeList = () => {
        getData(getPropertyType(), {}, {}).then((response) => {
            if (response?.status) {
                let propertyArray = nameToLabel(response?.data);
                setPropertyTypeList(propertyArray);
            }
        });
    };
    const getImageData = (customerData) => {
        if (edit) {
            let imageUrl = customerData.image ? customerData.image : {};
            let logoUrl = customerData.logo ? customerData.logo : {};

            setImageName([
                { name: imageUrl ? (imageUrl.fileName ? imageUrl.fileName : "") : "" },
            ]);
            setLogoName([
                { name: logoUrl ? (logoUrl.fileName ? logoUrl.fileName : "") : "" },
            ]);
        }
    };
    const updateCityList = (array) => {
        let cityArray = [];
        array?.map((has) => {
            customerDetails?.cities.map((el) => {
                if (el?.value === has?.value) {
                    cityArray.push(el);
                }
                return el;
            });
            return has;
        });
        setCustomerDetails((prev) => ({ ...prev, cities: cityArray }));
    };
    const getCities = (id, onChangeState) => {
        if (id && id.length > 0) {
            setDataLoad(true);
            let url = "/country/getCitiesByState";
            let listedId = id.map((item) => item.value);
            let payload = { id: listedId };
            postGetData(url, {}, payload).then((response) => {
                if (response?.status) {
                    let cityData = nameToLabel(response?.data);
                    setCityArray(cityData);
                    if (onChangeState) {
                        updateCityList(cityData);
                    }
                    setDataLoad(false);
                } else {
                    setDataLoad(false);
                }
            });
        }
    };

    const getCustomerData = () => {
        if (edit) {
            company = location?.state?.company;
            let url;
            if (company) {
                url = `/company/${brandIdUpdate}`;
            } else {
                url = `/brand/${brandIdUpdate}`;
            }

            getData(url, {}, {}).then((response) => {
                if (response.status) {
                    getImageData(response.data);
                    let customerObj = {};
                    let subscriptionDetail = response.data.subscriptionPlanId;
                    let customerData = {
                        cities: [],
                        state: [],
                        name: "",
                        propertyTypes: "",
                        transactionType: "",
                        about: "",
                        logo: "",
                        image: "",
                        noOfExperience:''
                    };
                    setCompanyId(response.data?.companyId?._id);
                    Object.entries(customerData)?.forEach(([k, value]) => {
                        Object.assign(customerObj, { [k]: response.data[k] });
                    });
                    let cityArray = nameToLabel(customerObj?.cities);
                    let stateArray = nameToLabel(customerObj?.state);
                    let propertyArray = nameToLabel(customerObj?.propertyTypes);
                    let transactionArray = convertIntoNameLabelObject(
                        customerObj?.transactionType ? customerObj?.transactionType : []
                    );
                    customerObj.cities = cityArray;
                    customerObj.state = stateArray;
                    customerObj.propertyTypes = propertyArray;
                    customerObj.transactionType = transactionArray;

                    if (
                        response?.data?.subscriptionPlanId?.relationshipManager === "No"
                    ) {
                        subscriptionDetail.rm = "";
                    }
                    // } else {
                    //   subscriptionDetail.rm = response?.data?.rm;
                    // }

                    if (response?.data?.isSuccessModel) {
                        setSuccessModel(true);
                    } else {
                        setAccountHandler("");
                    }

                    if (response?.data?.accountHandlerId !== "null") {
                        setAccountHandler(response?.data?.accountHandlerId);
                    }

                    if (subscriptionDetail.submissionAgainstDemand === "Unlimited") {
                        subscriptionDetail.submissionAgainstDemandSelect = "Unlimited";
                    } else {
                        subscriptionDetail.submissionAgainstDemandSelect = "Enter number";
                        setSubmissionInputShow(true);
                    }
                    if (subscriptionDetail.propertyOrDemand === "Unlimited") {
                        subscriptionDetail.propertyOrDemandSelect = "Unlimited";
                    } else {
                        subscriptionDetail.propertyOrDemandSelect = "Enter number";
                        setPropertyDemandInput(true);
                    }
                    if (subscriptionDetail.contactSuppliers === "Unlimited") {
                        subscriptionDetail.contactSuppliersSelect = "Unlimited";
                    } else {
                        subscriptionDetail.contactSuppliersSelect = "Enter number";
                        setContactSuppliersShow(true);
                    }
                    //Checking for Subscription Expiry
                    const validityDays = parseInt(subscriptionDetail?.validityDays);
                    const subscriptionDate = new Date(
                        subscriptionDetail?.subscriptionDate
                    );

                    const expireDate = new Date(
                        subscriptionDate.getTime() + validityDays * 24 * 60 * 60 * 1000
                    );

                    const currentDate = new Date();

                    const hasExpired = expireDate <= currentDate;
                    if (hasExpired) {
                        setSubscriptionExpired(true);
                    } else {
                        setSubscriptionExpired(false);
                    }

                    const day = expireDate.getDate();
                    const monthNames = [
                        "Jan",
                        "Feb",
                        "Mar",
                        "Apr",
                        "May",
                        "Jun",
                        "Jul",
                        "Aug",
                        "Sep",
                        "Oct",
                        "Nov",
                        "Dec",
                    ];
                    const monthIndex = expireDate.getMonth();
                    const year = expireDate.getFullYear();

                    const formattedExpiryDate = `${day}${day === 1 ? "st" : day === 2 ? "nd" : day === 3 ? "rd" : "th"
                        } ${monthNames[monthIndex]} ${year}`;
                    if (hasExpired) {
                        setSubscriptionExpiryDate(`Expired on ${formattedExpiryDate}`);
                    } else {
                        setSubscriptionExpiryDate(`Plan expires on ${formattedExpiryDate}`);
                    }

                    setCustomerDetails({ ...customerObj });
                    setInitialSubscriptionData({ ...subscriptionDetail });
                    setSubscriptionPlan({ ...subscriptionDetail });
                    getCities(stateArray, false);
                    setInitLoading(false);
                } else {
                    setInitLoading(false);
                }
                if (response?.data?.companyId?.receiveDemandFromAgent) {
                    setRecieveDemands(response?.data?.companyId?.receiveDemandFromAgent);
                }
            });
        } else {
            setInitLoading(false);
        }
    };
    
    const getImage = (files, key) => {
        if (files.length === 0) {
            setCustomerDetails({ ...customerDetails, [key]: {} });
        }
        let data = new FormData();
        data.append("uploadfiles", files[0]);
        if (files.length > 0) {
            setDataLoad(true);
            let url = "/brand/file-upload/?type=image&module=brand";
            postUploadData(url, {}, data).then((response) => {
                if (response.status) {
                    setCustomerDetails({ ...customerDetails, [key]: response.data[0] });
                    setDataLoad(false);
                } else {
                    setDataLoad(false);
                }
            });
        }
    };

    const createPhysicalTeamPage = () => {
        if (subscriptionPlan.contactSuppliersSelect === "Unlimited") {
            subscriptionPlan.contactSuppliers = "Unlimited";
        }
        delete subscriptionPlan.submissionAgainstDemandSelect;
        delete subscriptionPlan.propertyOrDemandSelect;
        let cityArray = labelToName(customerDetails?.cities);
        let stateArray = labelToName(customerDetails?.state);
        let propertyArray = labelToName(customerDetails?.propertyTypes);
        let transactionTypeArra = labelToName(customerDetails?.transactionType);
        let dataList = {
            ...customerDetails,
            cities: cityArray,
            state: stateArray,
            propertyTypes: propertyArray,
            transactionType: transactionTypeArra,
            subscriptionPlanId: subscriptionPlan,
            roles: role,
            type: "Physical Team",
            receiveDemandFromAgent: recieveDemands,
        };

        // if (successModel) {
        //   delete dataList["receiveDemandFromAgent"];
        // }

        if (dataList?.image === "" || Object.keys(dataList?.image).length === 0) {
            dataList.image = {};
        }
        if (edit) {
            delete dataList.roles;
            delete dataList.type;
            delete dataList.subscriptionPlanId;
        }
        if (dataList?.subscriptionPlanId?.relationshipManager === "No") {
            delete dataList?.subscriptionPlanId?.rm;
        } else {
            dataList.rm = dataList?.subscriptionPlanId?.rm;
        }
        dataList.subBrands = false;
        setLoading(true);
        if (edit) {
            setApiErrorData("");
            putData(`/enterpriseAgent/${brandIdUpdate}`, {}, dataList).then(
                (response) => {
                    if (response.status) {
                        navigate("/company", {
                            state: { companyActiveKey: PHYSICALTEAM },
                        });
                        setLoading(false);
                    } else if (
                        response.statusCode === 400 ||
                        response.statusCode === 422
                    ) {
                        setLoading(false);
                        setApiErrorData(response.message);
                    } else {
                        setLoading(false);
                    }
                }
            );
        } else {
            setApiErrorData("");
            postData("/enterpriseAgent", {}, dataList).then((response) => {
                if (response.status) {
                    localStorage.setItem("physicalTeamEdit", JSON.stringify(true));
                    setEdit(true);
                    dispatch(updatePhysicalTeamId(response?.data?._id));
                    setLoading(false);
                    navigate("/company?route=yes");
                } else if (response.statusCode === 400 || response.statusCode === 422) {
                    setLoading(false);
                    setApiErrorData(response.message);
                } else {
                    setLoading(false);
                }
            });
        }
    };
    const handleOnSubmit = (event) => {
        if (subscription.submissionAgainstDemandSelect === "Unlimited") {
            subscription.submissionAgainstDemand = "Unlimited";
        }
        if (subscription.propertyOrDemandSelect === "Unlimited") {
            subscription.propertyOrDemand = "Unlimited";
        }

        event.preventDefault();
        let errors = errorCheck(customerDetails, subscriptionPlan);
        if (
            successModel &&
            (accountHandler === "" ||
                accountHandler === null ||
                accountHandler === undefined)
        ) {
            errors["accountHandlerId"] = "Select an account handler";
        }
        if (Object.keys(errors).length === 0) {
            createPhysicalTeamPage();
        } else {
            setErrorObject(errors);
        }
    };

    const subscriptionArray = [
        {
            name: "Subscription plan *",
            key: "planName",
            type: "select",
            option: subscriptionPlanOptions,
        },
        {
            name: "Listing *",
            key: "propertyOrDemandSelect",
            subKey: "propertyOrDemand",
            type: "select",
            option: ["Unlimited", "Enter number"],
        },
        {
            name: "No. of uploads *",
            key: "propertyOrDemand",
            subKey: "propertyOrDemandSelect",
            type: "input",
            inputType: "number",
            placeholder: "Listing upload",
        },
        {
            name: "Validity days *",
            key: "validityDays",
            type: "input",
            inputType: "number",
        },
        {
            name: "Demand Alerts *",
            key: "demandMails",
            type: "select",
            option: ["Instant", "Daily", "Monthly", "Weekly"],
        },

        {
            name: "Submission against demand Limit *",
            key: "submissionAgainstDemandSelect",
            subKey: "submissionAgainstDemand",
            type: "select",
            option: ["Unlimited", "Enter number"],
        },
        {
            name: "No. of Submission against demand *",
            key: "submissionAgainstDemand",
            subKey: "submissionAgainstDemandSelect",
            type: "input",
            inputType: "number",
            placeholder: "Submission against demand",
        },
        {
            name: "User dashboard *",
            key: "userDashboard",
            type: "input",
            inputType: "text",
            placeholder: "eg:Multi-user dashboard",
        },
        {
            name: "Featured listing *",
            key: "featuredProperty",
            type: "select",
            option: ["Yes", "No"],
        },
        {
            name: "Assisted uploading *",
            key: "assistedUploading",
            type: "select",
            option: ["Yes", "No"],
        },
        {
            name: "Relationship manager *",
            key: "relationshipManager",
            type: "select",
            option: ["Yes", "No"],
        },
        {
            name: "Select RM *",
            key: "rm",
            type: "select",
            option: rmList,
        },
        {
            name: "Email to suppliers *",
            key: "emailtoSuppliers",
            type: "select",
            option: ["Yes", "No"],
        },
        {
            name: "Contact suppliers *",
            key: "contactSuppliersSelect",
            subKey: "contactSuppliers",
            type: "select",
            option: ["Unlimited", "Enter number"],
        },
        {
            name: "Contact suppliers *",
            key: "contactSuppliers",
            subKey: "contactSuppliersSelect",
            type: "input",
            inputType: "number",
            placeholder: "Enter number",
        },
    ];

    const onChange = (value, key) => {
        delete errorObject[key];
        let data = value;
        if (key === "state") {
            if (value?.length > 0) {
                getCities(value, true);
                setCustomerDetails({ ...customerDetails, [key]: value });
            } else {
                setCityArray([]);
                setCustomerDetails({ ...customerDetails, cities: [], [key]: value });
            }
        } else {
            setCustomerDetails({ ...customerDetails, [key]: data });
        }
    };

    const handleInputFieldDisable = (
        propertyOrDemand,
        submissionAgainstDemand
    ) => {
        let retobj = {};
        if (propertyOrDemand === "Unlimited") {
            retobj = { ...retobj, propertyOrDemandSelect: "Unlimited" };
            setPropertyDemandInput(false);
        } else {
            retobj = { ...retobj, propertyOrDemandSelect: "Enter number" };
            setPropertyDemandInput(true);
        }

        if (submissionAgainstDemand === "Unlimited") {
            retobj = { ...retobj, submissionAgainstDemandSelect: "Unlimited" };
            setSubmissionInputShow(false);
        } else {
            retobj = { ...retobj, submissionAgainstDemandSelect: "Enter number" };
            setSubmissionInputShow(true);
        }

        return retobj;
    };
    const onChangeSubscription = (value, key) => {
        delete errorObject[key];
        if (key === "planName") {
            value === "" ? setSubPlanChosen(false) : setSubPlanChosen(true);
            const defaultSubscriptionPlan = defaultsSubscriptionPlans.filter(
                (plan) => plan.planName === value
            );

            const { propertyOrDemandSelect, submissionAgainstDemandSelect } =
                handleInputFieldDisable(
                    defaultSubscriptionPlan[0]?.propertyOrDemand,
                    defaultSubscriptionPlan[0]?.submissionAgainstDemand
                );
            if (defaultSubscriptionPlan[0]?.contactSuppliers === "Unlimited") {
                defaultSubscriptionPlan[0].contactSuppliersSelect = "Unlimited";
            } else {
                defaultSubscriptionPlan[0].contactSuppliersSelect = "Enter number";
                setContactSuppliersShow(true);
            }

            let notPresent = {}
            let arrkeys = Object.keys(defaultObj);
            arrkeys.forEach(function(ae){
                if(defaultSubscriptionPlan[0][ae]===undefined && ae!=='rm' && ae!=='submissionAgainstDemandSelect'){
                    notPresent[ae] = ''
                }
            });

            setSubscriptionPlan((prev) => ({
                ...defaultSubscriptionPlan[0],
                ...notPresent,
                propertyOrDemandSelect,
                submissionAgainstDemandSelect,
                rm: prev.rm,
            }));

        } else if (key === "relationshipManager") {
            delete errorObject["rm"];
            if (value === "No") {
                // delete subscription["rm"]
                setSubscriptionPlan({
                    ...subscriptionPlan,
                    rm: { id: "", name: "" },
                    [key]: value,
                });
            } else {
                setSubscriptionPlan({ ...subscriptionPlan, [key]: value });
            }
        } else if (key === "rm") {
            let rmObj = rmList?.filter((el) => value === el?._id);
            setSubscriptionPlan({ ...subscriptionPlan, [key]: rmObj[0] });
        } else {
            setSubscriptionPlan({ ...subscriptionPlan, [key]: value });
        }
    };

    const onKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            handleOnSubmit(e);
        }
    };

    useEffect(() => {
        if (fromCustomerSeller) {
            setSubscrptionEdit(true);
        }
    }, [location]);

    useEffect(() => {
        getPropertyTypeList();
        getStates();
        getRole();
        getRmList();
        getSubscription();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    
    useEffect(() => {
        // setInitLoading(true);
        if (
            stateList?.length > 0 &&
            rmList?.length > 0 &&
            propertyTypeList?.length > 0
        ) {
            let customerArrays = PhysicalTeamFieldArray()[0]?.fieldArray;
            customerArrays?.forEach((item) => {
                if (item.key === "state") {
                    item.option = stateList;
                }
                if (item.key === "propertyTypes") {
                    item.option = propertyTypeList;
                }
                if (item.key === "transactionType") {
                    item.option = [];
                }
            });
            setCustomerArray(customerArrays);
            setCustomerDetails(PhysicalTeamFieldArray()[0]?.fieldObject);
        }

        setTimeout(() => {
            getCustomerData();
        }, 2000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [edit, stateList, rmList, propertyTypeList]);

    return (
        <>
            {initLoading && <Loader className={"fixed"} />}
            {dataLoad && <Loader className={"fixed"} />}
            {customerView ? (
                <>
                    <NavBar />
                    {!initLoading && (
                        <div className="brandPageForm-container">
                            <Form
                                noValidate
                                onKeyDown={(e) => onKeyDown(e)}
                                validated={validated}
                                onSubmit={(e) => handleOnSubmit(e)}
                            >
                                <div className="row">
                                    <div className="col col-12 col-lg-6">
                                        <div className="d-flex company-radio-container">
                                            <p>Receive Demand From Agents</p>
                                            <Form.Check
                                                inline
                                                type="switch"
                                                checked={recieveDemands === true}
                                                disabled={location?.state?.view ? true : false}
                                                name="RecieveDemands"
                                                id={"inline-radio-1"}
                                                onChange={() => {
                                                    handleRecieveDemands(!recieveDemands);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* {successModel && (
                  <div className="row">
                    <Select
                      label="Select account handler"
                      name="accountHandler"
                      value={accountHandler}
                      disabled={location?.state?.view ? true : false}
                      onChange={(e) => {
                        setAccountHandler(e.target.value);
                        delete errorObject["accountHandlerId"];
                      }}
                      errorMessage={errorObject["accountHandlerId"]}
                      isInvalid={errorObject["accountHandlerId"] && true}
                      placeholder="Select"
                      options={accountHandlerList}
                      size={"w-40"}
                    />
                  </div>
                )} */}
                                <div className="row mb-3" id="form-dev">
                                    {customerArray?.length > 0 &&
                                        customerArray.map((item, i) => {
                                            // destructuring customer array
                                            let options =
                                                item.key === "cities" ? cityArray : item.option;
                                            const {
                                                key,
                                                type,
                                                inputType,
                                                placeholder,
                                                name,
                                                option,
                                            } = item;
                                            return (
                                                <React.Fragment key={i}>
                                                    {type === "input" && (
                                                        <RenderInput
                                                            disabled={location?.state?.view ? true : false}
                                                            name={name}
                                                            keyValue={key}
                                                            value={customerDetails[key]}
                                                            error={errorObject[key]}
                                                            inputType={inputType}
                                                            objName="customer"
                                                            placeholder={placeholder}
                                                            onChange={onChange}
                                                            edit={edit}
                                                        />
                                                    )}
                                                    {type === "phoneInput" && (
                                                        <RenderPhoneInput
                                                            disabled={location?.state?.view ? true : false}
                                                            name={name}
                                                            keyValue={key}
                                                            value={customerDetails[key]}
                                                            error={errorObject[key]}
                                                            inputType={inputType}
                                                            placeholder={placeholder}
                                                            onChange={onChange}
                                                        />
                                                    )}
                                                    {type === "textArea" && (
                                                        <RenderTextArea
                                                            disabled={location?.state?.view ? true : false}
                                                            name={name}
                                                            keyValue={key}
                                                            value={customerDetails[key]}
                                                            error={errorObject[key]}
                                                            placeholder={placeholder}
                                                            onChange={onChange}
                                                        />
                                                    )}
                                                    {type === "select" && (
                                                        <RenderSelect
                                                            option={option}
                                                            disabled={location?.state?.view ? true : false}
                                                            name={name}
                                                            keyValue={key}
                                                            value={customerDetails[key]}
                                                            error={errorObject[key]}
                                                            placeholder={placeholder}
                                                            onChange={onChange}
                                                            objName="customer"
                                                            user="developer"
                                                        />
                                                    )}
                                                    {type === "multiSelect" && (
                                                        <RenderMultiSelect
                                                            option={options}
                                                            name={name}
                                                            keyValue={key}
                                                            disabled={location?.state?.view ? true : false}
                                                            value={customerDetails[key]}
                                                            error={errorObject[key]}
                                                            placeholder={placeholder}
                                                            onChange={onChange}
                                                            objName="customer"
                                                        />
                                                    )}
                                                    {type === "coverImage" && (
                                                        <div className="col-md-6 w-40 col-sm-12">
                                                            <SingleImageUpload
                                                                multiple={false}
                                                                disabled={location?.state?.view ? true : false}
                                                                files={imageName ? imageName : []}
                                                                name="image"
                                                                id="image"
                                                                imageApi={(files) =>
                                                                    getImage(files, "image", errorObject)
                                                                }
                                                                placeholder="Upload cover image"
                                                                label="Upload cover image"
                                                                errorObject={errorObject}
                                                                setErrorObject={setErrorObject}
                                                                errorMessage={
                                                                    errorObject?.image ? errorObject?.image : ""
                                                                }
                                                                className="w-100"
                                                            />
                                                            <p className="f-12 mb-0">
                                                                *Cover image resolution must be 1440 X 348{" "}
                                                            </p>
                                                        </div>
                                                    )}
                                                    {type === "logo" && (
                                                        <div className="col-md-6 col-sm-12 w-40">
                                                            <SingleImageUpload
                                                                multiple={false}
                                                                disabled={location?.state?.view ? true : false}
                                                                files={logoName ? logoName : []}
                                                                name="logo"
                                                                id="logo"
                                                                imageApi={(files) =>
                                                                    getImage(files, "logo", errorObject)
                                                                }
                                                                placeholder="Upload logo"
                                                                label="Upload logo *"
                                                                errorObject={errorObject}
                                                                setErrorObject={setErrorObject}
                                                                errorMessage={
                                                                    errorObject?.logo ? errorObject?.logo : ""
                                                                }
                                                                className="w-100"
                                                            />
                                                        </div>
                                                    )}
                                                </React.Fragment>
                                            );
                                        })}
                                </div>
                                <div className="row">
                                    <div className="col col-6">
                                        <label title="Subscription" className="text-heading">
                                            Subscription
                                        </label>
                                        {!location?.state?.view && !subscriptionExpired && edit && (
                                            <span className="text-sm text-muted ms-3">
                                                {`(${subscriptionExpiryDate})`}
                                            </span>
                                        )}
                                    </div>
                                    <div className="col col-6">
                                        <div className="d-flex gap-3 float-end align-items-center">
                                            {subscriptionExpired && !location?.state?.view ? (
                                                <>
                                                    <span className="text-sm text-danger">
                                                        {" "}
                                                        {subscriptionExpiryDate}
                                                    </span>
                                                    <Button
                                                        type="button"
                                                        className="btn-color-primary d-block"
                                                        onClick={(e) => {
                                                            setShowRenewModal(true);
                                                        }}
                                                    >
                                                        <span>Renew Subscription</span>
                                                    </Button>
                                                </>
                                            ) : (
                                                !subscrptionEdit &&
                                                edit &&
                                                !location?.state?.view && (
                                                    <Button
                                                        type="button"
                                                        className="btn-outline-color-primary d-block border-0"
                                                        onClick={(e) => {
                                                            setSubscrptionEdit(true);
                                                        }}
                                                    >
                                                        <span className="me-2 text-blue ">Edit Plan</span>
                                                        <FaPen className="text-blue" />
                                                    </Button>
                                                )
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    {subscriptionArray?.length > 0 &&
                                        subscriptionArray.map((item, i) => {
                                            // destructuring subscription array
                                            const {
                                                key,
                                                type,
                                                inputType,
                                                placeholder,
                                                name,
                                                option,
                                                subKey,
                                            } = item;
                                            return (
                                                <React.Fragment key={i}>
                                                    {type === "input" ? (
                                                        <RenderInput
                                                            name={name}
                                                            keyValue={key}
                                                            value={subscriptionPlan[key]}
                                                            // disabled={
                                                            //     location?.state?.view ||
                                                            //         !subscrptionEdit ||
                                                            //         !subPlanChosen
                                                            //         ? true
                                                            //         : false
                                                            // }

                                                            disabled={
                                                                location?.state?.view ||
                                                                (!subscrptionEdit && edit)
                                                                  ? true
                                                                  : false
                                                              }
                                                            error={errorObject[key]}
                                                            inputType={inputType}
                                                            objName="subscription"
                                                            onChangeSubscription={onChangeSubscription}
                                                            edit={edit}
                                                            placeholder={placeholder}
                                                            subKey={subKey ? subKey : ""}
                                                            submissionInputShow={submissionInputShow}
                                                            propertyDemandInput={propertyDemandInput}
                                                            contactSuppliersShow={contactSuppliersShow}
                                                            size="w-50"
                                                        />
                                                    ) : (
                                                        <RenderSelect
                                                            name={name}
                                                            keyValue={key}
                                                            option={option}
                                                            value={subscriptionPlan[key]}
                                                            // disabled={
                                                            //     location?.state?.view ||
                                                            //         !subscrptionEdit ||
                                                            //         (key !== "planName" && !subPlanChosen)
                                                            //         ? true
                                                            //         : false
                                                            // }

                                                            disabled={
                                                                location?.state?.view ||
                                                                (!subscrptionEdit && edit)
                                                                  ? true
                                                                  : false
                                                              }
                                                            error={errorObject[key]}
                                                            placeholder={placeholder}
                                                            onChangeSubscription={onChangeSubscription}
                                                            objName="subscription"
                                                            subKey={subKey ? subKey : ""}
                                                            rmVal={subscriptionPlan?.relationshipManager}
                                                            setSubmissionInputShow={setSubmissionInputShow}
                                                            setPropertyDemandInput={setPropertyDemandInput}
                                                            setContactSuppliersShow={setContactSuppliersShow}
                                                            subscription={subscriptionPlan}
                                                            companyTypeReceived={companyTypeReceived}
                                                            size="w-50"
                                                        />
                                                    )}
                                                    {key === "planName" && <div />}
                                                </React.Fragment>
                                            );
                                        })}
                                </div>

                                <span className="error-message_span mb-3">{apiErrorData}</span>
                                <div className="row">
                                    <div className="col col-sm-12 col-md-12 col-lg-6 align-items-center">
                                        {subscrptionEdit && (
                                            <div className="btn-container mt-3 mb-5 float-lg-start">
                                                <>
                                                    <Button
                                                        type="button"
                                                        className="btn-outline-color-primary d-block"
                                                        onClick={(e) => {
                                                            setSubscriptionPlan(initialSubscriptionData);
                                                            setSubscrptionEdit(false);
                                                        }}
                                                        disabled={!subscrptionEdit}
                                                    >
                                                        Cancel
                                                    </Button>
                                                    {view ? null : (
                                                        <Button
                                                            type="button"
                                                            disabled={loading || !subscrptionEdit}
                                                            loading={loading}
                                                            className="btn-color-primary d-block"
                                                            onClick={handleSubscriptionEdit}
                                                        >
                                                            Update Subscription
                                                        </Button>
                                                    )}
                                                </>
                                            </div>
                                        )}
                                    </div>
                                    <div className="col col-sm-12 col-md-12 col-lg-6 d-flex align-items-end justify-content-end">
                                        {location?.state?.view ? null : (
                                            <div className="btn-container mt-3">
                                                <Button
                                                    type="button"
                                                    className="btn-outline-color-primary d-block"
                                                    onClick={(e) => navigate("/company")}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    type="submit"
                                                    disabled={loading}
                                                    loading={loading}
                                                    className="btn-color-primary d-block"
                                                >
                                                    {edit ? "Update Physical Team page" : "Create Physical Team Page"}
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Form>

                            {edit ? (
                                <div className="mt-4">
                                    <PocTable
                                        view={location?.state?.view}
                                        customerDetails={customerDetails}
                                        companyTypeReceived={companyTypeReceived}
                                        type={PHYSICALTEAM}
                                    />
                                </div>
                            ) : (
                                ""
                            )}
                            <EnterpriseAgentSubscriptionRenew
                                subscriptionArray={subscriptionArray}
                                showRenewModal={showRenewModal}
                                setShowRenewModal={setShowRenewModal}
                                companyId={companyId}
                                setRmList={setRmList}
                                rmList={rmList}
                                getCustomerData={getCustomerData}
                            />
                        </div>
                    )}
                </>
            ) : (
                <AccessDenied />
            )}
        </>
    );
};
PhysicalTeamFromPage.propTypes = {
    view: PropTypes.bool,
};
export default PhysicalTeamFromPage;