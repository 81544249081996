import { useEffect, useReducer, useState, useContext } from "react";
import MultiSelect from "../../components/select/dropDown";
import Search from "../../components/Search/search";
import { Col } from "react-bootstrap";
import { postExportData, postGetData } from "../../api";
import DatePicker from "react-datepicker";
import Button from "../../components/Form/Button";
import {
  convertIntoNameLabelObject,
  nameToLabel,
} from "../../utilities/commonFunction";
import { activityLogFilterOptions } from "./filterOpions";
import { ActivityLogsContext } from ".";

export const Filter = ({ tabKey }) => {
  const { filterDispatch, filterValues } = useContext(ActivityLogsContext);

  const initialState = {
    eventNameList: [{ label: "Events ", value: "select" }],
  };

  const reducer = (state, action) => {
    const stateKeys = {
      EventNameList: "eventNameList",
    };
    const stateKey = stateKeys[action.type];
    if (!stateKey) {
      return state;
    }

    return {
      [stateKey]: [state[stateKey], { label: "Events ", value: "select" }, ...action.value, ],
    };
  };

  const [filter, reducerDispatch] = useReducer(reducer, initialState);
  const [filterInputs, setFilterInputs] = useState([]);
  const [viewCities, setViewCities] = useState({ show: false, value: {} });
  const onClickViewCities = (value) => {
    setViewCities({ show: true, value: value });
  };
  const handelCloseCities = () => {
    setViewCities({ show: false, value: {} });
  };

  const setOptions = (key, data) => {
    let finalValue;
    if (key === "eventNameList") {
      finalValue = nameToLabel(data);
    } else {
      finalValue = nameToLabel(data);
    }
    const filterKey = {
      eventNameList: () =>
        reducerDispatch({ type: "EventNameList", value: finalValue }),
    };

    return filterKey[key];
  };

  const getFilterData = async (array) => {
    let url = "/eventLog/getAllEventName";
    let payLoad = {
      customerType:
        tabKey === "customerBuyer"
          ? "Enterprise Buyer"
          : tabKey === "customerSeller"
          ? "Enterprise Seller"
          : tabKey === "customerEAgent"
          ? "Enterprise Agent"
          : tabKey === "customerAgent"
          ? "agent"
          : "",
    };
    const apiDatas = await postGetData(url, {}, payLoad);
    const { data, statusCode } = apiDatas;
    if (statusCode === 200) {
      array?.map((item) => {
        if (data?.hasOwnProperty(item?.key)) {
          let returnFun = setOptions(item?.key, data[item?.key]);
          returnFun();
        }
        return item;
      });
    }
  };
  const handelSearch = (e) => {
    filterDispatch({
      type: "search",
      value: e.target.value,
    });
  };
  const onChangeSelect = (val, item) => {
    filterDispatch({
      value: val,
      type: item?.name,
      isMulti: item.isMulti ? "yes" : "no",
    });
  };
  const onClickExport = () => {
    onClickExportData();
  };
  useEffect(() => {
    let filterValue = [...activityLogFilterOptions];
    getFilterData(filterValue);
    setFilterInputs(filterValue);
  }, [tabKey]);
  const onClickExportData = async () => {
    let customerTabKey = localStorage.getItem("activityLogsTabKey");
    let customerTypeName;

    if (customerTabKey === "customerBuyer") {
      customerTypeName = "Brand";
    } else if (customerTabKey === "customerAgent") {
      customerTypeName = "Agent";
    } else if (customerTabKey === "customerEAgent") {
      customerTypeName = "EAgent";
    } else {
      customerTypeName = "Developer";
    }
    let url = "/eventLog/getAllEventLog";
    let payLoad = {
      ...(filterValues?.search && { search: filterValues?.search }),
      ...(filterValues?.eventNameList && {
        eventNameList: filterValues?.eventNameList?.value,
      }),
      ...(filterValues?.startDate && { startDate: filterValues?.startDate }),
      ...(filterValues?.endDate && { endDate: filterValues?.endDate }),
    };
    payLoad.pageNo = 1;
    payLoad.limit = 10;
    payLoad.customerType = customerTypeName;
    let filterPayload = { ...payLoad, reportType: "export" };

    postExportData(url, {}, filterPayload).then((blob) => {
      if (blob) {
        let urls = window.URL.createObjectURL(blob);
        let a = document.createElement("a");

        a.href = urls;

        a.download = `acitivityLogs-${customerTypeName}.xlsx`;
        a.click();
      }
    });
    //   let response = await postGetData(url, {}, payLoad);

    //   const { data, statusCode } = response;
    //   let dataKey = tableDataKey(page);
    //   if (statusCode === 200) {
    //     setTableData(data?.[dataKey]);
    //     const totalDoc = data?.totalDoc
    //       ? data?.totalDoc
    //       : data?.totalEventCount;
    //     setPageCount(Math.ceil(totalDoc / perPage));
    //     setTotalCount(totalDoc);
    //   } else {

    //     setPageCount(0);
    //     setTableData([]);
    //   }
  };
  return (
    <>
      {filterInputs?.length > 0 &&
        filterInputs.map((item, i) => {
          if (item?.fieldType === "search") {
            return (
              <Col
                md={3}
                sm={6}
                className="d-flex align-items-start pt-3"
                key={i}
                style={{ marginTop: "-8px" }}
              >
                <Search
                  size="search-input-width mb-3 "
                  onChange={handelSearch}
                  placeholder="Search"
                  value={filterValues[item?.name]}
                />
              </Col>
            );
          } else if (item?.fieldType === "select") {
            let value = item?.isMulti
              ? Object.keys(filterValues)?.length > 0 &&
                filterValues[item?.name]?.length > 0
                ? filterValues[item?.name]
                : []
              : Object.keys(filterValues)?.length > 0 &&
                filterValues[item?.name]?.value
              ? filterValues[item?.name]
              : item?.defaultValue[0];

            return (
              <Col md={3} sm={6} className="d-flex align-items-start " key={i}>
                <MultiSelect
                  disabled={false}
                  onChange={(val) => onChangeSelect(val, item)}
                  isMulti={item.isMulti}
                  name={""}
                  size={"select-multi"}
                  options={filter[item?.name]}
                  value={value}
                  lable={""}
                  placeholder={item?.defaultValue[0]?.label}
                  viewAllString={true}
                  view={item?.name === "regionList" ? true : false}
                  onClickViewList={(viewValue) => onClickViewCities(viewValue)}
                />
              </Col>
            );
          } else if (item?.fieldType === "export") {
            return (
              <Col
                md={3}
                sm={6}
                className="d-flex align-items-start mt-2 "
                key={i}
              >
                <Button
                  children={item?.name}
                  className={"btn-primary w-75"}
                  onClick={onClickExport}
                />
              </Col>
            );
          } else if (item?.fieldType === "datePicker") {
            return (
              <Col
                md={3}
                sm={6}
                className="d-flex flex-column justify-content-center align-items-center"
                key={i}
                style={{ marginTop: "-12px" }}
              >
                <div>
                  {item?.name === "startDate" ? "Start Date" : "End Date"}
                </div>
                <div className="">
                  {item.name === "startDate" ? (
                    <DatePicker
                      showIcon
                      selected={filterValues[item.name] ?? new Date()}
                      onChange={(val) => onChangeSelect(val, item)}
                      selectsStart
                      startDate={filterValues["startDate"] ?? new Date()}
                      endDate={filterValues["endDate"] ?? new Date()}
                      maxDate={new Date()}
                    />
                  ) : (
                    <DatePicker
                      showIcon
                      selected={filterValues[item.name] ?? new Date()}
                      onChange={(val) => onChangeSelect(val, item)}
                      selectsEnd
                      startDate={filterValues["startDate"] ?? new Date()}
                      endDate={filterValues["endDate"] ?? new Date()}
                      minDate={filterValues["startDate"] ?? new Date()}
                      maxDate={new Date()}
                    />
                  )}
                </div>
              </Col>
            );
          }
          return null;
        })}
    </>
  );
};
