import { useEffect, useState } from "react";

//library
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import lockIcon from "../../../assets/images/lock1_1.svg";

//fetch
import {
  deleteData,
  getAllSwitchUsers,
  getCurrentUser,
  getData,
  postData,
  putData,
  resetPassword,
  switchToUsers,
} from "../../../api/index";
//icons
import { BsFillEyeFill } from "react-icons/bs";
import { FaPen, FaTrash } from "react-icons/fa";

//components
import { useContext } from "react";
import { AppContext } from "../../../App";
import switchuser from "../../../assets/images/switchuser.png";
import Button from "../../../components/Form/Button";
import Loader from "../../../components/Loader";
import { ListPopup } from "../../../components/Modal/listModal";
import MainTable from "../../../components/Table/index";
import { isObjectHasValue } from "../../../utilities/commonFunction";
import {
  getTableStoreValue,
  setScrollBackId,
  setTableFilterData,
} from "../../../utilities/pageScroll";
import { updateAgentId } from "../../Login/reducer";
import { CustomerWarningPopup } from "./warningPopup";
import { CustomerDeleteWarningPopUp } from "./CustomerDeleteWarningPopUp";
import { MULTIPLE_SWITCH, SWITCH_LABEL } from "../../../../src/custom/constants";

function AgentTable(props) {
  let navigate = useNavigate();

  let tableName = "customerAgent";
  const { tableStoreContext, setTableStoreContext, pageScroll, setPageScroll } =
    useContext(AppContext);
  let scrollStoreArray = [...pageScroll];
  const currentTableValueFromConText = getTableStoreValue(
    tableName,
    tableStoreContext
  );
  const storetableName = currentTableValueFromConText?.tableName;
  const storePerPage = currentTableValueFromConText?.perPage;
  let checkForTableName = tableName === storetableName;
  const [initLoading, setInitLoading] = useState(false);

  //list
  const [listData, setListData] = useState([]);
  //pagination
  const [perPage, setPerPage] = useState(
    checkForTableName ? (storePerPage ? storePerPage : 10) : 10
  );
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  //active
  const [activeStatus, setActiveStatus] = useState(false);
  const [customerId, setCustomerId] = useState("");
  const [warningMessage, setWarningMessage] = useState("");
  const [warningPopup, setWarningPopup] = useState(false);
  const [showListPopup, setShowListPopup] = useState(false);
  const [cityList, setcityList] = useState([]);
  //Delete
  const [deleteWarningPopup, setDeleteWarningPopup] = useState(false);
  const [activeAgentId, setActiveAgentId] = useState("");
  //password reset
  const [passwordResetWarningPopup, setPasswordResetWarningPopup] =
    useState(false);
  const [currentUserId, setCurrentUserId] = useState("");

  let search = props.search ? props.search : "";

  const dispath = useDispatch();

  //Switch Acount
  const handleAccountSwitch = async ({ userId, companyId, SWITCH_URL = process.env.REACT_APP_SWITCH_URL}) => {
    const data = {
      companyId: companyId,
      customerId: userId,
      type: "user",
    };
    switchToUsers(data).then((res) => {
      let openUrl = SWITCH_URL;
      if (res.status) {
        window.open(`${openUrl}/${res?.data?.token}`);
      }
    });
  };

  // PASSWORD RESET
  const handleResetPassword = (row) => {
    setCurrentUserId(row?.userId?._id);
    setPasswordResetWarningPopup(true);
    setWarningMessage(
      "Are you sure, do you want to reset the login credentials?"
    );
  };

  const passwordResetWarningYesClick = () => {
    resetPassword(currentUserId).then(() => {
      setPasswordResetWarningPopup(false);
    });
  };

  const handlePasswordResetWarningClose = () => {
    setPasswordResetWarningPopup(false);
  };

  const setContextValue = (id) => {
    let scrollArray = setScrollBackId(
      tableName,
      id,
      props?.filter,
      search,
      scrollStoreArray
    );
    setPageScroll(scrollArray);
  };
  const updateTableStoreContext = (id) => {
    let newValue = setTableFilterData(
      tableName,
      { pageNo: props?.offSet, perPage: perPage, id: id },
      tableStoreContext
    );
    setTableStoreContext(newValue);
  };

  const getCustomerList = () => {
    setInitLoading(true);

    let url = `/brand/?pageNo=${
      props.offSet ? props.offSet : 1
    }&limit=${perPage}&type=${"Agent"}&userId=${getCurrentUser()._id}`;
    if (search) {
      url += `&search=${search}`;
    }
    if (props?.filter?.active === "Active") {
      url += `&isActive=${true}`;
    }
    if (props?.filter?.active === "Inactive") {
      url += `&isActive=${false}`;
    }
    if (
      props?.filter?.city?.label !== "City" &&
      props?.filter?.city?.label !== ""
    ) {
      url += `&cities=${props?.filter?.city?.label}&id=${props?.filter?.city?.value}`;
    }
    getData(url).then((response) => {
      if (response.status) {
        setInitLoading(false);
        setPageCount(Math.ceil(response.data.totalCount / perPage));
        setTotalCount(response.data.totalCount);
        setListData(response.data.customerList);
      } else {
        setInitLoading(false);
        setPageCount(0);
        setListData([]);
      }
    });
  };

  const warningYesClick = () => {
    let status = {
      isActive: activeStatus ? false : true,
    };
    let url = `/brand/${customerId}`;
    putData(url, {}, status).then((response) => {
      if (response.status) {
        getCustomerList();
        setWarningPopup(false);
      } else {
        setWarningPopup(false);
      }
    });
  };

  const deleteWarningYesClick = () => {
    let url = `/user/deleteAgent/${activeAgentId}`;
    deleteData(url, {}, {}).then((response) => {
      if (response.status) {
        getCustomerList();
        setDeleteWarningPopup(false);
      } else {
        setDeleteWarningPopup(false);
      }
    });
  };

  const handleWarningClose = (type) => {
    if (type === "delete") {
      setDeleteWarningPopup(false);
    } else {
      setWarningPopup(false);
    }
  };
  const onActiveStatusChange = (id, active) => {
    setActiveStatus(active);
    setCustomerId(id);
    setWarningPopup(true);
    if (active === true) {
      setWarningMessage("Are you sure, you want to deactivate this customer");
    } else if (active === false) {
      setWarningMessage("Are you sure, you want to activate this customer");
    }
  };

  function userButtonFormatterSeller(cell, row, rowIndex) {
    return (
      <div className="d-flex justify-content-center align-items-center ">
        {props?.access?.switchingCapabilityAgent ? (
          <>
          <div class="bg-white shadow-md rounded-lg overflow-hidden max-w-xs mx-auto mr-20">
            <img 
             style={{ cursor: "pointer", width: "17px", height: "17px" }}
             src={switchuser}
             onClick={() => {
                handleAccountSwitch({
                  userId: row?.userId?._id,
                  companyId: row?._id,
                });
             }}
             alt="Description of image" class="w-full"/>
             {MULTIPLE_SWITCH && 
              <div style={{padding : 2}}>
                  <p class="text-gray-700" style={{fontSize: '10px'}}>{SWITCH_LABEL.SWITCH1}</p>
              </div>}
          </div>
          
          {MULTIPLE_SWITCH && 
            <div class="bg-white shadow-md rounded-lg overflow-hidden max-w-xs mx-auto">
              <img 
              style={{ cursor: "pointer", width: "17px", height: "17px" }}
              src={switchuser}
              onClick={() => {
                handleAccountSwitch({
                  userId: row?.userId?._id,
                  companyId: row?._id,
                  SWITCH_URL: process.env.REACT_APP_REVAMP_SWITCH_URL
                });
              }}
              alt="Description of image" class="w-full"/>
              <div style={{padding : 2}}>
                  <p class="text-gray-700" style={{fontSize: '10px'}}>{SWITCH_LABEL.SWITCH2}</p>
              </div>
            </div>}

          </>
         
         
        ) : (
          ""
        )}
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Button
          onClick={() => {
            updateTableStoreContext(row._id);
            localStorage.removeItem("propTabKey");
            localStorage.setItem(
              "customerId",
              JSON.stringify(row?.userId?._id)
            );
            localStorage.setItem("agentId", JSON.stringify(row._id));
            dispath(updateAgentId(row._id));
            navigate(`/customer/agentPage/${row.slug}`, {
              state: { id: row._id, view: true },
            });
          }}
          disabled={props?.access?.viewAgentCustomers ? false : true}
          className={"table-btn p-0"}
        >
          <BsFillEyeFill />
        </Button>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Button
          onClick={() => {
            if (row.isActive) {
              updateTableStoreContext(row._id);
              let url = `/brand/${row._id}`;
              getData(url, {}, {}).then((response) => {
                if (response.status) {
                  navigate("/customer/agentPageCreation", {
                    state: {
                      customer: response.data,
                      id: row._id,
                      edit: true,
                    },
                  });
                }
              });
            }
          }}
          disabled={
            row.isActive && props?.access?.editAgentCustomers ? false : true
          }
          className={"table-btn p-0"}
        >
          <FaPen />
        </Button>{" "}
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Form.Check
          type="switch"
          id={`custom-switch_${rowIndex}}`}
          checked={row.isActive ? true : false}
          disabled={props?.access?.agentEnableDisable ? false : true}
          onChange={(e) => onActiveStatusChange(row._id, row.isActive)}
        />
        <Button
          className="table-btn p-0"
          onClick={(e) => {
            setActiveAgentId(row?.userId?._id);
            setDeleteWarningPopup(true);
          }}
          disabled={props?.access?.deleteAgentCustomers ? false : true}
        >
          <FaTrash />
        </Button>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        {getCurrentUser()?.role[0]?.name === "admin" ||
        getCurrentUser()?.role[0]?.name === "Master Admin" ? (
          <div
            className=""
            onClick={() => {
              handleResetPassword(row);
            }}
          >
            <img
              src={lockIcon}
              alt="lock icon"
              style={{
                cursor: "pointer",
                width: "20px",
                height: "20px",
                marginLeft: "-7px",
              }}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
  const handelOpenListPopup = (cityData) => {
    setcityList(cityData);
    setShowListPopup(true);
  };
  const handelChangePerPage = (count) => {
    setPerPage(count);
    props?.setOffset(1);
  };
  const columnsBuyer = [
    {
      dataField: "sno",
      text: "S.no",
    },
    {
      dataField: "contactPerson",
      text: "First Name",
      formatter: (cellContent, row, rowIndex) => {
        if (row.contactPerson === null) {
          return "-";
        } else if (row.contactPerson && row.contactPerson) {
          return row.contactPerson;
        }
      },
    },
    {
      dataField: "userId",
      text: "Last Name",
      formatter: (cellContent, row, rowIndex) => {
        return row?.userId?.lastName ? row?.userId?.lastName : "-";
      },
    },
    {
      dataField: "city",
      text: "City",
      formatter: (cellContent, row, rowIndex) => {
        if (row.cities === null || row?.cities?.length === 0) {
          return "-";
        } else if (row?.cities?.length > 0) {
          let cityArray = [];

          row?.cities?.map((val, index) => {
            cityArray.push(val?.name);
            return val;
          });
          return (
            <>
              {cityArray?.length > 1 ? (
                <span>
                  {`${cityArray[0]},..`}
                  <span
                    className="view-all-btn cursor-pointer"
                    onClick={() => handelOpenListPopup(cityArray)}
                  >
                    (View all)
                  </span>
                </span>
              ) : (
                <span>{cityArray.toString()}</span>
              )}
            </>
          );
        }
      },
    },
    {
      dataField: "count",
      text: "Property/Demands Uploads",
      formatter: (cellContent, row, rowIndex) => {
        if (String(row.count) === null) {
          return "-";
        } else if (String(row.count)) {
          return String(row.count);
        }
      },
    },
    {
      dataField: "rm",
      text: "Relationship Manager",
      formatter: (cellContent, row, rowIndex) => {
        if (
          row.rm === null ||
          row?.subscriptionPlanId?.rm === null ||
          (!("rm" in row) &&
            !(row?.subscriptionPlanId && "rm" in row?.subscriptionPlanId)) ||
          row?.subscriptionPlanId?.relationshipManager === "No"
        ) {
          return "-";
        } else {
          if (
            row?.subscriptionPlanId?.rm &&
            row?.subscriptionPlanId?.rm?.name
          ) {
            return row?.subscriptionPlanId?.rm?.name;
          } else {
            return row?.rm?.name;
          }
        }
      },
    },
    {
      dataField: "companyId",
      text: "Subscription Valid Till",
      formatter: (cellContent, row, rowIndex) => {
        if (
          row?.subscriptionPlanId?.validDate === null ||
          row?.subscriptionPlanId?.validDate === undefined ||
          row?.subscriptionPlanId?.validDate === ""
        ) {
          return "-";
        } else if (row?.subscriptionPlanId) {
          return new Date(
            row?.subscriptionPlanId?.validDate
          ).toLocaleDateString();
        }
      },
    },
    {
      dataField: "createdAt",
      text: "Created on",
      formatter: (cellContent, row, rowIndex) => {
        if (
          row.createdAt === null ||
          row.createdAt === "" ||
          !("createdAt" in row)
        ) {
          return "-";
        } else if (row?.createdAt) {
          return new Date(row?.createdAt).toLocaleString();
        }
      },
    },

    {
      dataField: "action",
      text: "Action",
      formatter: userButtonFormatterSeller,
    },
  ];
  //
  const tableProps = {
    data: listData,
    columns: columnsBuyer,
    offset: props?.offSet,
    setOffset: props?.setOffset,
    handelChangePerPage,
    pageCount,
    perPage,
    totalCount,
    tableName: "customerAgent",
    setPerPage,
  };
  const onCloseCityListPopup = () => {
    setcityList([]);
    setShowListPopup(false);
  };

  useEffect(() => {
    getCustomerList();
    if (search || isObjectHasValue(props?.filter)) {
      setContextValue();
    }
  }, [perPage, props?.offSet, search, props?.filter]);
  return (
    <div>
      {warningPopup && (
        <CustomerWarningPopup
          show={warningPopup}
          message={warningMessage}
          warningYesClick={warningYesClick}
          onClose={handleWarningClose}
        />
      )}
      {deleteWarningPopup && (
        <CustomerDeleteWarningPopUp
          show={deleteWarningPopup}
          title={"Confirmation"}
          message={"Are you sure, you want to delete this agent"}
          warningYesClick={deleteWarningYesClick}
          onClose={() => handleWarningClose("delete")}
        />
      )}
      {initLoading && <Loader className={"fixed"} />}
      <MainTable {...tableProps} />
      {showListPopup && (
        <ListPopup
          onClose={onCloseCityListPopup}
          lists={cityList}
          show={showListPopup}
          title="Cities"
        />
      )}
      {passwordResetWarningPopup && (
        <CustomerWarningPopup
          title={"Confirmation"}
          show={passwordResetWarningPopup}
          message={warningMessage}
          warningYesClick={passwordResetWarningYesClick}
          onClose={handlePasswordResetWarningClose}
        />
      )}
    </div>
  );
}

export default AgentTable;
