export const activityLogsColumns = {
  Brand: [
    {
      dataField: "sno",
      text: "S.no",
      formatter: false,
    },
    // {
    //   dataField: "demandIdFormatter",
    //   text: "Demand ID",
    //   formatter: true,
    // },
    {
      dataField: "brandNameFormatterForActivityLog",
      text: "Brand name",
      formatter: true,
    },
    {
      dataField: "doneByUser.name",
      text: "POC name",
      formatter: false,
    },
    {
      dataField: "eventTypeFormatterForActivityLog",
      text: "Events",
      formatter: true,
    },
    {
      dataField: "demandIdFormatterLog",
      text: "Demand ID",
      formatter: true,
    },
    {
      dataField: "propertyIdFormatterLog",
      text: "Property ID",
      formatter: true,
    },

    {
      dataField: "cityFormatterForActivityLog",
      text: "City",
      formatter: true,
    },
    {
      dataField: "createdAtActionForActivityLog",
      text: "Timestamp",
      formatter: true,
    },
  ],
  Developer: [
    {
      dataField: "sno",
      text: "S.no",
      formatter: false,
    },
    // {
    //   dataField: "demandIdFormatter",
    //   text: "Demand ID",
    //   formatter: true,
    // },
    {
      dataField: "brandNameFormatterForActivityLog",
      text: "Developer name",
      formatter: true,
    },
    {
      dataField: "doneByUser.name",
      text: "POC name",
      formatter: false,
    },
    {
      dataField: "eventTypeFormatterForActivityLog",
      text: "Events",
      formatter: true,
    },
    {
      dataField: "demandIdFormatterLog",
      text: "Demand ID",
      formatter: true,
    },
    {
      dataField: "propertyIdFormatterLog",
      text: "Property ID",
      formatter: true,
    },

    {
      dataField: "cityFormatterForActivityLog",
      text: "City",
      formatter: true,
    },
    {
      dataField: "createdAtActionForActivityLog",
      text: "Timestamp",
      formatter: true,
    },
  ],
  EAgent: [
    {
      dataField: "sno",
      text: "S.no",
      formatter: false,
    },
    // {
    //   dataField: "demandIdFormatter",
    //   text: "Demand ID",
    //   formatter: true,
    // },
    {
      dataField: "brandNameFormatterForActivityLog",
      text: "Agent name",
      formatter: true,
    },
    {
      dataField: "doneByUser.name",
      text: "Agent POC name",
      formatter: false,
    },
    {
      dataField: "eventTypeFormatterForActivityLog",
      text: "Events",
      formatter: true,
    },
    {
      dataField: "demandIdFormatterLog",
      text: "Demand ID",
      formatter: true,
    },
    {
      dataField: "propertyIdFormatterLog",
      text: "Property ID",
      formatter: true,
    },

    {
      dataField: "cityFormatterForActivityLog",
      text: "City",
      formatter: true,
    },
    {
      dataField: "createdAtActionForActivityLog",
      text: "Timestamp",
      formatter: true,
    },
  ],
  Agent: [
    {
      dataField: "sno",
      text: "S.no",
      formatter: false,
    },
    // {
    //   dataField: "demandIdFormatter",
    //   text: "Demand ID",
    //   formatter: true,
    // },
    {
      dataField: "brandNameFormatterForActivityLog",
      text: "Agent name",
      formatter: true,
    },
    {
      dataField: "donebyuserFormatterForActivityLog",
      text: "Done by",
      formatter: true,
    },
    {
      dataField: "eventTypeFormatterForActivityLog",
      text: "Events",
      formatter: true,
    },
    {
      dataField: "demandIdFormatterLog",
      text: "Demand ID",
      formatter: true,
    },
    {
      dataField: "propertyIdFormatterLog",
      text: "Property ID",
      formatter: true,
    },

    {
      dataField: "cityFormatterForActivityLog",
      text: "City",
      formatter: true,
    },
    {
      dataField: "createdAtActionForActivityLog",
      text: "Timestamp",
      formatter: true,
    },
  ],
  "Physical Team": [
    {
      dataField: "sno",
      text: "S.no",
      formatter: false,
    },
    // {
    //   dataField: "demandIdFormatter",
    //   text: "Demand ID",
    //   formatter: true,
    // },
    {
      dataField: "brandNameFormatterForActivityLog",
      text: "Agent name",
      formatter: true,
    },
    {
      dataField: "doneByUser.name",
      text: "Agent POC name",
      formatter: false,
    },
    {
      dataField: "eventTypeFormatterForActivityLog",
      text: "Events",
      formatter: true,
    },
    {
      dataField: "demandIdFormatterLog",
      text: "Demand ID",
      formatter: true,
    },
    {
      dataField: "propertyIdFormatterLog",
      text: "Property ID",
      formatter: true,
    },

    {
      dataField: "cityFormatterForActivityLog",
      text: "City",
      formatter: true,
    },
    {
      dataField: "createdAtActionForActivityLog",
      text: "Timestamp",
      formatter: true,
    },
  ],
};
