export const sellerUrls = {
  developerList: () => "/admin/developerNew",
  developerPocList: () => "/admin/developerPocNew",
  cityPocList: () => "/admin/cityPocNew",
  propertyAcceptedList: () => "/admin/propertyAccepted",
  propertyRejectedList: () => "/admin/propertyAccepted",
  needMoreInfoList: () => "/admin/propertyAccepted",
  propertyContactedList: () => "/admin/propertyContacted",
  propertyPostedList: () => "/admin/popertyPosted",
  propertyPostedAgainstList: () => "/admin/popertyPosted",
  propertyResponseList: () => "/admin/popertyPosted",
  propertyInActiveList: () => "/admin/popertyPosted",
  propertyActiveList: () => "/admin/popertyPosted",
  mailingLogs: () => "/eventLog/getMailingLogForAdmin",
};
export const sellerFilterUrls = {
  developerList: () => "/admin/brandFilterNew",
  developerPocList: () => "/admin/pocFilterNew",
  cityPocList: () => "/admin/pocFilterNew",
  propertyAcceptedList: () => "/admin/propertyReportFilter",
  propertyRejectedList: () => "/admin/propertyReportFilter",
  needMoreInfoList: () => "/admin/propertyReportFilter",
  propertyContactedList: () => "/admin/propertyReportFilter",
  propertyPostedList: () => "/admin/popertyPostedFilter",
  propertyPostedAgainstList: () => "/admin/popertyPostedFilter",
  propertyResponseList: () => "/admin/popertyPostedFilter",
  propertyInActiveList: () => "/admin/popertyPostedFilter",
  propertyActiveList: () => "/admin/popertyPostedFilter",
  mailingLogs: () => "/eventLog/getAllEventName",
};
