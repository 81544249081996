export const cardArray = [
  {
    name: "buyer",
    value: [
      {
        id: 1,
        name: "totalCompanies",
        label: "Company",
        path: "/buyerReport/companyList",
        pageName: "companyList",
      },
      {
        id: 2,
        name: "totalCompanyPoc",
        label: "Company POC",
        path: "/buyerReport/pocList",
        pageName: "companyPocList",
      },
      {
        id: 3,
        name: "totalBrands",
        label: "No.of brands",
        path: "/buyerReport/brandList",
        pageName: "brandList",
      },
      {
        id: 4,
        name: "totalBrandPoc",
        label: "Brand POC",
        path: "/buyerReport/pocList",
        pageName: "brandPocList",
      },

      {
        id: 5,
        name: "totalRegionPoc",
        label: "Region POC",
        path: "/buyerReport/pocList",
        pageName: "regionPocList",
      },
      {
        id: 6,
        name: "totalDemandPosted",
        path: "/buyerReport/demandList",
        pageName: "demandPostedList",
        label: "Demands posted",
      },
      {
        id: 7,
        name: "totalDemandResponses",
        label: "Demands responses",
        path: "/buyerReport/demandList",
        pageName: "demandResponseList",
      },
      {
        id: 8,
        name: "totalActiveDemands",
        label: "Active demands",
        path: "/buyerReport/demandList",
        pageName: "activeDemandList",
      },

      {
        id: 9,
        name: "totalInActiveDemands",
        label: "In-Active demands",
        path: "/buyerReport/demandList",
        pageName: "inActiveDemandList",
      },
      {
        id: 10,
        name: "totalPropertiesContected",
        label: "Properties contacted",
        path: "/buyerReport/demandList",
        pageName: "propertyContactedList",
      },
      {
        id: 11,
        name: "totalPropertiesAccepted",
        label: "Properties accepted",
        path: "/buyerReport/demandList",
        pageName: "propertyAcceptedList",
      },
      {
        id: 12,
        name: "totalPropertiesRejected",
        label: "Properties rejected",
        path: "/buyerReport/demandList",
        pageName: "propertyRejectedList",
      },
      {
        id: 13,
        name: "totalNeedFurtherInformation",
        label: "Need further information",
        path: "/buyerReport/demandList",
        pageName: "needMoreInfoList",
      },
      {
        id: 14,
        name: "mailingLogsCount",
        label: "Mailing Logs",
        path: "/buyerReport/demandList",
        pageName: "mailingLogs",
      },
    ],
  },
  {
    name: "seller",
    value: [
      {
        id: 14,
        name: "totalDevelopers",
        label: "Developer",
        path: "/sellerReport/developerList",
        pageName: "developerList",
      },
      {
        id: 15,
        name: "totalDeveloperPoc",
        label: "Developer POC",
        path: "/sellerReport/pocList",
        pageName: "developerPocList",
      },
      {
        id: 16,
        name: "totalCityPoc",
        label: "City POC",
        path: "/sellerReport/pocList",
        pageName: "cityPocList",
      },
      {
        id: 17,
        name: "totalPropertiesPosted",
        label: "Properties posted",
        path: "/sellerReport/propertyList",
        pageName: "propertyPostedList",
      },
      {
        id: 18,
        name: "totalPropertiesResponses",
        label: "Properties responses",
        path: "/sellerReport/propertyList",
        pageName: "propertyResponseList",
      },
      {
        id: 19,
        name: "totalPropertiesPostedAgainstDemands",
        label: "Properties posted against demands",
        path: "/sellerReport/propertyList",
        pageName: "propertyPostedAgainstList",
      },

      {
        id: 20,
        name: "totalActiveProperties",
        path: "/sellerReport/propertyList",
        pageName: "propertyActiveList",
        label: "Active properties",
      },
      {
        id: 21,
        name: "totalInActiveProperties",
        path: "/sellerReport/propertyList",
        pageName: "propertyInActiveList",
        label: "In-Active properties",
      },

      {
        id: 22,
        name: "totalPropertiesContected",
        label: "Properties contacted",
        path: "/sellerReport/propertyList",
        pageName: "propertyContactedList",
      },

      {
        id: 23,
        name: "totalPropertiesAccepted",
        label: "Properties accepted",
        path: "/sellerReport/propertyList",
        pageName: "propertyAcceptedList",
      },
      {
        id: 24,
        name: "totalPropertiesRejected",
        label: "Properties rejected",
        path: "/sellerReport/propertyList",
        pageName: "propertyRejectedList",
      },
      {
        id: 25,
        name: "totalNeedFurtherInformation",
        label: "Need further information",
        path: "/sellerReport/propertyList",
        pageName: "needMoreInfoList",
      },
      {
        id: 14,
        name: "mailingLogsCount",
        label: "Mailing Logs",
        path: "/sellerReport/propertyList",
        pageName: "mailingLogs",
      },
      {
        id: 26,
        name: "projects",
        label: "Projects",
        path: "/dashboard/enterpriseSeller/Projects",
        pageName: "needMoreInfoList",
      },
      {
        id: 27,
        name: "projectLeads",
        label: "Project Leads",
        path: "/dashboard/enterpriseSeller/projectLeads",
        pageName: "needMoreInfoList",
      },
    ],
  },
  {
    name: "enterpriseAgent",
    value1: [
      {
        id: 25,
        name: "totalEAgents",
        label: "Enterprise Agent",
        path: "/enterpriseAgentReport/agentList",
        pageName: "agentList",
      },
      {
        id: 40,
        name: "totalEAgentPoc",
        label: "Agent POC",
        path: "/enterpriseAgentReport/agentpocList",
        pageName: "agentPocList",
      },

      {
        id: 26,
        name: "totalPropertiesPosted",
        label: "Properties posted",
        path: "/enterpriseAgentReport/propertyList",
        pageName: "propertyPostedList",
      },
      {
        id: 27,
        name: "totalPropertiesResponses",
        label: "Properties responses",
        path: "/enterpriseAgentReport/propertyList",
        pageName: "propertyResponseList",
      },
      {
        id: 28,
        name: "totalPropertiesPostedAgainstDemands",
        label: "Properties posted against demands",
        path: "/enterpriseAgentReport/propertyList",
        pageName: "propertyPostedAgainstList",
      },

      {
        id: 29,
        name: "totalActiveProperties",
        path: "/enterpriseAgentReport/propertyList",
        pageName: "propertyActiveList",
        label: "Active properties",
      },
      {
        id: 30,
        name: "totalInActiveProperties",
        path: "/enterpriseAgentReport/propertyList",
        pageName: "propertyInActiveList",
        label: "In-Active properties",
      },

      {
        id: 31,
        name: "totalPropertiesContected",
        label: "Properties contacted",
        path: "/enterpriseAgentReport/propertyList",
        pageName: "propertyContactedList",
      },

      {
        id: 32,
        name: "totalPropertiesAccepted",
        label: "Properties accepted",
        path: "/enterpriseAgentReport/propertyList",
        pageName: "propertyAcceptedList",
      },
      {
        id: 32,
        name: "totalPropertiesRejected",
        label: "Properties rejected",
        path: "/enterpriseAgentReport/propertyList",
        pageName: "propertyRejectedList",
      },
      {
        id: 33,
        name: "totalNeedFurtherInformation",
        label: "Need further information",
        path: "/enterpriseAgentReport/propertyList",
        pageName: "needMoreInfoList",
      },
      {
        id: 34,
        name: "mailingLogsCount",
        label: "Mailing Logs",
        path: "/enterpriseAgentReport/propertyList",
        pageName: "mailingLogs",
      },
    ],
    value2: [
      {
        id: 1,
        name: "totalDemandPosted",
        label: "Demand posted",
        path: "/enterpriseAgentReport/demandList",
        pageName: "demandPostedList",
      },
      {
        id: 2,
        name: "totalDemandResponses",
        label: "Demand responses",
        path: "/enterpriseAgentReport/demandList",
        pageName: "demandResponseList",
      },
      {
        id: 4,
        name: "totalActiveDemands",
        path: "/enterpriseAgentReport/demandList",
        pageName: "activeDemandList",
        label: "Active demands",
      },
      {
        id: 5,
        name: "totalInActiveDemands",
        label: "In-Active demands",
        path: "/enterpriseAgentReport/demandList",
        pageName: "inActiveDemandList",
      },
      {
        id: 6,
        name: "totalPropertiesContectedAgent",
        label: "Properties contacted",
        path: "/enterpriseAgentReport/demandList",
        pageName: "propertyContactedDemandList",
      },

      // {
      //   id: 6,
      //   name: "totalPropertiesContected",
      //   label: "Properties contacted",
      //   path: "/agent-report/property-list",
      //   pageName: "propertyContactedList",
      // },

      // {
      //   id: 7,
      //   name: "totalPropertiesAccepted",
      //   label: "Properties accepted",
      //   path: "/agent-report/property-list",
      //   pageName: "propertyAcceptedList",
      // },
    ],
    value3: [
      {
        id: 1,
        name: "totalContectSeen",
        label: "Total Contact Seen",
        path: "/enterpriseAgentReport/contacts",
        pageName: "demandContactVisibility",
      },
      // {
      //   id: 1,
      //   name: "totalContectReceived",
      //   label: "Contact Visibility",
      //   path: "/agentReport/demandList",
      //   pageName: "demandContactVisibility",
      // },
      // {
      //   id: 1,
      //   name: "totalContectRequested",
      //   label: "Viewed Contact",
      //   path: "/agentReport/demandList",
      //   pageName: "demandViewedContact",
      // },
    ],
  },
  {
    name: "agent",
    value1: [
      {
        id: 25,
        name: "totalAgents",
        label: "Agent",
        path: "/agentReport/agentList",
        pageName: "agentList",
      },

      {
        id: 26,
        name: "totalPropertiesPosted",
        label: "Properties posted",
        path: "/agentReport/propertyList",
        pageName: "propertyPostedList",
      },
      {
        id: 27,
        name: "totalPropertiesResponses",
        label: "Properties responses",
        path: "/agentReport/propertyList",
        pageName: "propertyResponseList",
      },
      {
        id: 28,
        name: "totalPropertiesPostedAgainstDemands",
        label: "Properties posted against demands",
        path: "/agentReport/propertyList",
        pageName: "propertyPostedAgainstList",
      },

      {
        id: 29,
        name: "totalActiveProperties",
        path: "/agentReport/propertyList",
        pageName: "propertyActiveList",
        label: "Active properties",
      },
      {
        id: 30,
        name: "totalInActiveProperties",
        path: "/agentReport/propertyList",
        pageName: "propertyInActiveList",
        label: "In-Active properties",
      },

      {
        id: 31,
        name: "totalPropertiesContected",
        label: "Properties contacted",
        path: "/agentReport/propertyList",
        pageName: "propertyContactedList",
      },

      {
        id: 32,
        name: "totalPropertiesAccepted",
        label: "Properties accepted",
        path: "/agentReport/propertyList",
        pageName: "propertyAcceptedList",
      },
      {
        id: 32,
        name: "totalPropertiesRejected",
        label: "Properties rejected",
        path: "/agentReport/propertyList",
        pageName: "propertyRejectedList",
      },
      {
        id: 33,
        name: "totalNeedFurtherInformation",
        label: "Need further information",
        path: "/agentReport/propertyList",
        pageName: "needMoreInfoList",
      },
      {
        id: 34,
        name: "mailingLogsCount",
        label: "Mailing Logs",
        path: "/agentReport/propertyList",
        pageName: "mailingLogs",
      },
    ],
    value2: [
      {
        id: 1,
        name: "totalDemandPosted",
        label: "Demand posted",
        path: "/agentReport/demandList",
        pageName: "demandPostedList",
      },
      {
        id: 2,
        name: "totalDemandResponses",
        label: "Demand responses",
        path: "/agentReport/demandList",
        pageName: "demandResponseList",
      },
      {
        id: 4,
        name: "totalActiveDemands",
        path: "/agentReport/demandList",
        pageName: "activeDemandList",
        label: "Active demands",
      },
      {
        id: 5,
        name: "totalInActiveDemands",
        label: "In-Active demands",
        path: "/agentReport/demandList",
        pageName: "inActiveDemandList",
      },
      {
        id: 6,
        name: "totalPropertiesContectedAgent",
        label: "Properties contacted",
        path: "/agentReport/demandList",
        pageName: "propertyContactedDemandList",
      },

      // {
      //   id: 6,
      //   name: "totalPropertiesContected",
      //   label: "Properties contacted",
      //   path: "/agent-report/property-list",
      //   pageName: "propertyContactedList",
      // },

      // {
      //   id: 7,
      //   name: "totalPropertiesAccepted",
      //   label: "Properties accepted",
      //   path: "/agent-report/property-list",
      //   pageName: "propertyAcceptedList",
      // },
    ],
    value3: [
      {
        id: 1,
        name: "totalContectSeen",
        label: "Total Contact Seen",
        path: "/agentReport/contacts",
        pageName: "demandContactVisibility",
      },
      // {
      //   id: 1,
      //   name: "totalContectReceived",
      //   label: "Contact Visibility",
      //   path: "/agentReport/demandList",
      //   pageName: "demandContactVisibility",
      // },
      // {
      //   id: 1,
      //   name: "totalContectRequested",
      //   label: "Viewed Contact",
      //   path: "/agentReport/demandList",
      //   pageName: "demandViewedContact",
      // },
    ],
  },
  {
    name: "physicalTeam",
    value1: [
      {
        id: 25,
        name: "totalPhysicalTeamCount",
        label: "Physical Team",
        path: "/physicalTeamReport/physicalTeamList",
        pageName: "physicalTeamList",
      },
      {
        id: 40,
        name: "totalPhysicalTeamPoc",
        label: "Pyhsical Team POC",
        path: "/physicalTeamReport/physicalTeamPocList",
        pageName: "physicalTeamPocList",
      },

      {
        id: 26,
        name: "totalPropertiesPosted",
        label: "Properties posted",
        path: "/physicalTeamReport/propertyList",
        pageName: "propertyPostedList",
      },
      {
        id: 27,
        name: "totalPropertiesResponses",
        label: "Properties responses",
        path: "/physicalTeamReport/propertyList",
        pageName: "propertyResponseList",
      },
      {
        id: 28,
        name: "totalPropertiesPostedAgainstDemands",
        label: "Properties posted against demands",
        path: "/physicalTeamReport/propertyList",
        pageName: "propertyPostedAgainstList",
      },

      {
        id: 29,
        name: "totalActiveProperties",
        path: "/physicalTeamReport/propertyList",
        pageName: "propertyActiveList",
        label: "Active properties",
      },
      {
        id: 30,
        name: "totalInActiveProperties",
        path: "/physicalTeamReport/propertyList",
        pageName: "propertyInActiveList",
        label: "In-Active properties",
      },

      {
        id: 31,
        name: "totalPropertiesContected",
        label: "Properties contacted",
        path: "/physicalTeamReport/propertyList",
        pageName: "propertyContactedList",
      },

      {
        id: 32,
        name: "totalPropertiesAccepted",
        label: "Properties accepted",
        path: "/physicalTeamReport/propertyList",
        pageName: "propertyAcceptedList",
      },
      {
        id: 35,
        name: "totalPropertiesRejected",
        label: "Properties rejected",
        path: "/physicalTeamReport/propertyList",
        pageName: "propertyRejectedList",
      },
      {
        id: 33,
        name: "totalNeedFurtherInformation",
        label: "Need further information",
        path: "/physicalTeamReport/propertyList",
        pageName: "needMoreInfoList",
      },
      {
        id: 34,
        name: "mailingLogsCount",
        label: "Mailing Logs",
        path: "/physicalTeamReport/propertyList",
        pageName: "mailingLogs",
      },
    ],
    value2: [
      {
        id: 1,
        name: "totalDemandPosted",
        label: "Demand posted",
        path: "/physicalTeamReport/demandList",
        pageName: "demandPostedList",
      },
      {
        id: 2,
        name: "totalDemandResponses",
        label: "Demand responses",
        path: "/physicalTeamReport/demandList",
        pageName: "demandResponseList",
      },
      {
        id: 4,
        name: "totalActiveDemands",
        path: "/physicalTeamReport/demandList",
        pageName: "activeDemandList",
        label: "Active demands",
      },
      {
        id: 5,
        name: "totalInActiveDemands",
        label: "In-Active demands",
        path: "/physicalTeamReport/demandList",
        pageName: "inActiveDemandList",
      },
      {
        id: 6,
        name: "totalPropertiesContectedAgent",
        label: "Properties contacted",
        path: "/physicalTeamReport/demandList",
        pageName: "propertyContactedDemandList",
      },

      // {
      //   id: 6,
      //   name: "totalPropertiesContected",
      //   label: "Properties contacted",
      //   path: "/physicalTeamReport/property-list",
      //   pageName: "propertyContactedList",
      // },

      // {
      //   id: 7,
      //   name: "totalPropertiesAccepted",
      //   label: "Properties accepted",
      //   path: "/physicalTeamReport/property-list",
      //   pageName: "propertyAcceptedList",
      // },
    ],
    value3: [
      {
        id: 1,
        name: "totalContectSeen",
        label: "Total Contact Seen",
        path: "/physicalTeamReport/contacts",
        pageName: "demandContactVisibility",
      },
      // {
      //   id: 1,
      //   name: "totalContectReceived",
      //   label: "Contact Visibility",
      //   path: "/physicalTeamReport/demandList",
      //   pageName: "demandContactVisibility",
      // },
      // {
      //   id: 1,
      //   name: "totalContectRequested",
      //   label: "Viewed Contact",
      //   path: "/physicalTeamReport/demandList",
      //   pageName: "demandViewedContact",
      // },
    ],
    value4: [
      {
        id: 1,
        name: "projects",
        label: "Projects",
        path: "/dashboard/enterpriseSeller/Projects",
        pageName: "demandContactVisibility",
      },
      {
        id: 2,
        name: "projectLeads",
        label: "Project Leads",
        path: "/dashboard/enterpriseSeller/projectLeads",
        pageName: "demandContactVisibility",
      },
    ],
  },
];
