import { Form } from "react-bootstrap";
import { BsFillEyeFill } from "react-icons/bs";
import { FaPen } from "react-icons/fa";
import Button from "../../../../../components/Form/Button";
import { buyerFilterUrls } from "./url";
// table response key
export const tableDataKey = (key) => {
  if (!key) return "";
  const lookup = {
    companyList: "dataList",
    companyPocList: "dataList",
    regionPocList: "dataList",
    brandList: "dataList",
    brandPocList: "dataList",
    demandPostedList: "demands",
    inActiveDemandList: "demands",
    activeDemandList: "demands",
    demandResponseList: "demands",
    propertyContactedList: "responseData",
    propertyAcceptedList: "responseData",
    propertyRejectedList: "responseData",
    needMoreInfoList: "responseData",
    mailingLogs: "data",
  };

  const dataKey = lookup[key];
  return dataKey;
};

export const tableTotalDataKey = (key) => {
  if (!key) return "";
  const lookup = {
    companyList: "totalDataCount",
    companyPocList: "totalDataCount",
    brandList: "totalDataCount",
    brandPocList: "totalDataCount",
    regionPocList: "totalDataCount",
  };

  const dataKey = lookup[key];
  return dataKey;
};

// table list api Payload
export const getPayLoad = (data, offset, perPage) => {
  const defaultPayload = {
    pageNo: offset,
    limit: perPage,
  };
  const payloadMap = {
    companyList: {
      pageNo: offset,
      limit: perPage,
      type: "Brand",
    },
    companyPocList: {
      pageNo: offset,
      limit: perPage,
      roleType: "Enterprise Buyer",
    },
    brandPocList: {
      pageNo: offset,
      limit: perPage,
      roleType: "Enterprise Buyer",
      poc: "brandPoc"
    },
    regionPocList: {
      pageNo: offset,
      limit: perPage,
      roleType: "Enterprise Buyer",
      poc: "regionPoc"
    },
    inActiveDemandList: {
      pageNo: offset,
      limit: perPage,
      isActive: false,
      demandType: "brand",
    },
    activeDemandList: {
      pageNo: offset,
      limit: perPage,
      isActive: true,
      demandType: "brand",
    },
    demandResponseList: {
      pageNo: offset,
      limit: perPage,
      response: true,
    },
    demandPostedList: {
      pageNo: offset,
      limit: perPage,
      demandType: "brand",
    },
    propertyAcceptedList: {
      pageNo: offset,
      limit: perPage,
      demandStatus: "completed",
      propertyType: "accept",
    },
    propertyRejectedList: {
      pageNo: offset,
      limit: perPage,
      demandStatus: "rejected",
    },
    needMoreInfoList: {
      pageNo: offset,
      limit: perPage,
      propertyType: "needInfo",
    },
    mailingLogs: {
      pageNo: offset,
      limit: perPage,
      customerType: "Enterprise Buyer",
    },
    export: {
      reportType: "export",
    },
  };
  return payloadMap[data] || defaultPayload;
};
export const getFilterURL = (key) => {
  if (!buyerFilterUrls.hasOwnProperty(key)) return "";
  return buyerFilterUrls[key]();
};

export const getFilterPayLoad = (data, offset, perPage) => {
  const defaultPayload = {
    pageNo: offset,
    limit: perPage,
  };
  const payloadMap = {
    companyList: {
      type: "Brand",
    },
    brandList: {
      type: "Brand",
    },
    companyPocList: {
      pocType: "companyPoc",
    },
    brandPocList: {
      pocType: "brandPoc",
      type: "Brand"
    },
    regionPocList: {
      pocType: "regionPoc",
      type: "Brand"
    },
    demandResponseList: {
      response: true,
    },
    inActiveDemandList: {
      isActive: false,
    },
    activeDemandList: {
      isActive: true,
    },
    propertyContactedList: {
      propertyType: "contectSeller",
    },
    propertyAcceptedList: {
      demandStatus: "completed",
      propertyType: "accept",
    },
    propertyRejectedList: {
      demandStatus: "rejected",
    },
    needMoreInfoList: {
      propertyType: "needInfo",
    },
    mailingLogs: {
      isEmail: true,
      customerType: "Enterprise Buyer",
    },
  };

  return payloadMap[data] || defaultPayload;
};
//view formatter
export function viewbuttonFormatter(onClick, access, type) {
  return (cell, row, rowIndex) => {
    return (
      <div className="d-flex justify-content-center align-items-center table-row">
        <Button
          className="table-btn p-0"
          disabled={
            type === "company"
              ? access?.viewCompanyBuyer
                ? false
                : true
              : access?.viewBuyerCustomers
              ? false
              : true
          }
          onClick={() => onClick(row)}
        >
          <BsFillEyeFill />
        </Button>{" "}
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </div>
    );
  };
}
// poc view,edit,disableand enable formatter

export function userButtonFormatterPocForCompany(
  page,
  openModal,
  openWaring,
  table,
  access
) {
  return (cell, row, rowIndex) => {
    let brandId = page === "companyPocList" ? row?.companyId : row?.brandId;
    return (
      <div className="d-flex justify-content-center align-items-center ">
        <Button
          className="table-btn p-0"
          disabled={access?.viewCompanyBuyer ? false : true}
          onClick={() => openModal("view", brandId, row)}
        >
          <BsFillEyeFill />
        </Button>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Button
          className="table-btn p-0"
          disabled={row?.isActive && access?.editCompanyBuyer ? false : true}
          onClick={() => openModal("edit", brandId, row)}
        >
          <FaPen />
        </Button>{" "}
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Form.Check
          type="switch"
          id={`custom-switch_${rowIndex}}`}
          checked={row.isActive ? true : false}
          onChange={() => openWaring(row, row.isActive, table)}
          // disabled={props.access.enable?false:true}
          disabled={access.companyBuyerEnableDisable ? false : true}
        />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </div>
    );
  };
}
export function userButtonFormatterPoc(
  page,
  openModal,
  openWaring,
  table,
  access
) {
  return (cell, row, rowIndex) => {
    let brandId = page === "companyPocList" ? row?.companyId : row?.brandId;
    return (
      <div className="d-flex justify-content-center align-items-center ">
        <Button
          className="table-btn p-0"
          disabled={access?.viewBuyerCustomers ? false : true}
          onClick={() => openModal("view", brandId, row)}
        >
          <BsFillEyeFill />
        </Button>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Button
          className="table-btn p-0"
          disabled={row?.isActive && access?.editBuyerCustomers ? false : true}
          onClick={() => openModal("edit", brandId, row)}
        >
          <FaPen />
        </Button>{" "}
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Form.Check
          type="switch"
          id={`custom-switch_${rowIndex}}`}
          checked={row.isActive ? true : false}
          onChange={() => openWaring(row, row.isActive, table)}
          // disabled={props.access.enable?false:true}
          disabled={access.buyerEnableDisable ? false : true}
        />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </div>
    );
  };
}
export function userButtonFormatterForDemand(
  page,
  openModal,
  openWaring,
  table,
  access
) {
  return (cell, row, rowIndex) => {
    let brandId = page === "companyPocList" ? row?.companyId : row?.brandId;
    return (
      <div>
        <div className="d-flex justify-content-center align-items-center ">
          <Button
            className="table-btn p-0"
            disabled={
              row?.demandType === "agent"
                ? access?.agentView
                  ? false
                  : true
                : access?.view
                ? false
                : true
            }
            onClick={() => openModal("view", brandId, row)}
          >
            <BsFillEyeFill />
          </Button>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Button
            className="table-btn p-0"
            disabled={
              row?.demandType === "agent"
                ? row?.isActive && access?.agentEdit
                  ? false
                  : true
                : row?.isActive && access?.edit
                ? false
                : true
            }
            onClick={() => openModal("edit", brandId, row)}
          >
            <FaPen />
          </Button>{" "}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Form.Check
            type="switch"
            id={`custom-switch_${rowIndex}}`}
            checked={row.isActive ? true : false}
            onChange={() => openWaring(row, row.isActive, table)}
            // disabled={props.access.enable?false:true}
            disabled={
              row?.demandType === "agent"
                ? access?.agentEnable
                  ? false
                  : true
                : access.enable
                ? false
                : true
            }
          />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
      </div>
    );
  };
}

// city formatter
export function cityFormatter(handelOpenListPopup, key) {
  return (cell, row, rowIndex) => {
    const cities = row[key]
      ?.filter((city) => city?.name)
      .map((city) => city.name);
    const cityString = cities?.join(", ");

    if (!cityString) {
      return "-";
    }
    return (
      <>
        {cities.length > 1 ? (
          <span>
            {`${cities[0]},..`}
            <span
              className="view-all-btn cursor-pointer"
              onClick={() => handelOpenListPopup(cities, key)}
            >
              (View all)
            </span>
          </span>
        ) : (
          <span>{cityString}</span>
        )}
      </>
    );
  };
}
// property id formatter for multiple properites
export function propertyIdFormatterForMallingLogs(handelOpenListPopup, key) {
  return (cell, row, rowIndex) => {
    const properties = row[key];
    const propertiesIds = properties.map((property) => {
      return property?.productId?.display ? property?.productId?.display : "-";
    });
    return (
      <>
        {properties.length > 1 ? (
          <span>
            {`${propertiesIds[0]},..`}
            <span
              className="view-all-btn cursor-pointer"
              onClick={() => handelOpenListPopup(propertiesIds, key)}
            >
              (View all)
            </span>
          </span>
        ) : properties.length === 1 ? (
          <span>{propertiesIds[0]}</span>
        ) : (
          <span>-</span>
        )}
      </>
    );
  };
}
// property Id formatter for Malling logs
export function propertyIdFormatterForMallingLog() {
  return (cell, row, rowIndex) => {
    if (row?.productId?.display) {
      return row?.productId?.display;
    } else {
      return "-";
    }
  };
}

// property Id formatter
export function onClickIdFormater(key, onClickView) {
  return (cell, row, rowIndex) => {
    return (
      <div className="f-14 fw-700 table-link">
        {" "}
        <span className="cursor-pointer" onClick={() => onClickView(row)}>
          {row[key]?.display}
        </span>
      </div>
    );
  };
}



// city formatter for activity log
export function cityFormatterForActivityLog(key) {
  return (cell, row, rowIndex) => {
    if (row?.event === "RESPONSE_ACTION") {
      if (row?.propertyData?.location) {
        return row.propertyData.location;
      } else {
        return "-";
      }
    } else {
      if (row?.demandData?.location) {
        return row?.demandData?.location;
      } else {
        return "-";
      }
    }
  };
}

// brand name formatter for mailling log
export function brandNameFormatterForMallingLog() {
  return (cell, row, rowIndex) => {
    // if (row?.loginType === "Switch") {
    //   if (row?.behalfOf?.brandName) {
    //     return row.behalfOf?.brandName;
    //   } else {
    //     return "-";
    //   }
    // } else {
    //   if (row?.doneByUser?.brandName) {
    //     return row?.doneByUser?.brandName;
    //   } else if (row?.behalfOf?.brandName) {
    //     return row.behalfOf?.brandName;
    //   } else {
    //     return "-";
    //   }
    // }
    if (row?.otherInfo?.brandName) {
      return row?.otherInfo?.brandName;
    } else if (row?.behalfOf?.brandName) {
      return row.behalfOf?.brandName;
    } else if(row?.otherInfo === null) {
      return row.doneByUser?.brandName;
    } else {
      return "-";
    }
  };
}

// created formatter
export function createdOnFormatter(key = "createdAt") {
  return (cell, row, rowIndex) => {
    if (!row[key] || row[key] === null || row[key] === "" || !(key in row)) {
      return "-";
    }
    return new Date(row[key]).toLocaleString();
  };
}
// count formatter
export function onClickCountFormater(key, onClickView) {
  return (cell, row, rowIndex) => {
    return (
      <div className="f-14 fw-700 table-link">
        {" "}
        <span className="cursor-pointer" onClick={() => onClickView(row)}>
          {row[key]}
        </span>
      </div>
    );
  };
}
export function companyNameFormatter() {
  return (cell, row, rowIndex) => {
    return row?.company?.companyName ? row?.company?.companyName : "-";
  };
}
export function projectNameFormatter() {
  return (cell, row, rowIndex) => {
    return row?.projectName !== "" ? row?.projectName : "-";
  };
}
export function properSizeFormatter() {
  return (cell, row, rowIndex) => {
    return `${row.carpetAreaMin ? row.carpetAreaMin : 0}-${
      row.carpetAreaMax ? row.carpetAreaMax : 0
    } sqft`;
  };
}
export function brandNameWithLogoFormatter() {
  return (cell, row, rowIndex) => {
    if (!row?.name) {
      return "-";
    }
    return (
      <div className="brand-with-logo">
        <div>{row?.name}</div>
        <div className="logo">
          <img src={row?.logo?.viewUrl} alt={`brandLogo ${rowIndex}`} />
        </div>
      </div>
    );
  };
}
