import Button from "../../../../components/Form/Button";
import { BsFillEyeFill } from "react-icons/bs";
import { FaPen } from "react-icons/fa";
import {  Form } from "react-bootstrap";




export function viewbuttonFormatter(onClick,access) {
  return (cell, row, rowIndex) => {
    return (
      
        <Button
          className="d-flex table-btn p-0 m-auto"
          disabled={
            access?.viewCompanySeller
              ? false
              : true
          }
          onClick={() => onClick(row)}
        >
          <BsFillEyeFill />{" "}
        </Button>
    );
  };
}

  
  export function userButtonFormatterPoc(page,openModal,openWaring,table,access) {
    return (cell, row, rowIndex) => {
      let brandId=page==="companyPocList"?row?.companyId:row?.brandId;
      return (
          <div className="d-flex justify-content-center align-items-center ">
          <Button
            className="table-btn p-0"
            disabled={
              access?.viewSellerCustomers
                ? false
                : true
            }
            onClick={() => openModal("view", brandId, row)}
          >
            <BsFillEyeFill/>
          </Button>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Button
            className="table-btn p-0"
            disabled={
             row?.isActive && access?.editSellerCustomers
                ? false
                : true
            }
            onClick={() => openModal("edit",brandId, row)}
          >
            <FaPen />
          </Button>{" "}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Form.Check
            type="switch"
            id={`custom-switch_${rowIndex}}`}
            checked={row.isActive ? true : false}
            onChange={() => openWaring(row, row.isActive, table)}
            // disabled={props.access.enable?false:true}
            disabled={
            access.sellerEnableDisable
                ? false
                : true
            }
          />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
      );
    };
  }
  export function userButtonFormatterForProperty(page,openModal,openWaring,table,access) {
    return (cell, row, rowIndex) => {
      let brandId=page==="companyPocList"?row?.companyId:row?.brandId;
      return (
          <div className="d-flex justify-content-center align-items-center ">
          <Button
            className="table-btn p-0"
            disabled={
              row?.demandType === "agent"
                ? access?.agentView
                  ? false
                  : true
                : access?.view
                ? false
                : true
            }
            onClick={() => openModal("view", brandId, row)}
          >
            <BsFillEyeFill/>
          </Button>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Button
            className="table-btn p-0"
            disabled={
              row?.demandType === "agent"
                ? row?.isActive && access?.agentEdit
                  ? false
                  : true
                : row?.isActive && access?.edit
                ? false
                : true
            }
            onClick={() => openModal("edit",brandId, row)}
          >
            <FaPen />
          </Button>{" "}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Form.Check
            type="switch"
            id={`custom-switch_${rowIndex}}`}
            checked={row.isActive ? true : false}
            onChange={() => openWaring(row, row.isActive, table)}
            // disabled={props.access.enable?false:true}
            disabled={
              row?.demandType === "agent"
                ? access?.agentEnable
                  ? false
                  : true
                : access.enable
                ? false
                : true
            }
          />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
      );
    };
  }
 
  export function postedByFormatter() {
    return (cell, row, rowIndex) => {
      if (row?.brandNameRequired) {
        return `${row.brandId ? row.brandId?.name : "Anonymous"}`;
      }
      return "Anonymous";
    };
  }
 
  export function properSizeFormatter() {
    return (cell, row, rowIndex) => {
      return `${row.carpetAreaMin ? row.carpetAreaMin : 0}-${
        row.carpetAreaMax ? row.carpetAreaMax : 0
      } sqft`;
    };
  }
 
  export function createdOnFormatter(key="createdAt") {
    return (cell, row, rowIndex) => {
      if (!row[key] || row[key] === null || row[key] === "" || !(key in row)) {
        return "-";
      }
      return new Date(row[key]).toLocaleString();
    };
  }
  export function cityFormatter(handelOpenListPopup) {
    return (cell, row, rowIndex) => {
      const cities = row?.cities
        ?.filter((city) => city?.name)
        .map((city) => city.name);
      const cityString = cities?.join(", ");

      if (!cityString) {
        return "-";
      }
      return (
        <>
          {cities.length > 1 ? (
            <span>
              {`${cities[0]},..`}
              <span
                className="view-all-btn cursor-pointer"
                onClick={() => handelOpenListPopup(cities)}
              >
                (View all)
              </span>
            </span>
          ) : (
            <span>{cityString}</span>
          )}
        </>
      );
    }
  
  }
  export function onClickIdFormater(key,onClickView,toPage="") {
    return (cell, row, rowIndex) => {
      return <div className="btn f-14 fw-700 btn btn-link link-btn table-link-btn"> <span className="cursor-pointer" onClick={()=>onClickView(row,toPage)}>{row[key]?.display}</span></div>;
    }
  
  }
  export function companyNameFormatter(){
    return (cell, row, rowIndex) => {
      return row?.company?.companyName
    }
  }

  export function propertyTypeFormatter(){
    return (cell, row, rowIndex) => {
      return row?.propertyType?.name
    }
  }

  // optional field
  export function projectNameFormatter(){
    return (cell, row, rowIndex) => {
      return row?.projectName !== "" ? row?.projectName : "-"
    }
  } 

  export function onClickCountFormater(key,onClickView) {
    return (cell, row, rowIndex) => {
      return <div className="btn f-14 fw-700 btn btn-link link-btn table-link-btn"> <span className="cursor-pointer" onClick={()=>onClickView(row)}>{row[key]}</span></div>;

    }
  
  }
 
  