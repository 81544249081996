import { useState, useEffect, useRef } from "react";

import Swal from "sweetalert2/dist/sweetalert2.js";
//
import { Form } from "react-bootstrap";

//components
import Input from "../../components/Form/Input";
import Select from "../../components/Form/Select";
import FormModal from "../../components/Modal";
import Button from "../../components/Form/Button";
import Loader from "../../components/Loader";
import PhoneNumberInput from "../../components/Form/Input/phoneNumberInput";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import ProfilePic from "../../assets/images/profilePic.jpg";

//api
import {
  getData,
  putData,
  postData,
  postUploadData,
  putUploadData,
} from "../../api";

export default function AdminAddAndEdit({
  onClose,
  show,
  label,
  value,
  adminId,
}) {
  const ref = useRef();
  const emailField = useRef(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [initLoading, setInitLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [changeImage, setChangeImage] = useState(false);
  const [addAdmin, setAddAdmin] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    password: "",
    city: "",
    roles: "",
  });

  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [roleData, setRoleData] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [isLoading, setIsLoading] = useState("");
  const [validated, setValidated] = useState(false);
  const [apiErrorData, setApiErrorData] = useState("");
  const geteditClientsData = async (id) => {
    let url = `/user/getSubAdmin/${id}`;
    const userData = await getData(url, {}, {});
    const { data, statusCode } = userData;
    if (statusCode === 200) {
      setAddAdmin({
        ...data,
        roles: data?.roles[0],
        uploadFiles: data?.profilePic,
      });
      setImage(data?.profilePic?.viewUrl);
    } else {
      setAddAdmin({
        name: "",
        email: "",
        phoneNumber: "",
        password: "",
        // country: "",
        // state: "",
        city: "",
        roles: "",
      });
      setImage(ProfilePic);
    }
  };

  const getCities = () => {
    let url = "/country/getAllCities";
    getData(url, {}, {}).then((response) => {
      setCityList(response.data);
    });
  };

  const handleChanges = (e) => {
    const { name } = e.target;
    if (Object.keys(errorMessage).length > 0) {
      let error = { ...errorMessage };
      delete error[name];

      setErrorMessage(error);
    }
    const datas = { ...addAdmin };
    datas[name] = e.target.value;
    if (datas.phoneNumber.length === 11) return false;

    setAddAdmin(datas);
  };
  const getRolesFunction = async () => {
    let url = "/role/roletype?type=admin";
    const apiDatas = await getData(url);
    const { data, statusCode } = apiDatas;
    if (statusCode === 200) {
      setRoleData(data);
    } else {
      setRoleData([]);
    }
  };

  const handleSelectInput = (selectedValue, key) => {
    if (Object.keys(errorMessage).length > 0) {
      let error = { ...errorMessage };
      delete error[key];
      setErrorMessage(error);
    }
    let valueData = roleData.find((el) => el._id === selectedValue);
    let val = {
      name: valueData?.name ? valueData?.name : "",
      _id: valueData?._id ? valueData?._id : "",
      type: "admin",
    };

    setAddAdmin({ ...addAdmin, [key]: val });
  };
  const CustomValidator = () => {
    let emailRegx =
      /^(?![^@]*([-.])\1)(?![^@]*([-.])(?:[^@]*\2){2})\w(?:[\w.-]*\w)?@(?:[a-zA-Z]+\.)+[a-zA-Z]+$/;
    // let passwordRegx =
    let error = {};
    let message = "This field is required";
    let datas = ["name", "email", "phoneNumber", "city", "roles"];
    if (label !== "Edit") {
      datas.push("password");
    }
    datas.map((item) => {
      if (!(item in addAdmin)) {
        error[item] = message;
      } else {
        if (addAdmin[item]?.length === 0) {
          error[item] = message;
        } else if (item === "phoneNumber") {
          if (addAdmin[item]?.length < 10) {
            error[item] = "Enter a valid phone number.";
          }
        } else if (item === "name") {
          if (/[^a-z, ]/i.test(addAdmin[item])) {
            error[item] = "Enter only characters.";
          }
        } else if (item === "roles") {
          if (!addAdmin[item]?._id) {
            error[item] = message;
          }
        } else {
          delete error[item];
        }
      }
      return item;
    });
    if (!addAdmin.email.match(emailRegx)) {
      error.email = "Please enter valid email id";
    }
    return error;
  };
  const updateAdmin = async (id) => {
    setErrorMessage("");
    let checkData = {
      name: addAdmin.name,
      email: addAdmin.email,
      phoneNumber: `${addAdmin.phoneNumber}`,
      // country: addAdmin?.country,
      ...(image && { uploadfiles: image }),
      // state: addAdmin.state,
      city: addAdmin.city,
      roles: addAdmin.roles,
    };
    let data = new FormData();
    data.append("name", addAdmin.name);
    data.append("email", addAdmin.email);
    data.append("phoneNumber", addAdmin.phoneNumber);
    addAdmin?.uploadFiles && data.append("image", addAdmin?.uploadFiles);
    data.append("toRemove", !image || image == ProfilePic);
    data.append("city", addAdmin.city?.cityId || addAdmin.city?._id);
    data.append("roles", addAdmin.roles?._id);

    setApiErrorData("");
    let url = `/user/updateSubAdmin/${id}`;
    setIsLoading(true);
    const apiDatas = await putUploadData(url, {}, data);
    const { statusCode, message } = apiDatas;
    if (statusCode === 200) {
      setIsLoading(false);
      setImage();
      setAddAdmin({
        name: "",
        email: "",
        phoneNumber: "",
        password: "",
        // country: "",
        // state: "",
        city: "",
        roles: "",
      });
      onClose("updated");
      setErrorMessage();
      setApiErrorData("");
      Swal.fire({
        // title: header,
        text: message,
        icon: "success",
        timer: 3000,
        buttons: false,
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: true,
      });
    } else if (statusCode === 400 || statusCode === 422) {
      // setErrorMessage();
      setIsLoading(false);
      setApiErrorData(message);
    } else {
      setIsLoading(false);
      Swal.fire({
        // title: header,
        text: message,
        icon: "error",
        timer: 3000,
        buttons: false,
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: true,
      });
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    let err = CustomValidator();
    if (err === undefined || Object.keys(err).length === 0) {
      if (form.checkValidity() === true) {
        if (adminId) {
          updateAdmin(adminId);
        } else {
          addAdminData();
        }
      }
    } else {
      setErrorMessage(err);
    }
  };

  const addAdminData = async () => {
    let url = "/user/addAdmin";
    setIsLoading(true);
    let checkData = {
      name: addAdmin.name,
      email: addAdmin.email,
      phoneNumber: `${addAdmin.phoneNumber}`,
      password: addAdmin?.password,
      // country: addAdmin?.country,
      ...(image && { uploadfiles: image }),
      // state: addAdmin.state,
      city: addAdmin.city?._id,
      roles: addAdmin.roles?._id,
    };
    let data = new FormData();
    data.append("name", addAdmin.name);
    data.append("email", addAdmin.email);
    data.append("password", addAdmin.password);
    data.append("phoneNumber", addAdmin.phoneNumber);
    image && data.append("image", addAdmin?.uploadFiles);
    data.append("city", addAdmin.city?._id);
    data.append("roles", addAdmin.roles?._id);

    const adminData = await postUploadData(url, {}, data);
    const { statusCode, message } = adminData;
    if (statusCode === 200) {
      setIsLoading(false);
      onClose("updated");
      setAddAdmin({
        name: "",
        email: "",
        phoneNumber: "",
        password: "",
        // country: "",
        // state: "",
        city: "",
        roles: "",
      });
      setImage();
      setErrorMessage();
      Swal.fire({
        // title: header,
        text: message,
        icon: "success",
        timer: 3000,
        buttons: false,
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: true,
      });
      setApiErrorData("");
    } else if (statusCode === 400 || statusCode === 422) {
      // setErrorMessage();
      setIsLoading(false);
      setApiErrorData(message);
    } else {
      setIsLoading(false);
      Swal.fire({
        // title: header,
        text: message,
        icon: "error",
        timer: 3000,
        buttons: false,
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: true,
      });
    }
  };

  const handleUploadImage = async (e) => {
    setInitLoading(true);
    setErrorMessage({});
    let formData = new FormData();
    // console.log(, "files")
    formData.append("uploadfiles", e.target.files[0]);

    setAddAdmin((prev) => ({
      ...prev,
      ["uploadFiles"]: e.target.files[0],
    }));
    setImage(URL.createObjectURL(e.target.files[0]));
    // let url = "/brand/file-upload/?type=image&module=brand";
    // const newDatas = await postUploadData(url, {}, datas);
    // const { data, statusCode, status } = newDatas;
    // if (status && statusCode === 200) {
    //   setInitLoading(false);

    //   let newObj = { ...image };
    //   newObj["logo"] = data[0];
    //   // setImage(newObj);
    // } else {
    //   setInitLoading(false);
    // }
  };

  const handleRemoveImage = () => {
    ref.current.value = "";
    setImage(ProfilePic);
  };

  const onChange = (fieldValue, key) => {
    if (Object.keys(errorMessage).length > 0) {
      let error = { ...errorMessage };
      delete error[key];
      setErrorMessage(error);
    }
    if (key === "city") {
      let values = cityList.find((el) => el._id === fieldValue);

      let val = {
        name: values?.name ? values?.name : "",
        _id: values?._id ? values?._id : "",
      };
      setAddAdmin({ ...addAdmin, [key]: val });
    } else {
      setAddAdmin({ ...addAdmin, [key]: fieldValue });
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter" && event.target.tagName !== "TEXTAREA") {
      event.preventDefault();
      handleSubmit(event);
    }
  };
  useEffect(() => {
    getRolesFunction();
    // getCountryList();
    getCities();

    if (adminId) geteditClientsData(adminId);
  }, []);

  return (
    <>
      <div>
        <FormModal
          onClose={onClose}
          show={show}
          heading={`${label}  Admin`}
          size={"md"}
          loading={false}
          loader="fixed"
        >
          <div className="mt-1">
            <div className="select-head">
              <Form
                noValidate
                validated={validated}
                onSubmit={(e) => handleSubmit(e)}
                onKeyDown={handleKeyPress}
              >
                {
                  <div className="profile-image-container">
                    <div className="fw-700 f-14 text-black">Profile Image</div>
                    <div className="image-container">
                      <div className="admin-profile-container">
                        <img
                          src={image || ProfilePic}
                          alt="images"
                          className="admin-profile"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = ProfilePic;
                          }}
                        />
                      </div>
                    </div>
                    <label className="change-text f-14 primary-color">
                      {`${
                        label == "Add"
                          ? image && image !== ProfilePic
                            ? "Change"
                            : label
                          : image && image !== ProfilePic
                          ? "Change"
                          : "Add"
                      } Image`}
                      <input
                        ref={ref}
                        name={"profilePicture"}
                        id={"profilePicture"}
                        type="file"
                        className="d-none"
                        // multiple={props.multiple?true:false}
                        onChange={handleUploadImage}
                        multiple={true}
                        accept="image/png, image/jpeg, image/jpg"
                      />
                    </label>
                    {image && image !== ProfilePic && (
                      <span
                        className="change-text f-14 error-message_span"
                        onClick={() => {
                          handleRemoveImage();
                        }}
                      >
                        Remove
                      </span>
                    )}
                  </div>
                }
                {/* {changeImage &&
                  <>
                    <Input
                      errorMessage={errorMessage?.profileImage && errorMessage?.profileImage}
                      label={"Profile Image"}
                      type={"file"}
                      name={"image"}
                      placeholder={"Profile Image"}
                      onChange={handleUploadImage}
                      value={addAdmin?.profileImage}
                      isInvalid={errorMessage?.profileImage && true}
                    />
                    <div className="change-text f-14" onClick={() => { setChangeImage(false) }}>Cancel</div>
                  </>
                } */}
                {/* <label>
                  Change profile picture
                  <input
                    name={"profilePicture"}
                    id={"profilePicture"}
                    type="file"
                    className="d-none"
                    // multiple={props.multiple?true:false}
                    onChange={handleUploadImage}
                    multiple={true}
                    accept="image/png, image/jpeg"
                  />
                </label> */}
                <Input
                  errorMessage={errorMessage?.name && errorMessage?.name}
                  label={"Name *"}
                  type={"text"}
                  name={"name"}
                  placeholder={"Enter Admin Name"}
                  onChange={handleChanges}
                  value={addAdmin?.name}
                  isInvalid={errorMessage?.name && true}
                />
                <Input
                  ref={emailField}
                  errorMessage={errorMessage?.email && errorMessage?.email}
                  label={"Email *"}
                  type={"email"}
                  name={"email"}
                  placeholder={"Enter Email"}
                  autocomplete={"new-password"}
                  onChange={handleChanges}
                  value={addAdmin?.email}
                  isInvalid={errorMessage?.email && true}
                />
                <PhoneNumberInput
                  isInvalid={!!errorMessage?.phoneNumber}
                  min={""}
                  errorMessage={
                    errorMessage?.phoneNumber && errorMessage?.phoneNumber
                  }
                  label={"Phone Number *"}
                  type={"number"}
                  name={"phoneNumber"}
                  placeholder={"Enter Phone Number"}
                  onChange={handleChanges}
                  value={addAdmin?.phoneNumber}
                />
                {label !== "Edit" ? (
                  <>
                    <div className="mb-2 input-position-style">
                      <Input
                        errorMessage={
                          errorMessage?.password && errorMessage?.password
                        }
                        type={isRevealPwd ? "text" : "password"}
                        isInvalid={errorMessage?.password && true}
                        label={"Password *"}
                        name={"password"}
                        autocomplete={"new-password"}
                        placeholder={"Enter Password"}
                        value={addAdmin?.password}
                        className={"input-padding-icon"}
                        onChange={handleChanges}
                      />
                      <span
                        className="eye-icon-style"
                        onClick={() =>
                          setIsRevealPwd((prevState) => !prevState)
                        }
                      >
                        {" "}
                        {isRevealPwd ? (
                          <AiOutlineEye />
                        ) : (
                          <AiOutlineEyeInvisible />
                        )}{" "}
                      </span>
                    </div>
                  </>
                ) : null}

                <Select
                  errorMessage={errorMessage?.city && errorMessage?.city}
                  label={"City *"}
                  options={cityList}
                  name={"city"}
                  isInvalid={errorMessage?.city && true}
                  value={addAdmin.city && addAdmin?.city?.cityId}
                  onChange={(e) => {
                    onChange(e.target.value, "city");
                  }}
                />

                <Select
                  errorMessage={errorMessage?.roles && errorMessage?.roles}
                  label={"Assign Role *"}
                  options={roleData}
                  name={"roles"}
                  isInvalid={errorMessage?.roles && true}
                  value={addAdmin.roles && addAdmin?.roles?._id}
                  onChange={(e) => handleSelectInput(e.target.value, "roles")}
                  disabled={
                    adminId && addAdmin?.roles?.name === "Account Handler"
                  }
                />
                <span className="error-message_span mb-3">{apiErrorData}</span>

                <div className="d-flex justify-content-end  gap-3 ">
                  <Button
                    type={"button"}
                    className={"btn-outline-color-primary"}
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    type={"submit"}
                    className="btn-color-primary"
                    loading={isLoading}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </FormModal>

        {isLoading && <Loader className={"fixed"} />}
      </div>
    </>
  );
}
