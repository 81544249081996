import React, { useEffect, useState } from "react";

//library
import { Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

//fetch
import {
  AccessDenied,
  getData,
  postData,
  postGetData,
  postUploadData,
  putData,
  updateSubscription,
} from "../../../api/index";

//components
import Button from "../../../components/Form/Button";
import TextArea from "../../../components/Form/TextArea";
import Loader from "../../../components/Loader";
import SingleImageUpload from "../../../components/imageUpload/singleImageUpload";

// custom components
import {
  RenderInput,
  RenderMultiSelect,
  RenderPhoneInput,
  RenderSelect,
} from "./customComponents/inputGroups";

//validate
import { errorCheck } from "./brandErrorCheck";
import { brandErrorCheck } from "../../../components/subscriptionRenew/brandSubscriptionRenewErrorCheck";

//scss
import { useDispatch, useSelector } from "react-redux";
import NavBar from "../../../components/Navbar";
import {
  convertIntoNameLabelObject,
  labelToName,
  nameToLabel,
} from "../../../utilities/commonFunction";
import {
  updateBrandId,
  updateComapanyId,
  updateCompanyFormStatus,
} from "../../Login/reducer";
import "../style.scss";
import {
  BrandFieldArray,
  BrandPlanFieldArray,
} from "./customComponents/fieldArray";
import PocTable from "./poc/pocTable";
import { FaPen } from "react-icons/fa";
import SubscriptionRenew from "../../../components/subscriptionRenew/brandSubscriptionRenew";
import {
  brandTrancationOptions,
  demandAndPropertyTransactionTypes,
  getTransactionOptions,
} from "../../../utilities/transactionOptions";

const BrandPageForm = ({
  companyType,
  fromCompanyForm,
  setIsCreated,
  successModel,
  setSuccessModel,
  accountHandler,
  setAccountHandler,
  setHandlerError,
  view,
}) => {
  const dispatch = useDispatch();
  let location = useLocation();
  const navigate = useNavigate();
  const loginPermission = useSelector((state) => state.login.userPermission);
  const menuId = useSelector((state) => state.login.menuId);
  const access = {
    editBuyerCustomers: loginPermission?.includes(menuId.editBuyerCustomers),
    addBuyerCustomers: loginPermission?.includes(menuId?.addBuyerCustomers),
    editCompanyBuyer: loginPermission?.includes(menuId.editCompanyBuyer),
    addCompanyBuyer: loginPermission?.includes(menuId?.addCompanyBuyer),
    viewCompanyBuyer: loginPermission?.includes(menuId?.viewCompanyBuyer),
    deleteBrandPoc: loginPermission?.includes(menuId?.deleteBuyerCustomers),
  };
  const [validated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [contactSuppliersShow, setContactSuppliersShow] = useState(false);
  const [propertyDemandInput, setPropertyDemandInput] = useState(false);
  const [companyId, setCompanyId] = useState("");
  const [transactionTypeList, setTransactionTypeList] = useState([]);

  const [initLoading, setInitLoading] = useState(true);
  const [imageLoad] = useState(false);
  const [logoLoad] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);

  //Subscription edit
  const [subscrptionEdit, setSubscrptionEdit] = useState(false);
  const [initialSubscriptionData, setInitialSubscriptionData] = useState({});
  const [subscriptionExpired, setSubscriptionExpired] = useState(false);
  const [showRenewModal, setShowRenewModal] = useState(false);
  const [subscriptionExpiryDate, setSubscriptionExpiryDate] = useState("");

  const transactionArray = [
    { value: "Lease", label: "Lease" },
    { value: "Revenue Share", label: "Revenue Share" },
    { value: "MG Revenue Share", label: "MG Revenue Share" },
    { value: "Sales", label: "Sales" },
  ];

  let customerView =
    (access?.addBuyerCustomers || access?.editBuyerCustomers ) &&
    ["customer", "brandPageCreation"]?.every((item) =>
      window.location.pathname.split("/").includes(item)
    );
  let companyView = (access?.addCompanyBuyer || access?.editCompanyBuyer || access?.viewCompanyBuyer)
  let companySubscription = [
    {
      key: "featuredDemand",
      subKey: "companyFeature",
    },
    {
      key: "homePageSlotAndBanner",
      subKey: "companyTopBrand",
    },
    {
      key: "emailtoSuppliers",
      subKey: "companyEmailtoSuppliers",
    },
  ];
  let company;
  //location state
  // customer type (Yes flow or No flow)
  let companyTypeReceived = location?.state?.customerType
    ? location?.state?.customerType
    : companyType
    ? companyType
    : "";

  // edit flow check
  let editStorage = JSON.parse(localStorage.getItem("brandEdit"));
  const [edit, setEdit] = useState(
    editStorage
      ? editStorage
      : location?.state?.edit
      ? location?.state?.edit
      : false
  );

  const [customerArray, setCustomerArray] = useState([]);
  const [customerDetails, setCustomerDetails] = useState({});
  const [subscriptionArray, setSubscriptionArray] = useState([]);
  const [subscription, setSubscription] = useState({});

  //select options list data
  const [cityList, setCityList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [rmList, setRmList] = useState([]);
  const [propertyTypeList, setPropertyTypeList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [regionList, setRegionList] = useState([]);
  // regions fetched initially on edit this gets passed to poc region modal
  const [regions, setRegions] = useState([]);

  //imageupload state
  const [imageName, setImageName] = useState([]);
  const [logoName, setLogoName] = useState([]);
  const [apiErrorData, setApiErrorData] = useState("");
  const [role, setRole] = useState({});
  //error object
  const [errorObject, setErrorObject] = useState({});
  // brand id
  const brandIdUpdate = useSelector((state) => state.login.brandId);

  //useState

  const getRegions = async () => {
    let url = "/region/regions";
    const apiDatas = await getData(url);
    const { data, statusCode } = apiDatas;
    if (statusCode === 200) {
      let regionArray = nameToLabel(data);
      setRegionList(regionArray);
    } else {
      setRegionList([]);
    }
  };

  useEffect(() => {
    // let transactionOptions = getTransactionOptions(
    //   customerDetails,
    //   brandTrancationOptions
    // );
    let transactionOptions = getTransactionOptions(
      customerDetails,
      demandAndPropertyTransactionTypes
    );
    if (customerDetails?.transactionType) {
      const filteredTransactionArray = customerDetails?.transactionType.filter(
        (item1) => {
          return nameToLabel(transactionOptions).some(
            (item2) => item2.value === item1.value
          );
        }
      );
      setCustomerDetails({
        ...customerDetails,
        transactionType: filteredTransactionArray,
      });
    }
    setTransactionTypeList(nameToLabel(transactionOptions));
  }, [customerDetails?.propertyTypes]);

  //SubscriptionUpdate

  const handleSubscriptionEdit = async () => {
    if (subscription.contactSuppliersSelect === "Unlimited") {
      subscription.contactSuppliers = "Unlimited";
    }
    if (subscription.propertyOrDemandSelect === "Unlimited") {
      subscription.propertyOrDemand = "Unlimited";
    }

    if (subscription?.relationshipManager === "No") {
      delete subscription?.rm;
    }
    let errors = await brandErrorCheck(subscription);
    if (
      !errors["validityDays"] &&
      parseInt(subscription?.validityDays) <
        parseInt(initialSubscriptionData?.validityDays)
    ) {
      errors["validityDays"] =
        "Subscription validity must be greater that current validity";
    }
    if (Object.keys(errors).length === 0) {
      delete subscription.contactSuppliersSelect;
      delete subscription.propertyOrDemandSelect;
      setLoading(true);
      updateSubscription(companyId, { ...subscription }, "Brand").then(
        (res) => {
          setLoading(false);
          setSubscrptionEdit(false);
        }
      );
    } else {
      setErrorObject(errors);
    }
  };

  const getRole = () => {
    let url = "/role/roletype?type=user";
    getData(url, {}, {}).then((response) => {
      if (response.status) {
        let roleID;
        response?.data.find((el) =>
          el.name === "Enterprise Buyer" ? (roleID = el._id) : null
        );
        let roleObject = {
          _id: roleID,
          name: "Enterprise Buyer",
          type: "user",
        };
        setRole(roleObject);
      }
    });
  };

  const getCompanyList = () => {
    let url = "/company?type=Brand&mode=select";
    getData(url, {}, {}).then((response) => {
      let data = [];
      if (response?.status) {
        // setIsReady(true)

        response?.data?.companies.forEach((item) => {
          data.push({
            name: item?.companyName,
            _id: item?._id,
            subscriptionPlanId: item?.subscriptionPlanId,
            propertyTypes: item?.propertyType,
            region: item?.region,
            cities: item?.cities,
            state: item?.state,
            brandNameRequired: item?.brandNameRequired,
          });
        });
        setCompanyList(data);
      }
    });
  };
  const getRmList = () => {
    let url =
      "/user/getAllSubAdmin?pageNo=0&limit=0&role=Relationship Manager&type=rm";
    getData(url, {}, {}).then((response) => {
      setRmList(response?.data?.subAdminList);
    });
  };
  const updateCityList = (array) => {
    let cityArray = [];
    array?.map((has) => {
      customerDetails?.cities.map((el) => {
        if (el?.value === has?.value) {
          cityArray.push(el);
        }
      });
      return has;
    });
    setDataLoading(false);
    setCustomerDetails((prev) => ({ ...prev, cities: cityArray }));
  };
  const getCities = (id, onChangeState, cId) => {
    setDataLoading(true);
    let url;
    let listedId = id.map((item) => item.value);
    let payload;
    if (companyTypeReceived === "companyYesBrand") {
      url = `/company/companyCities/${cId ? cId : companyId}`;
      payload = { stateIds: listedId };
    } else {
      url = "/country/getCitiesByState";
      payload = { id: listedId };
    }

    postGetData(url, {}, payload).then((response) => {
      // setInitLoading(false);
      if (response?.status) {
        let cityArray = nameToLabel(response?.data);
        setCityList(cityArray);
        if (onChangeState) {
          updateCityList(cityArray);
        } else {
          setDataLoading(false);
        }
      } else {
        setDataLoading(false);
      }
    });
  };
  const updateStateList = (array) => {
    let stateArray = [];
    array?.map((has) => {
      customerDetails?.state.map((el) => {
        if (el?.value === has?.value) {
          stateArray.push(el);
        }
        return el;
      });
      return has;
    });
    setCustomerDetails((prev) => ({ ...prev, state: stateArray }));
    getCities(stateArray, true, companyId);
  };

  const getStates = (region, onChangeRegion, id) => {
    let url;
    let listedId = region.map((item) => item.value);
    let payload;
    if (companyTypeReceived === "companyYesBrand") {
      url = `/company/companyStates/${id ? id : companyId}`;
      payload = { regionIds: listedId };
    } else {
      url = "/country/getStates";
      payload = { regionIds: listedId };
    }
    postGetData(url, {}, payload).then((response) => {
      if (response?.status) {
        let stateArray = nameToLabel(response?.data);
        setStateList(stateArray);
        if (onChangeRegion) {
          updateStateList(stateArray, region);
        }
      }
    });
  };

  const getPropertyType = () => {
    let url = "/propertyType";
    getData(url, {}, {}).then((response) => {
      if (response?.status) {
        let propertyArray = nameToLabel(response?.data);
        setPropertyTypeList(propertyArray);
      }
    });
  };
  const getImageData = (customerData) => {
    if (edit) {
      let imageUrl = customerData.image ? customerData.image : {};
      let logoUrl = customerData.logo ? customerData.logo : {};
      setImageName([
        { name: imageUrl ? (imageUrl.fileName ? imageUrl.fileName : "") : "" },
      ]);
      setLogoName([
        { name: logoUrl ? (logoUrl.fileName ? logoUrl.fileName : "") : "" },
      ]);
    }
  };
  const setSubscriptionData = (value, fromChange) => {
    let company = companyList?.filter((el) => el?._id === value);

    let subdata = { ...subscription };
    companySubscription?.forEach((item) => {
      if (fromChange) {
        subdata[item?.key] = "";
      }
      if (item.key === "homePageSlotAndBanner") {
        if (fromChange) {
          if (
            company[0]?.brandNameRequired &&
            company[0]?.subscriptionPlanId[item?.key] === "Yes"
          ) {
            subdata[item?.subKey] = true;
          } else {
            subdata[item?.key] = "No";
            delete subdata[item?.subKey];
          }
        } else {
          if (customerDetails?.brandNameRequired === "Yes") {
            subdata[item?.subKey] = true;
          } else {
            subdata[item?.key] = "No";
            delete subdata[item?.subKey];
          }
        }
      } else if (company[0]?.subscriptionPlanId[item?.key] === "Yes") {
        subdata[item?.subKey] = true;
      } else {
        subdata[item?.key] = "No";
        delete subdata[item?.subKey];
      }
    });
    setSubscription(subdata);
  };

  const getCustomerData = () => {
    if (edit) {
      setInitLoading(true);
      company = location?.state?.company;
      let url;
      if (company) {
        url = `/company/${brandIdUpdate}`;
      } else {
        url = `/brand/${brandIdUpdate}`;
      }

      getData(url, {}, {}).then((response) => {
        if (response.status) {
          setIsReady(true);
          getImageData(response.data);
          setCompanyId(response.data.companyId._id);
          let customerObj = {};
          let subscriptionDetail = response.data.subscriptionPlanId;
          setInitialSubscriptionData({ ...subscriptionDetail });
          let customerData;
          if (companyTypeReceived === "companyYesBrand") {
            customerData = {
              companyId: "",
              brandNameRequired: "",
              cities: [],
              state: [],
              region: [],
              name: "",
              propertyTypes: [],
              transactionType: [],
              about: "",
              logo: "",
              image: "",
              yearOfEstablished:'',
              currentStores:'',
              expansionPlans: ''
            };
          } else {
            customerData = {
              brandNameRequired: "",
              cities: [],
              region: [],
              state: [],
              name: "",
              propertyTypes: [],
              transactionType: [],
              about: "",
              logo: "",
              image: "",
              yearOfEstablished:'',
              currentStores:'',
              expansionPlans: ""
            };
          } // eslint-disable-next-line
          Object.entries(customerData).map(([k, value]) => {
            // eslint-disable-next-line
            Object.assign(customerObj, { [k]: response.data[k] });
          });

          let cityArray = nameToLabel(customerObj?.cities);
          let stateArray = nameToLabel(customerObj?.state);
          let propertyArray = nameToLabel(customerObj?.propertyTypes);
          let regionArray = nameToLabel(customerObj?.region);
          let brandShow = customerObj?.brandNameRequired;

          setRegions(customerObj?.region);
          if (!customerObj.hasOwnProperty("image")) {
            customerObj.image = {};
          }
          customerObj.region = regionArray;
          customerObj.cities = cityArray;
          customerObj.state = stateArray;
          customerObj.propertyTypes = propertyArray;
          customerObj.transactionType = convertIntoNameLabelObject(
            customerObj?.transactionType ? customerObj?.transactionType : []
          );
          customerObj.brandNameRequired = brandShow === true ? "Yes" : "No";
          let company = companyList?.filter(
            (el) => el?.name === customerObj?.companyId?.companyName
          );
          setSubscriptionData(company[0]?._id);

          if (companyTypeReceived === "companyNoBrand") {
            if (subscriptionDetail.contactSuppliers === "Unlimited") {
              subscriptionDetail.contactSuppliersSelect = "Unlimited";
            } else {
              subscriptionDetail.contactSuppliersSelect = "Enter number";
              setContactSuppliersShow(true);
            }
            if (subscriptionDetail.propertyOrDemand === "Unlimited") {
              subscriptionDetail.propertyOrDemandSelect = "Unlimited";
            } else {
              subscriptionDetail.propertyOrDemandSelect = "Enter number";
              setPropertyDemandInput(true);
            }
            if (
              response?.data?.subscriptionPlanId?.relationshipManager === "No"
            ) {
              subscriptionDetail.rm = "";
            } else {
              if (!response?.data?.subscriptionPlanId?.rm) {
                subscriptionDetail.rm = response?.data?.rm;
              }
            }
            if (fromCompanyForm) {
              if (response?.data?.isSuccessModel) {
                setSuccessModel(true);
              } else {
                setAccountHandler("");
              }

              if (response?.data?.accountHandlerId !== "null") {
                setAccountHandler(response?.data?.accountHandlerId);
              } else {
                setAccountHandler("");
              }
            }
          } else {
            let data = { ...subscriptionDetail };
            companySubscription?.forEach((item) => {
              if (item.key === "homePageSlotAndBanner") {
                if (
                  customerObj?.brandNameRequired === "Yes" &&
                  company[0]?.subscriptionPlanId[item?.key] === "Yes"
                ) {
                  data[item?.subKey] = true;
                } else {
                  data[item?.key] = "No";
                  delete data[item?.subKey];
                }
              } else if (company[0]?.subscriptionPlanId[item?.key] === "Yes") {
                data[item?.subKey] = true;
              } else {
                data[item?.key] = "No";
                delete data[item?.subKey];
              }
            });

            setRegionList(nameToLabel(company[0]?.region));
            setPropertyTypeList(nameToLabel(company[0]?.propertyTypes));
            subscriptionDetail = data;
          }
          setSubscription({ ...subscriptionDetail });
          setCustomerDetails({ ...customerObj });
          getStates(regionArray, false, response.data.companyId._id);
          getCities(stateArray, false, response.data.companyId._id);

          //Checking for Subscription Expiry
          const validityDays = parseInt(subscriptionDetail?.validityDays);
          const subscriptionDate = new Date(
            subscriptionDetail?.subscriptionDate
          );

          const expireDate = new Date(
            subscriptionDate.getTime() + validityDays * 24 * 60 * 60 * 1000
          );

          const currentDate = new Date();

          const hasExpired = expireDate <= currentDate;
          if (hasExpired) {
            setSubscriptionExpired(true);
          } else {
            setSubscriptionExpired(false);
          }

          const day = expireDate.getDate();
          const monthNames = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ];
          const monthIndex = expireDate.getMonth();
          const year = expireDate.getFullYear();

          const formattedExpiryDate = `${day}${
            day === 1 ? "st" : day === 2 ? "nd" : day === 3 ? "rd" : "th"
          } ${monthNames[monthIndex]} ${year}`;

          if (hasExpired) {
            setSubscriptionExpiryDate(`Expired on ${formattedExpiryDate}`);
          } else {
            setSubscriptionExpiryDate(`Plan expires on ${formattedExpiryDate}`);
          }
          setSubscription({ ...subscriptionDetail });
          setInitialSubscriptionData({ ...subscriptionDetail });
          getStates(regionArray, false);
          getCities(stateArray, false);

          setInitLoading(false);
        } else {
          setInitLoading(false);
        }
      });
    } else {
      setInitLoading(false);
    }
  };

  const createBrandPage = () => {
    delete subscription.contactSuppliersSelect;
    delete subscription.propertyOrDemandSelect;
    let cityArray = labelToName(customerDetails?.cities);
    let stateArray = labelToName(customerDetails?.state);
    let propertyArray = labelToName(customerDetails?.propertyTypes);
    let transactionArr = labelToName(customerDetails?.transactionType);
    let regionArray = labelToName(customerDetails?.region);

    // setCustomerDetails({
    //   ...customerDetails,
    //   subscriptionPlanId: subscription,
    // });

    let dataList = {
      ...customerDetails,
      cities: cityArray,
      state: stateArray,
      propertyTypes: propertyArray,
      transactionType: transactionArr,
      region: regionArray,
      subscriptionPlanId: subscription,
      brandNameRequired:
        customerDetails?.brandNameRequired === "Yes" ? true : false,
      roles: role,
      type: "Brand",
      isSuccessModel: successModel,
      accountHandlerId: successModel ? accountHandler : "null",
    };
    let companyID = companyList?.filter(
      (el) => el?.name === dataList?.companyId?.name
    );
    if (companyTypeReceived === "companyNoBrand") {
      if (dataList?.subscriptionPlanId?.relationshipManager === "No") {
        delete dataList?.subscriptionPlanId?.rm;
      } else {
        dataList.rm = dataList?.subscriptionPlanId?.rm;
        delete dataList?.subscriptionPlanId?.rm;
      }
      dataList.subBrands = false;
    } else if (companyTypeReceived === "companyYesBrand") {
      let data = [
        "companyFeature",
        "companyTopBrand",
        "companyEmailtoSuppliers",
      ];
      delete dataList?.disableBrandNameStatus;
      data.forEach((item) => {
        delete dataList?.subscriptionPlanId[item];
      });
    }

    if (dataList?.image === "" || Object.keys(dataList?.image).length === 0) {
      dataList.image = {};
    }
    
    if (edit) {
      delete dataList.roles;
      delete dataList.type;
    }

    for (let x in dataList) {
      if(dataList[x]===''){
        dataList[x] = null;
      }
    }

    if (edit) {
      setLoading(true);
      setApiErrorData("");
      putData(`/brand/${brandIdUpdate}`, {}, dataList).then((response) => {
        if (response.status) {
          setLoading(false);
          if (fromCompanyForm === true) {
            navigate("/company");
          } else {
            navigate("/customer");
          }
        } else if (response.statusCode === 400 || response.statusCode === 422) {
          setLoading(false);
          setApiErrorData(response.message);
          setSubscriptionData(companyID[0]?._id);
          setSubscription({ ...subscription, rm: dataList?.rm });
        } else {
          setLoading(false);
          setSubscriptionData(companyID[0]?._id);
          setSubscription({ ...subscription, rm: dataList?.rm });
        }
      });
    } else {
      setLoading(true);
      setApiErrorData("");
      postData("/brand", {}, dataList).then((response) => {
        if (response.status) {
          localStorage.setItem("brandEdit", JSON.stringify(true));
          dispatch(updateBrandId(response?.data?._id));
          setEdit(true);
          setIsCreated(true);
          setLoading(false);
        } else if (response.statusCode === 400 || response.statusCode === 422) {
          setLoading(false);
          setApiErrorData(response.message);
          setSubscriptionData(companyID[0]?._id);
          setSubscription({ ...subscription, rm: dataList?.rm });
        } else {
          setLoading(false);
          setSubscriptionData(companyID[0]?._id);
          setSubscription({ ...subscription, rm: dataList?.rm });
        }
      });
    }
    // setLoading(false);
  };
  const handleOnSubmit = async (event) => {
    if (companyTypeReceived === "companyNoBrand") {
      if (subscription.contactSuppliersSelect === "Unlimited") {
        subscription.contactSuppliers = "Unlimited";
      }
      if (subscription.propertyOrDemandSelect === "Unlimited") {
        subscription.propertyOrDemand = "Unlimited";
      }
    }
    event.preventDefault();
    let errors = await errorCheck(
      customerDetails,
      subscription,
      companyTypeReceived
    );

    if (successModel && (accountHandler === "" || accountHandler === null)) {
      errors["accountHandlerId"] = "required";
      setHandlerError({ accountHandlerId: "Select an account handler" });
    }

    delete errors["rm"];
    if (Object.keys(errors).length === 0) {
      createBrandPage();
    } else {
      setErrorObject(errors);
    }
  };

  //create brand page api call

  const onChange = (value, key) => {
    delete errorObject[key];
    let data = value;
    if (key === "companyId") {
      delete errorObject["emailtoSuppliers"];
      delete errorObject["featuredDemand"];
      delete errorObject["homePageSlotAndBanner"];
      let rmObj = companyList?.filter((el) => value === el?._id);
      setCompanyId(value);
      data = rmObj[0];
      setSubscription({ ...data?.subscriptionPlanId });

      // setSubscriptionData(value, true);
      if (companyTypeReceived === "companyYesBrand") {
        setCityList([]);
        setRegionList([]);
        setPropertyTypeList([]);
        setCustomerDetails({
          ...customerDetails,
          brandNameRequired: rmObj[0]?.brandNameRequired === false ? "No" : "",
          disableBrandNameStatus:
            rmObj[0]?.brandNameRequired === false ? true : false,
          region: [],
          cities: [],
          state: [],
          propertyTypes: [],
          transactionType: [],
          [key]: data,
        });

        setPropertyTypeList(nameToLabel(rmObj[0]?.propertyTypes));
        let regionArray = nameToLabel(data?.region);
        setRegionList(regionArray);
      }
    } else if (key === "brandNameRequired") {
      let subdata = { ...subscription };
      delete errorObject["homePageSlotAndBanner"];
      companySubscription?.forEach((item) => {
        if (item.key === "homePageSlotAndBanner") {
          // subdata[item?.key] = "";
          if (companyTypeReceived === "companyYesBrand") {
            if (
              value === "Yes" &&
              customerDetails?.companyId?.subscriptionPlanId
                ?.homePageSlotAndBanner === "Yes"
            ) {
              subdata[item?.subKey] = true;
            } else {
              subdata[item?.key] = "No";
              delete subdata[item?.subKey];
            }
          } else {
            if (value === "Yes") {
              subdata[item?.subKey] = true;
            } else {
              subdata[item?.key] = "No";
              delete subdata[item?.subKey];
            }
          }
        }
      });
      setSubscription(subdata);
      setCustomerDetails({ ...customerDetails, [key]: data });
    } else if (key === "state") {
      if (value?.length > 0) {
        getCities(value, true, companyId);
        setCustomerDetails({ ...customerDetails, [key]: value });
      } else {
        setCityList([]);
        setCustomerDetails({ ...customerDetails, cities: [], [key]: value });
      }
    } else if (key === "region") {
      if (value?.length > 0) {
        getStates(value, true, companyId);
        setCustomerDetails({ ...customerDetails, [key]: value });
      } else {
        setStateList([]);
        setCityList([]);
        setCustomerDetails({
          ...customerDetails,
          state: [],
          cities: [],
          [key]: value,
        });
      }
    } else {
      setCustomerDetails({ ...customerDetails, [key]: data });
    }
  };

  const onChangeSubscription = (value, key) => {
    delete errorObject[key];
    let data = value;
    if (key === "relationshipManager") {
      delete errorObject["rm"];
      if (value !== "Yes") {
        // setSubscription({ ...subscription, rm: "" });
        subscription.rm = "";
      }
    } else if (key === "rm") {
      let rmObj = rmList?.filter((el) => value === el?._id);
      data = rmObj[0];
    }
    setSubscription({ ...subscription, [key]: data });
  };

  const renderTextArea = (name, key, value, error, placeholder) => {
    return (
      <TextArea
        errorMessage={error}
        placeholder={placeholder}
        label={name}
        name={name}
        value={value}
        isInvalid={!!error}
        size={"w-80"}
        disabled={view ? true : false}
        onChange={(e) => {
          onChange(e.target.value.replace("\n", ""), key);
        }}
      />
    );
  };

  const logoImageApi = (files, key) => {
    if (files.length === 0) {
      setCustomerDetails({ ...customerDetails, [key]: {} });
    }
    let data = new FormData();
    data.append("uploadfiles", files[0]);
    if (files.length > 0) {
      setInitLoading(true);

      let url = "/brand/file-upload/?type=image&module=brand";
      postUploadData(url, {}, data).then((response) => {
        if (response.status) {
          setCustomerDetails({ ...customerDetails, [key]: response.data[0] });
          setInitLoading(false);
        } else {
          setInitLoading(false);
        }
      });
    }
  };
  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleOnSubmit(e);
    }
  };

  useEffect(() => {
    if (companyTypeReceived === "companyYesBrand") {
      getCompanyList();
    } else {
      // getPropertyType();
      getRegions();
    }
    getPropertyType();
    getRmList();
    getRole();
    if (!edit || !view) {
      setIsReady(true);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      (companyTypeReceived === "companyNoBrand" &&
        rmList?.length > 0 &&
        regionList?.length > 0) ||
      (companyTypeReceived === "companyYesBrand" &&
        companyList?.length > 0 &&
        rmList?.length > 0)
    ) {
      let customerArray = BrandFieldArray(
        cityList,
        companyList,
        stateList,
        transactionTypeList
      );
      let subscriptionArray = BrandPlanFieldArray(rmList);
      let currentCustomerArray = customerArray.filter(
        (el) => el.key === companyTypeReceived
      );
      let currentSubscriptionArray = subscriptionArray.filter(
        (el) => el.key === companyTypeReceived
      );
      setCustomerArray(currentCustomerArray[0]?.fieldArray);
      setCustomerDetails(currentCustomerArray[0]?.fieldObject);
      setSubscriptionArray(currentSubscriptionArray[0]?.fieldArray);
      setSubscription(currentSubscriptionArray[0]?.fieldObject);
      // ...
      setTimeout(() => {
        getCustomerData();
      }, 2000);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit, companyList, rmList]);
  return (
    <>
      {customerView || companyView ? (
        <>
          {(initLoading || dataLoading) && <Loader className={"fixed"} />}

          {isReady && (
            <>
              {fromCompanyForm ? "" : <NavBar />}

              <div className="brandPageForm-container">
                {/* {!initLoading && */}

                <Form
                  noValidate
                  validated={validated}
                  onKeyDown={(e) => onKeyDown(e)}
                  onSubmit={(e) => handleOnSubmit(e)}
                >
                  <div className="row">
                    {customerArray?.length > 0 &&
                      customerArray.map((item, i) => {
                        return (
                          <React.Fragment key={i}>
                            {item.type === "input" && (
                              <RenderInput
                                name={item.name}
                                disabled={view ? true : false}
                                keyValue={item.key}
                                value={customerDetails[item.key]}
                                error={errorObject[item.key]}
                                inputType={item.inputType}
                                objName="customer"
                                placeholder={item.placeholder}
                                onChange={onChange}
                                edit={edit}
                              />
                            )}
                            {item.type === "phoneInput" && (
                              <RenderPhoneInput
                                name={item.name}
                                keyValue={item.key}
                                disabled={view ? true : false}
                                value={customerDetails[item.key]}
                                error={errorObject[item.key]}
                                inputType={item.inputType}
                                placeholder={item.placeholder}
                                onChange={onChange}
                              />
                            )}
                            {item.type === "textArea" &&
                              renderTextArea(
                                item.name,
                                item.key,
                                customerDetails[item.key],
                                errorObject[item.key],
                                item.placeholder
                              )}
                            {item.type === "select" && (
                              <RenderSelect
                                option={item?.option}
                                name={item.name}
                                keyValue={item.key}
                                value={customerDetails[item.key]}
                                error={errorObject[item.key]}
                                placeholder={item.placeholder}
                                disabled={
                                  view ||
                                  (edit &&
                                    (item.key === "companyId" ||
                                      item.key === "brandNameRequired"))
                                    ? true
                                    : false
                                }
                                onChange={onChange}
                                objName="customer"
                                customerDetails={customerDetails}
                                companyTypeReceived={companyTypeReceived}
                              />
                            )}
                            {item.type === "multiSelect" && (
                              <RenderMultiSelect
                                option={item?.option}
                                name={item.name}
                                keyValue={item.key}
                                value={customerDetails[item.key]}
                                disabled={view ? true : false}
                                error={errorObject[item.key]}
                                placeholder={item.placeholder}
                                onChange={onChange}
                                objName="customer"
                              />
                            )}
                            {item.type === "coverImage" && (
                              <div className="col-md-6 w-40 col-sm-12">
                                <SingleImageUpload
                                  loading={imageLoad}
                                  multiple={false}
                                  files={imageName ? imageName : []}
                                  name="image"
                                  id="image"
                                  disabled={view ? true : false}
                                  imageApi={(files) =>
                                    logoImageApi(files, "image", errorObject)
                                  }
                                  placeholder="Upload cover image"
                                  label="Upload cover image"
                                  errorObject={errorObject}
                                  setErrorObject={setErrorObject}
                                  errorMessage={
                                    errorObject?.image ? errorObject?.image : ""
                                  }
                                  className="w-100"
                                />
                                <p className="f-12 mb-0">
                                  *Cover image resolution must be 1440 X 348{" "}
                                </p>
                              </div>
                            )}
                            {item.type === "logo" && (
                              <div className="col-md-6 w-40 col-sm-12">
                                <SingleImageUpload
                                  loading={logoLoad}
                                  multiple={false}
                                  files={logoName ? logoName : []}
                                  name="logo"
                                  disabled={view ? true : false}
                                  id="logo"
                                  imageApi={(files) =>
                                    logoImageApi(files, "logo", errorObject)
                                  }
                                  placeholder="Upload logo"
                                  label="Upload logo *"
                                  errorObject={errorObject}
                                  setErrorObject={setErrorObject}
                                  errorMessage={
                                    errorObject?.logo ? errorObject?.logo : ""
                                  }
                                  className="w-100"
                                />
                              </div>
                            )}
                          </React.Fragment>
                        );
                      })}
                    <RenderMultiSelect
                      option={regionList}
                      name={"Region *"}
                      keyValue={"region"}
                      value={customerDetails["region"]}
                      disabled={view ? true : false}
                      error={errorObject["region"]}
                      placeholder={"Select"}
                      onChange={onChange}
                      objName="customer"
                    />
                    <RenderMultiSelect
                      option={stateList}
                      name={"State *"}
                      keyValue={"state"}
                      value={customerDetails["state"]}
                      disabled={view ? true : false}
                      error={errorObject["state"]}
                      placeholder={"Select"}
                      onChange={onChange}
                      objName="customer"
                    />
                    <RenderMultiSelect
                      option={cityList}
                      name={"Operating cities *"}
                      keyValue={"cities"}
                      value={customerDetails["cities"]}
                      disabled={view ? true : false}
                      error={errorObject["cities"]}
                      placeholder={"Select"}
                      onChange={onChange}
                      objName="customer"
                    />
                    <RenderMultiSelect
                      option={propertyTypeList}
                      name={"Property Type *"}
                      keyValue={"propertyTypes"}
                      value={customerDetails["propertyTypes"]}
                      disabled={view ? true : false}
                      error={errorObject["propertyTypes"]}
                      placeholder={"Select"}
                      onChange={onChange}
                      objName="customer"
                    />
                    <RenderMultiSelect
                      option={transactionTypeList}
                      name={"Transaction Type *"}
                      keyValue={"transactionType"}
                      value={customerDetails["transactionType"]}
                      disabled={view ? true : false}
                      error={errorObject["transactionType"]}
                      placeholder={"Select"}
                      onChange={onChange}
                      objName="customer"
                    />
                  </div>

                  <div className="row mt-3">
                    <div className="col col-6">
                      <label title="Subscription" className="text-heading">
                        Subscription
                      </label>
                      {edit && !subscriptionExpired && (
                        <span className="text-sm text-muted">{` (${subscriptionExpiryDate})`}</span>
                      )}
                    </div>
                    <div className="col col-6">
                      <div className="d-flex gap-3 float-end align-items-center">
                        {fromCompanyForm ? (
                          subscriptionExpired ? (
                            <>
                              <span className="text-sm text-danger">
                                {subscriptionExpiryDate}
                              </span>
                              <Button
                                type="button"
                                className="btn-color-primary d-block"
                                onClick={(e) => {
                                  setShowRenewModal(true);
                                }}
                              >
                                <span>Renew Subscription</span>
                              </Button>
                            </>
                          ) : (
                            !subscrptionEdit &&
                            edit &&
                            !view && (
                              <Button
                                type="button"
                                className="btn-outline-color-primary d-block border-0 float-end"
                                onClick={(e) => {
                                  setSubscrptionEdit(true);
                                }}
                              >
                                <span className="me-2 text-blue ">
                                  Edit Plan
                                </span>
                                <FaPen className="text-blue" />
                              </Button>
                            )
                          )
                        ) : (
                          edit &&
                          !view && (
                            <div className="d-flex gap-3 float-end align-items-center">
                              <Button
                                type="button"
                                className="btn-outline-color-primary d-block border-0"
                                onClick={(e) => {
                                  localStorage.setItem(
                                    "subscriptionEdit",
                                    JSON.stringify(true)
                                  );
                                  navigate("/company/brandCompanyCreation", {
                                    state: {
                                      edit: true,
                                      companyType:
                                        companyTypeReceived ===
                                        "companyYesBrand"
                                          ? ""
                                          : "companyNoBrands",
                                    },
                                  });
                                  dispatch(updateComapanyId(companyId));
                                  dispatch(updateCompanyFormStatus("edit"));

                                  dispatch(updateBrandId(brandIdUpdate));
                                }}
                              >
                                <span className="me-2 text-blue ">
                                  Edit Plan
                                </span>
                                <FaPen className="text-blue" />
                              </Button>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    {subscriptionArray?.length > 0 &&
                      subscriptionArray.map((item, i) => {
                        return (
                          <React.Fragment key={i}>
                            {item.type === "input" && (
                              <RenderInput
                                name={item.name}
                                keyValue={item.key}
                                value={subscription[item.key]}
                                error={errorObject[item.key]}
                                inputType={item.inputType}
                                objName="subscription"
                                onChangeSubscription={onChangeSubscription}
                                disabled={
                                  !subscrptionEdit && edit
                                    ? true
                                    : !fromCompanyForm
                                    ? true
                                    : false
                                }
                                edit={edit}
                                placeholder={item.placeholder}
                                subKey={item.subKey ? item.subKey : ""}
                                contactSuppliersShow={contactSuppliersShow}
                                propertyDemandInput={propertyDemandInput}
                              />
                            )}
                            {item.type === "select" && (
                              <RenderSelect
                                name={item.name}
                                keyValue={item.key}
                                option={item?.option}
                                value={subscription[item.key]}
                                error={errorObject[item.key]}
                                placeholder={item.placeholder}
                                disabled={
                                  !subscrptionEdit && edit
                                    ? true
                                    : !fromCompanyForm
                                    ? true
                                    : false
                                }
                                onChangeSubscription={onChangeSubscription}
                                objName="subscription"
                                subKey={item.subKey ? item.subKey : ""}
                                rmVal={subscription?.relationshipManager}
                                setContactSuppliersShow={
                                  setContactSuppliersShow
                                }
                                setPropertyDemandInput={setPropertyDemandInput}
                                subscription={subscription}
                                companyTypeReceived={companyTypeReceived}
                                customerDetails={customerDetails}
                              />
                            )}
                          </React.Fragment>
                        );
                      })}
                    <br />
                  </div>

                  <span className="error-message_span mb-3">
                    {apiErrorData}
                  </span>
                  <div className="row">
                    <div className="col col-sm-12 col-md-12 col-lg-6 align-items-center">
                      {subscrptionEdit && (
                        <div className="btn-container mt-3 mb-5 float-lg-start">
                          <>
                            <Button
                              type="button"
                              className="btn-outline-color-primary d-block"
                              onClick={(e) => {
                                setSubscription(initialSubscriptionData);
                                setSubscrptionEdit(false);
                              }}
                              disabled={!subscrptionEdit}
                            >
                              Cancel
                            </Button>
                            {view ? null : (
                              <Button
                                type="button"
                                disabled={loading || !subscrptionEdit}
                                loading={loading}
                                className="btn-color-primary d-block"
                                onClick={handleSubscriptionEdit}
                              >
                                Update Subscription
                              </Button>
                            )}
                          </>
                        </div>
                      )}
                    </div>
                    <div className="col col-sm-12 col-md-12 col-lg-6 d-flex align-items-end justify-content-end">
                      {view ? null : (
                        <div className="btn-container mt-3">
                          <Button
                            type="button"
                            className="btn-outline-color-primary d-block"
                            onClick={() => navigate("/customer")}
                          >
                            Cancel
                          </Button>
                          <Button
                            type="submit"
                            onClick={(e) => handleOnSubmit(e)}
                            disabled={loading}
                            loading={loading}
                            className="btn-color-primary d-block"
                          >
                            {edit ? "Update brand page" : "Create brand page"}
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </Form>

                {edit ? (
                  <div className="mt-4">
                    <PocTable
                      regions={regions}
                      customerDetails={customerDetails}
                      view={view}
                      type={"brand"}
                      companyTypeReceived={companyTypeReceived}
                      brandID={brandIdUpdate}
                      access={access}
                    />
                  </div>
                ) : (
                  ""
                )}

                {/* // } */}
              </div>
              <SubscriptionRenew
                subscriptionArray={subscriptionArray}
                showRenewModal={showRenewModal}
                setShowRenewModal={setShowRenewModal}
                brandId={companyId}
                setRmList={setRmList}
                rmList={rmList}
                getCustomerData={getCustomerData}
              />
            </>
          )}
        </>
      ) : (
        <AccessDenied />
      )}
    </>
  );
};
export default BrandPageForm;
