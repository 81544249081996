import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { FaPen, FaTrash } from "react-icons/fa";
import { useSelector } from "react-redux";
//components
import Button from "../../components/Form/Button";
import Loader from "../../components/Loader/index";
import Search from "../../components/Search/search";
import MainTable from "../../components/Table/index";
import AddAdminAndEdit from "./adminAddAndEdit";
import {
  AdminWarningPopup,
  AssignCustomer,
  ChangeRm,
  ViewCustomer,
  ViewEnquiry,
} from "./adminPopup";
//fetch
import {
  AccessDenied,
  deleteData,
  getCurrentUser,
  getData,
  postData,
  putData,
  resetPassword,
} from "../../api/index";

//json

//scss
import "./style.scss";
import lockIcon from "../../assets/images/lock1_1.svg";
import { CustomerWarningPopup } from "../Customer/CustomerTable/warningPopup";
import { CustomerDeleteWarningPopUp } from "../Customer/CustomerTable/CustomerDeleteWarningPopUp";

//image

export default function Admin() {
  const [label, setLabel] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [editDataID, setEditDataID] = useState("");
  const [adminWarning, setAdminWarning] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [adminActiveStatus, setAdminActiveStatus] = useState(false);
  const [adminId, setAdminId] = useState("");
  const [viewEnquiry, setViewEnquiry] = useState(false);

  const [viewAssignPopup, setViewAssignPopup] = useState(false);
  const [roleData, setRoleData] = useState([]);
  const [listData, setListData] = useState([]);
  const [offset, setOffset] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const [filter, setFilter] = useState("All");
  const [search, setSearch] = useState("");
  const [viewCustomer, setviewCustomer] = useState(false);
  const [changeRm, setChangeRm] = useState(false);
  const [customerId, setCustomerId] = useState();

  const [adminDetails, setAdminDetails] = useState({
    name: "",
    designation: "",
  });
  const [showAdmin, setShowAdmin] = useState(false);
  const [roleDesignation, setRoleDesignation] = useState("");
  const [rmObject, setRmObject] = useState("");

  //Delete
  const [deleteWarningPopup, setDeleteWarningPopup] = useState(false);
  const [activeAdminId, setActiveAdminId] = useState("");

  //password reset
  const [passwordResetWarningPopup, setPasswordResetWarningPopup] =
    useState(false);
  const [currentUserId, setCurrentUserId] = useState("");

  const loginPermission = useSelector((state) => state.login.userPermission);
  const menuId = useSelector((state) => state.login.menuId);
  const access = {
    view: loginPermission?.includes(menuId?.customerView) ? true : false,
    add: loginPermission?.includes(menuId?.adminAdd) ? true : false,
    edit: loginPermission?.includes(menuId?.adminEdit) ? true : false,
    delete: loginPermission?.includes(menuId?.adminDelete) ? true : false,
    enable: loginPermission?.includes(menuId?.adminEnableDisable)
      ? true
      : false,
    assign: loginPermission?.includes(menuId?.adminAssignCustomer)
      ? true
      : false,
    adminChangeRm: loginPermission?.includes(menuId?.adminChangeRm)
      ? true
      : false,
  };
  const getRolesFunction = async () => {
    let url = `/role/roletype?type=admin`;
    const apiDatas = await getData(url);
    const { data, statusCode } = apiDatas;
    if (statusCode === 200) {
      setRoleData(data);
    } else {
      setRoleData([]);
    }
  };
  useEffect(() => {
    getRolesFunction();
  }, []);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setOffset(1);
  };
  const getAdminListData = async () => {
    setIsLoading(true);
    let url = `/user/getAllSubAdmin?pageNo=${offset}&limit=${perPage}&role=${filter}`;
    if (search) {
      url += `&search=${search}`;
    }
    const getList = await getData(url, {}, {});
    const { statusCode, data } = getList;
    if (statusCode === 200) {
      setIsLoading(false);
      setListData(data.subAdminList);
      setPageCount(Math.ceil(data.totalCount / perPage));
      setTotalCount(data.totalCount);
    } else {
      setPageCount(0);
      setIsLoading(false);
      setListData([]);
    }
  };
  useEffect(() => {
    getAdminListData();
  }, [perPage, offset, filter, search]);

  const handelChangePerPage = (count) => {
    setPerPage(count);
    setOffset(1);
  };

  // PASSWORD RESET
  const handleResetPassword = (row) => {
    setCurrentUserId(row._id);
    setPasswordResetWarningPopup(true);
    setWarningMessage(
      "Are you sure, do you want to reset the login credentials?"
    );
  };

  const passwordResetWarningYesClick = () => {
    resetPassword(currentUserId).then(() => {
      setPasswordResetWarningPopup(false);
    });
  };

  const handlePasswordResetWarningClose = () => {
    setPasswordResetWarningPopup(false);
  };

  const handelAddAdmin = (name, row = {}) => {
    setShowAdmin(true);
    setEditDataID();
    setLabel(name);
    if (name === "Edit") {
      setEditDataID(row._id);
    }
  };

  const handelSelectRm = (custId, adminID, roleDesign) => {
    setCustomerId(custId);
    setviewCustomer(false);
    setChangeRm(true);
    setAdminId(adminID);
    setRoleDesignation(roleDesign);
  };

  const warningYesClick = async () => {
    let url = `/user/updateSubAdminStatus/${adminId}`;
    let datas = {
      isActive: adminActiveStatus ? false : true,
    };
    setIsLoading(true);
    const apiDatas = await putData(url, {}, datas);
    const { statusCode } = apiDatas;
    if (statusCode === 200) {
      setIsLoading(false);
      getAdminListData();
      setAdminWarning(false);
    } else {
      setIsLoading(false);
    }
  };

  const updateStatus = (id, activestatus) => {
    setAdminActiveStatus(activestatus);
    setAdminId(id);
    setAdminWarning(true);
    if (activestatus === true) {
      setWarningMessage("Are you sure you want disable this admin");
    } else if (activestatus === false) {
      setWarningMessage("Are you sure you want enable this admin");
    }
  };

  const updateRelationShipManager = async (custId, rm, action = false) => {
    let url = `/brand/assignRm/${custId}`;
    let role = roleDesignation === "Relationship Manager" ? "rm" : "uploader";
    let value =
      roleDesignation === "Relationship Manager"
        ? action
          ? rm
          : rmObject
        : rm;
    let datas = {
      [role]: value,
    };
    setIsLoading(true);
    const reponse = await postData(url, {}, datas);
    const { statusCode } = reponse;
    if (statusCode === 200) {
      setCustomerId();
      setAdminId();
      setRmObject({});
      setIsLoading(false);
      getAdminListData();
      setViewAssignPopup(false);
      setChangeRm(false);
      // alert(message);
    } else {
      setIsLoading(false);
      setAdminId();
      // alert(message);
    }
  };

  /*------------add a Client------------------- */

  const customerListView = (row) => {
    let adminDetail = {
      name: row?.name,
      designation: row?.roles[0]?.name,
    };
    setviewCustomer(true);
    setAdminId(row._id);
    setAdminDetails(adminDetail);
  };

  function assignCustomer(cell, row) {
    return (
      <>
        {row.roles[0].name === "Business Development Executive" ? (
          "-"
        ) : (
          <Button
            variant="link"
            // disabled={
            //   loginPermission?.includes(menuId?.manageUsers) ? false : true
            // }

            className={
              row.roles[0].name === "Relationship Manager"
                ? row?.noOfCustomers === 0
                  ? "btn btn-link link-btn table-btn-text"
                  : "btn btn-link link-btn table-link-btn"
                : row.roles[0].name === "Uploader"
                ? row?.uploaderCount === 0
                  ? "btn btn-link link-btn table-btn-text"
                  : "btn btn-link link-btn table-link-btn"
                : "table-btn"
            }
            onClick={() =>
              // row.roles[0].name === "Relationship Manager" ?
              row?.noOfCustomers !== 0
                ? customerListView(row)
                : //  : row.roles[0].name === "Uploader" ?
                row?.uploaderCount !== 0
                ? customerListView(row)
                : ""
            }
            disabled={access.view ? false : true}
          >
            {row.roles[0].name === "Relationship Manager"
              ? row?.noOfCustomers
              : row?.uploaderCount}
          </Button>
        )}
      </>
    );
  }

  const onClickEnquiryCount = (row) => {
    // if (access.view) {
    let adminDetail = {
      name: row?.name,
      designation: row?.roles[0]?.name,
    };
    setViewEnquiry(true);
    setAdminId(row._id);
    setAdminDetails(adminDetail);
    // }
  };

  function leadList(cell, row) {
    return (
      <>
        {row.roles[0].name === "Business Development Executive" ? (
          <Button
            variant="link"
            // disabled={
            //   loginPermission?.includes(menuId?.manageUsers) ? false : true
            // }

            className={
              row?.leadCount === 0
                ? "btn btn-link link-btn table-btn-text"
                : "btn btn-link link-btn table-link-btn cursor-pointer"
            }
            onClick={() =>
              row?.leadCount === 0 ? "" : onClickEnquiryCount(row)
            }
          >
            {row?.leadCount}
          </Button>
        ) : (
          "-"
        )}
      </>
    );
  }

  
  const deleteWarningYesClick = () => {
    let url = `/user/deleteAdminTeam/${activeAdminId}`;
    deleteData(url, {}, {}).then((response) => {
      if (response.status) {
        getAdminListData();
        setDeleteWarningPopup(false);
      } else {
        setDeleteWarningPopup(false);
      }
    });
  };

  function userButtonFormatter(cell, row, rowIndex) {
    return (
      <>
        <div className="d-flex align-items-center">
          <Form.Check
            type="switch"
            className="d-flex justify-content-center align-items-center"
            id={`custom-switch_${rowIndex}}`}
            checked={row.isActive ? true : false}
            disabled={access.enable ? false : true}
            onChange={(e) => {
              updateStatus(row._id, row.isActive);
            }}
          />
          <Button
            className="table-btn  p-2"
            disabled={row.isActive && access.edit ? false : true}
          >
            <FaPen
              className="icon-pointer cursor-pointer"
              onClick={access.edit ? () => handelAddAdmin("Edit", row) : null}
            />
          </Button>
          <Button
            className="table-btn  p-2"
            onClick={(e) => {
              setActiveAdminId(row?._id);
              setDeleteWarningPopup(true);
            }}
            disabled={row?.roles[0]?.name === "Account Handler" ? true : access.delete ? false : true}
            // to add permission
          >
            <FaTrash />
          </Button>
    
          {getCurrentUser()?.role[0]?.name === "admin" ? (
            <div
              style={{marginLeft:"8px"}}
              onClick={() => {
                handleResetPassword(row);
              }}
            >
              <img
                src={lockIcon}
                alt="lock icon"
                style={{
                  cursor: "pointer",
                  width: "20px",
                  height: "20px",
                }}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </>
    );
  }
  const columns = [
    {
      dataField: "sno",
      text: "S.no",
    },
    {
      dataField: "name",
      text: "Name",
    },
    {
      dataField: "roles",
      text: "User Role",
      formatter: (cellContent, row, rowIndex) =>
        Object.values(row.roles.map((item) => item.name)),
    },
    {
      dataField: "email",
      text: "Email ID",
    },
    {
      dataField: "phoneNumber",
      text: "Phone Number",
    },
    {
      dataField: "leadCount",
      text: "Lead",
      formatter: leadList,
    },
    {
      dataField: "noOfCustomers",
      text: "Customer",
      formatter: assignCustomer,
    },
    {
      dataField: "isActive",
      text: "Action",
      formatter: userButtonFormatter,
    },
  ];

  const handelCloseAdmin = (status) => {
    setShowAdmin(false);
    if (status === "updated") getAdminListData();
  };

  const handleWarningClose = (type) => {
    if (type === "delete") {
      setDeleteWarningPopup(false);
    } else {
      setAdminWarning(false);
    }
    
  };

  const tableProps = {
    data: listData,
    columns,
    offset,
    setOffset,
    handelChangePerPage,
    pageCount,
    perPage,
    totalCount,
    tableName: "admin",
    setPerPage,
  };

  const handelfilter = (filterValue) => {
    setFilter(filterValue);
    setOffset(1);
  };
  const handelCloseViewCustomer = () => {
    setviewCustomer(false);
  };
  const handelCloseChangeRm = () => {
    setChangeRm(false);
  };
  const handelCloseCustomer = () => {
    setViewAssignPopup(false);
  };

  const handelCloseViewEnquiry = () => {
    setViewEnquiry(false);
  };

  return (
    <>
      {loginPermission?.includes(menuId?.admin) ? (
        <div>
          <div className="dashboard-container">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex justify-content-between align-items-center text-heading">
                <label title="Dashboard">Admin</label>
              </div>

              <div className="d-flex mb-4 flexEnd_div">
                <div className="mx-3 manageWidthSearch">
                  <Search onChange={handleSearch} />
                </div>
                <Button
                  className="btn-color-primary"
                  onClick={() => handelAddAdmin("Add")}
                  disabled={access.add ? false : true}
                >
                  Add Admin
                </Button>
              </div>
            </div>
            <div className="filter-container">
              <div className="d-flex justify-content-end widWrapper">
                <select
                  className="form-select filterBGColor w-100"
                  key="admin"
                  name="admin"
                  id="admin"
                  onChange={(e) => handelfilter(e.target.value)}
                >
                  <option value="All">All</option>
                  {roleData.map((item, index) => {
                    return (
                      <option key={index} value={item.name}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="mt-4">
              <MainTable {...tableProps} />
            </div>
          </div>

          {viewAssignPopup ? (
            <AssignCustomer
              show={viewAssignPopup}
              adminId={adminId}
              roleDesignation={roleDesignation}
              onClose={handelCloseCustomer}
              onAssignCustomer={updateRelationShipManager}
            ></AssignCustomer>
          ) : null}

          {viewCustomer && (
            <ViewCustomer
              show={viewCustomer}
              adminId={adminId}
              onClose={handelCloseViewCustomer}
              onClickRmChange={handelSelectRm}
              adminDetails={adminDetails}
              access={access}
            />
          )}
          {changeRm && (
            <ChangeRm
              show={changeRm}
              adminId={adminId}
              customerId={customerId}
              onClose={handelCloseChangeRm}
              roleDesignation={roleDesignation}
              onSelectRmChange={updateRelationShipManager}
            />
          )}
          {showAdmin && (
            <AddAdminAndEdit
              label={label}
              show={showAdmin}
              onClose={handelCloseAdmin}
              adminId={editDataID}
            ></AddAdminAndEdit>
          )}
          {adminWarning && (
            <AdminWarningPopup
              show={adminWarning}
              message={warningMessage}
              warningYesClick={warningYesClick}
              onClose={handleWarningClose}
            />
          )}

          {deleteWarningPopup && (
            <CustomerDeleteWarningPopUp
              show={deleteWarningPopup}
              title={"Confirmation"}
              message={"Are you sure, you want to delete this admin"}
              warningYesClick={deleteWarningYesClick}
              onClose={() => handleWarningClose("delete")}
            />
          )}

          {viewEnquiry && (
            <ViewEnquiry
              show={viewEnquiry}
              adminId={adminId}
              onClose={handelCloseViewEnquiry}
              adminDetails={adminDetails}
            />
          )}
          {passwordResetWarningPopup && (
            <CustomerWarningPopup
              title={"Confirmation"}
              show={passwordResetWarningPopup}
              message={warningMessage}
              warningYesClick={passwordResetWarningYesClick}
              onClose={handlePasswordResetWarningClose}
            />
          )}
          {isLoading && <Loader className={"fixed"} />}
        </div>
      ) : (
        <AccessDenied />
      )}
    </>
  );
}
