import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import { FaRegFilePdf } from 'react-icons/fa';
import { IoEyeOutline } from 'react-icons/io5';
import { IoIosArrowBack, IoIosArrowForward, IoMdDownload } from 'react-icons/io';
import FormModal from '../Modal';

function BrochureAdmin({ DemoSwiperimages }) {
    const [openModalBrochure, setopenModalBrochure] = useState(false);
    const [pdfData, setPDfData] = useState([]);
    const [currentSlideslide, setCurrentSlideslide] = useState(0);

    const handleViewBrochure = (image) => {
        setopenModalBrochure(true);
        setPDfData(image);
    };

    const handleDownLoad = (url, filename) => {
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    useEffect(() => {
        if (openModalBrochure) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [openModalBrochure]);

    const [iframeError, setIframeError] = useState(false);

    const handleIframeError = () => {
        setIframeError(true);
    };

    return (
        <div>
            <div className="d-flex justify-content-between align-items-center p-4">
                <div className="d-flex align-items-center gap-2">
                    <FaRegFilePdf style={{ color: "#6C8C91", fontSize: "1.3rem" }} />
                    <h1 className={"fs-5 pt-1"}>Brochure</h1>
                </div>
            </div>

            <div className="position-relative d-flex justify-content-center align-items-center w-100 h-100">
                <Swiper
                    slidesPerView={1}
                    spaceBetween={27}
                    loop={true}
                    navigation={{ nextEl: ".arrow-right-row2", prevEl: ".arrow-left-row1" }}
                    breakpoints={{
                        499: { slidesPerView: 2, spaceBetween: 6 },
                        650: { slidesPerView: 3, spaceBetween: 6 },
                        999: { slidesPerView: 3, spaceBetween: 6 },
                    }}
                    className="z-0 w-100"
                >
                    <SwiperSlide className="w-100 border" style={{ width: '100%', minHeight: '70vh', position: 'relative' }}>
                        <div className="d-flex justify-content-center w-100" style={{ width: '100%', minHeight: '70vh', position: 'relative',  }}>
                            {DemoSwiperimages[0]?.viewUrl && !iframeError ? (
                                <iframe
                                    src={`https://docs.google.com/gview?url=${encodeURIComponent(DemoSwiperimages[0]?.viewUrl)}&embedded=true`}
                                    style={{ width: '100%', height: '70vh', position: 'relative', border: 'none' ,overflowY: 'hidden'}}
                                    title="PDF Viewer"
                                    onError={handleIframeError}
                                />
                            ) : (
                                <div>No document available for viewing</div>
                            )}
                        </div>

                        <div className="position-absolute inset-0 backdrop-blur-sm z-10 bg-white/20"></div>
                        <div
                            className="position-absolute gap-2 bg-white rounded-2 d-flex pt-3 px-3 bg-white/20 bg-[#D9E5E6] mt-3 top-50 start-50 translate-middle z-20"
                            onClick={() => { handleViewBrochure(DemoSwiperimages[0]?.viewUrl); }}
                            style={{ cursor: "pointer" }}
                        >
                            <IoEyeOutline style={{ color: "#6C8C91", fontSize: "25px" }} />
                            <p className="fs-5" style={{ color: "#6C8C91", fontWeight: "600" }}>View Brochure</p>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>

            {/* Modal View All */}
            <FormModal
                show={openModalBrochure}
                onClose={() => setopenModalBrochure(false)}
                heading={"Brochure"}
                size="xl"
            >
                <div style={{ width: '100%', height: '70vh', position: 'relative', overflow: 'hidden' }}>
                    <Swiper
                        slidesPerView={1}
                        scrollbar={true}
                        autoplay={{ delay: 10000, disableOnInteraction: false }}
                        speed={500}
                        loop
                        pagination={{ clickable: true }}
                        navigation={{ nextEl: ".bannerPrev", prevEl: ".bannerNext", disabledClass: "#08536B" }}
                        modules={[Navigation, Pagination, Autoplay]}
                        onSlideChange={(slide) => setCurrentSlideslide(slide?.realIndex)}
                        className="mySwiper landing-swiper w-100 position-relative"
                    >
                        {DemoSwiperimages?.map((image, index) => (
                            <SwiperSlide key={index} className="w-100 border" style={{ width: '100%', height: '70vh', position: 'relative' }}>
                                <div className="d-flex justify-content-center w-100" style={{ height: '100%', overflowY: 'auto' }}>
                                    {image?.viewUrl && !iframeError ? (
                                        <iframe
                                            src={`https://docs.google.com/gview?url=${encodeURIComponent(image?.viewUrl)}&embedded=true`}
                                            style={{ width: '100%', height: '100%', border: 'none' }}
                                            title="PDF Viewer"
                                            onError={handleIframeError}
                                        />
                                    ) : (
                                        <div>No document available for viewing</div>
                                    )}
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>

                    {/* Custom navigation buttons */}
                    <div style={{ display: 'block' }}>
                        <div
                            id="bannerNext"
                            className="bannerNext"
                            style={{
                                position: 'absolute',
                                left: '4px',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                height: '80px',
                                width: '40px',
                                zIndex: 20,
                                cursor: 'pointer',
                                fontSize: '30px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                border: '1px solid #08536B',
                                background: 'rgba(0, 37, 31, 0.2)',
                                backdropFilter: 'blur(10px)',
                            }}
                        >
                            <IoIosArrowBack style={{ color: 'white' }} />
                        </div>
                        <div
                            id="bannerPrev"
                            className="bannerPrev"
                            style={{
                                position: 'absolute',
                                right: '4px',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                height: '80px',
                                width: '40px',
                                zIndex: 20,
                                cursor: 'pointer',
                                fontSize: '30px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                border: '1px solid #08536B',
                                background: 'rgba(0, 37, 31, 0.2)',
                                backdropFilter: 'blur(10px)',
                            }}
                        >
                            <IoIosArrowForward style={{ color: 'white', fontWeight: 'bold' }} />
                        </div>
                    </div>
                    <div
                        style={{
                            position: 'absolute',
                            right: '0',
                            bottom: '0',
                            fontSize: '1.25rem',
                            fontWeight: 'bold',
                        }}
                    >
                        {currentSlideslide + 1}/{DemoSwiperimages?.length}
                    </div>
                </div>
            </FormModal>

            <div className="d-flex justify-content-end my-3" onClick={() => { handleDownLoad(DemoSwiperimages[0]?.viewUrl, DemoSwiperimages[0]?.name); }}>
                <div className="d-flex gap-2 p-2 rounded" style={{ background: '#D9E5E6', cursor: 'pointer' }}>
                    <IoMdDownload style={{ fontSize: '1.8rem', color: '#08536B' }} />
                    <div className="fs-5">Download</div>
                </div>
            </div>
        </div>
    );
}

export default BrochureAdmin;
