import { useMemo, useState, useCallback } from "react";
import { FaCloudUploadAlt } from "react-icons/fa";
import { useDropzone } from "react-dropzone";
import { useEffect } from "react";
import Loader from "../../components/Loader";
import { postUploadData } from "../../api";
import NoImage from "../../assets/images/noImage.jpg";
import swal from "sweetalert";
import "react-toastify/dist/ReactToastify.css";

import "./style.scss";
const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "rgba(56, 78, 183, 0.3)",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};
export default function Dropzone(props) {
  const [fileList, setfileList] = useState([]);
  const [fileURL, setfileURL] = useState([]);
  const [initLoading, setInitLoading] = useState(false);
  const [interior, setinterior] = useState([]);
  const [exterior, setExterior] = useState([]);
  const [frontSide, setForntSide] = useState([]);
  const [coworkImagesVideos, setCoworkImagesVideos] = useState([]);
  useEffect(() => {
    let data;
    if (props.name === "interior") {
      data = {
        name: "interior",
        data: interior,
      };
    } else if (props.name === "exterior") {
      data = {
        name: "exterior",
        data: exterior,
      };
    } else if (props.name === "coworkImagesAndVideos") {
      data = {
        name: "coworkImagesAndVideos",
        data: coworkImagesVideos,
      };
    } else {
      data = {
        name: "frontSide",
        data: frontSide,
      };
    }
    props.handleImagesAll(data);
  }, [interior, exterior, frontSide, coworkImagesVideos]);

  const handleApiFile = async (acceptedFiles) => {
    // isLoading()
    setInitLoading(true);
    let acceptData = acceptedFiles;
    const formData = new FormData();
    for (let i = 0; i < acceptData.length; i++) {
      if (
        acceptData[i].size > 20000 * 1024 ||
        acceptData[i].size === undefined
      ) {
        swal({
          // title: header,
          text: "file size should be less than 20 MB",
          icon: "warning",
          timer: 3000,
          buttons: false,
        });
        setInitLoading(false);
      }
      if (
        acceptData[i].type === "application/pdf" ||
        acceptData[i].type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        acceptData[i].type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        swal({
          // title: header,
          text: "Only Image or Video is accepted",
          icon: "warning",
          timer: 3000,
          buttons: false,
        });
        setInitLoading(false);
      } else {
        formData.append("uploadfiles", acceptData[i]);
      }
    }

    let url = `/user/file-upload/${JSON.parse(localStorage.getItem("generatedId"))
        ? JSON.parse(localStorage.getItem("generatedId"))
        : props.propertyId
      }?type=image&module=property`;

    const newData = await postUploadData(url, {}, formData);
    const { statusCode, data } = newData;
    if (statusCode === 200) {
      // isNotLoading()
      setInitLoading(false);
      if (props.name === "interior") {
        // setfileURL([]);
        data.map((item) => {
          setfileURL((prev) => [...prev, item]);

          setinterior((arr) => [...arr, item]);
          return item;
          // }
        });
      } else if (props.name === "exterior") {
        // setfileURL([]);
        data.map((item) => {
          setfileURL((prev) => [...prev, item]);

          setExterior((arr) => [...arr, item]);
          return item;
          // }
        });
      } else if (props.name === "coworkImagesAndVideos") {
        data.forEach((item) => {
          setfileURL((prev) => [...prev, item]);

          setCoworkImagesVideos((arr) => [...arr, item]);
        });
      } else {
        // setfileURL([]);
        data.map((item) => {
          setfileURL((prev) => [...prev, item]);

          setForntSide((arr) => [...arr, item]);
          return item;
          // }
        });
      }
    } else {
      setInitLoading(false);
    }

    // })
  };

  const onDrop = useCallback(
    (acceptedFiles, name, deletePosition) => {
      // setfileList([...fileList,acceptedFiles])
      if (name !== "delete") {
        if (fileList.length !== 0) {
          acceptedFiles.forEach((file) => {
            const reader = new FileReader();
            reader.onload = () => {
              setfileURL((prev) => [...prev, reader.result]);
            };
            reader.readAsDataURL(file);
            setfileList([...fileList, file]);
          });
        } else {
          acceptedFiles.forEach((file) => {
            const reader = new FileReader();
            reader.onload = () => {
              setfileURL((prev) => [...prev, reader.result]);
            };
            reader.readAsDataURL(file);
            fileList.push(file);
          });
        }

        handleApiFile(acceptedFiles);
      } else {
        setfileURL([]);
        setfileList([]);
        acceptedFiles.forEach((file) => {
          const reader = new FileReader();
          reader.onload = () => {
            setfileURL((prev) => [...prev, reader.result]);
          };
          reader.readAsDataURL(file);
          setfileList((prev) => [...prev, file]);
        });
      }
    },
    [fileList, fileURL]
  );

  const handleDelete = (position) => {
    if (props.name === "interior") {
      interior.splice(position, 1);
      setinterior(interior);
    } else if (props.name === "exterior") {
      exterior.splice(position, 1);
      setExterior(exterior);
    } else if (props.name === "coworkImagesAndVideos") {
      coworkImagesVideos.splice(position, 1);
      setCoworkImagesVideos(exterior);
    } else {
      frontSide.splice(position, 1);
      setForntSide(frontSide);
    }

    fileList.splice(position, 1);
    setfileList(fileList);
    onDrop(fileList, "delete", position);
    // handleImgData(position)
  };

  useEffect(() => {
    if (props?.imagesList?.imagesAndVideos !== undefined) {
      const { interior, exterior, frontSide, coworkImagesVideos } =
        props?.imagesList?.imagesAndVideos;
      if (props.name === "interior") {
        setinterior([]);
        interior.map((item) => {
          setinterior((arr) => [...arr, item]);
          return item;
        });
      } else if (props.name === "exterior") {
        setExterior([]);
        exterior.map((item) => {
          setExterior((arr) => [...arr, item]);
          return item;
        });
      } else if (props.name === "coworkImagesAndVideos") {
        setCoworkImagesVideos([]);
        coworkImagesVideos.forEach((item) => {
          setCoworkImagesVideos((arr) => [...arr, item]);
        });
      } else {
        setForntSide([]);
        frontSide.map((item) => {
          setForntSide((arr) => [...arr, item]);
          return item;
        });
      }
    }
  }, [props?.imagesList?.imagesAndVideos]);

  const { getRootProps, getInputProps, open } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    onDrop,
    accept: {
      'image/png': ['.png', '.bmp', '.jpg', '.jpeg', '.tiff', '.tif', '.jfif', '.webP', '.heic', '.heif'],
      "audio/*": [],
      "video/mp4": ['.mp4', '.mkv', '.mov', '.mpeg', '.m4v', '.flv', '.ogv']
    },
    minSize: 0,
  });
  const style = useMemo(
    () => ({
      ...baseStyle,
    }),
    []
  );

  return (
    <div className="container">
      {initLoading && <Loader className={"fixed"} />}
      <div {...getRootProps({ style })}>
        <input
          {...getInputProps()}
          multiple={true}
          accept="image/png, image/jpeg"
        />
        <div className="image-dropzoneWrapper image-container-grids">
          {props?.imagesList?.imagesAndVideos === undefined
            ? // ? fileURL.length > 0 &&

            props.name === "interior"
              ? interior?.length > 0 &&
              interior?.map((item, index) => (
                <div className={"dropZone-ImageSize grid-image"} key={index}>
                  {item?.type === "video" ? (
                    <video width="150" height="150" controls>
                      <source src={item?.viewUrl} type="video/mp4" />
                    </video>
                  ) : item?.type === "image" ? (
                    <img
                      src={item?.viewUrl}
                      alt="images"
                      className={"images-dropzone"}
                    />
                  ) : (
                    <img
                      src={NoImage}
                      alt="images"
                      className={"images-dropzone"}
                    />
                  )}
                  <div
                    className="remove-image"
                    onClick={() => handleDelete(index)}
                  >
                    Remove
                  </div>
                </div>
              ))
              : props.name === "exterior"
                ? exterior?.length > 0 &&
                exterior?.map((item, index) => (
                  <div className={"dropZone-ImageSize grid-image"} key={index}>
                    {item?.type === "video" ? (
                      <video width="150" height="150" controls>
                        <source src={item?.viewUrl} type="video/mp4" />
                      </video>
                    ) : item?.type === "image" ? (
                      <img
                        src={item?.viewUrl}
                        alt="images"
                        className={"images-dropzone"}
                      />
                    ) : (
                      <img
                        src={NoImage}
                        alt="images"
                        className={"images-dropzone"}
                      />
                    )}
                    <div
                      className="remove-image"
                      onClick={() => handleDelete(index)}
                    >
                      Remove
                    </div>
                  </div>
                ))
                : props.name === "frontSide"
                  ? frontSide?.length > 0 &&
                  frontSide?.map((item, index) => (
                    <div className={"dropZone-ImageSize grid-image"} key={index}>
                      {item?.type === "video" ? (
                        <video width="150" height="150" controls>
                          <source src={item?.viewUrl} type="video/mp4" />
                        </video>
                      ) : item?.type === "image" ? (
                        <img
                          src={item?.viewUrl}
                          alt="images"
                          className={"images-dropzone"}
                        />
                      ) : (
                        <img
                          src={NoImage}
                          alt="images"
                          className={"images-dropzone"}
                        />
                      )}
                      <div
                        className="remove-image"
                        onClick={() => handleDelete(index)}
                      >
                        Remove
                      </div>
                    </div>
                  ))
                  : props.name === "coworkImagesAndVideos"
                    ? coworkImagesVideos?.length > 0 &&
                    coworkImagesVideos?.map((item, index) => (
                      <div className={"dropZone-ImageSize grid-image"} key={index}>
                        {item?.type === "video" ? (
                          <video width="150" height="150" controls>
                            <source src={item?.viewUrl} type="video/mp4" />
                          </video>
                        ) : item?.type === "image" ? (
                          <img
                            src={item?.viewUrl}
                            alt="images"
                            className={"images-dropzone"}
                          />
                        ) : (
                          <img
                            src={NoImage}
                            alt="images"
                            className={"images-dropzone"}
                          />
                        )}
                        <div
                          className="remove-image"
                          onClick={() => handleDelete(index)}
                        >
                          Remove
                        </div>
                      </div>
                    ))
                    : null
            : interior?.length > 0 && props.name === "interior"
              ? interior?.map((item, index) => (
                <div className={"dropZone-ImageSize grid-image"} key={index}>
                  {/* <img
                      src={item?.viewUrl}
                      className={"images-dropzone"}
                      alt="images"
                    /> */}
                  {item?.type === "video" ? (
                    <video width="150" height="150" controls>
                      <source src={item?.viewUrl} type="video/mp4" />
                    </video>
                  ) : item?.type === "image" ? (
                    <img
                      src={item?.viewUrl}
                      alt="images"
                      className={"images-dropzone"}
                    />
                  ) : (
                    <img
                      src={NoImage}
                      alt="images"
                      className={"images-dropzone"}
                    />
                  )}
                  <div
                    className="remove-image"
                    onClick={() => handleDelete(index)}
                  >
                    Remove
                  </div>
                </div>
              ))
              : exterior.length > 0 && props.name === "exterior"
                ? exterior?.map((item, index) => (
                  <div className={"dropZone-ImageSize grid-image"} key={index}>
                    {/* <img
                      src={item?.viewUrl}
                      className={"images-dropzone"}
                      alt="images"
                    /> */}
                    {item?.type === "video" ? (
                      <video width="150" height="150" controls>
                        <source src={item?.viewUrl} type="video/mp4" />
                      </video>
                    ) : item?.type === "image" ? (
                      <img
                        src={item?.viewUrl}
                        alt="images"
                        className={"images-dropzone"}
                      />
                    ) : (
                      <img
                        src={NoImage}
                        alt="images"
                        className={"images-dropzone"}
                      />
                    )}
                    <div
                      className="remove-image"
                      onClick={() => handleDelete(index)}
                    >
                      Remove
                    </div>
                  </div>
                ))
                : props.name === "frontSide" && frontSide.length > 0
                  ? frontSide?.map((item, index) => (
                    <div className={"dropZone-ImageSize grid-image"} key={index}>
                      {/* <img
                      src={item?.viewUrl}
                      className={"images-dropzone"}
                      alt="images"
                    /> */}
                      {item?.type === "video" ? (
                        <video width="150" height="150" controls>
                          <source src={item?.viewUrl} type="video/mp4" />
                        </video>
                      ) : item?.type === "image" ? (
                        <img
                          src={item?.viewUrl}
                          alt="images"
                          className={"images-dropzone"}
                        />
                      ) : (
                        <img
                          src={NoImage}
                          alt="images"
                          className={"images-dropzone"}
                        />
                      )}
                      <div
                        className="remove-image"
                        onClick={() => handleDelete(index)}
                      >
                        Remove
                      </div>
                    </div>
                  ))
                  : props.name === "coworkImagesAndVideos" &&
                    coworkImagesVideos.length > 0
                    ? coworkImagesVideos?.map((item, index) => (
                      <div className={"dropZone-ImageSize grid-image"} key={index}>
                        {item?.type === "video" ? (
                          <video width="150" height="150" controls>
                            <source src={item?.viewUrl} type="video/mp4" />
                          </video>
                        ) : item?.type === "image" ? (
                          <img
                            src={item?.viewUrl}
                            alt="images"
                            className={"images-dropzone"}
                          />
                        ) : (
                          <img
                            src={NoImage}
                            alt="images"
                            className={"images-dropzone"}
                          />
                        )}
                        <div
                          className="remove-image"
                          onClick={() => handleDelete(index)}
                        >
                          Remove
                        </div>
                      </div>
                    ))
                    : null}
        </div>

        <FaCloudUploadAlt size="50px" color="#034168" onClick={open} />
        <p className="drag-container-text">
          Drag & image or Video files or{" "}
          <a className="drag-container-link" onClick={open} >
            Browse
          </a>
        </p>
        <span className="drag-container-span">
          Each Property image should be under 2MB <br />
          Each Property Video should be under 20MB <br />
          Image Resolution should be at least 300 pixels per inch (ppi) <br />
          {props.name === "frontSide" && (
            <span className="drag-container-span">
              Note : First Image of Front Side Categories will be display as
              main image
            </span>
          )}
        </span>
      </div>
    </div>
  );
}