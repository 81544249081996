export const eventsDefaultValue = [{ label: "Events ", value: "select" }];

export const activityLogFilterOptions = [
  // {
  //   id: 1,
  //   fieldType: "search",
  //   name: "search",
  // },
  {
    id: 2,
    fieldType: "select",
    isMulti: false,
    name: "eventNameList",
    key: "eventNameList",
    option: [],
    defaultValue: eventsDefaultValue,
  },
  {
    id: 4,
    fieldType: "datePicker",
    isMulti: false,
    name: "startDate",
    key: "sort",
    option: [],
  },
  {
    id: 4,
    fieldType: "datePicker",
    isMulti: false,
    name: "endDate",
    key: "sort",
    option: [],
  },
  {
    id: 3,
    fieldType: "export",
    name: "Export to CSV",
    key: "export",
  },
];

