import React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import FormModal from "../Modal";
import { getData, updateSubscription } from "../../api";

// custom components

import Button from "../Form/Button";
import {
  RenderInput,
  RenderSelect,
} from "../../pages/Customer/CustomerForm/customComponents/inputGroups";
import { errorCheck } from "./developerSubscriptionRenewErrorCheck";
import { getRm } from "../../api/api";

const SubscriptionRenew = ({
  subscriptionArray,
  showRenewModal,
  setShowRenewModal,
  companyId,
  setRmList,
  rmList,
  getCustomerData
}) => {
  let brandID = useSelector((state) => state.login.companyId);
  let location = useLocation();
  let companyTypeReceived = "companyNoDeveloper";

  const [submissionInputShow, setSubmissionInputShow] = useState(false);
  const [propertyDemandInput, setPropertyDemandInput] = useState(false);
  const [loading, setLoading] = useState(false);
  //error object
  const [errorObject, setErrorObject] = useState({});
  const [subscription, setSubscription] = useState({
    propertyOrDemandSelect: "",
    propertyOrDemand: "",
    validityDays: "",
    userDashboard: "Yes",
    demandMails: "",
    homePageSlotAndBanner: "",
    assistedUploading: "",
    featuredProperty: "",
    submissionAgainstDemand: "",
    submissionAgainstDemandSelect: "",
    relationshipManager: "",
    rm: "",
  });

  const handleSubscriptionRenew = () => {
    delete subscription.submissionAgainstDemandSelect;
    delete subscription.propertyOrDemandSelect;
    if (subscription?.relationshipManager === "No") {
      delete subscription?.rm;
    }
    setLoading(true);
    updateSubscription(companyId, { ...subscription }, "Developer").then(
      (res) => {
        const {status} = res
        if(status) {
          setShowRenewModal(false)
          getCustomerData()
        }
        setLoading(false);
      }
    );
  };

  const handleOnSubmit = (event) => {
    if (subscription.submissionAgainstDemandSelect === "Unlimited") {
      subscription.submissionAgainstDemand = "Unlimited";
    }
    if (subscription.propertyOrDemandSelect === "Unlimited") {
      subscription.propertyOrDemand = "Unlimited";
    }

    event.preventDefault();
    let errors = errorCheck(subscription);
    if (Object.keys(errors).length === 0) {
      handleSubscriptionRenew(event);
    } else {
      setErrorObject(errors);
    }
  };

  const getRmList = () => {
    getData(getRm(), {}, {}).then((response) => {
      setRmList(response?.data?.subAdminList);
    });
  };
  useEffect(() => {
    if (subscription?.relationshipManager === "Yes") {
      getRmList();
    }
  }, [subscription?.relationshipManager]);

  const onChangeSubscription = (value, key) => {
    delete errorObject[key];
    if (key === "relationshipManager") {
      delete errorObject["rm"];
      if (value === "No") {
        // delete subscription["rm"]
        setSubscription({ ...subscription, rm: {}, [key]: value });
      } else {
        setSubscription({ ...subscription, [key]: value });
      }
    } else if (key === "rm") {
      let rmObj = rmList?.filter((el) => value === el?._id);
      setSubscription({ ...subscription, [key]: rmObj[0] });
    } else {
      setSubscription({ ...subscription, [key]: value });
    }
  };
  return (
    <FormModal
      show={showRenewModal}
      heading="Renew Subscription"
      size="lg"
      onClose={() => setShowRenewModal(false)}
    >
      <div className="row mb-3">
        {subscriptionArray?.length > 0 &&
          subscriptionArray.map((item, i) => {
            // destructuring subscription array
            const { key, type, inputType, placeholder, name, option, subKey } =
              item;
            return (
              <React.Fragment key={i}>
                {type === "input" ? (
                  <RenderInput
                    name={name}
                    keyValue={key}
                    value={subscription[key]}
                    // disabled={
                    //   location?.state?.view || !subscrptionEdit ? true : false
                    // }
                    error={errorObject[key]}
                    inputType={inputType}
                    objName="subscription"
                    onChangeSubscription={onChangeSubscription}
                    // edit={edit}
                    placeholder={placeholder}
                    subKey={subKey ? subKey : ""}
                    submissionInputShow={submissionInputShow}
                    propertyDemandInput={propertyDemandInput}
                    size="w-50"
                  />
                ) : (
                  <RenderSelect
                    name={name}
                    keyValue={key}
                    option={option}
                    value={subscription[key]}
                    // disabled={
                    //   location?.state?.view || !subscrptionEdit ? true : false
                    // }
                    error={errorObject[key]}
                    placeholder={placeholder}
                    onChangeSubscription={onChangeSubscription}
                    objName="subscription"
                    subKey={subKey ? subKey : ""}
                    rmVal={subscription?.relationshipManager}
                    setSubmissionInputShow={setSubmissionInputShow}
                    setPropertyDemandInput={setPropertyDemandInput}
                    subscription={subscription}
                    companyTypeReceived={companyTypeReceived}
                    size="w-50"
                  />
                )}
              </React.Fragment>
            );
          })}
        <br />
        <div className="btn-container mt-1 ">
          <>
            <Button
              type="button"
              className="btn-outline-color-primary d-block"
              onClick={(e) => {
                setShowRenewModal(false);
              }}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              type="button"
              // disabled={loading || !subscrptionEdit}
              // loading={loading}
              className="btn-color-primary d-block"
              onClick={(e) => {
                handleOnSubmit(e);
              }}
              loading={loading}
              disabled={loading}
            >
              Save
            </Button>
          </>
        </div>
      </div>
    </FormModal>
  );
};

export default SubscriptionRenew;
