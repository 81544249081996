import React from 'react';

function DetailSection({ demoData }) {
    return (
        <div className="container-fluid " style={{background:'#D1E7EE'}}>
            <div className="d-flex gap-4 p-3" style={{background:'#D1E7EE'}}>
                {demoData.map((item) => (
                    <div key={item.id} className="d-flex flex-column justify-content-center align-items-center col pt-2 rounded  bg-white">
                        <p className=" fs-7" style={{color:"#08536B"}}>{item.title}</p>
                        
                        <p className=" fs-6 fw-bold" style={{color:"#08536B"}}>{item.name}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default DetailSection;
