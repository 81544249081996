import { useEffect, useState } from "react";
// import ReportCards from "../../../components/Cards/report";
// import { cardArray, cardDemandArray } from "./reportsArray";
// import Loader from "../../../components/Loader";
import { useSelector, useDispatch } from "react-redux";
// import {
//   updateParentPageData,
//   updateSelectedAgentReportTab,
// } from "../../../pages/Login/reducer";
import { useLocation } from "react-router-dom";
// import { getData } from "../../../api";
// import CTab from "../../../components/Tab";
import { cardArray } from "../../reportsArray";
import { getData } from "../../../../../api";
import CTab from "../../../../../components/Tab";
import { updateParentPageData, updateSelectedAgentReportTab, updateSelectedEnterpriseAgentReportTab } from "../../../../Login/reducer";
import Loader from "../../../../../components/Loader";
import ReportCards from "../../../../../components/Cards/report";

export default function EnterpriseAgentReportData() {
  const [filterArray, setFilterArray] = useState([]);
  const [demandsFilterArray, setDemmandFilterArray] = useState([]);
  const location = useLocation();
  const [isloding, setIsLoading] = useState(false);
  const [reportValue, setReportValue] = useState({});
  const [key, setKey] = useState("properties");
  const [agentTabKey, setAgentTabKey] = useState("enterpriseAgent");
  const user = useSelector((state) => state.login.parentPage);
  const selectedEnterpriseAgentReportTab = useSelector(
    (state) => state.login.selectedEnterpriseAgentReportTab
  );

  // const storeuser = location?.state?.user ? location?.state?.user : user;
  const storeuser = "enterpriseAgent";
  const getReportData = async () => {
    setIsLoading(true);
    let url = `/admin/reportNew?type=${storeuser==="enterpriseAgent"? "eagent":storeuser}&sectionType=${selectedEnterpriseAgentReportTab}`;
    const res = await getData(url, {}, {});
    const { statusCode, data } = res;
    if (statusCode === 200) {
      setIsLoading(false);
      setReportValue(data);
    } else {
      setIsLoading(false);
    }
  };

  const setTabKey = (k) => {
    // setOffset(1);
    setKey(k);
    // setContextValue(k);
    // localStorage.setItem("companyTabKey", k);
  };

  const dispath = useDispatch();
  
  useEffect(() => {
    getReportData();
    // if (storeuser) {
    //   dispath(updateParentPageData(storeuser));

      let filterValue = cardArray.filter((item) => item.name === "enterpriseAgent");
        if (selectedEnterpriseAgentReportTab === "demands") {
          setFilterArray(filterValue[0]?.value2);
        } else if (selectedEnterpriseAgentReportTab === "properties") {
          setFilterArray(filterValue[0]?.value1);
        } else if (selectedEnterpriseAgentReportTab === "contacts") {
          setFilterArray(filterValue[0]?.value3);
        }
      

      return () => {
        setFilterArray([]);
        setReportValue({});
      };
    // }
  }, [storeuser, selectedEnterpriseAgentReportTab]);
  
  const tabDetails = [
    {
      title: "Properties",
      content: (
        <>
          {key === "properties" ? (
            <ReportCards keyData={filterArray} value={reportValue} />
          ) : null}
        </>
      ),
      eventKey: "properties",
    },
    {
      title: "Demands",
      content: (
        <>
          <ReportCards keyData={filterArray} value={reportValue} />
        </>
      ),
      eventKey: "demands",
    },

    {
      title: "Contacts",
      content: <ReportCards keyData={filterArray} value={reportValue} />,
      eventKey: "contacts",
    },
    // {
    //   title: "Responses",
    //   content: <>{key === "responses" ? <div>Responses Report</div> : null}</>,
    //   eventKey: "responses",
    // },
  ];
  return (
    <>
      {isloding ? (
        <Loader />
      ) : storeuser === "enterpriseAgent" ? (
        <div className="w-100">
          <CTab
            tabDetails={tabDetails}
            activeKey={selectedEnterpriseAgentReportTab}
            onSelect={(k) => {
              dispath(updateSelectedEnterpriseAgentReportTab(k));
            }}
          />
        </div>
      ) : (
        <ReportCards keyData={filterArray} value={reportValue} />
      )}
    </>
  );
}
