import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getCurrentUser, getData, handleDashboardNewResponse, postExportData, postGetData, putGetData } from '../../../api';
import { MdDelete, MdEdit } from 'react-icons/md';
// import Search from '../../components/Search/search';
// import Select from '../../components/Form/Select';
import ReactPaginate from 'react-paginate';
import { HiOutlineChevronDoubleLeft, HiOutlineChevronDoubleRight } from 'react-icons/hi';
import { IoChevronBackOutline, IoChevronForwardOutline, IoEyeSharp } from 'react-icons/io5';
import { Form } from 'react-bootstrap';
// import { CustomerDeleteWarningPopUp } from '../Customer/CustomerTable/CustomerDeleteWarningPopUp';
// import Loader from '../../components/Loader';
import { useSelector } from 'react-redux';
import { CustomerDeleteWarningPopUp } from '../../Customer/CustomerTable/CustomerDeleteWarningPopUp';
import Loader from '../../../components/Loader';
import Select from '../../../components/Form/Select';
import Search from '../../../components/Search/search';
// import { putGetData } from '../../../api';

const ProjectsLeads = () => {
    const bearerTokenLocal = getCurrentUser()?.token;
    const loginPermission = useSelector((state) => state.login.userPermission);
    const menuId = useSelector((state) => state.login.menuId);
    let addpermission = loginPermission.includes(menuId.addProject);
    let viewpermission = loginPermission.includes(menuId.viewProject);
    let deletePermission = loginPermission.includes(menuId.deleteProject);
    let editProjectPermission = loginPermission.includes(menuId.editProject);
    let projectEnableDisablePermission = loginPermission.includes(
        menuId.projectEnableDisable
    );
    const [search, setSearch] = useState('');
    const [SortBy, setSortBy] = useState()
    const [UserType, setUserType] = useState()
    const [projectAdminData, setProjectAdminData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pagination, setPagination] = useState()
    const [limit, setLimit] = useState(10);
    const [TotalCount, setTotalCount] = useState(0);
    const [deleteID, setDeleteID] = useState(null)
    const [showModal, setShowModal] = useState(false)
    const [loader, setLoader] = useState(false)
    const [updateDate, setUpdateDone] = useState(false)
    const sortby = [{ value: "asc", name: "Oldest to Newest " }, { value: "desc", name: "Newest to Oldest" }]
    const userTypeData = [
        { value: "Brand", name: "Brand" },
        { value: "Developer", name: "Developer" },
        { value: "Agent", name: "Agent" },
        { value: "Occupier", name: "Occupier" },
        { value: "Operator", name: "Operator" },
        { value: "External", name: "External" }
    ];


    const getAllProjectLead = async (payLoad) => {
        try {
            setLoader(true)
            const getList = await postGetData(`/admin/getAllProjectLead`, {}, payLoad)
            const { statusCode, data } = getList;
            if (statusCode === 200) {
                setLoader(false);
                setProjectAdminData(data?.projectEnqInfo)
                setPagination(data)
                setTotalCount(getList?.data?.totalCount)
            } else {
                setLoader(false);
            }
        } catch (e) {
            console.log(e, "error");
            setLoader(false)

        }
    }

    const OnClickExport = async (params) => {
        setLoader(true);

        postExportData(`/admin/getAllProjectLead`, {}, params).then((blob) => {
            if (blob) {
                setLoader(false);
                let urls = window.URL.createObjectURL(blob);
                let a = document.createElement("a");
                a.href = urls;
                a.download = `projectLeads.xlsx`;
                a.click();
            }
        });
    };

    const debounce = (func, delay) => {
        let timeoutId;
        return (...args) => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };
    const debouncedSearch = useCallback(debounce((searchTerm) => {
        setSearch(searchTerm);
    }, 1000), []);
    useEffect(() => {
        getAllProjectLead({
            "sort": SortBy,
            "search": search,
            "limit": limit,
            "pageNo": currentPage,
            ...(UserType) && { "userType": UserType },
        });
    }, [SortBy, search, limit, currentPage, UserType])


    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber.selected + 1);
    };
    const handleFirstPage = () => {
        setCurrentPage(1);
    };
    const handleLastPage = () => {
        setCurrentPage(TotalCount>0 ? Math.ceil(TotalCount / limit) : 1);
    };

    
    const navigate = useNavigate()
    const handleNavigation = (path) => {
        navigate(path);
    };
    const handleSearch = (e) => {
        const searchTerm = e.target.value;
        console.log(searchTerm, "aman")
        debouncedSearch(searchTerm);
    };
    const handlePerPage = (value) => {
        setLimit(Number(value));
        setCurrentPage(1);
    };
    const TableRows = [5, 10, 20, 50];
    const startItem = (currentPage - 1) * limit + 1;
    const endItem = Math.min(currentPage * limit, pagination?.totalCount);



    return (
        <div>
            <div className="  p-3 rounded-2 " style={{ background: "white" }}>
                <div className='d-flex py-2 justify-content-end  gap-3 align-self-center align-items-center'>


                    <div className='row row-cols-4 '>

                        <Select

                            options={sortby}
                            onChange={(e) => setSortBy(e.target.value)}
                            placeholder={"Sort By"}

                        />
                        <Select

                            options={userTypeData}
                            onChange={(e) => setUserType(e.target.value)}
                            placeholder={"User Type"}

                        />
                        <Search onChange={handleSearch} value={search} />
                        <div style={{
                            background: "#08536B",
                            cursor: "pointer",
                            height: "40px",
                            textAlign: "center",
                            color: "#D9E5E6",
                            width: "170px",
                            padding: "10px  auto",
                            borderRadius: "5px"

                        }} className="d-flex justify-content-center align-items-center" onClick={() => {

                            OnClickExport({
                                "projectId": '',
                                "userType": UserType,
                                "sort": SortBy,
                                "search": search,
                                "reportType": "export"
                            })
                        }}>
                            Export CSV
                        </div>
                    </div>
                </div>
                {projectAdminData?.length > 0 ? <div className="table-wrapper rounded-2 rounded  ">
                    {!loader ? <table className="table   border-success-subtle"
                    >
                        <thead className="thead-dark">
                            <tr>
                                <th className="py-2 text-center px-4">Sr. No</th>
                                <th className="py-2 text-center px-4">Timestamp</th>
                                <th className="py-2 text-center px-4">Name</th>
                                <th className="py-2 text-center px-4">Brand Name</th>
                                <th className="py-2 text-center px-4">User Type</th>
                                <th className="py-2 text-center px-4">Is Property Consultant </th>
                                <th className="py-2 text-center px-4">Email ID</th>
                                <th className="py-2 text-center px-4">Phone No</th>
                                <th className="py-2 text-center px-4">Project Name</th>
                                <th className="py-2 text-center px-4">City</th>

                                <th className="py-2 text-center px-4">Project ID</th>
                                <th className="py-2 text-center px-4">Developer Name</th>
                                <th className="py-2 text-center px-4">Developer POC</th>
                                <th className="py-2 text-center px-4">Status</th>
                            </tr>
                        </thead>
                        {projectAdminData?.map((value, index) => {
                            console.log(value.isActive, 'value.isActive')
                            const isoDateString = value?.createdAt;

                            // Parse the ISO date string into a Date object
                            const date = new Date(isoDateString);

                            // Format the date into the desired format
                            const formattedDate = date.toLocaleString("en-US", {
                                month: "numeric",
                                day: "numeric",
                                year: "numeric",

                            });
                            return <tbody key={index}>
                                <tr style={{ borderBottom: "1px solid #004A6E", background: "white", fontWeight: 'bold' }} >

                                    <td className="py-2 text-center px-3">{((currentPage - 1) * limit) + index + 1}</td>
                                    <td className="py-2 text-center px-3">{formattedDate}</td>
                                    <td className="py-2 text-center px-3 w-[200px]">{value?.name}</td>
                                    <td className="py-2 text-center px-3 w-[200px]">{value?.brandName}</td>
                                    <td className="py-2 text-center px-3 w-[100px]">{value?.type}</td>
                                    <td className="py-2 text-center px-3 w-[150px]">{value?.isConsultant ? "Yes" : "No"}</td>
                                    <td className="py-2 text-center px-3 w-[300px]">{value?.email}</td>
                                    <td className="py-2 text-center px-3 w-[150px]">{value?.phoneNumber}</td>
                                    <td className="py-2 text-center text-capitalize px-3 w-[400px]">{value?.projectId?.projectName}</td>
                                    <td className="py-2 text-center px-3 w-[200px]">{value?.city}</td>
                                    <td className="py-2 text-center  px-3 w-[100px]  !font-bold cursor-pointer" style={{
                                        borderBottom: "2px  solid #004A6E",
                                        background: "white",
                                        fontWeight: "bold",
                                        cursor: "pointer",
                                        color: "#004A6E",
                                        textDecorationLine: "underline"

                                    }} onClick={
                                        viewpermission
                                            ? () => {
                                                handleNavigation(
                                                    `/viewproject/${value?.projectId?._id}`
                                                );
                                            }
                                            : () => { }
                                    } >{value?.projectId?.displayId}</td>
                                    <td className="py-2 text-center text-capitalize px-3 w-[400px]">{value?.projectId?.developerName}</td>
                                    <td className="py-2 text-center px-3 w-[400px]">{value?.projectId?.developerPoc}</td>
                                    <td className="py-2 text-center px-3 w-[400px]">{value?.projectId?.isActive ? 'Active' : 'Inactive'}</td>

                                </tr>



                            </tbody>
                        })}
                    </table> : <><Loader /></>}



                </div> : <div className='d-flex w-100 align-items-center fs-5 justify-content-center py-4 ' >Data not found</div>}

                <div className='d-flex  justify-content-end p-2' style={{ backgroundColor: "#7FA2B829" }}>
                    <div className="d-flex align-items-center w-80 flex-shrink-1">
                        <label className="page">Rows per page: &nbsp;</label>
                        <div>
                            <Form.Select
                                aria-label="Default select example"
                                value={limit}
                                onChange={(e) => handlePerPage(e.target.value)}
                            >
                                {TableRows.map((rows, index) => (
                                    <option key={index} value={rows}>
                                        {rows}
                                    </option>
                                ))}
                            </Form.Select>
                        </div>
                    </div>
                    <div className="pagenationwrapper d-flex justify-content-end">
                        <div className="mr-2 text-end p-2 page pl-5 d-flex justify-content-end align-items-center page-count">
                            {`${startItem ? startItem : 0} - ${endItem ? endItem : 0} of ${pagination?.totalCount ? pagination?.totalCount : 0}`}
                        </div>
                        <div className="d-flex align-items-start justify-content-end page-between flex-shrink-1">
                            <div className="page__arrow" onClick={handleFirstPage}>
                                <HiOutlineChevronDoubleLeft size={15} style={{ color: 'black' }} />
                            </div>
                            <ReactPaginate
                                breakLabel=""
                                nextLabel={<IoChevronForwardOutline size={15} />}
                                marginPagesDisplayed={0}
                                pageRangeDisplayed={4}
                                pageCount={Math.ceil(pagination?.totalCount / limit)}
                                previousLabel={<IoChevronBackOutline size={15} />}
                                pageClassName="page"
                                pageLinkClassName="page"
                                previousClassName="page"
                                previousLinkClassName="page"
                                nextClassName="page"
                                nextLinkClassName="page"
                                activeClassName="pagination-active"
                                onPageChange={handlePageChange}
                                forcePage={currentPage - 1}
                            />
                          
                            <div className="page__arrow" onClick={handleLastPage}>
                                <HiOutlineChevronDoubleRight
                                    size={15}
                                    style={{ color: "black" }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div >
    )
}

export default ProjectsLeads;