import { useEffect, useState } from "react";
import Swal from "sweetalert2/dist/sweetalert2.js";

// components
import MainTable from "../../../../components/Table";
import Select from "../../../../components/Form/Select/index";
import Search from "../../../../components/Search/search";
import {
  AccessDenied,
  getAllSwitchUsers,
  getCurrentUser,
  getData,
  getData2,
  handleDashboardNewResponse,
  handleRmDashboardNewReleventCrossAction,
  postGetData,
  switchToUsers,
} from "../../../../api";

import { Row, Col } from "react-bootstrap";

// icons and images
import CrossSign from "../../../../assets/images/CrossSign.svg";
import Loader from "../../../../components/Loader";
import SwitchAccountPopup from "../../../../components/switchModal/SwitchCompanyBuyerPopup";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { PHYSICAL_TEAM, PHYSICALTEAM, PROPVANTAGE } from "../../../../custom/constants";

const ListResponsePropertyPhysicalTeam = ({ filterData, trigger, tableType }) => {
  const [offSet, setOffset] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [tableData, setTableData] = useState([]);

  const [PropertyId, setPropertyId] = useState();
  const [sort, setSort] = useState("");
  const [search, setSearch] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  // const newResponses = searchParams.get("filter") === "new";
  const newResponses = tableType.includes("New");
  const [listType, setListType] = useState(
    searchParams.get("filter") === "new" ? "new" : "all"
  );

  // account switch modal
  const [switchModal, setSwitchModal] = useState(false);
  const [pocs, setPocs] = useState({});
  const [companyId, setCompanyId] = useState(null);

  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  const loginPermission = useSelector((state) => state.login.userPermission);
  const menuId = useSelector((state) => state.login.menuId);

  const handleAccountSwitch = async (id) => {
    const data = {
      customerId: id,
      type: "user",
    };
    switchToUsers(data).then((res) => {
      let openUrl = process.env.REACT_APP_SWITCH_URL;
      if (res.status) {
        window.open(`${openUrl}/${res?.data?.token}`);
      } else {
        Swal.fire({
          // title: header,
          text: "No Poc Found",
          icon: "error",
          timer: 3000,
          buttons: false,
          showCancelButton: false,
          showConfirmButton: false,
          showCloseButton: true,
        });
      }
    });
  };

  const handlePropertyResponse = async (row) => {

    const url = `/property/propertyRelevantRead/${row?.productId}`;
    let body = {
      type:PHYSICAL_TEAM
  }
    const res = await handleRmDashboardNewReleventCrossAction(url,body);
    // const url = `/property/updateResponseStatus`;
    // const data = {
    //   propertyId: row?.propertyId,
    //   responseId: row?.response?._id,
    //   responseTypeId: row?.response?.types?._id,
    // };

    // const res = await handleDashboardNewResponse(url, data);
    if (res?.status) {
      getTableData(false);
    }
  };

  const getFormattedResponse = (response) => {
    if (response === "contectSeller") {
      return "Contact Selller";
    }

    if (response === "accept") {
      return "Accept";
    }
  };

  const columnsBrand = [
    {
      text: "Timestamp",
      dataField: "respondedOn",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div>
            {new Date(row.respondedOn).toLocaleString(undefined, {
              timeZone: "Asia/Kolkata",
            })}
          </div>
        );
      },
    },
    {
      text: "Physical Team Name",
      dataField: "agentName",
    },
    {
      text: "R Type",
      dataField: "ResponseType",
      formatter: (cellContent, row, rowIndex) => {
        return <div>{getFormattedResponse(row?.response?.types?.type)}</div>;
      },
    },
    {
      text: "Property ID",
      dataField: "productId",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div
            className="hyperlinkText"
            onClick={(e) => {
              navigate("propertyDetailView/" + row?.productId, {
                state: { isNew: newResponses },
              });
            }}
          >
            {row?.productId}
          </div>
        );
      },
    },
    {
      text: "Submitted By",
      dataField: "submittedBy",
      formatter: (cellContent, row, rowIndex) => {
        return <div>{row?.responededBy}</div>;
      },
    },
    {
      text: "POC Name",
      dataField: "pocName",
    },
    {
      text: "Demand ID",
      dataField: "demandId",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <>
            <div
              className={`${row.demandId !== "-" && "hyperlinkText"}`}
              onClick={(e) => {
                if (row.demandId !== "-") {
                  navigate("DemandDetailView/" + row?.demandId);
                }
              }}
            >
              {row.demandId ? row.demandId : "-"}
            </div>
          </>
        );
      },
    },
    // {
    //   dataField: "action",
    //   text: "Action",
    //   formatter: (cellContent, row, rowIndex) => {
    //     return (
    //       <>
    //         {newResponses ? (
    //           <div className="db-number-card-container">
    //             <img
    //               style={{ width: "20px", height: "20px" }}
    //               alt="cross"
    //               src={CrossSign}
    //             />
    //           </div>
    //         ) : (
    //           <div className="db-number-card-container">
    //             {getCurrentUser()?.role[0]?.name === "admin" ||
    //             getCurrentUser()?.role[0]?.name === "Relationship Manager" ? (
    //               <img
    //                 style={{ cursor: "pointer", width: "20px", height: "20px" }}
    //                 onClick={(e) => {
    //                   e.preventDefault();
    //                   handleAccountSwitch(row?.response?._id);
    //                 }}
    //                 alt="switch"
    //                 src={switchuser}
    //               />
    //             ) : (
    //               ""
    //             )}
    //           </div>
    //         )}
    //       </>
    //     );
    //   },
    // },
  ];

  if (getCurrentUser()?.role[0]?.name === "admin") {
    const rmData = [
      {
        text: "Relationship Manager",
        dataField: "rmName",
        formatter: (cellContent, row, rowIndex) => {
          return <div>{cellContent ? cellContent : "-"}</div>;
        },
      },
    ];
    columnsBrand.splice(7, 0, ...rmData);
  }
  if (newResponses) {
    const actionCol = [
      {
        dataField: "action",
        text: "Action",
        formatter: (cellContent, row, rowIndex) => {
          return (
            <div className="db-number-card-container">
              <img
                style={{ width: "20px", height: "20px", cursor: "pointer" }}
                alt="cross"
                src={CrossSign}
                onClick={() => {
                  handlePropertyResponse(row);
                }}
              />
            </div>
          );
        },
      },
    ];
    columnsBrand.splice(8, 0, ...actionCol);
  }

  const getTableData = async (loadStatus) => {
    if (loadStatus) {
      setLoading(true);
    }
    let url = `/dashboard/getPropertyResponses`;

    // let url = `/dashboard/getCustomers?type=Brand&page=${offSet}&limit=${perPage}`;
    // let url = `/dashboard/getPropertyResponses?pageNo=${offSet}&limit=${perPage}&type=Agent&isNew=${
    //   newResponses ? true : false
    // }`;
    // if (search.length > 2) {
    //   url += `&search=${search}`;
    // }
    // if (sort) {
    //   url += `&sort=${sort}`;
    // } else {
    //   url += `&sort=${"desc"}`;
    // }
    let payLoad = {
      pageNo: offSet,
      limit: perPage,
      type: PHYSICAL_TEAM,
      isNew: newResponses ? true : false,
    };

    if (search.length > 2) {
      payLoad.search = search;
    }
    if (sort === "asc") {
      payLoad.sort = "asc";
    }
    if (sort === "desc") {
      payLoad.sort = "desc";
    }

    // payLoad.sort = sort ? "asc" : "desc";
    // if (sort) {
    //   if (sort===) {
    //     payLoad.sort = "asc"
    //   } else {
    //     payLoad.sort = "desc";
    //   }

    // }

    // let cusomerData = await getData(url);

    postGetData(url, {}, payLoad).then((response) => {
      const { statusCode, data } = response;
      if (statusCode === 200) {
        setPageCount(Math.ceil(data.count / perPage));
        setTotalCount(data.count);
        setTableData(data.list);
        setLoading(false);
      } else {
        setPageCount(0);
        setTableData([]);
        setLoading(false);
      }
    });

    // const { statusCode, data } = cusomerData;
    // if (statusCode === 200) {
    //   setPageCount(Math.ceil(data.count / perPage));
    //   setTotalCount(data.count);
    //   setTableData(data.list);
    //   setLoading(false);
    // } else {
    //   setPageCount(0);
    //   setTableData([]);
    //   setLoading(false);
    // }
  };

  const handelChangePerPage = (count) => {
    setPerPage(count);
    setOffset(1);
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
    setOffset(1);
  };

  const tableProps = {
    data: tableData,
    columns: columnsBrand,
    offset: offSet,
    setOffset: setOffset,
    handelChangePerPage,
    pageCount,
    perPage,
    totalCount,
    tableName: "RmDashboard",
  };

  useEffect(() => {
    if (search === "" || search.length > 2) getTableData(true);
  }, [perPage, offSet, search, PropertyId, sort, listType]);

  // useEffect(() => {
  //   getTableData(false);
  // }, [trigger]);

  let customerCreatedSortValues = [
    {
      name: "Newest",
      _id: "desc",
    },
    {
      name: "Oldest",
      _id: "asc",
    },
  ];
  let PropertyIdSortValues = [
    {
      name: "PR154",
      _id: "One",
    },
    {
      name: "PR185",
      _id: "Two",
    },
    {
      name: "PR171",
      _id: "Three",
    },
  ];
  let transactionTypeSortValues = [
    {
      _id: "Lease",
      name: "Lease",
    },
    {
      _id: "Revenue Share",
      name: "Revenue Share",
    },
    {
      _id: "MG Revenue Share",
      name: "MG Revenue Share",
    },
    {
      _id: "Buy",
      name: "Buy",
    },
  ];

  if (loginPermission?.includes(menuId?.agentDashboard)) {
    return (
      <>
        {loading && <Loader className={"fixed"} />}
        <div className="db-filter-container">
          <Row className=" filter-select">
            <>
              <Col md={4} sm={5}>
                <Select
                  placeholder="Posted On"
                  options={customerCreatedSortValues}
                  name={"Posted"}
                  value={sort}
                  onChange={(e) => {
                    setSort(e.target.value);
                    setOffset(1);
                  }}
                />
              </Col>
            </>
          </Row>
          <Row className=" filter-search-inbox">
            <Col md={6} sm={7} className="d-flex align-items-start">
              <Search onChange={handleSearch} value={search} />
            </Col>
          </Row>
        </div>
        <MainTable {...tableProps} trigger={trigger} />
        <SwitchAccountPopup
          show={switchModal}
          onClose={() => setSwitchModal(false)}
          data={pocs}
          companyId={companyId}
        />
      </>
    );
  } else {
    return <AccessDenied />;
  }
};
export default ListResponsePropertyPhysicalTeam;
