import React, { useEffect, useRef, useState } from "react";
import Select from "../../../components/Form/Select";
import Input from "../../../components/Form/Input";
import Mapdragger from "../../../components/Maps/mapDrag";
import { HiUpload } from "react-icons/hi";
import { MdDeleteOutline } from "react-icons/md";
import Dropzone from "../../../components/dragAndDrop/dropZone";
// import UploadPropertyDocumentsFloorPlan from '../../AddNewProperty/UploadPropertyDocumentFloorPlan';
import { FaChevronLeft } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import {
  getData,
  handleDashboardNewResponse,
  handleRmDashboardNewReleventCrossAction,
  postDataForgot,
  postExportData,
} from "../../../api";
import GoogleAutoComplete from "../../../components/autoSearch/googleAutoSearch";
import { isFalsy } from "../../../utilities/commonFunction";
import {
  RenderMultiSelect,
  RenderMultiSelectProject,
} from "../../AddNewDemand/inputGroups";
import MultiSelect from "../../../components/select/dropDown";
import Loader from "../../../components/Loader";
import UploadDocument from "../../../components/UploadDocument/UploadDocument";
import DropzoneProject from "../../../components/dragAndDrop/DropZoneProject";
import TextArea from "../../../components/Form/TextArea";
import UploadPropertyDocuments from "../../../components/UploadDocument/UploadPdfProject";
import VideoDropZone from "../../../components/dragAndDrop/VideoDropZone";
// import VideoDropZone from '../../../components/dragAndDrop/VideoDropZone';
export const CreateProject = () => {
  const navigate = useNavigate();
  const handleNavigation = (path) => {
    navigate(path);
  };
  const params = useParams();
  const [inputValues, setInputValues] = useState({
    developerName: { value: "", error: "" },
    developerPOC: { value: "", error: "" },
    // checkbox: { value: false, error: '' },
    projectName: { value: "", error: "" },
    ProjectType: { value: "", error: "" },
    TransactionType: { value: "", error: "" },
    // location: { value: '', error: '' },
    City: { value: "", error: "" },
    State: { value: "", label: "", error: "" },
    Pincode: { value: "", error: "" },
    ProjectStatus: { value: "", error: "" },
    PosessionDate: { value: "", error: "" },
    area: { value: "", error: "" },
    AgeofProject: { value: "", error: "" },
    PropertyType: { value: [], error: "" },
    //
    UnitsAvailable: { value: "", error: "" },
    unitSizeMax: { value: "", error: "" },
    unitSizeMin: { value: "", error: "" },
    NoOFBuildings: { value: "", error: "" },
    ReraID: { value: "", error: " " },
    CarpetArea: { value: "", error: "" },
    Properties: { value: [], error: "" },
    //
    AboutProject: { value: "", error: "" },
    mapLocation: { value: "", error: "" },
    FeatureAmenities: { value: [], error: "" },
    //
    file: { value: "", error: "" },
    minPrice: { value: "", error: "" },
    maxPrice: { value: "", error: "" },
    OCStatus: { value: "", error: "" },
    Brochure: { value: "", error: "" },
    // BusinesSpace: { value: '', error: '' },
    CurrentTenants: { value: "", error: "" },
    // AbouttheDeveloper: { value: '', error: '' },
    NoofParkingsAvailable: { value: "", error: "" },
    AssigningPT: { value: [], error: "" },
    //
    AssigingRM: { value: "", error: "" },
    ChooseUserBase: { value: [], error: "" },
    //
    enduse: { value: "", error: "" },
    microLocation: { value: "", error: "" },
    // CAMCharges: { value: '', error: '' }
  });

  console.log({ inputValues });
  const [microLocationError, setMicroLocationError] = useState(false);
  const [interioruploadedImages, setinterioruploadedImages] = useState([]);
  const [exterioruploadedImages, setexterioruploadedImages] = useState([]);
  const [exterioruploadedVideos, setexterioruploadedVideos] = useState([]);
  const [googleMapError, setGoogleMapError] = useState(false);
  console.log({ exterioruploadedVideos });
  const [projectDrafID, setProjectDrafId] = useState();
  const [GetByIDData, setGetByIDData] = useState();
  const [gotoStep, setGotoStep] = useState(0);
  const [propertyDetail, setPropertyDetail] = useState({});
  const [step, setStep] = useState(0);
  const [initData, setInitData] = useState({});
  const [documentproperytyInput, setDocumentPropertyInput] = useState(null);
  console.log({ documentproperytyInput });
  const [uploadDocumentStepButtonRef, setUploadDocumentStepButtonRef] =
    useState(null);
  const [UploadFloorChanged, setUploadFloorChanged] = useState(false);
  const [type, setType] = useState("add");
  const [loader, setLoader] = useState(false);
  // Define a state for isExitData
  const [isExitData, setIsExitData] = useState(false);
  const [DeveloperID, setDeveloperID] = useState([]);
  const [POCID, setPOCID] = useState([]);
  const [projectTypeId, setProjectTypeID] = useState([]);
  const [PropertyID, setPropertyID] = useState([]);
  const [PropertyNameID, setPropertyNameID] = useState();
  const [EndUses, setEndUses] = useState([]);
  const [Pteam, setPteam] = useState([]);
  const [AMenity, setAMenity] = useState([]);
  const [properytyObject, setProperyObject] = useState();
  const [city, setCity] = useState([]);
  const [cityData, setCityData] = useState(true);
  const [uploadFiles, setUploadFiles] = useState(true);
  const [interiorImagesError, setInteriorImagesError] = useState("");
  const [exteriorImagesError, setExteriorImagesError] = useState("");
  const [State, setState] = useState([]);
  // Function to handle step change
  const handleStepChange = (newStep) => {
    setStep(newStep);
  };

  const [minDate, setMinDate] = useState("");

  useEffect(() => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const dd = String(today.getDate()).padStart(2, "0");

    setMinDate(`${yyyy}-${mm}-${dd}`);
  }, []);

  // api integration
  const PorjectAge = [
    {
      value: 3,
      label: "Less than 5 years",
    },
    {
      value: 2,
      label: "5 - 10 years",
    },
    {
      value: 1,
      label: "Above 10 years",
    },
  ];

  const GetDeveloperID = async (id) => {
    setLoader(true);
    try {
      const url = `/project/getProjectById/${id}`;
      const getList = await getData(url, {}, {});
      const { statusCode, data } = getList;
      if (statusCode === 200) {
        setUploadFiles(true);
        setGetByIDData(data?.getProject);
        // Original date string
        const originalDateStr = data?.getProject?.possesionDate;

        const convertDateFormat = (dateString) => {
          const date = new Date(dateString);
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
          const day = String(date.getDate()).padStart(2, "0");
          return `${year}-${month}-${day}`;
        };
        const ageOfProjectValue = data?.getProject?.ageOfProject;
        const ageOfProjectLabel =
          PorjectAge.find((age) => age.value === ageOfProjectValue)?.label ||
          "-";

        setInputValues({
          developerName: {
            value: data?.getProject?.developerDetails?._id,
            label: data?.getProject?.developerDetails?.name,
            error: "",
          },
          developerPOC: {
            value: {
              value: data?.getProject?.pocDetail?._id,
              label: data?.getProject?.pocDetail?.name,
            },
            error: "",
          },
          // checkbox: { value: false, error: '' },
          projectName: { value: data?.getProject?.projectName, error: "" },
          ProjectType: {
            value: data?.getProject?.projTypes?._id,
            label: data?.getProject?.projTypes?.name,
            error: "",
          },
          // TransactionType: { value: data?.getProject?.transactionType, label: data?.getProject?.transactionType, error: '' },
          TransactionType: {
            value:
              data?.getProject?.transactionType?.map((v) => {
                return {
                  value: v,
                  label: v,
                };
              }) || [],
            error: "",
          },
          // location: { value: '', error: '' },
          City: {
            value: {
              value: data?.getProject?.location,
              label: data?.getProject?.location,
            },
            error: "",
          },

          State: {
            value: data?.getProject?.stateId?._id,
            label: data?.getProject?.stateId?.name,
            error: "",
          },
          Pincode: { value: data?.getProject?.pincode, error: "" },
          ProjectStatus: {
            value: data?.getProject?.projectStatus,
            label: data?.getProject?.projectStatus,
            error: "",
          },

          PosessionDate: {
            value: convertDateFormat(originalDateStr) || "",
            error: "",
          },
          area: { value: data?.getProject?.builtupArea, error: "" },

          AgeofProject: {
            value: {
              value: ageOfProjectValue,
              label: ageOfProjectLabel,
            },
            error: "",
          },
          PropertyType: {
            value:
              data?.getProject?.propertyTypes?.map((v) => {
                return {
                  value: v._id,
                  label: v.name,
                };
              }) || [],
            error: "",
          },
          UnitsAvailable: { value: data?.getProject?.unitAvailable, error: "" },
          // UnitSizes: { value: data?.getProject?.unitSize, error: '' },
          unitSizeMax: {
            value: Number(data?.getProject?.unitSizeMax),
            error: "",
          },
          unitSizeMin: {
            value: Number(data?.getProject?.unitSizeMin),
            error: "",
          },
          NoOFBuildings: {
            value: data?.getProject?.noOfBuildingOrTower,
            error: "",
          },
          ReraID: { value: data?.getProject?.reraId, error: " " },
          CarpetArea: { value: data?.getProject?.carpetArea, error: "" },
          Properties: {
            value:
              data?.getProject?.propertiesData?.map((v) => {
                return {
                  value: v?._id,
                  label: v?.displayId,
                };
              }) || [],
            error: "",
          },
          AboutProject: { value: data?.getProject?.aboutProject, error: "" },
          CAMCharges: { value: data?.getProject?.camCharges, error: "" },
          mapLocation: { value: data?.getProject?.gMapLatLong, error: "" },
          FeatureAmenities: {
            value:
              data?.getProject?.otherAmenities?.map((v) => {
                return {
                  value: v._id ? v?._id : v?.value,
                  label: v.name ? v?.name : v?.label,
                };
              }) || [],
            error: "",
          },
          file: { value: "", error: "" },
          minPrice: { value: data?.getProject?.priceRangeMin, error: "" },
          maxPrice: { value: data?.getProject?.priceRangeMax, error: "" },
          OCStatus: {
            value: data?.getProject?.ocStatus == true ? "1" : "2",
            label: data?.getProject?.ocStatus == true ? "true" : "No",
            error: "",
          },
          Brochure: { value: "", error: "" },
          // BusinesSpace: { value: '', error: '' },
          CurrentTenants: { value: data?.getProject?.tenants, error: "" },
          // AbouttheDeveloper: { value: data?.getProject?.aboutDeveloper, error: '' },
          NoofParkingsAvailable: {
            value: data?.getProject?.parking,
            error: "",
          },
          AssigningPT: {
            value:
              data?.getProject?.physicalTeamUsers?.map((v) => {
                return {
                  value: v._id,
                  label: v.name,
                };
              }) || [],
            error: "",
          },
          AssigingRM: { value: "", error: "" },
          ChooseUserBase: {
            value:
              data?.getProject?.userType.map((v) => {
                return {
                  value: v,
                  label: v,
                };
              }) || [],
            error: "",
          },
          enduse: {
            value: data?.getProject?.endUse?.map((v) => {
              return {
                value: v._id,
                label: v.name,
              };
            }),
            error: "",
          },
          microLocation: {
            description: data?.getProject?.microLocation?.description,
            error: "",
          },
        });

        const otherDocuments = data?.getProject?.otherDocuments || {};

        // Map the fetched document data to the format used in the UploadDocument component
        let formattedDocumentData = [
          {
            label: "Floorplans available",
            type: "file",
            name: "floorPlans",
            floorPlans: [],
            uploadFiles: otherDocuments.floorPlans,
          },
          {
            label: "Brochure",
            type: "file",
            name: "Brochure",
            Brochure: [],
            uploadFiles: otherDocuments.brochure,
          },
        ];

        // Update the document input state
        setDocumentPropertyInput(formattedDocumentData);

        setinterioruploadedImages({
          name: "interior",
          data: data?.imagesAndVideos?.interior,
        });
        setexterioruploadedVideos({
          name: "coworkImagesAndVideos",
          data: data?.imagesAndVideos?.videos,
        });
        setexterioruploadedImages({
          name: "exterior",
          data: data?.imagesAndVideos?.exterior,
        });
        setProjectDrafId(data?.getProject?._id);
        localStorage.setItem("ProjectId", data?.getProject?._id);
        // setInputValues({
        //     developerName: {
        //         value: data?.getProject?.developerDetails?._id,
        //         label: data?.getProject?.developerDetails?.name,
        //         error: ""
        //     }
        // })
        setLoader(false);
      } else {
        setUploadFiles(true);
        setLoader(false);
      }
    } catch (e) {
      console.log(e, "error");
      setLoader(false);
    }
  };

  useEffect(() => {
    if (GetByIDData?.imagesAndVideos?.interior) {
      setinterioruploadedImages({
        name: "interior",
        data: GetByIDData?.imagesAndVideos?.interior,
      });
    } else {
    }
  }, [GetByIDData?.imagesAndVideos?.interior]);

  useEffect(() => {
    console.log("Document Property Input Updated:", documentproperytyInput);
  }, [documentproperytyInput]);

  // get developer
  const GetDeveloper = async () => {
    try {
      const url =
        "/project/getAllDeveloperWithoutOperator/?pageNo=1&limit=100&type=Developer";
      const getList = await getData(url, {}, {});
      const { statusCode, data } = getList;
      if (statusCode === 200) {
        const mynewData = data?.companies
          ?.filter((company) => company?.isActive)
          ?.map((value) => {
            return {
              value: value?._id,
              name: value?.companyName,
              state: value.state.map((v) => {
                return {
                  value: v?._id,
                  label: v?.name,
                };
              }),
            };
          });
        setDeveloperID(mynewData);
      }
    } catch (e) {
      console.log(e, "error");
    }
  };
  //
  const GetState = async (id) => {
    try {
      const url = `/brand/${id}`;
      const getList = await getData(url, {}, {});
      const { statusCode, data } = getList;
      if (statusCode === 200) {
        const newData = data?.state?.map((value) => {
          return {
            value: value?._id,
            label: value?.name,
          };
        });
        const PropertyID = data?.propertyTypes?.map((value) => {
          return {
            value: value?._id,
            label: value?.name,
          };
        });

        setPropertyID(PropertyID);

        setState(newData);
      }
    } catch (e) {
      console.log(e, "error");
    }
  };
  // get Poc
  const GetPoc = async (id) => {
    try {
      const url = `/brand/getAllPoc/${id}`;
      const getList = await getData(url, {}, {});
      const { statusCode, data } = getList;
      if (statusCode === 200) {
        const newData = data.map((value) => {
          return {
            value: value?._id,
            label: value?.name,
          };
        });

        setPOCID(newData);
      }
    } catch (e) {
      console.log(e, "error");
    }
  };

  // project Type

  const GetProjectTypeID = async () => {
    try {
      const url = `/project/getProjectTypes`;
      const getList = await getData(url, {}, {});
      const { statusCode, data } = getList;
      if (statusCode === 200) {
        const newData = data?.getRes?.map((value) => {
          return {
            value: value?._id,
            label: value?.name,
          };
        });

        setProjectTypeID(newData);
      }
    } catch (e) {
      console.log(e, "error");
    }
  };

  //

  // project Type

  const GetamenityData = async () => {
    try {
      const url = `/amenity`;
      const getList = await getData(url, {}, {});
      const { statusCode, data } = getList;
      if (statusCode === 200) {
        const newData = data?.map((value) => {
          return {
            value: value?._id,
            label: value?.name,
          };
        });

        setAMenity(newData);
      }
    } catch (e) {
      console.log(e, "error");
    }
  };
  // property ID

  const GetPropertyID = async () => {
    try {
      const url = `/propertyType`;
      const getList = await getData(url, {}, {});
      const { statusCode, data } = getList;
      if (statusCode === 200) {
        const newData = data?.map((value) => {
          return {
            value: value?._id,
            label: value?.name,
          };
        });

        setPropertyID(newData);
      }
    } catch (e) {
      console.log(e, "error");
    }
  };

  // getPropertiesBased

  const getPropertiesBased = async (devID, city) => {
    try {
      const url = `/project/getPropertiesBasedOnDeveloper?developerId=${devID}&location=${inputValues?.City?.value?.value}`;
      const getList = await getData(url, {}, {});
      const { statusCode, data } = getList;
      if (statusCode === 200) {
        const newData = data?.getRes?.map((value) => {
          return {
            value: value?._id,
            label: value?.productId?.display,
          };
        });

        setPropertyNameID(newData);
      }
    } catch (e) {
      console.log(e, "error");
    }
  };

  // /enduse/getEndUses
  const getEndUses = async () => {
    try {
      const url = `/enduse/getEndUses`;
      const getList = await getData(url, {}, {});
      const { statusCode, data } = getList;
      if (statusCode === 200) {
        const newData = data?.map((value) => {
          return {
            value: value?._id,
            label: value?.name,
          };
        });

        setEndUses(newData);
      }
    } catch (e) {
      console.log(e, "error");
    }
  };

  // pt

  const getPt = async () => {
    try {
      const url = `/project/getAllPhysicalTeamPoc`;
      const getList = await getData(url, {}, {});
      const { statusCode, data } = getList;
      if (statusCode === 200) {
        const newData = data?.map((value) => {
          return {
            value: value?._id,
            label: value?.name,
          };
        });

        setPteam(newData);
      }
    } catch (e) {
      console.log(e, "error");
    }
  };

  // create Project

  const CreateprojectAPi = async (body) => {
    try {
      const url = `/project/editProject/${projectDrafID}`;
      const getList = await handleDashboardNewResponse(url, body);
      const { statusCode, data } = getList;
      if (statusCode === 200) {
        handleNavigation("/project");
      }
    } catch (e) {
      console.log(e, "error");
    }
  };
  const Createcity = async (body) => {
    try {
      const url = `/country/getCitiesByState`;
      const getList = await postDataForgot(url, {}, body);
      const { statusCode, data } = getList;
      if (statusCode === 200) {
        const newData = data?.slice(1).map((value) => {
          return {
            value: value?.name,
            label: value?.name,
          };
        });
        setCityData(false);
        setCity(newData);
      }
    } catch (e) {}
  };
  // city
  const state = DeveloperID.flatMap((v) => {
    return v.state;
  });
  // clg

  useEffect(() => {
    GetDeveloper();
  }, []);

  useEffect(() => {
    GetamenityData();
    getPt();
  }, []);

  useEffect(() => {
    GetProjectTypeID();
    GetPropertyID();
    getEndUses();
  }, []);

  useEffect(() => {
    if (inputValues?.State?.value) {
      Createcity({
        id: [inputValues?.State?.value],
      });
    }
  }, [inputValues?.State?.value]);

  useEffect(() => {
    if (inputValues?.developerName?.value) {
      GetPoc(inputValues?.developerName?.value);
    }
  }, [inputValues?.developerName?.value]);

  useEffect(() => {
    if (inputValues?.developerName?.value) {
      GetState(inputValues?.developerName?.value);
    }
  }, [inputValues?.developerName?.value]);

  useEffect(() => {
    if (inputValues?.developerName?.value && inputValues?.City?.value) {
      const handler = setTimeout(() => {
        getPropertiesBased(
          inputValues?.developerName?.value,
          inputValues?.City?.value?.name
        );
      }, 2000);
      return () => {
        clearTimeout(handler);
      };
    }
  }, [inputValues?.developerName?.value, inputValues?.City?.value, 2000]);

  useEffect(() => {
    if (params?.slug) {
      GetDeveloperID(params?.slug);
    }
  }, [params?.slug]);

  const coworkGoogleMapDivRef = useRef(null);
  const handleChangeMap = (newLocation) => {
    handleInputChange("mapLocation", newLocation);
  };

  const pocList = [
    { value: "1", label: "aman" },
    { value: "2", label: "rutwik" },
    { value: "3", label: "pratik" },
  ];
  const OCStatusList = [
    { value: "1", label: "Yes" },
    { value: "2", label: "No" },
  ];

  const projectStatus = [
    {
      value: "Ready to Move",
      label: "Ready to Move",
    },
    {
      value: "Under Construction",
      label: "Under Construction",
    },
  ];

  const UserType = [
    {
      value: "Brand",
      label: "Brand",
    },
    // {
    //     value: 'Developer', label: 'Developer'
    // },
    {
      value: "Agent",
      label: "Agent",
    },
  ];

  const trysitionType = [
    { value: "Lease", label: "Lease" },
    { value: "Sale", label: "Sale" },
    { value: "Management contract", label: "Management contract" },
    { value: "Revenue share", label: "Revenue share" },
    { value: "MG Revenue share", label: "MG Revenue share" },
  ];

  const handleInputChange = (fieldName, value, label) => {
    if (fieldName === "City") {
      setInputValues((prevValues) => ({
        ...prevValues,
        microLocation: {
          value: "",
          label: "",
          error: false,
        },
        Properties: {
          value: "",
          error: false,
        },
      }));
    }

    if (fieldName === "ProjectStatus") {
      setInputValues((prevValues) => ({
        ...prevValues,
        PosessionDate: {
          value: "",
          label: "",
          error: false,
        },
        AgeofProject: {
          value: {},
          error: "",
        },
      }));
    }

    if (fieldName === "developerName") {
      setInputValues((prevValues) => ({
        ...prevValues,
        PropertyType: {
          value: [],
          error: false,
        },
        State: {
          value: "",
          label: "",
          error: false,
        },

        City: {
          value: "",
          label: "",
          error: false,
        },
        Pincode: {
          value: "",
          error: false,
        },
        developerPOC: {
          value: "",
          label: "",
          error: false,
        },
        Properties: {
          value: [],
          error: false,
        },
        microLocation: {
          value: [],
          error: false,
        },
      }));
    }

    if (fieldName === "State") {
      setInputValues((prevValues) => ({
        ...prevValues,

        State: {
          value: "",
          label: "",
          error: false,
        },

        City: {
          value: "",
          label: "",
          error: false,
        },
        Pincode: {
          value: "",
          error: false,
        },

        microLocation: {
          value: [],
          error: false,
        },
      }));
    }

    if (fieldName === "Pincode" && value.length > 6) {
      return;
    }

    setInputValues((prevValues) => ({
      ...prevValues,
      [fieldName]: {
        ...prevValues[fieldName],
        value,
        label,
        error: validateField(fieldName, value),
      },
    }));
  };

  const validateField = (fieldName, value) => {
    switch (fieldName) {
      case "developerName":
        return value ? "" : "Please select developer name";
      case "developerPOC":
        return value ? "" : "Please select developer POC";
      case "projectName":
        return value ? "" : "Please enter project name ";
      case "TransactionType":
        return value === 0
          ? "Please select at least one  transaction type "
          : "";
      case "ProjectType":
        return value ? "" : "Please select project type ";
      case "City":
        return value ? "" : "Please select city  ";
      case "ProjectStatus":
        return value ? "" : "Please select  project status";
      case "AgeofProject":
        if (inputValues?.ProjectStatus?.value === "Ready to Move") {
          return value ? "" : "Please select age of project";
        }
        return "";
      // case 'enduse':
      case "OCStatus":
        // case 'AssigningPT':
        return value ? "" : "Please select OC status ";
      case "location":
      // case 'projectName':
      case "State":
        return value ? "" : "Please select state  ";
      case "PosessionDate":
        if (inputValues?.ProjectStatus?.value == "Under Construction") {
          return value ? "" : "Please select posession date ";
        }
        return "";
      case "AboutProject":
        return value ? "" : "Please enter about project   ";
      case "microLocation":
        const textField = /.+/;
        return textField.test(value) ? "" : "Please enter location";
      case "mapLocation":
        const textFieldRegex = /.+/;
        return textFieldRegex.test(value) ? "" : "Please enter location name";
      // case 'minPrice':
      //     return value <= 0 ? 'Min price must be greater than 0' : '';
      // case 'maxPrice':
      //     return value <= 0 ? 'Max price must be greater than 0' : '';
      case "Pincode":
        if (value.trim() === "") {
          return value ? "" : "Please enter a valid 6-digit pincode";
        }
        const PinCodeRegex = /^\d{6}$/;
        return PinCodeRegex.test(value)
          ? ""
          : "Please enter a valid 6-digit pincode";
      case "FeatureAmenities":
        return value === 0 ? "Please select at least one amenity" : "";
      case "PropertyType":
        return value === 0 ? "Please select at least one PropertyType" : "";
      case "AssigningPT":
        return value === 0 ? "Please select at least one AssigningPT" : "";
      case "ChooseUserBase":
        return value === 0 ? "Please select at least one ChooseUserBase" : "";
      default:
        return "";
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let hasError = false;
    const updatedInputValues = {};
    // console.log({ inputValues1231232: inputValues })

    for (const fieldName in inputValues) {
      const value = inputValues[fieldName].value;
      const error = validateField(fieldName, value);
      updatedInputValues[fieldName] = {
        ...inputValues[fieldName],
        error,
      };

      if (error) {
        hasError = true;
      }
    }

    // Check for errors in array fields
    if (inputValues.ChooseUserBase.value.length === 0) {
      updatedInputValues.ChooseUserBase = {
        ...inputValues.ChooseUserBase,
        error: "Please select at least one choose user base",
      };
      hasError = true;
    }

    // if (inputValues.AssigningPT.value.length === 0) {
    //     updatedInputValues.AssigningPT = {
    //         ...inputValues.AssigningPT,
    //         error: 'Please select at least one assigning PT',
    //     };
    //     hasError = true;
    // }

    if (inputValues.FeatureAmenities.value.length === 0) {
      updatedInputValues.FeatureAmenities = {
        ...inputValues.FeatureAmenities,
        error: "Please select at least one feature amenities",
      };
      hasError = true;
    }

    if (inputValues.PropertyType.value.length === 0) {
      updatedInputValues.PropertyType = {
        ...inputValues.PropertyType,
        error: "Please select at least one property type",
      };
      hasError = true;
    }
    const minPrice = parseFloat(updatedInputValues.minPrice.value);
    const maxPrice = parseFloat(updatedInputValues.maxPrice.value);

    // console.log('Min Price:', minPrice);
    // console.log('Max Price:', maxPrice);

    if (minPrice >= maxPrice) {
      updatedInputValues.minPrice.error =
        "Min price must be less than Max price";
      updatedInputValues.maxPrice.error =
        "Max price must be greater than Min price";
      hasError = true;
    }

    const unitSizeMin = parseFloat(updatedInputValues.unitSizeMin.value);
    const unitSizeMax = parseFloat(updatedInputValues.unitSizeMax.value);

    // console.log('Min Price:', minPrice);
    // console.log('Max Price:', maxPrice);

    if (unitSizeMin >= unitSizeMax) {
      updatedInputValues.unitSizeMin.error =
        "Min unit  must be less than Max unit";
      updatedInputValues.unitSizeMax.error =
        "Max unit must be greater than Min unit";
      hasError = true;
    }

    const area = parseFloat(updatedInputValues.area.value);
    const carpetArea = parseFloat(updatedInputValues.CarpetArea.value);

    // console.log('Area:', area);
    // console.log('Carpet Area:', carpetArea);

    if (isNaN(area) || isNaN(carpetArea)) {
      updatedInputValues.area.error = "Built-up must be a valid number";
      // updatedInputValues.CarpetArea.error = 'Carpet area must be a valid number';
      hasError = true;
    } else if (area <= carpetArea) {
      updatedInputValues.area.error =
        "Built-up must be greater than Carpet area";
      // updatedInputValues.CarpetArea.error = 'Carpet area must be less than Area';
      hasError = true;
    }

    if (interioruploadedImages.data.length < 3) {
      setInteriorImagesError("Please upload at least 3 interior images");
      hasError = true;
    } else {
      setInteriorImagesError("");
    }

    if (exterioruploadedImages.data.length < 3) {
      setExteriorImagesError("Please upload at least 3 exterior images");
      hasError = true;
    } else {
      setExteriorImagesError("");
    }

    console.log({ updatedInputValues });
    setInputValues(updatedInputValues);

    if (!hasError) {
      console.log({ hasError });
      // Form submission logic
      CreateprojectAPi({
        // "projectId": projectDrafID,
        ...(inputValues?.CAMCharges?.value && {
          camCharges: inputValues?.CAMCharges?.value,
        }),
        developerId: inputValues?.developerName?.value,
        developerPocId: inputValues?.developerPOC?.value?.value,
        projectName: inputValues?.projectName?.value,
        projectTypeId: inputValues?.ProjectType?.value,
        ...(inputValues?.TransactionType?.value && {
          transactionType: inputValues?.TransactionType?.value?.map((v) => {
            return v.value;
          }),
        }),
        location: inputValues?.City?.value?.value,
        microLocation: inputValues?.microLocation,
        stateId: {
          _id: inputValues?.State?.value,
          name: inputValues?.State?.label,
        },
        pincode: inputValues?.Pincode?.value,
        projectStatus: inputValues?.ProjectStatus?.value,
        ...(inputValues?.ProjectStatus?.value == "Ready to Move" && {
          ageOfProject: inputValues?.AgeofProject?.value?.value,
        }),
        ...(inputValues?.PropertyType?.value && {
          propertyTypes: inputValues?.PropertyType?.value?.map((v) => {
            return {
              name: v.label,
              _id: v.value,
            };
          }),
        }),
        ...(inputValues?.area?.value && {
          builtupArea: inputValues?.area?.value,
        }),
        ...(inputValues?.UnitsAvailable?.value && {
          unitAvailable: inputValues?.UnitsAvailable?.value,
        }),
        ...(inputValues?.unitSizeMax?.value && {
          unitSizeMax: inputValues?.unitSizeMax?.value,
        }),
        ...(inputValues?.unitSizeMin?.value && {
          unitSizeMin: inputValues?.unitSizeMin?.value,
        }),
        ...(inputValues?.CarpetArea?.value && {
          carpetArea: inputValues?.CarpetArea?.value,
        }),
        ...(inputValues?.NoOFBuildings?.value && {
          noOfBuildingOrTower: inputValues?.NoOFBuildings?.value,
        }),
        ...(inputValues?.ReraID?.value && {
          reraId: inputValues?.ReraID?.value,
        }),
        ...(inputValues?.Properties?.value && {
          properties: inputValues?.Properties?.value?.map((v) => {
            return v.value;
          }),
        }),
        ...(inputValues?.AboutProject?.value && {
          aboutProject: inputValues?.AboutProject?.value,
        }),
        gMapLatLong: inputValues?.mapLocation?.value,
        otherAmenities: inputValues?.FeatureAmenities?.value?.map((v) => {
          return v?.value;
        }),
        ocStatus: inputValues?.OCStatus?.value == "1" ? true : false,
        otherDocuments: {
          brochure:
            (documentproperytyInput[1]?.name == "brochure" &&
              documentproperytyInput[1]?.uploadFiles) ||
            [],
          floorPlans:
            (documentproperytyInput[0]?.name == "floorPlans" &&
              documentproperytyInput[0]?.uploadFiles) ||
            [],
        },
        ...(inputValues?.enduse?.value && {
          endUse: inputValues?.enduse?.value?.map((v) => {
            return v.value;
          }),
        }),
        imagesAndVideos: {
          exterior: exterioruploadedImages?.data,
          interior: interioruploadedImages?.data,
          videos: exterioruploadedVideos?.data,
        },
        ...(inputValues?.CurrentTenants?.value && {
          tenants: inputValues?.CurrentTenants?.value,
        }),
        ...(inputValues?.NoofParkingsAvailable?.value && {
          parking: inputValues?.NoofParkingsAvailable?.value,
        }),
        ...(inputValues?.AssigningPT?.value && {
          ptIds: inputValues?.AssigningPT?.value?.map((v) => {
            return v.value;
          }),
        }),
        ...(inputValues?.ChooseUserBase?.value && {
          userType: inputValues?.ChooseUserBase?.value?.map((v) => {
            return v.value;
          }),
        }),
        ...(inputValues?.ProjectStatus?.value == "Under Construction" && {
          possesionDate: inputValues?.PosessionDate?.value,
        }),
        ...(inputValues.minPrice?.value && {
          priceRangeMin: inputValues.minPrice?.value,
        }),
        ...(inputValues.maxPrice?.value && {
          priceRangeMax: inputValues.maxPrice?.value,
        }),
      });
      console.log("Form submitted successfully!");
    } else {
      // setInputValues(updatedInputValues);
      console.log("notdonee");
    }
  };

  // Function to handle uploaded images
  const handleImagesAllinterior = (images) => {
    setinterioruploadedImages(images);
  };

  const handleImagesAllexterior = (images) => {
    setexterioruploadedImages(images);
  };
  const handleImagesAllVideos = (images) => {
    setexterioruploadedVideos(images);
  };

  const handleSelectLocation = (suggestion, action) => {
    // setMicroLocationError(false);
    let Locationdata = {
      ...suggestion,
      _id: suggestion?.placeId ? suggestion?.placeId : suggestion?._id,
      name: suggestion?.description,
    };
    setInputValues((prev) => ({
      ...prev,
      microLocation: Locationdata,
      // locationmap: JSON.stringify(Locationdata),
    }));
  };

  const flattenNestedValues = (data) => {
    const flattenedValues = [];
    const traverse = (item) => {
      if (Array.isArray(item.value)) {
        item.value.forEach((nestedItem) => traverse(nestedItem));
      } else {
        flattenedValues.push({ value: item.value, label: item.label });
      }
    };
    data.forEach((item) => traverse(item));
    return flattenedValues;
  };

  const handleMultiselect = (fieldName, data) => {
    const flattenedData = flattenNestedValues(data);
    setInputValues((prev) => ({
      ...prev,
      [fieldName]: {
        ...prev[fieldName],
        value: flattenedData,
        error: validateField(fieldName, data),
      },
    }));
  };

  // MultiSelect handlers
  const handleMultiselectPropertyType = (data) =>
    handleMultiselect("PropertyType", data);
  const handleMultiselectProperties = (data) =>
    handleMultiselect("Properties", data);
  const handleMultiselectFeatureAmenities = (data) =>
    handleMultiselect("FeatureAmenities", data);
  const handleMultiselectAssigningPT = (data) =>
    handleMultiselect("AssigningPT", data);
  const handleMultiselectChooseUserBase = (data) =>
    handleMultiselect("ChooseUserBase", data);
  const handleMultiselectEndUse = (data) => handleMultiselect("enduse", data);
  const handleMultiselectTransactionType = (data) =>
    handleMultiselect("TransactionType", data);

  const [ErrorMsgfordeveloperName, setErrorMsgfordeveloperName] =
    useState(false);

  useEffect(() => {
    if (inputValues.developerName.value) {
      setErrorMsgfordeveloperName(false);
    }
  }, [inputValues.developerName.value]);

  const handleCheckDeveloperCheckBox = () => {
    setErrorMsgfordeveloperName(false);
  };

  return (
    <div>
      {!loader ? (
        <form
          onSubmit={handleSubmit}
          className="pt-20 mt-48 border bg-slate-500"
        >
          <div className="rounded-2 p-3" style={{ background: "white" }}>
            <div className="d-flex gap-2 align-items-center self-items-center">
              <FaChevronLeft
                onClick={() => {
                  handleNavigation("/project");
                }}
                style={{ width: "1.5rem", height: "1.5rem", cursor: "pointer" }}
              />
              <h5 className="pt-2">Edit Project</h5>
            </div>
            {/* primary Details */}
            <div
              className="p-2 rounded-2 my-3"
              style={{
                background: "#004A6E",
                color: "white",
                fontWeight: "bold",
              }}
            >
              {" "}
              Primary Details
            </div>
            <div className="row row-cols-2 pb-3 ">
              {/*  */}
              <div className="pb-3">
                <Select
                  label={"Developer Name*"}
                  value={inputValues.developerName.value}
                  options={DeveloperID}
                  onChange={(e) =>
                    handleInputChange(
                      "developerName",
                      e.target.value,
                      e.target?.label
                    )
                  }
                  errorMessage={inputValues.developerName.error}
                  isInvalid={!!inputValues.developerName.error}
                  onClick={handleCheckDeveloperCheckBox}
                />

                {ErrorMsgfordeveloperName && (
                  <p
                    style={{
                      fontSize: "12px",
                      color: "red",
                      marginTop: "-15px",
                    }}
                  >
                    Please select a Developer Name first{" "}
                  </p>
                )}
              </div>

              {/*  */}
              <div className="pb-3">
                <RenderMultiSelectProject
                  checboxDisabled={true}
                  label="Developer POC*"
                  name="State"
                  value={inputValues.developerPOC.value}
                  onChange={(value, label) =>
                    handleInputChange("developerPOC", value)
                  }
                  options={POCID}
                  errorMessage={inputValues.developerPOC.error}
                  isInvalid={!!inputValues.developerPOC.error}
                  disabled={inputValues.developerName?.value ? false : true}
                  placeholder={"Select POC"}
                  // disabled={
                  //     !cityData ? false : true
                  // }
                  isMulti={false}
                />
              </div>

              <div className="">
                <Input
                  label={"Project  name*"}
                  type={"text"}
                  placeholder={"Enter Project name"}
                  name="projectName"
                  value={inputValues?.projectName?.value}
                  onChange={(e) =>
                    handleInputChange(
                      "projectName",
                      e.target.value,
                      e.target?.label
                    )
                  }
                  disabled={false}
                  errorTrue={true}
                  errorMessage={inputValues.projectName.error}
                />
              </div>

              <div className="pb-3">
                <Select
                  label="Project Type*"
                  options={projectTypeId}
                  value={inputValues.ProjectType.value}
                  onChange={(e) =>
                    handleInputChange(
                      "ProjectType",
                      e.target.value,
                      e.target?.label
                    )
                  }
                  errorMessage={inputValues.ProjectType.error}
                  isInvalid={!!inputValues.ProjectType.error}
                />
              </div>
              {/*  */}
              <div className="pb-3" style={{ zIndex: "5000" }}>
                {/* <Select
                                label="Transaction Type*"
                                value={inputValues.TransactionType?.value}
                                options={trysitionType}
                                onChange={(e) => handleInputChange('TransactionType', e.target.value, e.target?.label)}
                                errorMessage={inputValues.TransactionType.error}
                                isInvalid={!!inputValues.TransactionType.error}
                            /> */}
                <MultiSelect
                  // disabled={disabled}

                  errormessage={inputValues.TransactionType.error}
                  onChange={handleMultiselectTransactionType}
                  isMulti={true}
                  options={trysitionType}
                  value={inputValues.TransactionType?.value}
                  lable={"Transaction Type*"}
                  // size="w-100"
                />
              </div>

              <div className="">
                <Select
                  label="State*"
                  options={State}
                  value={inputValues.State.value}
                  onChange={(e) =>
                    handleInputChange("State", e.target.value, e.target?.label)
                  }
                  errorMessage={inputValues.State.error}
                  isInvalid={!!inputValues.State.error}
                />
              </div>

              <div className="pb-2" style={{ zIndex: "1000" }}>
                {/* <Select
                                label="City*"
                                options={city}
                                value={inputValues?.City?.value}
                                onChange={(e) => handleInputChange('City', e.target.value, e.target?.label)}
                                errorMessage={inputValues.City.error}
                                isInvalid={!!inputValues.City.error}
                                disabled={!cityData ? false : true}
                            /> */}

                <RenderMultiSelectProject
                  checboxDisabled={true}
                  label="City*"
                  name="City"
                  value={inputValues?.City?.value}
                  onChange={(value, label) => handleInputChange("City", value)}
                  options={city}
                  errorMessage={inputValues.City.error}
                  isInvalid={!!inputValues.City.error}
                  disabled={!cityData ? false : true}
                  placeholder={"Select City"}
                  // disabled={
                  //     !cityData ? false : true
                  // }
                  isMulti={false}
                />
              </div>

              <div className="">
                <Input
                  label={"Pincode*"}
                  type={"text"}
                  placeholder={"Enter Pincode"}
                  name="Pincode"
                  value={inputValues?.Pincode?.value}
                  onChange={(e) =>
                    handleInputChange(
                      "Pincode",
                      e.target.value,
                      e.target?.label
                    )
                  }
                  disabled={false}
                  errorTrue={true}
                  errorMessage={inputValues.Pincode.error}
                />
                <p
                  style={{ fontSize: "12px", color: "red", marginTop: "-5px" }}
                >
                  {inputValues.Pincode.error}{" "}
                </p>
              </div>
              <div className="">
                <GoogleAutoComplete
                  cityValue={""}
                  label="Location * "
                  value={inputValues["microLocation"]}
                  handleSelectLocation={handleSelectLocation}
                  size={"m-0"}
                  disabled={!inputValues.City.value ? true : false}
                />
                <p
                  style={{ fontSize: "12px", color: "red", marginTop: "-5px" }}
                >
                  {inputValues.microLocation.error}{" "}
                </p>
                {/* <p style={{ fontSize: '16px', color: '#406065' }}>Please select a city first </p> */}
              </div>
              {/*  */}

              <div className="pb-2">
                <Select
                  label="Project Status*"
                  value={inputValues?.ProjectStatus?.value}
                  options={projectStatus}
                  onChange={(e) =>
                    handleInputChange(
                      "ProjectStatus",
                      e.target.value,
                      e.target?.label
                    )
                  }
                  errorMessage={inputValues.ProjectStatus.error}
                  isInvalid={!!inputValues.ProjectStatus.error}
                />
              </div>

              {/*  */}

              <div className="">
                <Input
                  label={"Posession Date* "}
                  type={"date"}
                  placeholder={"Enter Posession Date"}
                  name="PosessionDate"
                  min={minDate}
                  value={inputValues?.PosessionDate?.value}
                  onChange={(e) =>
                    handleInputChange(
                      "PosessionDate",
                      e.target.value,
                      e.target?.label
                    )
                  }
                  // disabled={inputValues.ProjectStatus.value == 2 ? false : true}
                  errorTrue={true}
                  disabled={
                    inputValues?.ProjectStatus?.value == "Ready to Move"
                      ? true
                      : false
                  }
                  errorMessage={inputValues.PosessionDate.error}
                />
                <p
                  style={{ fontSize: "12px", color: "red", marginTop: "-15px" }}
                >
                  {inputValues.PosessionDate.error}{" "}
                </p>
              </div>

              {/*  */}
              <div className="pb-2">
                {/* <Select
                                label="Age of Project*"
                                options={PorjectAge}
                                value={inputValues?.AgeofProject?.value}
                                onChange={(e) => handleInputChange('AgeofProject', e.target.value, e.target?.label)}
                                errorMessage={inputValues.AgeofProject.error}
                                isInvalid={!!inputValues.AgeofProject.error}
                                disabled={inputValues?.ProjectStatus?.value == "Ready to Move" ? false : true}
                            // disabled={inputValues.ProjectStatus.value == 1 ? false : true}
                            /> */}

                <RenderMultiSelectProject
                  checboxDisabled={true}
                  label="Age of Project*"
                  name="AgeofProject"
                  value={inputValues.AgeofProject.value}
                  onChange={(value, label) =>
                    handleInputChange("AgeofProject", value)
                  }
                  options={PorjectAge}
                  errorMessage={inputValues.AgeofProject.error}
                  isInvalid={!!inputValues.developerPOC.error}
                  disabled={
                    inputValues?.ProjectStatus?.value == "Ready to Move"
                      ? false
                      : true
                  }
                  placeholder={"Age of Project*"}
                  // disabled={
                  //     !cityData ? false : true
                  // }
                  isMulti={false}
                />
              </div>
              <div className="pb-2">
                <MultiSelect
                  // disabled={disabled}
                  errormessage={inputValues.PropertyType.error}
                  onChange={handleMultiselectPropertyType}
                  isMulti={true}
                  options={PropertyID}
                  value={inputValues.PropertyType.value}
                  lable={"Property Type*"}
                  size="w-100"
                />
              </div>
              {/*  */}
              <div className="">
                <Input
                  label={"Total Built-up Area in sq.ft* "}
                  type={"number"}
                  placeholder={"Enter Total Built-up Area in sq.ft "}
                  name="area"
                  sufix={"sq.ft"}
                  value={inputValues?.area?.value}
                  onChange={(e) =>
                    handleInputChange("area", e.target.value, e.target?.label)
                  }
                  disabled={false}
                  errorTrue={true}
                  // errorMessage={inputValues.area.error}
                />
                <p
                  style={{ fontSize: "12px", color: "red", marginTop: "-15px" }}
                >
                  {inputValues.area.error}{" "}
                </p>
              </div>

              <div className="">
                <Input
                  label={"Total Carpet Area in sq.ft* "}
                  type={"number"}
                  placeholder={"Enter Total Carpet Area in sq.ft     "}
                  name="CarpetArea"
                  sufix={"sq.ft"}
                  value={inputValues?.CarpetArea?.value}
                  onChange={(e) =>
                    handleInputChange(
                      "CarpetArea",
                      e.target.value,
                      e.target?.label
                    )
                  }
                  disabled={false}
                  errorTrue={true}
                  // errorMessage={inputValues.CarpetArea.error}
                />
                <p
                  style={{ fontSize: "12px", color: "red", marginTop: "-15px" }}
                >
                  {inputValues.CarpetArea.error}{" "}
                </p>
              </div>
              {/*  */}
              <div className="">
                <Input
                  label={"Units Available"}
                  type={"number"}
                  placeholder={"Enter Units Available"}
                  name="UnitsAvailable"
                  value={inputValues?.UnitsAvailable?.value}
                  onChange={(e) =>
                    handleInputChange(
                      "UnitsAvailable",
                      e.target.value,
                      e.target?.label
                    )
                  }
                  disabled={false}
                  sufix={"Units"}
                />
              </div>

              <div className="">
                <Input
                  label={"Unit Size Min "}
                  type={"number"}
                  placeholder={"Enter Unit Sizes Min"}
                  name="unitSizeMin"
                  value={inputValues?.unitSizeMin?.value}
                  onChange={(e) =>
                    handleInputChange("unitSizeMin", e.target.value)
                  }
                  disabled={false}
                  sufix={"sq.ft"}
                />
                <p
                  style={{ fontSize: "12px", color: "red", marginTop: "-15px" }}
                >
                  {inputValues.unitSizeMin.error}{" "}
                </p>
              </div>
              <div className="">
                <Input
                  label={"Unit Size Max "}
                  type={"number"}
                  placeholder={"Enter Unit Sizes Max "}
                  name="unitSizeMax"
                  value={inputValues?.unitSizeMax?.value}
                  onChange={(e) =>
                    handleInputChange("unitSizeMax", e.target.value)
                  }
                  disabled={false}
                  sufix={"sq.ft"}
                />
                <p
                  style={{ fontSize: "12px", color: "red", marginTop: "-15px" }}
                >
                  {inputValues.unitSizeMax.error}{" "}
                </p>
              </div>

              {/*  */}

              <div className="">
                <Input
                  label={"Number of Buildings/Towers "}
                  type={"number"}
                  placeholder={"Enter Number of Buildings/Towers"}
                  name="NoOFBuildings"
                  value={inputValues?.NoOFBuildings?.value}
                  onChange={(e) =>
                    handleInputChange(
                      "NoOFBuildings",
                      e.target.value,
                      e.target?.label
                    )
                  }
                  disabled={false}
                />
              </div>

              <div className="">
                <Input
                  label={"Rera Id "}
                  type={"text"}
                  placeholder={"Enter Rera Id"}
                  name="ReraID"
                  value={inputValues?.ReraID?.value}
                  onChange={(e) =>
                    handleInputChange("ReraID", e.target.value, e.target?.label)
                  }
                  disabled={false}
                />
              </div>

              <div className="">
                <Input
                  label={"CAM Charges"}
                  type={"number"}
                  sufix={"₹"}
                  placeholder={"Enter CAM Charges"}
                  name="CAMCharges"
                  value={inputValues?.CAMCharges?.value}
                  onChange={(e) =>
                    handleInputChange(
                      "CAMCharges",
                      e.target.value,
                      e.target?.label
                    )
                  }
                  disabled={false}
                  errorTrue={true}
                />
              </div>
            </div>
            {/*  */}

            <div
              className="p-2  rounded-2 mb-3"
              style={{
                background: "#004A6E",
                color: "white",
                fontWeight: "bold",
              }}
            >
              Tag Properties
            </div>
            <div className=" py-2 ">
              <MultiSelect
                // disabled={disabled}
                errormessage={inputValues.Properties.error}
                onChange={handleMultiselectProperties}
                isMulti={true}
                options={PropertyNameID}
                value={inputValues.Properties.value}
                lable={"Properties "}
                size="w-100"
              />
            </div>
            {/* maps & all other sections */}
            <div
              className="p-2 rounded-2  mb-2"
              style={{
                background: "#004A6E",
                color: "white",
                fontWeight: "bold",
              }}
            >
              Project Description
            </div>

            <div className="row row-cols-2 pb-3">
              <div>
                <TextArea
                  errorMessage={inputValues.AboutProject.error}
                  placeholder={"Enter About Project"}
                  label={"About Project* "}
                  type={"text"}
                  name="AboutProject"
                  value={inputValues?.AboutProject?.value}
                  onChange={(e) =>
                    handleInputChange(
                      "AboutProject",
                      e.target.value,
                      e.target?.label
                    )
                  }
                />
                <p
                  style={{ fontSize: "12px", color: "red", marginTop: "-15px" }}
                >
                  {inputValues.AboutProject.error}{" "}
                </p>
              </div>

              <div className="pb-5" ref={coworkGoogleMapDivRef}>
                <label className="fw-700 f-14 text-black form-label">
                  Google Location*
                </label>
                <Mapdragger
                  mapValue={inputValues.mapLocation.value}
                  handleChangeMap={handleChangeMap}
                />
                {inputValues.mapLocation.error && (
                  <div style={{ color: "red", fontSize: "12px" }}>
                    {inputValues.mapLocation.error}
                  </div>
                )}
              </div>
              {/*  */}
              <div className="">
                <MultiSelect
                  // disabled={disabled}
                  errormessage={inputValues.FeatureAmenities.error}
                  onChange={handleMultiselectFeatureAmenities}
                  isMulti={true}
                  options={AMenity}
                  value={inputValues.FeatureAmenities.value}
                  lable={"Feature & Amenities*"}
                  size="w-100"
                />
              </div>

              {/*  */}
              <div>
                <Input
                  label={"Min Price* "}
                  type={"number"}
                  placeholder={"Min Range - Rate/Sq.Ft (Carpet Area)"}
                  name="minPrice"
                  prefix={"Rs"}
                  value={inputValues?.minPrice?.value}
                  onChange={(e) =>
                    handleInputChange(
                      "minPrice",
                      e.target.value,
                      e.target?.label
                    )
                  }
                  disabled={false}
                  errorTrue={true}
                />
                <p
                  style={{ fontSize: "12px", color: "red", marginTop: "-15px" }}
                >
                  {inputValues.minPrice.error}{" "}
                </p>
              </div>
              <div>
                <Input
                  label={"Max Price* "}
                  type={"number"}
                  prefix={"Rs"}
                  placeholder={"Max Range - Rate/Sq.Ft (Carpet Area)"}
                  name="maxPrice"
                  value={inputValues?.maxPrice?.value}
                  onChange={(e) =>
                    handleInputChange(
                      "maxPrice",
                      e.target.value,
                      e.target?.label
                    )
                  }
                  disabled={false}
                  errorTrue={true}
                />
                <p
                  style={{ fontSize: "12px", color: "red", marginTop: "-15px" }}
                >
                  {inputValues.maxPrice.error}{" "}
                </p>
              </div>
              {/*  */}
              <div>
                <Select
                  label="OC Status*"
                  options={OCStatusList}
                  value={inputValues?.OCStatus?.value}
                  onChange={(e) =>
                    handleInputChange(
                      "OCStatus",
                      e.target.value,
                      e.target?.label
                    )
                  }
                  errorMessage={inputValues.OCStatus.error}
                  isInvalid={!!inputValues.OCStatus.error}
                />
              </div>
              <div className="">
                <UploadPropertyDocuments
                  gotoStep={handleStepChange}
                  projectID={projectDrafID}
                  propertyDetail={GetByIDData}
                  step={step}
                  initData={initData}
                  properytyInput={documentproperytyInput}
                  setPropertyInput={setDocumentPropertyInput}
                  uploadDocumentStepButtonRef={uploadDocumentStepButtonRef}
                  setChanged={setUploadFloorChanged}
                  type={type}
                  isExitData={isExitData}
                />
                {inputValues.file.error && (
                  <div style={{ color: "red", fontSize: "12px" }}>
                    {inputValues.file.error}
                  </div>
                )}
              </div>
              <div>
                <MultiSelect
                  // errormessage={inputValues.ChooseUserBase.error}
                  onChange={handleMultiselectEndUse}
                  isMulti={true}
                  options={EndUses}
                  value={inputValues?.enduse?.value}
                  lable={"Business Space For"}
                  // size="w-100"
                />
              </div>
            </div>

            {/* new section Images & videos */}

            <div
              className="p-2 rounded-2  mb-2"
              style={{
                background: "#004A6E",
                color: "white",
                fontWeight: "bold",
              }}
            >
              Images & Videos
            </div>
            <div className="py-3">
              <label className="fw-700 f-14 text-black form-label">
                Interior*
              </label>
              <div>
                <DropzoneProject
                  handleImagesAll={handleImagesAllinterior}
                  name={"interior"}
                  propertyId={projectDrafID}
                  imagesList={GetByIDData}
                />
                <p style={{ fontSize: "12px", color: "red" }}>
                  {interiorImagesError}{" "}
                </p>
              </div>
            </div>
            {/*vidoes  */}
            <div className="py-3">
              <label className="fw-700 f-14 text-black form-label">
                Exterior*
              </label>
              <div>
                <DropzoneProject
                  handleImagesAll={handleImagesAllexterior}
                  name={"exterior"}
                  propertyId={projectDrafID}
                  imagesList={GetByIDData}
                />
                <p style={{ fontSize: "12px", color: "red" }}>
                  {exteriorImagesError}{" "}
                </p>
              </div>
            </div>
            {/*videos  */}
            <div className="py-3">
              <label className="fw-700 f-14 text-black form-label">
                Videos
              </label>
              <div>
                {/* < DropzoneProject
                                handleImagesAll={handleImagesAllVideos}
                                name={"videos"}
                                propertyId={projectDrafID}
                                imagesList={GetByIDData}
                            /> */}

                <VideoDropZone
                  handleImagesAll={handleImagesAllVideos}
                  name={"videos"}
                  projectID={projectDrafID}
                  imagesList={GetByIDData}
                />
              </div>
            </div>
            {/* Additional Settings */}
            <div
              className="p-2 rounded-2  mb-2"
              style={{
                background: "#004A6E",
                color: "white",
                fontWeight: "bold",
              }}
            >
              Additional Settings
            </div>
            <div className="row row-cols-2 py-3">
              <Input
                label={"Current Tenants "}
                type={"text"}
                placeholder={"Enter Current Tenants"}
                name="CurrentTenants"
                value={inputValues?.CurrentTenants?.value}
                onChange={(e) =>
                  handleInputChange(
                    "CurrentTenants",
                    e.target.value,
                    e.target?.label
                  )
                }
                disabled={false}
                errorTrue={true}
              />
              {/* <Input
                            label={"About the Developer "}
                            type={"text"}
                            placeholder={"Enter About the Developer"}
                            name="AbouttheDeveloper"
                            value={inputValues?.AbouttheDeveloper?.value}
                            onChange={(e) => handleInputChange('AbouttheDeveloper', e.target.value, e.target?.label)}
                            disabled={false}
                            errorTrue={true}

                        /> */}
              <Input
                label={"No of Parkings Available"}
                type={"number"}
                placeholder={"Enter No of Parkings Available"}
                name="NoofParkingsAvailable"
                value={inputValues?.NoofParkingsAvailable?.value}
                onChange={(e) =>
                  handleInputChange(
                    "NoofParkingsAvailable",
                    e.target.value,
                    e.target?.label
                  )
                }
                disabled={false}
                errorTrue={true}
              />
            </div>
            {/*  User Tagging */}
            <div
              className="p-2 rounded-2  mb-2"
              style={{
                background: "#004A6E",
                color: "white",
                fontWeight: "bold",
              }}
            >
              {" "}
              User Tagging
            </div>
            <div className="row row-cols-2 py-3">
              <MultiSelect
                // disabled={disabled}
                errormessage={inputValues.AssigningPT.error}
                onChange={handleMultiselectAssigningPT}
                isMulti={true}
                options={Pteam}
                value={inputValues.AssigningPT.value}
                lable={"Assign Physical Team POC"}
                size="w-100"
              />

              <div className="py-2">
                <MultiSelect
                  errormessage={inputValues.ChooseUserBase.error}
                  onChange={handleMultiselectChooseUserBase}
                  isMulti={true}
                  options={UserType}
                  value={inputValues.ChooseUserBase.value}
                  lable={"UserType*"}
                  size="w-100"
                />
              </div>
            </div>

            <div className="row ">
              <div className="d-flex justify-content-end gap-5">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </form>
      ) : (
        <div className="d-flex justify-items-center justify-content-center ">
          <Loader />
        </div>
      )}
    </div>
  );
};

export default CreateProject;
