import { useState, useEffect } from "react";
import FormModal from "../../../components/Modal";
import Select from "../../../components/Form/Select";
import SingleImageDropzone from "../../../components/dragAndDrop/singleFileDrop";
import Button from "../../../components/Form/Button";
import { postUploadData, getData, putData, postData, handleDashboardNewResponse } from "../../../api/index";
import Loader from "../../../components/Loader";
import Input from "../../../components/Form/Input";

export function AddStatistics({ onClose, show, label, editValue, activeContentID }) {
  const [errorMessage, setErrorMessage] = useState({});
  const [formValues, setFormValues] = useState({
    title: "",
    image: "",
    value: "",
    fileName: "",
  });
  const [imageData, setImageData] = useState({});
  const [loading, setLoading] = useState(false);
  const [formSubmitloading, setformSubmitLoading] = useState(false);
  const [initLoading, setInitLoading] = useState(false);

  const handelOnSelect = (value, name) => {
    delete errorMessage[name];
    let data = { ...formValues };
    data[name] = value;
    setFormValues(data);
  };

  const checkErrorMessage = (data) => {
    let error = {};
    let requriedMessage = "This Field Is Required";

    if (data.title === "") {
      error.title = requriedMessage;
    }
    if (data.value === "") {
      error.value = requriedMessage;
    }

    return error;
  };

 

  const handleSubmitForm = async () => {
    setformSubmitLoading(true);
    let url = `/contentMangementRevamp/createStatistics/${activeContentID}`;

    let payLoad = {
      ...formValues
    };
    const {  status } = await putData(url, {}, payLoad);
    if (status) {
      setformSubmitLoading(false);
      onClose("updated");
    } else {
      setformSubmitLoading(false);
    }
  };

  const handleEditForSubmission = async() => {
    setformSubmitLoading(true);
    let url = `/contentMangementRevamp/editStatistics/${activeContentID}/${editValue?._id}`;

    let payLoad = {
      ...formValues
    };
    const {  status } = await handleDashboardNewResponse(url, payLoad);
    if (status) {
      setformSubmitLoading(false);
      onClose("updated");
    } else {
      setformSubmitLoading(false);
    }
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    const checkError = checkErrorMessage(formValues);
    setErrorMessage(checkError);

    let NoError = Object.values(checkError).every((v) => v.length === 0);
    if (NoError) {
      label === "Edit" ? handleEditForSubmission() : handleSubmitForm();
      
    }
  };

  const handleImage = async (file) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("image", file[0]);
    let url = `/contentManagement/file-upload/${JSON.parse(
      localStorage.getItem("contentManagementID")
    )}?module=banner`;
    const imageDataUpload = await postUploadData(url, {}, formData);
    const { data, status } = imageDataUpload;
    if (status) {
      setLoading(false);
      setImageData(data);
      setFormValues((pre) => ({ ...pre, image: data?.url, fileName: data?.fileName }));
      setErrorMessage((pre) => ({ ...pre, image: "" }));
    } else {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (label === "Edit" || label === "View") {
      setFormValues((pre) => {
        return {
          ...pre,
          ...{
            title: editValue.title,
            image: editValue.image,
            signedImage: editValue?.signedImage,
            fileName: editValue?.fileName,
            value: editValue?.value,
            _id: editValue?._id,
          },
        };
      });

      setImageData({
        viewUrl: editValue.signedImage,
      });
    }
  }, []);

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && event.target.tagName !== "TEXTAREA") {
      event.preventDefault();
      handleSubmit(event);
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [formValues]);

  return (
      <FormModal
        onClose={onClose}
        show={show}
        heading={`${label}  Stats`}
        size={"md"}
        loading={initLoading}
        loader="fixed"
      >
        <div className="mt-1">
          <div className="select-head">
            <form
              noValidate
              // validated={validated}
              onSubmit={handleSubmit}
            >
              <Input
                errorMessage={
                  errorMessage?.title && errorMessage?.title
                }
                label={"Title*"}
                name={"title"}
                isInvalid={errorMessage?.title && true}
                value={formValues?.title && formValues?.title}
                onChange={(e) => {
                  handelOnSelect(e.target.value, "title");
                }}
                disabled={label === "View"}
              />
              <Input
                errorMessage={
                  errorMessage?.value && errorMessage?.value
                }
                label={"Value*"}
                name={"value"}
                isInvalid={errorMessage?.value && true}
                value={formValues?.value && formValues?.value}
                onChange={(e) => {
                  handelOnSelect(e.target.value, "value");
                }}
                disabled={label === "View"}
              />
              <div className="w-100">
                <label
                  style={{
                    fontStyle: "normal",
                    fontWeight: "700",
                    fontSize: "14px",
                    color: "#000000",
                    marginBottom: "12px",
                  }}
                >
                  Upload Icon
                </label>
              </div>

              <SingleImageDropzone
                loading={loading}
                onHandelImage={handleImage}
                data={imageData}
              />
              <div className="error-msg">{errorMessage.image}</div>

              <div className="d-flex justify-content-end  gap-3 mt-4 ">
                <Button type={"button"} className={"pre-btn"} onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  type={"submit"}
                  className="next-btn"
                  loading={formSubmitloading}
                  disabled={label === "View"}
                >
                  Save
                </Button>
              </div>
            </form>
          </div>
        </div>

        {loading && <Loader className={"fixed"} />}
      </FormModal>
  );
}

export const AdminWarningPopup = ({
  onClose,
  show,
  message,
  warningYesClick,
}) => {
  return (
    <FormModal
      onClose={onClose}
      show={show}
      heading={"Confirmation"}
      size={"md"}
      loading={false}
      loader="fixed"
    >
      <p>{message}</p>
      <div className="btn-container">
        <Button className={"btn-outline-color-primary"} onClick={onClose}>
          No
        </Button>
        <Button onClick={warningYesClick} className="btn-color-primary">
          Yes
        </Button>
      </div>
    </FormModal>
  );
};
