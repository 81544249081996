import { useEffect, useState } from "react";
import ReportCards from "../../../components/Cards/report";
import { cardArray, cardDemandArray } from "./reportsArray";
import Loader from "../../../components/Loader";
import { useSelector, useDispatch } from "react-redux";
import {
  updateParentPageData,
  updateSelectedAgentReportTab,
  updateSelectedAgentTabKey,
  updateSelectedPhysicalTeamReportTab,
} from "../../../pages/Login/reducer";
import { useLocation } from "react-router-dom";
import { getData } from "../../../api";
import CTab from "../../../components/Tab";
import AgentReportTable from "./reportTabels/agent/agentReport";
import AgentReport from "./reportTabels/agent/agentReportData";
import EnterpriseAgentReportData from "./reportTabels/enterpriseAgent/enterpriseAgentReportData";
import RefreshButton from "../../../components/RefreshButton";
import { PHYSICALTEAM, PHYSICAL_TEAM } from "../../../custom/constants";

export default function ReportData() {
  const [filterArray, setFilterArray] = useState([]);
  const [demandsFilterArray, setDemmandFilterArray] = useState([]);
  const location = useLocation();
  const [isloding, setIsLoading] = useState(false);
  const [reportValue, setReportValue] = useState({});
  const [refLoading, setRefLoading] = useState(false);
  const [key, setKey] = useState("properties");
  // const [agentTabKey, setAgentTabKey] = useState("enterpriseAgent");
  const user = useSelector((state) => state.login.parentPage);
  const selectedAgentReportTab = useSelector(
    (state) => state.login.selectedAgentReportTab
  );
  const selectedPhysicalTeamReportTab = useSelector(
    (state) => state.login.selectedPhysicalTeamReportTab
  );
  const agentTabKey = useSelector((state) => state.login.agentTabKey);
  const storeuser = location?.state?.user ? location?.state?.user : user;
  const getReportData = async () => {
    setIsLoading(true);
    let url = `/admin/reportNew?type=${storeuser}&sectionType=${selectedPhysicalTeamReportTab}`;
    const res = await getData(url, {}, {});
    const { statusCode, data } = res;
    if (statusCode === 200) {
      setIsLoading(false);
      setReportValue(data);
    } else {
      setIsLoading(false);
    }
  };

  const setTabKey = (k) => {
    // setOffset(1);
    setKey(k);
    // setContextValue(k);
    // localStorage.setItem("companyTabKey", k);
  };
  const dispath = useDispatch();
  useEffect(() => {
    if (storeuser !== "agent") {
      getReportData();
    }
    return () => {
      setFilterArray([]);
      setReportValue({});
    };
}, [storeuser])
  
  useEffect(() => {
    if (storeuser) {
      dispath(updateParentPageData(storeuser));

      let filterValue = cardArray.filter((item) => item.name === storeuser);
      if (storeuser === "agent") {
        if (selectedAgentReportTab === "demands") {
          setFilterArray(filterValue[0]?.value2);
        } else if (selectedAgentReportTab === "properties") {
          setFilterArray(filterValue[0]?.value1);
        } else if (selectedAgentReportTab === "contacts") {
          setFilterArray(filterValue[0]?.value3);
        }
      } else if (storeuser === "physicalTeam") {
        if (selectedPhysicalTeamReportTab === "demands") {
          setFilterArray(filterValue[0]?.value2);
        } else if (selectedPhysicalTeamReportTab === "properties") {
          setFilterArray(filterValue[0]?.value1);
        } 
        else if (selectedPhysicalTeamReportTab === "contacts") {
          setFilterArray(filterValue[0]?.value3);
        }
        else if (selectedPhysicalTeamReportTab === "projects") {
          setFilterArray(filterValue[0]?.value4);
        }
      } else {
        setFilterArray(filterValue[0]?.value);
      }

     
    }
  }, [storeuser, selectedPhysicalTeamReportTab]);

  // const refreshRelevancy = async () => {
  //   const url = `/dashboard/setRelevancyData?type=relevantPropertyForAgent`;
  //   setRefLoading(true);
  //   let response = await refreshData(url);
  //   setRefLoading(false);
  //   if (response?.status) {
  //     Swal.fire({
  //       // title: header,
  //       text: "Please wait! New data will reflect after 5 Min",
  //       icon: "success",
  //       timer: 5000,
  //       buttons: false,
  //       showCancelButton: false,
  //       showConfirmButton: false,
  //       showCloseButton: true,
  //     });
  //   }
  // };

  const tabDetails = [
    {
      title: "Properties",
      content: (
        <>
          {key === "properties" ? (
            <ReportCards keyData={filterArray} value={reportValue} />
          ) : null}
        </>
      ),
      eventKey: "properties",
    },
    {
      title: "Demands",
      content: (
        <>
          <ReportCards keyData={filterArray} value={reportValue} />
        </>
      ),
      eventKey: "demands",
    },

    {
      title: "Contacts",
      content: <ReportCards keyData={filterArray} value={reportValue} />,
      eventKey: "contacts",
    },
    {
      title: "Projects",
      content: <ReportCards keyData={filterArray} value={reportValue} />,
      eventKey: "projects",
    },
    // {
    //   title: "Responses",
    //   content: <>{key === "responses" ? <div>Responses Report</div> : null}</>,
    //   eventKey: "responses",
    // },
  ];
  const agentTabDetails = [
    {
      title: "Enterprise Agent",
      content:
        // <div className="w-100">
        //   <CTab
        //     tabDetails={tabDetails}
        //     activeKey={selectedAgentReportTab}
        //     onSelect={(k) => {
        //       dispath(updateSelectedAgentReportTab(k));
        //     }}
        //   />
        // </div>
        agentTabKey === "enterpriseAgent" ? (
          <EnterpriseAgentReportData />
        ) : null,
      eventKey: "enterpriseAgent",
    },
    {
      title: "Individual Agent",
      content:
        // <>
        //   {/* <div className="w-100">
        //     <CTab
        //       tabDetails={tabDetails}
        //       activeKey={selectedAgentReportTab}
        //       onSelect={(k) => {
        //         dispath(updateSelectedAgentReportTab(k));
        //       }}
        //     />
        //   </div> */}

        //   <AgentReportTable />
        // </>
        agentTabKey === "agent" ? <AgentReport /> : null,
      eventKey: "agent",
    },
    // {
    //   title: "Responses",
    //   content: <>{key === "responses" ? <div>Responses Report</div> : null}</>,
    //   eventKey: "responses",
    // },
  ];
  return (
    <>
      <label className="text-heading" title="Dashboard">
        {`${
          storeuser === "agent"
            ? "Agent"
            : storeuser === PHYSICALTEAM
            ? PHYSICAL_TEAM
            : `Enterprise ${storeuser}`
        }`}
      </label>
      {/* <div></div> */}
      {/* {storeuser === "agent" ? (
        <div className="Relevancy_Refresh">
          <RefreshButton loading={refLoading} onClick={refreshRelevancy} />
        </div>
      ) : (
        ""
      )} */}

      {isloding ? (
        <Loader />
      ) : storeuser === PHYSICALTEAM ? (
        <div className="w-100">
          <CTab
            tabDetails={tabDetails}
            activeKey={selectedPhysicalTeamReportTab}
            onSelect={(k) => {
              dispath(updateSelectedPhysicalTeamReportTab(k));
            }}
          />
        </div>
      ) : storeuser === "agent" ? (
        <div className="w-100">
          <CTab
            tabDetails={agentTabDetails}
            activeKey={agentTabKey}
            onSelect={(k) => {
              dispath(updateSelectedAgentTabKey(k));
            }}
          />
        </div>
      ) : (
        <ReportCards keyData={filterArray} value={reportValue} />
      )}
    </>
  );
}
