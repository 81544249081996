import { useEffect, useState } from "react";
import { getData } from "../../../api";

const Reports = ({ customerUserId, type, setReports, isExternalsetReports = false }) => {
  const [reportData, setReportData] = useState({});
  console.log({ type })
  const data = [
    {
      id: "listingAllocated",
      label: "Listings Allocated",
      value: "Nil",
    },
    {
      id: "projects",
      label: type === "brand" ? "Demands" : "Properties",
      value: "Nil",
    },
    {
      id: "numberOfResponse",
      label: "Numbers of Responses",
      value: "Nil",
    },
  ];

  const eAgentdata = [
    {
      id: "listingAllocated",
      label: "Listings Allocated",
      value: "Nil",
    },
    {
      id: "numberOfResponse",
      label: "Numbers of Responses",
      value: "Nil",
    },
    {
      id: "propertyProjects",
      label: "Properties",
      value: "Nil",
    },
    {
      id: "demandProjects",
      label: "Demands",
      value: "Nil",
    },
  ];


  const physicalTeamData = [
    {
      id: "listingAllocated",
      label: "Listings Allocated",
      value: "Nil",
    },
    {
      id: "propertyProjects",
      label: "Properties",
      value: "Nil",
    },
    {
      id: "numberOfResponse",
      label: "Numbers of Responses",
      value: "Nil",
    },
    {
      id: "demandProjects",
      label: "Demands",
      value: "Nil",
    },
    {
      id: "projects",
      label: "Projects",
      value: "Nil",
    },
    {
      id: "projectLeads",
      label: "Project Leads",
      value: "Nil",
    },
  ];

  const renderAgentData = () => {
    if (type === "physicalTeam") {
      return physicalTeamData
    } else {
      return data
    }
  }
  console.log({ isExternalsetReports })

  const getReportData = async () => {
    let url = `/brand/reports/${customerUserId}?type=${type}&reportType=filter`;
    await getData(url, {}, {}).then((response) => {
      if (response.statusCode === 200 && response.status) {
        setReportData(response.data);
        if (isExternalsetReports) setReports(response.data)
      }
    });
  };
  useEffect(() => {
    getReportData();
  }, []);

  return (
    <>
      <label className="body-text-heading mb-0">Report Summary</label>
      <div className="d-flex flex-wrap card-container">
        {type !== "eagent"
          ? renderAgentData().map((item, index) => {
            return (
              <div
                key={index}
                className="d-flex flex-column justify-content-center mt-3 small-card"
              >
                <div title={item.label} className="m-0 card-text-heading">
                  {item.label}
                </div>
                <div
                  title={reportData[item.id]}
                  className="m-0 card-sub-text"
                >
                  {reportData[item.id]}
                </div>
              </div>
            );
          })
          : eAgentdata.map((item, index) => {
            return (
              <div
                key={index}
                className="d-flex flex-column justify-content-center mt-3 small-card"
              >
                <div title={item.label} className="m-0 card-text-heading">
                  {item.label}
                </div>
                <div
                  title={reportData[item.id]}
                  className="m-0 card-sub-text"
                >
                  {reportData[item.id]}
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default Reports;
