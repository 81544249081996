export const errorCheck = (customer, subscription) => {
  let errors = {};
  let requiredField = "This Field is Required";

  const customerArray = [
    "companyId",
    "logo",
    "cities",
    "name",
    "state",
    "about",
    "stats",
    "propertyTypes",
    "transactionType"
  ];
  const subscriptionArray = [
    "propertyOrDemandSelect",
    "propertyOrDemand",
    "validityDays",
    "userDashboard",
    "demandMails",
    "homePageSlotAndBanner",
    "assistedUploading",
    "featuredProperty",
    "submissionAgainstDemand",
    "submissionAgainstDemandSelect",
    "relationshipManager",
    "rm",
  ];
  customerArray?.map((item) => {
    if (customer.hasOwnProperty(item)) {
      if (item === "about" || item === "name" || item === "stats") {
        if (customer[item] === "" || customer[item].match(/^\s*$/)) {
          errors[item] = requiredField;
        }
      }
      if (item === "logo") {
        if (customer.logo === "" || Object.keys(customer.logo).length === 0) {
          errors.logo = requiredField;
        }
      }
      if (item === "cities" || item === "state" || item === "propertyTypes" || item==="transactionType") {
        if (customer[item]?.length === 0) {
          errors[item] = requiredField;
        }
      }
      if (item === "companyId") {
        if (customer[item] === "Select" || customer[item] === "") {
          errors[item] = requiredField;
        }
      }
    }
    return item;
  });

  subscriptionArray.map((item) => {
    if (subscription.hasOwnProperty(item)) {
      if (item === "propertyOrDemandSelect") {
        if (subscription[item] === "") {
          errors.propertyOrDemandSelect = requiredField;
        } else if (subscription[item] === "Enter number") {
          if (
            subscription.propertyOrDemand === null ||
            subscription.propertyOrDemand === "" ||
            subscription.propertyOrDemand.match(/^\s*$/)
          ) {
            errors.propertyOrDemand = requiredField;
          }
        }
      }
      if (item === "validityDays") {
        if (
          subscription[item] === null ||
          subscription[item] === "" ||
          isNaN(subscription[item])
        ) {
          errors[item] = requiredField;
        }
      } else {
        if (
          item === "demandMails" ||
          item === "featuredProperty" ||
          item === "assistedUploading" ||
          item === "homePageSlotAndBanner" ||
          item === "relationshipManager"
        ) {
          if (subscription[item] === "Select" || subscription[item] === "") {
            errors[item] = requiredField;
          }
        }
      }

      if (item === "submissionAgainstDemandSelect") {
        if (subscription[item] === "") {
          errors[item] = requiredField;
        } else if (subscription[item] === "Enter number") {
          if (
            subscription.submissionAgainstDemand === null ||
            subscription.submissionAgainstDemand === "" ||
            subscription.submissionAgainstDemand.match(/^\s*$/)
          ) {
            errors.submissionAgainstDemand = requiredField;
          }
        }
      }
      if (item === "userDashboard") {
        if (
          subscription[item] === null ||
          subscription[item] === "Select" ||
          subscription[item] === ""
        ) {
          errors[item] = requiredField;
        }
      }
      if (item === "rm") {
        if (
          subscription?.relationshipManager === "Yes" &&
          (subscription?.rm === "" ||
            subscription?.rm === undefined ||
            Object.keys(subscription?.rm)?.length === 0)
        ) {
          errors.rm = requiredField;
        }
      }
    }
    return item
  });

  // -----------------------------------------------

  return errors;
};
