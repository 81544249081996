import React from "react";

function DetailSection2({ demoData2 }) {
  return (
    <div className="container-fluid mt-2" style={{ background: "#D9E5E6" }}>
      <div className="d-flex gap-4 p-3">
        {demoData2.map((item) => {
          if (item?.hideContainer) {
            return;
          } else {
            return (
              <div
                className="d-flex flex-column justify-content-center align-items-center col pt-2 rounded  bg-white"
                style={{ background: "white" }}
                key={item.id}
              >
                <p className=" fs-7" style={{ color: "#08536B" }}>
                  {item.title}
                </p>

                <p className=" fs-6 fw-bold" style={{ color: "#08536B" }}>
                  {item.name}
                </p>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
}

export default DetailSection2;
