import { useContext, useState } from "react";
import { CustomerWarningPopup } from "../../../../Customer/CustomerTable/warningPopup";
import { AgentContext } from "./physicalTeamReport";
import { ListPopup } from "../../../../../components/Modal/listModal";
import { putData } from "../../../../../api";
import { ViewCities } from "../../reportModals";
import AgentPocModal from "../../../../Customer/CustomerForm/poc/pocModal/agentPocModal";
import FormModal from "../../../../../components/Modal";

const PhysicalTeamModal = ({ page, getTableData }) => {
  const {
    openPocModal,
    setOpenPocModal,
    warningPopup,
    setWarningPopup,
    showListCities,
    setShowListcities,
    viewCities,
    setViewCities,
  } = useContext(AgentContext);

  // state
  const [btnLoading, setBtnLoading] = useState(false);

  // warning modal action

  const handleCloseWarning = () => {
    setWarningPopup({ show: false, message: "", rowData: {}, key: "" });
  };
  // onClickyes warning to active or deactive the poc
  const onHandelChangeStatus = () => {
    setBtnLoading(true);
    let data = {
      isActive: !warningPopup?.rowData?.isActive,
    };
    if (page === "physicalTeamPocList") {
      data.type = "brandPoc";
    }
    let url;
    switch (page) {
      case "propertyPostedList":
        url = `/property/changeStatus/${warningPopup?.rowData?._id}`;
        delete data["type"];
        break;
      case "propertyActiveList":
        url = `/property/changeStatus/${warningPopup?.rowData?._id}`;
        delete data["type"];
        break;
      case "propertyInActiveList":
        url = `/property/changeStatus/${warningPopup?.rowData?._id}`;
        delete data["type"];
        break;
      case "propertyResponseList":
        url = `/property/changeStatus/${warningPopup?.rowData?._id}`;
        delete data["type"];
        break;
      case "propertyPostedAgainstList":
        url = `/property/changeStatus/${warningPopup?.rowData?._id}`;
        delete data["type"];
        break;
      case "demandPostedList":
        url = `/demand/updateDemand/${warningPopup?.rowData?._id}`;
        delete data?.type;
        break;
      case "demandResponseList":
        url = `/demand/updateDemand/${warningPopup?.rowData?._id}`;
        delete data?.type;
        break;
      case "activeDemandList":
        url = `/demand/updateDemand/${warningPopup?.rowData?._id}`;
        delete data?.type;
        break;
      case "inActiveDemandList":
        url = `/demand/updateDemand/${warningPopup?.rowData?._id}`;
        delete data?.type;
        break;
      case "physicalTeamPocList":
        url = `/brand/poc/updatePoc/${warningPopup?.rowData?.brandId}/${warningPopup?.rowData?._id}`;
        break;
      default:
        break;
    }
    putData(url, {}, data).then((response) => {
      if (response.status) {
        setBtnLoading(false);
        getTableData();
        handleCloseWarning();
      } else {
        setBtnLoading(false);
        handleCloseWarning();
      }
    });
  };

  const warningYesClick = () => {
    onHandelChangeStatus();
  };
  const handelCloseCities = () => {
    setViewCities({ show: false, value: {} });
  };

  const handelClosePocModal = () => {
    setOpenPocModal({ show: false, pocType: "", brandID: "", rowObj: {} });
  };

  //   warning poup action ends

  // city list popup action starts
  const onCloseListModal = () => {
    setShowListcities({ show: false, value: [] });
  };
  const getPocType = (value) =>
    value === "cityPocList"
      ? "cityPOC"
      : value === "developerPocList"
      ? "brandPOC"
      : "brandPOC";

  return (
    <>
      {openPocModal?.show && (
        <FormModal
          show={openPocModal?.show}
          onClose={handelClosePocModal}
          heading={
            openPocModal?.pocType === "view"
              ? "View POC"
              : openPocModal?.pocType === "edit"
              ? "Edit POC"
              : "Add POC"
          }
          children={
            <AgentPocModal
              companyTypeReceived={
                openPocModal?.companyTypeReceived === "Yes"
                  ? "companyYesBrand"
                  : "companyNoBrand"
              }
              modKey={getPocType(page)}
              type={"developer"}
              pocType={openPocModal?.pocType}
              pocList={getTableData}
              id={openPocModal?.brandID}
              onClose={handelClosePocModal}
              pocEditData={openPocModal?.rowObj}
              customerDetails={{ state: openPocModal?.stateList }}
            />
          }
          size={"lg"}
        />
      )}
      {warningPopup?.show && (
        <CustomerWarningPopup
          btnLoading={btnLoading}
          title={"Confirmation"}
          show={warningPopup?.show}
          message={warningPopup?.message}
          warningYesClick={warningYesClick}
          onClose={handleCloseWarning}
        />
      )}

      {showListCities?.show && (
        <ListPopup
          onClose={onCloseListModal}
          lists={showListCities?.value}
          show={showListCities?.show}
          title="Cities"
        />
      )}
      {viewCities?.show && (
        <ViewCities
          show={viewCities}
          locationId=""
          onClose={handelCloseCities}
          value={viewCities?.value}
          heading={"View Cities"}
        />
      )}
    </>
  );
};
export default PhysicalTeamModal;
